import React, { Component } from 'react';
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import { AuthUserContext } from '../../Session';
import { withFirebase } from '../../Firebase';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import Switch from "react-switch";


const styles = theme => ({
    grow: {
        flexGrow: 1,
    },
});


class PublishingSwitch extends Component {
    static contextType = AuthUserContext;
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    handleClose = (event) => {
        event.stopPropagation();
        //console.log('handleClose')
        this.setState({openInfo: false, closeTag: true})
    }

    render() {
        const { theme } = this.props;
        //console.log('updated props', this.props, this.state)
        return (
            <div 
                onClick={(event) => {
                    event.stopPropagation();
                    if((this.props.access === false || this.props.disabled === true) && !(this.state.openInfo) && this.state.closeTag !== true){
                        this.setState({openInfo: true})
                        if(this.props.onClickHandler !== undefined){
                            this.props.onClickHandler(true)
                        }
                    }
                    if(this.state.closeTag === true){
                        this.setState({closeTag: false})
                    }
                }}
                style={{
                    position: 'absolute', 
                    zIndex: 5, // Ensure it's above the greyed-out div
                    right: 0, 
                    paddingLeft: 4,
                    paddingTop: 4,
                    paddingRight: 4,
                    top: 0, 
                    //backgroundColor: theme.palette.white + "22",
                    borderBottomLeftRadius: theme.borderRadius,
                    borderTopRightRadius: theme.borderRadius
                }}
            >
                <Dialog
                    open={this.state.openInfo || false}
                    onClose={this.handleClose }
                >
                    <DialogTitle>
                        Unpublished
                    </DialogTitle>
                    <DialogContent>
                        <p style={theme.textErrorCenterPadding}>
                            {this.props.infoText || "Item cannot be published! Please edit your item settings."}
                        </p>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose}>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
                <Switch
                    onChange={
                        (this.props.onChange !== undefined && this.props.access !== false && this.props.disabled !== true)
                        ?   (c, event, id) => {
                                this.props.onChange(c, event, id)
                            }
                        :   () => void(0)
                    }
                    offColor={theme.palette.themeRed}
                    checked={this.props.checked || false}
                    onColor={this.props.onColor || theme.palette.themeAlertGreen}
                    disabled={this.props.disabled || this.props.access === false || false}
                    uncheckedIcon={<NotInterestedIcon style={{color: "white", marginLeft: 4, marginTop: 2}}/>}
                />
            </div>
        );
    }
}

export default compose(
    withStyles(styles, { withTheme: true }),
    withFirebase,
)(PublishingSwitch);
