import React, { Component } from 'react';
import { compose } from 'recompose';
import LanguageManager from '../../constants/languages';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import { AddAnalytics } from '../Modules/AddAnalytics';
import DangerouslySetInnerHTML from '../Content/DangerouslySetInnerHTML';

//import { FindTextValueInDict } from '../Modules/LoadCategoriesAndQualities';
import AppInstallLinks from '../Profile/AppInstallLinks';
import RoleOverview from '../Profile/RoleOverview';
import queryString from 'query-string';
import * as ROUTES from '../../constants/routes';
import BadgeCard from '../Modules/BadgeCard';
import logoWhatsNext from "../../images/Badge_XP_whatsnext.png";
import CardChangeName from "../Onboarding/CardChangeName";

import { AuthUserContext } from '../Session';
import { withFirebase } from '../Firebase';
// MODULES
import GoalOverview from '../Goals/GoalOverview';
import SettingUser from './SettingUser';
import SettingAccountLinking from './SettingAccountLinking';
import SettingCode from './SettingCode';
import SettingEmail from './SettingEmail';
import SettingPlan from './SettingPlan';
import SettingAbout from './SettingAbout';
import SettingAppColorMode from './SettingAppColorMode';
import SettingLanguage from './SettingLanguage';
import SettingGamification from './SettingGamification';

import SnackbarInfo from '../Modules/SnackbarInfo';

import Card from '@material-ui/core/Card';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';

//style icons
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import NotificationsOffIcon from '@material-ui/icons/NotificationsOff';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import FaceIcon from '@material-ui/icons/Face';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import InfoIcon from '@material-ui/icons/Info';
import LinkIcon from '@material-ui/icons/Link';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import GroupIcon from '@material-ui/icons/Group';
import TuneIcon from '@material-ui/icons/Tune';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import IconButton from '@material-ui/core/IconButton';
import DialogTutorialPage from '../Onboarding/DialogTutorialPage';
// style elements
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionActions';
//import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
//import Typography from '@material-ui/core/Typography';
//import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import MailOutlineIcon from '@material-ui/icons/MailOutline';

import SignOutButton from '../SignOut';
import {IsMobileDevice} from '../Modules/IsMobileDevice';
import { CheckMobileNotificationActivated } from '../Modules/StoreMobileDeviceInfo';

const styles = theme => ({
    root: {
        marginRight: 'auto',
        marginLeft: 'auto',
        maxWidth: theme.maxWidthDialog,
        backgroundColor: theme.palette.backPaper,
        height: '100%'
    },
});



class HomeProfile extends Component {
    static contextType = AuthUserContext;
    _isMounted = false;

    constructor(props) {
        super(props);
        this.dialogRef = React.createRef();
        this.showSubscriptionsRef = React.createRef();
        this.showNotificationsRef = React.createRef();
        this.readOutAccountLinkingInstructions();
        var coachAccount = false;
        if (window.location?.hostname?.includes('coach.rocky.ai')){
            coachAccount = true;
        }
        this.state = {
            loading: false,
            user: null,
            coachAccount: coachAccount
        };
    }

    componentDidMount() {
        this._isMounted = true;
        this.setState({ loading: false },
            (() => {
                try{
                    if(this.props.location?.state?.showSubscriptions){
                        this.setState({showSubscriptions: true});
                        //console.log('stater', this.state)
                        if(this.showSubscriptionsRef !== undefined ) {
                            this.showSubscriptionsRef.current.scrollIntoView({behaviour: 'smooth'});
                            //console.log('locaded into view on start')
                        }
                    }
                    else if(this.props.location?.state?.showNotifications){
                        this.setState({showNotifications: true});
                        if(this.showNotificationsRef !== undefined ) {
                            this.showNotificationsRef.current.scrollIntoView({behaviour: 'smooth'});
                        }
                    }
                    else if(this.dialogRef !== undefined ) {
                        this.dialogRef.current.scrollIntoView({behaviour: 'smooth'});
                        //console.log('locaded into view on start')
                    }
                }
                catch (err) {
                    console.log('No scroll to top');
                }
            })
        );
        AddAnalytics('screen_view', 'PageProfile');
    }

    componentWillUnmount() {
        this._isMounted = false;
        if (this.unsubscribe !== undefined ) this.unsubscribe();
    }

    readOutAccountLinkingInstructions() {
        //console.log("accoun",this.props)
        if(process?.env !== undefined && process.env.REACT_APP_DEVICE_PLATFORM!==undefined && process.env.REACT_APP_DEVICE_PLATFORM==="BROWSER"){
            if (this.props.location && this.props.location.search !== undefined && this.props.location.search!==""){
                //var query = queryString.parse(this.props.location.search)
                var urlSearch = window.location.href.slice(window.location.href.indexOf('?'));
                var query = queryString.parse(urlSearch);
                if(query.scope!==undefined && query.scope === "alexa::skills:account_linking"){
                    if(query.code !== undefined && query.state!==undefined){
                        var relatedUserId = query.state;
                        //this.props.firebase.user(relatedUserId).collection("accounts").doc("alexaTempCode").update(requestConnection)
                        this.props.firebase.user(relatedUserId).collection("accounts").doc("alexa")
                        .get()
                        .then(snapshot => {
                            var storedInfo = snapshot.data()
                            var codeTempAmazon = query.code;
                            storedInfo["codeTempAmazon"] = codeTempAmazon;
                            storedInfo["userId"] = relatedUserId;
                            storedInfo["createdAt"] = this.props.firebase.fieldValue.serverTimestamp();
                            //console.log(snapshot.data())
                            this.props.firebase.user(relatedUserId).collection("accounts").doc(codeTempAmazon).set(storedInfo, {merge:true })
                            this.props.history.replace({
                                pathname: ROUTES.PROFILE,
                                search:'', 
                                state:{profileTab:'accountLinking'}
                            });
                        })

                        //console.log(this.props.firebase, "detaerfrefrreoc", query, requestConnection)
                        
                        //useHistory().replace({search: ""})
                        //window.location.search = ""
                    }
                }
            }
        }
    }

    render() {
        var user = this.context;
        const { loading } = this.state;
        const { theme, classes, screen } = this.props;
        //console.log('this profile', this.props)
        return (
            <Grid key="splitsidenavi" container direction="row" ref={this.dialogRef} id='pageroot'>
                <DialogTutorialPage 
                    defaultSet={this.props.defaultSet}
                    handleOpen={this.state.handleOpenTutorial || false}
                    handleBadgeFamily = {'settings'}
                    handleClose={() => {this.setState({handleOpenTutorial: false})}}
                />
                <Grid item xs={screen==="desktop" ? 2 : false} >
                    {false && screen==="desktop"
                        &&
                        <Card style={theme.CardDesktopLeft}>
                            <Card style={theme.CardNaviLeft}>
                                <h4 style={theme.CardContent.titleText}>Settings</h4>
                            </Card>
                        </Card>
                    }
                </Grid>
                <Grid item xs={screen==="desktop" ? 8 : 12} >
                    {screen !== "desktop"
                        &&
                            <Card 
                                style={{
                                    ...theme.CardTop, 
                                    position: this.state.headerVisible? 'relative' : 'fixed',
                                    zIndex: this.state.headerVisible? 0 : 100,
                                    top: 0,
                                    opacity: this.state.headerVisible? '1.0' : '0.94',
                                }}>
                                <div style={theme.textMonthCenter}><strong>Account Settings</strong></div>
                                <div style={{position: 'fixed', top: 3, right: 8}}>
                                    <IconButton onClick={() => {this.setState({handleOpenTutorial: true})}}>
                                        <HelpOutlineIcon />
                                    </IconButton>
                                </div>
                            </Card>
                    }
                    <div className={classes.root}>
                        {user !== undefined && user !== null &&
                            <SnackbarInfo 
                                alertText = 'Click on the goal or growth topic to change the chat and content context. You can also define if you want to get reminded daily or weekly.'
                                alertName = 'profileintro'
                                userId = {this.context.uid}
                                                                    user = {this.context}
                                />
                        }

                        <div style={{paddingBottom: 100}}>
                            {screen === "desktop"
                                ?
                                    <div style={{textAlign: 'right', width: '100%'}}>
                                        <IconButton style={{marginRight: 2}} onClick={() => {this.setState({handleOpenTutorial: true})}}>
                                            <HelpOutlineIcon />
                                        </IconButton>
                                    </div>
                                :   <span>&nbsp;</span>
                            }
                        </div>
                
                        <div style={theme.textMonthCenter}>
                            <AccountCircleIcon style={{ color: theme.palette.primary.dark, fontSize: 60  }} />
                            <CardChangeName
                                title = {'Change Name'}
                                simple = {true}
                                text = {"What should I call you?"}
                                user = {this.props.user}
                            />
                        </div>

                        {loading &&
                            <div style={theme.divCenterPadding}>
                                <CircularProgress color="secondary"/>
                            </div>
                        }
                        {(this.props.showFutureSelf === false || this.state.coachAccount || (!(this.props.user?.achievementSelf?.text?.length > 1) && this.props.user?.hidePublicContent))
                            ?   ''
                            :
                                <div style={{marginLeft: 20, marginRight: 20}}>
                                    {this.props.user?.achievementSelf && this.props.user?.achievementSelf.text
                                        ?
                                            <BadgeCard
                                                backgroundColor ={"white"}
                                                image={this.props.user?.achievementSelf?.icon || logoWhatsNext} 
                                                avatarImage = {undefined}
                                                title = {this.props.user?.achievementSelf?.text}
                                                subtitle = {this.props.user?.achievementSelf?.explanationShort || undefined}
                                                strongSubtitle = {false}
                                                onClick={ () => { this.props.history.push({pathname: ROUTES.ONBOARDING, state: {allowBack: true} }) }}
                                                clickText= {'Change'}
                                            />
                                        :
                                            <BadgeCard
                                                backgroundColor ={"white"}
                                                image={logoWhatsNext} 
                                                avatarImage = {undefined}
                                                title = {'My Future Self'}
                                                subtitle = {'Define who you want to be'}
                                                strongSubtitle = {false}
                                                onClick={ () => { this.props.history.push({pathname: ROUTES.ONBOARDING, state: {allowBack: true} }) }}
                                                clickText= {'Set Up'}
                                            />
                                    }
                                </div>
                        }
                        {user!== undefined && user!==null && this.state.coachAccount !== true &&
                            <div style={{ maxWidth: IsMobileDevice() ? '100%' :  theme.maxWidthDialog }}>
                                <RoleOverview 
                                    defaultSet={this.props.defaultSet || undefined}
                                    key={"role"}
                                    user={user}
                                />
                            </div>
                        }
                        {user!== undefined && user!==null && this.props.leadershipCategories !== undefined && this.props.leadershipQualities !== undefined && this.state.coachAccount !== true &&
                            <div style={{ maxWidth: IsMobileDevice() ? '100%' :  theme.maxWidthDialog }}>
                                <GoalOverview 
                                    user={user}
                                    defaultSet={this.props.defaultSet || undefined}
                                    //features={(!this.context.programs?.includes('default') || FindTextValueInDict(this.props.firebase, this.props.leadershipCategories, this.context.currentCategory, 'program') !== 'default') ? undefined : 'goal'}
                                    leadershipCategories={this.props.leadershipCategories}
                                    leadershipQualities={this.props.leadershipQualities}
                                    refreshQualities = {this.props.refreshQualities || undefined}
                                    refreshCategories = {this.props.refreshCategories || undefined}
                                />
                            </div>
                        }
                        {this.props.defaultSet?.programBrand?.length > 10 &&
                            <div style={{ alignItems:"center", verticalAlign:'middle', textAlign: 'center'}}>
                                <img
                                    alt={this.props.defaultSet.programTitle}
                                    style={{maxWidth: 150}}
                                    src={ this.props.defaultSet?.programBrand}
                                    >
                                </img>
                            </div>
                        }
                        <div>&nbsp;</div>
                        {user!== undefined && user!==null && 
                            <div style={{ maxWidth: IsMobileDevice() ? '100%' :  theme.maxWidthDialog }}> 
                                {true &&
                                    <Accordion 
                                        ref={this.showNotificationsRef}
                                        TransitionProps={{ mountOnEnter: true }}
                                        expanded={this.state.showNotifications ? true : false}
                                        onChange={(e,expanded) => {this.setState({showNotifications: expanded})}}  >
                                        <AccordionSummary
                                            expandIcon={<KeyboardArrowRightIcon />}
                                            aria-label="NotificationExpand"
                                            aria-controls="Notifications-content"
                                            id="Notifications-header"
                                            >
                                            <FormControlLabel
                                                aria-label="Notifications"
                                                control={
                                                    !(CheckMobileNotificationActivated(user))
                                                    ?   <NotificationsOffIcon style={{...theme.cardSettings.iconDesign, paddingRight: 6, color: "red"}}/>
                                                    :   <NotificationsNoneIcon style={{...theme.cardSettings.iconDesign, paddingRight: 6}}/>
                                                }
                                                label={
                                                    <LanguageManager
                                                        text={"Notification Preferences"}
                                                        type='button'
                                                        language={this.context?.features?.language}
                                                    />
                                                }
                                            />
                                        </AccordionSummary>
                                        <AccordionDetails style={theme.divDefaultSidePadding}>
                                            <SettingEmail user={user}/>
                                        </AccordionDetails>
                                    </Accordion>
                                }  
                                {user.news!== undefined && user.news!== "" 
                                    && 
                                    <Accordion TransitionProps={{ mountOnEnter: true }}>
                                        <AccordionSummary
                                            expandIcon={<KeyboardArrowRightIcon />}
                                            aria-label="News"
                                            aria-controls="News"
                                            id="News"
                                            >
                                            <FormControlLabel
                                                aria-label= "News"
                                                control= {<MailOutlineIcon style={{...theme.cardSettings.iconDesign, paddingRight: 6}}/>}
                                                label = {
                                                    <LanguageManager
                                                        text={"News & Updates"}
                                                        type='button'
                                                        language={this.context?.features?.language}
                                                    />
                                                }
                                            />
                                        </AccordionSummary>
                                        <AccordionDetails style={theme.divDefaultSidePadding}>
                                            <div style={{...theme.innerHTML, width:'100%', textAlign: 'left'}} >
                                                <DangerouslySetInnerHTML
                                                    user={user}
                                                    defaultLanguage={this.props.defaultSet?.programLanguage}
                                                    textHTML = {user.news}
                                                />
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                }
                                <Accordion TransitionProps={{ mountOnEnter: true }}>
                                    <AccordionSummary
                                        expandIcon={<KeyboardArrowRightIcon />}
                                        aria-label="Code-Expand"
                                        aria-controls="Code-content"
                                        id="Code-header"
                                        >
                                        <FormControlLabel
                                            aria-label= "Code"
                                            control= {<FaceIcon style={{...theme.cardSettings.iconDesign, paddingRight: 6}}/>}
                                            label= {
                                                (user.roles?.includes('COACHEE') || user.roles?.includes('OBSERVE'))
                                                ?   (user.groups !== undefined && user.groups.length > 0)
                                                    ?   <>
                                                            Coach-Code&nbsp;&&nbsp;
                                                            <LanguageManager
                                                                text={"Your Teams"}
                                                                type='button'
                                                                language={this.context?.features?.language}
                                                            />
                                                            &nbsp;
                                                            <GroupIcon style={{...theme.inlineIconRight,color: "green"}}/>&nbsp;
                                                            {this.context.coachAccess &&
                                                                <RemoveRedEyeIcon style={{...theme.inlineIconRight,color: "red"}}/>
                                                            }
                                                        </>
                                                    :   <>
                                                            Coach-Code&nbsp;&&nbsp;
                                                            <LanguageManager
                                                                text={"Your Teams"}
                                                                type='button'
                                                                language={this.context?.features?.language}
                                                            />
                                                        </>
                                                :   
                                                    "Coach-Code"
                                            }
                                        />
                                    </AccordionSummary>
                                    <AccordionDetails style={theme.divDefaultSidePadding}>
                                        <SettingCode user={user}/>
                                    </AccordionDetails>
                                </Accordion>
                                {false && this.state.coachAccount !== true 
                                && 
                                    <Accordion TransitionProps={{ mountOnEnter: true }}
                                            //expanded={this.props.location['state'] !== undefined && this.props.location.state.profileTab === "accountLinking" ? true : false}
                                        >
                                        <AccordionSummary
                                            expandIcon={<KeyboardArrowRightIcon />}
                                            aria-label="servicelink-Expand"
                                            aria-controls="servicelink-content"
                                            id="servicelink-header"
                                            >
                                            <FormControlLabel
                                                aria-label="servicelink"
                                                control={<LinkIcon style={{...theme.cardSettings.iconDesign, paddingRight: 6}}/>}
                                                label={
                                                    <LanguageManager
                                                        text={"Connect Other Apps"}
                                                        type='button'
                                                        language={this.context?.features?.language}
                                                    />
                                                }
                                            />
                                        </AccordionSummary>
                                        <AccordionDetails style={theme.divDefaultSidePadding}>
                                            <Grid container 
                                                direction="column"
                                            >
                                                <AppInstallLinks 
                                                    user={user}
                                                    defaultSet={this.props.defaultSet}
                                                />
                                                <Grid item xs={12}>
                                                    <SettingAccountLinking 
                                                        user={user}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion> 
                                }
                                {this.props.showSubscriptions !== false &&
                                    <Accordion
                                        ref={this.showSubscriptionsRef}
                                        TransitionProps={{ mountOnEnter: true }}
                                        expanded={this.state.showSubscriptions ? true : false}
                                        onChange={(event, open) => this.setState({showSubscriptions: open})}
                                    >
                                        <AccordionSummary
                                            expandIcon={<KeyboardArrowRightIcon />}
                                            aria-label="Subscription-Expand"
                                            aria-controls="Subscription-content"
                                            id="Subscription-header"
                                            >
                                            <FormControlLabel
                                                aria-label="Subscription"
                                                control={<StarBorderIcon style={{...theme.cardSettings.iconDesign, paddingRight: 6}}/>}
                                                label={
                                                    <LanguageManager
                                                        text={"Subscription Plan"}
                                                        type='button'
                                                        language={this.context?.features?.language}
                                                    />
                                                }
                                            />
                                        </AccordionSummary>
                                        <AccordionDetails style={theme.divDefaultSidePadding}>
                                            <SettingPlan 
                                                user={user}
                                                defaultSet={this.props.defaultSet || undefined}
                                                variant = {this.props.defaultSet?.programShopURL?.length > 2 ? 'externalLink' : undefined}
                                                externalLink = {this.props.defaultSet?.programShopURL?.length > 2 ? this.props.defaultSet.programShopURL : undefined}
                                                coach={this.state.coachAccount ? true : false}
                                            />
                                        </AccordionDetails>
                                    </Accordion>
                                }
                                <Accordion TransitionProps={{ mountOnEnter: true }}>
                                    <AccordionSummary
                                        expandIcon={<KeyboardArrowRightIcon />}
                                        aria-label="About-Expand"
                                        aria-controls="About-content"
                                        id="About-header"
                                        >
                                        <FormControlLabel
                                            aria-label="About"
                                            control={<InfoIcon style={{...theme.cardSettings.iconDesign, paddingRight: 6}}/>}
                                            label={
                                                <LanguageManager
                                                    text={"About Us, Terms & Privacy"}
                                                    type='button'
                                                    language={this.context?.features?.language}
                                                />
                                            }
                                        />
                                    </AccordionSummary>
                                    <AccordionDetails style={theme.divDefaultSidePadding}>
                                        <SettingAbout 
                                            user={user}
                                            defaultSet={this.props.defaultSet || undefined}
                                        />
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion TransitionProps={{ mountOnEnter: true }}>
                                    <AccordionSummary
                                        expandIcon={<KeyboardArrowRightIcon />}
                                        aria-label="App-Expand"
                                        aria-controls="App-content"
                                        id="App-header"
                                    >
                                        <FormControlLabel
                                            aria-label="App"
                                            control={<TuneIcon style={{...theme.cardSettings.iconDesign, paddingRight: 6}}/>}
                                            label={
                                                <LanguageManager
                                                    text={"App Options"}
                                                    type='button'
                                                    language={this.context?.features?.language}
                                                />
                                            }
                                        />
                                    </AccordionSummary>
                                    <AccordionDetails style={theme.divDefaultSidePadding}>
                                        <Grid container direction='row'>
                                            <Grid item xs={12}>
                                                <SettingLanguage
                                                    user={user}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <SettingAppColorMode 
                                                    user={user}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <SettingGamification
                                                    user={user}
                                                />
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion TransitionProps={{ mountOnEnter: true }}>
                                    <AccordionSummary
                                        expandIcon={<KeyboardArrowRightIcon />}
                                        aria-label="Account-Expand"
                                        aria-controls="Account-content"
                                        id="Account-header"
                                        >
                                        <FormControlLabel
                                            aria-label="Account"
                                            control={<PermIdentityIcon style={{...theme.cardSettings.iconDesign, paddingRight: 6}}/>}
                                            label={
                                                <LanguageManager
                                                    text={"Account, Data & Credentials"}
                                                    type='button'
                                                    language={this.context?.features?.language}
                                                />
                                            }
                                        />
                                    </AccordionSummary>
                                    <AccordionDetails style={theme.divDefaultSidePadding}>
                                        <SettingUser 
                                            user={user}
                                        />
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        }

                        { //user !== undefined && user !==null &&  user.anonymousSignup !== true &&
                            <div style={theme.textMonthCenter}>
                                <p style={{ marginTop: 30, marginBottom: 50, color: theme.palette.secondary.main}}>
                                    <SignOutButton /> 
                                    <span onClick={this.props.firebase.doSignOut}>
                                        <LanguageManager
                                            text={"Sign Out"}
                                            type='button'
                                            language={this.context?.features?.language}
                                        />
                                    </span>
                                </p>
                            </div>
                        }
                        <p style={{ marginTop: 50, marginBottom:  50}}>
                            &nbsp;
                        </p>

                    </div>
                </Grid>
                <Grid item xs={screen==="desktop" ? 2 : false} ></Grid>
            </Grid>
        );
    }
}

HomeProfile.propTypes = {
    classes: PropTypes.object.isRequired
};

//const condition = authUser => !!authUser;

export default compose(
    withRouter,
    withStyles(styles, { withTheme: true }),
    withFirebase
)(HomeProfile);
