import React, { Component } from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import withStyles from '@material-ui/core/styles/withStyles';
import LanguageManager from '../../constants/languages';

import DialogueStages from '../Routine/DialogueStages';
import Grid from '@material-ui/core/Grid';
import ProgressDoughnut from '../Charts/ProgressDoughnut';

//// List lements
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Card from '@material-ui/core/Card';

//import AdjustIcon from '@material-ui/icons/Adjust';

//import {AvatarCard} from '../Modules/AvatarImage';
//import AssistantIcon from '@material-ui/icons/Assistant';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
//import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import SendIcon from '@material-ui/icons/Send';

// Help
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import IconButton from '@material-ui/core/IconButton';

import DialogContentItem from '../Content/DialogContentItem';
import CardSentimentGauge from '../Charts/CardSentimentGauge';
import CardLatestSentiments from '../Charts/CardLatestSentiments';

const styles = theme => ({
    grow: {
      flexGrow: 1,
    },
});

class MindfulnessScore extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);

        var dialogue = null;
        if(this.props.dialogue !== undefined){
            dialogue = this.props.dialogue;
            //console.log('dialogueino', dialogue)
        }
        this.state = {
            loading: false,
            dialogue: dialogue,
            countQuestions: 0,
            countAnswers: 0,
            countMindfulAnswers: 0,
        }
    }

    componentDidMount() {
        //const { theme } = this.props;
        //console.log(this.props)
        this._isMounted = true;
        this.loadDialogueResults();
    }

    componentWillUnmount(){
        this._isMounted = false;
    }

    loadDialogueResults(){
        var chatResultScore = 0.0;
        var chatResultKeys = 0;
        var chatResultActions = 0;
        var xpGenerated = 0;
        var dialogue = null;
        if(this.props.dialogue !== undefined){
            dialogue = this.props.dialogue;
            //console.log('dialogueino', dialogue)
        }
        if (dialogue!== undefined && dialogue!== null && 'wordCollection' in dialogue) {
            if ('keys' in dialogue.wordCollection) {
                chatResultKeys =  Object.keys(dialogue.wordCollection.keys).length;
                chatResultActions = Object.keys(dialogue.wordCollection.actions).length;
            }
            chatResultScore = chatResultKeys*0.1 + chatResultActions*0.01;
            if(dialogue.xpEnd !== undefined && dialogue.xpStart !== undefined && dialogue.xpEnd > 0){
                var delta = 4 + dialogue.xpEnd - dialogue.xpStart;
                if(delta > 0){
                    xpGenerated = delta;
                    //console.log('xp', xpGenerated)
                }
            }
        }
        if( chatResultScore > 5) {
            chatResultScore = 5;
        }
        if (chatResultScore > 0){
            var percentage = Math.round(chatResultScore*20);
            if(this._isMounted) this.setState({dataReflectionercentage : percentage, xpGenerated, dialogue})
        }
    }

    componentDidUpdate(prevProps){
        if(this._isMounted && this.props.dialogue !== undefined && prevProps.dialogue !== undefined && this.props.dialogue.xpEnd !== prevProps.dialogue.xpEnd ){
            this.loadDialogueResults();
        }
    }

    updateContentDataSelection (content, index) {
        if(this._isMounted) {
            var contentData = this.state.dialogue.contentSelection[index];
            //var contentId = this.state.dialogue.contentSelection[index].contentId;
            //var contentData = content;
            var contentId = contentData?.contentId || 'xxxxxx';
            this.setState({
                DialogContentPreviewOpen: true, 
                previewContentId : contentId,
                contentData : contentData,
            })
            if(contentData === undefined || contentData.text === undefined || contentData.text === '' ){
                this.reloadContent(contentId)
            }
        }
    }

    reloadContent = (contentId) => {
        if ( contentId !== undefined && contentId!== "") {
            this.props.firebase.content(contentId)
            .get().then(doc => {
                var contentData = doc.data() ;
                if (contentData !== undefined && contentData.text !== undefined && this._isMounted ){
                    this.setState({contentData });
                }
            })  
        }
    }

    submitInspiration = (text) => {
        if(this.props.handlerMessageSubmit!== undefined){
            this.props.handlerMessageSubmit(text);
            this.setState({open:false});
        }
    }

    render() {
        const {theme} = this.props;
        if(this.state.dataReflectionercentage !== undefined){
            return(
                <div style={{width:'100%'}}>
                    {this.props.showIdeas &&
                        <DialogContentItem
                            user={this.props.user}
                            defaultSet={this.props.defaultSet || undefined}
                            open={this.state.DialogContentPreviewOpen}
                            handleClose={() => this.setState({DialogContentPreviewOpen : false})}
                            specialQuery={'contentByIds'}
                            contentData={this.state.contentData}
                            contentId = {this.state.previewContentId}
                            leadershipCategories={this.props.leadershipCategories}
                            leadershipQualities={this.props.leadershipQualities}
                            handlerMessageSubmit = {
                                this.props.handlerMessageSubmit === undefined
                                    ?  undefined
                                    :  (text) => {this.submitInspiration(text)}
                                }
                        />
                    }
                    {/** <AvatarCard title={true} textBlock={'Reflection score'}  type={'icon'} image={<AssistantIcon/>} squareSize='20' backgroundColor={'black'}/> */}
                    <Grid container spacing={2}  alignItems="center">
                        {this.props.showMindset !== false
                        && 
                            <>
                                <Grid item xs={12}>
                                    <div style={{position: 'relative'}}>
                                        <h4 style={theme.CardContent.titleText}>Reflection Score</h4>
                                        {this.props.tutorialOpen 
                                        &&
                                            <div style={{position: 'absolute', top: -4, right: -6}}>
                                                <IconButton style={{marginRight: 0}} onClick={() => {if(this.props.tutorialOpen) this.props.tutorialOpen('insights')}}>
                                                    <HelpOutlineIcon />
                                                </IconButton>
                                            </div>
                                        }
                                    </div>
                                </Grid>
                                {this.props.showPhase !== false && this.props.dialogue!== undefined && this.props.dialogue !== null && this.props.dialogue.dialogueStage !== undefined
                                    &&
                                    <Grid item xs={12}>
                                        <DialogueStages
                                            finished = {true}
                                            stages = {this.props.dialogue.dialogueStage || undefined}
                                        />
                                    </Grid>
                                }
                                {this.props.dialogue?.sentiments !== undefined && this.props.dialogue?.sentiments !== {}
                                    ?
                                        <Grid item xs> 
                                            <CardSentimentGauge
                                                user={this.props.user}
                                                title={'Trending Mindset'}
                                                sentiments={this.props.dialogue?.sentiments || {}}
                                                tutorialOpen={
                                                    this.props.tutorialOpen !== undefined
                                                    ?   this.props.tutorialOpen
                                                    :   undefined
                                                }
                                            />
                                        </Grid>
                                    :   
                                        <React.Fragment>
                                            <Grid item xs={4}>
                                                <div //style={{marginBottom:50, left:'10%' , position:'sticky'}} 
                                                >
                                                    {this.state.xpGenerated > 0
                                                        ?
                                                            <ProgressDoughnut
                                                                percentage={this.state.dataReflectionercentage || 50}
                                                                overWriteText={(this.state.xpGenerated + ' XP')}
                                                            />
                                                        :
                                                            <ProgressDoughnut
                                                                percentage={this.state.dataReflectionercentage || 50}
                                                            />
                                                    }
                                                </div>
                                            </Grid>
                                            <Grid item xs={8}>
                                                {this.state.dialogue!== undefined && this.state.dialogue !== null
                                                    ?
                                                        <List>
                                                            <ListItem  style={{paddingLeft:0, paddingRight:0}}>
                                                                {this.state.dialogue.dialogueStats !== undefined && this.state.dialogue.dialogueStats.counterQuestions > 4  
                                                                    ?
                                                                        <ListItemIcon style={{marginRight:10, minWidth:'auto', color: "green"}}>
                                                                            <CheckCircleOutlineIcon/>
                                                                        </ListItemIcon>
                                                                    :
                                                                        <ListItemIcon style={{marginRight:10, minWidth:'auto', color: "black"}}>
                                                                            <RadioButtonUncheckedIcon/>
                                                                        </ListItemIcon>
                                                                }
                                                                <ListItemText>
                                                                    {this.state.dialogue.dialogueStats !== undefined && this.state.dialogue.dialogueStats.counterQuestions > 2  
                                                                        ?
                                                                            "Interacted with "  + this.state.dialogue.dialogueStats.counterQuestions + " questions"
                                                                        :
                                                                            "Reflection started" 
                                                                    }
                                                                </ListItemText>
                                                            </ListItem>
                                                            <ListItem  style={{paddingLeft:0, paddingRight:0}}>
                                                                {this.state.dialogue.dialogueStats !== undefined && this.state.dialogue.dialogueStats.counterResponses > 4  
                                                                    ?
                                                                        <ListItemIcon style={{marginRight:10, minWidth:'auto', color: "green"}}>
                                                                            <CheckCircleOutlineIcon/>
                                                                        </ListItemIcon>
                                                                    :
                                                                        <ListItemIcon style={{marginRight:10, minWidth:'auto', color: "black"}}>
                                                                            <RadioButtonUncheckedIcon/>
                                                                        </ListItemIcon>
                                                                }
                                                                <ListItemText>
                                                                    {this.state.dialogue.dialogueStats !== undefined && this.state.dialogue.dialogueStats.counterResponses > 2  
                                                                        ?
                                                                            ""  + this.state.dialogue.dialogueStats.counterResponses + " descriptive responses"
                                                                        :
                                                                            this.state.dialogue.dialogueStats === undefined
                                                                            ?   this.state.dialogue.dialogueEnded ? "Finished the reflection" : "Reflection incomplete"
                                                                            :   "More effort in giving respones required" 
                                                                    }
                                                                </ListItemText>
                                                            </ListItem>
                                                            <ListItem  style={{paddingLeft:0, paddingRight:0}}>
                                                                {this.state.dialogue.dialogueStats !== undefined && this.state.dialogue.dialogueStats.counterReflections > 2  
                                                                    ?
                                                                        <ListItemIcon style={{marginRight:10, minWidth:'auto', color: "green"}}>
                                                                            <CheckCircleOutlineIcon/>
                                                                        </ListItemIcon>
                                                                    :
                                                                        <ListItemIcon style={{marginRight:10, minWidth:'auto', color: "black"}}>
                                                                            <RadioButtonUncheckedIcon/>
                                                                        </ListItemIcon>
                                                                }
                                                                <ListItemText>
                                                                    <ListItemText>
                                                                        {this.state.dialogue.dialogueStats !== undefined && this.state.dialogue.dialogueStats.counterReflections >= 1  
                                                                            ?
                                                                                this.state.dialogue.dialogueStats.counterReflections + " mindful reflections"
                                                                            :
                                                                                this.state.dialogue.dialogueStats === undefined
                                                                                ?   this.state.dialogue.wordCollection!== undefined  ? "Keywords identified" : "No reflection context"
                                                                                :  "Add more reflective articulations" 
                                                                        }
                                                                    </ListItemText>
                                                                </ListItemText>
                                                            </ListItem>
                                                        </List>
                                                    :
                                                        ''
                                                }
                                            </Grid>
                                        </React.Fragment>
                                }
                                {this.props.showSentiments !== false && this.props.dialogue?.sentiments !== undefined &&
                                    <Grid item xs style={{minWidth: 200}}>
                                        <CardLatestSentiments
                                            user={this.props.user}
                                            title={'Trending Topics'}
                                            sentiments={this.props.dialogue?.sentiments}
                                            tutorialOpen={
                                                this.props.tutorialOpen !== undefined
                                                ?   this.props.tutorialOpen
                                                :   undefined
                                            }
                                        />
                                    </Grid>
                                }
                            </>
                        }
                        {this.props.showIdeas === true && this.state.dialogue !== undefined && this.state.dialogue.contentSummary !== undefined && this.state.dialogue.contentSummary.length > 0
                            //&& this.state.dialogue.phase === 'session_ended'  
                            && 
                            <Grid item xs={12}>
                                {this.ideasSection()}
                            </Grid>
                        }
                    </Grid>
                    
                </div>
            );
        } else {
            return ''
        }
    }

    ideasSection(){
        return(
            <IdeasSection
                user = {this.props.user}
                lastConclusions = {this.state.dialogue?.lastConclusions || undefined}
                contentSummary = {this.state.dialogue?.contentSummary || undefined}
                contentSelection = {this.state.dialogue?.contentSelection || undefined}
                handlerContentOpened = {this.state.dialogue?.handlerContentOpened || undefined}
                handlerMessageSubmit = {this.props.handlerMessageSubmit !== undefined ? (content) => this.submitInspiration(content) : undefined}
                onClick = {(content, index) => this.updateContentDataSelection(content, index)}
                tutorialOpen = {this.props.tutorialOpen || undefined}
                leadershipCategories = {this.props.leadershipCategories || undefined}
                leadershipQualities = {this.props.leadershipQualities || undefined}
            />
        )
    }
}

class IdeasSectionList extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        //console.log('Ideas', this.props)

        this.state = {
            text: '',
        };
    }

    render(){
        const {theme} = this.props;
        var contentSummary = this.props.contentSummary || undefined;
        if (contentSummary?.length > 0) {
            return(
                <div>
                    {this.props.showTitle !== false 
                    &&
                        <div style={{position: 'relative'}}>
                            <h4 style={theme.CardContent.titleText}>
                                <LanguageManager
                                    text={"Ideas"}
                                    type='button'
                                    language={this.props.user?.features?.language}
                                />
                            </h4>
                            {this.props.tutorialOpen 
                            &&
                                <div style={{position: 'absolute', top: -4, right: -6}}>
                                    <IconButton style={{marginRight: 0}} onClick={() => {if(this.props.tutorialOpen) this.props.tutorialOpen('content')}}>
                                        <HelpOutlineIcon />
                                    </IconButton>
                                </div>
                            }
                        </div>
                    }
                    <Card 
                        style={this.props.themeClass !== undefined ? undefined : theme.CardGoals}
                        className={this.props.themeClass !== undefined ? this.props.themeClass : undefined}
                    >
                        <List style={{paddingLeft: 10, paddingRight:10}} >
                            {contentSummary !== undefined && contentSummary.map((content, index) => {
                                if(index<=2){
                                    if(this.props.contentSelection!== undefined && this.props.contentSelection[index] !== undefined 
                                        && this.props.leadershipCategories !== undefined && this.props.user !== undefined){
                                        return(
                                            <ListItem key={'expl_'+index}
                                                onClick={
                                                    this.props.handlerMessageSubmit!==undefined
                                                    ?   () => {
                                                            this.setState({['checked_'+index]: true, ['hover'+index]: false});
                                                            this.props.handlerMessageSubmit(content, index);
                                                        }
                                                    :   this.props.onClick !== undefined
                                                        ?   () => {
                                                                this.setState({['checked_'+index]: true, ['hover'+index]: false});
                                                                this.props.onClick(content, index);
                                                            }
                                                        :   void (0)
                                                }
                                                style={
                                                    this.state['hover'+index] === true
                                                    ?   {paddingLeft: 10, paddingRight: 10, backgroundColor: theme.palette.themeAltGold, borderRadius: theme.borderRadius}
                                                    :   {paddingLeft:0, paddingRight:0, backgroundColor: 'transparent' }
                                                }
                                                onMouseEnter={() => {this.setState({['hover'+index]: true})} }
                                                onMouseLeave={() => {this.setState({['hover'+index]: false})} }
                                            >
                                                <ListItemText disableTypography style={{color: this.state['hover'+index] === true ? 'black' : theme.palette.themeText, paddingLeft:0, paddingRight: 10}}>
                                                    <LanguageManager
                                                        text={content}
                                                        type={'text'}
                                                        language={this.props.user?.features?.language}
                                                    />
                                                </ListItemText>
                                                <ListItemIcon style={{marginLeft:10, minWidth:'auto',  color: theme.palette.themeRed}}>
                                                    {this.props.handlerMessageSubmit!==undefined
                                                        ?   <SendIcon fontSize='small' />
                                                        :   (this.state['checked_'+index] || this.props.handlerContentOpened?.includes(this.props.contentSelection?.[index]?.contentId))
                                                            ?   <CheckCircleIcon style={{color: theme.palette.themeAlertGreen}}/>
                                                            :   <SearchIcon />  
                                                    }
                                                </ListItemIcon>
                                            </ListItem>
                                        ) 
                                    }
                                    else{
                                        return(
                                            <ListItem key={'expl_'+index} style={{paddingLeft:0, paddingRight:0}}
                                            >
                                                <ListItemIcon style={{marginLeft:10, minWidth:'auto', color: theme.palette.themeDarkBlue}}>
                                                    <RadioButtonCheckedIcon /> 
                                                </ListItemIcon>
                                                <ListItemText disableTypography style={{color: theme.palette.themeRed, marginLeft:10}}>
                                                    <LanguageManager
                                                        text={content}
                                                        type={'text'}
                                                        language={this.props.user?.features?.language}
                                                    />
                                                    {content}
                                                </ListItemText>
                                            </ListItem>
                                        ) 
                                    }
                                }
                                else return ""               
                            })}
                        </List>
                        {this.props.lastConclusions?.length> 0
                            &&
                                <div style={{
                                    paddingLeft: 10,
                                    paddingBottom: 15,
                                    paddingRight: 40,
                                    display: 'inline-block',
                                    fontSize: 16,
                                    whiteSpace: 'pre-wrap'}}
                                >
                                    <span onClick={() => this.setState({showFullText: true})}>
                                        <LanguageManager
                                            text={this.props.lastConclusions[this.props.lastConclusions.length - 1]}
                                            type='text'
                                            limit={this.state.showFullText ? undefined : 140}
                                            language={this.props.user?.features?.language}
                                        />
                                    </span>
                                </div>
                        }
                    </Card>
                    {
                        <p style={theme.textSupportCenter}>
                            <LanguageManager
                                text={"Reflection"}
                                type='button'
                                language={this.props.user?.features?.language}
                            />
                            &nbsp;&bull;&nbsp;
                            <LanguageManager
                                text={"Conclusions"}
                                type='button'
                                language={this.props.user?.features?.language}
                            />
                            &nbsp;&bull;&nbsp;
                            <LanguageManager
                                text={"Actions"}
                                type='button'
                                language={this.props.user?.features?.language}
                            />
                        </p>
                    }
                </div>
            )
        }
        else return ''
    }
}

export const IdeasSection = compose(
    withStyles(styles, { withTheme: true })
)(IdeasSectionList);

export default compose(
    withFirebase,
    withStyles(styles, { withTheme: true })
)(MindfulnessScore);
