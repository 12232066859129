//import palette from '../palette';

function muiStyle (palette) { return {
    root: {
        fontSize: '16px',
        //backgroundColor: 'rgba(241,240,238,.9)',
        backgroundColor: palette.white + "66",
        borderTopLeftRadius: 15,
        borderTopRightRadius: 15,
        borderBottomLeftRadius: 15,
        marginTop: 5,
        '& .MuiOutlinedInput-root': {
            borderTopLeftRadius: 15,
            borderTopRightRadius: 15,
            borderBottomLeftRadius: 15,
            '&:hover fieldset': {
                borderColor: palette.secondary.light,
                borderTopLeftRadius: 15,
                borderTopRightRadius: 15,
                borderBottomLeftRadius: 15,
            },
            '&.Mui-focused fieldset': {
                borderColor: palette.black,
                borderTopLeftRadius: 15,
                borderTopRightRadius: 15,
                borderBottomLeftRadius: 15,
            },
        },
        // Add this to style the label
        '& .MuiInputLabel-root': {
            paddingLeft: 2,
            paddingTop: 10,
        },
        '& .MuiInputBase-input.MuiInput-input': {  // Target the input element specifically
            paddingLeft: 13,
            paddingRight: 10,
        },
    }
}}

export default muiStyle;