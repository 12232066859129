import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

const getTextContent = (children) => {
    if (typeof children === 'string') return children;
    if (Array.isArray(children)) {
        return children
            .map(child => getTextContent(child))
            .filter(Boolean)
            .join('');
    }
    if (children?.props?.children) {
        return getTextContent(children.props.children);
    }
    return '';
};
const nativeSelect = process?.env?.REACT_APP_DEVICE_PLATFORM==="BROWSER" ? false : true;

export const SelectOption = ({children, ...props }) => {
    if (nativeSelect) {
        const textContent = getTextContent(children);
        return (
            <option {...props}>
                {textContent}
            </option>
        );
    }
    return (
        <MenuItem {...props}>
            {children}
        </MenuItem>
    );
};

export const SelectWrapper = ({ children, ...props }) => {
    return (
        <Select
            native={nativeSelect}
            disableUnderline={true}
            MenuProps={{
                disableScrollLock: true,
            }}
            {...props}
        >
            {children}
        </Select>
    );
};
