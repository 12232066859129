import React, { Component } from "react";
import { AddAnalytics } from '../Modules/AddAnalytics';
import { withRouter } from 'react-router-dom';
import LanguageManager from '../../constants/languages';
import { withAuthorization,} from '../Session';
import { AuthUserContext } from '../Session';
import { compose } from "recompose";
import { Link } from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
import ChatBlock from '../Routine/ChatBlock';
import BadgeCard from '../Modules/BadgeCard';
import AvatarImage from '../Modules/AvatarImage';

import {ExtendDialogueMessage} from '../Routine/DiaryManual';

import SnackbarInfo from '../Modules/SnackbarInfo';
import {GetTimeZone} from '../Modules/LoadUserTimezone';
import { FindTextValueInDict } from '../Modules/LoadCategoriesAndQualities';

import WidgetsIcon from '@material-ui/icons/Widgets';
//import ForumIcon from '@material-ui/icons/Forum';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import RockyIcon from '../Modules/RockyIcon';
//import ForumIcon from '@material-ui/icons/Forum';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import {AvatarCard} from '../Modules/AvatarImage';
import DialogTutorialPage from '../Onboarding/DialogTutorialPage';
//import { PasswordForgetLink } from "../PasswordForget";
import { withFirebase } from "../Firebase";
import * as ROUTES from "../../constants/routes";

import MobileStepper from '@material-ui/core/MobileStepper';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
//import Typography from '@material-ui/core/Typography';
import SwipeableViews from 'react-swipeable-views';
import LoadingProgress from '../Modules/LoadingProgress';
import {SetStateLoadCategoriesAndQualities} from '../Modules/LoadCategoriesAndQualities';

import LeadershipQualityPicker from '../Goals/LeadershipQualityPicker';

//import backgroundImageWelcome from "../../images/login-background.gif";
//import backgroundImageDesktop from "../../images/login-background.png";
import backgroundImageSignup from "../../images/bluerunningtrack3.jpg";

import logo from "../../images/rocky-logo-square.png";
import logoAnimated from "../../images/rocky-logo-animated.gif";
import logoContent from "../../images/content-logo-square.png";
//import logoCaption from "../../images/rockyai-logo-caption.png";
import logoWhatsNext from "../../images/Badge_XP_whatsnext.png";
import logoChampion from "../../images/Badge_Accomplishment.png";
import logoStart from "../../images/Badge_XP_start.png";

// MUI stuff
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

import {StoreMobileDeviceToken } from '../Modules/StoreMobileDeviceInfo';
import {IsMobileDevice} from '../Modules/IsMobileDevice';

const styles = theme => ({
    root: {
        paddingTop: 'calc( 20px + env(safe-area-inset-top))',
        paddingBottom: 'calc( 20px + env(safe-area-inset-bottom))',
        backgroundColor: theme.palette.themeAltBlue,
        backgroundImage: theme.palette.backgroundImage,
        backgroundPosition: "center",
        //backgroundAttachment: "fixed", // does not work due to overflow
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        minHeight: "100vh",
        //width: "100%"
    },
    rootMobile: {
        paddingTop: 'calc( 20px + env(safe-area-inset-top))',
        paddingBottom: 'calc( 20px + env(safe-area-inset-bottom))',
        //backgroundImage: `url(${backgroundImageWelcome})`,
        //backgroundPosition: "center 30px",
        //backgroundSize: "cover",
        //backgroundRepeat: "no-repeat",
        minHeight: "100vh",
    },
    //https://stackoverflow.com/questions/50780945/how-to-override-active-color-and-font-weight-of-steplabel
    stepIcon: {
        color: theme.palette.primary.main,
    },
    done: { color: theme.palette.secondary.dark+" !important" },
    active: { color: theme.palette.secondary.light+" !important"},
    fail: { color: "red !important" },
    chat: {
        maxWidth: '500',
        width:0,
        minWidth:'100%',
    },
});


class OnboardingQuick extends Component {
    static contextType = AuthUserContext;
    _isMounted = false;
    constructor(props) {
        super(props);
        this.dialogRef = React.createRef();
        var logoProgram = logo;
        this.state = {
            activeStep : 0,
            username: '',
            logoProgram
        };
    }
  
    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
        //console.log('component mounted', this.props)
        this.setState({username: this.context.username});
        if(this.context !== undefined && this.context.programLogo !== "" && this.context.programLogo !== undefined){
            var logoProgram = this.context.programLogo;
            this.setState({logoProgram});
        }
        this.loadOnboardingData();
        SetStateLoadCategoriesAndQualities(this, this.props.firebase, true, true, this.context.programs);
        //console.log('origin props', this.context)
        StoreMobileDeviceToken(this.props.firebase.user(this.context.uid), (this.context?.dialogueCounter >=1));
        this.props.firebase.user(this.context.uid).update({
            onboardingRequired: false,
            engagementCounter: this.props.firebase.fieldValue.increment(1),
        });
        this.internetConnectionCheck();
        AddAnalytics('screen_view', 'PageOnboarding');
    }

    internetConnectionCheck() {
        this.props.firebase.onDisconnectSimple(this, (connected) => {
            //console.log('event CallReceived', this.state)
            if(connected === true && (this.state.onboardingPages === undefined || this.state.onboardingPages === null || this.state.onboardingPages.length < 1) && this._isMounted){
                this.loadOnboardingData();
                //console.log('restarted loading onboarding')
                if(this.state.leadershipQualities===undefined || this.state.leadershipQualities===null || this.state.leadershipQualities.length<1){
                    SetStateLoadCategoriesAndQualities(this, this.props.firebase, true, true, this.context.programs);
                }
                StoreMobileDeviceToken(this.props.firebase.user(this.context.uid), (this.context?.dialogueCounter >=1));
                GetTimeZone(this.props.firebase, this.context);
            }
        });
    }
        
    loadOnboardingData() { 
        var onboardingPages = [];
        this.props.firebase
        .onboardingContents()
        .where('category', '==', 'onboardQuick')
        .where('active', '==', true)
        .orderBy('page', 'asc')
        .get() 
        .then(snapshot => {
            if (snapshot.empty) {
                onboardingPages.push({ text: "We are loading the onboarding. Please ensure to have a running internet connection.", pid:"default" });
                return;
            }
            snapshot.forEach((doc) => {
                onboardingPages.push({ ...doc.data(), pid: doc.id });
            });
            if(this._isMounted){
                this.setState({ 
                    onboardingPages: onboardingPages 
                });
            }
        //console.log('pages', this.state.onboardingPages)
        });
    }

    mobileStepperButtons(){
        const {theme } = this.props;
        return(
            <MobileStepper
                variant="dots"
                steps={this.state.onboardingPages?.length || 0}
                position="static"
                activeStep={this.state.activeStep}
                style={{
                    backgroundColor: 'transparent',
                    padding: 0,
                    marginBottom: 20,
                    //opacity: '0.9',
                    //backgroundColor: theme.palette.secondary.light,
                    //borderRadius: IsMobileDevice()? '12px 12px 0 0' : '12px',
                }}
                nextButton={
                    <div style={{textAlign: 'right', width: '35%'}}>
                        {
                        this.state.activeStep === this.state.onboardingPages.length - 1 
                        ?
                            <Button 
                                variant={"outlined"}
                                disabled={(this.state['Step_'+this.state.activeStep] || this.state.selectedSelf?.id) ? false : true}
                                onClick={this.state['typingBotPageDone_'+this.state.activeStep] !== true
                                    ?   () => {this.setState({['typingBotPageDone_'+this.state.activeStep] : true, typingSkipTrigger : true})}
                                    :   this.state.selectedSelf?.id
                                        ?
                                            () => {this.props.history.push( {pathname: ROUTES.PROGRAM})}
                                        :   (this['refIntro_'+this.state.activeStep] !== null && this['refIntro_'+this.state.activeStep] !== undefined)
                                            ?   () => this['refIntro_'+this.state.activeStep].scrollIntoView({ behavior: "smooth", block:'start'  })
                                            :   () => {this.props.history.push( {pathname: ROUTES.PROGRAM})}
                                }
                            >
                                {this.state['typingBotPageDone_'+this.state.activeStep] 
                                    ?   this.state.selectedSelf?.id
                                        ?
                                            <>
                                                {theme.WelcomeLogin.buttonTextDark === true
                                                    ?   <span style={{color: 'black'}}>
                                                            <LanguageManager
                                                                text={"Next"}
                                                                type='button'
                                                                language={this.context.features?.language}
                                                            />
                                                        </span>
                                                    :   
                                                        <LanguageManager
                                                            text={"Next"}
                                                            type='button'
                                                            language={this.context.features?.language}
                                                        />
                                                }
                                                {theme.WelcomeLogin.buttonTextDark === true
                                                    ?   <KeyboardArrowRight fontSize="small" style={{color: 'black'}}/>
                                                    :   <KeyboardArrowRight fontSize="small" />
                                                }
                                            </>
                                        :
                                            <>
                                                &nbsp;
                                            </>
                                    :   <>
                                            {theme.WelcomeLogin.buttonTextDark === true
                                                ?   <span style={{color: 'black'}}>
                                                        <LanguageManager
                                                            text={"Skip"}
                                                            type='button'
                                                            language={this.context.features?.language}
                                                        />
                                                    </span>
                                                :   
                                                    <LanguageManager
                                                        text={"Skip"}
                                                        type='button'
                                                        language={this.context.features?.language}
                                                    />
                                            }
                                            {theme.WelcomeLogin.buttonTextDark === true
                                                ?   <KeyboardArrowRight fontSize="small" style={{color: 'black'}}/>
                                                :   <KeyboardArrowRight fontSize="small" />
                                            }
                                        </>
                                }
                            </Button>
                        :
                            this.state['typingBotPageDone_'+this.state.activeStep] === true
                            ?
                                <Button 
                                    variant={"outlined"}
                                    disabled={(this.state['Step_'+this.state.activeStep] || this.state.selectedSelf?.id) ? false : true}
                                    onClick={ 
                                        (this.state['Step_'+this.state.activeStep] || this.state.selectedSelf?.id)
                                        ?   () => this.handleNext('')
                                        :
                                            (this['refIntro_'+this.state.activeStep] !== null && this['refIntro_'+this.state.activeStep] !== undefined)
                                            ?   () => this['refIntro_'+this.state.activeStep].scrollIntoView({behavior: "smooth", block: "end", inline: "nearest" })
                                            :   () => this.handleNext('')
                                    }
                                >
                                    {(this.state['Step_'+this.state.activeStep] || this.state.selectedSelf?.id)
                                        ?
                                            <>
                                                {theme.WelcomeLogin.buttonTextDark === true
                                                    ?   <span style={{color: 'black'}}>
                                                            <LanguageManager
                                                                text={"Next"}
                                                                type='button'
                                                                language={this.context.features?.language}
                                                            />
                                                        </span>
                                                    :   
                                                        <LanguageManager
                                                            text={"Next"}
                                                            type='button'
                                                            language={this.context.features?.language}
                                                        />
                                                }
                                                {theme.WelcomeLogin.buttonTextDark === true
                                                    ?   <KeyboardArrowRight style={{color: 'black'}}/>
                                                    :   <KeyboardArrowRight />
                                                }
                                            </>
                                        :
                                            <>
                                                &nbsp;
                                            </>
                                    }
                                </Button>
                            :
                                <Button 
                                    variant={"outlined"}
                                    onClick={ 
                                        () =>  {this.setState({['typingBotPageDone_'+this.state.activeStep] : true, typingSkipTrigger : true})}
                                    }
                                >
                                    {theme.WelcomeLogin.buttonTextDark === true
                                        ?   <span style={{color: 'black'}}>
                                                <LanguageManager
                                                    text={"Skip"}
                                                    type='button'
                                                    language={this.context.features?.language}
                                                />
                                            </span>
                                        :   
                                            <LanguageManager
                                                text={"Skip"}
                                                type='button'
                                                language={this.context.features?.language}
                                            />
                                    }
                                    {theme.WelcomeLogin.buttonTextDark === true
                                        ?   <KeyboardArrowRight fontSize="small" style={{color: 'black'}}/>
                                        :   <KeyboardArrowRight fontSize="small"  />
                                    }
                                </Button>
                        }
                    </div>
                }
                backButton={
                    <div style={{textAlign: 'left', width: '35%'}}>
                        {
                        (this.state.activeStep > 0 || this.props.location?.state?.allowBack)
                        ?
                            <Button onClick={this.handleBack} variant="outlined">
                                {theme.WelcomeLogin.buttonTextDark === true
                                    ?   <KeyboardArrowLeft fontSize="small"  style={{color: 'black'}}/>
                                    :   <KeyboardArrowLeft fontSize="small" />
                                }
                                {theme.WelcomeLogin.buttonTextDark === true
                                    ?   <span style={{color: 'black'}}>
                                            <LanguageManager
                                                text={"Back"}
                                                type='button'
                                                language={this.context.features?.language}
                                            />
                                        </span>
                                    :   
                                        <LanguageManager
                                            text={"Back"}
                                            type='button'
                                            language={this.context.features?.language}
                                        />
                                }
                            </Button>
                        :
                            <Button variant="outlined" disabled style={{width: 84}}>
                                &nbsp;
                            </Button>
                        }
                    </div>
                }
            />
        )
    }

    render() {
        const { classes, theme } = this.props;
        //console.log('state', this.state, this.context)
        return (
            <div style={theme.rootFrame}>
                <div 
                    className={IsMobileDevice() ? classes.rootMobile : classes.root}
                    //className={classes.rootSignup}
                    ref={this.dialogRef}
                >
                    <Grid
                        container
                        spacing={0}
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Grid item xs={12} style={{ width:'100%', maxWidth: 500 }}>
                            <div style={theme.WelcomeLogin}>
                                <div style={{maxWidth: 'inherit'}}>
                                    {(this.state.onboardingPages === undefined || this.state.firebaseConnectionError===true)
                                    ?
                                        <div style={{maxWidth: 'inherit'}}>
                                            <Link to={ROUTES.PROGRAM}>
                                                <div style={theme.logoContainer}>
                                                    <img src={this.props?.defaultSet?.programLogo?.length > 5 
                                                        ?   this.props.defaultSet.programLogo
                                                        :   logoAnimated
                                                        }
                                                        style={theme.logo} 
                                                        alt="AI Coaching Chatbot Journaling" 
                                                    />
                                                </div>
                                            </Link>
                                        </div>
                                    :
                                        <div style={{maxWidth: 'inherit'}}>
                                            <Link to={ROUTES.PROGRAM}>
                                                <div style={theme.logoContainer}>
                                                    <img src={this.props?.defaultSet?.programLogo?.length > 5 
                                                        ?   this.props.defaultSet.programLogo
                                                        :   logo
                                                        }
                                                        style={theme.logo} 
                                                        alt="AI Coaching Chatbot" 
                                                    />
                                                </div>
                                            </Link>
                                            {this.state.onboardingPages[this.state.activeStep]?.h1 !== undefined && this.state.onboardingPages[this.state.activeStep]?.h1 !== '' &&
                                                <h1 style={theme.textCenterPadding}>
                                                    <LanguageManager
                                                        text={this.state.onboardingPages[this.state.activeStep].h1?.replace('{{username}}', this.context.username)}
                                                        type='button'
                                                        language={this.context?.features?.language}
                                                    />
                                                </h1>
                                            }
                                            {this.state.onboardingPages[this.state.activeStep]?.h2 !== undefined && this.state.onboardingPages[this.state.activeStep]?.h2 !== '' &&
                                                <h2 style={theme.textCenterPadding}>
                                                    <LanguageManager
                                                        text={this.state.onboardingPages[this.state.activeStep].h2?.replace('{{username}}', this.context.username)}
                                                        type='tutorial'
                                                        language={this.context?.features?.language}
                                                    />
                                                </h2>
                                            }
                                            { //!IsMobileDevice() &&
                                                <div style={theme.MobileStepperDesktop}>
                                                    {this.mobileStepperButtons()}
                                                </div>
                                            }
                                        </div>
                                    }
                                </div>
                                <div className={classes.chat}>
                                    {(this.state.onboardingPages !== undefined && this.state.firebaseConnectionError!==true)
                                    ?
                                        <SwipeableViews
                                            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                            index={this.state.activeStep}
                                            //onChangeIndex={(step) => this.handleStepChange(step)}
                                            enableMouseEvents
                                            disabled
                                            style={{maxWidth: 'inherit'}}
                                        >
                                            {this.state.onboardingPages.map((step, index) => (
                                                <div 
                                                    key={step.page+'_swiper_'+index} 
                                                >
                                                {Math.abs(this.state.activeStep - index) <= 2 
                                                ?   (
                                                        <div key={step.page+"_"+index}
                                                            >
                                                            {(this.state.activeStep === index || this.state['typingBotPageDone_'+index]) 
                                                            &&
                                                                <ChatBlock
                                                                    key={step.page}
                                                                    user={this.context}
                                                                    variant={"chatMain"}
                                                                    defaultSet={this.props.defaultSet}
                                                                    messages={[ {text: [step.h3, step.h4, step.h5, step.h6], isCoach: true, uid: step.page} ]} 
                                                                    typingBotFinishTypingNow={this.state['typingBotPageDone_'+index] || this.state.typingSkipTrigger || false}
                                                                    handlerTypingDone={() => {
                                                                        if(this._isMounted){
                                                                            this.setState({['typingBotPageDone_'+index]: true});
                                                                            //console.log('message end')
                                                                            if(this['refIntro_'+index] !== null && this['refIntro_'+index] !== undefined){
                                                                                //console.log('message scrooll')
                                                                                this['refIntro_'+index].scrollIntoView({ behavior: "smooth", block:'center' });
                                                                            }
                                                                        }
                                                                    }}
                                                                />
                                                            }
                                                            <div ref={(el) => { this['refIntro_'+index] = el; }}>
                                                                {this.state['typingBotPageDone_'+index] && (step.buttonLandingPage === undefined || step.buttonLandingPage === '') 
                                                                && step.features !== undefined && step.features !== '' 
                                                                &&
                                                                    <div key={'features_div'+"_"+index} style={{marginTop:10, marginBottom:10, marginLeft: 2, marginRight: 2}}>
                                                                    {step.features.includes('chooseGroup') 
                                                                        &&                      
                                                                        <div style={theme.textCenter} key={'features_s'+"_"+index}>
                                                                            {this.state.leadershipCategories?.map((item, index) => (
                                                                                <div key={'cats_'+index}>
                                                                                    {this.context.programs?.includes(item?.program || "xcsdvrevf")
                                                                                    //// DO NOT SHOW DEFAULTS if there are OTHER programs
                                                                                    && ((
                                                                                        (false && this.context.hidePublicContent !== true && ['confidence', 'priority', 'people', 'clarity', 'communication', 'balance']?.includes(this.context.currentCategory))
                                                                                        || item.value === this.context.currentCategory
                                                                                        || item?.program?.length > 0 && item.program !== 'default' && this.context.programs?.includes(item.program))
                                                                                        || (this.context.programs?.length === 1 && this.context.programs?.includes("default"))
                                                                                        || (this.context.programs?.length > 1 && item.program !== 'default'))
                                                                                        ?
                                                                                            <BadgeCard
                                                                                                key={'cat'+index}
                                                                                                backgroundColor ={"white"}
                                                                                                image={undefined} 
                                                                                                avatarImage = {
                                                                                                    <AvatarImage 
                                                                                                        type='imageIcon' 
                                                                                                        key={'cat'+index} 
                                                                                                        image={FindTextValueInDict(this.props.firebase, this.state.leadershipCategories, item.value, 'icon') || logoWhatsNext}
                                                                                                        vertical-align="middle" squareSize={"auto"}  
                                                                                                        backgroundColor={FindTextValueInDict(this.props.firebase, this.state.leadershipCategories, item.value, 'backgroundColor') || "white"} 
                                                                                                    />
                                                                                                }
                                                                                                title = {item.text}
                                                                                                subtitle = {
                                                                                                    item.explanationShort?.length > 0 
                                                                                                        ?   item.explanationShort.length > 24
                                                                                                            ?   item.explanationShort.substring(0, 23) + "..."
                                                                                                            :   item.explanationShort
                                                                                                        :   undefined
                                                                                                }
                                                                                                strongSubtitle = {false}
                                                                                                onClick={ () => { this.handleProgram(item) } }
                                                                                                clickText= {undefined}
                                                                                                button={
                                                                                                    <>
                                                                                                        <CheckCircleOutlineIcon fontSize="small"/>
                                                                                                        &nbsp;
                                                                                                        {"Choose"}
                                                                                                    </>
                                                                                                }
                                                                                            />
                                                                                        :
                                                                                            ""
                                                                                    }
                                                                                </div>
                                                                            ))}
                                                                            {(this.context.hidePublicContent === true || this.context.roles?.includes("COACHEE"))
                                                                            ?
                                                                                <Link 
                                                                                    to={"#"}
                                                                                    style={{textAlign: 'right', marginTop: 20}}
                                                                                    onClick={() => this.handleStepNext()}
                                                                                >
                                                                                    <LanguageManager
                                                                                        text={"Skip"}
                                                                                        type='button'
                                                                                        language={this.context?.features?.language}
                                                                                    />
                                                                                </Link>
                                                                            :
                                                                                <div style={{textAlign: 'center', marginTop: 20}} >
                                                                                    <Button 
                                                                                        //variant = 'contained'
                                                                                        variant="outlined"
                                                                                        color="secondary"
                                                                                        onClick={
                                                                                            () => {this.setState({openLeadershipQualityPicker1: true})}
                                                                                        }
                                                                                    >
                                                                                        <LanguageManager
                                                                                            text={ "Change 'Program'"}
                                                                                            type='button'
                                                                                            language={this.context?.features?.language}
                                                                                        />
                                                                                    </Button>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    }
                                                                    {step.features.includes('chooseSelf') 
                                                                        &&                      
                                                                        <div style={theme.textCenter}>
                                                                            {this.state.programOptions?.map( (futureSelf, index) =>{
                                                                                if(futureSelf?.active && futureSelf?.group?.includes(this.state['BUTTONSTATE'])){
                                                                                    return(
                                                                                        <div key={"badegeself"+index}>
                                                                                            <BadgeCard
                                                                                                backgroundColor ={"white"}
                                                                                                image={futureSelf?.icon || logoWhatsNext} 
                                                                                                avatarImage = {undefined}
                                                                                                title = {futureSelf.text}
                                                                                                subtitle = {futureSelf.explanationShort || undefined}
                                                                                                strongSubtitle = {false}
                                                                                                onClick={ () => this.setFutureSelf(futureSelf) }
                                                                                                clickText= {'Choose'}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }
                                                                                else return ''
                                                                            })}
                                                                            {(this.state['BUTTONSTATE'] === undefined || this.state.programOptions=== undefined || Object.keys(this.state.programOptions)?.length === 0)
                                                                                &&
                                                                                <div style={{marginTop:10, marginBottom:10, textAlign:'right'}}>
                                                                                    <Button onClick={this.handleBack}>
                                                                                        <LanguageManager
                                                                                            text={"Choose"}
                                                                                            type='button'
                                                                                            language={this.context?.features?.language}
                                                                                        />
                                                                                    </Button>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    }
                                                                    {step.features.includes('quality') 
                                                                        &&                      
                                                                        <>
                                                                            {this.state.selectedSelf
                                                                                ?
                                                                                    <div key={'set_'+this.state.selectedSelf?.id}>
                                                                                        <div key={"badegeself"+index} style={theme.textCenter}>
                                                                                            <BadgeCard
                                                                                                backgroundColor ={"white"}
                                                                                                image={this.state.selectedSelf?.icon || logoWhatsNext} 
                                                                                                avatarImage = {undefined}
                                                                                                title = {this.state.selectedSelf.text}
                                                                                                subtitle = {this.state.selectedSelf.explanationShort || undefined}
                                                                                                strongSubtitle = {false}
                                                                                            />
                                                                                        </div>
                                                                                        {this.context.roles?.includes("COACHEE")
                                                                                            ?
                                                                                                <ChatBlock
                                                                                                    defaultSet={this.props.defaultSet}
                                                                                                    key={'selection done'}
                                                                                                    user={this.context}
                                                                                                    variant={"chatMain"}
                                                                                                    messages={[ {text: ['You already have a personal Coaching Plan set up by your coach, who created your account and invited you.'], isCoach: true, uid: step.page} ]} 
                                                                                                    typingBotFinishTypingNow={this.state['typingBotPageDone_'+index] || this.state.typingSkipTrigger || false}
                                                                                                />
                                                                                            :
                                                                                                (!this.context.programs?.includes('default') || FindTextValueInDict(this.props.firebase, this.state.leadershipCategories, this.context.currentCategory, 'program') !== 'default')
                                                                                                ?
                                                                                                    <ChatBlock
                                                                                                        key={'selection done'}
                                                                                                        user={this.context}
                                                                                                        defaultSet={this.props.defaultSet}
                                                                                                        variant={"chatMain"}
                                                                                                        messages={[ {text: ['Your exclusive program is:'], isCoach: true, uid: step.page} ]} 
                                                                                                        typingBotFinishTypingNow={this.state['typingBotPageDone_'+index] || this.state.typingSkipTrigger || false}
                                                                                                    />
                                                                                                :
                                                                                                    ''
                                                                                        }
                                                                                        {(!this.context.programs?.includes('default') || FindTextValueInDict(this.props.firebase, this.state.leadershipCategories, this.context.currentCategory, 'program') !== 'default')
                                                                                        &&
                                                                                            <></>
                                                                                        }
                                                                                        {this.state.selectedSelf?.sisterQualities?.length> 2
                                                                                        ?
                                                                                            <>
                                                                                                {!this.context.roles?.includes("COACHEE") && this.context?.programs?.[0] === 'default'
                                                                                                    &&
                                                                                                    <ChatBlock
                                                                                                        key={'selection done'}
                                                                                                        user={this.context}
                                                                                                        defaultSet={this.props.defaultSet}
                                                                                                        variant={"chatMain"}
                                                                                                        messages={[ {text: ['Choose 2-6 coaching modules that are most interesting to you by (un-) checking them.'], isCoach: true, uid: step.page} ]} 
                                                                                                        typingBotFinishTypingNow={this.state['typingBotPageDone_'+index] || this.state.typingSkipTrigger || false}
                                                                                                    />
                                                                                                }
                                                                                                <div style={{textAlign: 'right'}}>
                                                                                                    {this.state.selectedSelf?.sisterQualities?.map( (quality, index) => {
                                                                                                        if(this.state.selectedQualities && this.state.selectedQualities?.includes(quality)){
                                                                                                            return(
                                                                                                                <span key={"badegeselfUnselect"+index}
                                                                                                                    onClick={
                                                                                                                        this.context.roles?.includes("COACHEE")
                                                                                                                        ?   () => {this.setState({flagAlertText: true, alertText: 'Coaches have already set up your personal Coaching Plan and account profile.'})}
                                                                                                                        :   () => {this.changeSelectedQualities(quality, 'remove')}
                                                                                                                    }
                                                                                                                > 
                                                                                                                    &nbsp;&nbsp;
                                                                                                                    <AvatarCard key={quality} dict={this.state.leadershipQualities} type='quality' item={quality} squareSize={26} />
                                                                                                                </span>
                                                                                                            )
                                                                                                        }
                                                                                                        else{ 
                                                                                                            return(
                                                                                                                <span key={"badegeselfSelect"+index} style={{opacity: 0.5}}
                                                                                                                    onClick={
                                                                                                                        this.context.roles?.includes("COACHEE")
                                                                                                                        ?   () => {this.setState({flagAlertText: true, alertText: 'Coaches have already set up your personal Coaching Plan and account profile.'})}
                                                                                                                        :   () => {this.changeSelectedQualities(quality, 'add')}
                                                                                                                }
                                                                                                                > 
                                                                                                                    &nbsp;&nbsp;
                                                                                                                    <AvatarCard key={quality} backgroundColor={'grey'} dict={this.state.leadershipQualities} type='quality' item={quality} squareSize={26} />
                                                                                                                </span>
                                                                                                            )
                                                                                                        }
                                                                                                    })}
                                                                                                </div>
                                                                                            </>
                                                                                        :
                                                                                            <div key={"badegecategory"+index} style={theme.textCenter}>
                                                                                                <BadgeCard
                                                                                                    backgroundColor ={"white"}
                                                                                                    image={undefined} 
                                                                                                    avatarImage = {
                                                                                                        <AvatarImage type='imageIcon' 
                                                                                                            key={this.context.currentLeadershipQuality} 
                                                                                                            image={FindTextValueInDict(this.props.firebase, this.state.leadershipCategories, this.context.currentCategory, 'icon') || logoWhatsNext}
                                                                                                            vertical-align="middle" squareSize={"auto"}  
                                                                                                            backgroundColor={FindTextValueInDict(this.props.firebase, this.state.leadershipCategories, this.context.currentCategory, 'backgroundColor') || "white"} 
                                                                                                            />
                                                                                                    }
                                                                                                    title = {FindTextValueInDict(this.props.firebase, this.state.leadershipCategories, this.context.currentCategory)}
                                                                                                    subtitle = {FindTextValueInDict(this.props.firebase, this.state.leadershipCategories, this.context.currentCategory, 'explanationShort')}
                                                                                                    strongSubtitle = {false}
                                                                                                />
                                                                                            </div>
                                                                                        }
                                                                                        <div key={"bs"+index} style={{ marginBottom: 10}}>
                                                                                            <Grid
                                                                                                container
                                                                                                spacing={0}
                                                                                                direction="row"
                                                                                                justifyContent="space-between"
                                                                                                alignItems="center"
                                                                                            >
                                                                                                <Grid item xs={6}>
                                                                                                    {!this.context.roles?.includes("COACHEE")
                                                                                                        &&
                                                                                                            <Button 
                                                                                                                //variant = 'contained'
                                                                                                                variant="outlined"
                                                                                                                color="secondary"
                                                                                                                onClick={
                                                                                                                    () => {this.setState({openLeadershipQualityPicker1: true})}
                                                                                                                }
                                                                                                            >
                                                                                                                <LanguageManager
                                                                                                                    text=
                                                                                                                        {(!this.context.programs?.includes('default') || FindTextValueInDict(this.props.firebase, this.state.leadershipCategories, this.context.currentCategory, 'program') !== 'default')
                                                                                                                            ?   "Change 'Program'"
                                                                                                                            :   "None of them"
                                                                                                                        }
                                                                                                                    type='button'
                                                                                                                    language={this.context?.features?.language}
                                                                                                                />
                                                                                                            </Button>
                                                                                                    }
                                                                                                </Grid>
                                                                                                <Grid item xs={6}>
                                                                                                    {(this.state.selectedQualities?.length>1 || this.state.selectedSelf?.sisterQualities?.length===1)
                                                                                                    &&
                                                                                                        <div style={{textAlign: 'right'}}>
                                                                                                            &nbsp;&nbsp;
                                                                                                            <Button onClick={ () => {this.handleNext('I am ready')}} >
                                                                                                                <LanguageManager
                                                                                                                    text={"I am ready"}
                                                                                                                    type='button'
                                                                                                                    language={this.context?.features?.language}
                                                                                                                />
                                                                                                            </Button>
                                                                                                        </div>
                                                                                                    }
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        </div>
                                                                                    </div>
                                                                                :
                                                                                    <div style={{marginTop:10, marginBottom:10, textAlign:'right'}}>
                                                                                        <Button onClick={this.handleBack}>
                                                                                            <LanguageManager
                                                                                                text={"Choose"}
                                                                                                type='button'
                                                                                                language={this.context?.features?.language}
                                                                                            />
                                                                                        </Button>
                                                                                    </div>
                                                                            }
                                                                        </>
                                                                    }
                                                                    {step.features.includes('chat') 
                                                                        &&                   
                                                                        <div style={theme.textCenter}>
                                                                            {step.buttons !== undefined && step.buttons.map( (buttonSet, index) =>{
                                                                                if(buttonSet.active){
                                                                                    if(buttonSet.title?.includes('busy')) return(
                                                                                        <div key={"b"+index}>
                                                                                            <BadgeCard
                                                                                                backgroundColor ={"white"}
                                                                                                image={logoStart} 
                                                                                                avatarImage = {undefined}
                                                                                                title = {buttonSet.title}
                                                                                                subtitle = {buttonSet.text || undefined}
                                                                                                strongSubtitle = {false}
                                                                                                onClick={ () =>{
                                                                                                    ExtendDialogueMessage(this.props.firebase, this.context, (buttonSet.title+ ' ' +buttonSet.text) , 'What is next?', 'utter_ask_help', '', 'profile');
                                                                                                    this.props.history.push(ROUTES.PROGRAM); 
                                                                                                } }
                                                                                                clickText= {undefined}
                                                                                                button={
                                                                                                    <>
                                                                                                        <CheckCircleOutlineIcon fontSize="small"/>
                                                                                                        &nbsp;
                                                                                                        {buttonSet.button}
                                                                                                    </>
                                                                                                }
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                    else if(buttonSet.feature === "HOME") return(
                                                                                        <div key={"b"+index}>
                                                                                            <BadgeCard
                                                                                                backgroundColor ={"white"}
                                                                                                image={logoChampion} 
                                                                                                avatarImage = {undefined}
                                                                                                title = {buttonSet.title}
                                                                                                subtitle = {buttonSet.text || undefined}
                                                                                                strongSubtitle = {false}
                                                                                                onClick={ () =>{
                                                                                                    ExtendDialogueMessage(this.props.firebase, this.context, (buttonSet.title+ ' ' +buttonSet.text) , 'What is next?', 'utter_ask_help', '', 'profile');
                                                                                                    this.props.history.push(ROUTES.PROGRAM); 
                                                                                                } }
                                                                                                clickText= {undefined}
                                                                                                button={
                                                                                                    <>
                                                                                                        <CheckCircleOutlineIcon fontSize="small"/>
                                                                                                        &nbsp;
                                                                                                        {buttonSet.button}
                                                                                                    </>
                                                                                                }
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                    else if(buttonSet.feature === "ROUTINE") return(
                                                                                        <div key={"b"+index}>
                                                                                            {!( buttonSet?.button?.includes('Mentor') && this.context?.features?.mentormode === false)
                                                                                            &&
                                                                                                <BadgeCard
                                                                                                    backgroundColor ={"white"}
                                                                                                    image={this.props.defaultSet?.programLogo?.length > 5 ? this.props.defaultSet?.programLogo : logo} 
                                                                                                    avatarImage = {undefined}
                                                                                                    title = {buttonSet.title}
                                                                                                    subtitle = {buttonSet.text || undefined}
                                                                                                    strongSubtitle = {false}
                                                                                                    onClick={ () => { 
                                                                                                        if(buttonSet.button?.includes('Mentor')){
                                                                                                            this.props.firebase.user(this.context.uid).update({
                                                                                                                ['features.mentormode']: true
                                                                                                            });
                                                                                                            //console.log('set 12')
                                                                                                        }
                                                                                                        ExtendDialogueMessage(this.props.firebase, this.context, (buttonSet.title+ ' ' +buttonSet.text) , 'What is next?', 'utter_ask_help', '', 'profile');
                                                                                                        this.props.history.push(ROUTES.ROUTINE); 
                                                                                                    } }
                                                                                                    clickText= {undefined}
                                                                                                    button={
                                                                                                        <>
                                                                                                            <RockyIcon fontSize="small"/>
                                                                                                            &nbsp;
                                                                                                            {buttonSet.button}
                                                                                                        </>
                                                                                                    }
                                                                                                />
                                                                                            }
                                                                                        </div>
                                                                                    )
                                                                                    else if(buttonSet.feature === "CONTENT") return(
                                                                                        <div key={"b"+index}>
                                                                                            <BadgeCard
                                                                                                backgroundColor ={"white"}
                                                                                                image={logoContent} 
                                                                                                avatarImage = {undefined}
                                                                                                title = {buttonSet.title}
                                                                                                subtitle = {buttonSet.text || undefined}
                                                                                                strongSubtitle = {false}
                                                                                                onClick={ () => { 
                                                                                                    ExtendDialogueMessage(this.props.firebase, this.context, (buttonSet.title+ ' ' +buttonSet.text) , 'What is next?', 'utter_ask_help', '', 'profile');
                                                                                                    this.props.history.push(ROUTES.CONTENT); 
                                                                                                } }
                                                                                                clickText= {undefined}
                                                                                                button={
                                                                                                    <>
                                                                                                        <WidgetsIcon fontSize="small"/>
                                                                                                        &nbsp;
                                                                                                        {buttonSet.button}
                                                                                                    </>
                                                                                                }
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                    else if(buttonSet.feature === "TUTORIAL") return(
                                                                                        <div key={"b"+index}>
                                                                                            <BadgeCard
                                                                                                backgroundColor ={"white"}
                                                                                                image={logoContent} 
                                                                                                avatarImage = {undefined}
                                                                                                title = {buttonSet.title}
                                                                                                subtitle = {buttonSet.text || undefined}
                                                                                                strongSubtitle = {false}
                                                                                                onClick={ () => {
                                                                                                    ExtendDialogueMessage(this.props.firebase, this.context, (buttonSet.title+ ' ' +buttonSet.text) , 'What is next?', 'utter_ask_help', '', 'profile');
                                                                                                    this.setState({handleTutorialOpen: true});
                                                                                                }}
                                                                                                clickText= {undefined}
                                                                                                button={
                                                                                                    <>
                                                                                                        <HelpOutlineIcon fontSize="small"/>
                                                                                                        &nbsp;
                                                                                                        {buttonSet.button}
                                                                                                    </>
                                                                                                }
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                    else return ""
                                                                                }
                                                                                else return ""
                                                                            })}
                                                                        </div>
                                                                    }
                                                                    </div>
                                                                }
                                                                {this.state['typingBotPageDone_'+index] && (step.features === undefined || step.features === '') 
                                                                && 
                                                                    <div style={{marginTop:10, marginBottom:10, textAlign:'right'}}>
                                                                        <Button onClick={() => {this.handleNext('Okay')}}>
                                                                            Okay
                                                                        </Button>
                                                                    </div>
                                                                }
                                                                {/***
                                                                {this.state['typingBotPageDone_'+index] && (step.buttonConfirm1 === undefined || step.buttonConfirm1 === '') && (step.buttonLandingPage !== undefined && step.buttonLandingPage !== '') 
                                                                &&
                                                                    step.buttonLandingPage !== undefined && step.buttonLandingPage !== '' &&
                                                                    <div style={theme.divCenter}> 
                                                                        <Link to={ROUTES[step.buttonLandingPage]}>
                                                                            <Button  >
                                                                            {step.button}
                                                                            </Button>
                                                                        </Link>
                                                                    </div>
                                                                }
                                                                 */}
                                                            </div>
                                                        </div>
                                                    ) 
                                                :   ''
                                                }
                                                </div>
                                            ))}
                                        </SwipeableViews>
                                    :
                                        <div style={theme.loadingCenterPadding}>
                                            <LoadingProgress 
                                                defaultSet={this.props.defaultSet}
                                                type={'CircularProgress'} 
                                                loadingText={"loading"}
                                                firebaseConnectionSpeed={this.state.firebaseConnectionSpeed} 
                                            />
                                        </div>
                                    }

                                    { ((!window.navigator.onLine  ) || this.state.firebaseConnectionError===true )
                                        &&
                                            <div >
                                                <p style={this.props.theme.textErrorCenterPadding}>
                                                    &nbsp; Trying to connect with the internet!
                                                </p> 
                                            </div>
                                    }
                                    <DialogTutorialPage 
                                        defaultSet={this.props.defaultSet}
                                        handleBadgeFamily = {'total'} 
                                        handleOpen={this.state.handleTutorialOpen || false}
                                        handleClose={() => {this.setState({handleTutorialOpen: false})}}
                                    />
                                    <LeadershipQualityPicker
                                        defaultSet={this.props.defaultSet || undefined}
                                        firstSessionDone={false}
                                        premiumAccess={true}
                                        //chosenCategory={this.state.chosenCategory}
                                        scope={'current'}
                                        user={this.context}
                                        //preselectQuality={this.state.preselectQuality}
                                        openChooseTopic1={this.state.openLeadershipQualityPicker1 || false}
                                        openChooseTopic2={this.state.openLeadershipQualityPicker2 || false}
                                        leadershipCategories={this.state.leadershipCategories}
                                        leadershipQualities={this.state.leadershipQualities}
                                        handlerCloseIgnoreProgramsFlag = {true}
                                        handler={ (scope) => {this.handleLeadershipQualityPickerCloseBack()} }
                                        handlerOpen2={ (entry) => {this.handleLeadershipQualityPickerOpen2(entry)} }
                                        handlerClose2={() => this.handleLeadershipQualityPickerClose2()}
                                        handlerCloseAll={() => this.handleLeadershipQualityPickerCloseAll()}
                                        submitNewPath={(category, quality) => this.loadPathFromCatalogue(category, quality)}
                                    />
                                    <SnackbarInfo 
                                        alertText = {this.state.alertText || 'Choose 2-6 coaching modules that are most interesting to you by (un-) checking them.'}
                                        openOnClick = {true}
                                        alertOpenEvent = {this.state.flagAlertText || false}
                                        closeHandler = {() => {this.setState({flagAlertText: false})}}
                                    />
                                </div>
                                <div style={{minHeight:50}}>&nbsp;</div> 
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
        );
    }

    handleStepNext = () => {
        var activeStep = this.state.activeStep || 0;
        console.log('active steos', activeStep)
        this.setState({
            activeStep : activeStep + 1,
            ['Step_'+activeStep]: true
        }, 
        () => this.handleScrollTop());
    }
    handleStepChange = step => {
        //console.log('handleStepChange')
        if(this._isMounted){
            this.setState({activeStep : step}, 
                () => this.handleScrollTop());
        }
    };
    handleScrollTop = () => {
        if(this.dialogRef !== undefined) this.dialogRef.current.scrollIntoView({behaviour: 'smooth'});
    }

    handleProgram = (category) => {
        //console.log('category', category)
        var userProfileUpdate = {};
        userProfileUpdate['todaysLeadershipQuality'] = this.props.firebase.fieldValue.delete();
        userProfileUpdate['additionalLeadershipQuality1'] = this.props.firebase.fieldValue.delete();
        userProfileUpdate['additionalLeadershipQuality2'] = this.props.firebase.fieldValue.delete();
        userProfileUpdate['additionalLeadershipQuality3'] = this.props.firebase.fieldValue.delete();
        userProfileUpdate['additionalLeadershipQuality4'] = this.props.firebase.fieldValue.delete();
        userProfileUpdate['additionalLeadershipQuality5'] = this.props.firebase.fieldValue.delete();
        userProfileUpdate['additionalLeadershipQuality6'] = this.props.firebase.fieldValue.delete();
        var goal = category.answerQuestionIssueLongTerm || category.explanationShort;
        var initialLevelQuality = category.initialLevelQuality || undefined;
        if(category.program === 'default' && initialLevelQuality?.length > 2){
            userProfileUpdate['currentLeadershipQuality'] = initialLevelQuality;
        }
        else{
            //console.log('this.state.leadershipQualities', this.state.leadershipQualities)
            var selectedQualityIndex = 0;
            this.state.leadershipQualities?.map((qualityDict, index) => {
                if(selectedQualityIndex <= 5){
                    if(qualityDict.category === category.value){
                        if(selectedQualityIndex === 0){
                            userProfileUpdate['currentLeadershipQuality'] = qualityDict.value;
                        }
                        else{
                            userProfileUpdate[('additionalLeadershipQuality'+selectedQualityIndex)] = qualityDict.value;
                        }
                        selectedQualityIndex +=1 ;
                        //console.log('qualityDict.value leadershipQualities', selectedQualityIndex, qualityDict.value)
                    }
                }
            })
        }
        var program = category.value;
        userProfileUpdate['currentCategory'] = program;
        if(goal?.length > 2 && !(this.context.dialogueCounter > 2)){
            userProfileUpdate['achievementGoal'] = goal;
        }
        if(this.context && this.context.uid){
            userProfileUpdate['onboardingDone'] = true;
            this.props.firebase.user(this.context.uid).update(userProfileUpdate);
            ExtendDialogueMessage(this.props.firebase, this.context, ('I want to develop ' + category.text + " and " + goal), 'What is your interest?', 'utter_ask_improvement', '', 'profile');
        }
        var activeStep = this.state.activeStep ;
        if(activeStep === this.state.onboardingPages?.length - 1 ){
            this.props.history.push( {pathname: ROUTES.PROGRAM});
        }
        else{
            this.setState({
                activeStep : activeStep + 1,
                ['Step_'+activeStep]: true
            });
            this.handleScrollTop();
        }
    }

    handleNext = (response='', value='value') => {
        this.handleScrollTop();
        //console.log('handlenext start', this.state)
        var activeStep = this.state.activeStep ;
        if(activeStep === this.state.onboardingPages?.length - 1 ){
            this.props.history.push( {pathname: ROUTES.PROGRAM});
        }
        else if(this._isMounted){
            if(response !== undefined && response !== ''){
                this.setState({
                    activeStep : activeStep + 1,
                    [value]: response,
                    ['Step_'+activeStep]: true
                });
                ExtendDialogueMessage(this.props.firebase, this.context, ('I want to start with ' + response), 'What is your interest?', 'utter_ask_improvement', '', 'profile');
            }
            else{
                this.setState({
                    activeStep : activeStep + 1,
                    ['Step_'+activeStep]: true
                });
            }
        }
        //console.log(this.state)
    };
    handleBack = () => {
        //console.log('handleback')
        if(this.state.activeStep === 0 && this.props.location?.state?.allowBack){
            window.history.back();
        }
        else{
            this.setState({activeStep : this.state.activeStep - 1}, 
                () => this.handleScrollTop());
        }
    };

    setFutureSelf = (dataSet) => {
        //console.log('setFuture self')
        if(dataSet!==undefined && dataSet!==null && dataSet.motherCategory!==undefined){
            var newSelf = Object.assign({}, dataSet);
            var selectedQualities = [].concat(dataSet.sisterQualities);
            //// THIS ITEM DETERMINES HOW MANY ARE PRE-SELECTED
            selectedQualities = selectedQualities?.splice(0, 2);
            var userProfileUpdate = {
                achievementSelf: {
                    text: dataSet.text,
                    value: dataSet.value,
                    group: dataSet.group,
                    icon: dataSet.icon,
                    explanationShort: dataSet.explanationShort,
                    definition: dataSet.definition,
                },
                onboardingDone: true
            }
            if(this.context.programs?.includes('default') && !(this.context.roles?.includes("COACHEE")) && 
                (this.context.programs?.length === 1 || (FindTextValueInDict(this.props.firebase, this.state.leadershipCategories, this.context.currentCategory, 'program') === 'default'))
            ){
                if(dataSet.motherCategory!==undefined && dataSet.motherCategory!==''){
                    userProfileUpdate['currentCategory'] = dataSet.motherCategory;
                }
                var newLeadershipQuality = dataSet.initialLevelQuality || dataSet.sisterQualities?.[0] || '';
                if(newLeadershipQuality?.length > 0){
                    var qualityValue = FindTextValueInDict(this.props.firebase, this.state.leadershipQualities, newLeadershipQuality, 'value') || newLeadershipQuality;
                    console.log('load initial LQ', qualityValue, newLeadershipQuality)
                    userProfileUpdate['currentLeadershipQuality'] = qualityValue;
                }
                if(dataSet.answerQuestionInterest !== undefined && dataSet.answerQuestionInterest!==''){
                    userProfileUpdate['ctaText'] = dataSet.answerQuestionInterest;
                }
                if(dataSet.answerQuestionIssueLongTerm !== undefined && dataSet.answerQuestionIssueLongTerm!==''){
                    if(this.context.dialogueCounter === undefined || this.context.dialogueCounter < 1){
                        userProfileUpdate['achievementGoal'] = dataSet.answerQuestionIssueLongTerm;
                    }
                }
                userProfileUpdate['additionalLeadershipQuality1'] = this.props.firebase.fieldValue.delete();
                userProfileUpdate['additionalLeadershipQuality2'] = this.props.firebase.fieldValue.delete();
                userProfileUpdate['additionalLeadershipQuality3'] = this.props.firebase.fieldValue.delete();
                userProfileUpdate['additionalLeadershipQuality4'] = this.props.firebase.fieldValue.delete();
                userProfileUpdate['additionalLeadershipQuality5'] = this.props.firebase.fieldValue.delete();
                userProfileUpdate['additionalLeadershipQuality6'] = this.props.firebase.fieldValue.delete();
                var extrasQualities = [].concat(selectedQualities);
                const index = extrasQualities.indexOf(newLeadershipQuality);
                if (index > -1) { // only splice array when item is found
                    extrasQualities.splice(index, 1); // 2nd parameter means remove one item only
                }
                extrasQualities.forEach((value, index) => {
                    if (index < 6 && value !== newLeadershipQuality){
                        userProfileUpdate['additionalLeadershipQuality'+(index+1)] = value;
                    }
                })
            }
            //else if(this.context.roles?.includes("COACHEE")){
            else {
                selectedQualities = [this.context.currentLeadershipQuality];
                if(this.context['additionalLeadershipQuality1'] && this.context['additionalLeadershipQuality1']!== '' ) selectedQualities = selectedQualities.concat(this.context['additionalLeadershipQuality1']);
                if(this.context['additionalLeadershipQuality2'] && this.context['additionalLeadershipQuality2']!== '' ) selectedQualities = selectedQualities.concat(this.context['additionalLeadershipQuality2']);
                if(this.context['additionalLeadershipQuality3'] && this.context['additionalLeadershipQuality3']!== '' ) selectedQualities = selectedQualities.concat(this.context['additionalLeadershipQuality3']);
                if(this.context['additionalLeadershipQuality4'] && this.context['additionalLeadershipQuality4']!== '' ) selectedQualities = selectedQualities.concat(this.context['additionalLeadershipQuality4']);
                if(this.context['additionalLeadershipQuality5'] && this.context['additionalLeadershipQuality5']!== '' ) selectedQualities = selectedQualities.concat(this.context['additionalLeadershipQuality5']);
                if(this.context['additionalLeadershipQuality6'] && this.context['additionalLeadershipQuality6']!== '' ) selectedQualities = selectedQualities.concat(this.context['additionalLeadershipQuality6']);
                newSelf['sisterQualities'] = selectedQualities;
            }
            if(this.context && this.context.uid){
                this.props.firebase.user(this.context.uid).update(userProfileUpdate);
                ////// if two extendDialog in the row at the initial stage it will make two new dialogues
                if(dataSet.answerQuestionIssueLongTerm !== undefined && dataSet.answerQuestionIssueLongTerm!==''){
                    ExtendDialogueMessage(this.props.firebase, this.context, dataSet.answerQuestionIssueLongTerm, 'How does your future self look like?', 'utter_ask_expectations', '', 'profile'); 
                }
                else{
                    ExtendDialogueMessage(this.props.firebase, this.context, ('I want to be ' + dataSet.text), 'Who do you want to be?', 'utter_ask_expectations', '', 'profile');
                }
            }
            this.setState({selectedSelf: newSelf, selectedQualities});
            this.handleNext();
            //console.log('newself', newSelf)
        }
        else{
            console.log('error with program selection', dataSet)
        }
    }

    changeSelectedQualities(quality, type='add'){
        var change = false;
        var changedSelectedQualities = this.state.selectedQualities || [];
        //console.log('before', changedSelectedQualities)
        if(type==='remove' && quality !==''){
            if(this.state.selectedQualities?.length>2){
                const removeIndex = this.state.selectedQualities?.indexOf(quality);
                if (removeIndex > -1) {
                    changedSelectedQualities?.splice(removeIndex, 1); // 2nd parameter means remove one item only
                }
                //console.log(removeIndex, changedSelectedQualities)
                this.setState({selectedQualities: changedSelectedQualities});
                change = true;
            }
            else{
                this.setState({
                    alertText: 'You must choose at least two modules.',
                    flagAlertText: true
                })
            }
        }
        else if (type==='add' && quality !==''){
            if(this.state.selectedQualities?.length<6){
                ///// move to first
                changedSelectedQualities = [quality].concat(changedSelectedQualities);
                //// move to second instead
                //changedSelectedQualities.splice(1, 0, quality);
                this.setState({selectedQualities: changedSelectedQualities});
                change = true;
                ExtendDialogueMessage(this.props.firebase, this.context, ('I want to improve ' + FindTextValueInDict(this.props.firebase, this.state.leadershipQualities, quality)), 'What do you want to change?', 'utter_ask_improvement', '', 'profile');
            }
            else{
                this.setState({
                    alertText: 'You can choose up to 6 modules.',
                    flagAlertText: true
                })
            }
        }
        //console.log('after', changedSelectedQualities)
        if(change){
            var userProfileUpdate = {};
            userProfileUpdate['currentLeadershipQuality'] = quality;
            userProfileUpdate['additionalLeadershipQuality1'] = this.props.firebase.fieldValue.delete();
            userProfileUpdate['additionalLeadershipQuality2'] = this.props.firebase.fieldValue.delete();
            userProfileUpdate['additionalLeadershipQuality3'] = this.props.firebase.fieldValue.delete();
            userProfileUpdate['additionalLeadershipQuality4'] = this.props.firebase.fieldValue.delete();
            userProfileUpdate['additionalLeadershipQuality5'] = this.props.firebase.fieldValue.delete();
            userProfileUpdate['additionalLeadershipQuality6'] = this.props.firebase.fieldValue.delete();
            var newLeadershipQuality = changedSelectedQualities[0];
            changedSelectedQualities.forEach((value, index) => {
                if(index === 0){
                    userProfileUpdate['currentLeadershipQuality'] = newLeadershipQuality;
                }
                else if (index < 6 && value !== newLeadershipQuality){
                    userProfileUpdate['additionalLeadershipQuality'+(index)] = value;
                }
            })
            if(this.context && this.context.uid){
                this.props.firebase.user(this.context.uid).update(userProfileUpdate);
            }
        }
    }

    loadPathFromCatalogue(category, quality){
        //console.log('new path catalgoue', category, quality)
        if(quality!==undefined && quality!==''){
            try{
                var userProfileUpdate = {};
                userProfileUpdate['currentLeadershipQuality'] = quality;
                userProfileUpdate['todaysLeadershipQuality'] = this.props.firebase.fieldValue.delete();
                userProfileUpdate['additionalLeadershipQuality1'] = this.props.firebase.fieldValue.delete();
                userProfileUpdate['additionalLeadershipQuality2'] = this.props.firebase.fieldValue.delete();
                userProfileUpdate['additionalLeadershipQuality3'] = this.props.firebase.fieldValue.delete();
                userProfileUpdate['additionalLeadershipQuality4'] = this.props.firebase.fieldValue.delete();
                userProfileUpdate['additionalLeadershipQuality5'] = this.props.firebase.fieldValue.delete();
                userProfileUpdate['additionalLeadershipQuality6'] = this.props.firebase.fieldValue.delete();
                if(category!==undefined && category!==''){
                    userProfileUpdate['currentCategory'] = category;
                }
                var newSisterQualities = [];
                var fallBackToday = quality !== 'focus' ? 'focus' : 'open_mind';
                var sisterQualities = FindTextValueInDict(this.props.firebase, this.props.leadershipQualities, quality, 'sisterQualities');
                if(sisterQualities && sisterQualities.length > 0){
                    newSisterQualities = [quality].concat(sisterQualities);
                    //console.log('created new sisters', newSisterQualities)
                }
                else{
                    newSisterQualities = [quality].concat(this.state.selectedSelf?.sisterQualities || [fallBackToday]);
                    //console.log('rebuild new sisters', newSisterQualities)
                }
                if(this.context && this.context.uid){
                    var updateSelectedSelf = this.state.selectedSelf || {};
                    updateSelectedSelf['id'] = quality;
                    ///// FIRST WE GIVE ONLY ONE OPTION SO THAT NO Module SELECTION POSSIBLE
                    updateSelectedSelf['sisterQualities'] = [quality];
                    var newSelectedQualities = [quality];
                    ///// HOWEVER IF WE PROVIDE A PROGRAM WE CAN ALLOW THEM TO ADD
                    if((FindTextValueInDict(this.props.firebase, this.state.leadershipCategories, category, 'program') === 'default')){
                        userProfileUpdate['additionalLeadershipQuality1'] = newSisterQualities[1];
                        newSelectedQualities = [quality, newSisterQualities[1]];
                        updateSelectedSelf['sisterQualities'] = newSisterQualities;
                    }
                    this.setState({
                        selectedSelf: updateSelectedSelf,
                        selectedQualities: newSelectedQualities
                    });
                    //console.log('update profile from catalogue', userProfileUpdate)
                    this.props.firebase.user(this.context.uid).update(userProfileUpdate);
                }
            }
            catch(err){
                console.log('err:', err)
            }
        }
    }

    handleLeadershipQualityPickerOpen2 = (entry) => {
        this.setState({
            openLeadershipQualityPicker1: false,
            openLeadershipQualityPicker2: true,
        });
    }
    handleLeadershipQualityPickerCloseBack = () => {
        this.setState({
            openLeadershipQualityPicker1: false,
            openLeadershipQualityPicker2: false,
        });
    }
    handleLeadershipQualityPickerClose2 = () => {
        this.setState({
            openLeadershipQualityPicker1: true,
            openLeadershipQualityPicker2: false,
        });
    }
    handleLeadershipQualityPickerCloseAll = () => {
        this.setState({
            openLeadershipQualityPicker2: false,
            openLeadershipQualityPicker1: false,
        });
    }

    startSelect(){
        this.setState({openProgramSelection: true,  chosenCategory: this.context.currentCategory});
        this.handleNext();
        ExtendDialogueMessage(this.props.firebase, this.context, 'Let me choose a topic from my program and the related Coach-Code that I used.', 'How do you want to start your journey?', 'utter_ask_help', '', 'profile');
    }
}


const condition = authUser => !!authUser;

export default compose(
    withStyles(styles, { withTheme: true }),
    withFirebase,
    withRouter,
    withAuthorization(condition),
)(OnboardingQuick);