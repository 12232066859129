import React, { Component } from 'react';
import { compose } from 'recompose';
import LanguageManager from '../../constants/languages';

import {GetAlternativeRewriteArrayRockyAI} from '../Coach/ManageQuestions';

import withStyles from '@material-ui/core/styles/withStyles';

import {IconPicker} from '../Modules/IconPicker';
import {DataCacheRead, DataCacheWrite} from "../Modules/DataCache";

import { withFirebase } from '../Firebase';

import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';

//Panels
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionActions';
import AccordionSummary from '@material-ui/core/AccordionSummary'
//import CancelIcon from '@material-ui/icons/Cancel';
import CreateIcon from '@material-ui/icons/Create';
import SendIcon from '@material-ui/icons/Send';

import _ from "lodash";

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
//import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import {IsMobileDevice} from '../Modules/IsMobileDevice';
import {RemoveScroll} from 'react-remove-scroll';

const styles = theme => ({
    hideBorder: {
        '&.MuiAccordion-root:before' : {
            display: 'none',
        },
        '&.MuiPaper-root' : {
            backgroundColor: 'transparent',
        },
        boxShadow: 'none',
    },
    bad:{
        color: theme.palette.themeAlertRed
    },
    good:{
        color: theme.palette.themeAlertGreen
    },
    negative:{
        color: theme.palette.themeAlertBlue
    },
    positive:{
        color: theme.palette.themeAlertGreen
    },
    neutral:{
        color: theme.palette.themeAlertOrange
    },
    fabRow: {
        position: 'fixed',
        //bottom: theme.spacing(2),
        bottom: 'calc(8% + env(safe-area-inset-bottom))', //ios
        //right: theme.spacing(2),
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: 460,
        width: '90%',
        zIndex: 100,
        transform: 'translate3d(0,0,0)',
        //overflowX: 'hidden',
        //overflowY: 'scroll',
        //WebkitOverflowScrolling: 'touch',
    },
})


var wordElements = {
    wordSettings : {
        feel: {text: 'I am', factor:"because of", assess: "and it needs", factorContext: 'wordSentiments', factorCategory: 'wordReasons', factorAssess: 'wordAssessments'},
        did: {text: 'I did', factor:"about", assess: "and it was", factorContext: 'wordActions', factorCategory: 'wordReasons', factorAssess: 'wordAssessments'},
        need: {text: 'I need to', factor:"due to", assess: "and have to solve", factorContext: 'wordActions', factorCategory: 'wordReasons',  factorAssess: 'wordAssessments'},
        want: {text: 'I want', factor:"because of", assess: "and should change", factorContext: 'wordActions', factorCategory: 'wordReasons',  factorAssess: 'wordSentiments'},
        conclude: {text: 'Important is', factor:"to be", assess: "and requires to", factorContext: 'wordReasons', factorCategory: 'wordAssessments',  factorAssess: 'wordActions'},
    },
    wordSentiments : {
        unhappy: {text: 'unhappy', sentiment: 'negative' },
        good: {text: 'good', sentiment: 'positive'},
        happy: {text: 'happy', sentiment: 'positive'},
        bad: {text: 'bad', sentiment: 'negative' },
        busy: {text: 'busy', sentiment: 'negative'},
        love:  {text: 'love', sentiment: 'neutral'},
        alerted: {text: 'alerted', sentiment: 'negative'},
        frustrated: {text: 'frustrated', sentiment: 'negative'},
        calm: {text: 'calm', sentiment: 'neutral'},
        tired: {text: 'tired', sentiment: 'negative'},
        curious: {text: 'curious', sentiment: 'neutral'},
    },
    wordActions : {
        work: {text: 'work', sentiment: 'neutral'},
        think: {text: 'think', sentiment: 'neutral'},
        speak: {text: 'speak', sentiment: 'neutral'},
        rest: {text: 'rest', sentiment: 'neutral'},
        meet:  {text: 'meet', sentiment: 'positive' },
        listen: {text: 'listen', sentiment: 'positive'},
        learn: {text: 'learn', sentiment: 'positive'},
        eat: {text: 'eat', sentiment: 'positive'},
        failing: {text: 'fail', sentiment: 'negative'},
        exercise: {text: 'exercise', sentiment: 'positive' },
        enjoy: {text: 'enjoy', sentiment: 'positive' },
        solve: {text: 'solve', sentiment: 'neutral'},
        plan: {text: 'plan', sentiment: 'neutral'},
        engage: {text: 'engage', sentiment: 'positive'},
        write: {text: 'write', sentiment: 'neutral'},
    },
    wordReasons : {
        work: {text: 'work', sentiment: 'positive'},
        challenges: {text: 'challenges', sentiment: 'negative'},
        leisure: {text: 'leisure', sentiment: 'positive'},
        schedule: {text: 'schedule', sentiment: 'neutral'},
        business: {text: 'business', sentiment: 'neutral'},
        communication: {text: 'communication', sentiment: 'neutral' },
        confidence: {text: 'confidence', sentiment: 'positive' },
        energy: {text: 'energy', sentiment: 'negative' },
        learning: {text: 'learning', sentiment: 'positive'},
    },
    wordAssessments : {
        successful: {text: 'successful', sentiment: 'positive'},
        healthy: {text: 'healthy', sentiment: 'neutral'},
        smart: {text: 'smart', sentiment: 'positive' },
        happy: {text: 'happy', sentiment: 'positive'},
        bad: {text: 'bad', sentiment: 'negative'},
        failing: {text: 'failing', sentiment: 'negative'},
        satisfying: {text: 'satisfying', sentiment: 'positive' },
        good: {text: 'good', sentiment: 'positive' },
        positive: {text: 'positive', sentiment: 'positive'},
        challenging: {text: 'challenging', sentiment: 'negative' },
        negative: {text: 'negative', sentiment: 'negative' },
        complete: {text: 'complete', sentiment: 'neutral' },
        impossible: {text: 'impossible', sentiment: 'negative'},
        incomplete: {text: 'incomplete', sentiment: 'negative' },
        motivating: {text: 'motivating', sentiment: 'positive' },
    }
}

class IconSentence extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);

        //var words = wordElements;

        var selectorContext = 'feel';
        if(this.props.selectorContext !== undefined && this.props.selectorContext!==""){
            if(Object.keys(wordElements.wordSettings).includes(this.props.selectorContext)){
                selectorContext = this.props.selectorContext;
            }
        }
        //console.log('started loading icons')

        this.state = {
            sentence: '',
            //sentiments: _.shuffle(wordSentiments), //array
            //reasons: _.shuffle(wordReasons), //array,=
            setIntents: _.shuffle(wordElements[wordElements.wordSettings[selectorContext].factorContext]), 
            setEntities: _.shuffle(wordElements[wordElements.wordSettings[selectorContext].factorCategory]), 
            setAssessments: _.shuffle(wordElements[wordElements.wordSettings[selectorContext].factorAssess]),
            wordSettings: wordElements.wordSettings,
            words: wordElements,
            selectorContext: selectorContext,
            contextExpanded: true,
            intentExpanded: true,
            entityExpanded: true,
            assessmentExpanded: true,
            choosenEntities:[]
        };
    }

    componentDidMount() {
        this._isMounted = true;
        this.loadNewWords();

    }
    componentWillUnmount() {
        this._isMounted = false;
    }

    loadContentSelectionWords = (contentSelection, newWords) => {
        //console.log('contentSelection',  contentSelection);
        let keys = [];
        var extrawordSentiments = {};
        var extrawordActions = {};
        var extrawordReasons = {};
        var extrawordAssessments = {};
        if(contentSelection!== undefined && contentSelection.length>0){
            contentSelection.map(contentItem => {
                var sentiment = 'neutral';
                var sentiments = [];
                var intents = [];
                if(contentItem.intentCategory !== undefined){
                    sentiments = Object.keys(contentItem.intentCategory);
                    //console.log('contentItem', sentiment, sentiments, contentItem)
                    if(sentiments.includes('failurereason') || sentiments.includes('challenge') ){
                        sentiment = 'negative';
                        intents = Object.keys(contentItem.intentTags);
                        intents.map(intent => {
                            if(!keys.includes(intent)){
                                keys.push(intent);
                                extrawordReasons[intent] = {text: intent, sentiment};
                                //console.log('setEntities', intent, type, sentiment, sentiments, contentItem)
                            }
                            return true;
                        })
                    }
                    else if(sentiments.includes('sentiment') || sentiments.includes('goal') ){
                        sentiment = 'negative';
                        intents = Object.keys(contentItem.intentTags);
                        intents.map(intent => {
                            if(!keys.includes(intent)){
                                keys.push(intent);
                                //extrawordSentiments[intent] = {text: intent, sentiment};
                                extrawordReasons[intent] = {text: intent, sentiment};
                                //console.log('setAssessments', intent, type, sentiment, sentiments, contentItem)
                            }
                            return true;
                        })
                    }
                    else if(sentiments.includes('accomplishment') || sentiments.includes('successreason') || sentiments.includes('question')){
                        sentiment = 'positive';
                        intents = Object.keys(contentItem.intentTags);
                        intents.map(intent => {
                            if(!keys.includes(intent)){
                                keys.push(intent);
                                //extrawordAssessments[intent] = {text: intent, sentiment};
                                extrawordReasons[intent] = {text: intent, sentiment};
                                //console.log('setAssessments', intent, type, sentiment, sentiments, contentItem)
                            }
                            return true;
                        })
                    }
                    else if(sentiments.includes('intention') || sentiments.includes('activity') ){
                        sentiment = 'positive';
                        intents = Object.keys(contentItem.intentTags);
                        intents.map(intent => {
                            if(!keys.includes(intent)){
                                keys.push(intent);
                                //extrawordActions[intent] = {text: intent, sentiment};
                                extrawordReasons[intent] = {text: intent, sentiment};
                                //console.log('setIntents', intent, type, sentiment, sentiments, contentItem)
                            }
                            return true;
                        })
                    }
                }
                /***
                if(contentItem.intentTags!==undefined){
                    var intents = Object.keys(contentItem.intentTags);
                    intents.map(intent => {
                        if(!keys.includes(intent)){
                            keys.push(intent);
                            results[intent] = {text: intent, sentiment};
                            //results.push({text: intent, sentiment});
                        }
                    })
                }
                 */
                return true;
            })
            let newContentWords = {
                //contentSelectionEntities: results,
                extrawordSentiments,
                extrawordActions: extrawordActions,
                extrawordReasons,
                extrawordAssessments
            };
            //console.log('newWordsnefore', newWords)
            var factorContext = newWords.wordSettings[this.state.selectorContext].factorContext;
            newWords[factorContext] = this.shuffleObject(newWords[factorContext]);
            //console.log('this.state[factorContext] ', factorContext, this.state)
            if(newContentWords['extra'+factorContext] !== undefined && Object.keys(newContentWords['extra'+factorContext]).length > 0){
                //console.log('this.state[factorContext] ', factorContext, newContentWords['extra'+factorContext] )
                Object.keys(newContentWords['extra'+factorContext]).map(key => {
                    if(newWords[factorContext][key] === undefined){
                        newWords[factorContext][key] = newContentWords['extra'+factorContext][key];
                        //delete newWords[factorContext][Object.keys(newWords[factorContext])[0]];
                    }
                    return true;
                })
                newWords[factorContext] = this.shuffleObject(newWords[factorContext]);
            }
            var factorCategory = newWords.wordSettings[this.state.selectorContext].factorCategory;
            newWords[factorCategory] = this.shuffleObject(newWords[factorCategory]);
            if(newContentWords['extra'+factorCategory] !== undefined && Object.keys(newContentWords['extra'+factorCategory]).length > 0){
                //console.log('this.state[factorCategory] ', factorCategory, newContentWords['extra'+factorCategory] )
                Object.keys(newContentWords['extra'+factorCategory]).map(key => {
                    if(newWords[factorCategory][key] === undefined){
                        newWords[factorCategory][key] = newContentWords['extra'+factorCategory][key];
                        //delete newWords[factorCategory][Object.keys(newWords[factorCategory])[0]];
                    }
                    return true;
                })
                newWords[factorCategory] = this.shuffleObject(newWords[factorCategory]);
            }
            var factorAssess = newWords.wordSettings[this.state.selectorContext].factorAssess;
            newWords[factorAssess] = this.shuffleObject(newWords[factorAssess]);
            if(newContentWords['extra'+factorAssess] !== undefined && Object.keys(newContentWords['extra'+factorAssess]).length > 0){
                //console.log('this.state[factorAssess] ', factorAssess, newContentWords['extra'+factorAssess] )
                Object.keys(newContentWords['extra'+factorAssess]).map(key => {
                    if(newWords[factorAssess][key] === undefined){
                        newWords[factorAssess][key] = newContentWords['extra'+factorAssess][key];
                        //delete newWords[factorAssess][Object.keys(newWords[factorAssess])[0]];
                    }
                    return true;
                })
                newWords[factorAssess] = this.shuffleObject(newWords[factorAssess]);
            }
            //console.log('extrawordSentiments', extrawordSentiments)
            //console.log('extrawordActions', extrawordActions)
            //console.log('extrawordReasons', extrawordReasons)
            //console.log('extrawordAssessments', extrawordAssessments)
            //console.log(' current', this.state)
            return newWords;
        }
        else{
            return newWords;
        }
    }

    loadNewWords() {
        var newWords = {};
        var elementCategories = ["default"];
        if(this.props.currentCategory !== undefined && this.props.currentCategory !== ""){
            elementCategories.push(this.props.currentCategory)
        }
        if(this.props.userId!== undefined && this.props.userId!==""){
            elementCategories.push(('xtra'+this.props.userId))
        }
        //console.log(elementCategories)
        var cacheStoreCategories = DataCacheRead('wordFilters')
        if(cacheStoreCategories.cacheAvailable && (!cacheStoreCategories.isCacheExpired)) {
            newWords = cacheStoreCategories.cacheData;
            newWords = this.loadContentSelectionWords(this.props.contentSelection, newWords);
            this.setState({
                words: newWords,
                wordSettings: newWords.wordSettings
            });
            //console.log('newWordsafter', newWords)
        }
        else{
            this.props.firebase
            .insightsWordsFilter()
            .where('leadership_category', 'in', elementCategories)
            .where('active', '==', true)
            .get()
            .then(snapshot => {
                if (snapshot.size) {
                    var allLoadedKeys = [];
                    var qualityCheck = false;
                    snapshot.forEach((doc, index) => {
                        var loadedWords = doc.data();
                        //console.log('loadedWords', loadedWords)
                        var wordKeys = Object.keys(loadedWords);
                        wordKeys.forEach((key) => {
                            if(key==='wordSettings'){
                                qualityCheck = true;
                            }
                            if(allLoadedKeys.includes(key)){
                                newWords[key] = _.assign(newWords[key], loadedWords[key]);
                                //console.log('adding', newWords[key]  )
                            }
                            else{
                                allLoadedKeys.push(key)
                                newWords[key] = loadedWords[key];
                                //console.log('new', newWords[key]  )
                            }
                        })
                        //console.log(allLoadedKeys)
                    })
                    if(qualityCheck && this._isMounted ){
                        try{
                            /***
                            var setIntents = wordElements[wordElements.wordSettings[this.state.selectorContext].factorContext];
                            if(newWords.wordSettings[this.state.selectorContext]!== undefined && newWords.wordSettings[this.state.selectorContext].factorContext!== undefined){
                                if(newWords[newWords.wordSettings[this.state.selectorContext].factorContext]!== undefined) 
                                    setIntents = newWords[newWords.wordSettings[this.state.selectorContext].factorContext];
                            }
                            var setEntities = wordElements[wordElements.wordSettings[this.state.selectorContext].factorCategory]; 
                            if(newWords.wordSettings[this.state.selectorContext]!== undefined && newWords.wordSettings[this.state.selectorContext].factorCategory!== undefined){
                                if(newWords[newWords.wordSettings[this.state.selectorContext].factorCategory]!== undefined) 
                                    setEntities = newWords[newWords.wordSettings[this.state.selectorContext].factorCategory];
                            }
                            var setAssessments = wordElements[wordElements.wordSettings[this.state.selectorContext].factorAssess]; 
                            if(newWords.wordSettings[this.state.selectorContext]!== undefined && newWords.wordSettings[this.state.selectorContext].factorAssess!== undefined){
                                if(newWords[newWords.wordSettings[this.state.selectorContext].factorAssess]!== undefined) 
                                    setAssessments = newWords[newWords.wordSettings[this.state.selectorContext].factorAssess];
                            }
                             */
                            DataCacheWrite('wordFilters', newWords, 1200)  
                            newWords = this.loadContentSelectionWords(this.props.contentSelection, newWords);
                            //console.log('newWordsafterUpdate', newWords)
                            this.setState({
                                words: newWords,
                                wordSettings: newWords.wordSettings,
                                //setIntents, 
                                //setEntities, 
                                //setAssessments,
                                //setIntents: newWords[newWords.wordSettings[this.state.selectorContext].factorContext], 
                                //setEntities: newWords[newWords.wordSettings[this.state.selectorContext].factorCategory], 
                                //setAssessments: newWords[newWords.wordSettings[this.state.selectorContext].factorAssess],
                            });
                        } catch (err) {
                            console.log('change topic err',err)
                        }
                    }
                    //console.log(newWords)
                }
            })
        }
    }

    shuffleObject(obj){
        // new obj to return
        let newObj = {};
        // create keys array
        var keys = Object.keys(obj);
        // randomize keys array
        keys.sort(function(a,b){return Math.random()- 0.5;});
        // save in new array
        keys.forEach(function(k) {
            newObj[k] = obj[k];
        });
        return newObj;
    }

    selectNewContext = (selectorContext ) => {
        if(selectorContext!== undefined && selectorContext!==""){
            //console.log('selectNewContext', selectorContext, this.state.wordSettings[selectorContext])
            try{
                var tempSentiments = this.state.tempSentiments || ['neutral'];
                if(this.state.wordSettings[selectorContext] !== undefined && this.state.wordSettings[selectorContext]['sentiment'] !== undefined){
                    tempSentiments.push(this.state.wordSettings[selectorContext]['sentiment'] );
                }
                //console.log('tempSentiments', tempSentiments)
                var setIntents = this.state.words[this.state.wordSettings[selectorContext].factorContext];
                var setEntities = this.state.words[this.state.wordSettings[selectorContext].factorCategory];
                var setAssessments = this.state.words[this.state.wordSettings[selectorContext].factorAssess]; 
                if(this.state.contentSelectionEntities!== undefined ){
                    //console.log('extraEntitySet2222', setEntities,this.state.contentSelectionEntities)
                    setEntities = this.state.contentSelectionEntities;
                    //console.log('setEntities', setEntities)
                }
                this.setState({
                    selectorContext: selectorContext,
                    setIntents: setIntents, 
                    setEntities: setEntities, 
                    setAssessments: setAssessments, 
                    choosenIntent: undefined , 
                    choosenEntities: [],
                    choosenAssessment: undefined,
                    clickedSelectorContext: true,
                    intentExpanded:true, 
                    contextExpanded:false,
                    entityExpanded:true,
                    assessmentExpanded: true,
                    tempSentiments
                }) 
                if (this.props.handleActivateSentence) {
                    this.props.handleActivateSentence(false);
                }
            } catch (err) {
                console.log('change topic err',err)
            }
        }
    }

    updateStateHandler = (dataSet, activate = true) => {
        //console.log('updateStateHandler', dataSet)
        var tempSentiments = this.state.tempSentiments || ['neutral'];
        if(dataSet['sentiment'] !== undefined && dataSet['sentiment'] !== null && dataSet['sentiment'] !== ''){
            tempSentiments.push(dataSet['sentiment'] );
            dataSet['tempSentiments'] = tempSentiments;
        }
        if (this.props.handleActivateSentence) {
            if(activate) this.props.handleActivateSentence(false)
            else this.props.handleActivateSentence(undefined)
        }
        this.setState(dataSet)
    }
/**
    shouldComponentUpdate(nextProps, nextState) {  
        if (this.state.contextExpanded) {  
            //console.log('no rerender')
            return false;  
        }  
        //console.log(' rerender')
        return true;  
    }
     */
    render () {
        const { theme, classes } = this.props;
        //console.log(this.state)
        return (
            <div>
                <Accordion key="factorContext"  id={`factorContext`}  
                    className={classes.hideBorder}
                    expanded={this.state.contextExpanded}
                >
                    <AccordionSummary
                        aria-controls="factorContext"
                        id="factorContext"
                        style={{minHeight:0, height: 0}}
                    >
                    </AccordionSummary>
                    <AccordionDetails  style={{padding:0, justifyContent: 'center'}}>
                        <div style={{textAlign: 'center'}}>
                            {Object.keys(this.state.wordSettings).map( (key, index) => (
                                <Button
                                    key={"but_"+key}
                                    style={{margin:5}}
                                    color={'primary'}
                                    //color={key === this.state.selectorContext ? 'secondary' : 'primary'}
                                    variant='text'
                                    //variant={key === this.state.selectorContext ? "contained" : "text"}
                                    onClick={ () => this.selectNewContext(key)  }
                                >
                                    <div style={{display: 'default'}}>
                                        <LanguageManager
                                            key={"but_"+key}
                                            text={this.state.wordSettings[key].text || ""}
                                            type='button'
                                            language={this.props.user?.features?.language}
                                            //handlerUpdate={(text) => {this.setState({['text_'+key]: text})}}
                                        />
                                    </div>
                                    {
                                    //this.state.wordSettings[key].text.length> 9 ? this.state.wordSettings[key].text.substring(0,9)+"..." :  this.state.wordSettings[key].text
                                    }
                                </Button>
                            ))}
                            {false
                            &&
                                <>
                                    <br/>
                                    <Button
                                            key={"but_yes"}
                                            style={{margin:5}}
                                            variant="outlined"
                                            color="secondary"
                                            onClick={() => {this.props.messageSend(this.state['buttonText_yes'] || "Yes")}}
                                        >
                                            <LanguageManager
                                                text={"Yes"}
                                                type='button'
                                                language={this.props.user?.features?.language}
                                                handlerUpdate={(text) => this.setState({['buttonText_yes'] : text})}
                                            />
                                    </Button>
                                    <Button
                                            key={"but_no"}
                                            style={{margin:5}}
                                            variant="outlined"
                                            color="secondary"
                                            onClick={() => {this.props.messageSend(this.state['buttonText_no'] || "No")}}
                                        >
                                        <LanguageManager
                                            text={"No"}
                                            type='button'
                                            language={this.props.user?.features?.language}
                                            handlerUpdate={(text) => this.setState({['buttonText_no'] : text})}
                                        />
                                    </Button>
                                    <Button
                                            key={"but_next"}
                                            style={{margin:5}}
                                            variant="outlined"
                                            color="secondary"
                                            onClick={() => {this.props.messageSend(this.state['buttonText_skip'] || "Skip")}}
                                        >
                                        <LanguageManager
                                            text={"Skip"}
                                            type='button'
                                            language={this.props.user?.features?.language}
                                            handlerUpdate={(text) => this.setState({['buttonText_skip'] : text})}
                                        />
                                    </Button>
                                </>
                            }
                        </div>
                    </AccordionDetails>
                </Accordion>
                <p style={theme.textCenterPadding}>
                    {this.state.clickedSelectorContext === true
                    &&
                        <LanguageManager
                            text={this.state.wordSettings[this.state.selectorContext].text}
                            type='button'
                            language={this.props.user?.features?.language}
                        />
                    }
                    {this.state.setIntents!== undefined && this.state.choosenIntent!==undefined && 
                        this.state.setIntents[this.state.choosenIntent] !==undefined  &&  this.state.setIntents[this.state.choosenIntent].sentiment !==undefined 
                            &&
                                <span key={"senti"} style={{ textDecoration: 'none',  display: 'inline-block' }}
                                    >
                                    <IconButton aria-label={'senti'} className={ 
                                            Object.keys(classes).includes( this.state.setIntents[this.state.choosenIntent].sentiment ) 
                                                ?   classes[this.state.setIntents[this.state.choosenIntent].sentiment]
                                                :   classes.neutral
                                            } >
                                        {
                                            IconPicker(this.state.choosenIntent)
                                            //this.state.setIntents[this.state.choosenIntent].icon
                                        }
                                    </IconButton> 
                                    <strong>
                                        <LanguageManager
                                            text={this.state.setIntents[this.state.choosenIntent].text}
                                            type='button'
                                            language={this.props.user?.features?.language}
                                            handlerUpdate={(text) => this.setState({['buttonText_' + this.state.setIntents[this.state.choosenIntent].text] : text})}
                                        />
                                    </strong>
                                </span>
                        }
                </p>
                {this.state.setIntents!== undefined && this.state.clickedSelectorContext === true
                &&
                    <Accordion key="groups_details1"  id={`groups-key1`}  
                        className={classes.hideBorder}
                        expanded={this.state.intentExpanded}>
                        <AccordionSummary
                            //expandIcon={this.state.intentExpanded? "" : <CancelIcon onClick={ () => this.setState({intentExpanded:true })} />}
                            aria-controls="groups-content"
                            id="groups"
                            style={{minHeight:10}}
                            >
                        </AccordionSummary>
                        <AccordionDetails  style={{padding:0}}>
                            <div>
                                {Object.keys(this.state.setIntents).map( (sentiment, index) => (
                                    this.state.setIntents[sentiment]!== undefined && this.state.setIntents[sentiment].sentiment !== undefined 
                                        &&
                                        <span key={"sen_"+sentiment} style={{ textDecoration: 'none',  display: 'inline-block' }}
                                            onClick={ () => this.updateStateHandler({choosenIntent: sentiment, intentExpanded:false, contextExpanded:false, sentiment: this.state.setIntents[sentiment].sentiment })}
                                        >
                                                <IconButton aria-label={index} className={ 
                                                        Object.keys(classes).includes( this.state.setIntents[sentiment].sentiment ) 
                                                            ? classes[this.state.setIntents[sentiment].sentiment]
                                                            : classes.neutral
                                                        }  >
                                                    {
                                                        IconPicker(sentiment)
                                                        //this.state.setIntents[sentiment].icon
                                                    }
                                                </IconButton> 
                                                <LanguageManager
                                                    text={this.state.setIntents[sentiment].text}
                                                    type='button'
                                                    language={this.props.user?.features?.language}
                                                />
                                        </span>
                                ))}
                                <span key={"sen_newitemshere"} style={{ textDecoration: 'none',  display: 'inline-block' }}
                                    onClick={ () => this.updateStateHandler({openAddText: true, openAddType: 'setIntents', openAddDescription: this.state.wordSettings[this.state.selectorContext].text  })}
                                >
                                    <IconButton aria-label={'extrasneit'} className={ classes.neutral }  > <CreateIcon/></IconButton> 
                                    <small>
                                        <LanguageManager
                                            text={"Add more"}
                                            type='button'
                                            language={this.props.user?.features?.language}
                                        />
                                    </small>
                                </span> 
                            </div>
                        </AccordionDetails>
                    </Accordion>
                }
                {this.state.choosenIntent!== undefined && this.state.intentExpanded!== true 
                &&
                    <p style={theme.textCenterPadding}>
                        <LanguageManager
                            text={this.state.wordSettings[this.state.selectorContext].factor}
                            type='button'
                            language={this.props.user?.features?.language}
                        />
                        {this.state.entityExpanded? "..." : ""}
                        {!this.state.entityExpanded &&  this.state.choosenEntities!==undefined && 
                                this.state.choosenEntities.map( (item, index) => (
                                    this.state.setEntities[item] !==undefined  &&  this.state.setEntities[item].sentiment !==undefined 
                                    &&
                                    <span key={"senti"} style={{ textDecoration: 'none',  display: 'inline-block' }}
                                        >
                                        <IconButton aria-label={'senti'} className={ 
                                                Object.keys(classes).includes( this.state.setEntities[item].sentiment ) 
                                                    ? classes[this.state.setEntities[item].sentiment]
                                                    : classes.neutral
                                                }  >
                                            {
                                                IconPicker(item)
                                                //this.state.setEntities[item].icon
                                            }
                                        </IconButton> 
                                        <strong>
                                            <LanguageManager
                                                text={this.state.setEntities[item].text}
                                                type='button'
                                                language={this.props.user?.features?.language}
                                            />
                                        </strong>
                                    </span>
                                ))
                            }
                    </p>
                }
                {this.state.choosenIntent!== undefined && this.state.intentExpanded!== true
                &&
                    <Accordion key="reason"  id={`reason`}  
                        className={classes.hideBorder}
                        //expanded={true}
                        expanded={this.state.entityExpanded}
                        >

                        <AccordionSummary
                            //expandIcon={this.state.entityExpanded? "" : <CancelIcon onClick={ () => this.setState({entityExpanded:true, choosenEntities:[] })} />}
                            aria-controls="reason-content"
                            id="reason"
                            style={{minHeight:10}}
                            >
                        </AccordionSummary>
                        <AccordionDetails  style={{padding:0}}>
                            <div>
                                {Object.keys(this.state.setEntities).map( (reason, index) => (
                                    this.state.setEntities[reason] !==undefined  &&  this.state.setEntities[reason].sentiment !==undefined 
                                    &&
                                    <span key={"rea_"+reason} style={{ textDecoration: 'none',  display: 'inline-block' }}
                                        onClick={ () => this.updateStateHandler({choosenEntities: [...this.state.choosenEntities , reason], entityExpanded:false , sentiment: this.state.setEntities[reason].sentiment})}
                                        >
                                            <IconButton aria-label={index} className={ 
                                                Object.keys(classes).includes( this.state.setEntities[reason].sentiment ) 
                                                    ? classes[this.state.setEntities[reason].sentiment]
                                                    : classes.neutral
                                                }  >
                                                {
                                                    IconPicker(reason)
                                                }
                                            </IconButton>
                                            <LanguageManager
                                                text={this.state.setEntities[reason].text}
                                                type='button'
                                                language={this.props.user?.features?.language}
                                            />
                                    </span>
                                ))}
                                <span key={"ent_newitemshere"} style={{ textDecoration: 'none',  display: 'inline-block' }}
                                    onClick={ () => this.updateStateHandler({openAddText: true, openAddType: 'setEntities', openAddDescription: this.state.wordSettings[this.state.selectorContext].factor  })}
                                >
                                    <IconButton aria-label={'rntrtrntr'} className={ classes.neutral }  > <CreateIcon/></IconButton> 
                                        <small>
                                            <LanguageManager
                                                text={"Add more"}
                                                type='button'
                                                language={this.props.user?.features?.language}
                                            />
                                        </small>
                                </span> 
                            </div>
                        </AccordionDetails>
                    </Accordion>

                }
                {this.state.entityExpanded!== true && this.state.intentExpanded!== true
                &&
                    <p style={theme.textCenterPadding}>
                        <LanguageManager
                            text={this.state.wordSettings[this.state.selectorContext].assess}
                            type='button'
                            language={this.props.user?.features?.language}
                        />
                        {this.state.assessmentExpanded? "..." : ""}
                        {!this.state.assessmentExpanded &&  this.state.choosenAssessment!==undefined && 
                                    <span key={"senti"} style={{ textDecoration: 'none',  display: 'inline-block' }}
                                        >
                                        <IconButton aria-label={'senti'} className={ 
                                                Object.keys(classes).includes( this.state.setAssessments[this.state.choosenAssessment].sentiment ) 
                                                    ? classes[this.state.setAssessments[this.state.choosenAssessment].sentiment]
                                                    : classes.neutral
                                                }  >
                                            {
                                                IconPicker(this.state.choosenAssessment)
                                                //this.state.setAssessments[this.state.choosenAssessment].icon
                                            }
                                        </IconButton> 
                                        <strong>
                                            <LanguageManager
                                                text={this.state.setAssessments[this.state.choosenAssessment].text}
                                                type='button'
                                                language={this.props.user?.features?.language}
                                            />
                                        </strong>
                                    </span>
                            }
                    </p>
                }
                {this.state.entityExpanded!== true && this.state.intentExpanded!== true
                &&
                    <Accordion key="assess"  id={`assess`}  
                        className={classes.hideBorder}
                        //expanded={true}
                        expanded={this.state.assessmentExpanded}
                        >

                        <AccordionSummary
                            aria-controls="assess-content"
                            id="assess"
                            style={{minHeight:10}}
                            >
                        </AccordionSummary>
                        <AccordionDetails style={{padding:0}}>
                            <div>
                                {Object.keys(this.state.setAssessments).map( (key, index) => (
                                    this.state.setAssessments[key] !==undefined  &&  this.state.setAssessments[key].sentiment !== undefined 
                                    &&
                                    <span key={"rea_"+key} style={{ textDecoration: 'none',  display: 'inline-block' }}
                                        onClick={ () => this.updateStateHandler({choosenAssessment: key, assessmentExpanded:false, sentiment: this.state.setAssessments[key].sentiment })}
                                        >
                                        {true && (this.state.tempSentiments === undefined || this.state.tempSentiments === [] || this.state.tempSentiments.includes(this.state.setAssessments[key].sentiment)
                                            || this.state.tempSentiments.includes('neutral'))
                                            ?
                                                <>
                                                    <IconButton aria-label={index} className={ 
                                                        Object.keys(classes).includes( this.state.setAssessments[key].sentiment ) 
                                                            ? classes[this.state.setAssessments[key].sentiment]
                                                            : classes.neutral
                                                        }  >
                                                        {
                                                            IconPicker(key)
                                                        }
                                                    </IconButton> 
                                                    <LanguageManager
                                                        text={this.state.setAssessments[key].text}
                                                        type='button'
                                                        language={this.props.user?.features?.language}
                                                    />
                                                </>
                                            :   ""
                                        }
                                    </span>
                                ))}
                                <span key={"asssess_newitemshere"} style={{ textDecoration: 'none',  display: 'inline-block' }}
                                    onClick={ () => this.updateStateHandler({openAddText: true, openAddType: 'setAssessments', openAddDescription: this.state.wordSettings[this.state.selectorContext].assess  })}
                                >
                                    <IconButton aria-label={'rntrasssesstrntr'} className={ classes.neutral }  > <CreateIcon/></IconButton> 
                                        <small>
                                            <LanguageManager
                                                text={"Add more"}
                                                type='button'
                                                language={this.props.user?.features?.language}
                                            />
                                        </small>
                                </span> 
                            </div>
                        </AccordionDetails>
                    </Accordion>

                }
                {this.state.contextExpanded!== true
                &&
                    <Button
                        onClick = {() => this.updateStateHandler({
                            contextExpanded:true,
                            choosenIntent: undefined , 
                            choosenEntities: [],
                            choosenAssessment: undefined,
                            intentExpanded:true, 
                            entityExpanded:true,
                            assessmentExpanded: true,
                            clickedSelectorContext: false,
                        }, false)}
                        variant="outlined"
                        color="secondary"
                    >
                        <LanguageManager
                            text={"Restart"}
                            type='button'
                            language={this.props.user?.features?.language}
                        />
                    </Button>
                }
                {this.state.clickedSelectorContext === true
                &&
                    <div style={{textAlign: 'center', margin: 30, fontWeight: 'bold'}}>
                        <LanguageManager
                            text={this.buildText()}
                            type='text'
                            forceEnglish={true}
                            language={this.props.user?.features?.language}
                            handlerUpdate={(text) => this.setState({inputText: text})}
                        />
                    </div>
                }
                <div className={classes.fabRow} >
                    {this.state.choosenIntent!== undefined && this.state.intentExpanded!== true
                    &&
                        <Fab
                            onClick={() => {this.sendText(this.state.inputText || undefined)}}
                            //variant="contained"
                            variant="extended"
                            //color="secondary"
                            size="medium"
                            style={{...theme.buttonRight, backgroundColor: theme.palette.secondary.main, color: 'white'}}
                        >
                            <SendIcon fontSize={'small'} style={{color:theme.palette.themeRed}} />
                            &nbsp;
                            <LanguageManager
                                text={"Capture"}
                                type='button'
                                language={this.props.user?.features?.language}
                            />
                        </Fab>
                    }
                </div>

                <RemoveScroll enabled={this.state.openAddText || false }>
                    <Dialog
                        key={'openaddtext'}
                        open={this.state.openAddText || false}
                        onClose={() => this.setState({openAddText: false})}
                        fullScreen={IsMobileDevice()}
                        PaperProps={{style:theme.dialogWindow}}
                    >
                        <DialogTitle style={theme.dialogTitle}  >
                            <Grid container spacing={0} direction="row"
                                justifyContent="space-between"
                                alignItems="center">
                                <Grid item >
                                    <IconButton onClick={() =>  {this.setState({openAddText: false})} }>
                                        <ArrowBackIcon />
                                    </IconButton>
                                </Grid>
                                <Grid item>
                                    <span style={theme.dialogTitle.titleText}>
                                        Add personal word
                                    </span>
                                </Grid>
                                <Grid item>
                                    <span style={theme.dialogTitle.titleLink} onClick={() =>  {this.setState({openAddText: false})} }>
                                        {"Cancel"}
                                    </span>
                                </Grid>
                            </Grid>
                        </DialogTitle>
                        {this.state.openAddText &&
                            <DialogContent style={{...theme.dialogContent}}>
                                <strong>
                                    {this.state.openAddDescription!== undefined && this.state.openAddDescription !== ""? this.state.openAddDescription : "new word"}:<br/><br/>
                                </strong>
                                <TextField
                                    fullWidth
                                    id="personalWord"
                                    style={ {fontSize: '16px'} }
                                    //label="Personal word"
                                    type="text"
                                    variant="outlined"
                                    placeholder="manage"
                                    defaultValue={this.state.personalWord || ""}
                                    onChange={(event) => this.setState({ personalWord: event.target.value }) }
                                /><br/><br/>
                                <Grid item xs={12}>
                                    {this.state.personalWord !== undefined && this.state.personalWord.length>=3 &&
                                        <Button onClick={() => {this.saveAddWord()}} style={theme.buttonRight}>
                                            Save
                                        </Button>
                                    }
                                </Grid>
                            </DialogContent>
                        }
                    </Dialog>
                </RemoveScroll>
            </div>
        )
    }

    saveAddWord = () => {
        //console.log(this.state.openAddType, this.state.personalWord, this.props.userId)
        if(this.state.personalWord!== undefined && this.state.personalWord!=="" ){
            if (this.props.userId!== undefined){
                var word = this.state.personalWord.toLowerCase();
                word = word.replace(/[^\w\s]/gi, '')
                word = word.replace(/\s/g,"_")
                var newItemWord = {text: this.state.personalWord.toLowerCase(), sentiment: 'normal'};
                var workingDict = {};
                var myWordGroup = 'wordReasons';
                if(this.state.openAddType === 'setIntents'){
                    workingDict = this.state.setIntents;
                    workingDict[word] = newItemWord;
                    myWordGroup = this.state.words.wordSettings[this.state.selectorContext].factorContext;
                    //console.log(word, workingDict)
                    this.setState({
                        setIntents: workingDict, 
                        choosenIntent: word, 
                        intentExpanded:false, 
                        contextExpanded:false, 
                        openAddText: false 
                    })
                }
                else if(this.state.openAddType === 'setEntities'){
                    workingDict = this.state.setEntities;
                    workingDict[word] = newItemWord;
                    myWordGroup = this.state.words.wordSettings[this.state.selectorContext].factorCategory;
                    //console.log(word, workingDict)
                    this.setState({
                        setEntities: workingDict, 
                        choosenEntities: [...this.state.choosenEntities , word], 
                        entityExpanded:false, 
                        openAddText: false 
                    })
                }
                else if(this.state.openAddType === 'setAssessments'){
                    workingDict = this.state.setAssessments;
                    workingDict[word] = newItemWord;
                    myWordGroup = this.state.words.wordSettings[this.state.selectorContext].factorAssess;
                    //console.log(word, workingDict)
                    this.setState({
                        setAssessments: workingDict, 
                        choosenAssessment: word, 
                        assessmentExpanded:false, 
                        openAddText: false 
                    })
                }
                else {
                    workingDict = this.state.setEntities;
                    workingDict[word] = newItemWord;
                    myWordGroup = this.state.words.wordSettings[this.state.selectorContext].factorCategory;
                    //console.log(word, workingDict)
                    this.setState({
                        setEntities: workingDict, 
                        choosenEntities: [...this.state.choosenEntities , word], 
                        entityExpanded:false, 
                        openAddText: false 
                    })
                }
                var updateSet = {
                    leadership_category: 'xtra'+this.props.userId, 
                    active: true,
                    [myWordGroup] : {[word]: newItemWord}
                }
                this.props.firebase.insightsWordsFilter().doc('xtra'+this.props.userId).set(updateSet, {merge:true })
                DataCacheWrite('wordFilters',this.state.words, 1)  
                //console.log(this.state)
            }
            else {
                this.setState({errorMessage: 'Thank you for the new word, soon it will be available.'})
            }
        }
    }

    buildText(){
        var text = "I feel";
        try{
            if(this.state.wordSettings!== undefined && this.state.selectorContext!== undefined){
                text = this.state.wordSettings[this.state.selectorContext].text
            }
            if(this.state.setIntents!== undefined && this.state.choosenIntent!==undefined){
                text = text + " " + this.state.setIntents[this.state.choosenIntent].text;
            }
            if(this.state.setEntities!== undefined && this.state.choosenEntities!==undefined){
                if(this.state.choosenEntities.length > 0){
                    if(this.state.wordSettings!== undefined && this.state.selectorContext!== undefined){
                        text = text + " " + this.state.wordSettings[this.state.selectorContext].factor;
                    }
                    else{
                        text = text + " due to";
                    }
                }
                if(this.state.choosenEntities.length > 0){
                    this.state.choosenEntities.map( (item, index) =>{
                        if(index === 0) text = text + " " + this.state.setEntities[item].text;
                        else text = text + " and " + this.state.setEntities[item].text;
                        return text;
                    })
                }
            }
            if(this.state.setAssessments!== undefined && this.state.choosenAssessment!==undefined){
                if(this.state.wordSettings!== undefined && this.state.selectorContext!== undefined){
                    text = text + " " + this.state.wordSettings[this.state.selectorContext].assess;
                }
                text = text + " " + this.state.setAssessments[this.state.choosenAssessment].text;
            }
        }
        catch (err) {text = text + ".."}
        return text
    }

    sendText = async (textReady='') => {
        if(this._isMounted){
            var text = "I feel";
            if(textReady!=='' && textReady.length>=1){
                text = textReady;
            }
            else{
                text = this.buildText()
            }
            if (this.props.handleActivateSentence) {
                this.props.handleActivateSentence(undefined);
            }
            this.props.handlerMessageSubmit(text+".");
            if (false){
                try{
                    GetAlternativeRewriteArrayRockyAI(text+".", 1, "", true)
                    .then(result => {
                        console.log('result', result)
                        this.props.handlerMessageSubmit(result[0]);
                    })
                }
                catch(error){
                }
            }
        }
    };

}




export default compose(
    withFirebase,
    withStyles(styles, { withTheme: true}),
)(IconSentence);
