import React, { Component } from 'react';
import { compose } from 'recompose';
import { Link } from 'react-router-dom';
import { withFirebase } from '../Firebase';
import { AuthUserContext } from '../Session';
import withStyles from '@material-ui/core/styles/withStyles';
import {CheckXPLevel} from '../Charts/XPLevels';

import LeadershipQualityPicker from './LeadershipQualityPicker';
import DialogChangeGoal from './DialogChangeGoal';
import DialogAssessQuality from '../Onboarding/DialogAssessQuality';
import { FindTextValueInDict, FindDictByKey } from '../Modules/LoadCategoriesAndQualities';
import SnackbarInfo from '../Modules/SnackbarInfo';
import BadgeCard from '../Modules/BadgeCard';
import imageVisionBackground from '../../images/satisfied5.png';
//import DialogAssessment from '../Assessments/DialogAssessment';

//import Accordion from '@material-ui/core/Accordion';
//import AccordionSummary from '@material-ui/core/AccordionSummary'
//import AccordionDetails from '@material-ui/core/AccordionActions';
//import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import imageSettingGoal from "../../images/settinggoal.png";
import imageSettingQuality from "../../images/settingquality.png";
// HN - module for AvatarImage replaces the Avatar Object
import AvatarImage from '../Modules/AvatarImage';
import Card from '@material-ui/core/Card';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Grid from '@material-ui/core/Grid';

import Button from '@material-ui/core/Button';

import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import CircularProgress from '@material-ui/core/CircularProgress';
//import AssistantIcon from '@material-ui/icons/Assistant';
//import * as ROUTES from '../../constants/routes';
//import { Link } from 'react-router-dom';

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
});

class GoalOverview extends Component {
    static contextType = AuthUserContext;
    _isMounted = false;

    constructor(props) {
        super(props);
        var assessmentResults = undefined;
        if(this.props.assessmentResults !== undefined){
            assessmentResults = this.props.assessmentResults ;
        }
        var chosenCategory = '';
        if(this.context !== undefined){
            chosenCategory = this.context.currentCategory;
        }
        this.state = {
            assessmentResults,
            openLeadershipQualityPicker: false,
            openLeadershipQualityPicker2: false,
            todaysLeadershipQuality: "",
            currentAchievementGoal: this.props.user.achievementGoal,
            scope: 'current',
            chosenCategory,
            quiz:false,
            openCurrentAchievementGoal:false,
        };
    }

    componentDidMount() {
        this._isMounted = true;
        var quiz = false;
        var openLeadershipQualityPicker = false ;
        var openCurrentAchievementGoal = false;
        var openLeadershipQualityPicker2 = false;
        var chosenCategory = this.state.chosenCategory || '';
        if (this.props.openDialogue) {
            //console.log('dialogue open')
            if(this.context.roles!==undefined && !this.context.roles?.includes("COACHEE")){
                if(this.props.features !== undefined && this.props.features.includes('quality')) {
                    if (this.context.programs!==undefined && this.context.programs.includes("default")) {
                        quiz = true;
                        //console.log('quiz open')
                    }
                    else{
                        openLeadershipQualityPicker = true;
                        //console.log('picker open')
                    }
                }
                if(this.props.features !== undefined && this.props.features.includes('goal')) {openCurrentAchievementGoal = true};
            }
        }
        else if(this.props.openProgramSelection){
            openLeadershipQualityPicker2 = true;
            if(this.context !== undefined){
                chosenCategory = this.context.currentCategory;
            }
        }
        this.setState({
            user: this.props.user,
            loading: false,
            quiz,
            chosenCategory,
            openLeadershipQualityPicker,
            openLeadershipQualityPicker2,
            openCurrentAchievementGoal,
        });        
        const xpLevels = {
            modules: CheckXPLevel(this.props.user, 'modules'),
        }
        this.setState({xpLevels});
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidUpdate(prevProps){
        if(this.props.features?.includes('quality') && this.props?.openDialogue!==undefined && ((this.props.openDialogue !== this.state.openCheck) ) ){
            //console.log('state updatesss')
            if(this._isMounted) {
                if ((this.state.quiz !== true) && this.context.programs!==undefined && this.context.programs.includes("default")) {
                    this.setState({ quiz : this.props.openDialogue, openCheck: this.props.openDialogue})
                    //console.log('quiz state updated open')
                }
                else if (this.state.openLeadershipQualityPicker !== true){
                    this.setState({ openLeadershipQualityPicker : this.props.openDialogue, openCheck: this.props.openDialogue})
                    //console.log('picker state open')
                }
            }
        }
        if(this.props.openProgramSelection === true && prevProps.openProgramSelection !== true && this.state.openLeadershipQualityPicker2 !== true){
            var chosenCategory = this.context?.currentCategory;
            this.setState({
                chosenCategory,
                openLeadershipQualityPicker2: true,
                openLeadershipQualityPicker: false
            }); 
        }
    }
  
    // Leadership Picker Handling
    handleLeadershipQualityPickerOpen = (scope) => {
        if(this.state.xpLevels?.modules?.valid){
            this.setState({
                scope: scope,
                openLeadershipQualityPicker: true,
            })
        }
        else if(this.context.roles?.includes("COACHEE")){
            //console.log("nononon ")
            this.setState({ 
                alertCoacheeText: "The module is set by an assigned Coach", 
                alertCoacheeQuality: true
            })
        }
        else{
            this.setState({
                scope: scope,
                openLeadershipQualityPicker: true,
            })
        }
    }

    handleLeadershipQualityPickerOpen2 = (entry) => {
        //console.log('new things', entry);
        this.setState({
            chosenCategory: entry
        }, () => {
            //console.log('nupdaterew things', entry);
            this.setState({
                openLeadershipQualityPicker: false,
                openLeadershipQualityPicker2: true,
            })}
        );
    }

    handleLeadershipQualityPickerClose = () => {
        //console.log('props', this.props)
        if(this._isMounted){
            this.setState({
                openCheck: false,
                activateChangeButtons: false,
                openLeadershipQualityPicker: false,
                openLeadershipQualityPicker2: false,
            });
        }
        if (this.props.closeDialog !== undefined ) this.props.closeDialog();
    }

    handleLeadershipQualityPickerClose2= () => {
        if(this._isMounted){
            //console.log('handler has been opened')
            this.setState({
                openLeadershipQualityPicker: true,
                openLeadershipQualityPicker2: false,
            });
        }
    }

    handleCloseDialog = () => {
        this.setState({activateChangeButtons: false, openCheck: false})
        if (this.props.closeDialog !== undefined ) this.props.closeDialog();
    }
    handleLeadershipQualityPickerCloseAll = () => {
        if(this._isMounted){
            this.setState({
                openCheck: false,
                activateChangeButtons: false,
                openLeadershipQualityPicker: false,
                openLeadershipQualityPicker2: false,
            });
        }
        if (this.props.closeDialog !== undefined ) this.props.closeDialog();
    }

    // Current Achievement Goal Handling
    handleCurrentAchievementGoalOpen = () =>  {
        if(this._isMounted){
            if(this.context.roles?.includes("COACHEE")){
                //console.log('goal no too')
                this.setState({ 
                    alertCoacheeText: "Your north-star-goal is set by your assigned coach, please contact your coach if you want to change your vision under ... More > Support.", 
                    alertCoacheeGoal: true
                })
            }
            else{
                this.setState({
                    openCurrentAchievementGoal: true,
                    // currentAchievementGoal: this.state.currentAchievementGoal ? this.state.currentAchievementGoal : this.state.user.currentAchievementGoal,
                });
            }
        }
    }

    handleCurrentAchievementGoalClose = () => {
        if(this._isMounted){
            this.setState({
                activateChangeButtons: false,
                openCurrentAchievementGoal: false,
            });
        }
        if(this.props.closeDialogNext!== undefined ) this.props.closeDialogNext();
    }

    render() {
        const { theme, features } = this.props;
        var bgColor= theme.palette.themeBlue;
        var todayBgColor= theme.palette.themeGrey;
        var icon = undefined;
        var todayIcon = undefined;
        var selectorCurrentLeadershipQuality = "";
        var sisterQualities = []
        if(this.context !== undefined && this.context.currentLeadershipQuality!== undefined){
            selectorCurrentLeadershipQuality = this.context.currentLeadershipQuality
        }
        else if(this.props.user !== undefined ) {
            selectorCurrentLeadershipQuality = this.props.user.currentLeadershipQuality
        }
        try{
            const qualityDict = FindDictByKey(this.props.firebase, this.props.leadershipQualities, selectorCurrentLeadershipQuality);
            if(qualityDict.sisterQualities !== undefined && qualityDict.sisterQualities.length > 0){
                sisterQualities = qualityDict.sisterQualities;
            }
            if(qualityDict.icon !== undefined && qualityDict.icon !== ''){
                icon = qualityDict.icon;
            }
            if(qualityDict.backgroundColor !== undefined && qualityDict.backgroundColor !== ''){
                bgColor = qualityDict.backgroundColor;
            }
            //console.log('try:', icon);
        } catch (e){
            icon = undefined;
            //console.log('error:', icon);
        }
        try{
            const todayDict = FindDictByKey(this.props.firebase, this.props.leadershipQualities, this.props.user.todaysLeadershipQuality);
            if(todayDict.icon !== undefined && todayDict.icon !== ''){
                todayIcon = todayDict.icon;
            }
            if(todayDict.backgroundColor !== undefined && todayDict.backgroundColor !== ''){
                todayBgColor = todayDict.backgroundColor;
            }
        } catch (e){
            //console.log('error:', icon);
        }
        //console.log('state', this.state)
        return (
            <div style={{
                ...theme.cardSettings, 
                overflowX:'hidden', 
                paddingLeft: this.props.type==='quick'? 0 : 20 , 
                paddingRight: this.props.type==='quick'? 0 : 20,
                }}>
                {(features === undefined || features.includes('goal')) &&
                    <>
                        {this.props.showProgramlook
                            ?
                                <BadgeCard
                                    backgroundColor = {"white"}
                                    backgroundImage = {this.state.backgroundImage || imageVisionBackground}
                                    image={this.state.backgroundIcon || imageSettingGoal} 
                                    avatarImage = { undefined  }
                                    //color={theme.palette.themeAltGold}
                                    //title = {"My Vision"}
                                    subtitle = {
                                        (this.props.user.achievementGoal !== undefined && this.props.user.achievementGoal.length > 89)
                                            ?   (this.props.user.achievementGoal.substring(0,87) + "...")
                                            :   this.props.user.achievementGoal 
                                    }
                                    //subtitle = {}
                                    strongSubtitle = {false}
                                    shadowText={true}
                                    onClick={ () => {
                                        if(this.context.roles?.includes("COACHEE")){ void(0)}
                                        else{this.setState({openCurrentAchievementGoal : true})}
                                        //else{ this.props.history.push(ROUTES.PROFILE) }
                                    } }
                                    clickText= {this.context.roles?.includes("COACHEE")
                                        ?   undefined
                                        :   "Change"
                                    }
                                />
                            :
                                <Card style={{
                                    ...theme.CardDialogueButton,
                                    boxShadow: 'none',
                                    width: '100%',
                                    background: `url(${imageVisionBackground})`,
                                    backgroundColor: undefined, // else it has conflict with two settings
                                    backgroundPosition: "center center",
                                    backgroundSize: "cover",
                                    backgroundRepeat: "no-repeat",
                                    }}
                                    onClick={() => this.handleCurrentAchievementGoalOpen()}
                                >
                                    <div style={{
                                        position:'relative',
                                        top:0,
                                        right:10,
                                        height:0,
                                        textAlign: 'right',
                                        zIndex: 2,
                                        width: '100%',
                                        color: theme.palette.secondary.main
                                    }}
                                        >
                                        Change
                                    </div>
                                    <Grid container direction="row" justifyContent={this.props.type==='quick'?"center":'flex-start'} alignItems="center"  spacing={1}>
                                        <Grid item > 
                                            <img src={imageSettingGoal} alt='North Star Goal' style={theme.cardSettings.iconDesign} />
                                        </Grid>
                                        <Grid item > 
                                            <strong
                                                style={{ 
                                                    padding:10,
                                                    textAlign: 'center',
                                                    color: theme.palette.black,
                                                    textShadow: " 0.07em 0 white,  0 0.07em white, -0.07em 0 white, 0 -0.07em white"
                                                }}
                                            >
                                                My Vision
                                            </strong>
                                        </Grid>
                                        <Grid item > 
                                        </Grid>
                                    </Grid>
                                    <div 
                                        style={{ 
                                            padding:10,
                                            textAlign: 'center',
                                            color: theme.palette.black,
                                            textShadow: "0.07em 0 white,  0 0.07em white, -0.07em 0 white, 0 -0.07em white"
                                        }} 
                                        onClick={() => this.handleCurrentAchievementGoalOpen()} 
                                    >
                                        <strong >
                                            <q>{this.props.user.achievementGoal}</q>
                                        </strong> 
                                    </div>
                                </Card>
                        }
                    </>
                }
                {this.props.leadershipQualities?.length > 0 
                ?
                    (features === undefined || features.includes('quality')) &&
                    <div>
                        <Grid container direction="row" justifyContent={this.props.type==='quick'?"center":'flex-start'}  alignItems="center"  spacing={1}>
                            <Grid item > 
                                <img src={imageSettingQuality} alt='My Current Focus' style={theme.cardSettings.iconDesign} />
                            </Grid>
                            <Grid item > 
                                <strong>Today's Quest</strong>
                            </Grid>
                        </Grid>
                        {(features !== undefined && features.length === 1)
                        ? 
                            <div style={{marginLeft:10, marginRight:10, color: theme.palette.secondary.main}}>
                                <BadgeCard
                                    backgroundColor ={"transparent"}
                                    image={undefined} 
                                    avatarImage = {
                                        bgColor !== undefined
                                        ?
                                            (icon!== undefined && icon!== "")
                                                ?
                                                    <AvatarImage type='imageIcon' 
                                                        key={selectorCurrentLeadershipQuality} image={icon}
                                                        vertical-align="middle" squareSize={"auto"}  backgroundColor={bgColor} />
                                                :
                                                    <AvatarImage type='quality' 
                                                        key={selectorCurrentLeadershipQuality} 
                                                        vertical-align="middle" item={selectorCurrentLeadershipQuality} 
                                                        squareSize={"auto"} backgroundColor={bgColor} />
                                        :
                                            undefined
                                        }
                                    title = {
                                        FindTextValueInDict(this.props.firebase, this.props.leadershipCategories, this.props.user.currentCategory)
                                    }
                                    subtitle = {
                                        FindTextValueInDict(this.props.firebase, this.props.leadershipQualities, selectorCurrentLeadershipQuality)
                                    }
                                    strongSubtitle = {true}
                                    onClick={ 
                                        (this.context.roles!== undefined && !this.context.roles?.includes("COACHEE")
                                        && this.state.activateChangeButtons !== true)
                                        ?   () => this.handleLeadershipQualityPickerOpen2(this.context.currentCategory)
                                            //() => this.setState({activateChangeButtons : true})
                                        :   undefined 
                                    }
                                    clickText= {undefined}
                                />
                                {false && !this.state.activateChangeButtons &&
                                    sisterQualities !== undefined && sisterQualities.length > 0 &&
                                    sisterQualities.map((item, index) => {
                                        if(index<2) return(
                                            <span key={item + "_" + index}>
                                                {index > 0 ? '-' : ''}
                                                &nbsp;
                                                <Link to="#"
                                                    onClick={() => this.setCurrentQuality(item)}
                                                    >
                                                    {
                                                        FindTextValueInDict(this.props.firebase, this.props.leadershipQualities, item)
                                                    }
                                                </Link>
                                                &nbsp;
                                            </span>
                                        )
                                        else return ""
                                    })
                                }
                            </div>
                        : 
                            <div onClick={() => {this.handleLeadershipQualityPickerOpen('current')}} style={{display:"inline-block", width:'100%', marginLeft:'auto',marginRight:0}}>
                                <Grid container direction="row" alignItems="center" >
                                    <Grid item xs={2}  > 
                                            {icon!== undefined && icon!== ""
                                                ?
                                                    <AvatarImage key={selectorCurrentLeadershipQuality}  type='imageIcon' image={icon} squareSize='60'  backgroundColor={bgColor} />
                                                :
                                                    <AvatarImage type='quality' item={selectorCurrentLeadershipQuality}  squareSize='60' backgroundColor={bgColor}/>
                                            }
                                    </Grid>
                                    <Grid item xs={8} style={{padding:10}} >
                                        <span style={theme.textSettings.settingsTextDescription}>
                                            { 
                                                FindTextValueInDict(this.props.firebase, this.props.leadershipCategories, this.props.user.currentCategory)
                                            }
                                            {" / "}
                                        </span>
                                        <span>
                                            {FindTextValueInDict(this.props.firebase, this.props.leadershipQualities, selectorCurrentLeadershipQuality)}
                                        </span>
                                    </Grid>
                                    <Grid item xs={2}  > 
                                        <div style={{fontSize:"small", color: theme.palette.secondary.main, textAlign:'center', marginRight:10}} 
                                            onClick={() => {this.handleLeadershipQualityPickerOpen('current')}} >
                                            <IconButton color="secondary"  style={{padding: 2}}>
                                                <EditIcon fontSize="small" style={{color: theme.palette.secondary.main}}/>
                                            </IconButton>
                                            <br/>Change 
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        }
                        { (features !== undefined && features.length === 1)
                            && 
                            <Grid container direction="row" justifyContent="space-between" alignItems="center"  spacing={1}>
                                <Grid item > 
                                  
                                </Grid>
                                <Grid item > 
                                    {(this.context.roles!== undefined && !this.context.roles?.includes("COACHEE")) 
                                        && this.state.activateChangeButtons
                                        &&
                                        <>
                                            <span style={{display: "inline-block"}}>
                                                <Button onClick={() => this.handleLeadershipQualityPickerOpen2(this.context.currentCategory)} >
                                                    Change Module 
                                                </Button>
                                                {/**Choose Module <IconButton>  <EditIcon  /> </IconButton>  */}
                                            </span>
                                        </>
                                    }
                                </Grid>
                                <Grid item > 
                                    {(this.context.roles!== undefined && !this.context.roles?.includes("COACHEE")) 
                                        // && (this.context.programs!== undefined && this.context.programs.includes("default"))
                                        && this.state.activateChangeButtons !== true
                                        &&

                                        <div style={{fontSize:"small", color:'grey', textAlign:'center', marginRight:10}} 
                                            onClick={() => this.handleLeadershipQualityPickerOpen2(this.context.currentCategory)}
                                            //onClick={() => this.setState({activateChangeButtons : true})} 
                                        >
                                            <IconButton color="secondary" style={{padding: 2}}>
                                                <EditIcon fontSize="small" style={{color: "grey"}}/>
                                            </IconButton>
                                            <br/>Change
                                        </div>
                                            
                                    }
                                </Grid>
                            </Grid>
                        }
                        </div>
                :
                    <div>
                        <CircularProgress  style={theme.loading} />
                    </div>
                }
                {false && (features === undefined || features.includes('daily')) && (this.props.user.dialogueCounter > 2 ) 
                && this.props.user.engagementCounter!== undefined && this.props.user.engagementCounter>=25
                ?      
                    this.props.leadershipQualities.length > 0 && this.props.user.todaysLeadershipQuality !== '' && this.props.user.todaysLeadershipQuality !== undefined
                    ?
                    <div style={{display:"inline-block", width:'100%', marginLeft:'auto',marginRight:0}}>
                        <Grid container direction="row" alignItems="center">
                            <Grid item xs={2}  > 
                                {todayIcon!== undefined && todayIcon!== ""
                                    ?
                                        <AvatarImage key={this.props.user.todaysLeadershipQuality} type='imageIcon' image={todayIcon} squareSize='30'  backgroundColor={todayBgColor} />
                                    :
                                        <AvatarImage type='quality' item={this.props.user.todaysLeadershipQuality}  squareSize='30' backgroundColor={todayBgColor}/>
                                }
                            </Grid>
                            <Grid item xs={8} style={{padding:10}} >
                                <span style={theme.textSettings.settingsTextDescription}>
                                Additional Module{" / "}
                                </span>
                                <span>
                                {
                                    FindTextValueInDict(this.props.firebase, this.props.leadershipQualities, this.props.user.todaysLeadershipQuality)
                                }
                                </span>
                            </Grid>
                            <Grid item xs={2}  > 
                                <div style={{fontSize:"small", color:'grey', textAlign:'center', marginRight:10}} 
                                    onClick={() => this.handleLeadershipQualityPickerOpen('todays')}>
                                    <IconButton color="secondary" style={{padding: 2}}>
                                        <EditIcon fontSize="small" style={{color: "grey"}}/>
                                    </IconButton>
                                    <br/>Change 
                                </div>
                            </Grid>
                        </Grid>
                    </div> 
                    :
                    <div style={{display:"inline-block", width:'100%', marginLeft:'auto',marginRight:0}}>
                        <p style={theme.CardGoals.chooseTodayText}   onClick={ () => this.handleLeadershipQualityPickerOpen('todays')}>
                        <IconButton aria-label="Add daily module" >
                            <AddCircleOutlineIcon  fontSize="small" />							
                        </IconButton>
                        Add a temporary module
                        </p>
                    </div>
                    : ''
                }

                <LeadershipQualityPicker
                    defaultSet={this.props.defaultSet || undefined}
                    firstSessionDone={this.props.user.firstSessionDone}
                    chosenCategory={this.state.chosenCategory}
                    scope={this.state.scope}
                    removeTodaysOptions={this.state.scope === 'todays' ? true : false}
                    user={this.props.user}
                    //preselectQuality={this.state.preselectQuality}
                    openChooseTopic1={this.state.openLeadershipQualityPicker}
                    openChooseTopic2={this.state.openLeadershipQualityPicker2}
                    leadershipCategories={this.props.leadershipCategories}
                    leadershipQualities={this.props.leadershipQualities}
                    refreshQualities = {this.props.refreshQualities || undefined}
                    refreshCategories = {this.props.refreshCategories || undefined}
                    handler={() => {this.handleLeadershipQualityPickerClose()}}
                    handlerOpen2={(entry) => {this.handleLeadershipQualityPickerOpen2(entry)}}
                    handlerClose2={() => {this.handleLeadershipQualityPickerClose2()}}
                    handlerCloseAll={() => {this.handleLeadershipQualityPickerCloseAll()}}
                    closeDialog={() => this.handleCloseDialog()}
                    handlerContinue={this.props.handlerContinue || undefined}
                />      
                <DialogChangeGoal 
                    type = {this.props.type!==undefined?this.props.type: this.state.type}
                    openCurrentAchievementGoal = {this.state.openCurrentAchievementGoal || false}
                    handleCurrentAchievementGoalClose = {this.handleCurrentAchievementGoalClose}
                    leadershipQualities={this.props.leadershipQualities}
                    user={this.props.user}
                    /> 
                <DialogAssessQuality 
                    openDialog={this.state.quiz || false}
                    restartDialog={() => {
                        this.setState({quiz : true });
                    }}
                    closeDialog={() => {
                        this.setState({quiz : false })
                        if(this.props.closeDialog!== undefined ) this.props.closeDialog();
                    }}
                    closeDialogNext={() => {
                        this.setState({quiz : false })
                        if(this.props.closeDialogNext!== undefined ) this.props.closeDialogNext();
                    }}
                    openQualityPickerAfterQuiz = {(this.props.openQualityPickerAfterQuiz || this.state.extraQuiz)}
                    //handleTopicSelection={(preselectQuality) => { this.setState({preselectQuality: preselectQuality})}}
                    leadershipQualities={this.props.leadershipQualities}
                    leadershipCategories={this.props.leadershipCategories}
                    user={this.props.user}
                    assessmentResults={this.state.assessmentResults}
                    />
                <SnackbarInfo 
                    alertName={'info_'+String(this.props.features)}
                    alertText = {this.state.alertCoacheeText}
                    openOnClick = {true}
                    swipeIndex={this.props.swipeIndex}
                    alertOpenEvent = {this.state.alertCoacheeGoal || this.state.alertCoacheeQuality || false}
                    closeHandler = { this.alertCloseHandler}
                />
            </div>
        ); 
    }

    alertCloseHandler = () => {
        if(this._isMounted){
            //console.log(String(this.props.features), this.state)
            this.setState({alertCoacheeGoal: false, alertCoacheeQuality: false})
        }
    }

    setCurrentQuality = (quality) => {
        if(quality!== undefined && quality !== ''){
            this.props.firebase.user(this.context.uid).update({currentLeadershipQuality: quality})
        }
    }
}

export default compose(
    withStyles(styles, { withTheme: true }),
    withFirebase
)(GoalOverview);