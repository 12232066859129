import React, { Component } from 'react';
//import { Link } from "react-router-dom";
import { compose } from 'recompose';
import { AuthUserContext } from '../Session';
import withStyles from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import { withFirebase } from '../Firebase';
import LoadingProgress from '../Modules/LoadingProgress';
import PublishingSwitch from '../Admin/Modules/PublishingSwitch';
// MUI stuff
import BorderColorTwoToneIcon from '@material-ui/icons/BorderColorTwoTone';
import Tooltip from '@material-ui/core/Tooltip';
// MUI stuff
import Grid from "@material-ui/core/Grid";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';


import SettingContentBase from '../Admin/Modules/SettingContentBase';
//import SettingColorPicker from '../Admin/Modules/SettingColorPicker';
import SettingContentActive from '../Admin/Modules/SettingContentActive';
import SettingContentNumber from '../Admin/Modules/SettingContentNumber';
//import SettingContentArray from '../Admin/Modules/SettingContentArray';
//import SettingContentImage from '../Admin/Modules/SettingContentImage';
import SettingContentUploadImage from '../Admin/Modules/SettingContentUploadImage';
//import SettingContentRichText from '../Admin/Modules/SettingContentRichText';
//import SettingContentArraySelect from '../Admin/Modules/SettingContentArraySelect';
import SettingContentArrayCheckbox from '../Admin/Modules/SettingContentArrayCheckbox';
//import SettingContentSelectCombi from '../Admin/Modules/SettingContentSelectCombi';

import SettingContentSelect from '../Admin/Modules/SettingContentSelect';
import { ExternalHelpLink } from '../Modules/ExternalLinks';
//import SettingContentMapSelect from '../Admin/Modules/SettingContentMapSelect';

const styles = theme => ({
	desktopPadding: {
	 	minHeight: 40,
	},
	buttonCenter: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-around",
		alignItems: "center",
		marginBottom: 15,
    },
});


//const clientSecret = 'sk_test_aezZzGRBsx1XHlSFhpAwEiZE00MViCDaX5'
//const clientSecret = 'pk_live_UmgRVYFa2moTOxDNrpK6DpYy00xV16Ocp8'
//const stripePromise = new Stripe(clientSecret)


class ManageShop extends Component {
    static contextType = AuthUserContext;
    _isMounted = false;

    constructor(props) {
        super(props);
        
        this.state = {
            open: false,
        };
    } 

    componentDidMount() {
        this._isMounted = true;
        this.loadShopProducts();
        //console.log(this.state)
    }
    componentWillUnmount(){
        this._isMounted = false;
    }
    
    loadShopProducts(){
        var programsRaw = [];
        var programCustomerPortalTest = undefined;
        var programCustomerPortalLive = undefined;
        if(this.props.programsOverwrite?.length > 0){
            this.props.programsOverwrite?.map(programItem => {
                programsRaw.push(programItem.replace("#",""))
            })
        }
        else{
            this.context?.adminPrograms?.map(programItem => {
                programsRaw.push(programItem.replace("#",""))
            })
        }
        /**
        if(programsRaw?.includes('default_all') && this.context.roles?.includes('ADMIN')){
            programsRaw.unshift('ROCKY', 'REMOTECOACH', 'NEWCOACHCREATOR')
        }
        */
        console.log('loadShopProducts', programsRaw)
        if(programsRaw?.length > 0){
            this.unsubscribe = this.props.firebase
            .collectionByName('shopArticles')
            .where('groupId', 'in', programsRaw)
            .onSnapshot(snapshot => {
                let contentsProduction = {};
                let contentsTest = {};
                var content = {}
                const test = true;
                var updatedShop = false;
                var counterContentsActive = 0;
                snapshot.forEach(doc =>{
                    content = doc.data();
                    if(!(content.position > 0)){
                        content['position'] = 0
                    }
                    //console.log('content', content)
                    if(this.context.roles?.includes('ADMIN') && this._isMounted){
                        if(test || this.context.adminPrograms?.includes(content.program) || content.program===undefined){
                            if(content.production === false){
                                contentsTest[doc.id] = { ...content, cid: doc.id }
                            }
                            else{
                                contentsProduction[doc.id] = { ...content, cid: doc.id };
                            }
                        } // end if true
                    } // end of if ADMIN
                    else if(this.context.roles?.includes('COACHADMIN') && this._isMounted ){
                        if((this.context.adminPrograms?.includes(content.program) || content.program===undefined) && content.program !=='ROCKY' && content.program !=='REMOTECOACH' ){
                            if(content.production === false){
                                contentsTest[doc.id] = { ...content, cid: doc.id }
                                if(content.active === true){
                                    // not needed for test
                                    //counterContentsActive +=1
                                    if(content.stripeCustomerPortal?.includes('http')){
                                        programCustomerPortalTest = content.stripeCustomerPortal
                                    }
                                }
                            }
                            else{
                                if(content?.stripeCustomerPortal?.includes('http') && !updatedShop && this.props.defaultSet?.stripeCustomerPortal !== content.stripeCustomerPortal){
                                    updatedShop = true;
                                }
                                contentsProduction[doc.id] = { ...content, cid: doc.id };
                                if(content.active === true){
                                    counterContentsActive +=1
                                    if(content.stripeCustomerPortal?.includes('http')){
                                        programCustomerPortalLive = content.stripeCustomerPortal
                                    }
                                }
                            }
                        }//end if program / o default
                    } // end of if ADMIN
                });//end of snapshot);

                if(this.props.defaultSet?.programCode?.length > 0 && !(this.props.defaultSet?.programCustomerPortal?.includes('http'))){
                    if(programCustomerPortalLive?.includes('http') || programCustomerPortalTest?.includes('http')){
                        try{
                            var updateSet = {programCustomerPortal: (programCustomerPortalLive || programCustomerPortalTest)}
                            if(! this.props.defaultSet?.programShopURL?.includes('http')){
                                updateSet['programShopURL'] = updateSet['programCustomerPortal'] 
                            }
                            this.props.firebase.group(this.props.defaultSet.programCode).update(updateSet)
                            var defaultContent = this.props.defaultSet || {};
                            defaultContent['programCustomerPortal'] = updateSet['programCustomerPortal'] 
                            if(! this.props.defaultSet?.programShopURL?.includes('http')){
                                defaultContent['programShopURL'] = updateSet['programCustomerPortal'] 
                            }
                            //console.log('udpated on ', updateSet)
                            this.props.refreshDefaultSet(defaultContent, true);
                        }
                        catch(error){
                            console.log('error on ', error)
                        }
                    }
                }
                if(this._isMounted){
                    if(this.props.handlerActiveItems !== undefined){
                        this.props.handlerActiveItems(counterContentsActive || 0)
                    }
                    this.setState({
                        counterContentsActive,
                        contentsProduction,
                        contentsTest,
                        loading: false,
                    });
                }
                //console.log('total', contentsProduction)
            });
        }
    }

    syncWithStripe = async (key, dict) => {
        try{
            this.setState({loadingStripeSync: true});
            var url = 'https://us-central1-rocky-beta-dev.cloudfunctions.net/sync_stripe_product'
            let data = {
                'key': key,
                'productDict' : dict
            }
            let formData = {
                method: 'post',
                body: JSON.stringify(data)
            }
            //console.log('input sending', data)
            var response = await this.requestAPI(url, formData);
            let result = await response.json();
            //console.log('input result', result)

            this.setState({stripeMessage: result})
            this.setState({loadingStripeSync: false});
            return "result test";
        } catch (e){
            this.setState({loadingStripeSync: false});
            return "Failure with Sync"
        }
    }
    requestAPI(url, options) {
        //console.log('options', options)
        //console.log(options)
        return fetch(url, {
            mode: 'cors',
            headers: {
                //'Access-Control-Allow-Origin': '*',
                //'Access-Control-Allow-Headers': "*",
                'Content-Type': 'application/json',
            },
            ...options
        });
    }

    addEmptyProduct = () => {
        var programId = undefined;
        this.context?.adminPrograms?.map(programItem => {
            if(programId === undefined && programItem !=='default' && programItem !=='default_all'){
                programId = programItem
            }
        })
        if(programId !== undefined){
            var groupId = "" + programId;
            groupId = groupId.replace("#", "");
            var newProduct = {
                "storeId": "ai.rocky.app.subscription.premium.monthly",
                "groupId": groupId,
                "program": programId,
                "position": 0,
                "premium": true,
                "createdAt": this.props.firebase.fieldValue.serverTimestamp(),
                "category": "enduser",
                "currency": "USD",
                "amount": 2950,
                "details": "Monthly subscription, purchase via Stripe",
                "price": "from US$ 29.00",
                "button": "Join Monthly",
                "billing": "billed monthly",
                "active": false,
                "production": true,
                "description": "Access premium content and programs with unlimited AI coaching chats",
                "imageUrl": "https://firebasestorage.googleapis.com/v0/b/rocky-beta-dev.appspot.com/o/rockyai%2Fimages%2FShop%2FIRs9g6JJGJANFuuaYFUt%2FimageUrl_1661951779154_Rocky_logo-subscription_500transparent_premiumdeal.png?alt=media&token=fcc558b4-18ee-48f5-8ee3-d212280dd417",
                "type": "MONTHLY",
                "title": "Subscription AI Coach",
                "author": this.context.email,
                "store": "Stripe",
            }
            //console.log('newProduct', newProduct)
            this.props.firebase.collectionByName('shopArticles').add(newProduct)
        }
    }

    handleOpen = (contentId) => {
        this.setState({ openDialog: true, cid: contentId});
    };
    
    handleClose = () => {
        this.setState({ openDialog: false, contentId: ""});
    };

    updateStripeProduct = async(content) => {
        if(this.props.defaultSet?.programShopURL?.includes('http')){
            content['programShopURL'] = this.props.defaultSet.programShopURL
        }
        //console.log('updateStripeProduct', content)
        if(this.state.stripeKey?.length > 20){
            this.setState({stripeMessage: "Started syncing... "})
            this.syncWithStripe(this.state.stripeKey, content)
        }
        else{
            console.log('no stripe key')
        }
    }
    
    render() {
        const { theme } = this.props;
        //console.log(this.state.reflectionInTimeRange,this.state.reflectionStatus )
        return (
            <div style={{marginTop: 20}}>
                <div style={{textAlign: 'center'}}>
                    <Button
                        onClick={() => this.addEmptyProduct()}
                    >
                        Add Product for Stripe
                    </Button>
                </div>
                {false &&
                    <TextField
                        name="title"
                        type="text"
                        label="Secret Key"
                        placeholder="Enter key"
                        value={this.state.stripeKey || "sk_"}
                        onChange={(event) => this.setState({stripeKey: event.target.value})}
                        fullWidth
                        style={ {fontSize: '16px'} }
                    />
                }
                <h5 style={theme.CardContent.titleText}>
                    {(this.props.title || "Products") + " for Live Shop"}
                </h5>
                {this.renderShopItemDialogue()}
                {Object.keys(this.state.contentsProduction || {})?.length > 0
                &&
                    <ImageList rowHeight={'auto'} cols={1} spacing={10} style={{overflow: 'hidden'}}>
                        {Object.keys((this.state.contentsProduction || {}))
                            ?.sort((a, b) => this.state.contentsProduction[a].position - this.state.contentsProduction[b].position)
                            ?.map((shopId, index) => {
                            const shopDict = this.state.contentsProduction[shopId]
                            return(
                                <div key={index+"main"}>
                                    {this.renderShopPreview(shopDict, shopId, index)}
                                </div>
                            )
                        })}
                    </ImageList>
                }

                <h5 style={theme.CardContent.titleText}>
                    {(this.props.title || "Products") + " for Test Mode"}
                </h5>
                {true
                    ? 
                        ExternalHelpLink(theme, "Connect to Stripe", "https://help.rocky.ai/rocky-for-creators/add-stripe-for-your-subscribers", 'Help Stripe')
                    :   ""
                }
                {Object.keys(this.state.contentsTest || {})?.length > 0
                &&
                    <ImageList rowHeight={'auto'} cols={1} spacing={10} style={{overflow: 'hidden'}}>
                        {Object.keys((this.state.contentsTest || {}))
                            ?.sort((a, b) => this.state.contentsTest[a].position - this.state.contentsTest[b].position)
                            ?.map((shopId, index) => {
                            const shopDict = this.state.contentsTest[shopId]
                            return(
                                <div key={index+"test"}>
                                    {this.renderShopPreview(shopDict, shopId, index)}
                                </div>
                            )
                        })}
                    </ImageList>
                }
            </div>
        )
    }

    renderShopPreview(shopDict, shopId, index){
        const {theme} = this.props;
        return(
            <ImageListItem 
                key={index} 
                style={{
                    ...theme.cardJournalDayHighlight, 
                    minWidth: 200,
                    listStyleType: 'none',

                }} 
                onClick={() => {this.handleOpen(shopId)}}
            >
                <PublishingSwitch
                    checked={shopDict.active || false}
                    access={((shopDict.active === true) || (shopDict?.stripeLinkId?.length > 0 && !(this.state.counterContentsActive>6))) ? true : false}
                    infoText={[shopDict?.stripeLinkId?.length > 0 ? "Activate up to 6 products" : "This product has to be first activated in Stripe"]}
                    onChange={(c, event, id) => {
                        if(shopDict.active && c === false){
                            this.props.firebase.documentIdByCollection('shopArticles', shopId).update({active: false})
                        }
                        else{
                            this.props.firebase.documentIdByCollection('shopArticles', shopId).update({active: true})
                            if(this.props.refreshDefaultSet !== undefined){
                                if(shopDict?.stripeCustomerPortal?.includes("stripe")){
                                    if(!(shopDict.stripeCustomerPortal?.includes('test') && this.props.defaultSet?.programShopURL?.includes('stripe'))){
                                        var defaultContent = this.props.defaultSet || {};
                                        defaultContent['programShopURL'] = shopDict['stripeCustomerPortal'];
                                        this.props.firebase.group(this.props.defaultSet.programCode).update({programShopURL: defaultContent['programShopURL']})
                                        this.props.refreshDefaultSet(defaultContent, true);
                                    }
                                }
                            }
                        }
                    }}
                />
                <div style={{
                    borderRadius: theme.borderRadius, padding: 8, 
                    backgroundColor: shopDict.active ? theme.palette.secondary.main : 'darkgrey', 
                    textAlign: 'center', color: 'white'
                }}>
                    {shopDict.title}
                </div>
                <div style={{
                    paddingLeft: 16,
                    paddingRight: 16
                }}>
                    <Grid container spacing={0} direction='row' justifyContent="space-between" >
                        <Grid item xs ={3}>
                            {shopDict?.imageUrl?.includes("http")
                            &&
                                <img src={shopDict.imageUrl} style={{maxHeight: 60, maxWidth: 60}} alt="Example"/>
                            }
                        </Grid>
                        <Grid item xs ={7}>
                            <Grid container spacing={0} direction='row' justifyContent="space-between" >
                                <Grid item xs ={12}>
                                    Brand-code: &nbsp; <strong>#{shopDict.groupId}</strong>
                                </Grid>
                                <Grid item xs ={12}>
                                    Price: &nbsp; <strong>{shopDict.price}</strong>
                                </Grid>
                                <Grid item xs ={12}>
                                    Type: &nbsp; <strong>{shopDict.button}</strong>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs ={2} style={{textAlign: 'right'}}>
                            <Tooltip title={'Edit/Change'}>
                                <IconButton onClick={this.handleSettingOpen} style={{marginTop: 14}}> 
                                    <BorderColorTwoToneIcon fontSize="small" style={{color:"red"}}/>
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </div>
            </ImageListItem>
        )
    }

    renderShopItemDialogue(){
        const {theme} = this.props;
        const {loading, contentsProduction, contentsTest} = this.state;
        let content = {}
        if(this.state.cid?.length > 2){
            if(this.state.cid in contentsProduction && contentsProduction[this.state.cid] !== undefined){
                content = contentsProduction[this.state.cid] 
            }
            else if(contentsTest?.[this.state.cid]){
                content = contentsTest[this.state.cid] 
                //console.log('contentsTest',contentsTest, content)
            }
        }
        return(
            <Dialog
                open={this.state.openDialog}
                onClose={this.handleClose}
            >
                <DialogTitle >
                    <Grid container spacing={0} direction='row' justifyContent="space-between" >
                        <Grid item >
                            <IconButton onClick={this.handleClose}>
                                <ArrowBackIcon />
                            </IconButton>
                        </Grid>
                        <Grid item>
                            <span style={theme.dialogTitle.titleText}>
                                Manage Shop Items
                            </span>
                        </Grid>
                        <Grid item >
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent >
                    {this.state.openDialog && this.state?.cid?.length > 2
                    &&
                        <div>
                            <h5 style={styles.subtitle}>Content ({this.state.cid})</h5>
                            {loading && 
                                <LoadingProgress 
                                    defaultSet={this.props.defaultSet}
                                    type={'LoadingPageAnimated'} 
                                    loadingText={"many, many, data"} 
                                    firebaseConnectionSpeed={this.state.firebaseConnectionSpeed} 
                                    allowRefresh={true}
                                />
                            }
                    
                            {this.state.errorMessage !== '' && 
                                <h4 style={{color :'red'}}>{this.state.errorMessage}</h4>
                            }
                            {content?.cid?.length > 2
                            &&
                                <div>
                                    <h5>Experience Settings</h5>
                                    <SettingContentBase 
                                        dbUpdateLink={this.props.firebase.documentIdByCollection('shopArticles', this.state.cid)}
                                        emptyOption={true}
                                        title={"Name & Description"} 
                                        content={content} 
                                        params={['title', 'description']}
                                        labels={["Description", "Details"]}
                                    />
                                    <Grid container spacing={0} direction='row' justifyContent="space-between" style={{width: '100%'}}>
                                        <Grid item xs={6}>
                                            <SettingContentSelect 
                                                dbUpdateLink={this.props.firebase.documentIdByCollection('shopArticles', this.state.cid)}
                                                emptyOption={false}
                                                noLabel={true}
                                                title={"Main Currency"} 
                                                content={content} 
                                                params={['currency']}
                                                selectOptions={["USD", "CAD", "AUD", "EUR", "GBP", "INR", "SGD", "ZAR"]}
                                                updateState={(resultDict) => {
                                                    if(resultDict.currency?.length > 0){
                                                        resultDict['price'] = "from " + (resultDict.currency) + " " + (content?.amount > 0 ? (("" + content.amount).slice(0,-2) + "." + ("" + content.amount ).slice(-2) ) : "0.00")
                                                        this.props.firebase.documentIdByCollection('shopArticles', this.state.cid).update(resultDict)
                                                    }
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs ={6} style={{paddingLeft: 0}}>
                                            <SettingContentBase 
                                                dbUpdateLink={this.props.firebase.documentIdByCollection('shopArticles', this.state.cid)}
                                                emptyOption={false}
                                                noLabel={true}
                                                numberFormatCurrency={true}
                                                currency={content.currency || "USD"}
                                                title={"Amount (with decimals)"} 
                                                content={content} 
                                                params={['amount']}
                                                updateState={(resultDict) => {
                                                    if(resultDict.amount > 0){
                                                        resultDict['price'] = "from " + (content.currency || "USD") + " " + ("" + resultDict.amount).slice(0,-2) + "." + ("" + resultDict.amount ).slice(-2) 
                                                        this.props.firebase.documentIdByCollection('shopArticles', this.state.cid).update(resultDict)
                                                    }
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <SettingContentSelect 
                                        dbUpdateLink={this.props.firebase.documentIdByCollection('shopArticles', this.state.cid)}
                                        title={"Billing Type"} 
                                        content={content} 
                                        noLabel={true}
                                        params={['billing']}
                                        //selectOptions={['paid once', 'billed monthly', 'billed every 3 months', 'billed annually']}
                                        selectOptions={['billed monthly', 'billed annually']}
                                        updateState={(resultDict) => {
                                            if(resultDict.billing === 'billed monthly'){
                                                resultDict['details'] = "Pay every month, cancel any-time, purchase via Stripe"
                                                resultDict['type'] = "MONTHLY"
                                                resultDict['button'] = "Subscribe monthly"
                                                resultDict['storeId'] = "ai.rocky.app.subscription.premium.monthly"
                                            }
                                            else if(resultDict.billing === 'billed every 3 months'){
                                                resultDict['details'] = "Pay every 90 days, cancel any-time, purchase via Stripe"
                                                resultDict['type'] = "90 DAYS"
                                                resultDict['button'] = "Subscribe 3-monthly"
                                                resultDict['storeId'] = "ai.rocky.app.subscription.premium.3month"
                                            }
                                            else if(resultDict.billing === 'billed annually'){
                                                resultDict['details'] = "Pay annually, cancel any-time, purchase via Stripe"
                                                resultDict['type'] = "YEARLY"
                                                resultDict['button'] = "Subscribe annually"
                                                resultDict['storeId'] = "ai.rocky.app.subscription.premium.yearly"
                                            }
                                            else if(resultDict.billing === 'paid once'){
                                                resultDict['details'] = "Purchase via Stripe"
                                                resultDict['type'] = "PURCHASE"
                                                resultDict['button'] = "Purchase"
                                                resultDict['storeId'] = "ai.rocky.app.purchase"
                                            }
                                            this.props.firebase.documentIdByCollection('shopArticles', this.state.cid).update(resultDict)
                                        }}
                                    />
                                    {true &&
                                        <SettingContentNumber
                                            dbUpdateLink={this.props.firebase.documentIdByCollection('shopArticles', this.state.cid)}
                                            title={"Trial Days:"} 
                                            noLabel={true}
                                            content={content} 
                                            params={['trialDays']}
                                            min={0}
                                            max={14}
                                        />
                                    }
                                    <SettingContentUploadImage
                                        dbUpdateLink={this.props.firebase.documentIdByCollection('shopArticles', this.state.cid)}
                                        title={"Main Image (for single image use)"} 
                                        noLabel={true}
                                        previewImageSize={true}
                                        content={content} 
                                        params={['imageUrl']}
                                        uploadDatabase={this.props.firebase.storageImages('Shop/'+this.state.cid)}
                                    />
                                    <h5>Stripe:</h5>
                                    <SettingContentBase 
                                        dbUpdateLink={this.props.firebase.documentIdByCollection('shopArticles', this.state.cid)}
                                        emptyOption={true}
                                        mandatory={true}
                                        title={"Stripe Key"} 
                                        content={content} 
                                        params={['stripePK']}
                                        labels={["Stripe Publishable Key"]}
                                        infoText={["Necessary to have an embedded checkout"]}
                                    />
                                    <div style={{width: "100%", textAlign: 'right', padding: 10}}>
                                        <div>
                                            <TextField
                                                name="title"
                                                type="text"
                                                label="Stripe Secret Key"
                                                placeholder="Enter key"
                                                value={this.state.stripeKey || "sk_"}
                                                onChange={(event) => this.setState({stripeKey: event.target.value})}
                                                fullWidth
                                                style={ {fontSize: '16px'} }
                                            />
                                            <span style={theme.textSupport}>Necessary to connect to Stripe Admin</span>
                                        </div>
                                        {this.state.stripeKey?.length > 8
                                            ?
                                                <div>
                                                    <Button onClick={() => this.updateStripeProduct(content)}>
                                                        Sync with Stripe
                                                    </Button>
                                                    <p style={theme.textSupportCenter}>
                                                        Attention: The secret key will not be stored! 
                                                        <br/>Ensure to store it in a safe space, so that you can reuse it later for other modifications!</p>
                                                </div>
                                            :   
                                                <div>
                                                    <Button disabled>
                                                        Sync with Stripe
                                                    </Button>
                                                    <p style={theme.textSupportCenter}>Please add your Stripe Secret key</p>
                                                </div>
                                        }
                                        {this.state.stripeMessage?.length > 0
                                            &&
                                                <p style={{color:'red'}}>
                                                    {this.state.stripeMessage}
                                                </p>
                                        }
                                    </div>
                                    <h5>In-App Settings</h5>
                                    <SettingContentActive 
                                        dbUpdateLink={this.props.firebase.documentIdByCollection('shopArticles', this.state.cid)}
                                        title={"Active"} 
                                        content={content} 
                                        params={['active']}
                                        access={((content.active === true) || (content?.stripeLinkId?.length > 0 && !(this.state.counterContentsActive>6))) ? true : false}
                                        labels={["Published in the app"]}
                                        infoText={[content?.stripeLinkId?.length > 0 ? "Activate up to 6 products" : "This product has to be first activated in Stripe"]}
                                        updateState={(resultDict) => {
                                            if(this.props.refreshDefaultSet !== undefined && content.stripeCustomerPortal?.includes('stripe') && resultDict.active){
                                                if(!(content.stripeCustomerPortal?.includes('test') && this.props.defaultSet?.programShopURL?.includes('stripe'))){
                                                    var defaultContent = this.props.defaultSet || {};
                                                    defaultContent['programShopURL'] = content['stripeCustomerPortal'];
                                                    this.props.firebase.group(this.props.defaultSet.programCode).update({programShopURL: defaultContent['programShopURL']})
                                                    this.props.refreshDefaultSet(defaultContent, true);
                                                }
                                            } 
                                        }}
                                    />
                                    <SettingContentSelect
                                        dbUpdateLink={this.props.firebase.documentIdByCollection('shopArticles', this.state.cid)}
                                        title={"Select Brand-code"} 
                                        noLabels={true}
                                        content={content} 
                                        params={['program']}
                                        selectAtLeastOne={true}
                                        addNewOption={this.context.roles?.includes('ADMIN') || false}
                                        selectOptions={this.context.adminPrograms || []}
                                        updateState={(resultList) => {
                                            if(resultList?.program?.length > 0){
                                                var programNewCode = resultList.program
                                                if(resultList.program === "default_all"){
                                                    resultList['program'] = 'ROCKY'
                                                }
                                                //console.log('resultList', resultList, programNewCode)
                                                programNewCode = programNewCode.replace("#", "")
                                                this.props.firebase.documentIdByCollection('shopArticles', this.state.cid).update({groupId: programNewCode})
                                            }
                                        }}
                                    />
                                    <SettingContentActive 
                                        dbUpdateLink={this.props.firebase.documentIdByCollection('shopArticles', this.state.cid)}
                                        title={"Production / Livemode in Stripe"} 
                                        content={content} 
                                        access={false}
                                        params={['production']}
                                        labels={["Live mode in Stripe"]}
                                        infoText={["This setting cannot be overwritten. It depends on your Stripe Live vs. Test Mode secret key. It can be only tested by creator accounts."]}
                                    />
                                    <SettingContentSelect 
                                        dbUpdateLink={this.props.firebase.documentIdByCollection('shopArticles', this.state.cid)}
                                        title={"Position in Selection"} 
                                        content={content} 
                                        params={['position']}
                                        selectOptions={[1, 2, 3, 4, 5, 6]}
                                    />
                                    <SettingContentBase 
                                        dbUpdateLink={this.props.firebase.documentIdByCollection('shopArticles', this.state.cid)}
                                        emptyOption={false}
                                        mandatory={true}
                                        title={"Price offer in App"} 
                                        labels={["e.g. from US$ 19.99"]}
                                        content={content} 
                                        params={['price']}
                                    />
                                    <SettingContentBase 
                                        dbUpdateLink={this.props.firebase.documentIdByCollection('shopArticles', this.state.cid)}
                                        emptyOption={true}
                                        mandatory={false}
                                        title={"Promotion"} 
                                        content={content} 
                                        params={['promotion', 'details', 'comparison']}
                                    />
                                    <SettingContentBase 
                                        dbUpdateLink={this.props.firebase.documentIdByCollection('shopArticles', this.state.cid)}
                                        emptyOption={true}
                                        mandatory={false}
                                        max={12}
                                        labels={["e.g. SAVE 30%"]}
                                        title={"Discount"} 
                                        content={content} 
                                        params={['discount']}
                                    />
                                    <SettingContentArrayCheckbox
                                        dbUpdateLink={this.props.firebase.documentIdByCollection('shopArticles', this.state.cid)}
                                        title={"Exclusive for a user group?"} 
                                        mandatory={false}
                                        content={content} 
                                        params={['requirements']}
                                        selectOptions={['default', 'executive', 'professional', 'seeker', 'student', 'teenager']}
                                        selectOptionsText={['Question for every situation', 'Executive/Entrepreneur', 'Professional/Employed', 'Available/Job-seeker', 'Student (Uni)', 'K-12/Teenager' ]}
                                    />
                                    <h5>Stripe Settings</h5>
                                    <SettingContentBase 
                                        dbUpdateLink={this.props.firebase.documentIdByCollection('shopArticles', this.state.cid)}
                                        title={"Stripe IDs (Auto filled)"} 
                                        content={content} 
                                        params={['stripeProductId', 'stripePriceId', 'stripeLinkId', 'stripeLinkURL', 'stripeCustomerPortal']}
                                        labels={['Stripe Product Id', 'Default Stripe Price Id', 'Stripe Link Id', 'Stripe Link URL', 'Stripe Customer Portal Link']}
                                        updateState={(resultList) => {
                                            if(resultList?.stripeLinkURL?.includes('http')){
                                                var programNewCode = resultList.program
                                                if(programNewCode?.length > 4){
                                                    if(resultList.program === "default_all"){
                                                        resultList['program'] = 'ROCKY'
                                                    }
                                                    //console.log('resultList', resultList, programNewCode)
                                                    programNewCode = programNewCode.replace("#", "")
                                                    this.props.firebase.documentIdByCollection('shopArticles', this.state.cid).update({groupId: programNewCode})
                                                }
                                            }
                                        }}
                                    />
                                    <SettingContentBase 
                                        title={"Stripe Link Id"} 
                                        content={content} 
                                        access={false}
                                        noLabel={true}
                                        params={['id']}
                                    />
                                    <SettingContentBase 
                                        title={"Metadata for Stripe"} 
                                        content={content} 
                                        access={false}
                                        params={['groupId', 'storeId', 'title', 'description', 'stripeCustomerPortal']}
                                        labels={['domaincode', 'storeId', 'title', 'description', 'billing']}
                                    />
                                    <div style={{paddingLeft: 5 }}>
                                        <p style={{color : theme.palette.themeAlertRed}}>Danger zone!</p>
                                        {   
                                            <Button 
                                                disabled={ content.active === true}
                                                onClick={() => {
                                                    this.props.firebase.documentIdByCollection('shopArticles', this.state.cid).update({
                                                    active: false, 
                                                    groupId: "archive_"+content.groupId
                                                });
                                                this.handleClose();
                                            }}>
                                                Archive item
                                            </Button>
                                        }
                                        {content.active === true
                                            &&
                                            <span>&nbsp;You can only remove and archive unpublished items! </span>
                                        }
                                    </div>
                                    {false 
                                    &&
                                        <>
                                            <SettingContentActive 
                                                dbUpdateLink={this.props.firebase.documentIdByCollection('shopArticles', this.state.cid)}
                                                title={"Premium Feature Access"} 
                                                content={content} 
                                                params={['premium']}
                                            />
                                            <SettingContentSelect 
                                                dbUpdateLink={this.props.firebase.documentIdByCollection('shopArticles', this.state.cid)}
                                                title={"Buyer category"} 
                                                content={content} 
                                                params={['category']}
                                                selectOptions={['enduser','coach']}
                                            />
                                            <SettingContentSelect 
                                                dbUpdateLink={this.props.firebase.documentIdByCollection('shopArticles', this.state.cid)}
                                                title={"Billing indication (user)"} 
                                                content={content} 
                                                params={['type']}
                                                selectOptions={['MONTHLY', 'YEARLY', 'PURCHASE', 'SEATS', '90 DAYS']}
                                            />
                                            <SettingContentSelect 
                                                dbUpdateLink={this.props.firebase.documentIdByCollection('shopArticles', this.state.cid)}
                                                title={"Button text"} 
                                                content={content} 
                                                params={['button']}
                                                selectOptions={['Subscribe Monthly', 'Subscribe Annually', 'Join Monthly', 'Join Annually', 'Buy Chats', 'Buy Seats', 'Allow to Link', 'Subscribe for 90 Days', 'Join for 90 Days', 'Subscribe for 90 Days', 'Join for 12 Months']}
                                            />
                                            <SettingContentSelect 
                                                dbUpdateLink={this.props.firebase.documentIdByCollection('shopArticles', this.state.cid)}
                                                title={"Store"} 
                                                content={content} 
                                                params={['store']}
                                                selectOptions={['Mobile','Stripe', 'Paytm', 'Bluesnap', 'accountLinking']}
                                            />
                                        </>
                                    }
                                </div>
                            }
                        </div>
                    }
                </DialogContent>
            </Dialog> 
        )
    }
}

export default compose(
    withFirebase,
    withStyles(styles, { withTheme: true})
)(ManageShop);
