import React, { Component } from 'react';
import { compose } from 'recompose';
import { AuthUserContext } from '../Session';
import withStyles from '@material-ui/core/styles/withStyles';
import { withFirebase } from '../Firebase';
import {AvatarCard} from '../Modules/AvatarImage';
import LanguageManager from '../../constants/languages';
// Modules

import ImageListItem from '@material-ui/core/ImageListItem';
import ImageListItemBar from '@material-ui/core/ImageListItemBar';
import Collapse from '@material-ui/core/Collapse';

import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import SearchIcon from '@material-ui/icons/Search';
import AssignmentIcon from '@material-ui/icons/Assignment';


// collapse details
import IconButton from '@material-ui/core/IconButton';
//import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
//import CancelIcon from '@material-ui/icons/Cancel';
//import ArrowBackIcon from '@material-ui/icons/ArrowBack';
//import {IconPicker} from '../Modules/IconPicker';



const styles = theme => ({
    grow: {
        flexGrow: 1,
    },
    ReactCalendarHeatmap:{
        text:{
            fontSize: 4,
            fill: '#555'
        },
        '&.color-empty': {
            fill: 'red'
        }
    }
})

class QualityCard extends Component {
    static contextType = AuthUserContext;
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            
        };
    }  

    componentDidMount() {
        this._isMounted =  true;
    }
    componentWillUnmount(){
        this._isMounted =  false;
    }
  
    render(){
        const {theme} = this.props;
        var secondColor = theme.cardJournalDayHighlight.backgroundColor;
        var qid = this.props.qid || undefined;
        var quality = this.props.qualityDict || undefined;
        if(quality?.value !== undefined){
            if(qid === undefined){
                qid = quality.value;
            }
            if(quality?.backgroundColor!== undefined && quality?.backgroundColor!== "" ){
                secondColor = quality.backgroundColor;
            }
            var background = 'linear-gradient(to bottom, '+secondColor+' 0%, '+theme.palette.white+' 90%)';
            if(quality?.text?.length > 0)
                return(
                    <Grid item>
                        <ImageListItem key={qid} 
                            style={{
                                ...theme.cardJournalDayHighlight, 
                                height: this.props.compactView ? 200 : 160,
                                minWidth: this.props.compactView ? 200 : '100%',
                                background: quality.url?.length>1
                                    ?  'url("'+quality.url+'")'
                                    :   background,
                                backgroundPosition: quality.url?.length>1 ? 'right 40%' : undefined,
                                backgroundRepeat: quality.url?.length>1 ? 'no-repeat' : undefined,
                                backgroundSize: quality.url?.length>1 ? 'cover' : undefined,
                                listStyleType: 'none'
                            }} 
                            onClick={
                                this.props.clickAction
                                ?   () => {this.props.clickAction()}
                                :   undefined
                            }
                        >
                            <div style={{padding: 20}}>
                                {quality.icon!== ""
                                    ?
                                        <AvatarCard 
                                            key={qid} type='imageIcon' 
                                            user={this.props.user}
                                            image={quality.icon} 
                                            textBlock={quality.text} 
                                            squareSize='20'  
                                            backgroundColor={quality.backgroundColor} />
                                    :
                                        <AvatarCard 
                                            user={this.props.user}
                                            key={qid} 
                                            dict={quality.value} type='quality'
                                            item={qid} 
                                            squareSize='20' />
                                }
                            </div>
                            <ImageListItemBar
                                title={
                                    <span>
                                        <LanguageManager
                                            text={quality.text}
                                            type={quality.program ==='default' ? 'tutorial' : 'text'}
                                            language={this.props.user?.features?.language}
                                        />
                                    </span>
                                    }
                                subtitle={<span>{quality.explanationShort}</span>}
                                style={{ borderRadius: '0 0 '+theme.borderRadius+'px '+theme.borderRadius+'px', maxHeight:"35%"}}
                                actionIcon={
                                    <IconButton aria-label={`Choose ${quality.text}`} >
                                        {this.props.showDetails
                                            ?   
                                                this.state.qualityExpended === quality.value
                                                    ?   <AddCircleOutlineIcon  fontSize="large" style={{ color: theme.palette.themeRed}} />
                                                    :   <SearchIcon  fontSize="large" style={{ color: theme.palette.themeRed}} />
                                            :   <AssignmentIcon />
                                        }
                                    </IconButton>
                                }
                            />
                        </ImageListItem>
                        {this.props.showDetails &&
                            <Collapse style={{width: '100%'}} in={this.state.qualityExpended === quality.value} key={quality.value} timeout="auto" unmountOnExit>
                                <CardContent 
                                    style={{
                                        marginTop: -20,
                                        paddingTop: 40,
                                        marginBottom: 20,
                                        marginRight: 10,
                                        marginLeft: 10,
                                        border: 1, 
                                        borderColor: 'black',
                                        borderStyle: 'solid',
                                        borderBottomLeftRadius: theme.borderRadius,
                                        borderBottomRightRadius: theme.borderRadius,
                                    }}
                                >
                                    <span style={{color: theme.palette.secondary.main }}>
                                        <LanguageManager
                                            text={quality.definition}
                                            type={quality.program ==='default' ? 'tutorial' : 'text'}
                                            language={this.props.user?.features?.language}
                                        />
                                    </span>                                                               
                                </CardContent>
                            </Collapse>
                        }
                    </Grid>
                )
            else{
                return ""
            }
        }
        else{
            return ""
        }
    }
}


export default compose(
    withFirebase,
    withStyles(styles, { withTheme: true })
)(QualityCard);
