import React, { Component } from 'react';
import LanguageManager from '../../constants/languages';

import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import withStyles from '@material-ui/core/styles/withStyles';

import AssessmentSlider from '../Charts/AssessmentSlider';
import {AddEngagementPoints} from '../Modules/AddEngagementPoints';
import * as ROUTES from '../../constants/routes';
import { Link } from 'react-router-dom';

//import Card from '@material-ui/core/Card';
//// Dialog History
import JournalListSwipe from '../Days/JournalListSwipe';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
//import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import {RemoveScroll} from 'react-remove-scroll';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import {IsMobileDevice} from '../Modules/IsMobileDevice';
import Grid from '@material-ui/core/Grid';

import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';

import DateBadge from '../Modules/DateBadge';
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import SentimentSatisfiedAltIcon from '@material-ui/icons/SentimentSatisfiedAlt';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfied';

import InputAdornment from '@material-ui/core/InputAdornment';
import SpeechToTextButton from '../Modules/SpeechToTextButton';
import TextField from "@material-ui/core/TextField";
import IconButton from '@material-ui/core/IconButton';
import SendIcon from '@material-ui/icons/Send';
//import {DrawConnectedCurve} from '../Charts/DrawCurve';

import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';

import {ExtendDialogueMessage} from '../Routine/DiaryManual';
//import {DataCacheRead, DataCacheWrite} from "../Modules/DataCache";

const styles = theme => ({
    grow: {
        flexGrow: 1,
    },
});
/***
const styleCanvas = {
    circle: {
        width: 5,
        height: 5,
        borderRadius: "50%",
        position: 'relative',
        backgroundColor: "#FCD730",
        top: 34,
        zIndex: 4,
        margin: 0,
    }
}
 */

class AssessmentTimeline extends Component {
    
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            refreshing: false,
            highlightTime: 0
        };    
    }

    componentDidMount() {
        this._isMounted = true;
        this.loadTimeline();
    }
    componentWillUnmount(){
        this._isMounted = false;
        if(this.unsubscribe !== undefined ) this.unsubscribe();
    }

    componentDidUpdate(prevProps)  {
        if(this._isMounted && !this.state.loading && !this.state.refreshing){
            var newValues = '';
            var oldValues = '';
            //console.log('load', this.props.assessmentArchive[this.props.assessmentArchive.length -1]?.date ,prevProps.assessmentArchive[prevProps.assessmentArchive.length -1]?.date )
            if(this.props.assessmentArchive?.length > 0){
                newValues = this.props.assessmentArchive[this.props.assessmentArchive.length -1]?.date || '';
                //;
                //console.log('reload pre', newValues)
            }
            if(this.state.timeLineItems?.length > 0){
                oldValues = this.state.timeLineItems[0]?.date || '';
                //(''+this.state.timeLineItems.length);
            }
            //console.log('r values', newValues, oldValues )
            if(newValues !== '' && oldValues !== '' && newValues !== oldValues && this._isMounted) {
                //console.log('reload', newValues, oldValues)
                this.setState({refreshing: true});
                this.loadTimeline();
                setTimeout(() => {
                    if(this._isMounted){
                        this.setState({refreshing: false});
                    }
                }, 3000);

            }
            else{
                //console.log('load', this.state.timeLineItems, this.props.assessmentArchive)
            }
        }
        return true
    }

    loadTimeline(){
        var timeLineItems = [];
        var dateCheckList = [];
        var d = new Date();    
        var dateNow = new Date(d.getTime() - d.getTimezoneOffset() * 60000).toISOString().slice(0,10);
        //var dateNow = new Date().toISOString().slice(0,10);
        //console.log('date', new Date())
        //console.log('dateNow', dateNow)
        if(this._isMounted) {
            this.setState({loading: true});
            //console.log('timeline', this.props.assessmentKey)
            if(this.props.assessmentArchive?.length>0){
            //console.log('archive', this.props.assessmentArchive)
                timeLineItems = [];
                dateCheckList = [];
                //console.log('assessmentArchive', this.props.assessmentArchive)
                this.props.assessmentArchive.forEach( item => {
                    var itemUpgrade = item;
                    var itemDate = '';
                    if(item.value === undefined){
                        if(item.offTrack) itemUpgrade['value'] = 25;
                        else if(item.onTrack) itemUpgrade['value'] = 75;
                        else if(item.done) itemUpgrade['value'] = 100;
                        else if(item.failed) itemUpgrade['value'] = 0;
                        else itemUpgrade['value'] = 50;
                        itemUpgrade['type'] = 'scale';
                        itemUpgrade['scale'] = '100%';
                    }
                    //// add the main comment
                    if(item.comment === undefined && item.comment?.length>0){
                        itemUpgrade['comment'] = item.comment;
                    }
                    //// add the actual text added
                    if(item?.intention?.length>0){
                        itemUpgrade['message'] = item.intention;
                    }
                    else if(item?.message?.length>0){
                        itemUpgrade['message'] = item.message;
                    }
                    if(item.date?.length>4){
                        itemDate = item.date.slice(0,10);
                        itemUpgrade['date'] = itemDate;
                    }
                    if(itemDate!== '' && !dateCheckList.includes(itemDate)){
                        timeLineItems.unshift(itemUpgrade);
                        dateCheckList.push(itemDate);
                    }
                })
                if(timeLineItems?.[0]?.date !== dateNow){
                    //console.log('time1', timeLineItems[0].date,  dateNow)
                    timeLineItems.unshift({date: dateNow, comment: '', key: this.props.assessmentKey || 'new'});
                }
                //console.log('archive upgrade', timeLineItems)
                this.setState({timeLineItems, loading: false});
                //console.log('state', this.state)
            }
            else if(this.props.assessmentKey !== undefined && this.props.assessmentKey  !== "" && this.props.user !== undefined){
                if(this.unsubscribe !== undefined ) this.unsubscribe();
                this.unsubscribe = this.props.firebase.insightAssessments(this.props.user.uid)
                .where('key', '==', this.props.assessmentKey )
                .orderBy('createdAt', 'desc')
                .limit(7)
                .onSnapshot(snapshot => {
                    if(this._isMounted){
                        timeLineItems = [];
                        snapshot.forEach(doc => {
                            var timeLineSet = doc.data();
                            //console.log('timeLinePush', timeLineSet)
                            if(timeLineSet.value === undefined){
                                timeLineSet['value'] = 50;
                                timeLineSet['type'] = "scale";
                            }
                            if(timeLineSet.itention?.length > 5){
                                ////// this replace is needed for the format saved the items in the list in the DB
                                var tempMessage = timeLineSet.itention.replace(" -", ". ")
                                var messageArray = tempMessage.split(". ");
                                messageArray.forEach((subMessage, index) => {
                                    if(subMessage.startsWith("-")){
                                        subMessage = subMessage.replace("-", "")
                                    }
                                    subMessage = subMessage.trim()
                                    //newMessage = newMessage + " • " + subMessage + "\n" 
                                    messageArray[index] = subMessage;
                                })
                                timeLineSet['messageArray'] = messageArray;
                            }
                            else if(timeLineSet.message?.length > 5){
                                ////// this replace is needed for the format saved the items in the list in the DB
                                var tempMessage = timeLineSet.message.replace(" -", ". ")
                                var messageArray = tempMessage.split(". ");
                                messageArray.forEach((subMessage, index) => {
                                    if(subMessage.startsWith("-")){
                                        subMessage = subMessage.replace("-", "")
                                    }
                                    subMessage = subMessage.trim()
                                    //newMessage = newMessage + " • " + subMessage + "\n" 
                                    messageArray[index] = subMessage;
                                })
                                timeLineSet['messageArray'] = messageArray;
                            }
                            timeLineItems.push(timeLineSet);
                        })
                        if(timeLineItems?.[0]?.date !== dateNow){
                            //console.log('time2', timeLineItems[0].date,  dateNow)
                            timeLineItems.unshift({date: dateNow, comment: '', key: this.props.assessmentKey || 'new'});
                        }
                        this.setState({timeLineItems, loading: false});
                    }
                })
            }
        }
    }

    render() {
        const {theme} = this.props;
        //console.log('assessmenttimeline', this.state)
        return(
            <div>
                {this.state.timeLineItems !== undefined && this.state.timeLineItems.length>0
                    && 
                        <div>
                            <h4>
                                <LanguageManager
                                    text= {this.props.title || "Journal"}
                                    type='button'
                                    language={this.props.user?.features?.language}
                                />
                            </h4>
                            <p style={theme.textSupportCenter}>
                                <LanguageManager
                                    text= {this.props.assessmentText}
                                    type='text'
                                    language={this.props.user?.features?.language}
                                />
                            </p>
                            {this.props.showAssessmentSlider === true &&
                                this.renderGoalAssessment(this.props.assessmentQuestion, '', '')
                            }
                            {this.timeLineCentral()}
                            {this.props.linkToContent === true &&
                                <Link
                                    onClick={() => {
                                        if(this.props.handleChangeTab !== undefined){
                                            this.props.handleChangeTab("Diary")
                                        }
                                    }}
                                    to={{pathname: ROUTES.JOURNAL, state:{startTab: "Diary"}}} 
                                    style={{float: 'right', whiteSpace: 'nowrap'}}>
                                    <LanguageManager
                                        text= {"Show All"}
                                        type='button'
                                        language={this.props.user?.features?.language}
                                    />
                                    &nbsp;&gt;
                                </Link>
                            }
                        </div>
                }
                <RemoveScroll enabled={this.state.openDialogue || false }>
                    <Dialog
                        open={this.state.openDialogue || false}
                        onClose={() => this.setState({openDialogue: false})}
                        PaperProps={{style:theme.dialogWindow}}
                        fullScreen={IsMobileDevice()}
                        maxWidth="sm"
                    >
                        <DialogTitle style={theme.dialogTitle}  >
                            <Grid container spacing={0} direction="row"
                                justifyContent="space-between"
                                alignItems="center">
                                <Grid item >
                                    <IconButton onClick={() =>  {this.setState({openDialogue: false})} }>
                                        <ArrowBackIcon />
                                    </IconButton>
                                </Grid>
                                <Grid item>
                                    <span style={theme.dialogTitle.titleText}>
                                        <LanguageManager
                                            text= {"Chat History"}
                                            type='button'
                                            language={this.props.user?.features?.language}
                                        />
                                    </span>
                                </Grid>
                                <Grid item>
                                    <span style={theme.dialogTitle.titleLink} onClick={() =>  {this.setState({openDialogue: false})} }>
                                        <LanguageManager
                                            text= {"Cancel"}
                                            type='text'
                                            language={this.props.user?.features?.language}
                                        />
                                    </span>
                                </Grid>
                            </Grid>
                        </DialogTitle>
                        <DialogContent style={{...theme.dialogContent}}>
                            {this.state.openDialogue &&
                                    <div style={{padding: 0}}>
                                        <JournalListSwipe 
                                            historyLimit={2}
                                            messageWeek={this.state.messageWeek || undefined}
                                        />
                                    </div>
                            }
                        </DialogContent>
                    </Dialog>
                </RemoveScroll>
            </div>
        )
    }

    renderGoalAssessment = (block, keyWord, comment) => {
        return(
            <div style={{marginBottom: 60}}>
                <h4>
                    {keyWord}
                </h4>
                <p>
                    {comment}
                </p>
                {
                    <AssessmentSlider
                        circular = {true}
                        user = {this.props.user}
                        assessmentKey = {this.props.assessmentKey}
                        assessmentText = {this.props.user.achievementGoal}
                        hideAssementText = {true}
                        assessmentQuestion = 'Is your vision becoming a reality?'
                        hideAssementQuestion = {false}
                        updateScore = { (score) => { 
                            this.setState({goalAssessedPoint: 1});
                        } }
                    />
                }
            </div>
        )
    }

    timeLineCentral(){
        //console.log('state', this.state)
        const {theme} = this.props;
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        var d = new Date();
        var date = new Date(d.getTime() - d.getTimezoneOffset() * 60000).toISOString().slice(0,10);
        return(
            <Timeline style={{padding:0, position: 'relative'}}>
                {this.state.timeLineItems.map( (item, index) => (
                    <div key={'time'+index}>
                        {!(item.message?.length) > 1 && item.question?.length>10 &&
                            <div style={{fontSize: 'smaller', textAlign: "center"}}>
                                {this.state.highlightTime !== index && item.question?.length > 79
                                    ?   (item.question.substring(0,76) + "...")
                                    :   item.question
                                }
                                <br/>
                            </div>
                        }
                        {(index === 0 || item.message?.length > 1 || item.comment?.length > 1) &&
                        <div
                            style={(item.message?.length > 1 || item.comment?.length > 1)
                                ?   theme.CardContentShadow
                                :   {}
                            }
                        >
                            {item.message?.length > 1 && item.message !== this.props.assessmentText
                                &&
                                <div
                                    key={"message_"+index} 
                                    style={{textAlign:'center'}}
                                    onClick={() => this.setState({highlightTime: index})}
                                >
                                    <span style={{
                                        textAlign: 'left',
                                        color:  (item.value === undefined || item.value > 50)
                                            ?   theme.palette.themeAlertGreen
                                            :   item.value < 15
                                                ?   theme.palette.themeAlertRed
                                                :   theme.palette.themeText //theme.palette.themeAlertOrange
                                    }}>
                                        {this.state.highlightTime !== index && item.message.length > 279
                                            ?   item.messageArray?.length > 0
                                                ?   <>
                                                        <li style={{marginLeft: 20, fontSize: 'small'}}>{item.messageArray[0]}</li>
                                                        <li style={{marginLeft: 20, fontSize: 'small'}}><span style={{color: theme.palette.secondary.main}}> ... more </span></li>
                                                    </>
                                                :   <span>
                                                        (item.message.substring(0,276))
                                                        <span style={{color: theme.palette.secondary.main}}> ... more </span>
                                                    </span>
                                            :   item.messageArray?.length > 0
                                                ?   item.messageArray.map((item, ind2) => (
                                                        <li key={ind2} style={{marginLeft: 20, fontSize: 'small'}}>{item}</li>
                                                    ))
                                                :   item.message
                                        }
                                    </span>
                                </div>
                            }
                            {index === 0 && this.state['showComment_'+item.key]
                            ?
                                <div style={{width: this.props.screen !== "desktop"? "100%" : "80%", margin : "auto"}}>
                                    <TextField
                                        key={'comment_'+item.key}
                                        autoFocus={true}
                                        //inputRef={(input) => input && input.focus()}
                                        name="comment"
                                        style={ {fontSize: '16px'} }
                                        //label={this.props.assessmentQuestion !== undefined && this.props.assessmentQuestion !=="" ? this.props.assessmentQuestion : "What's on your mind?"}
                                        value={this.state['newComment_'+item.key] === undefined ? (item.comment || "" ) : this.state['newComment_'+item.key]}
                                        onChange={(e) => {this.setState({ ['newComment_'+item.key] : e.target.value})}}
                                        type="text"
                                        variant="outlined"
                                        placeholder="What's on your mind?"
                                        fullWidth
                                        multiline
                                        InputProps={{
                                            style:{fontSize: '16px'},
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <SpeechToTextButton 
                                                        renderKey={'speech_'+item.key}
                                                        returnText={(result) => this.setState({['newComment_'+item.key] : result})}
                                                        existingText={this.state['newComment_'+item.key] || ""}
                                                    />
                                                </InputAdornment>
                                            ),
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        edge="end"
                                                        aria-label="Send message"
                                                        disabled={(this.state['newComment_'+item.key]=== undefined || this.state['newComment_'+item.key].length<5) ? true : false} 
                                                        onClick={() => this.addNewComment(item.key, "What's on your mind on: " + this.props.assessmentQuestion + "?")}
                                                        >
                                                        <SendIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </div>
                            :
                                (item.comment?.length > 1 && item.comment !== this.props.assessmentText)
                                ?
                                        <div 
                                            style={theme.textCenter}
                                            onClick={() => this.setState({highlightTime: index})}
                                        >
                                            "
                                            {(this.state.highlightTime !== index) && item.comment.length > 79
                                                ?   ( item.comment.substring(0,76) + "...")
                                                :   item.comment
                                            }
                                            "
                                        </div>
                                :   index === 0 
                                    ?   <div 
                                            style={{...theme.textSupportCenter, color: theme.palette.themeAlertRed}}
                                            onClick = { () =>  this.setState({
                                                ['showComment_'+item.key]: this.state['showComment_'+item.key]? false : true 
                                            })}
                                        >
                                            ...
                                        </div>
                                    :   ""
                            }
                        </div>
                        }
                        {/***
                        <div id={'circle_'+index} style={{...styleCanvas.circle, left: ""+ ( (item.value || 50)) +"%" }}></div>
                        {index > 0
                            &&
                            <div id={"line_"+index}></div>
                        }
                         */}
                        <TimelineItem >
                            <TimelineOppositeContent style={{textAlign: 'center'}}>
                                {item.value <= 49
                                    ?   this.returnSmiley(item.value, (date !== item.date?.slice(0,10) ? item.date?.slice(0,10) : undefined))
                                    :
                                        (index === 0 && date === item.date?.slice(0,10))
                                        ?
                                            <div style={{fontSize:"small", color:theme.palette.secondary.main, textAlign:'center', marginTop: 10}} 
                                                onClick = { () =>  this.setState({
                                                    ['showComment_'+item.key]: this.state['showComment_'+item.key]? false : true 
                                                })}
                                                >
                                                <IconButton color="secondary" onClick={this.handleOpen} style={{padding: 2}}>
                                                    <SpeakerNotesIcon fontSize="small" style={{color: theme.palette.secondary.main}}/>
                                                </IconButton>
                                                <br/>
                                                {this.state['showComment_'+item.key]
                                                    ? 'Cancel'
                                                    : 'Add Note'
                                                }
                                            </div>
                                        :   ''
                                }
                            </TimelineOppositeContent>
                            <TimelineSeparator style={{margin: 0}}>
                                <TimelineConnector />
                                {item.date !== undefined 
                                    ?
                                        <div style={{margin: 0, transform: 'scale(0.7)'}}>
                                            <DateBadge
                                                valueMonth={months[parseInt(item.date.slice(5,7))-1]} 
                                                valueDay={item.date.slice(8,10)}
                                            /> 
                                        </div>
                                    :
                                        <TimelineDot/>
                                }
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent style={{textAlign: 'center'}}>
                                {(item.value > 49 || item.value === undefined)
                                    ?   this.returnSmiley(item.value, (date !== item.date?.slice(0,10) ? item.date?.slice(0,10) : undefined))
                                    :   
                                        (index === 0 && date === item.date?.slice(0,10))
                                        ?
                                            <div style={{fontSize:"small", color:theme.palette.themeRed, textAlign:'center', marginTop: 10}} 
                                                onClick = { () =>  this.setState({
                                                    ['showComment_'+item.key]: this.state['showComment_'+item.key]? false : true 
                                                })}
                                                >
                                                <IconButton color="secondary" onClick={this.handleOpen} style={{padding: 2}}>
                                                    <SpeakerNotesIcon fontSize="small" style={{color: theme.palette.themeRed}}/>
                                                </IconButton>
                                                <br/>
                                                {this.state['showComment_'+item.key]
                                                    ? 'Cancel'
                                                    : 'Add Note'
                                                }
                                            </div>
                                        :   ''
                                            
                                }
                            </TimelineContent>
                        </TimelineItem>
                    </div>
                ))}
                <canvas id={"canvas_timeline"} style={{position: 'absolute', zIndex:-1, width: '100%', height: '100%'}} width="100%" height="100%">
                </canvas>
            </Timeline>
        )
    }

    returnSmiley(value, date = undefined) {
        const {theme} = this.props;
        if (value > 75 ) return (
            <div 
                onClick={() => this.setState({openDialogue: true, messageWeek: date})}
                style={{ 
                    position: 'relative',
                    top: 10,
                    //left: "calc("+(value/2)+"% - 14px)",
                    left: "40%",
                    fontSize: 'small', 
                    color: theme.palette.themeAlertGreen }}
            >
                    <SentimentVerySatisfiedIcon fontSize="small" style={{color: theme.palette.themeAlertGreen}}/>
                    <br/>
                    Show
           </div>
        )
        else if (value > 60 ) return(
            <div
                onClick={() => this.setState({openDialogue: true,  messageWeek: date})} 
                style={{ 
                    position: 'relative',
                    top: 10,
                    //left: "calc("+(value/2)+"% - 14px)",
                    left: "-5%",
                    fontSize: 'small', 
                    color: theme.palette.secondary.light}} 
            >
                    <SentimentSatisfiedAltIcon fontSize="small" style={{color: theme.palette.secondary.light}}/>
                    <br/>
                    Show
           </div>
        )
        else if (value !== undefined && value < 20 ) return(
            <div 
                onClick={() => this.setState({openDialogue: true, messageWeek: date})}
                style={{ 
                    position: 'relative',
                    top: 10,
                    left: "-40%",
                    fontSize: 'small', 
                    color: theme.palette.themeRed }} 
            >
                    <SentimentVeryDissatisfiedIcon fontSize="small" style={{color: theme.palette.themeRed}}/>
                    <br/>
                    Show
            </div>
        )
        else if (value !== undefined && value < 40 ) return(
            <div
                onClick={() => this.setState({openDialogue: true, messageWeek: date })}
                style={{ 
                    position: 'relative',
                    top: 10,
                    fontSize: 'small', 
                    color: 'orange'}} 
            >
                    <SentimentDissatisfiedIcon fontSize="small" style={{color: 'orange'}}/>
                    <br/>
                    Show
            </div>
        )
        else if (value !== undefined && value >= 50 ) return(
            <div 
                onClick={() => this.setState({openDialogue: true, messageWeek: date})}
                style={{ 
                    position: 'relative',
                    top: 10,
                    left: "-40%",
                    fontSize: 'small', 
                    color: theme.palette.secondary.main}} 
            >
                    <SentimentSatisfiedIcon fontSize="small" style={{color: theme.palette.secondary.main}}/>
                    <br/>
                    Show
            </div>
        )
        else if (value === undefined) return (
            <></>
        )
        else return (
            <div 
                onClick={() => this.setState({openDialogue: true, messageWeek: date})}
                style={{ 
                    position: 'relative',
                    top: 10,
                    left: "40%",
                    fontSize: 'small', 
                    color: theme.palette.secondary.main}} 
            >
                    <SentimentSatisfiedIcon fontSize="small" style={{color: theme.palette.secondary.main}}/>
                    <br/>
                    Show
            </div>
        )
    }

    addNewComment = (key, question, category='goals') => {
        var comment = this.state['newComment_'+key];
        //console.log('comment', comment, key)
        this.setState({ ['showComment_'+key] : false })
        if(this.props.updateComment !== undefined){
            this.props.updateComment(comment);
        }
        else if(comment !== this.state.cacheComment){
            var d = new Date();    
            var date = new Date(d.getTime() - d.getTimezoneOffset() * 60000).toISOString().slice(0,10);
            var updateSet = {
                createdAt: this.props.firebase.fieldValue.serverTimestamp(),
                date: date,
                scale: '100%',
                measure: 'satisfaction',
                comment: comment,
                source: 'self',
                key: key,
                userId: this.props.user.uid
            }
            updateSet['messageId'] = this.props.assessmentKey;
            //console.log('comment', comment, key, date, this.props.user.uid)
            this.props.firebase.insightAssessment(this.props.user.uid, date+'_'+category+'_'+key ).set( updateSet , {merge:true});
            if(this.props.updateScore!== undefined){
                this.props.updateScore(75);
            }
            ExtendDialogueMessage(this.props.firebase, this.props.user, comment, 'What is the status of: '+ this.props.assessmentText, 'utter_ask_assessment', '', 'profile');
            var engagement = {
                userId: this.props.user.uid,
                points: 2,
                type:'followUp',
                message: comment,
                origin: 'days',
            }
            if(this.props.user?.leadership_category !== undefined){
                engagement['leadership_category'] = this.props.user.leadership_category;
            }
            if(this.props.user?.leadership_quality !== undefined){
                engagement['leadership_quality'] = this.props.user.leadership_quality;
            }
            AddEngagementPoints(this.props.firebase, engagement);
        }
    }
}

export default compose(
    withFirebase,
    withStyles(styles, { withTheme: true })
)(AssessmentTimeline);
