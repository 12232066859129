import React, { Component } from 'react';
import { compose } from 'recompose';
import { Link } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import { withFirebase } from '../Firebase';
import { AuthUserContext } from '../Session';
import LanguageManager, { LanguageSets } from '../../constants/languages';

import ShowVouchersForGroup from '../Coach/ShowVouchersForGroup';
import Switch from "react-switch";
import List from '@material-ui/core/List';

import {DataCacheWrite} from "../Modules/DataCache";
import { FindTextValueInDict, FindDictByKey } from '../Modules/LoadCategoriesAndQualities';
import LoadingProgress from '../Modules/LoadingProgress';

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

///
import logo from "../../images/rocky-logo-square.png";
import logoBrand from "../../images/my-ai-coach-logo.png";
import pixel from "../../images/pixel.png";
import backgroundImageSignup from "../../images/bluerunningtrack3.jpg";

//UI
import Button from '@material-ui/core/Button';
import AssignmentIcon from '@material-ui/icons/Assignment';
import TextField from '@material-ui/core/TextField';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionActions';
import AccordionSummary from '@material-ui/core/AccordionSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import LinkIcon from '@material-ui/icons/Link';
import SettingLanguage from '../Profile/SettingLanguage';
// Modules for parameter change
import SettingColorPicker from '../Admin/Modules/SettingColorPicker';
import SettingContentBase from '../Admin/Modules/SettingContentBase';
import SettingContentActive from '../Admin/Modules/SettingContentActive';
import SettingContentNumber from '../Admin/Modules/SettingContentNumber';
import SettingContentArray from '../Admin/Modules/SettingContentArray';
import SettingContentRichText from '../Admin/Modules/SettingContentRichText';
import SettingContentTime from '../Admin/Modules/SettingContentTime';
import SettingContentArraySelect from '../Admin/Modules/SettingContentArraySelect';
import SettingContentArrayCheckbox from '../Admin/Modules/SettingContentArrayCheckbox';
import SettingContentSelect from '../Admin/Modules/SettingContentSelect';
import SettingContentUploadImage from '../Admin/Modules/SettingContentUploadImage';
import SettingContentMapSelect from '../Admin/Modules/SettingContentMapSelect';
import SettingContentReverseMapList from '../Admin/Modules/SettingContentReverseMapList';
import SettingContentSelectCombi from '../Admin/Modules/SettingContentSelectCombi';

import _ from "lodash";
import { ExternalHelpLink } from '../Modules/ExternalLinks';
import { AccessCheckCoachCreator } from '../../constants/roles';


const styles = theme => ({
    grow: {
        flexGrow: 1, 
    },
})

class CoachGroupSetting extends Component {
    static contextType = AuthUserContext;
    constructor(props) {
        super(props);
        //console.log('probs in JournalWeek ',this.probs);
        this.state = {
            loading: false,
            loadingText: 'loading group',
            group: null,
        };
    }

    createObjectId () {
        return this.createHex(Date.now() / 1000) +
            ' '.repeat(16).replace(/./g, () => this.createHex(Math.random() * 16))
    }
    createHex (value) {
        return Math.floor(value).toString(16)
    }

    componentDidMount() {
        this.setState({ loading: true, datebatchId : this.createObjectId() });
        if(this.props.groupId !== null && this.props.groupId !== undefined && this.props.newGroup !== true ){
            this.loadGroup(this.props.groupId)
        }
        else if(this.props.newGroup){
            this.setState({
                newGroup : true,
                loading: false,
            });
            var preCode = this.context.username;
            preCode = "#"+preCode.toUpperCase().replace(/[^A-Z0-9]/ig, "");
            if(this.state.newGroupCode===undefined){
                this.setState({newGroupCode:preCode})
            }
        }

        var availableCoachPrograms = ['default'];
        var defaultProgramName = "'Essentials' Program of Rocky.ai";
        var availableCoachProgramsText = [defaultProgramName];
        if(this.context.programs!==undefined && this.context.programs.length>0){
            availableCoachPrograms = [];
            availableCoachProgramsText = [];
            this.context.programs.map(item => {
                var text = item;
                //console.log('item', item, this.props)
                if(this.props.defaultSet?.programCode === item){
                    text = (this.props.defaultSet?.title || "") + " ("+item+")"
                }
                else{
                    text = FindTextValueInDict(this.props.firebase, this.props.leadershipCategories, item);
                }
                ///// add thee programs
                if(item === 'default'){
                    text = defaultProgramName;
                    availableCoachPrograms.unshift(item);
                    availableCoachProgramsText.unshift(text);
                }
                else{
                    availableCoachPrograms.push(item);
                    availableCoachProgramsText.push(text);
                }
                return true;
            })
            this.setState({
                availableCoachPrograms,
                availableCoachProgramsText,
            })
        }
        else{
            this.setState({
                availableCoachPrograms,
                availableCoachProgramsText,
            })
        }
    }

    loadGroup = (groupId) => {
        if(groupId?.length > 0){
            this.unsubscribe = this.props.firebase
            .group(groupId)
            .onSnapshot(snapshot => {
                var groupData=snapshot.data();
                if(groupData){
                    var previousPrograms = this.state.loadedPrograms;
                    var myPrograms = ['default']
                    if(groupData.programs!== undefined) {
                        myPrograms= groupData.programs
                    }
                    this.setState({
                        loadedPrograms: myPrograms,
                        group: groupData,
                        groupId: groupId,
                        loading: false,
                    });
                    if(this.state.leadershipQualitiesItemsText===undefined || !(_.isEqual(previousPrograms,myPrograms))){
                        this.setState({
                            loading: true,
                        });
                        //console.log('updating the filters', _.isEqual(previousPrograms,myPrograms), this.state.leadershipQualitiesItemsText)
                        this.filterQualities(myPrograms);
                        this.filterCategories(myPrograms);
                        this.setState({
                            loading: false,
                        });
                    }
                }
                else{
                    this.setState({
                        loading: false,
                    });
                }
            });
        }
        else{
            this.setState({
                loading: false,
            });
        }
    }

    filterCategories(userPrograms){
        var myArray = [];
        var myArrayText = [];
        var availableProgramIcons = [];
        this.props.leadershipCategories.map((item, index) => {
            var myPrograms = 'default'
            if(item.program!== undefined) myPrograms= item.program;
            if(userPrograms?.includes(myPrograms) && item.active){
                myArray.push(item.value);
                //myArrayText.push(item.text + " ("+ (item.program==="default"? "Rocky" : item.program)  + ")");
                var text = item.text + (item.program==="default"? ' (Rocky)' : '');
                myArrayText.push(text);
                if(item.program !== 'default' && item.icon!== undefined && item.icon!== '' && !availableProgramIcons.includes(item.icon)){
                    availableProgramIcons.push(item.icon);
                }
            };
            this.setState({
                leadershipCategoriesItems: myArray,
                leadershipCategoriesItemsText: myArrayText,
                availableProgramIcons
            });
            return true
        })
    }
    filterQualities(userPrograms){
        var myArray = [];
        var myArrayText = [];
        this.props.leadershipQualities.map((item, index) => {
            var myPrograms = 'default'
            if(item.program!== undefined) {
                myPrograms= item.program
            }
            if(userPrograms?.includes(myPrograms) && item.active){
                if(item.valueID?.length > 0){
                    myArray.push(item.valueID);
                }
                else{
                    myArray.push(item.value);
                }
                var textCategory = FindTextValueInDict(this.props.firebase, this.props.leadershipCategories, item.category );
                myArrayText.push(item.text + " (" + textCategory + ")");
            };
            this.setState({
                leadershipQualitiesItems: myArray,
                leadershipQualitiesItemsText: myArrayText
            });
            return true
        })
    }

    componentWillUnmount() {
        if (this.unsubscribe!== undefined){
            this.unsubscribe();
        }
        this.setState({ loading: false, group: null, newGroup: false });
    }

    updateBrandCache(content, refresh=false){
        //console.log('first instance', content, this.props)
        if(content?.partnerProgram === true && !(content?.programCode?.includes("#"))){
            console.log('missing conteent code', content.code)
        }
        if(this.props.refreshDefaultSet !== undefined && content.partnerProgram === true ){
            this.props.refreshDefaultSet(content, refresh)
            .then(() => {
                if(refresh){
                    //console.log('refresh codee', refresh)
                    //window.location.reload();
                }
            });
        }
    }

    updateQualityCache(content){
        var qualities = this.props.leadershipQualities;
        var qid = content.valueID || content.value;
        qualities = qualities.filter(c => c.valueID !== qid);
        content['valueID'] = qid;
        qualities.unshift(content);
        //console.log('qualities', qid, qualities, this.props.leadershipQualities)
        DataCacheWrite('leadershipQualities', qualities, 1800);
        if(this.props.refreshQualities !== undefined){
            //console.log('refreesh qualities', content, qid, qualities, this.props.leadershipQualities)
            this.props.refreshQualities(qualities);
        }
    }

    render(){
        //console.log('coachgroup settings', this.state)
        const {theme} = this.props;
        const {group, newGroup} = this.state;
        if(newGroup){
            return(
                <div>
                    <p>
                        {this.props.partnerProgram
                            ?   "Brand and App Name"
                            :   "Team Name (Only internal)"
                        }
                    </p>
                    {this.props.partnerProgram 
                        ? 
                            ExternalHelpLink(theme, "Add your brand", "https://help.rocky.ai/rocky-for-creators/add-your-own-brand-basic-brand-settings-and-identity", 'Help Brand')
                        :   ""
                    }
                    <p style={theme.textSupport}>
                        {this.props.partnerProgram
                            ?   "Define the name of your brand or AI coaching program."
                            :   "Give your team a name, so that you can keep an overview of your groups and teams."
                        }
                    </p>
                    <strong>
                        {this.props.partnerProgram
                            ?   "Brand Name"
                            :   "Team Name"
                        }
                    </strong>
                    <TextField
                        fullWidth
                        id="newGroupName"
                        style={ {fontSize: '16px'} }
                        //label={this.props.partnerProgram  ?   "Brand Name"  :   "Team Name"   }
                        variant="outlined"
                        value={this.state.newGroupName || ''}
                        onChange={ (event) => {
                            this.setState({newGroupName: event.target.value});
                            this.changeCodeName(event);
                        }}
                    />
                    <p style={theme.textSupport}>
                        For example: Happy Mind AI Coach <br/>
                        <li><strong>can be modified at any time</strong></li> <br/>
                    </p>
                    {this.props.partnerProgram 
                        ? 
                            ExternalHelpLink(theme, "Add your domain", "https://help.rocky.ai/rocky-for-creators/add-your-own-brand-set-up-domain-code", 'Help Domain')
                        :   ""
                    }
                    {this.props.partnerProgram
                        ?   ""
                        :
                            <SettingContentSelect 
                                title={"Assigned Group"} 
                                mandatory={false}
                                labels={["Sort team into a group"]}
                                content={{parentGroup: this.state.parentGroup}}
                                addNewOption={true}
                                max={30}
                                params={['parentGroup']}
                                selectOptions={this.props.coachGroupsNames || []}
                                updateState={(resultDict) => {
                                    if(resultDict?.parentGroup?.length > 2){
                                        this.setState({parentGroup: resultDict.parentGroup})
                                    }
                                }}
                            />
                    }
                    <strong>
                        {this.props.partnerProgram
                            ?   "Brand-code"
                            :   "Access-Code"
                        }
                    </strong>
                    <TextField
                        fullWidth
                        id="newCodeName"
                        variant="outlined"
                        style={ {fontSize: '16px'} }
                        //label="Code for User Access"
                        value={this.state.newGroupCode || "#"}
                        onChange={ this.changeCodeName }
                    />
                    <p style={theme.textSupport}>
                        For example: #HAPPINESS
                        <br/>
                        The {this.props.partnerProgram ? "domain" : "coach"}-code:
                        <li>is necessary to connect with your users</li>
                        {this.props.partnerProgram 
                            &&
                            <li>is necessary to set up your exclusive domain</li>
                        }
                        <li>has to be minimum 6 charcters long</li>
                        <li>cannot include the words 'Rocky', 'Coaching', 'Coach'</li>
                        <li><strong>cannot be modified afterwards!</strong></li>
                    </p>
                    {(this.props.allowInherit !== false && this.context.programCode?.length > 2)
                        &&
                            <>
                                <p>
                                    <br/>Inherit Team from main Domain-settings: <strong>{this.context.programCode}</strong>
                                </p>
                                <p style={theme.textSupport}>
                                    The initial settings will be derived from the ditital twin settings.
                                </p>
                                <Grid container spacing={0} direction='row' alignItems="center">
                                    <Grid item style={{textAlign:'center'}}>
                                        <Radio
                                            checked={this.state.inheritDigitalTwin || this.state.inheritDigitalTwin===undefined}
                                            onChange={(event)=>{this.setState({inheritDigitalTwin: true})}}
                                            value={true}
                                            name="radio-button-twin"
                                            inputProps={{ 'aria-label': 'Yes' }}
                                        /> 
                                        <br/>
                                        <div>Inherit</div>
                                    </Grid>
                                    {(this.context.roles?.includes("ADMIN") || this.context.roles?.includes("TEAM")) && 
                                        <Grid item style={{textAlign:'center'}}>
                                            <Radio
                                                checked={this.state.inheritDigitalTwin===false}
                                                onChange={(event)=>{this.setState({inheritDigitalTwin: false})}}
                                                value={false}
                                                name="radio-button-notwin"
                                                inputProps={{ 'aria-label': 'No, other settings' }}
                                            /> 
                                            <br/>
                                            <div>New</div>
                                        </Grid>
                                    }
                                </Grid>
                            </>
                    }
                    {((this.state.inheritDigitalTwin===false || !(this.context.programCode?.length > 2)) && (this.context.programs?.lenght>0 || !this.context.programs?.includes('default')))
                        ?
                            <>
                                <p>
                                    <br/>Choose the primary program pool:
                                </p>
                                <p style={theme.textSupport}>
                                    If you want to make premium content available for your teams, you have to have access to those programs first.
                                </p>
                                <Select
                                    value={this.state.newGroupProgrammMain !== undefined ? this.state.newGroupProgrammMain : 'default' }
                                    onChange={(e) => {this.setState({newGroupProgrammMain:e.target.value})}}
                                    name='newGroupProgrammMain_option'
                                    >
                                    {this.state.availableCoachPrograms!==undefined && this.state.availableCoachPrograms!==null && this.state.availableCoachPrograms.length>0
                                        ?
                                            this.state.availableCoachPrograms.map((entry_option, index) => {
                                                return (
                                                    <MenuItem key={index+"_add_"+entry_option} value={entry_option} >
                                                        {this.state.availableCoachProgramsText[index]}
                                                    </MenuItem>
                                                );
                                            })
                                        :
                                            <MenuItem key={"defaultprogram"} value={"default"} >
                                                All 'Essentials' programs of Rocky.ai (People, Productivity, ...)
                                            </MenuItem>
                                    }
                                </Select>
                            </>
                        :
                            <p>
                                &nbsp;
                            </p>
                    }

                    {!(this.state.newGroupName?.length > 2 && this.state.newGroupCode?.length > 2)
                    ?   <p style={theme.textErrorCenterPadding}> <br/> Please add the Brand Name and the preferred Brand-code </p>
                    :   this.state.errorMessage !== '' && this.state.errorMessage !== undefined
                        ?   <p style={theme.textErrorCenterPadding}> <br/> {this.state.errorMessage}  </p>
                        :   <p> </p>
                    }

                    <Button 
                        disabled={!(this.state.newGroupName?.length > 2 && this.state.newGroupCode?.length > 2)}
                        onClick={this.addNewTeam}>
                        Create<AssignmentIcon fontSize="small"/>
                    </Button>
                </div>
            )
        }
        else if(group!== null && group!== undefined){
            return(
                <div>
                    <strong>
                    {group.programURL?.includes('http')
                        &&
                            <Link
                                to="#"
                                onClick={ () => window.open(group.programURL, "webapp")}
                            >
                                <LinkIcon style={theme.inlineIconLeft}/>&nbsp;{group.programURL}
                            </Link>
                        }
                    </strong>
                    <h4>{group.programTitle}</h4>
                    {
                        this.renderCurrentCode(group)
                    }
                    {this.props.onlyDefaultUser !== true && ((this.context.purchasedProduct?.creator === true || this.context?.roles?.includes('ADMIN') || this.context.roles?.includes('TEAM')) || (this.context.roles?.includes('COACHADMIN') && group.partnerProgram === true ) )
                    &&
                        this.renderBrandSettings(group)
                    }
                    {this.props.onlyDefaultUser !== true && this.state.loadedPrograms?.length>=1
                    && (( (this.context.purchasedProduct?.creator === true || this.context?.roles?.includes('ADMIN') || this.context.roles?.includes('TEAM')) || (this.context.roles?.includes('COACHADMIN')) ) && group.partnerProgram === true )
                    &&
                        <Accordion key="logo"  id={`advanced-logo`} 
                            onChange={(e,expanded) => {this.setState({additionalAdvExpanded: expanded, showAdvanced: false})}} TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="advanced-logo"
                                id="logo"
                            >
                                <div style={theme.divFullWidth}>
                                    {group.programLogo !== undefined && group.programLogo !== ''
                                        ?   <img width={30} src={group.programLogo} alt={group.code} style={{marginRight: 10}}/>
                                        :   <img width={30} src={logo} alt={group.code} style={{marginRight: 10}}/>
                                    }
                                    <span>Icon and Brand Color Settings *</span>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                                {this.state.additionalAdvExpanded &&
                                    <Grid container spacing={0} direction='column' >
                                        <div style={{...theme.textSupport, padding: 20}}>
                                            Program App Icon used on home screen / browser icon / loading screens
                                            <br/><br/><strong>Tip:</strong> Have the icon designed as a transparent PNG, ideally as pure symbol without text, and ensure that it works well on light backgrounds.
                                            <br/><br/><strong>Tip:</strong> You can add your full brand logo below
                                        </div>
                                        <div style={{marginBottom:30}}>
                                            <Grid container spacing={0} direction='row' alignItems="center">
                                                <Grid item style={{textAlign:'center'}}>
                                                    <Radio
                                                        checked={group.programLogo === undefined || group.programLogo===""}
                                                        onChange={(event)=>{this.addLogoToGroup(event.target.value)}}
                                                        value={""}
                                                        name="radio-button-default"
                                                        inputProps={{ 'aria-label': 'default' }}
                                                    /> 
                                                    <br/>
                                                    <div>Rocky</div>
                                                    <br/>
                                                    <img width={100} src={logo} alt={"Rocky.ai"} />
                                                </Grid>
                                                {(group.programLogo !== undefined && group.programLogo !=="")
                                                    && (this.state.availableProgramIcons===undefined || !this.state.availableProgramIcons.includes(group.programLogo))
                                                    &&
                                                    <Grid item  style={{textAlign:'center'}}>
                                                        <Radio
                                                            checked={true}
                                                            onChange={(event)=>{this.addLogoToGroup(event.target.value)}}
                                                            value={group.programLogo}
                                                            name="radio-button-default"
                                                            inputProps={{ 'aria-label': 'current' }}
                                                        /> 
                                                        <br/>
                                                        <div>Current</div>
                                                        <br/>
                                                        <img width={100} src={group.programLogo} style={{borderRadius: theme.borderRadius || 12}}  alt={"Rocky.ai"} />
                                                    </Grid>
                                                }
                                                {this.state.availableProgramIcons!==undefined && this.state.availableProgramIcons.map( (icon, index) => (
                                                    <Grid item key={'icons_'+index} style={{textAlign:'center'}}>
                                                        <Radio
                                                            checked={icon === group.programLogo}
                                                            onChange={(event)=>{this.addLogoToGroup(event.target.value)}}
                                                            value={icon}
                                                            name="radio-button-demo"
                                                            inputProps={{ 'aria-label': 'Alternative '+index }}
                                                        />
                                                        <br/>
                                                        <div>Archive</div>
                                                        <br/>
                                                        <img width={100} src={icon} style={{borderRadius: theme.borderRadius || 12}} alt={'Alternative '+index} />
                                                    </Grid>
                                                ))}
                                                {(this.context.programLogo !== undefined && this.context.programLogo !=="")
                                                    && (this.state.availableProgramIcons===undefined || !this.state.availableProgramIcons.includes(this.context.programLogo))
                                                    && this.context.programLogo !== group.programLogo
                                                    &&
                                                    <Grid item  style={{textAlign:'center'}}>
                                                        <Radio
                                                            checked={this.context.programLogo === group.programLogo}
                                                            onChange={(event)=>{this.addLogoToGroup(event.target.value)}}
                                                            value={this.context.programLogo}
                                                            name="radio-button-demo"
                                                            inputProps={{ 'aria-label': 'Brand'}}
                                                        />
                                                        <br/>
                                                        <div>Saved</div>
                                                        <br/>
                                                        <img width={100} src={this.context.programLogo} alt={'Brand'} />
                                                    </Grid>
                                                }
                                                {(group.teamProgramLogo !== undefined && group.teamProgramLogo !=="")
                                                    && (this.state.availableProgramIcons===undefined || !this.state.availableProgramIcons.includes(this.context.teamProgramLogo))
                                                    && group.teamProgramLogo !== group.programLogo
                                                    &&
                                                    <Grid item  style={{textAlign:'center'}}>
                                                        <Radio
                                                            checked={group.teamProgramLogo === group.programLogo}
                                                            onChange={(event)=>{this.addLogoToGroup(event.target.value)}}
                                                            value={group.teamProgramLogo}
                                                            name="radio-button-demo"
                                                            inputProps={{ 'aria-label': 'Team'}}
                                                        />
                                                        <br/>
                                                        <div>Team</div>
                                                        <br/>
                                                        <img width={100} src={group.teamProgramLogo} alt={'Team'} />
                                                    </Grid>
                                                }
                                            </Grid>
                                        </div>
                                        <SettingContentUploadImage
                                            dbUpdateLink={this.props.firebase.group(this.state.groupId)}
                                            //dbUpdateLink={this.props.firebase.user(this.context.uid)}
                                            title={"Upload new App Favicon (512x512px, transparent, png-format)"} 
                                            mandatory={true}
                                            imgDef={"Square format, PNG file, max. 250kb, transparent background"}
                                            exampleImage={logo}
                                            imgExtensionList={[".png"]}
                                            imgFormat={"square"}
                                            previewImageSize={true}
                                            imgWidth={512}
                                            content={group} 
                                            params={['programLogo']}
                                            noLabel={true}
                                            //noLabel={(this.context?.roles?.includes('ADMIN') || this.context.roles?.includes('TEAM')) ? false : true}
                                            uploadDatabase={this.props.firebase.storageImages('programs/'+group.code.replace('#',''))}
                                            updateState={(resultDict) => {
                                                var allContentDict = Object.assign({}, group, resultDict)
                                                this.updateBrandCache(allContentDict, true);
                                            }}
                                        />
                                        {false && this.context.roles!== undefined  && ( this.context.roles?.includes("ADMIN") || this.context.roles?.includes("COACHADMIN") || this.context.roles?.includes("TEAM") ) 
                                            &&
                                            <SettingContentUploadImage
                                                dbUpdateLink={this.props.firebase.user(this.context.uid)}
                                                title={"Upload New App Icon (512x512px, transparent, png-format)"}
                                                mandatory={true}
                                                content={this.context} 
                                                imgFormat={"square"}
                                                previewImageSize={true}
                                                imgWidth={512}
                                                params={['programLogo']}
                                                noLabel={true}
                                                //noLabel={(this.context?.roles?.includes('ADMIN') || this.context.roles?.includes('TEAM')) ? false : true}
                                                uploadDatabase={this.props.firebase.storageImages('programs/'+group.code.replace('#',''))}
                                                updateState={(resultDict) => {
                                                    var allContentDict = Object.assign({}, group, resultDict)
                                                    this.updateBrandCache(allContentDict, true);
                                                }}
                                            />
                                        }
                                        <div style={{...theme.textSupport, padding: 20}}>
                                            The program brand image/logo is different then the icon as it is used in reference with your company information.
                                            <br/><br/><strong>Tip:</strong> Upload your brand logo here. Ensure that it has some padding towards the edges and that it ideally works well on light backgrounds.
                                        </div>
                                        <SettingContentUploadImage
                                            dbUpdateLink={this.props.firebase.group(this.state.groupId)}
                                            title={"Upload Brand Image/Logo (512px width, transparent, jpg, png, gif)"} 
                                            mandatory={false}
                                            content={group} 
                                            exampleImage={logoBrand}
                                            previewImageSize={true}
                                            noLabel={true}
                                            params={['programBrand']}
                                            uploadDatabase={this.props.firebase.storageImages('programs/coaching_'+group.code.replace('#','brand'))}
                                            updateState={(resultDict) => {
                                                var allContentDict = Object.assign({}, group, resultDict)
                                                this.updateBrandCache(allContentDict, true);
                                            }}
                                        />
                                        {this.props.showAdvancedToggle 
                                        &&
                                            <div 
                                                style={{textAlign: 'right', marginBottom: 20}}
                                                onClick={() => this.setState({showAdvanced: (this.state.showAdvanced ? false : true)})}
                                            >
                                                <span style={{color: theme.palette.secondary.main}}>
                                                    {this.state.showAdvanced
                                                        ?   "Hide Advanced Settings"
                                                        :   "Show Advanced Settings"
                                                    }
                                                </span>
                                            </div>
                                        }
                                        {(this.props.showAdvancedToggle !== true || this.state.showAdvanced )
                                        &&
                                            <>
                                                <div style={{...theme.textSupport, padding: 20}}>
                                                    Personalize your app's base colors! Adjust the colors here to create a unique look for your users.
                                                    <br/><br/><strong>Tip:</strong> Opt for calmer colors for optimal readability. Remember, these colors won't affect the dark-mode experience.
                                                </div>
                                                {(this.context?.roles?.includes('ADMIN'))
                                                &&
                                                    <SettingContentBase
                                                        dbUpdateLink={this.props.firebase.group(this.state.groupId)}
                                                        title={"Custom Colors"} 
                                                        mandatory={false}
                                                        content={group} 
                                                        labels={["Dominant Info Color (Teal)", "Light Color for Text Background (Lightgrey)", "Button Color for White Text (Blue)", "Dark Background (Darkblue)"]}
                                                        params={['programColorInfo', 'programColorFront', 'programColorButton', 'programColorBack']}
                                                        updateState={(resultDict) => {
                                                            var allContentDict = Object.assign({}, group, resultDict)
                                                            this.updateBrandCache(allContentDict, true);
                                                        }}
                                                    />
                                                }
                                                <SettingColorPicker
                                                    dbUpdateLink={this.props.firebase.group(this.state.groupId)}
                                                    title={"Primary Color"} 
                                                    mandatory={true}
                                                    checkColorDark={true}
                                                    content={group} 
                                                    defaultValue={theme.palette.themeBlue}
                                                    noLabel={true}
                                                    infoText={["Darker tone - Used for buttons, links and call-to-actions"]}
                                                    hexColorFormat={true}
                                                    params={['programColorButton']}
                                                    updateState={(resultDict) => {
                                                        var allContentDict = Object.assign({}, group, resultDict)
                                                        this.updateBrandCache(allContentDict, true);
                                                    }}
                                                />
                                                <SettingColorPicker
                                                    dbUpdateLink={this.props.firebase.group(this.state.groupId)}
                                                    title={"Secondary Color"} 
                                                    mandatory={true}
                                                    checkColorDark={true}
                                                    content={group}
                                                    infoText={["Darker tone - Used for info text, and for active tabs"]}
                                                    defaultValue={theme.palette.themeTeal}
                                                    noLabel={true}
                                                    hexColorFormat={true}
                                                    params={['programColorInfo']}
                                                    updateState={(resultDict) => {
                                                        var allContentDict = Object.assign({}, group, resultDict)
                                                        this.updateBrandCache(allContentDict, true);
                                                    }}
                                                />
                                                <SettingColorPicker
                                                    dbUpdateLink={this.props.firebase.group(this.state.groupId)}
                                                    title={"Tertiary Color"} 
                                                    mandatory={true}
                                                    checkColorLight={true}
                                                    content={group} 
                                                    defaultValue={theme.palette.themeGold}
                                                    noLabel={true}
                                                    infoText={["Lighter tone - Used for highlighting fields and hover of areas"]}
                                                    hexColorFormat={true}
                                                    params={['programColorHover']}
                                                    updateState={(resultDict) => {
                                                        var allContentDict = Object.assign({}, group, resultDict)
                                                        this.updateBrandCache(allContentDict, true);
                                                    }}
                                                />
                                                <SettingColorPicker
                                                    dbUpdateLink={this.props.firebase.group(this.state.groupId)}
                                                    title={"Accent Shade Color"} 
                                                    mandatory={false}
                                                    infoText={["Used as background color for text body"]}
                                                    checkColorLight={true}
                                                    content={group} 
                                                    defaultValue={theme.palette.themeLightGrey}
                                                    noLabel={true}
                                                    hexColorFormat={true}
                                                    params={['programColorFront']}
                                                    updateState={(resultDict) => {
                                                        var allContentDict = Object.assign({}, group, resultDict)
                                                        this.updateBrandCache(allContentDict, true);
                                                    }}
                                                />
                                                <SettingColorPicker
                                                    dbUpdateLink={this.props.firebase.group(this.state.groupId)}
                                                    title={"Light Background of Content Window"} 
                                                    mandatory={false}
                                                    checkColorLight={true}
                                                    content={group} 
                                                    defaultValue={theme.palette.themeNaviGrey}
                                                    noLabel={true}
                                                    hexColorFormat={true}
                                                    params={['programColorPrimary']}
                                                    updateState={(resultDict) => {
                                                        var allContentDict = Object.assign({}, group, resultDict)
                                                        this.updateBrandCache(allContentDict, true);
                                                    }}
                                                />
                                                <SettingColorPicker
                                                    dbUpdateLink={this.props.firebase.group(this.state.groupId)}
                                                    title={"Dark Background Color of the Navigation"} 
                                                    mandatory={false}
                                                    checkColorDark={true}
                                                    content={group} 
                                                    defaultValue={theme.palette.themeDarkBlue}
                                                    noLabel={true}
                                                    hexColorFormat={true}
                                                    params={['programColorBack']}
                                                    updateState={(resultDict) => {
                                                        var allContentDict = Object.assign({}, group, resultDict)
                                                        this.updateBrandCache(allContentDict, true);
                                                    }}
                                                />
                                                <SettingContentMapSelect
                                                    dbUpdateLink={this.props.firebase.group(this.state.groupId)}
                                                    title={"Dark-mode"} 
                                                    mandatory={false}
                                                    content={group} 
                                                    params={['features']}
                                                    noLabel={true}
                                                    mapOptions={['darkmode']}
                                                    mapOptionsText={['Start with Dark-mode']}
                                                    infoText={["New users start with dark-mode"]}
                                                    selectOptionsText={['Yes', 'No' ]}
                                                    selectOptions={[true, false]}
                                                />
                                                <SettingContentUploadImage
                                                    dbUpdateLink={this.props.firebase.group(this.state.groupId)}
                                                    title={"Upload App Teaser Image (min. 600px width as png, gif, jpg)"} 
                                                    mandatory={false}
                                                    content={group} 
                                                    params={['programImage']}
                                                    noLabel={(this.context?.roles?.includes('ADMIN') || this.context.roles?.includes('TEAM')) ? false : true}
                                                    uploadDatabase={this.props.firebase.storageImages('programs/coaching_'+group.code.replace('#','twin_teaser_'))}
                                                    updateState={(resultDict) => {
                                                        var allContentDict = Object.assign({}, group, resultDict)
                                                        this.updateBrandCache(allContentDict, true);
                                                    }}
                                                />
                                                <SettingContentUploadImage
                                                    dbUpdateLink={this.props.firebase.group(this.state.groupId)}
                                                    title={"Upload Background Image for Login-Screen (min. 1200px width as gif, jpg, max. 700kb)"} 
                                                    mandatory={false}
                                                    content={group} 
                                                    defaultValue={backgroundImageSignup}
                                                    params={['programBGImage']}
                                                    imgMinWidth={1200}
                                                    noLabel={(this.context?.roles?.includes('ADMIN') || this.context.roles?.includes('TEAM')) ? false : true}
                                                    uploadDatabase={this.props.firebase.storageImages('programs/coaching_'+group.code.replace('#','twin_bg_'))}
                                                    updateState={(resultDict) => {
                                                        var allContentDict = Object.assign({}, group, resultDict)
                                                        this.updateBrandCache(allContentDict, true);
                                                    }}
                                                />
                                                {group.programBGImage !== pixel 
                                                &&
                                                    <SettingContentActive
                                                        title={"Show Background Image"} 
                                                        content={{removeBG : (group.programBGImage !== pixel || true)}}
                                                        noLabel={true}
                                                        params={['removeBG']}
                                                        updateState={(resultDict) => {
                                                            if('removeBG' in resultDict && resultDict['removeBG'] === false){
                                                                console.log('hidePublicConten newt', resultDict)
                                                                this.props.firebase.group(this.state.groupId).update({programBGImage: pixel})
                                                                var allContentDict = Object.assign({}, group, {programBGImage: pixel})
                                                                this.updateBrandCache(allContentDict, true);
                                                            }
                                                        }}
                                                    />
                                                }
                                            </>
                                        }
                                    </Grid>
                                }
                            </AccordionDetails>
                        </Accordion>
                    }
                    {this.props.onlyBrand!==true 
                    &&
                        <Accordion key="quality_details1"  id={`quality-key1`} 
                                onChange={(e,expanded) => {this.setState({additionalSetsExpanded: expanded, showAdvanced: false})}}  TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="quality-content"
                                id="quality"
                                >
                                <div style={theme.divFullWidth}>
                                    <span>Default Programs and Goals *</span>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                                {this.state.additionalSetsExpanded &&
                                    <div style={theme.divFullWidth}>
                                        <div style={{...theme.textSupport, padding: 20}}>
                                            The following parameters define the initial configuration of a NEW user, who is signing up with your Access-Code.
                                            <br/><br/>Info: Every registered user can receive indiviual settings after registration.
                                        </div>
                                        {this.context?.roles?.includes('ADMIN') 
                                        &&
                                            <SettingContentSelect 
                                                dbUpdateLink={this.props.firebase.group(this.state.groupId)}
                                                title={"Default Program:"} 
                                                content={group}
                                                labels={["Program"]}
                                                params={['currentCategory']}
                                                selectOptions={this.state.leadershipCategoriesItems || []}
                                                selectOptionsText={this.state.leadershipCategoriesItemsText || []}
                                            />
                                        }
                                        {(this.context?.roles?.includes('ADMIN') || this.context.roles?.includes('TEAM') || this.context.programs?.length > 1)
                                        //|| this.context.roles?.includes('COACHADMIN')
                                        &&
                                            <>
                                                <div style={{...theme.textSupport, padding: 20}}>
                                                    You can decide which of your content programs can be visible to users in your domain catalogue.
                                                    <br/>e.g. Rocky.ai basic public content (People, Priority,...) is defined as Rocky.ai 'Essentials' program.
                                                </div>
                                                <SettingContentArrayCheckbox
                                                    dbUpdateLink={this.props.firebase.group(this.state.groupId)}
                                                    title={"Catalogue"} 
                                                    labels={["Domains and Programs"]}
                                                    mandatory={true}
                                                    content={group} 
                                                    params={['programs']}
                                                    selectAtLeastOne={true}
                                                    /// the selectOptionsStickyFilters ensure that an item cannot be disabled in the list, so in this case the program of the pre-selectec cateogry
                                                    selectOptionsStickyFilters={ [FindTextValueInDict(this.props.firebase, this.props.leadershipCategories, group.currentCategory, 'program')]}
                                                    selectOptions={this.state.availableCoachPrograms || ['default']}
                                                    selectOptionsText={this.state.availableCoachProgramsText || ["Rocky.ai 'Essentials' program"]}
                                                    updateState={(set) => {
                                                        //console.log('set', set)
                                                        if(set?.programs?.includes('default')){
                                                            this.props.firebase.group(this.state.groupId).update({hidePublicContent: false, programMinPrograms: set.programs})
                                                        }
                                                        else{
                                                            this.props.firebase.group(this.state.groupId).update({onboardingRequired: false, programMinPrograms: set.programs})
                                                        }
                                                        var allContentDict = Object.assign({}, group, set)
                                                        allContentDict['programMinPrograms'] = set.programs;
                                                        this.updateBrandCache(allContentDict, true);
                                                    }}
                                                />
                                            </>
                                        }
                                        {(this.context.roles?.includes("COACHADMIN") || this.context.roles?.includes("TEAM") || this.context.roles?.includes("ADMIN") )
                                        &&
                                            <>
                                                {this.props.showAdvancedToggle 
                                                &&
                                                    <Grid item>
                                                        <div 
                                                            style={{textAlign: 'right', marginBottom: 20}}
                                                            onClick={() => this.setState({showAdvanced: (this.state.showAdvanced ? false : true)})}
                                                        >
                                                            <span style={{color: theme.palette.secondary.main}}>
                                                                {this.state.showAdvanced
                                                                    ?   "Hide Advanced Settings"
                                                                    :   "Show Advanced Settings"
                                                                }
                                                            </span>
                                                        </div>
                                                    </Grid>
                                                }
                                                {(this.props.showAdvancedToggle !== true || this.state.showAdvanced )
                                                &&
                                                    <>
                                                        {!(group?.programs?.length > 0) 
                                                            // || (this.state.loadedPrograms===undefined || this.state.loadedPrograms===null || this.state.loadedPrograms.length<1)
                                                        ?
                                                            <p style={theme.textErrorCenterPadding}> You have to choose a program pool! </p>
                                                        :
                                                            <div>
                                                                {(group.programs?.length > 1 || !(group?.programs?.includes('default')))
                                                                //((group?.programs?.includes('default') && group?.hidePublicContent === true) || !(group?.programs?.includes('default')))
                                                                    &&
                                                                    <>
                                                                        <div style={{...theme.textSupport, padding: 20}}>
                                                                            {false &&
                                                                            <>
                                                                                The public 'Essentials' content is related to the built-in default categories of Rocky.ai -
                                                                                Following options are possible:
                                                                                <br/><br/>
                                                                                <li>Users have access to only 'Essentials' programs and content of Rocky.ai</li>
                                                                                <li>Users have access to your coaching programs and 'Essentials' programs</li>
                                                                                <li>Users have only access to your coaching programs, but can get 'Essentials' articles recommended</li>
                                                                                <li>Users have only access to your exclusive coaching programs and content</li>
                                                                                <br/><br/>
                                                                            </>
                                                                            }
                                                                            <LanguageManager
                                                                                text={"Unpublishing 'Essentials' content makes only sense if you added sufficient new content for your audience."}
                                                                                type='tutorial'
                                                                                language={this.context?.features?.language}
                                                                            />
                                                                            {this.props.partnerProgram 
                                                                                ? 
                                                                                    ExternalHelpLink(theme, "De/activate 'Essentials'", "https://help.rocky.ai/rocky-for-creators/deactivate-reactivate-the-built-in-essentials-program", 'Help Brand')
                                                                                :   ""
                                                                            }  
                                                                        </div>
                                                                        <SettingContentActive
                                                                            title={"Show 'Essentials' content library"} 
                                                                            content={{
                                                                                hidePublicCatalogue: ((group?.programs.includes('default') || this.context?.programs?.includes('default'))) === true ? false : true,
                                                                                hidePublicContent: (group.hidePublicContent)
                                                                            }} 
                                                                            mandatory={true}
                                                                            showInverted={true}
                                                                            infoText={["Users can see 'Essentials' programs in catalogue", "Users might get 'Essentials' content recommended during the AI chats"]}
                                                                            labels={["'Essentials' programs selectable in catalogue", "'Essentials' content available in AI chats"]}
                                                                            params={['hidePublicCatalogue', 'hidePublicContent']}
                                                                            updateState={(resultDict) => {
                                                                                //console.log('hidePublicConten newt', resultDict)
                                                                                var allContentDict = Object.assign({}, group)
                                                                                if('hidePublicContent' in resultDict){
                                                                                    if(resultDict['hidePublicContent'] === true){
                                                                                        this.props.firebase.group(this.props.defaultSet.programCode).update({
                                                                                            hidePublicContent: true,
                                                                                            programs : this.props.firebase.fieldValue.arrayRemove('default'), 
                                                                                            programMinPrograms: this.props.firebase.fieldValue.arrayRemove('default')
                                                                                        })
                                                                                        this.props.firebase.user(this.context.uid).update({
                                                                                            hidePublicContent: true,
                                                                                            programs : this.props.firebase.fieldValue.arrayRemove('default')
                                                                                        })
                                                                                        allContentDict['programMinPrograms'] = group.programs?.filter(item => item !== 'default');
                                                                                    }
                                                                                    else{
                                                                                        this.props.firebase.group(this.props.defaultSet.programCode).update({
                                                                                            hidePublicContent: false,
                                                                                        })
                                                                                        this.props.firebase.user(this.context.uid).update({
                                                                                            hidePublicContent: false
                                                                                        })
                                                                                    }
                                                                                }
                                                                                else if('hidePublicCatalogue' in resultDict){
                                                                                    if(resultDict['hidePublicCatalogue'] === true){
                                                                                        this.props.firebase.group(this.state.groupId).update({
                                                                                            programs : this.props.firebase.fieldValue.arrayRemove('default'),
                                                                                            programMinPrograms : this.props.firebase.fieldValue.arrayRemove('default')
                                                                                        })
                                                                                        this.props.firebase.user(this.context.uid).update({
                                                                                            hidePublicContent: false,
                                                                                            programs : this.props.firebase.fieldValue.arrayRemove('default')
                                                                                        })
                                                                                        allContentDict['programMinPrograms'] = group.programs?.filter(item => item !== 'default');
                                                                                    }
                                                                                    else{
                                                                                        this.props.firebase.group(this.state.groupId).update({
                                                                                            hidePublicContent: false,
                                                                                            programs : this.props.firebase.fieldValue.arrayUnion('default'),
                                                                                            programMinPrograms : this.props.firebase.fieldValue.arrayUnion('default')
                                                                                        })
                                                                                        this.props.firebase.user(this.context.uid).update({
                                                                                            hidePublicContent: false,
                                                                                            programs : this.props.firebase.fieldValue.arrayUnion('default')
                                                                                        })
                                                                                        if (!group.programs?.includes('default')) {
                                                                                            group.programs?.push('default');
                                                                                        }
                                                                                        allContentDict['programMinPrograms'] = group.programs;
                                                                                    }
                                                                                }
                                                                                this.updateBrandCache(allContentDict, true);
                                                                            }}
                                                                        />
                                                                    </>
                                                                }
                                                            </div>
                                                        }
            
                                                    </>
                                                }
                                            </>
                                        }
                                        <SettingContentBase
                                            dbUpdateLink={this.props.firebase.group(this.state.groupId)}
                                            title={"'Default' Vision & Call To Action"}
                                            mandatory={true}
                                            content={group}
                                            labels={["Vision", "Call To Action"]}
                                            infoText={["Default Goal after sign-up of user", "Initial reminder notification text"]}
                                            params={['achievementGoal', 'ctaText']}
                                            emptyOption={true}
                                        />
                                        <SettingContentArrayCheckbox
                                            dbUpdateLink={this.props.firebase.group(this.state.groupId)}
                                            title={"'Default' User-Profile of NEW user"}
                                            mandatory={true}
                                            selectAtLeastOne={true}
                                            selectMaxOne={true}
                                            content={group}
                                            labels={["Profile"]}
                                            params={['onboardingResponses']}
                                            selectOptions={this.props.defaultSet?.programLifeStages !== undefined ? Object.keys(this.props.defaultSet?.programLifeStages) : ['Executive', 'Professional', 'Available', 'Student', 'Teenager']}
                                            selectOptionsText={this.props.defaultSet?.programLifeStages !== undefined ? Object.values(this.props.defaultSet?.programLifeStages) : ['Leader/Entrepreneur', 'Professional/Employed', 'Available/Unemployed', 'Student (College/Uni)', 'K-12/Teenager']}
                                            //selectOptionsText={['Leader', 'Professional', 'Available/Unemployed', 'Student (Adult)', 'K-12/Teenager']}
                                        />
                                        <SettingContentSelectCombi
                                            dbUpdateLink={this.props.firebase.group(this.state.groupId)}
                                            title={"Preloaded Quests for NEW user"} 
                                            mandatory={true}
                                            content={group} 
                                            labels={["Quest"]} 
                                            params={['currentLeadershipQuality']}
                                            selectOptions={this.state.leadershipQualitiesItems || []}
                                            selectOptionsText={this.state.leadershipQualitiesItemsText || []}
                                            selectOptionsFilter={this.state.leadershipCategoriesItems|| []}
                                            selectOptionsFilterText={this.state.leadershipCategoriesItemsText || []}
                                            leadershipQualities={this.props.leadershipQualities}
                                            leadershipCategories={this.props.leadershipCategories}
                                            updateExtraChange={{'updateCoachingPlan' : true}}
                                            updateState={(set) => {
                                                //console.log('set', set)
                                                if(set?.category?.length > 0){
                                                    this.props.firebase.group(this.state.groupId).update({currentCategory: set.category})
                                                }
                                            }}
                                        />
                                        {[1,2,3,4,5].map( (item, index) => {
                                            var baseQuality = FindDictByKey(this.props.firebase, this.props.leadershipQualities, group.currentLeadershipQuality);
                                            var sisterQualities = this.state['sisterQualities_'+group.currentLeadershipQuality] || baseQuality?.sisterQualities || [];
                                            //console.log('setName')
                                            if(baseQuality?.program !== "default" && (index) <= sisterQualities.length){
                                                return(
                                                    <SettingContentSelectCombi
                                                        key = {'extra_'+index}
                                                        title={(index+2) + ". Quest:"} 
                                                        content={{sisterQuality: (sisterQualities[index] || '')}} 
                                                        labels={["Quest"]} 
                                                        params={['sisterQuality']}
                                                        emptyOption={true}
                                                        selectOptions={this.state.leadershipQualitiesItems || []}
                                                        selectOptionsText={this.state.leadershipQualitiesItemsText || []}
                                                        selectOptionsFilter={this.state.leadershipCategoriesItems|| []}
                                                        selectOptionsFilterText={this.state.leadershipCategoriesItemsText || []}
                                                        leadershipQualities={this.props.leadershipQualities}
                                                        leadershipCategories={this.props.leadershipCategories}
                                                        updateState={(set) => {
                                                            //console.log('leadershipQualities', this.props.leadershipQualities)
                                                            var qualityID = baseQuality.qid; 
                                                            var oldQuality = sisterQualities[index] || "DCVKUBRH";
                                                            if(qualityID?.length > 1){
                                                                if(set?.sisterQuality?.length > 1){
                                                                    if(sisterQualities?.length >= index){
                                                                        sisterQualities[index] = set.sisterQuality;
                                                                        this.props.firebase.leadershipQuality(qualityID).update({sisterQualities: sisterQualities})
                                                                    }
                                                                    else{
                                                                        sisterQualities.push(set.sisterQuality)
                                                                        this.props.firebase.leadershipQuality(qualityID).update({sisterQualities: this.props.firebase.fieldValue.arrayUnion(set.sisterQuality)})
                                                                    }
                                                                }
                                                                else{
                                                                    sisterQualities = sisterQualities.filter(item => item !== oldQuality);
                                                                    this.props.firebase.leadershipQuality(qualityID).update({sisterQualities: this.props.firebase.fieldValue.arrayRemove(oldQuality)})
                                                                }
                                                                //console.log('sisterQualities after', oldQuality, sisterQualities)
                                                                var allContentDict = Object.assign({}, baseQuality, {sisterQualities: sisterQualities})
                                                                this.setState({['sisterQualities_'+group.currentLeadershipQuality] : sisterQualities});
                                                                this.updateQualityCache(allContentDict);
                                                            }
                                                            this.props.firebase.group(this.state.groupId).update({
                                                                'updateCoachingPlan' : true,
                                                                ['additionalLeadershipQuality'+(index+1)] : set.sisterQuality
                                                            })
                                                        }}
                                                    />
                                                )
                                            }
                                            else return ""
                                        })}
                                        <SettingContentActive
                                            dbUpdateLink={this.props.firebase.group(this.state.groupId)}
                                            title={"Built-in New User-Onboarding"} 
                                            mandatory={false}
                                            content={group} 
                                            labels={['New User Onboarding (Future Vision) Required']}
                                            infoText={["The default Rocky.ai onboarding for first time users leading them to a selection of the Public Content"]}
                                            params={['onboardingRequired']}
                                        />
                                        <SettingContentArray 
                                            dbUpdateLink={this.props.firebase.group(this.state.groupId)}
                                            title={"Default Article Bookmarks"} 
                                            mandatory={false}
                                            labels={["Initial Article IDs"]}
                                            content={group} 
                                            params={['contentBookmarked']}
                                        /> 
                                    </div>
                                }
                            </AccordionDetails>
                        </Accordion>
                    }
                    {(AccessCheckCoachCreator(this.context) && group.partnerProgram === true )
                    &&
                        <Accordion key="Language" id={`Language`} 
                            onChange={(e,expanded) => {this.setState({additionaldigitaltwinExpanded: expanded, showAdvanced: false})}} TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="Language-content"
                                id="Language"
                            >
                                <div style={theme.divFullWidth}>
                                    <span>Brand Language Settings</span>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                                {this.state.additionaldigitaltwinExpanded &&
                                    <Grid container spacing={0} direction='column'>
                                        {group.partnerProgram === true &&
                                            <React.Fragment>
                                                <SettingContentSelect
                                                    dbUpdateLink={this.props.firebase.group(this.state.groupId)}
                                                    title={"Language"} 
                                                    max={12}
                                                    mandatory={false}
                                                    labels={["Default Language"]}
                                                    emptyStage={"American English"}
                                                    content={group} 
                                                    params={['programLanguage']}
                                                    infoText={["Default language at signup for new users"]}
                                                    selectOptions={Object.keys(LanguageSets)}
                                                    selectOptionsText={Object.values(LanguageSets).map(entry => entry.title)}
                                                    updateState={(resultDict) => {
                                                        var allContentDict = Object.assign({}, group, resultDict)
                                                        this.updateBrandCache(allContentDict, true);
                                                    }}
                                                />
                                                {group?.programLanguage?.length > 1
                                                    && 
                                                        <div style={{marginLeft: 20, marginRight: 20}}>
                                                            <div style={theme.textSupport}>
                                                                <LanguageManager
                                                                    text={"You have changed the general setting for new users landing on your site."}
                                                                    type='tutorial'
                                                                    language={this.context?.features?.language}
                                                                />
                                                                <br/>
                                                                <br/>
                                                                <LanguageManager
                                                                    text={"You can also change your personal language preference."}
                                                                    type='tutorial'
                                                                    language={this.context?.features?.language}
                                                                />
                                                            </div>
                                                            <SettingLanguage
                                                                user={this.context}
                                                            />
                                                        </div>
                                                }
                                            </React.Fragment>
                                        }
                                    </Grid>
                                }
                            </AccordionDetails>
                        </Accordion>
                    }
                    {this.props.onlyBrand!==true  && (((this.context?.roles?.includes('ADMIN') || this.context.roles?.includes('TEAM')) || (this.context.roles?.includes('COACHADMIN')) ) && group.partnerProgram === true )
                    &&
                        <Accordion key="chatbot"  id={`chatbot`} 
                            onChange={(e,expanded) => {this.setState({additionaldigitaltwinExpanded: expanded, showAdvanced: false})}} TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="chatbot-content"
                                id="chatbot"
                                >
                                <div style={theme.divFullWidth}>
                                    <span>Chatbot Settings *</span>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                                {this.state.additionaldigitaltwinExpanded &&
                                    <Grid container spacing={0} direction='column'>
                                        {group.partnerProgram === true &&
                                            <React.Fragment>
                                                <SettingContentBase 
                                                    dbUpdateLink={this.props.firebase.group(this.state.groupId)}
                                                    title={"Chatbot Avatar Name"} 
                                                    max={12}
                                                    mandatory={true}
                                                    labels={["Avatar Name"]}
                                                    emptyStage={"Coach"}
                                                    content={group} 
                                                    params={['programAvatarName']}
                                                    infoText={["(max. 12 characters) e.g. Rocky or Coach"]}
                                                    updateState={(resultDict) => {
                                                        var allContentDict = Object.assign({}, group, resultDict)
                                                        this.updateBrandCache(allContentDict, true);
                                                    }}
                                                />
                                                {(this.props.showAdvancedToggle !== true || this.state.showAdvanced )
                                                    &&
                                                    <>
                                                        <div style={{...theme.textSupport, padding: 20}}>
                                                            You can replace the intial 'Hello', 'Welcome', 'Private & Safe' and 'Personalized' messages with your preferred sentences, but you must keep the structure.
                                                            <br/>
                                                            <br/>
                                                            Include this onboarding step for two key reasons: A) It is mandatory to inform users that they will be interacting with an AI, and B) it allows them to select their current life stage.
                                                            <br/>
                                                            <br/>
                                                            Use your apps default language: <strong>{LanguageSets[(group.programLanguage || 'en-US')].title}</strong>,&nbsp;
                                                            for the Sign-Up Intro!
                                                        </div>
                                                        <SettingContentReverseMapList
                                                            dbUpdateLink={this.props.firebase.group(this.state.groupId)}
                                                            title={"Change Sign-Up Intro"} 
                                                            mandatory={false}
                                                            content={group} 
                                                            params={['programIntro']}
                                                            max={60}
                                                            maxItems={4}
                                                            noLabel={true}
                                                            mapOptions={['Hello', "Welcome, It's AI", 'Private & Safe', 'Personalized']}
                                                            infoText={['Min. 1 sentence or default: Check if the user is new or returning', 
                                                                'Min. 1 sentence or default: Ensure users are informed about the AI technologies and coaching methods they will encounter in the app', 
                                                                'Min. 1 sentence or default: Provide users with a clear hint about responsible data handling, emphasizing security, transparency, and compliance with privacy regulations', 
                                                                'Min. 1 sentence or default: Ensure users are informed that the app is personalized and that outcomes are directly influenced by their input.']}
                                                        />
                                                    </>
                                                }
                                            </React.Fragment>
                                        }
                                    </Grid>
                                }
                            </AccordionDetails>
                        </Accordion>
                    }
                    {this.props.onlyBrand!==true  && (((this.context?.roles?.includes('ADMIN') || this.context.roles?.includes('TEAM')) || (this.context.roles?.includes('COACHADMIN')) ) && group.partnerProgram === true )
                    &&
                        <Accordion key="Profile" id={`Profile`} 
                            onChange={(e,expanded) => {this.setState({additionaldigitaltwinExpanded: expanded, showAdvanced: false})}} TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="Profile-content"
                                id="Profile"
                                >
                                <div style={theme.divFullWidth}>
                                    <span>User-Profile Settings *</span>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                                {this.state.additionaldigitaltwinExpanded &&
                                    <Grid container spacing={0} direction='column'>
                                        {group.partnerProgram === true &&
                                            <React.Fragment>
                                                {this.props.showAdvancedToggle 
                                                &&
                                                    <div 
                                                        style={{textAlign: 'right', marginBottom: 20}}
                                                        onClick={() => this.setState({showAdvanced: (this.state.showAdvanced ? false : true)})}
                                                    >
                                                        <span style={{color: theme.palette.secondary.main}}>
                                                            {this.state.showAdvanced
                                                                ?   "Hide Advanced Settings"
                                                                :   "Show Advanced Settings"
                                                            }
                                                        </span>
                                                    </div>
                                                }
                                                {(this.props.showAdvancedToggle !== true || this.state.showAdvanced )
                                                    &&
                                                    <>
                                                        <div style={{...theme.textSupport, padding: 20}}>
                                                            You can modify the life-stages that are built-in.
                                                            From kid to executive, from beginner to expert.
                                                            Keep empty to stay with default, or add at least one life-stage.
                                                            <br/>
                                                            {group.programLanguage?.length > 1
                                                            &&  
                                                                <span>
                                                                    Use English for the titles!
                                                                </span>
                                                            }
                                                        </div>
                                                        <SettingContentReverseMapList
                                                            dbUpdateLink={this.props.firebase.group(this.state.groupId)}
                                                            title={"Change Life Stages"} 
                                                            mandatory={false}
                                                            content={group} 
                                                            params={['programLifeStages']}
                                                            max={20}
                                                            maxItems={1}
                                                            noLabel={true}
                                                            mapOptions={['Teenager', 'Student', 'Available', 'Professional', 'Executive']}
                                                            mapOptionsText={['K-12 / Youngest', 'Student / Older Young', 'Unemployed or Non-Professional', 'Employed or Professional', 'Leadership role or Executive']}
                                                            infoText={['Keep empty or replace with a beginner level', 
                                                                'Keep empty or replace', 
                                                                'Keep empty or replace with mid level',
                                                                'Keep empty or replace',
                                                                'Keep empty or replace with pro level', 
                                                            ]}
                                                            updateState={(resultDict) => {
                                                                var allContentDict = Object.assign({}, group, resultDict)
                                                                this.updateBrandCache(allContentDict, true);
                                                            }}
                                                        />
                                                        <SettingContentMapSelect
                                                            dbUpdateLink={this.props.firebase.group(this.state.groupId)}
                                                            title={"Mentoring & Gamification"} 
                                                            mandatory={false}
                                                            content={group} 
                                                            params={['features']}
                                                            defaultValue={["Yes", "Yes"]}
                                                            noLabel={true}
                                                            mapOptions={['gamification', 'mentormode']}
                                                            mapOptionsText={['Show reward badges during chat', 'Allow tips and mentormode during chat']}
                                                            selectOptionsText={['Yes', 'No' ]}
                                                            selectOptions={[true, false]}
                                                            updateState={(resultDict) => {
                                                                var allContentDict = Object.assign({}, group, resultDict)
                                                                this.updateBrandCache(allContentDict, true);
                                                            }}
                                                        />
                                                    </>
                                                }
                                            </React.Fragment>
                                        }
                                    </Grid>
                                }
                            </AccordionDetails>
                        </Accordion>
                    }
                    {this.props.onlyBrand!==true 
                    &&
                        <Accordion key="remindersettings"  id={`remindersettings-key1`} 
                                onChange={(e,expanded) => {this.setState({additionalSetsExpanded: expanded, showAdvanced: false})}}  TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="remindersettings-content"
                                id="remindersettings"
                                >
                                <div style={theme.divFullWidth}>
                                    <span>New User Reminder Settings</span>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container spacing={0} direction='column'>
                                    <div style={{...theme.textSupport, padding: 20}}>
                                        The reminder frequency defines default number of reminders per week.
                                        <br/>Info: If a user has a mobile phone installed, NO EMAILS will be sent!
                                    </div>
                                    <SettingContentSelect
                                        dbUpdateLink={this.props.firebase.group(this.state.groupId)}
                                        title={"Email & Mobile Reminder Frequency:"} 
                                        content={group} 
                                        labels={['Emails', 'Mobile alerts']}
                                        params={['emailNotifications', 'mobileNotifications']}
                                        selectOptions={['daily', 'casually', 'fixed', 'weekly', 'inactive']}
                                        selectOptionsText={['2 times a day', '4 times a week', '2 scheduled messages a week', '2 random messages a week',  'inactive']}
                                        />
                                    {(group.emailNotifications === 'fixed' || group.mobileNotifications === 'fixed' )
                                        &&
                                        <SettingContentSelect 
                                            dbUpdateLink={this.props.firebase.group(this.state.groupId)}
                                            title={"Notification days for fixed reminders:"} 
                                            content={group} 
                                            labels={['Day of morning mindset', 'Day of evening reflection']}
                                            params={['primingDay', 'reflectionDay']}
                                            selectOptions={['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun']}
                                            selectOptionsText={['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']}
                                            /> 
                                    }
                                    {(group.emailNotifications === 'fixed' || group.mobileNotifications === 'fixed' )
                                        &&
                                        <SettingContentTime 
                                            dbUpdateLink={this.props.firebase.group(this.state.groupId)}
                                            title={"Notification time:"} 
                                            content={group} 
                                            labels={['Morning reminder', 'Evening reminder (after 3pm)']}
                                            params={['emailPrimingReminderTime', 'emailReflectionReminderTime']}
                                            /> 
                                    }
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    }
                    {this.props.onlyBrand!==true 
                    &&
                        this.renderTeamOptions(group)
                    }
                    {this.props.onlyBrand!==true && (this.context.roles?.includes("COACHADMIN") || this.context.roles?.includes("TEAM") || this.context.roles?.includes("ADMIN") )
                    &&
                        <>
                            <div 
                                style={{textAlign: 'right', marginBottom: 20, marginTop: 20}}
                                onClick={() => this.setState({showOptional: (this.state.showOptional ? false : true)})}
                            >
                                <span style={{color: theme.palette.secondary.main}}>
                                    {this.state.showOptional
                                        ?   "Hide advanced settings"
                                        :   "Advanced settings"
                                    }
                                </span>
                            </div>
                            {this.props.onlyBrand !== true && this.state.showOptional && 
                            <>
                                {this.state.loadedPrograms!==undefined && this.state.loadedPrograms!==null && this.state.loadedPrograms.length>=1
                                &&
                                    <Accordion key="advanced_details1"  id={`advanced-key1`} 
                                        onChange={(e,expanded) => {this.setState({additionalAdvExpanded: expanded, showAdvanced: false})}}   TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="advanced-content"
                                            id="advanced"
                                            >
                                            <div style={theme.divFullWidth}>
                                                <span>Coachee Access Settings</span>
                                            </div>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            {this.state.additionalAdvExpanded &&
                                                <Grid container spacing={0} direction='column' >
                                                    {false && this.state.loadedPrograms.includes("default")
                                                    &&
                                                        <div>
                                                            <p>The onboarding quiz is only considering the default Rocky program set (People, Priority,...).
                                                                If a user should start with a specific topic, onboarding should be skipped.
                                                            </p>
                                                            <SettingContentActive
                                                                dbUpdateLink={this.props.firebase.group(this.state.groupId)}
                                                                title={"Skip welcome and onboarding for first-time login:"} 
                                                                content={group} 
                                                                labels={['Skip welcome / Onboarding']}
                                                                params={['onboardingDone']}
                                                                />
                                                        </div>
                                                    }
                                                    {(this.context?.roles?.includes("REMOTECOACH"))
                                                    &&   
                                                        <div style={{...theme.textSupport, padding: 20}}>
                                                            To be able to read the dialogues of a client and to be able to reply and send alerts,
                                                            the user must have the COACHEE role assigned.
                                                        </div>
                                                    }
                                                    {group?.partnerProgram
                                                    ?
                                                        <>
                                                            <SettingContentArraySelect
                                                                dbUpdateLink={this.props.firebase.group(this.state.groupId)}
                                                                title={"Default Roles:"}
                                                                mandatory={true}
                                                                content={group} 
                                                                noLabel={true}
                                                                labels={["User role"]}
                                                                firstItemMandatory={true}
                                                                maxNumberOfItems={1}
                                                                params={['roles']}
                                                                selectOptions={['default', 'OBSERVE']}
                                                                selectOptionsText={['Anonymous User - No access via coachpanel', 'Coachee - Access through Coach Panel']}
                                                            />
                                                            <SettingContentMapSelect
                                                                dbUpdateLink={this.props.firebase.group(this.state.groupId)}
                                                                title={"Sign/Signup Options:"} 
                                                                mandatory={false}
                                                                content={group} 
                                                                params={['programUX']}
                                                                defaultValue={["Yes", "Yes"]}
                                                                noLabel={true}
                                                                mapOptions={['allowSignup', 'SocLog']}
                                                                mapOptionsText={['Allow new users to create accounts via the app', 'Allow Signup with Social SSO (Google, Facebook)']}
                                                                selectOptionsText={['Yes', 'No' ]}
                                                                selectOptions={[true, false]}
                                                            />
                                                        </>

                                                    :   this.context.roles!== undefined && (this.context.roles?.includes("ADMIN") || this.context.roles?.includes("COACHADMIN"))
                                                        ?    
                                                            <SettingContentArraySelect
                                                                dbUpdateLink={this.props.firebase.group(this.state.groupId)}
                                                                title={"Roles:"} 
                                                                content={group} 
                                                                noLabel={true}
                                                                labels={["User role"]}
                                                                firstItemMandatory={true}
                                                                maxNumberOfItems={1}
                                                                params={['roles']}
                                                                selectOptions={['default', 'COACHEE', 'OBSERVE', 'REMOTECOACH']}
                                                                selectOptionsText={['Anonymous User', 'Coachee - who CANNOT change own modules!', 'Coachee - who CAN MODIFY all settings!', 'Consultant or Coach']}
                                                            />
                                                        :
                                                            this.context.roles!== undefined && (this.context.roles?.includes("REMOTECOACH") )
                                                                ?    
                                                                    <SettingContentArraySelect
                                                                        dbUpdateLink={this.props.firebase.group(this.state.groupId)}
                                                                        title={"Roles:"} 
                                                                        content={group} 
                                                                        labels={["User role"]}
                                                                        params={['roles']}
                                                                        firstItemMandatory={true}
                                                                        maxNumberOfItems={1}
                                                                        selectOptions={['default', 'COACHEE', 'OBSERVE']}
                                                                        selectOptionsText={['Anonymous User', 'Coachee - who CANNOT change own modules!', 'Coachee - who CAN MODIFY all settings!']}
                                                                    />
                                                                :   ""
                                                    }

                                                    {
                                                        /**
                                                        this.context.roles!== undefined && (this.context.roles?.includes("REMOTECOACH") )
                                                        &&   
                                                            <p>
                                                                The number of free AI chats until a subscription is a required.
                                                            </p>
                                                        */
                                                    }

                                                    {
                                                    this.context.roles!== undefined && (this.context.roles?.includes("ADMIN") || this.context.roles?.includes("TEAM"))
                                                        ?    
                                                            <SettingContentNumber
                                                                dbUpdateLink={this.props.firebase.group(this.state.groupId)}
                                                                title={"Trial AI Chats:"} 
                                                                content={group} 
                                                                labels={["Free chats"]}
                                                                params={['freeDialogues']}
                                                                min={0}
                                                                max={30}
                                                                />
                                                        :
                                                            this.context.roles!== undefined && (this.context.roles?.includes("REMOTECOACH") || this.context.roles?.includes("COACHADMIN") )
                                                            ?    
                                                                <SettingContentNumber
                                                                    dbUpdateLink={this.props.firebase.group(this.state.groupId)}
                                                                    title={"Trial AI Chats:"} 
                                                                    mandatory={true}
                                                                    content={group} 
                                                                    labels={["Free chats"]}
                                                                    params={['freeDialogues']}
                                                                    min={0}
                                                                    max={6}
                                                                />
                                                            :
                                                                ""
                                                        /**
                                                        */
                                                    }
                                                </Grid>
                                            }
                                        </AccordionDetails>
                                    </Accordion>
                                }
                                {this.props.onlyBrand !== true && this.context.roles!== undefined && (this.context.roles?.includes('ADMIN') || this.context.roles?.includes('COACHADMIN'))
                                &&
                                    <Accordion key="ownerpanel"  id={`ownerpanel`} 
                                        onChange={(e,expanded) => {this.setState({additionalOwnExpanded: expanded, showAdvanced: false})}}  TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="ownerpanel"
                                            id="ownerpanel"
                                            >
                                            <div style={theme.divFullWidth}>
                                                <span>Show/Add Team Owners</span>
                                            </div>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            {this.state.additionalOwnExpanded &&
                                                <Grid container spacing={0} direction='column' >
                                                    <Grid item >
                                                        <strong>Existing Team Owners:</strong>
                                                        {group.owner!== undefined && group.owner.length  > 0
                                                        &&
                                                            group.owner.map( ownerId => {
                                                                if(ownerId === this.context.uid ){
                                                                    return(
                                                                        <p key={"cons_"+ownerId}>
                                                                            - Myself
                                                                            &nbsp;
                                                                            {group.partnerProgram !== true && group.owner!== undefined && ((group.owner.length === 1 && group.owner.includes(this.context.uid)) || (group.owner.length === 0))
                                                                                ?
                                                                                    <Button style={{backgroundColor: 'red'}} onClick={this.deactivateCode}>
                                                                                        Archive team and code
                                                                                    </Button>
                                                                                :
                                                                                    <Button style={{backgroundColor: 'red'}} onClick={() => this.removeCoachFromGroup(this.context.uid)}>
                                                                                        Remove myself as owner
                                                                                    </Button>
                                                                            }
                                                                        </p>
                                                                    )
                                                                }
                                                                else if(this.props.consultantsList!==undefined && Object.keys(this.props.consultantsList).includes(ownerId) ){
                                                                    return(
                                                                        <p key={"cons_"+ownerId}>
                                                                            - <strong>{this.props.consultantsList[ownerId].username}</strong>
                                                                            &nbsp; {this.props.consultantsList[ownerId].email}
                                                                            &nbsp;
                                                                            <Button style={{backgroundColor: 'red'}} onClick={() => this.removeCoachFromGroup(ownerId)}>
                                                                                Remove Person as Owner
                                                                            </Button>
                                                                        </p>
                                                                    )
                                                                }
                                                                else{
                                                                    return(
                                                                        <p key={"cons_"+ownerId}>
                                                                            - Other user (no access / not within shared group)
                                                                        </p>
                                                                    )
                                                                }
                                                            })
                                                        }
                                                    </Grid>
                                                    <Grid item >
                                                        <hr />
                                                        <strong>Add New Team Owners (Coaches within group):</strong>
                                                        {this.props.consultantsList!==undefined &&  Object.keys(this.props.consultantsList).length>0
                                                            &&
                                                                <div>
                                                                    
                                                                    { Object.keys(this.props.consultantsList).map( coachId => {
                                                                        if(! group.owner.includes(coachId)){
                                                                            return(
                                                                                <p key={"sel_"+coachId}>
                                                                                    - <strong>{this.props.consultantsList[coachId].username}</strong>
                                                                                    &nbsp; {this.props.consultantsList[coachId].email}
                                                                                    &nbsp;
                                                                                    <Button onClick={ () => {this.addCoachToGroup(coachId)} }>
                                                                                        Add 
                                                                                    </Button>
                                                                                </p>
                                                                            )}
                                                                        else return ""
                                                                    })}
                                                                </div>
                                                        }
                                                        <hr />
                                                            <LanguageManager
                                                                text={"New coaches or group owners need to be registered as coach in another group first!"}
                                                                type='tutorial'
                                                                language={this.context?.features?.language}
                                                            />
                                                    </Grid>
                                                </Grid>
                                            }
                                        </AccordionDetails>
                                    </Accordion>
                                }
                                {this.props.onlyBrand !== true && this.context.roles!== undefined && (this.context.roles?.includes('ADMIN') || this.context.roles?.includes('TEAM') || this.context.roles?.includes('COACHADMIN'))
                                &&
                                    <Accordion key="voucher" id={`voucher`} 
                                        onChange={(e,expanded) => {this.setState({additionalOwnExpanded: expanded, showAdvanced: false})}}  TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="voucher"
                                            id="voucher"
                                            >
                                            <div style={theme.divFullWidth}>
                                                <span>Add Voucher Codes</span>
                                            </div>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            {this.state.additionalOwnExpanded &&
                                                <Grid container spacing={0} direction='row' >
                                                    <Grid item xs={12} style={{textAlign: 'center'}}>
                                                        <hr />
                                                        <strong>
                                                            <LanguageManager
                                                                text={"Choose the type of voucher code that will be deducted from your available seats."}
                                                                type='tutorial'
                                                                language={this.context?.features?.language}
                                                            />
                                                        </strong>
                                                        <br/>
                                                        <Radio
                                                            checked={this.state.voucherType === undefined || this.state.voucherType==='monthly'}
                                                            onChange={(event)=>{this.setState({voucherType: event.target.value})}}
                                                            value={'monthly'}
                                                            name="radio-button-demo"
                                                        />
                                                        Monthly Subscription
                                                        <Radio
                                                            checked={this.state.voucherType==='3-month' || false}
                                                            onChange={(event)=>{this.setState({voucherType: event.target.value})}}
                                                            value={'3-month'}
                                                            name="radio-button-demo"
                                                            inputProps={{ 'aria-label': 'Monthly Subscription' }}
                                                        />
                                                        3-Month Package
                                                        <Radio
                                                            checked={this.state.voucherType==='12-month' || false}
                                                            onChange={(event)=>{this.setState({voucherType: event.target.value})}}
                                                            value={'12-month'}
                                                            name="radio-button-demo"
                                                            inputProps={{ 'aria-label': 'Monthly Subscription' }}
                                                        />
                                                        1-Year Package
                                                    </Grid>
                                                    <Grid item xs={12} style={{textAlign: 'center', width: '100%'}}>
                                                        Source of Seats: &nbsp;
                                                        <Select
                                                            value={this.state.seatsSponsorUserId?.length > 1 ? this.state.seatsSponsorUserId : this.context.uid }
                                                            onChange={(e) => {this.setState({seatsSponsorUserId: e.target.value})}}
                                                            name='ownerseats'
                                                        >
                                                            <MenuItem key={this.context.uid} value={this.context.uid} >
                                                                {"Myself"} &nbsp; {this.context.seatsToGive || "0"} Seats availale
                                                            </MenuItem>
                                                            {group.owner!== undefined && group.owner.length > 0
                                                                && group.owner.map( ownerId => {
                                                                    if(ownerId !== this.context.uid && this.props.consultantsList?.[ownerId] !== undefined){
                                                                        return(
                                                                            <MenuItem key={ownerId} value={ownerId} >
                                                                                {this.props.consultantsList?.[ownerId]?.username} &nbsp; {this.props.consultantsList?.[ownerId]?.seatsToGive || "0"} Seats available
                                                                            </MenuItem>
                                                                        )
                                                                    }
                                                                    else return ""
                                                                } )
                                                            }
                                                        </Select>
                                                        <br/>
                                                        <span>
                                                            <LanguageManager
                                                                text={"Seats will be deducted once a voucher code is activated and will continue to be deducted on a monthly basis until canceled."}
                                                                type='tutorial'
                                                                language={this.context?.features?.language}
                                                            />
                                                        </span>
                                                        <br/><br/>
                                                    </Grid>
                                                    <Grid item xs={12} style={{textAlign: 'center', width: '100%'}}>
                                                        <hr />
                                                    </Grid>
                                                    <Grid item xs={6} style={{textAlign: 'center', width: '100%'}}>
                                                        Option 1: Add universal voucher to domain/coach-code
                                                        {group?.purchasedProduct?.quantity > 0 &&
                                                            " (active)"
                                                        }
                                                        <br/>
                                                        {group?.purchasedProduct?.quantity > 0
                                                            ?
                                                                <Button
                                                                    variant={'contained'}
                                                                    //disabled={true}
                                                                    onClick = {() => this.addVoucherToGroup(group, true)}
                                                                >
                                                                    Remove universal voucher
                                                                </Button>
                                                            :
                                                                <Button
                                                                    variant={'contained'}
                                                                    //disabled={true}
                                                                    onClick = {() => this.addVoucherToGroup(group)}
                                                                >
                                                                    Assign universal voucher
                                                                </Button>
                                                        }
                                                    </Grid>
                                                    <Grid item xs={6} style={{textAlign: 'center', width: '100%'}}>
                                                        Option 2: One-time codes <br/>
                                                        <Button
                                                            variant={'contained'}
                                                            onClick = {() => this.addVouchersUnique(group)}
                                                        >
                                                            Create 10 unique vouchers
                                                        </Button>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        {group?.code?.length > 0 &&
                                                            <div style={{width: '100%'}}>
                                                                <hr />
                                                                <strong>Available Voucher Codes:</strong>
                                                                <p></p>
                                                                <ShowVouchersForGroup
                                                                    group={group.code}
                                                                    setExistingVoucherCodes={(listVouchers) => this.setState({existingVoucherCodes: listVouchers})}
                                                                />
                                                            </div>
                                                        }
                                                    </Grid>
                                                </Grid>
                                            }
                                        </AccordionDetails>
                                    </Accordion>
                                }
                                {group.partnerProgram === true
                                ?
                                    <Accordion key="DELETE" id="dedlete"
                                        onChange={(e,expanded) => {this.setState({additionaDelExpanded: expanded, showAdvanced: false})}} TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}
                                    >
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="dekete"
                                        >
                                            <div style={theme.divFullWidth}>
                                                <span style={theme.textSupport}>Change Brand-code</span>
                                            </div>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            {this.state.additionaDelExpanded &&
                                                <Grid container spacing={0} direction='column' >
                                                    {this.renderCurrentCode(group)}
                                                    <Grid item >
                                                        <TextField
                                                            fullWidth
                                                            id="newCodeName"
                                                            variant="outlined"
                                                            style={ {fontSize: '16px'} }
                                                            label="Code for User Access"
                                                            value={this.state.newGroupCode || "#"}
                                                            onChange={ this.changeCodeName }
                                                        />
                                                    </Grid>
                                                    {this.state.errorMessage !== '' && this.state.errorMessage !== undefined
                                                        ?   <p style={theme.textErrorCenterPadding}> 
                                                                <br/> {this.state.errorMessage} 
                                                            </p>
                                                        :   <p> </p>
                                                    }
                                                    <p style={{color : theme.palette.themeAlertRed}}>Danger zone!</p>
                                                    <span>
                                                        <LanguageManager
                                                            text={"If you already added content and teams, there is a chance that you might loose those connections when you change your Brand-code!"}
                                                            type='tutorial'
                                                            language={this.context?.features?.language}
                                                        />
                                                    </span>
                                                    <List style={theme.textSettings}>
                                                        <span style={{ float: 'right'}}>
                                                        <Switch
                                                            checked={this.state.confirmChangeRequest || false}
                                                            onColor={theme.palette.themeBlue}
                                                            value={this.state.confirmChangeRequest}
                                                            onChange={(c, event, id) => {
                                                                this.setState({confirmChangeRequest : c});
                                                            }}
                                                            inputProps={{ 'aria-label': 'Allow Email Notifications' }}
                                                        />
                                                        </span>
                                                        <span style={theme.textSettings.settingsTextDescription}>
                                                            <LanguageManager
                                                                text={"Confirm change request"}
                                                                type='button'
                                                                language={this.context?.features?.language}
                                                            />
                                                            :
                                                        </span>
                                                    </List>
                                                    <Button disabled={!(this.state.confirmChangeRequest)} onClick={() => this.updateDomainCode(group)}>
                                                        Change<AssignmentIcon fontSize="small"/>
                                                    </Button>
                                                </Grid>
                                            }
                                        </AccordionDetails>
                                    </Accordion>
                                :
                                    <Accordion key="DELETE" id="dedlete"
                                        onChange={(e,expanded) => {this.setState({additionaDelExpanded: expanded, showAdvanced: false})}}  TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}
                                    >
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="dekete"
                                            >
                                            <div style={theme.divFullWidth}>
                                                <span style={theme.textSupport}>Remove and Archive Team</span>
                                            </div>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            {this.state.additionaDelExpanded &&
                                                <Grid container spacing={0} direction='column' >
                                                    <Grid item >
                                                        {group.partnerProgram !== true && group.owner!== undefined && group.owner.length === 1 && group.owner.includes(this.context.uid) 
                                                            ?
                                                                <p>
                                                                    By archiving this team, the team will be deactivated, and the connected users will loose the access to the dedicated settings of this code.
                                                                </p>
                                                            :
                                                                <p>
                                                                    You can remove yourself from this team. However, there are still other owners of this team.
                                                                </p>
                                                        }
                                                    </Grid>
                                                    <Grid item >
                                                        Remove team: &nbsp; <strong>{group.code}</strong> &nbsp;
                                                        {group.partnerProgram !== true && group.partnerProgram !== true && group.owner!== undefined && ((group.owner.length === 1 && group.owner.includes(this.context.uid)) || (group.owner.length === 0))
                                                            ?
                                                                <Button style={{backgroundColor: 'red'}} onClick={this.deactivateCode}>
                                                                    Archive team and code
                                                                </Button>
                                                            :
                                                                <Button style={{backgroundColor: 'red'}} onClick={() => this.removeCoachFromGroup(this.context.uid)}>
                                                                    Remove myself as owner
                                                                </Button>
                                                        }
                                                    </Grid>
                                                </Grid>
                                            }
                                        </AccordionDetails>
                                    </Accordion>
                                }
                            </>
                            }
                        </>
                    }
                </div>
            )
        }
        else{
            return(
                <div style={theme.loadingCenterPadding}>
                    <LoadingProgress 
                        defaultSet={this.props.defaultSet}
                        type={'LoadingPageAnimated'} loadingText={"loading team settings"} />
                </div>
            )
        }
    }

    renderBrandSettings(group){
        const {theme} = this.props;
        if(group!== null && group!== undefined){
            if(group.partnerProgram === true)
                return(
                    <>
                        <Accordion key="digitaltwin" id={`digitaltwin`} 
                            onChange={(e,expanded) => {this.setState({additionaldigitaltwinExpanded: expanded, showAdvanced: false})}}   TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="digitaltwin-content"
                                id="digitaltwin"
                            >
                                <div style={theme.divFullWidth}>
                                    <span>Brand Title and Description *</span>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                                {this.state.additionaldigitaltwinExpanded &&
                                    <Grid container spacing={0} direction='column' >
                                        {(this.context?.roles?.includes('ADMIN'))
                                        &&
                                            <SettingContentActive
                                                dbUpdateLink={this.props.firebase.group(this.state.groupId)}
                                                title={"Mark as White Label Page / Partner Program"} 
                                                content={group} 
                                                labels={['Check if White Label Page or access code for partner programs.']}
                                                params={['partnerProgram']}
                                                updateExtraChange={{'programCode': this.state.groupId}}
                                                updateState={(resultDict) => {
                                                    var allContentDict = Object.assign({}, group, resultDict)
                                                    this.updateBrandCache(allContentDict, true);
                                                }}
                                            />
                                        }
                                        <React.Fragment>
                                            <SettingContentBase 
                                                dbUpdateLink={this.props.firebase.group(this.state.groupId)}
                                                title={"Brand Title"} 
                                                mandatory={true}
                                                labels={["Title", "1-Sentence Pitch"]}
                                                max={60}
                                                content={group} 
                                                params={['programTitle', 'programSubTitle']}
                                                updateState={(resultDict) => {
                                                    var allContentDict = Object.assign({}, group, resultDict)
                                                    this.updateBrandCache(allContentDict, true);
                                                }}
                                            />
                                            <div style={{...theme.textSupport, padding: 20}}>
                                                Use your apps default language: <strong>{LanguageSets[(group.programLanguage || 'en-US')].title}</strong>,&nbsp;
                                                for the 1-Sentence Pitch and the Brand Description!
                                            </div>
                                            <SettingContentRichText 
                                                dbUpdateLink={this.props.firebase.group(this.state.groupId)}
                                                title={"Brand Description"} 
                                                mandatory={true}
                                                labels={["Full description and context of the App (Rich Text)"]}
                                                content={group} 
                                                params={['programDescription']}
                                                updateState={(resultDict) => {
                                                    var allContentDict = Object.assign({}, group, resultDict)
                                                    this.updateBrandCache(allContentDict, true);
                                                }}
                                            />
                                        </React.Fragment>
                                    </Grid>
                                }
                            </AccordionDetails>
                        </Accordion>
                        <Accordion key="company" id={`company`} 
                            onChange={(e,expanded) => {this.setState({additionalCompanyExpanded: expanded, showAdvanced: false})}}   TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="company-content"
                                id="company"
                            >
                                <div style={theme.divFullWidth}>
                                    <span>Company Info & Links *</span>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                                {this.state.additionalCompanyExpanded &&
                                    <Grid container spacing={0} direction='column' >
                                        <React.Fragment>
                                            {this.props.showAdvancedToggle &&
                                                <div 
                                                    style={{textAlign: 'right', marginBottom: 20}}
                                                    onClick={() => this.setState({showAdvanced: (this.state.showAdvanced ? false : true)})}
                                                >
                                                    <span style={{color: theme.palette.secondary.main}}>
                                                        {this.state.showAdvanced
                                                            ?   "Hide Advanced Settings"
                                                            :   "Show Advanced Settings"
                                                        }
                                                    </span>
                                                </div>
                                            }
                                            {(this.props.showAdvancedToggle !== true || this.state.showAdvanced)
                                                &&
                                                <>
                                                    <SettingContentBase
                                                        dbUpdateLink={this.props.firebase.group(this.state.groupId)}
                                                        title={"Business Information"} 
                                                        mandatory={true}
                                                        content={group}
                                                        labels={["Company name, city, country", "Your email address"]}
                                                        params={['programCompany', 'programEmail']}
                                                        infoText={["Your legal address e.g. Rocky Robots Ltd, Preston, UK", "e.g. coach@rocky.ai"]}
                                                        updateState={(resultDict) => {
                                                            var allContentDict = Object.assign({}, group, resultDict)
                                                            this.updateBrandCache(allContentDict, true);
                                                        }}
                                                    />
                                                    <SettingContentBase
                                                        dbUpdateLink={this.props.firebase.group(this.state.groupId)}
                                                        title={"Business Links"} 
                                                        mandatory={true}
                                                        content={group}
                                                        checkURLFormat={true}
                                                        labels={["Business website", "Contact web address", "Meeting and booking link", "Privacy Info URL", "Terms & Conditions Info URL", ]}
                                                        params={['programCompanyURL', 'programSupportURL', 'programBookingURL', 'programPrivacyURL', 'programTermsURL']}
                                                        infoText={["Your business URL", "Your contact info e.g https://www.rocky.ai/customersupport)", "e.g. Calendly where clients can book a meeting", "A link with your privacy info", "A link with your terms and conditions"]}
                                                        updateState={(resultDict) => {
                                                            if(group.programShopURL === undefined && resultDict.programSupportURL?.includes('http')){
                                                                resultDict['programShopURL'] = resultDict.programSupportURL;
                                                            }
                                                            else if(group.programShopURL === undefined && resultDict.programBookingURL?.includes('http')){
                                                                resultDict['programShopURL'] = resultDict.programBookingURL;
                                                            }
                                                            else if(group.programShopURL === undefined && resultDict.programCompanyURL?.includes('http')){
                                                                resultDict['programShopURL'] = resultDict.programCompanyURL;
                                                            }
                                                            var allContentDict = Object.assign({}, group, resultDict)
                                                            this.updateBrandCache(allContentDict, true);
                                                        }}
                                                    />
                                                    {(this.context?.roles?.includes('ADMIN') || this.context.roles?.includes('TEAM'))
                                                    &&
                                                        <>
                                                            <SettingContentBase
                                                                dbUpdateLink={this.props.firebase.group(this.state.groupId)}
                                                                title={"Shop URL"} 
                                                                mandatory={'ADMIN'}
                                                                content={group}
                                                                checkURLFormat={true}
                                                                labels={["Product Purchase Link"]}
                                                                params={['programShopURL']}
                                                                infoText={["Website that shows the key product purchase e.g. Stripe purchase Link", "Info where people can get "]}
                                                            />
                                                            <SettingContentBase
                                                                dbUpdateLink={this.props.firebase.group(this.state.groupId)}
                                                                title={"App Links"} 
                                                                mandatory={'ADMIN'}
                                                                checkURLFormat={true}
                                                                content={group}
                                                                labels={["Webapp URL", "Auto App Install URL"]}
                                                                params={['programURL', 'programInstallURL']}
                                                            />
                                                        </>
                                                    }
                                                </>
                                            }
                                        </React.Fragment>
                                    </Grid>
                                }
                            </AccordionDetails>
                        </Accordion>
                    </>
                )
            else
                var programCodes = []
                var programNames = []
                if(this.context.programCode?.length > 2){
                    programCodes.push(this.context.programCode)
                    programNames.push(this.context.programCode)
                }
                if(group.programCode?.length > 2 && group.programCode !== this.context.programCode){
                    programCodes.push(group.programCode)
                    programNames.push(group.programCode)
                }
                if(!(programCodes.length > 0)){
                    programCodes.push("")
                    programNames.push("NONE")
                }
                else if(this.context.roles?.includes("ADMIN")){
                    programCodes.push("")
                    programNames.push("Remove")
                }
                return(
                    <>
                        <Accordion key="digitaltwin" id={`digitaltwin`} 
                            onChange={(e,expanded) => {this.setState({additionaldigitaltwinExpanded: expanded, showAdvanced: false})}}   TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="digitaltwin-content"
                                id="digitaltwin"
                            >
                                <div style={theme.divFullWidth}>
                                    <span>Team Settings</span>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                                {this.state.additionaldigitaltwinExpanded &&
                                    <Grid container spacing={0} direction='column' >
                                        <SettingContentBase 
                                            dbUpdateLink={this.props.firebase.group(this.state.groupId)}
                                            title={"Name of Team"} 
                                            mandatory={true}
                                            labels={["Name"]}
                                            content={group} 
                                            params={['title']}
                                        />
                                        <SettingContentSelect 
                                            dbUpdateLink={this.props.firebase.group(this.state.groupId)}
                                            title={"Assigned Brand-code"} 
                                            mandatory={true}
                                            content={group}
                                            labels={["Brand-code"]}
                                            noLabel={true}
                                            params={['programCode']}
                                            access={group.partnerProgram !== true ? true : false}
                                            selectOptions={programCodes}
                                            selectOptionsText={programNames}
                                        />
                                        <SettingContentSelect 
                                            dbUpdateLink={this.props.firebase.group(this.state.groupId)}
                                            title={"Assigned Group"} 
                                            mandatory={false}
                                            labels={["Sort team into a group"]}
                                            content={group}
                                            addNewOption={true}
                                            max={30}
                                            params={['parentGroup']}
                                            selectOptions={this.props.coachGroupsNames || []}
                                        />
                                    </Grid>
                                }
                            </AccordionDetails>
                        </Accordion>
                    </>
                )
        }
    }

    renderTeamOptions(group){
        const {theme} = this.props;
        if(group!== null && group!== undefined){
            if(group.partnerProgram === true){
                return(
                    ""
                )
            }
            else
                return(
                    <Accordion key="goal_details1"  id={`goal-key1`} 
                        onChange={(e,expanded) => {this.setState({additionalGoalExpanded: expanded, showAdvanced: false})}}  TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="goal-content"
                            id="goal"
                            >
                            <div style={theme.divFullWidth}>
                                <span>Team Gamification</span>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            {this.state.additionalGoalExpanded &&
                                <Grid container spacing={0} direction='column' >
                                    {group.parentGroup !== undefined && group.parentGroup !== '' &&
                                        <> 
                                            <Grid item>
                                                <span>
                                                    <br/><br/>
                                                    If you want the teams within the group to compete against each other.
                                                </span>
                                                {group.groupCompetitionId !== undefined && group.groupCompetitionId !== ''
                                                    ?
                                                        (group.groupCompetitionId.includes(group.parentGroup) || this.props.ownedCompetitionIds?.find(a => a.includes(group.parentGroup)))
                                                        ?
                                                            this.props.ownedCompetitionIds.includes(group.groupCompetitionId)
                                                            ?
                                                                <SettingContentSelect 
                                                                    dbUpdateLink={this.props.firebase.group(this.state.groupId)}
                                                                    title={"Optional: Team competition ID: (Optional)"} 
                                                                    content={group}
                                                                    labels={["Choose team competitors"]}
                                                                    params={['groupCompetitionId']}
                                                                    selectOptions={['default'].concat(this.props.ownedCompetitionIds)}
                                                                    //selectOptionsText={['Any App User', (group.groupCompetitionId.split(group.parentGroup)[0]) ]}
                                                                    selectOptionsText={['Any App User'].concat(this.props.ownedCompetitionIds)}
                                                                    />
                                                            :
                                                                <SettingContentSelect 
                                                                    dbUpdateLink={this.props.firebase.group(this.state.groupId)}
                                                                    title={"Optional: Team competition ID: (Optional)"} 
                                                                    content={group}
                                                                    labels={["Choose team competitors"]}
                                                                    params={['groupCompetitionId']}
                                                                    selectOptions={['default', group.groupCompetitionId ].concat(this.props.ownedCompetitionIds)}
                                                                    //selectOptionsText={['Any App User', (group.groupCompetitionId.split(group.parentGroup)[0]) ]}
                                                                    selectOptionsText={['Any App User', group.groupCompetitionId ].concat(this.props.ownedCompetitionIds)}
                                                                    />
                                                        :
                                                            <SettingContentSelect 
                                                                dbUpdateLink={this.props.firebase.group(this.state.groupId)}
                                                                title={"Optional: Team competition ID: (Optional)"} 
                                                                content={group}
                                                                labels={["Choose team competitors"]}
                                                                params={['groupCompetitionId']}
                                                                selectOptions={['default', (group.parentGroup+'_#'+this.state.datebatchId)].concat(this.props.ownedCompetitionIds)}
                                                                selectOptionsText={['Any App User', 'New Group'].concat(this.props.ownedCompetitionIds)}
                                                                />
                                                    :
                                                        <SettingContentSelect 
                                                            dbUpdateLink={this.props.firebase.group(this.state.groupId)}
                                                            title={"Optional: Team competition ID: (Optional)"} 
                                                            content={group}
                                                            labels={["Choose team competitors"]}
                                                            params={['groupCompetitionId']}
                                                            selectOptions={['default', (group.parentGroup+'_#'+this.state.datebatchId)].concat(this.props.ownedCompetitionIds)}
                                                            selectOptionsText={['Any App User', 'New Group'].concat(this.props.ownedCompetitionIds)}
                                                            />
                                                }
                                            </Grid>
                                        </>
                                    }
                                    <SettingContentMapSelect
                                        dbUpdateLink={this.props.firebase.group(this.state.groupId)}
                                        title={"Optional: Mentoring & Gamification"} 
                                        content={group} 
                                        params={['features']}
                                        noLabel={true}
                                        mapOptions={['gamification', 'mentormode']}
                                        mapOptionsText={['Show reward badges during chat (Default: on)', 'Allow tips and mentormode during chat (Default: on)']}
                                        selectOptionsText={['Yes', 'No' ]}
                                        selectOptions={[true, false]}
                                    />
                                </Grid>
                            }
                        </AccordionDetails>
                    </Accordion>
                )
        }
    }

    renderCurrentCode(group){
        const {theme} = this.props;
        if(group?.code?.includes("#")){
            return(
                <p> 
                    <span style={theme.textSupport}>
                        {group?.partnerProgram === true ? "Brand-code:" : "Access-Code:"}&nbsp;
                    </span>
                    {group.code}
                    {this.props.partnerProgram 
                        ? 
                            ExternalHelpLink(theme, "Add your brand", "https://help.rocky.ai/rocky-for-creators/add-your-own-brand-basic-brand-settings-and-identity", 'Help Brand')
                        :   ""
                    }  
                </p>
            )
        }
    }
    

    addCoachToGroup = (coachId) => {
        this.props.firebase.group(this.state.groupId)
        .update({
            owner: this.props.firebase.fieldValue.arrayUnion( coachId )
        })
        this.props.firebase.user(coachId)
        .update({
            groups: this.props.firebase.fieldValue.arrayUnion( this.state.groupId )
        })
        this.setState({
            group: null,
            groupId: '',
            newGroup: true
        })
        if(this.props.handleReload !== undefined){
            this.props.handleReload();
        }
        if(this.props.handleClose !== undefined){
            this.props.handleClose();
        }
    }

    removeCoachFromGroup = (coachId) => {
        this.props.firebase.group(this.state.groupId)
        .update({
            owner: this.props.firebase.fieldValue.arrayRemove(coachId)
        })
        this.setState({
            group: null,
            groupId: '',
            newGroup: true
        })
        if(this.props.handleReload !== undefined){
            this.props.handleReload();
        }
        if(this.props.handleClose !== undefined){
            this.props.handleClose();
        }
    }

    deactivateCode = () => {
        this.props.firebase.group(this.state.groupId)
        .update({
            active: false,
            owner: [this.context.uid],
            ownerArchive: [this.context.uid]
        })
        this.setState({
            group: null,
            groupId: '',
            newGroup: true
        })
        if(this.props.handleReload !== undefined){
            this.props.handleReload();
        }
        if(this.props.handleClose !== undefined){
            this.props.handleClose();
        }
    }

    changeCodeName = (event) => {
        var code = event.target.value;
        var changedCode = "#"+code.toUpperCase().replace(/[^A-Z0-9-]/ig, "");
        if(this.props.partnerProgram && !(this.context.roles?.includes("ADMIN"))){
            changedCode = changedCode.replace("ROCKY", "");
            changedCode = changedCode.replace("COACHING", "");
            changedCode = changedCode.replace("COACH", "");
            changedCode = changedCode.replace("DAILY", "");
        }
        this.setState({newGroupCode: changedCode })
        if(this.props.resultCode !== undefined){
            this.props.resultCode(changedCode)
        }
    }

    addNewTeam = () => {
        var selectedProgram = this.state.newGroupProgrammMain;
        var parentGroup = "Unsorted";
        if(selectedProgram === undefined){
            if(this.context.programs === undefined){
                selectedProgram = 'default'
            }
            else if(this.context.programs !== undefined && this.context.programs.length>0){
                if (this.context.programs.includes('default')) {
                    selectedProgram = 'default';
                    //console.log('selectedProgram',selectedProgram)
                }
                else{
                    selectedProgram = this.context.programs[0];
                    //console.log('selectedProgram2',selectedProgram)
                }
            }
            else{
                selectedProgram = this.context.programs[0];
            }
        }
        var username = this.context.username!== undefined ? this.context.username.split(" ")[0] : "My";
        parentGroup = username + ' Teams';
        if(this.state.newGroupCode!==undefined && this.state.newGroupCode.length>5){
            //this.props.firebase.signupCodeByName(this.state.newGroupCode).get()
            this.props.firebase.group(this.state.newGroupCode).get()
            .then(code => {
                if(code.exists){
                    this.setState({
                        errorMessage: 'This code is already in usage, please change!'
                    });
                }
                else{
                    var newGroupData = {
                        active: true,
                        createdAt : this.props.firebase.fieldValue.serverTimestamp(),
                        title: this.state.newGroupName,
                        code: this.state.newGroupCode,
                        programs: [selectedProgram],
                        emailNotifications: 'casually',
                        mobileNotifications: 'casually',
                        onboardingRequired: true,
                        freeDialogues: 1,
                        parentGroup : parentGroup,
                        currentLeadershipQuality: this.context.currentLeadershipQuality,
                        currentCategory: this.context.currentCategory,
                        //achievementGoal: this.context.achievementGoal,
                        achievementGoal: 'Achieve personal development goals.',
                        onboardingResponses: ['Professional'],
                        //ctaText: this.context.ctaText,
                        ctaText: 'Start your AI Coach every day for a 5-minute self-coaching chat.',
                        owner: [this.context.uid],
                        roles: ['OBSERVE'],
                    }
                    if(this.state.parentGroup?.length > 2){
                        newGroupData['parentGroup'] = this.state.parentGroup;
                    }
                    if(this.props.partnerProgram){
                        newGroupData['partnerProgram'] = true;
                        newGroupData['programCode'] = this.state.newGroupCode;
                        newGroupData['programTitle'] = 'My Daily AI Coach';
                        newGroupData['programSubTitle'] = this.state.newGroupName;
                        newGroupData['programs'] = (this.context.programs || []).concat([this.state.newGroupCode]);
                        newGroupData['parentGroup'] = 'My AI Coach';
                        newGroupData['programMinPrograms'] = newGroupData['programs'];
                        /////// update the user as well
                        var userUpdate = {
                            programCode: this.state.newGroupCode,
                            adminPrograms: [this.state.newGroupCode],
                            programs: this.props.firebase.fieldValue.arrayUnion( this.state.newGroupCode )
                        }
                        this.props.firebase.user(this.context.uid).update(userUpdate);
                    }
                    if(this.state.inheritDigitalTwin !== false && this.props.allowInherit !== false && this.context.programCode?.length > 2){
                        this.props.firebase.group(this.context.programCode).get()
                        .then(refGroup => {
                            if(refGroup.exists){
                                var refGroupDict = refGroup.data(); 
                                newGroupData['programs'] = refGroupDict.programs;
                                newGroupData['emailNotifications'] = refGroupDict.emailNotifications;
                                newGroupData['mobileNotifications'] = refGroupDict.mobileNotifications;
                                newGroupData['onboardingRequired'] = refGroupDict.onboardingRequired !== undefined ? refGroupDict.onboardingRequired : this.props.firebase.fieldValue.delete();
                                newGroupData['hidePublicContent'] = refGroupDict.hidePublicContent !== undefined ? refGroupDict.hidePublicContent : this.props.firebase.fieldValue.delete();
                                newGroupData['programLogo'] = refGroupDict.programLogo?.length > 2 ? refGroupDict.programLogo : this.props.firebase.fieldValue.delete();
                                if (refGroupDict.freeDialogues !== undefined)
                                    newGroupData['freeDialogues'] = refGroupDict.freeDialogues;
                                if (refGroupDict.currentLeadershipQuality?.length > 0)
                                    newGroupData['currentLeadershipQuality'] = refGroupDict.currentLeadershipQuality;
                                if (refGroupDict.currentCategory?.length > 0)
                                    newGroupData['currentCategory'] = refGroupDict.currentCategory;
                                if (refGroupDict.achievementGoal?.length > 0)
                                    newGroupData['achievementGoal'] = refGroupDict.achievementGoal;
                                if (refGroupDict.onboardingResponses?.length > 0)
                                    newGroupData['onboardingResponses'] = refGroupDict.onboardingResponses;
                                if (refGroupDict.ctaText?.length > 0)
                                    newGroupData['ctaText'] = refGroupDict.ctaText;
                                if (refGroupDict.roles?.length > 0)
                                    newGroupData['roles'] = refGroupDict.roles;
                                if (refGroupDict.programCode?.length > 0)
                                    newGroupData['programCode'] = this.context.programCode;
                                this.props.firebase.group(this.state.newGroupCode).set(newGroupData, {merge: true})
                                .then(() => {
                                    this.loadGroup(this.state.newGroupCode);
                                })
                            }
                            else{
                                newGroupData['programURL'] = ("https://" + this.state.newGroupCode?.toLowerCase().replace("#", "") + ".my-ai.coach")
                                if(this.props.partnerProgram){
                                    this.props.firebase.user(this.context.uid).update({programCode: this.state.newGroupCode});
                                }
                                this.props.firebase.group(this.state.newGroupCode).set(newGroupData)
                                .then(() => {
                                    this.loadGroup(this.state.newGroupCode);
                                })
                            }
                        })
                    }
                    else{
                        this.props.firebase.group(this.state.newGroupCode).set(newGroupData)
                        .then(() => {
                            this.loadGroup(this.state.newGroupCode);
                        })
                    }
                    //// reload thee coach groups list
                    if(this.props.handleReload !== undefined){
                        this.props.handleReload();
                    }
                    this.setState({
                        errorMessage: 'Looks good!',
                        newGroup: false
                    });
                }
            })
        }
        else{
            this.setState({
                errorMessage: 'Code is too short (min. 6 characters) or team name is missing!'
            });
        }
    }

    updateDomainCode = (group) => {
        if(group.partnerProgram){
            //var oldParentGroup = "Unsorted";
            if(this.state.newGroupCode!==undefined && this.state.newGroupCode.length>5){
                //this.props.firebase.signupCodeByName(this.state.newGroupCode).get()
                this.props.firebase.group(this.state.newGroupCode).get()
                .then(code => {
                    if(code.exists){
                        this.setState({
                            errorMessage: 'This code is already in usage, please change!'
                        });
                    }
                    else{
                        var baseUrl = "my-ai.coach";
                        if(group.programURL?.includes('mydaily.coach')){
                            baseUrl = "mydaily.coach";
                        }
                        var oldCode = ""+group.code
                        var newGroupDict = group;
                        newGroupDict['code'] = this.state.newGroupCode;
                        newGroupDict['createdAt'] = this.props.firebase.fieldValue.serverTimestamp()
                        newGroupDict['programCode'] = this.state.newGroupCode;
                        newGroupDict['programURL'] = ("https://" + this.state.newGroupCode?.toLowerCase().replace("#", "") + "." + baseUrl)
                        newGroupDict['programs'] = [this.state.newGroupCode, ...(group.programs || [])]
                        this.props.firebase.group(this.state.newGroupCode).set(newGroupDict, {merge:true});
                        //// update old groups
                        var oldGroupUpdateDict = {};
                        oldGroupUpdateDict['partnerProgram'] = false;
                        oldGroupUpdateDict['programs'] = this.props.firebase.fieldValue.arrayUnion(this.state.newGroupCode);
                        //// Delete all default content settings
                        oldGroupUpdateDict['onboardingResponses'] = this.props.firebase.fieldValue.delete();
                        oldGroupUpdateDict['currentCategory'] = this.props.firebase.fieldValue.delete();
                        oldGroupUpdateDict['currentLeadershipQuality'] = this.props.firebase.fieldValue.delete();
                        oldGroupUpdateDict['ctaText'] = this.props.firebase.fieldValue.delete();
                        oldGroupUpdateDict['achievementGoal'] = this.props.firebase.fieldValue.delete();
                        oldGroupUpdateDict['roles'] = this.props.firebase.fieldValue.delete();
                        //// Delete all program settings
                        Object.keys(group).map((itemName => {
                            if(itemName.startsWith("program") && itemName!=="programs"){
                                oldGroupUpdateDict[itemName] = this.props.firebase.fieldValue.delete();
                            }
                            return true
                        }))
                        oldGroupUpdateDict['programCode'] = this.state.newGroupCode;
                        oldGroupUpdateDict['parentGroup'] = 'Archive';
                        oldGroupUpdateDict['programURL'] = ("https://" + this.state.newGroupCode?.toLowerCase().replace("#", "") + "." + baseUrl)
                        this.props.firebase.group(oldCode).update(oldGroupUpdateDict)
                        /////// update the user as well
                        var userUpdate = {
                            programCode: this.state.newGroupCode,
                            programs : this.props.firebase.fieldValue.arrayUnion(this.state.newGroupCode),
                            adminPrograms : [this.state.newGroupCode, ...this.context.adminPrograms]
                        }
                        this.props.firebase.user(this.context.uid).update(userUpdate)
                        .then( () => {
                            this.loadGroup(this.state.newGroupCode);
                            //// reload thee coach groups list
                            if(this.props.handleReload !== undefined){
                                this.props.handleReload();
                            }
                        });
                        this.setState({
                            errorMessage: 'Looks good!',
                            newGroup: false
                        });
                    }
                })
            }
            else{
                this.setState({
                    errorMessage: 'Code is too short (min. 6 characters) or team name is missing!'
                });
            }
        }
        else{
            this.setState({
                errorMessage: 'A change is currently not possible for this code!'
            });
        }
    }

    addLogoToGroup = (logoUrl) => {
        if(logoUrl === undefined || logoUrl===''){
            this.props.firebase.group(this.state.groupId).update({programLogo: this.props.firebase.fieldValue.delete()});
        }
        else{
            this.props.firebase.group(this.state.groupId).update({programLogo: logoUrl});
            if(this.state.group.partnerProgram === true){
                var allContentDict = this.state.group;
                allContentDict['programLogo'] = logoUrl;
                this.updateBrandCache(allContentDict, true);
            }
        }
    }

    getVoucherProductDict(){
        let purchasedProduct = {};
        var seatsSponsorUserId = this.context.uid;
        if(this.state.seatsSponsorUserId?.length >2){
            seatsSponsorUserId = this.state.seatsSponsorUserId;
        }
        if(this.state.voucherType === '3-month'){
            purchasedProduct = {
                owned: true,
                state: 'owned',
                expiryDate: null,
                premium: true,
                title: '3-month Premium Access',
                quantity: 3,
                type: 'VOUCHER',
                price: 'Voucher',
                autoAssignFlag: false,
                autoAssignSource: seatsSponsorUserId,
            }
        }
        else if(this.state.voucherType === '12-month'){
            purchasedProduct = {
                owned: true,
                state: 'owned',
                expiryDate: null,
                premium: true,
                title: '12-month Premium Access',
                quantity: 12,
                type: 'VOUCHER',
                price: 'Voucher',
                autoAssignFlag: false,
                autoAssignSource: seatsSponsorUserId,
            }
        }
        else if(this.state.voucherType === 'monthly' || this.state.voucherType === undefined){
            purchasedProduct = {
                owned: true,
                state: 'owned',
                expiryDate: null,
                premium: true,
                title: 'Monthly Premium Subscription',
                quantity: 1,
                type: 'VOUCHER',
                price: 'Voucher',
                autoAssignFlag: false,
                autoAssignSource: seatsSponsorUserId,
            }
        }
        return purchasedProduct;
    }

    addVouchersUnique = (group) => {
        console.log('add vouchers', group)
        let existingVoucherCodes = (this.state.existingVoucherCodes || []);
        let newVoucherCodes = [];
        var groupIdx = group.code[1] + group.code[2] + group.code[4] + group.code[6]
        while(newVoucherCodes.length < 10){
            var randomCode = '#' + groupIdx + (Math.random().toString(36).replace(/[^a-z-]+/g, '').substring(2,8)).toUpperCase();
            if (randomCode.length === 11 && !(existingVoucherCodes.includes(randomCode))){
                existingVoucherCodes.push(randomCode);
                newVoucherCodes.push(randomCode);
            }
        }
        let purchasedProduct = this.getVoucherProductDict();
        var programsExt = group.programs || [];
        programsExt.push('$$EXTEND');
        var rolesExt = (group.roles || []);
        rolesExt.push('$$EXTEND');
        var batch = this.props.firebase.batch();
        newVoucherCodes.map((item, index) => {
            var codeDict = {
                active: true,
                code: item,
                type: 'voucher',
                createdAt: this.props.firebase.fieldValue.serverTimestamp(),
                title: purchasedProduct?.title?.length > 1 ? purchasedProduct.title : 'general',
                newUser: {
                    achievementGoal: group.achievementGoal,
                    ctaText: group.ctaText,
                    onboardingResponses: (group.onboardingResponses || ['Available']),
                    currentCategory: group.currentCategory,
                    currentLeadershipQuality: group.currentLeadershipQuality,
                    groups: [group.code],
                    programs: group.programs,
                    roles: group.roles
                },
                addingCode: {
                    groups: [group.code, '$$EXTEND'],
                    programs: programsExt,
                    roles: rolesExt
                }
            }
            if (purchasedProduct.owned){
                purchasedProduct['voucherId'] = item;
                purchasedProduct['type'] = 'Voucher ' + item;
                codeDict['newUser']['purchasedProduct'] = purchasedProduct;
                codeDict['addingCode']['purchasedProduct'] = purchasedProduct;
            }
            if(group.teamProgramLogo?.length > 0){
                codeDict['newUser']['programLogo'] = group.teamProgramLogo;
                codeDict['addingCode']['programLogo'] = group.teamProgramLogo;
            }
            if(group.programCode?.includes("#")){
                codeDict['newUser']['programCode'] = group.programCode;
                codeDict['addingCode']['programCode'] = group.programCode;
            }
            //console.log('randomCode', item, codeDict)
            this.props.firebase.signupCodeByName(item).set(codeDict)
            // this.props.firebase.documentIdByCollection('groups/'+group.code+'/vouchers', item).set(codeDict)
            var docRef = this.props.firebase.documentIdByCollection('groups/'+group.code+'/vouchers', item);
            batch.set(docRef, codeDict);
            return true
        })
        batch.commit();
        //console.log('type', this.state.voucherType)
    }

    addVoucherToGroup = (group, deactivate=false) => {
        console.log('add voucher')
        let purchasedProduct = this.getVoucherProductDict();
        if (purchasedProduct.owned){
            purchasedProduct['voucherId'] = group['code'];
            purchasedProduct['type'] = "Voucher " + group['code'];
            purchasedProduct['multipurpose'] = true;
            if (deactivate === true){
                purchasedProduct['quantity'] = 0;
            }
            this.props.firebase.group(this.state.groupId).update({'purchasedProduct' : purchasedProduct})
        }
        //console.log('type', this.state.voucherType)
    }
}//end class


export default compose(
    withFirebase,
    withStyles(styles, { withTheme: true })
)(CoachGroupSetting);
