import React, { Component } from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import { AuthUserContext } from '../Session';
import * as chatAgent from '../../chatAgent';


/// Search
import SearchIcon from '@material-ui/icons/Search';
import CancelIcon from '@material-ui/icons/Cancel';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionActions';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SpeechToTextButton from '../Modules/SpeechToTextButton';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';


import {ExtendDialogueMessage} from '../Routine/DiaryManual';


class ContentSearchPanel extends Component {
    static contextType = AuthUserContext;
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            additional_search_expanded: false,
        };
    }

    componentDidMount() {
        this._isMounted = true
    }
    componentWillUnmount() {
        this._isMounted = false
    }


    render() {
        return(
            <div style={{width:"100%"}}>
                <Accordion style={{boxShadow:'none', backgroundColor: "transparent"}}
                        onChange={(e,expanded) => {this.setState({additional_search_expanded: expanded})}}>
                    <AccordionSummary
                        expandIcon={this.state.additional_search_expanded? <CancelIcon /> : <SearchIcon />}
                        style={{paddingLeft: 20, paddingRight: 9}}
                        aria-label="About-Expand"
                        aria-controls="About-content"
                        id="About-header"
                        >
                    </AccordionSummary>
                    <AccordionDetails  style={{padding:0}} >        
                        {this.searchField()}
                    </AccordionDetails>
                </Accordion>
            </div> 
        )
    }

    searchField = () => {
        return(
            <div style={ {width: '100%'} }>
                {this.state.additional_search_expanded===true &&
                    <Grid container 
                        justifyContent="space-between"
                        alignItems="center" spacing={1}
                        style={ {width: '100%'} }>
                        <Grid item xs={9}>
                            <TextField
                                name="search"
                                style={ {fontSize: '16px', backgroundColor: "white"} }
                               //"Search for key words"
                                value={this.state.searchKeywords}
                                onChange={(e) => {this.setState({searchKeywords: e.target.value})}}
                                type="text"
                                variant="outlined"
                                placeholder="e.g. confidence"
                                fullWidth
                                multiline
                                InputProps={{
                                    style:{fontSize: '16px'},
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SpeechToTextButton 
                                                renderKey={'saerch_'}
                                                returnText={(result) => this.setState({searchKeywords : result})}
                                                existingText={this.state.searchKeywords || ""}
                                            />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Button onClick={() => {this.searchTutorials()}}> 
                                Search&nbsp;<SearchIcon />
                            </Button>
                        </Grid>
                    </Grid>
                }
            </div>
        )
    }


    searchTutorials = async () => {
        //console.log('search', this.state)
        if(this.state.searchKeywords !== undefined && this.state.searchKeywords !== "" && this._isMounted){
            //console.log('sear', this.state)
            var userIntentResult = {}
            var userIntent = "inform_indicator_success_clarity";
            var searchPhrase = this.state.searchKeywords;
            this.setState({searchKeywords : 'searching...'})
            userIntentResult = await chatAgent.sendMessage({ 'text': searchPhrase }, 'requestNLU')
            //console.log('sear', searchPhrase, userIntentResult)
            if(searchPhrase !== undefined && searchPhrase !== ""){
                var searchSentence = searchPhrase.trim().toLowerCase();
                var searchArray = searchSentence.split(" ");
                //console.log(searchArray, 'searchArray')
                if(searchArray.length > 10){
                    searchArray = searchArray.slice(0,9)
                }
                if(userIntentResult !== undefined && userIntentResult.intent !== undefined){
                    var version = 'v2';
                    if('full_retrieval_intent' in userIntentResult) version = 'v1';
                    var sentiment = userIntentResult.intent.name;
                    var intent = '';
                    if(version === 'v1'){
                        intent = userIntentResult.response_selector[sentiment].full_retrieval_intent;
                    }
                    else{
                        intent = userIntentResult.response_selector[sentiment].response.intent_response_key;
                    }
                    var intent_set = intent.split("/")
                    userIntent = intent_set[0];
                    if(intent_set.length>1){
                        userIntent = intent_set[1];
                    }
                }
                //console.log('userIntent', userIntent)
                var searchPromises = [];
                if(this.context.hidePublicContent !== true ){
                    searchPromises.push(
                        this.props.firebase.contents()
                        .where('active', '==', true)
                        .where('category', '==', 'tips')
                        .where('public', '==', true)
                        .where('tags', 'array-contains-any', (searchArray || []))
                        .orderBy('contentVotes', 'desc')
                        .get()
                    );
                    searchPromises.push(
                        this.props.firebase.contents()
                        .where('active', '==', true)
                        .where('category', '==', 'tips')
                        .where('public', '==', true)
                        .where('utteranceClass', 'array-contains', (userIntent || []))
                        .orderBy('contentVotes', 'desc')
                        .get()
                    );
                }
                if(this.context.programs !== undefined && this.context.programs.length > 0){
                    for (var i = 0; i < this.context.programs.length; i++) {
                        if(this.context.programs[i] !== 'default'){
                            searchPromises.push(
                                this.props.firebase.contents()
                                .where('active', '==', true)
                                .where('category', '==', 'tips')
                                .where('program', '==', this.context.programs[i])
                                .where('tags', 'array-contains-any', (searchArray || []))
                                .orderBy('contentVotes', 'desc')
                                .get()
                            );
                            searchPromises.push(
                                 this.props.firebase.contents()
                                .where('active', '==', true)
                                .where('category', '==', 'tips')
                                .where('program', '==', this.context.programs[i])
                                .where('utteranceClass', 'array-contains', userIntent)
                                .orderBy('contentVotes', 'desc')
                                .get()
                            )
                        }
                        if(i > 4){
                            break;
                        }
                    }
                }
                //console.log('searchPromises', searchPromises)
                Promise.all(searchPromises)
                .then((querySnapshots) => {
                    return querySnapshots.map(qs => qs.docs)
                })
                .then((matchingArticleRefs) => {
                    var resultContentIds = [];
                    //console.log('matchingArticleRefs', matchingArticleRefs)
                    matchingArticleRefs.forEach( snapshot => {
                        if (snapshot !== undefined ){
                            snapshot.forEach(doc => {
                                var contentItem = doc.data();
                                var intesectionArray = contentItem.tags.filter(value => searchArray.includes(value))
                                if(!resultContentIds.includes(doc.id)){
                                    if(intesectionArray>1){
                                        resultContentIds.unshift(doc.id);
                                        //console.log('double tags', contentItem.title, intesectionArray.length, intesectionArray)

                                    }
                                    else if(contentItem.utteranceClass!== undefined &&  contentItem.utteranceClass.includes(userIntent)){
                                        resultContentIds.unshift(doc.id);
                                        //console.log('with intent', contentItem.title, intesectionArray.length, intesectionArray)

                                    }
                                    else{
                                        resultContentIds.push(doc.id);
                                        //console.log('default', contentItem.title, intesectionArray.length, intesectionArray)
                                    }
                                }
                            })
                        }
                    })
                    //console.log('resultContentIds', resultContentIds.length)
                    if(resultContentIds.length > 0  && this._isMounted){
                        this.props.onSubmit(resultContentIds);
                        this.setState({searchKeywords : 'results: ' + searchPhrase})
                        ExtendDialogueMessage(this.props.firebase, this.context, searchSentence, "For what topic are your searching for an answer?", 'utter_ask_help', '', 'search');
                    }
                    else if( this._isMounted ) {
                        this.setState({searchKeywords: "no results for: " + searchPhrase})
                        ExtendDialogueMessage(this.props.firebase, this.context, searchSentence, "For what topic should I prepare and learn more?", 'utter_ask_help', '', 'search');
                    }
                  
                })

                /**

                //console.log(searchArray, this.state.searchKeywords)
                var searchKeyWords = this.props.firebase.contents()
                    .where('active', '==', true)
                    .where('category', '==', 'tips')
                    .where('public', '==', true)
                    .where('tags', 'array-contains-any', searchArray)
                    .orderBy('contentVotes', 'desc')
                searchKeyWords = searchKeyWords.get()
                var searchIntent = this.props.firebase.contents()
                    .where('active', '==', true)
                    .where('category', '==', 'tips')
                    .where('public', '==', true)
                    .where('utteranceClass', 'array-contains', userIntent)
                    .orderBy('contentVotes', 'desc')
                searchIntent = searchIntent.get()
                const [querySearchKeyWords, querySearchIntents ] = await Promise.all([searchKeyWords, searchIntent]);
                const resultsSearchKeyWords = querySearchKeyWords.docs;
                const resultsSearchIntents = querySearchIntents.docs;
                const snapshot = resultsSearchKeyWords.concat(resultsSearchIntents)
                //.then(snapshot => {
                    snapshot.forEach(doc => {
                        var contentItem = doc.data();
                        var intesectionArray = contentItem.tags.filter(value => searchArray.includes(value))
                        if(!resultContentIds.includes(doc.id)){
                            if(intesectionArray>1){
                                resultContentIds.unshift(doc.id)
                            }
                            else{
                                resultContentIds.push(doc.id)
                            }
                            console.log(contentItem.title, intesectionArray.length, intesectionArray)
                        }
                    })
                    if(resultContentIds.length > 0  && this._isMounted){
                        this.props.onSubmit(resultContentIds);
                        this.setState({searchKeywords : 'results: ' + searchPhrase})
                        ExtendDialogueMessage(this.props.firebase, this.context, searchSentence, "For what topic are your searching for an answer?", 'utter_ask_help', '', 'search');
                    }
                    else if( this._isMounted ) {
                        this.setState({searchKeywords: "no results for: " + searchPhrase})
                        ExtendDialogueMessage(this.props.firebase, this.context, searchSentence, "For what topic should I prepare and learn more?", 'utter_ask_help', '', 'search');
                    }
                    
                //})
                 */
            }
        }
    }

}

export default compose(
    withFirebase,
)(ContentSearchPanel);