import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import LoadingProgress from '../../Modules/LoadingProgress';

import { withFirebase } from '../../Firebase';
import { withAuthorization,
	 // withEmailVerification
       } from '../../Session';
import { AuthUserContext } from '../../Session';
import * as ROLES from '../../../constants/roles';
import * as ROUTES from '../../../constants/routes';

// MUI stuff
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
//import Table from '@material-ui/core/Table';
//import TableCell from '@material-ui/core/TableCell';
//import TableHead from '@material-ui/core/TableHead';
//import TableRow from '@material-ui/core/TableRow';
//import TableBody from '@material-ui/core/TableBody';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
//import ButtonSwitch from '@material-ui/core/Switch';
import {Column, Table, SortDirection} from 'react-virtualized';
import {  AutoSizer } from 'react-virtualized';
import 'react-virtualized/styles.css'
import _ from "lodash";


import { SelectOption, SelectWrapper } from '../../Modules/SelectOption';
// Icons
import AddIcon from '@material-ui/icons/Add';

// Modules for parameter change
import SettingContentBase from '../Modules/SettingContentBase';
import SettingContentActive from '../Modules/SettingContentActive';
//import SettingContentNumber from '../Modules/SettingContentNumber';
//import SettingContentArray from '../Modules/SettingContentArray';
//import SettingContentImage from '../Modules/SettingContentImage';
import SettingContentUploadImage from '../Modules/SettingContentUploadImage';
import SettingContentRichText from '../Modules/SettingContentRichText';
import SettingContentArraySelect from '../Modules/SettingContentArraySelect';
import SettingContentSelect from '../Modules/SettingContentSelect';

const styles = {
    table: {
        minWidth: 100,
    },
    subtitle: {
        marginTop: 20,
        fontWeight: 700,
    },
    addButton: {
        marginTop: 20,
    },
};

class AdminNewsPage extends Component {
    render() {
        return (
            <div style={{backgroundColor: "#DDDDDD"}}>
                <Switch>
                    <Route exact path={ROUTES.ADMIN} component={NewsList} />
                    <Route exact path={ROUTES.ADMIN_NEWS} component={NewsList} />
                </Switch>
            </div>
        );
    }
}

class NewsListBase extends Component {
    static contextType = AuthUserContext;
    _isMounted = false;
    constructor(props) {
        super(props);
        const sortBy = 'active';
        const sortDirection = SortDirection.ASC;

        this.state = {
            loading: false,
            contents: [],
            contentsTab: [],
            contentsLabels: [],
            title: '',
            author: '',
            program: '',
            open: false,
            sortBy,
            sortDirection,
        };
        //this.sortState = createMultiSort(this.sort);
    }

    componentDidMount() {
        this._isMounted = true;
        this.setState({
            loading: true,
            open: false, 
            title: '',
            author: '',
            program: '',
            showTab: '',
            errorMessage : ''
        });

        var name = '';
        this.unsubscribe = this.props.firebase
        .collectionByName('newsArticles')
        //.orderBy('active', 'desc')
        .onSnapshot(snapshot => {
            let contentsTab = {};
            let contentsTabList = {};
            let contentsLabels = [];
            var keySorting = 'targetRole'
            var content = {}
            snapshot.forEach(doc =>{
                if((this.context.roles?.includes('ADMIN') || this.context.roles?.includes('TEAM')) && this._isMounted){
                    if(this.context.programs?.includes(doc.data().program) || doc.data().program===undefined){
                        name = doc.data()[keySorting];
                        //console.log('got name', name)
                        content = doc.data();
                        if(name === undefined){
                            name = '---undefined---'
                        }
                        if(!(name in contentsTab)){
                            //console.log('name', name, contentsLabels, contentsTab)
                            //contentsTab[doc.data().category]={name:doc.data().category, contents: []};
                            contentsTab[name] = [];
                            contentsTabList[name] = [];
                            contentsLabels.push( name );
                        }
                        else{
                            //console.log('pushing name', name)
                            //console.log('name', name, contentsLabels, contentsTab)
                        }
                        contentsTab[name].push({ ...doc.data(), uid: doc.id });
                        contentsTabList[name].push({
                            active: content.active? 'Yes': 'No',
                            button: content.button || '-',
                            title:  content.title || '-',
                            type:  content.targetRole || '-',
                            uid:  doc.id ,
                        });
                    } // end if true
                }
            });//end of snapshot


            //console.log('contents', contentsTab)
            if(this.state.showTab === undefined || this.state.showTab === ''){
                this.setState({showTab: name})
            }
            if(this._isMounted){
                this.setState({
                    contentsTab: contentsTab,
                    contentsTabList,
                    contentsLabels,
                    loading: false,
                });
            }
            //console.log('total', contentsTab)
        });
        
    }

    _sortList = ({sortBy, sortDirection}) => {
        const list = this.state.contentsTabList[this.state.showTab];
        //console.log('sortingbefore', sortBy ,sortDirection)
        let newList = _.sortBy(list, [sortBy]);
        if (sortDirection === SortDirection.DESC) {
            newList.reverse();
        }
        //console.log('sorting',newList, this.state.showTab )
        return newList;
    }
    _sort = ({sortBy, sortDirection}) => {
        const sortedList = this.state.contentsTabList;
        sortedList[this.state.showTab] = this._sortList({sortBy, sortDirection});
        this.setState({sortBy, sortDirection, contentsTabList: sortedList});
    }

    handleOpen = () => {
        //console.log('handleOpen');
        this.setState({ open: true });
    };

    handleClose = () => {
        //console.log('handleClose');
        this.setState({ open: false });
    };

    handleChange = (event) => {
        //console.log([event.target.name] + ": " + event.target.value);
        this.setState({
        [event.target.name]: event.target.value
        });
    }

    handleSubmit = () => {
        if(this.state.title !== '' && this.state.author !== '' && this.state.category !== ''){
            const timestamp = this.props.firebase.fieldValue.serverTimestamp();
            this.props.firebase.collectionByName('newsArticles').add({
                title: this.state.title,
                author: [this.state.author],
                targetRole: this.state.targetRole,
                active: false,
                createdAt: timestamp
            });
            this.setState({errorMessage : 'Added content'})
        }
        else{
            this.setState({errorMessage : 'All three values have to be defined to add a content'})
        }
        this.setState({
            title: '',
            author: '',
            program: '',
        });
        this.handleClose();
    }

    componentWillUnmount() {
        this._isMounted = false;
        this.unsubscribe();
    }

    handleTabChange = (event, newValue) => {
        //console.log('new tab', newValue)
        this.setState({
        showTab: newValue
        });
    }

    render() {
        const { showTab, contentsTab, contentsLabels, loading } = this.state;
        //console.log('votes', maxVotes)
        return (
            <div style={{height: '100vh'}}>
                {loading && 
                    <LoadingProgress 
                        defaultSet={this.props.defaultSet}
                        type={'LoadingPageAnimated'} 
                        loadingText={"many, many, data"} 
                        firebaseConnectionSpeed={this.state.firebaseConnectionSpeed} 
                        allowRefresh={true}
                    />
                }
                <div>
                    <IconButton style={styles.addButton} onClick={this.handleOpen}>
                        <AddIcon />
                    </IconButton>
                    <Dialog
                        open={this.state.open}
                        onClose={this.handleClose}
                        fullWidth
                        maxWidth="sm">
                        <DialogContent>
                        <DialogTitle>Add a new News item</DialogTitle>
                        <form>
                            <TextField
                                name="title"
                                style={ {fontSize: '16px'} }
                                type="text"
                                label="1. Title"
                                placeholder="Enter title"
                                value={this.state.title}
                                onChange={this.handleChange}
                                fullWidth
                            />
                            <TextField
                                name="author"
                                style={ {fontSize: '16px'} }
                                type="text"
                                label="2. Author"
                                placeholder="Enter content author name"
                                value={this.state.author}
                                onChange={this.handleChange}
                                fullWidth
                            />
                            <p>3. Select program</p>
                            <SelectWrapper
                                value={this.state.targetRole}
                                onChange={this.handleChange}
                                name='targetRole'
                            >
                            {this.state.contentsLabels.map(entry => {
                                return (
                                    <SelectOption key={"add_"+entry} value={entry}
                                        >
                                        {entry}
                                    </SelectOption>
                                );})
                            }
                                
                            </SelectWrapper>
                            
                            </form>
                            <DialogActions>
                                <Button onClick={this.handleClose}> Cancel </Button>
                                <Button onClick={this.handleSubmit}> Save </Button>
                            </DialogActions>
                        </DialogContent>
                    </Dialog>
                </div>
                {this.state.errorMessage !== '' && 
                <h4 style={{color :'red'}}>{this.state.errorMessage}</h4>
                }
                <Tabs
                    variant="scrollable"
                    scrollButtons="auto"
                    onChange={this.handleTabChange}
                    initialselectedindex={0}
                    value={showTab}
                >
                    {contentsLabels.length>0 && contentsLabels.map( (key, index) => (
                        <Tab 
                            value={key} 
                            key={key+'_'+index}
                            label={key + " ("+contentsTab[key]?.length+")"}/>
                        ))
                    }
                </Tabs>
                {contentsLabels.length>0 && contentsLabels.map( (key, index) => (
                    <div 
                        value={key} 
                        key={key+'_'+index}
                        hidden={showTab !==key}
                        >
                        <AutoSizer>
                        {({ height, width }) => (
                            <Table
                                width={width}
                                height={700}
                                headerHeight={20}
                                rowHeight={30}
                                rowCount={(this.state.contentsTabList?.[key]?.length || 0)}
                                sort={this._sort}
                                sortBy={this.state.sortBy}
                                sortDirection={this.state.sortDirection}
                                rowGetter={({index}) => this.state.contentsTabList[key][index]}
                                onRowClick={({ event, index, rowData })  => {
                                    this.setState({
                                        currentContentId: this.state.contentsTabList[key][index].uid, 
                                        dialogOpen:true
                                    });
                                    //console.log('state odate', this.state.contentsTabList[key][index])
                                }}
                            >
                                <Column label="Active" dataKey="active" width={100} disableSort={false}  />
                                <Column label="Title" dataKey="title" width={300} disableSort={false} />
                                <Column label="Audience" dataKey="type" width={200} disableSort={false} />
                                <Column label="Button" dataKey="button" width={200} disableSort={false} />
                                
                            </Table>
                        )}
                        </AutoSizer>
                    </div>
                ))
                }
                    <NewsItemDialog 
                        contentId={this.state.currentContentId}
                        openDialog={this.state.dialogOpen|| false}
                        closeDialog={(e) => {this.setState({dialogOpen : false })}}
                        contentsLabels={contentsLabels}
                    />
                </div>
        );
    }
}


class NewsItemBaseDialog extends Component {
    static contextType = AuthUserContext;
    _isMounted = false;
    constructor(props) {
        super(props);
        var errorMessage = '';
        var contentsLabels = ['total'];
        this.state = {
            loading: false,
            content: null,
            cid:'',
            contentsLabels: contentsLabels,
            errorMessage: errorMessage,
        };
    }
  
    componentDidMount() {
        this._isMounted = true;
        //    this.getleadershipCategoriesArray();
        //    this.getleadershipQualitiesArray();
        //    this.getBotUtterArray();
    }
  
    componentDidUpdate() {
        //console.log('myid',this.props.contentId)
        if (this.props.contentId && this.state.cid !== this.props.contentId) {
            //console.log('myidopen', this.props.contentId)
            this.setState({ loading: true, cid: this.props.contentId });
            this.unsubscribe = this.props.firebase
                .documentIdByCollection('newsArticles', this.props.contentId)
                .onSnapshot(snapshot => {
                    //console.log('data',snapshot.data())
                    if(snapshot && this._isMounted){
                        this.setState({
                            content: snapshot.data(),
                            loading: false,
                        });
                    }
                });
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
        this.setState({content:null});
        if (this.unscrubscribe !== undefined ) this.unsubscribe();
    }

    duplicate(content){
        if(content.active !== undefined){
            var newContent = content;
            newContent['title'] = ('COPY ...' + content.title);
            newContent['active'] = false;
            newContent['author'] = this.context.email;
            newContent['createdAt'] = this.props.firebase.fieldValue.serverTimestamp();
            this.props.firebase.collectionByName('newsArticles').add(newContent);
            //console.log('newContent', newContent)
        }
    }

    render() {
        const { content, loading } = this.state;
         
        //console.log('my context', this.context)
        return (
            <Dialog
                open={this.props.openDialog}
                onClose={this.props.closeDialog}
                >
                <DialogTitle >
                    <IconButton onClick={this.props.closeDialog}>
                        (X)
                    </IconButton>
                    Modify Content
                </DialogTitle>
                <DialogContent >
                    {this.props.openDialog &&
                    <div>
                        <h5 style={styles.subtitle}>Content ({this.props.contentId})</h5>
                        {loading && 
                            <LoadingProgress 
                                defaultSet={this.props.defaultSet}
                                type={'LoadingPageAnimated'} 
                                loadingText={"many, many, data"} 
                                firebaseConnectionSpeed={this.state.firebaseConnectionSpeed} 
                                allowRefresh={true}
                            />
                        }
                
                        {this.state.errorMessage !== '' && 
                            <h4 style={{color :'red'}}>{this.state.errorMessage}</h4>
                        }
                        {content && (
                            <div>
                                <SettingContentActive 
                                    dbUpdateLink={this.props.firebase.documentIdByCollection('newsArticles', this.state.cid)}
                                    title={"Active"} 
                                    content={content} 
                                    params={['active']}
                                />
                                <SettingContentSelect 
                                    dbUpdateLink={this.props.firebase.documentIdByCollection('newsArticles', this.state.cid)}
                                    title={"Position in Selection"} 
                                    content={content} 
                                    params={['position']}
                                    selectOptions={[1, 2, 3, 4, 5, 6]}
                                />
                                <SettingContentBase 
                                    dbUpdateLink={this.props.firebase.documentIdByCollection('newsArticles', this.state.cid)}
                                    emptyOption={true}
                                    title={"Name & Description"} 
                                    content={content} 
                                    params={['title', 'description']}
                                />
                                <SettingContentBase 
                                    dbUpdateLink={this.props.firebase.documentIdByCollection('newsArticles', this.state.cid)}
                                    title={"Button Text and Link (URL)"} 
                                    content={content} 
                                    params={['button', 'url']}
                                />
                                <SettingContentUploadImage
                                    dbUpdateLink={this.props.firebase.documentIdByCollection('newsArticles', this.state.cid)}
                                    title={"Main Image (for single image use)"} 
                                    content={content} 
                                    params={['imageUrl']}
                                    uploadDatabase={this.props.firebase.storageImages('News/'+this.state.cid)}
                                />
                                <SettingContentRichText
                                    dbUpdateLink={this.props.firebase.documentIdByCollection('newsArticles', this.state.cid)}
                                    title={"Edit Richt Text (external tool)"} 
                                    content={content} 
                                    params={['text']}
                                    />
                                <SettingContentSelect 
                                    dbUpdateLink={this.props.firebase.documentIdByCollection('newsArticles', this.state.cid)}
                                    title={"targetRole"} 
                                    content={content} 
                                    params={['targetRole']}
                                    selectOptions={['default', 'REMOTECOACH', 'COACHEE', 'OBSERVE']}
                                />
                                <SettingContentArraySelect 
                                    dbUpdateLink={this.props.firebase.documentIdByCollection('newsArticles', this.state.cid)}
                                    title={"Roles"} 
                                    content={content} 
                                    params={['roles']}
                                    selectOptions={['default', 'REMOTECOACH', 'COACHEE', 'OBSERVE']}
                                />
                                <Button 
                                    onClick= {() => this.duplicate(content)}
                                >
                                    Duplicate
                                </Button>
                            </div>
                        )}
                    </div>
                    }
                </DialogContent>
            </Dialog> 
        );
    }
}

AdminNewsPage.propTypes = {
    classes: PropTypes.object.isRequired
}

const NewsList = withFirebase(NewsListBase);
const NewsItemDialog = withFirebase(NewsItemBaseDialog);

const condition = authUser =>
    authUser && authUser.roles?.includes(ROLES.ADMIN);

export default compose(
    withStyles(styles),
    // withEmailVerification,
    withAuthorization(condition),
)(AdminNewsPage);

