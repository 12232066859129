export const LANDING = '/';
export const HOME = '/home';
export const WELCOME = '/welcome';
export const WELCOMECHAT = '/welcomechat';
export const ONBOARDING = '/onboarding';
export const ONBOARDQUICK = '/onboardquick';
//export const WELCOME_1 = '/welcome_1';
//export const WELCOME_2 = '/welcome_2';
//export const WELCOME_3 = '/welcome_3';
//export const ONBOARDING_Q1 = '/onboarding_q1';
//export const ONBOARDING_Q2 = '/onboarding_q2';
//export const ONBOARDING_Q3 = '/onboarding_q3';
//export const ONBOARDING_Q4 = '/onboarding_q4';
//export const ONBOARDING_Q5 = '/onboarding_q5';
//export const ONBOARDING_LOADING_RESULT = '/onboarding_loading_result';
//export const ONBOARDING_SCENARIO = '/onboarding_scenario';
export const FIRST_SESSION = '/first_session';
export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const PASSWORD_FORGET = '/pw-forget';
export const LINK_UNSUBSCRIBE_EMAIL = '/emailnotifications';
export const PROGRAM = '/program';
export const ROUTINE = '/routine';
export const STATS = '/stats';
export const CONTENT = '/content';
export const DIALOGUE = '/dialogue';
export const JOURNAL = '/days';
export const MESSAGES = '/messages';
export const SUPPORT = '/support';
export const CHATBOT = '/chatbot';
export const START_PRIMING = '/start_priming';
export const START_REFLECTION = '/start_reflection';
export const DIALOGUES = '/dialogues';
export const DIALOGUES_DETAIL = '/dialogues/:id';
//export const ACCOUNT = '/account';
export const PROFILE = '/profile';
export const PAYMENT = '/payment';
export const PRODUCTS = '/products';

// ADMIN SECTION
export const ADMIN = '/admin';
export const ADMIN_DASHBOARD = '/admin/dashboard';
export const ADMIN_USERS = '/admin/users';
export const ADMIN_TUTORIALS = '/admin/tutorials';
export const ADMIN_SHOP = '/admin/shop';
export const ADMIN_NEWS = '/admin/news';
export const ADMIN_USERS_DETAILS = '/admin/users/:id';
export const ADMIN_CONTENTS = '/admin/contents';
export const ADMIN_CONTENTS_DETAILS = '/admin/contents/:id';
export const ADMIN_FLAGS = '/admin/content_flags'
export const ADMIN_BADGES = '/admin/badges';
export const ADMIN_BADGES_DETAILS = '/admin/badges/:id';
export const ADMIN_DIALOGUES = '/admin/dialogues';
export const ADMIN_DIALOGUES_DETAILS = '/admin/dialogues/:id';
export const ADMIN_FEEDBACKS = '/admin/feedbacks';
export const ADMIN_LEADERSHIP_CATEGORIES = '/admin/leadership_categories';
export const ADMIN_LEADERSHIP_CATEGORIES_DETAILS = '/admin/leadership_categories/:id';
export const ADMIN_LEADERSHIP_PROGRAMS = '/admin/leadership_programs';
export const ADMIN_LEADERSHIP_PROGRAMS_DETAILS = '/admin/leadership_programs/:id';
export const ADMIN_LEADERSHIP_QUALITIES = '/admin/leadership_qualities';
export const ADMIN_LEADERSHIP_QUALITIES_DETAILS = '/admin/leadership_qualities/:id';
export const ADMIN_TASKS = '/admin/tasks';
export const ADMIN_TASKS_DETAILS = '/admin/tasks/:id';
export const ADMIN_UTTERANCES = '/admin/utterances';
export const ADMIN_UTTERANCES_DETAILS = '/admin/utterances/:id';
export const ADMIN_INTENTS = '/admin/intents';
export const ADMIN_INTENTSTATS = '/admin/intentstats';
export const ADMIN_TRAINING = '/admin/training';
export const ADMIN_QUESTIONNAIRES = '/admin/questionnaires';


// COACH Pages
export const COACH = '/coach';
export const COACH_SIGN_UP = '/coachsignup';
export const CREATOR_SIGN_UP = '/creatorsignup';
export const TEAM_SIGN_UP = '/teamsignup';
export const COACH_USERS = '/coach/users';
export const COACH_CONTENT = '/coach/content';
export const COACH_ADMIN = '/coach/admin';

export const ALEXA_SIGN_IN = '/alexasignin';

export function IsWhiteLabel(that){
    console.log('IsWhiteLabel', that)
    if(that?.props?.defaultSet?.programURL?.includes('http')){
        return true
    }
    else if(that?.props?.defaultSet?.programSupportURL?.includes('http')){
        return true
    }
    else if(process?.env?.REACT_APP_DEVICE_PLATFORM==="BROWSER"){
        if(window.location?.hostname?.includes('rocky.ai')){
            return false
        }
        else if(window.location?.hostname?.includes('my-ai.coach') || window.location?.hostname?.includes('mydaily.coach')){
            return true
        }
    }
    else{

    }
    return false
}

export function LinkCompanyURL(that){
    /// preferred url to company Page
    console.log('that', that)
    var LinkCompanyURL = 'https://www.rocky.ai';
    if(that?.props?.defaultSet?.programCompanyURL?.includes("http")){
        return that.props.defaultSet.programCompanyURL
    }
    else if(that?.props?.defaultSet?.programSupportURL?.includes("http")){
        return that.props.defaultSet.programSupportURL
    }
    else if(that?.props?.defaultSet?.programBookingURL?.includes("http")){
        return that.props.defaultSet.programBookingURL
    }
    return LinkCompanyURL
}
export function LinkSupportURL(that){
    /// preferred url to cSupport
    //console.log('support', that)
    var LinkCompanyURL = 'https://www.rocky.ai/customersupport';
    if(that?.props?.defaultSet?.programSupportURL?.includes("http")){
        return that.props.defaultSet.programSupportURL
    }
    else if(that?.props?.defaultSet?.programBookingURL?.includes("http")){
        return that.props.defaultSet.programBookingURL
    }
    else if(that?.props?.defaultSet?.programCompanyURL?.includes("http")){
        return that.props.defaultSet.programCompanyURL
    }
    return LinkCompanyURL
}
export function LinkBookMeeting(that){
    //console.log('LinkBookMeeting', that)
    //// Find the right meeting link if available and fall backs
    var userSet = that?.props?.user || that?.context || undefined;
    var result = "https://www.rocky.ai/customersupport"
    if(userSet?.roles?.includes("COACHADMIN") || userSet?.roles?.includes("ADMIN")){
        return "https://calendly.com/rocky-ai/30min"
    }
    else if(that?.props?.defaultSet !== undefined){
        if(that?.props?.defaultSet?.programBookingURL?.includes("http")){
            return that.props.defaultSet.programBookingURL
        }
        else if(that?.props?.defaultSet?.programSupportURL?.includes("http")){
            return that.props.defaultSet.programSupportURL
        }
        else if(that?.props?.defaultSet?.programCompanyURL?.includes("http")){
            return that.props.defaultSet.programCompanyURL
        }
        else if(that?.props?.defaultSet?.programEmail?.includes("@")){
            return that.props.defaultSet.programEmail
        }
    }
    return result
}
export function LinkPrivacyURL(that){
    //// Find the right meeting link if available and fall backs
    var userSet = that?.props?.user || that?.context || undefined;
    var result = "https://www.rocky.ai/privacy-policy"
    if(userSet?.roles?.includes("COACHADMIN") || userSet?.roles?.includes("ADMIN")){
        return result
    }
    if(that?.props?.defaultSet?.programPrivacyURL?.includes("http")){
        return that.props.defaultSet.programPrivacyURL
    }
    return result
}
export function LinkTermsURL(that){
    //// Find the right meeting link if available and fall backs
    var userSet = that?.props?.user || that?.context || undefined;
    var result = "https://www.rocky.ai/terms-and-conditions"
    if(userSet?.roles?.includes("COACHADMIN") || userSet?.roles?.includes("ADMIN")){
        return result
    }
    if(that?.props?.defaultSet?.programTermsURL?.includes("http")){
        return that.props.defaultSet.programTermsURL
    }
    return result
}
export function ProgramCodePreferred(that){
    /// preferred code
    //console.log('that ProgramCodePreferred', that)
    var userSet = that?.props?.user || that?.context || undefined;
    var programCodePreferred = 'default'
    if(userSet?.roles?.includes("COACHADMIN")){
        return 'default'
    }
    else if(userSet?.programCode?.length > 2){
        return userSet.programCode
    }
    else if(that?.props?.defaultSet?.programCode?.length > 2){
        return that.props.defaultSet.programCode
    }
    return programCodePreferred
}