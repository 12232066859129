import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import LoadingProgress from '../../Modules/LoadingProgress';

import { withFirebase } from '../../Firebase';
import { withAuthorization,
	 // withEmailVerification
       } from '../../Session';
import { AuthUserContext } from '../../Session';
import * as ROLES from '../../../constants/roles';
import * as ROUTES from '../../../constants/routes';

// MUI stuff
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
//import Table from '@material-ui/core/Table';
//import TableCell from '@material-ui/core/TableCell';
//import TableHead from '@material-ui/core/TableHead';
//import TableRow from '@material-ui/core/TableRow';
//import TableBody from '@material-ui/core/TableBody';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
//import ButtonSwitch from '@material-ui/core/Switch';
import {Column, Table, SortDirection} from 'react-virtualized';
import {  AutoSizer } from 'react-virtualized';
import 'react-virtualized/styles.css'
import _ from "lodash";


import { SelectOption, SelectWrapper } from '../../Modules/SelectOption';
// Icons
import AddIcon from '@material-ui/icons/Add';

// Modules for parameter change
import SettingContentBase from '../Modules/SettingContentBase';
import SettingContentActive from '../Modules/SettingContentActive';
import SettingContentArrayCheckbox from '../Modules/SettingContentArrayCheckbox';
//import SettingContentNumber from '../Modules/SettingContentNumber';
//import SettingContentArray from '../Modules/SettingContentArray';
//import SettingContentImage from '../Modules/SettingContentImage';
import SettingContentUploadImage from '../Modules/SettingContentUploadImage';
//import SettingContentRichText from '../Modules/SettingContentRichText';
//import SettingContentArraySelect from '../Modules/SettingContentArraySelect';
import SettingContentSelect from '../Modules/SettingContentSelect';

const styles = {
    table: {
        minWidth: 100,
    },
    subtitle: {
        marginTop: 20,
        fontWeight: 700,
    },
    addButton: {
        marginTop: 20,
    },
};

class AdminShopPage extends Component {

    render() {
        return (
            <div style={{backgroundColor: "#DDDDDD"}}>
                <Switch>
                    <Route exact path={ROUTES.ADMIN} component={ShopList} />
                    <Route exact path={ROUTES.ADMIN_SHOP} component={ShopList} />
                </Switch>
            </div>
        );
    }
}

class ShopListBase extends Component {
    static contextType = AuthUserContext;
    _isMounted = false;
    constructor(props) {
        super(props);
        const sortBy = 'active';
        const sortDirection = SortDirection.ASC;

        this.state = {
        loading: false,
        contents: [],
        contentsTab: [],
        contentsLabels: [],
        title: '',
        author: '',
        program: '',
        open: false,
        sortBy,
        sortDirection,
        };
        //this.sortState = createMultiSort(this.sort);
    }

    componentDidMount() {
        this._isMounted = true;
        this.setState({
            loading: true,
            open: false, 
            title: '',
            author: '',
            program: '',
            showTab: '',
            errorMessage : ''
        });

        var name = '';

        this.unsubscribe = this.props.firebase
        .collectionByName('shopArticles')
        .orderBy('title', 'asc')
        .onSnapshot(snapshot => {
            let contentsTab = [];
            let contentsTabList = [];
            let contentsLabels = [];

            var keySorting = 'store'
            var content = {}
            
            snapshot.forEach(doc =>{
                if(this.context.roles?.includes('ADMIN') && this._isMounted){
                    if(this.context.programs?.includes(doc.data().program) || doc.data().program===undefined){
                        name = doc.data()[keySorting];
                        content = doc.data();
                        if(name === undefined){
                            name = '---undefined---'
                        }
                        if(!(name in contentsTab)){
                            //contentsTab[doc.data().category]={name:doc.data().category, contents: []};
                            contentsTab[name] = [];
                            contentsTabList[name]= [];
                            contentsLabels.push(name );
                        }
                        contentsTab[name].push({ ...doc.data(), uid: doc.id });
                        contentsTabList[name].push({
                            active: content.active? 'Yes': 'No',
                            production: content.production? 'Yes': 'No',
                            button: content.button || '-',
                            type : content.type || '-',
                            billing:  content.billing || '-',
                            title:  content.title || '-',
                            price:  content.price || '-',
                            uid:  doc.id ,
                        });
                    } // end if true
                } // end of if ADMIN
                else if(this.context.roles?.includes('COACHADMIN') && this._isMounted ){
                    if(this.context.programs?.includes(doc.data().program) && doc.data().program !=='default'){
                        name = doc.data()[keySorting];
                        content = doc.data();
                        if(name === undefined){
                            name = '---undefined---'
                        }
                        if(!(name in contentsTab)){
                            //contentsTab[doc.data().category]={name:doc.data().category, contents: []};
                            contentsTab[name] = [];
                            contentsTabList[name] = [];
                            contentsLabels.push(name );
                        }
                        contentsTab[name].push({ ...doc.data(), uid: doc.id });
                        contentsTabList[name].push({
                            active: content.active? 'Yes': 'No',
                            production: content.production? 'Yes': 'No',
                            button: content.button || '-',
                            type : content.type || '-',
                            billing:  content.billing || '-',
                            title:  content.title || '-',
                            price:  content.price || '-',
                            uid:  doc.id ,
                        });
                    }//end if program / no default
                } // end of if ADMIN
            });//end of snapshot


            //console.log('contents', contentsTab)
            if(this.state.showTab === undefined || this.state.showTab === ''){
                this.setState({showTab: name})
            }
            if(this._isMounted){
                this.setState({
                    contentsTab: contentsTab,
                    contentsTabList,
                    contentsLabels,
                    loading: false,
                });
            }
            //console.log('total', contentsTab)
        });
        
    }

    _sortList = ({sortBy, sortDirection}) => {
        const list = this.state.contentsTabList[this.state.showTab];
        //console.log('sortingbefore', sortBy ,sortDirection)
        let newList = _.sortBy(list, [sortBy]);
        if (sortDirection === SortDirection.DESC) {
            newList.reverse();
        }
        //console.log('sorting',newList, this.state.showTab )
        return newList;
    }
    _sort = ({sortBy, sortDirection}) => {
        const sortedList = this.state.contentsTabList;
        sortedList[this.state.showTab] = this._sortList({sortBy, sortDirection});
        this.setState({sortBy, sortDirection, contentsTabList: sortedList});
    }

    handleOpen = () => {
        //console.log('handleOpen');
        this.setState({ open: true });
    };

    handleClose = () => {
        //console.log('handleClose');
        this.setState({ open: false });
    };

    handleChange = (event) => {
        //console.log([event.target.name] + ": " + event.target.value);
        this.setState({
        [event.target.name]: event.target.value
        });
    }

    handleSubmit = () => {
        if(this.state.title !== '' && this.state.author !== '' && this.state.category !== '')
        {
            const timestamp = this.props.firebase.fieldValue.serverTimestamp();
            this.props.firebase.collectionByName('shopArticles').add({
                title: this.state.title,
                author: [this.state.author],
                store: this.state.store,
                active: false,
                createdAt: timestamp
            });
            this.setState({errorMessage : 'Added content'})
        }
        else{
            this.setState({errorMessage : 'All three values have to be defined to add a content'})
        }
        this.setState({
            title: '',
            author: '',
            program: '',
        });
        this.handleClose();
    }

    componentWillUnmount() {
        this._isMounted = false;
        this.unsubscribe();
    }

    handleTabChange = (event, newValue) => {
        //console.log('new tab', newValue)
        this.setState({
        showTab: newValue
        });
    }

    render() {
        const { showTab, contentsTab, contentsLabels, loading } = this.state;
        //console.log('votes', maxVotes)
        return (
            <div style={{height: '100vh'}}>
                {loading && 
                    <LoadingProgress 
                        defaultSet={this.props.defaultSet}
                        type={'LoadingPageAnimated'} 
                        loadingText={"many, many, data"} 
                        firebaseConnectionSpeed={this.state.firebaseConnectionSpeed} 
                        allowRefresh={true}
                    />
                }
                <div>
                    <IconButton style={styles.addButton} onClick={this.handleOpen}>
                        <AddIcon />
                    </IconButton>
                    <Dialog
                        open={this.state.open}
                        onClose={this.handleClose}
                        fullWidth
                        maxWidth="sm">
                        <DialogContent>
                        <DialogTitle>Add a new Shop item</DialogTitle>
                        <form>
                            <TextField
                                name="title"
                                style={ {fontSize: '16px'} }
                                type="text"
                                label="1. Title"
                                placeholder="Enter title"
                                value={this.state.title}
                                onChange={this.handleChange}
                                fullWidth
                            />
                            <TextField
                                name="author"
                                style={ {fontSize: '16px'} }
                                type="text"
                                label="2. Author"
                                placeholder="Enter content author name"
                                value={this.state.author}
                                onChange={this.handleChange}
                                fullWidth
                            />
                            <p>3. Select program</p>
                            <SelectWrapper
                                value={this.state.store}
                                onChange={this.handleChange}
                                name='store'
                            >
                            {this.state.contentsLabels.map(entry => {
                                return (
                                    <SelectOption key={"add_"+entry} value={entry}
                                        >
                                        {entry}
                                    </SelectOption>
                                );})
                            }
                                
                            </SelectWrapper>
                            
                            </form>
                            <DialogActions>
                                <Button onClick={this.handleClose}> Cancel </Button>
                                <Button onClick={this.handleSubmit}> Save </Button>
                            </DialogActions>
                        </DialogContent>
                    </Dialog>
                </div>
                {this.state.errorMessage !== '' && 
                <h4 style={{color :'red'}}>{this.state.errorMessage}</h4>
                }
                <Tabs
                    variant="scrollable"
                    scrollButtons="auto"
                    onChange={this.handleTabChange}
                    initialselectedindex={0}
                    value={showTab}
                >
                    {contentsLabels.length>0 && 
                        contentsLabels.map( (key) => (
                        <Tab 
                            value={key} 
                            key={key}
                            label={key + " ("+contentsTab[key].length+")"}/>
                        ))
                    }
                </Tabs>
                {contentsLabels.length>0
                 &&  contentsLabels.map( (key) => (
                    
                    <div 
                    value={key} 
                    key={key} 
                    hidden={showTab !==key}>
                        <AutoSizer>
                        {({ height, width }) => (
                            <Table
                                width={width}
                                height={700}
                                headerHeight={20}
                                rowHeight={30}
                                rowCount={this.state.contentsTabList[key].length}
                                sort={this._sort}
                                sortBy={this.state.sortBy}
                                sortDirection={this.state.sortDirection}
                                rowGetter={({index}) => this.state.contentsTabList[key][index]}
                                onRowClick={({ event, index, rowData })  => {
                                    this.setState({
                                        currentContentId: this.state.contentsTabList[key][index].uid, 
                                        dialogOpen:true
                                    });
                                    //console.log('state odate', this.state.contentsTabList[key][index])
                                }}
                            >
                                <Column label="Active" dataKey="active" width={100} disableSort={false}  />
                                <Column label="Title" dataKey="title" width={300} disableSort={false} />
                                <Column label="Public" dataKey="production" width={100} disableSort={false} />
                                <Column label="Price" dataKey="price" width={200} disableSort={false} />
                                <Column label="Type" dataKey="type" width={200} disableSort={false} />
                                <Column label="Billing" dataKey="billing" width={200} disableSort={false} />
                                <Column label="Button" dataKey="button" width={100} disableSort={false} />
                                
                            </Table>
                        )}
                        </AutoSizer>
                    </div>
                ))
                }
                    <ShopItemDialog 
                        contentId={this.state.currentContentId}
                        openDialog={this.state.dialogOpen|| false}
                        closeDialog={(e) => {this.setState({dialogOpen : false })}}
                        contentsLabels={contentsLabels}
                    />
                </div>
        );
    }
}


class ShopItemBaseDialog extends Component {
    static contextType = AuthUserContext;
    _isMounted = false;
    constructor(props) {
        super(props);
        var errorMessage = '';
        var contentsLabels = ['total'];
        this.state = {
            loading: false,
            content: null,
            cid:'',
            contentsLabels: contentsLabels,
            errorMessage: errorMessage,
        };
    }
  
    componentDidMount() {
        this._isMounted = true;
        //    this.getleadershipCategoriesArray();
        //    this.getleadershipQualitiesArray();
        //    this.getBotUtterArray();
    }
  
    componentDidUpdate() {
        //console.log('myid',this.props.contentId)
        if (this.props.contentId && this.state.cid !== this.props.contentId) {
            //console.log('myidopen', this.props.contentId)
            this.setState({ loading: true, cid: this.props.contentId });
            this.unsubscribe = this.props.firebase
                .documentIdByCollection('shopArticles', this.props.contentId)
                .onSnapshot(snapshot => {
                    //console.log('data',snapshot.data())
                    if(snapshot && this._isMounted){
                        this.setState({
                            content: snapshot.data(),
                            loading: false,
                        });
                    }
                });
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
        this.setState({content:null});
        if (this.unscrubscribe !== undefined ) this.unsubscribe();
    }

    duplicate(content){
        if(content.active !== undefined){
            var newContent = content;
            newContent['title'] = ('COPY ...' + content.title);
            newContent['active'] = false;
            newContent['author'] = this.context.email;
            newContent['createdAt'] = this.props.firebase.fieldValue.serverTimestamp();
            this.props.firebase.collectionByName('shopArticles').add(newContent);
            //console.log('newContent', newContent)
        }
    }

    render() {
        const { content, loading } = this.state;
         
        //console.log('my context', this.context)
        return (
            <Dialog
                open={this.props.openDialog}
                onClose={this.props.closeDialog}
                >
                <DialogTitle >
                    <IconButton onClick={this.props.closeDialog}>
                        (X)
                    </IconButton>
                    Modify Content
                </DialogTitle>
                <DialogContent >
                    {this.props.openDialog &&
                    <div>
                        <h5 style={styles.subtitle}>Content ({this.props.contentId})</h5>
                        {loading && 
                        <LoadingProgress 
                            defaultSet={this.props.defaultSet}
                            type={'LoadingPageAnimated'} 
                            loadingText={"many, many, data"} 
                            firebaseConnectionSpeed={this.state.firebaseConnectionSpeed} 
                            allowRefresh={true}
                        />
                    }
                
                        {this.state.errorMessage !== '' && 
                            <h4 style={{color :'red'}}>{this.state.errorMessage}</h4>
                        }
                        {content && (
                            <div>
                                <SettingContentActive 
                                    dbUpdateLink={this.props.firebase.documentIdByCollection('shopArticles', this.state.cid)}
                                    title={"Active"} 
                                    content={content} 
                                    params={['active']}
                                />
                                <SettingContentActive 
                                    dbUpdateLink={this.props.firebase.documentIdByCollection('shopArticles', this.state.cid)}
                                    title={"Production / (Sandbox or testing only if not active)"} 
                                    content={content} 
                                    params={['production']}
                                />
                                <SettingContentSelect 
                                    dbUpdateLink={this.props.firebase.documentIdByCollection('shopArticles', this.state.cid)}
                                    title={"Position in Selection"} 
                                    content={content} 
                                    params={['position']}
                                    selectOptions={[1, 2, 3, 4, 5, 6]}
                                />
                                <SettingContentBase 
                                    dbUpdateLink={this.props.firebase.documentIdByCollection('shopArticles', this.state.cid)}
                                    emptyOption={true}
                                    title={"Name & Description"} 
                                    content={content} 
                                    params={['title', 'description']}
                                />
                                <SettingContentBase 
                                    dbUpdateLink={this.props.firebase.documentIdByCollection('shopArticles', this.state.cid)}
                                    emptyOption={true}
                                    title={"Default Price in USD and promotion discount text"} 
                                    content={content} 
                                    params={['price', 'promotion', 'details', 'comparison']}
                                />
                                <SettingContentActive 
                                    dbUpdateLink={this.props.firebase.documentIdByCollection('shopArticles', this.state.cid)}
                                    title={"Premium Feature Access"} 
                                    content={content} 
                                    params={['premium']}
                                />
                                <SettingContentSelect 
                                    dbUpdateLink={this.props.firebase.documentIdByCollection('shopArticles', this.state.cid)}
                                    title={"Billing type (internal)"} 
                                    content={content} 
                                    params={['billing']}
                                    selectOptions={['paid once','billed monthly','billed annually','billed every 3 months']}
                                />
                                <SettingContentSelect 
                                    dbUpdateLink={this.props.firebase.documentIdByCollection('shopArticles', this.state.cid)}
                                    title={"Buyer category"} 
                                    content={content} 
                                    params={['category']}
                                    selectOptions={['enduser','coach']}
                                />
                                <SettingContentSelect 
                                    dbUpdateLink={this.props.firebase.documentIdByCollection('shopArticles', this.state.cid)}
                                    title={"Billing indication (user)"} 
                                    content={content} 
                                    params={['type']}
                                    selectOptions={['MONTHLY', 'YEARLY', 'PURCHASE', 'SEATS', '90 DAYS']}
                                />
                                <SettingContentSelect 
                                    dbUpdateLink={this.props.firebase.documentIdByCollection('shopArticles', this.state.cid)}
                                    title={"Button text"} 
                                    content={content} 
                                    params={['button']}
                                    selectOptions={['Subscribe Monthly', 'Subscribe Annually', 'Join Monthly', 'Join Annually', 'Buy Chats', 'Buy Seats', 'Allow to Link', 'Subscribe for 90 Days', 'Join for 90 Days', 'Subscribe for 90 Days', 'Join for 12 Months']}
                                />
                                <SettingContentUploadImage
                                    dbUpdateLink={this.props.firebase.documentIdByCollection('shopArticles', this.state.cid)}
                                    title={"Main Image (for single image use)"} 
                                    previewImageSize={true}
                                    content={content} 
                                    params={['imageUrl']}
                                    uploadDatabase={this.props.firebase.storageImages('Shop/'+this.state.cid)}
                                />
                                <SettingContentSelect 
                                    dbUpdateLink={this.props.firebase.documentIdByCollection('shopArticles', this.state.cid)}
                                    title={"Store"} 
                                    content={content} 
                                    params={['store']}
                                    selectOptions={['Mobile','Stripe', 'Paytm', 'Bluesnap', 'accountLinking']}
                                />
                                <SettingContentBase 
                                    dbUpdateLink={this.props.firebase.documentIdByCollection('shopArticles', this.state.cid)}
                                    title={"App Store GroupId, StoreId, ItemId"} 
                                    content={content} 
                                    params={['groupId','storeId', 'id']}
                                />
                                <SettingContentArrayCheckbox
                                    dbUpdateLink={this.props.firebase.documentIdByCollection('shopArticles', this.state.cid)}
                                    title={"Is this item exclusive?"} 
                                    content={content} 
                                    params={['requirements']}
                                    selectOptions={['default', 'executive', 'professional', 'seeker', 'student', 'teenager']}
                                    selectOptionsText={['Question for every situation', 'Executive/Entrepreneur', 'Professional/Employed', 'Available/Job-seeker', 'Student (Uni)', 'K-12/Teenager' ]}
                                    />
                                <Button 
                                    onClick= {() => this.duplicate(content)}
                                >
                                    Duplicate
                                </Button>
                            </div>
                        )}
                    </div>
                    }
                </DialogContent>
            </Dialog> 
        );
    }
}

AdminShopPage.propTypes = {
    classes: PropTypes.object.isRequired
}

const ShopList = withFirebase(ShopListBase);
const ShopItemDialog = withFirebase(ShopItemBaseDialog);

const condition = authUser =>
    authUser && authUser.roles?.includes(ROLES.ADMIN);

export default compose(
    withStyles(styles),
    // withEmailVerification,
    withAuthorization(condition),
)(AdminShopPage);

