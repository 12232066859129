import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import LoadingProgress from '../../Modules/LoadingProgress';
import {ZonesEnglish} from '../../../constants/languages';

import { AuthUserContext } from '../../Session';
import { withFirebase } from '../../Firebase';
import { withAuthorization,
	 // withEmailVerification
       } from '../../Session';
import * as ROLES from '../../../constants/roles';
import * as ROUTES from '../../../constants/routes';
import Grid from '@material-ui/core/Grid';


//Modules for user admin
import {DeleteUser} from '../Modules/DeleteUser';
import CoachUserActions from '../../Coach/CoachUserActions';
import DayList from '../../Days/DayList';

// MUI stuff

import {RemoveScroll} from 'react-remove-scroll';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionActions';
import AccordionSummary from '@material-ui/core/AccordionSummary'
//table features
import {Column, Table as TabelVirtual, SortDirection} from 'react-virtualized';
import {  AutoSizer } from 'react-virtualized';
import 'react-virtualized/styles.css'
import _ from "lodash";
//import TextField from '@material-ui/core/TextField';
//import TableSortLabel from '@material-ui/core/TableSortLabel';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';


import { SelectOption, SelectWrapper } from '../../Modules/SelectOption';
// Icons
//import EditIcon from '@material-ui/icons/Edit';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';
// Modules for parameter change
import SettingContentBase from '../Modules/SettingContentBase';
import SettingContentActive from '../Modules/SettingContentActive';
import SettingContentNumber from '../Modules/SettingContentNumber';
import SettingContentArray from '../Modules/SettingContentArray';
//import SettingContentImage from '../Modules/SettingContentImage';
//import SettingContentRichText from '../Modules/SettingContentRichText';
import SettingContentArraySelect from '../Modules/SettingContentArraySelect';
import SettingContentSelect from '../Modules/SettingContentSelect';
import { CheckMobileNotificationActivated } from '../../Modules/StoreMobileDeviceInfo';

const styles = {
    admin: {
        color: 'blue',
    },
    button: {
        textTransform: 'none',
    },
    column: {
        flexBasis: '66.67%',
    },
    loading: {
        marginTop: 20,
        marginBottom: 20,
        textAlign: 'center',
    },
    select: {
        minWidth: '100%',
    },
    subtitle: {
        marginTop: 20,
        fontWeight: 700,
    },
    table: {
        color: 'red',
        minWidth: 100,
    },
};


const AdminUsersPage = () => (
    <div style={{backgroundColor: "#DDDDDD"}}>
        <Switch>
            <Route exact path={ROUTES.ADMIN} component={UserList} />
            <Route exact path={ROUTES.ADMIN_USERS} component={UserList} />
        </Switch>
    </div>
);

class UserListBase extends Component {
    static contextType = AuthUserContext;

    constructor(props) {
        super(props);

        const sortBy = 'lastEngagement';
        const sortDirection = SortDirection.DESC;

        this.state = {
            loading: false,
            contents: [],
            sortBy,
            sortDirection,
        };
    
    }

    componentDidMount() {
        this.setState({
            loading: true,
            open: false, 
            showTab: '',
        });
        this.loadData();
    }

    componentWillUnmount() {
        //this.unsubscribe();
    }

    loadData(){
        var name = '';
        var counter = this.state.counter || 0;
        var limit = 10000;
        var lastLoadedContent = this.state.lastLoadedContent || undefined;
        let contentsTab = this.state.contentsTab || {'topActiveChatters' : []};
        let contentsTabList = this.state.contentsTabList || {'topActiveChatters' : []};
        let contentsLabels = this.state.contentsLabels || ['topActiveChatters'];
        var keySorting = 'userBatch'
        let dashboardStats = this.state.dashboardStats || 
            {'topActiveChatters': {
                    totalUsers : 0,
                    tryingUsers: 0,
                    startedChat: 0,
                    returningUsers: 0,
                    topActiveChatters: 0,
                    finishedOnboarding: 0,
                    deactivatedUsers : 0,
                    profiledUsers : 0,
                    activeUsers: 0,
                    activeNotifications: 0,
                    interestedUsers:  0,
                    day1Retention : 0,
                    day7Retention : 0,
                    day30Retention : 0,
                    categories: {},
                    qualities : {},
                }
            }
        var dt = new Date(Date.now() - 24096e5); // minus 4 weeks
        //var dt = new Date(Date.now() - 2.592e6); // minus 30days
        var dt1MonthAgo = new Date(Date.now() - 24096e5);
        var dtSeconds1MonthAgo = dt.getTime();
        this.unsubscribe = this.props.firebase
        .users()
        //.orderBy('email', 'asc')
        .limit(1000)
        if(this.state.filterLang === 'active-only'){
            console.log('active-only', lastLoadedContent)
            this.unsubscribe = this.unsubscribe.where('lastEngagement', '>', dt1MonthAgo).orderBy('lastEngagement', 'desc');
        }
        else{
            this.unsubscribe = this.unsubscribe.orderBy('journeyStartedAt', 'desc');
        }
        if(lastLoadedContent !== undefined){
            //console.log('lastLoadedContent c', lastLoadedContent, counter)
            console.log('lastLoadedContent c', lastLoadedContent?.lastEngagement, counter)
            this.unsubscribe = this.unsubscribe.startAfter(lastLoadedContent);
        }
        ///// compare against users with assessment 
        //.where('currentAssessment', '>', '')
        this.unsubscribe.get().then(snapshot => {
        //.onSnapshot(snapshot => {
            var content = {};
            var counterLine = 0; 
            //var dt = new Date(); dt.setMonth(dt.getMonth()-1);
            //var keySorting = 'timezone'
            snapshot.forEach(doc =>{
                content = doc.data();
                counter += 1;
                counterLine += 1;
                lastLoadedContent = doc;
                //console.log('new id', lastLoadedContent, counter, content)
                if(counter % 50 === 0 && this._isMounted){
                    var progress = parseInt(counter/limit*100);
                    this.setState({loadingUserProgress : progress});
                }
                ////// Checking a bunch of filters
                var filterPassedLang = true;
                var filterPassedAudience = true;
                var filterPassedOS = true;
                if(this.state.filterRole !== undefined && this.state.filterRole !== 'default'){
                    if(content.onboardingResponses?.includes(this.state.filterRole)) filterPassedAudience = true;
                    else filterPassedAudience = false;
                }
                if(this.state.filterLang === 'target-english'){
                    if(content.onboardingResponses?.includes('Executive')) filterPassedAudience = true;
                    else if(content.onboardingResponses?.includes('Professional')) filterPassedAudience = true;
                    else if(content.onboardingResponses?.includes('Available')) filterPassedAudience = true;
                    else if(content.onboardingResponses?.includes('Student')) filterPassedAudience = true;
                    else filterPassedAudience = false;
                }
                if(this.state.filterLang !== undefined && this.state.filterLang !== 'default'){
                    var zones = ZonesEnglish;
                    if(this.state.filterLang === 'active-only'){
                        filterPassedLang = true;
                    }
                    else if(content.timezone && (this.state.filterLang === 'english' || this.state.filterLang === 'target-english') && zones.includes(content.timezone) ) filterPassedLang = true;
                    else if(content.timezone && this.state.filterLang === 'non-english' && !zones.includes(content.timezone) ) filterPassedLang = true;
                    else filterPassedLang = false;
                }
                if(this.state.filterOS !== undefined){
                    filterPassedOS = false;
                    if(this.state.filterOS === 'default') filterPassedOS = true;
                    else if(this.state.filterOS === 'notification' && CheckMobileNotificationActivated(content)) filterPassedOS = true;
                    else if(this.state.filterOS === 'browser' && content.channel === "browser") filterPassedOS = true;
                    else if(this.state.filterOS === 'android' && content.mobileDevice === "Android") filterPassedOS = true;
                    else if(this.state.filterOS === 'ios' && content.mobileDevice === "iOS") filterPassedOS = true;
                    else if(this.state.filterOS === 'browser' && content.mobileDevice === undefined) filterPassedOS = true;
                    else if(this.state.filterOS === 'tutorial' && content.tutorialChecked === true) filterPassedOS = true;
                    else filterPassedOS = false;
                }
                ////// doing the accounting work
                if(filterPassedAudience && filterPassedLang && filterPassedOS && !(content.email?.includes('harry') && content.email?.includes('rocky'))){
                    var appVersion = content.appVersion !== undefined ? (content.appVersion) : 'n/a';
                    var appInstallation = content.mobileDeviceToken !== undefined ? content.mobileDeviceToken === '' ? "deinstalled" : content.mobileNotifications : "not installed";
                    var appStatus = appInstallation;
                    var userRole = ((content.onboardingResponses?.length > 0) ? content.onboardingResponses[content.onboardingResponses?.length-1] : 'n/a');
                    if(content.mobileDeviceToken !== undefined){
                        appStatus = '(' + (content.mobileDevicePushNotificationAllowed? 'Y' : 'N') + ') ' + appStatus;
                    }
                    var packageTable = {
                        cid:  doc.id,
                        email: (content.email.slice(0,3)+"****") || '-',
                        currentCategory: content.currentCategory || '-',
                        currentLeadershipQuality : content.currentLeadershipQuality || '-',
                        code : content.code || '-',
                        timezone : content.timezone || '-',
                        os : content.mobileDevice !== undefined ? content.mobileDevice : content.mobileDeviceToken !== undefined? "Mobile": "Browser",
                        purchase : (content.purchasedProduct!== undefined 
                            ?   content.purchasedProduct.owned 
                                ?   "Yes"
                                :   content.purchasedProduct.type !== undefined 
                                    ?   'Y/Ex'
                                    :   content.ratedApp 
                                        ?   'Y? Rated'
                                        :   content.dialogueCounter >= 14
                                            ?   'Y/Free+'
                                            :   'Seen'
                            :   content.ratedApp 
                                ?   'Y? Rated'
                                :   "-"
                            ),
                        emailNotifications : content.emailNotifications,
                        mobileNotifications : appStatus,
                        activeNotifications : (content.mobilePWAPushNotificationAllowed === true || content.mobileDevicePushNotificationAllowed === true) ? "Y" : "N",
                        appVersion: appVersion, 
                        lastEngagement : content.lastEngagement?.seconds ? this.convertTimestampToDate(content.lastEngagement.seconds)  : content.journeyStartedAt  ? this.convertTimestampToDate(content.journeyStartedAt.seconds)  : '-',
                        journeyStartedAt : content.journeyStartedAt?.seconds  ? this.convertTimestampToDate(content.journeyStartedAt.seconds)  : '-',  
                        dialogueCounter : content.dialogueCounter + '/'+ (content.freeDialogues?content.freeDialogues:'-'),
                        engagementCounter : content.engagementCounter || '-',
                        contentCounter : (content.contentBookmarked? content.contentBookmarked.length : 0) + (content.contentLiked? content.contentLiked.length : 0),
                        tutorialChecked : content.tutorialChecked ? "Y" : '',
                        audioStatus : (content.dialogueStatus===undefined ? 'n/a' : (content.muteAudio ? "M" : content.muteAudio === false ? 'Y' : 'X')),
                        darkmode : content.features?.darkmode ? 'B' : 'W',
                        focusmode : content.features?.mentormode === false ? 'F' : 'M',
                        onboardingStatus : content.onboardingDone ? 'Y' : 'N', 
                        currentSelf: (content.achievementSelf?.value || 'n/a'),
                        userRole: userRole,
                        quizStatus: (content.currentAssessment!==undefined && content.currentAssessment!=='') ? 'Y' : 'N'
                    };
                    if(this.context.roles?.includes('ADMIN')){
                        if(this.context.programs?.includes(content.program) || content.program===undefined){
                            name = content[keySorting];
                            
                            if(name === undefined){
                                name = '---undefined---'
                            }
                            if(!(name in contentsTab)){
                                //contentsTab[content.category]={name:content.category, contents: []};
                                contentsTab[name] = [];
                                contentsTabList[name]= [];
                                contentsLabels.push(name );
                            }
                            contentsTab[name].push({ ...content, uid: doc.id });
                            contentsTabList[name].push(packageTable);
                            ///////// this is the dashboard user information
                            var deactivatedUsers = 0;
                            var profiledUsers = 0;
                            var activeUsers = 0;
                            var activeNotifications = 0;
                            //var oneMonthUsers = 0; // not checked yet
                            var successUsers = 0; // those who subscribe/d or gave feedback or freemium extensive > 20
                            var topActiveChatters = 0;
                            var tryingUsers = 0;
                            var startedChat = 0;
                            var returningUsers = 0;
                            var finishedOnboarding = 0;
                            var interestedUsers = 0;
                            var day1Retention = 0;
                            var day7Retention = 0;
                            var day30Retention = 0;
                            if((content.mobilePWAPushNotificationAllowed === true || content.mobileDevicePushNotificationAllowed === true)){
                                activeNotifications = 1;
                            }
                            if(content.purchasedProduct?.owned || content.purchasedProduct?.type !== undefined || content.ratedApp || content.dialogueCounter >= 14){
                                successUsers = 1;
                            } 
                            if(('emailNotifications' in content && content.emailNotifications==='never' && !(content.mobileDeviceToken?.length > 2))
                                || 
                                (content.mobileDeviceToken!== undefined && content.mobileDeviceToken==='' && (content.emailNotifications==='never' || content.emailNotifications==='inactive') )
                                ) {
                                deactivatedUsers = 1;
                            }
                            if('lastEngagement' in content && content.lastEngagement.seconds > dtSeconds1MonthAgo ) activeUsers = 1;
                            else if('journeyStartedAt' in content && content.journeyStartedAt.seconds > dtSeconds1MonthAgo ) activeUsers = 1;
                            if('lastEngagement' in content && 'journeyStartedAt' in content){
                                if (content.lastEngagement.seconds - content.journeyStartedAt.seconds > 86400 && deactivatedUsers === 0) day1Retention = 1;
                                if (content.lastEngagement.seconds - content.journeyStartedAt.seconds > 604800 && deactivatedUsers === 0) day7Retention = 1;
                                if (content.lastEngagement.seconds - content.journeyStartedAt.seconds > 2592000 && deactivatedUsers === 0) day30Retention = 1;
                            }
                            if(content.dialogueStatus!==undefined){
                                startedChat = 1;
                            }
                            if('dialogueCounter' in content && content.dialogueCounter>0 ) {
                                tryingUsers = 1;
                                startedChat = 1;
                                //finishedOnboarding = 1;
                            } 
                            if(content.achievementSelf?.value?.length > 2 || content.onboardingResponses?.length > 1 ){
                                profiledUsers = 1;
                            }
                            if(('lastEngagement' in content && 'journeyStartedAt' in content) && 
                                    (content.lastEngagement.seconds - content.journeyStartedAt.seconds > 32400 )){
                                returningUsers = 1;
                            }
                            if( content.onboardingDone || (content.onboardingRequired === false && content.dialogueCounter >=1) || content.dialogueCounter >=3){
                                finishedOnboarding = 1;
                            }
                            if(('dialogueCounter' in content && content.dialogueCounter >1) 
                                || ('primingSessionStartedAt' in content && 'reflectionSessionStartedAt' in content)
                                ) {
                                //tryingUsers = 1;
                                returningUsers = 1;
                                startedChat = 1;
                                //finishedOnboarding = 1;
                                interestedUsers = 1;
                            }
                            if('dialogueCounter' in content && content.dialogueCounter >= 3 ) {
                                //console.log(content)
                                //tryingUsers = 1;
                                returningUsers = 1;
                                //finishedOnboarding = 1;
                                topActiveChatters = 1;
                                startedChat = 1;
                                if('primingSessionFinishedAt' in content && content.primingSessionFinishedAt.seconds >dtSeconds1MonthAgo ) activeUsers = 1;
                                if('reflectionSessionFinishedAt' in content && content.reflectionSessionFinishedAt.seconds >dtSeconds1MonthAgo ) activeUsers = 1;
                            }
                            //console.log('contentsTab active users', content)
                            if((((activeUsers === 1 || (returningUsers && content.dialogueCounter >= 2)) && content.dialogueCounter >= 2 )) && content.userBatch !=='testaccounts'){
                                //console.log('contentsTab active users', contentsTab)
                                contentsTab['topActiveChatters'].push({ ...content, uid: doc.id });
                                contentsTabList['topActiveChatters'].push(packageTable);
                                dashboardStats['topActiveChatters'] = {
                                    totalUsers : dashboardStats['topActiveChatters'].totalUsers + 1,
                                    successUsers : dashboardStats['topActiveChatters'].successUsers + successUsers,
                                    tryingUsers: dashboardStats['topActiveChatters'].tryingUsers + tryingUsers,
                                    startedChat: dashboardStats['topActiveChatters'].startedChat + startedChat,
                                    returningUsers: dashboardStats['topActiveChatters'].returningUsers + returningUsers,
                                    topActiveChatters: dashboardStats['topActiveChatters'].topActiveChatters + topActiveChatters,
                                    finishedOnboarding: dashboardStats['topActiveChatters'].finishedOnboarding + finishedOnboarding,
                                    deactivatedUsers : dashboardStats['topActiveChatters'].deactivatedUsers + deactivatedUsers,
                                    profiledUsers : dashboardStats['topActiveChatters'].profiledUsers + profiledUsers,
                                    activeUsers: dashboardStats['topActiveChatters'].activeUsers + activeUsers,
                                    activeNotifications: dashboardStats['topActiveChatters'].activeNotifications + activeNotifications,
                                    interestedUsers:  dashboardStats['topActiveChatters'].interestedUsers + interestedUsers,
                                    day1Retention : dashboardStats['topActiveChatters'].day1Retention + day1Retention,
                                    day7Retention : dashboardStats['topActiveChatters'].day7Retention + day7Retention,
                                    day30Retention : dashboardStats['topActiveChatters'].day30Retention + day30Retention,
                                    categories : dashboardStats['topActiveChatters']['categories'],
                                    qualities : dashboardStats['topActiveChatters']['qualities']
                                }
                                if(dashboardStats['topActiveChatters']['categories'][content.currentCategory] === undefined ){
                                    dashboardStats['topActiveChatters']['categories'][content.currentCategory] = 1 
                                }
                                else{
                                    dashboardStats['topActiveChatters']['categories'][content.currentCategory] = dashboardStats['topActiveChatters']['categories'][content.currentCategory] + 1
                                }
                                if(dashboardStats['topActiveChatters']['qualities'][content.currentLeadershipQuality] === undefined ){
                                    dashboardStats['topActiveChatters']['qualities'][content.currentLeadershipQuality] = 1 
                                }
                                else{
                                    dashboardStats['topActiveChatters']['qualities'][content.currentLeadershipQuality] = dashboardStats['topActiveChatters']['qualities'][content.currentLeadershipQuality] + 1
                                }
                            }
                            if(!(name in dashboardStats)){
                                dashboardStats[name] = {
                                    totalUsers: 1,
                                    successUsers,
                                    tryingUsers,
                                    startedChat,
                                    returningUsers,
                                    topActiveChatters,
                                    finishedOnboarding,
                                    deactivatedUsers ,
                                    profiledUsers, 
                                    activeUsers,
                                    activeNotifications,
                                    interestedUsers,
                                    day1Retention,
                                    day7Retention,
                                    day30Retention,
                                    categories : {},
                                    qualities : {},
                                    userRoles: {'Executive': 0, 'Professional': 0, 'Available': 0, 'Student': 0, 'Teenager': 0, 'Total': 0}
                                }
                            }
                            else{
                                dashboardStats[name] = {
                                    totalUsers : dashboardStats[name].totalUsers + 1,
                                    successUsers : dashboardStats[name].successUsers + successUsers,
                                    tryingUsers: dashboardStats[name].tryingUsers + tryingUsers,
                                    startedChat: dashboardStats[name].startedChat + startedChat,
                                    returningUsers: dashboardStats[name].returningUsers + returningUsers,
                                    topActiveChatters: dashboardStats[name].topActiveChatters + topActiveChatters,
                                    finishedOnboarding: dashboardStats[name].finishedOnboarding + finishedOnboarding,
                                    deactivatedUsers : dashboardStats[name].deactivatedUsers + deactivatedUsers,
                                    profiledUsers : dashboardStats[name].profiledUsers + profiledUsers,
                                    activeUsers: dashboardStats[name].activeUsers + activeUsers,
                                    activeNotifications: dashboardStats[name].activeNotifications + activeNotifications,
                                    interestedUsers:  dashboardStats[name].interestedUsers + interestedUsers,
                                    day1Retention : dashboardStats[name].day1Retention + day1Retention,
                                    day7Retention : dashboardStats[name].day7Retention + day7Retention,
                                    day30Retention : dashboardStats[name].day30Retention + day30Retention,
                                    categories : dashboardStats[name].categories,
                                    qualities : dashboardStats[name].qualities,
                                    userRoles : dashboardStats[name].userRoles,
                                }
                            }
                            if(userRole !== 'n/a'){
                                //console.log(userRole, 'role')
                                if(dashboardStats[name]['userRoles'] !== undefined && userRole in dashboardStats[name]['userRoles']){
                                    dashboardStats[name]['userRoles'][userRole] = dashboardStats[name]['userRoles'][userRole] + 1;
                                    dashboardStats[name]['userRoles'].Total = (dashboardStats[name]['userRoles'].Total  + 1);
                                    //console.log('name', dashboardStats[name]['userRoles'])
                                }
                            }

                            if(dashboardStats[name]['categories'][content.currentCategory] === undefined ){
                                dashboardStats[name]['categories'][content.currentCategory] = 1 
                            }
                            else{
                                dashboardStats[name]['categories'][content.currentCategory] = dashboardStats[name]['categories'][content.currentCategory] + 1
                            }
                            if(dashboardStats[name]['qualities'][content.currentLeadershipQuality] === undefined ){
                                dashboardStats[name]['qualities'][content.currentLeadershipQuality] = 1 
                            }
                            else{
                                dashboardStats[name]['qualities'][content.currentLeadershipQuality] = dashboardStats[name]['qualities'][content.currentLeadershipQuality] + 1
                            }
                            
                        } // end if true
                    } // end of if ADMIN
                    else if(this.context.roles?.includes('COACHADMIN')){
                        if(this.context.programs?.includes(content.program) && content.program !=='default'){
                            name = content[keySorting];
                            if(name === undefined){
                                name = '---undefined---'
                            }
                            if(!(name in contentsTab)){
                                //contentsTab[content.category]={name:content.category, contents: []};
                                contentsTab[name] = [];
                                contentsTabList[name]= [];
                                contentsLabels.push(name );
                            }
                            contentsTab[name].push({ ...content, uid: doc.id });
                            contentsTabList[name].push({packageTable});
                        }//end if program / no default
                    } // end of if ADMIN
                }
            });//end of snapshot
            //console.log('dashboardStats', dashboardStats)
            if(this.state.showTab === undefined || this.state.showTab === ''){
                this.setState({showTab: name})
            }
            contentsLabels = contentsLabels.sort().reverse();
            var previousLoadedContent = this.state.lastLoadedContent;
            //console.log('counterLineBefore',counterLine, counter, lastLoadedContent ,previousLoadedContent)
            this.setState({
                contentsTab: contentsTab,
                contentsTabList,
                contentsLabels,
                loading: false,
                counter: counter,
                lastLoadedContent: lastLoadedContent,
                dashboardStats: dashboardStats,
            }, () => {
                //console.log('counterLine',counterLine, counter, lastLoadedContent ,previousLoadedContent)
                if(counterLine >= 999 && counter < limit && lastLoadedContent !== previousLoadedContent){
                    this.loadData();
                }
            });
        });
    }
    reloadData(filterType, value){
        this.setState({
            [filterType]: value,
            loading: true,
            open: false, 
            counter: 0,
            lastLoadedContent: undefined,
            contentsTab: undefined,
            contentsTabList: undefined,
            contentsLabels: undefined,
            dashboardStats: undefined,
        }, () => this.loadData());
        
    }

    _sortList = ({sortBy, sortDirection}) => {
        const list = this.state.contentsTabList[this.state.showTab];
        //console.log('sortingbefore', sortBy ,sortDirection)
        let newList = _.sortBy(list, [sortBy]);
        if (sortDirection === SortDirection.DESC) {
            newList.reverse();
        }
        //console.log('sorting',newList, this.state.showTab )
        return newList;
    }
    _sort = ({sortBy, sortDirection}) => {
        const sortedList = this.state.contentsTabList;
        sortedList[this.state.showTab] = this._sortList({sortBy, sortDirection});
        this.setState({sortBy, sortDirection, contentsTabList: sortedList});
    }

    handleSwitchDialogue = event => {
        //console.log(this.context.uid);
        //console.log(event.target.innerText);
        localStorage?.setItem('activeDialogue', event.target.innerText);
        this.props.firebase.user(this.context.uid).update({
            currentDialogue: event.target.innerText
        })
        .then(() => this.props.history.push(ROUTES.DIALOGUE));
    };

    convertTimestampToDate = ts => {
        let date = new Date(ts * 1000);
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();
        let hour = date.getHours();
        let minute = date.getMinutes();

        month = (month < 10 ? "0" : "") + month;
        day = (day < 10 ? "0" : "") + day;
        hour = (hour < 10 ? "0" : "") + hour;
        minute = (minute < 10 ? "0" : "") + minute;

        return year + "-" + month + "-" + day + " [" + hour + ":" + minute + "]";
    }

    handleTabChange = (event, newValue) => {
        //console.log('new tab', newValue)
        this.setState({
            showTab: newValue
        });
    }


    render() {
        const { showTab, contentsTab, contentsLabels, loading, dashboardStats } = this.state;

        return (
            <div style={{height: '100vh'}}>
            {loading &&
                <div style={styles.loading}>
                    <LoadingProgress
                        allowRefresh = {true}
                        type={'LoadingPageAnimated'} 
                        loadingText={"many, many data " + (this.state.loadingUserProgress || 0) + "%"} 
                    />
                </div>
            }
            <Tabs
                variant="scrollable"
                scrollButtons="auto"
                onChange={this.handleTabChange}
                initialselectedindex={0}
                value={showTab}
            >
                {contentsLabels?.length>0 && 
                    contentsLabels.map( (key) => (
                        <Tab 
                            value={key} 
                            key={key}
                            label={key + " ("+contentsTab[key].length+")"}/>
                    ))
                }
            </Tabs>
    
            {contentsLabels?.length>0 && contentsLabels.map( (key) => (
                <div 
                    value={key} 
                    key={key} 
                    hidden={showTab !==key}>
                    <Button onClick={() => {this.setState({openStats:true})}}>Show Stats</Button>
                    &nbsp;
                    <SelectWrapper
                        id="dialogType"
                        value={(this.state.filterRole || 'default')}
                        onChange={ (e) => this.reloadData('filterRole', e.target.value)}
                        >
                        <SelectOption value={'default'}>All</SelectOption>
                        <SelectOption value={'Executive'}>Executive</SelectOption>
                        <SelectOption value={'Professional'}>Pro</SelectOption>
                        <SelectOption value={'Available'}>Available</SelectOption>
                        <SelectOption value={'Student'}>Student</SelectOption>
                        <SelectOption value={'Teenager'}>Teenager</SelectOption>
                    </SelectWrapper>
                    &nbsp;
                    <SelectWrapper
                        id="dialogType"
                        value={(this.state.filterLang || 'default')}
                        onChange={ (e) => this.reloadData('filterLang', e.target.value)}
                        >
                        <SelectOption value={'default'}>All</SelectOption>
                        <SelectOption value={'target-english'}>English Target</SelectOption>
                        <SelectOption value={'english'}>English Natives All</SelectOption>
                        <SelectOption value={'non-english'}>Non-English All</SelectOption>
                        <SelectOption value={'active-only'}>Active User</SelectOption>
                    </SelectWrapper>
                    &nbsp;
                    <SelectWrapper
                        id="dialogSource"
                        value={(this.state.filterOS|| 'default')}
                        onChange={ (e) => this.reloadData('filterOS', e.target.value)}
                        >
                        <SelectOption value={'default'}>All</SelectOption>
                        <SelectOption value={'tutorial'}>Toturial Seen</SelectOption>
                        <SelectOption value={'notification'}>Notification On</SelectOption>
                        <SelectOption value={'browser'}>Browser</SelectOption>
                        <SelectOption value={'android'}>Android</SelectOption>
                        <SelectOption value={'ios'}>iOS</SelectOption>
                        <SelectOption value={'pwm'}>PWM</SelectOption>
                    </SelectWrapper>
                    <div> 
                        <strong>{dashboardStats[key].activeUsers}</strong> ({parseInt(dashboardStats[key].activeUsers/dashboardStats[key].totalUsers*100)}%) Active (last month) -&nbsp;
                        <strong>{dashboardStats[key].successUsers}</strong> ({parseFloat(dashboardStats[key].successUsers/dashboardStats[key].totalUsers*100).toFixed(2)}%) Success Rate - &nbsp;
                        <strong>{dashboardStats[key].topActiveChatters}</strong> ({parseInt(dashboardStats[key].topActiveChatters/dashboardStats[key].totalUsers*100)}%) chatters (>=3 chats) - &nbsp;
                        <strong>{dashboardStats[key].interestedUsers}</strong> ({parseInt(dashboardStats[key].interestedUsers/dashboardStats[key].totalUsers*100)}%) chatting (2 chats) - &nbsp;
                        <strong>{dashboardStats[key].activeNotifications}</strong> ({parseInt(dashboardStats[key].activeNotifications/dashboardStats[key].totalUsers*100)}%) reminders - &nbsp;
                        <strong>{dashboardStats[key].returningUsers}</strong> ({parseInt(dashboardStats[key].returningUsers/dashboardStats[key].totalUsers*100)}%) returning - &nbsp;
                        <strong>{dashboardStats[key].tryingUsers}</strong> ({parseInt(dashboardStats[key].tryingUsers/dashboardStats[key].totalUsers*100)}%) finished chat - &nbsp;
                        <strong>{dashboardStats[key].startedChat}</strong> ({parseInt(dashboardStats[key].startedChat/dashboardStats[key].totalUsers*100)}%) started chat - &nbsp;
                        <strong>{dashboardStats[key].finishedOnboarding}</strong> ({parseInt(dashboardStats[key].finishedOnboarding/dashboardStats[key].totalUsers*100)}%) finished onboarding - &nbsp;
                        <strong>{dashboardStats[key].profiledUsers}</strong> ({parseInt(dashboardStats[key].profiledUsers/dashboardStats[key].totalUsers*100)}%) profiledUsers users - &nbsp;
                        <strong>{dashboardStats[key].deactivatedUsers}</strong> ({parseInt(dashboardStats[key].deactivatedUsers/dashboardStats[key].totalUsers*100)}%) deactivated users
                        <br/><span>{"Roles:  " +JSON.stringify(dashboardStats[key].userRoles)}</span>
                    </div>
                    <p> &nbsp;</p>

                    <AutoSizer>
                    {({ height, width }) => (
                        <TabelVirtual
                            width={width}
                            height={700}
                            headerHeight={20}
                            rowHeight={30}
                            rowCount={this.state.contentsTabList[key].length}
                            sort={this._sort}
                            sortBy={this.state.sortBy}
                            sortDirection={this.state.sortDirection}
                            rowGetter={({index}) => this.state.contentsTabList[key][index]}
                            onRowClick={({ event, index, rowData })  => {this.setState({
                                currentContentId: this.state.contentsTabList[key][index].cid, 
                                dialogOpen:true,
                                currentContent: this.state.contentsTabList[key][index],
                            })}}
                            >
                            <Column label="Email" dataKey="email" width={100} disableSort={false}  />
                            <Column label="Role" dataKey="userRole" width={100} disableSort={false}  />
                            <Column label="Self" dataKey="currentSelf" width={130} disableSort={false} />
                            <Column label="Program" dataKey="currentCategory" width={130} disableSort={false} />
                            <Column label="Quality" dataKey="currentLeadershipQuality" width={200} disableSort={false} />
                            <Column label="Code" dataKey="code" width={130} disableSort={false} />
                            <Column label="Timezone" dataKey="timezone" width={200} disableSort={false} />
                            <Column label="OS" dataKey="os" width={60} disableSort={false} />
                            <Column label="Purch" dataKey="purchase" width={100} disableSort={false} />
                            <Column label="Alert" dataKey="activeNotifications" width={60} disableSort={false} />
                            <Column label="Mail" dataKey="emailNotifications" width={100} disableSort={false} />
                            <Column label="Mobile" dataKey="mobileNotifications" width={130} disableSort={false} />
                            <Column label="Ver" dataKey="appVersion" width={70} disableSort={false} />
                            <Column label="Last" dataKey="lastEngagement" width={150} disableSort={false}  />
                            <Column label="First" dataKey="journeyStartedAt" width={150} disableSort={false}  />
                            <Column label="Onboard" dataKey="onboardingStatus" width={70} disableSort={false}  />
                            <Column label="Chats" dataKey="dialogueCounter" width={70} disableSort={false}  />
                            <Column label="Eng" dataKey="engagementCounter" width={70} disableSort={false}  />
                            <Column label="Content" dataKey="contentCounter" width={70} disableSort={false}  />
                            <Column label="Tuto" dataKey="tutorialChecked" width={70} disableSort={false}  />
                            <Column label="Quiz" dataKey="quizStatus" width={70} disableSort={false}  />
                            <Column label="A+M" dataKey="audioStatus" width={70} disableSort={false}  />
                            <Column label="D+M" dataKey="darkmode" width={70} disableSort={false}  />
                            <Column label="F+M" dataKey="focusmode" width={70} disableSort={false}  />
                        </TabelVirtual>
                    )}
                    </AutoSizer>
                </div>
            ))}
            
            <UserItemDialog 
                uid={this.state.currentContentId}
                contentsLabels={contentsLabels}
                openDialog={this.state.dialogOpen || false}
                closeDialog={(e) => {this.setState({dialogOpen: false })}}
            />
            
            {this.dialogueCategorySettings()}
        </div>
        );
    }

    dialogueCategorySettings = () => {
        const {dashboardStats, contentsLabels} = this.state;
        return(
            <RemoveScroll enabled={this.state.openStats || false}>
                <Dialog
                    key={this.state.actionId}
                    open={this.state.openStats|| false}
                    onClose={() => {this.setState({openStats : false})}}
                >
                    <DialogTitle   >
                        <IconButton onClick={() => {this.setState({openStats : false})}}>
                            <ArrowBackIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent >
                        {(this.context?.roles?.includes("ADMIN") || this.context?.roles?.includes("ADMIN") )
                        &&
                            <h4 style={{color :'blue'}}>Unique ID value: <strong>Stats</strong></h4>  
                        }
                        {dashboardStats!== undefined && contentsLabels !== undefined &&  contentsLabels.map( (key) => (
                            <Grid container  key={key} cellspacing={2}>
                                <Grid item xs={12}><h2 style={{color:'red', }}>{key}</h2></Grid>

                                <Grid item xs={3}> <strong>{dashboardStats[key].activeUsers}</strong> ({parseInt(dashboardStats[key].activeUsers/dashboardStats[key].totalUsers*100)}%) Active (last month)</Grid>
                                <Grid item xs={3}> <strong>{dashboardStats[key].day30Retention}</strong> ({parseInt(dashboardStats[key].day30Retention/dashboardStats[key].totalUsers*100)}%) Day 30</Grid>
                                <Grid item xs={3}><strong>{dashboardStats[key].day7Retention}</strong> ({parseInt(dashboardStats[key].day7Retention/dashboardStats[key].totalUsers*100)}%) Day 7</Grid>
                                <Grid item xs={3}><strong>{dashboardStats[key].day1Retention}</strong> ({parseInt(dashboardStats[key].day1Retention/dashboardStats[key].totalUsers*100)}%) Day 1</Grid>

                                <Grid item xs={3}><strong>{dashboardStats[key].topActiveChatters}</strong> ({parseInt(dashboardStats[key].topActiveChatters/dashboardStats[key].totalUsers*100)}%) chatters (>=3 chats)</Grid>
                                <Grid item xs={3}><strong>{dashboardStats[key].interestedUsers}</strong> ({parseInt(dashboardStats[key].interestedUsers/dashboardStats[key].totalUsers*100)}%) chatting (2 chats)</Grid>
                                <Grid item xs={3}><strong>{dashboardStats[key].returningUsers}</strong> ({parseInt(dashboardStats[key].returningUsers/dashboardStats[key].returningUsers*100)}%) returning (>1 open)</Grid>
                                <Grid item xs={3}><strong>{dashboardStats[key].tryingUsers}</strong> ({parseInt(dashboardStats[key].tryingUsers/dashboardStats[key].totalUsers*100)}%) (finished 1 chat)</Grid>
                                
                                <Grid item xs={3}><strong>{dashboardStats[key].startedChat}</strong> ({parseInt(dashboardStats[key].startedChat/dashboardStats[key].totalUsers*100)}%) Started a Chat</Grid>
                                <Grid item xs={3}><strong>{dashboardStats[key].finishedOnboarding}</strong> ({parseInt(dashboardStats[key].finishedOnboarding/dashboardStats[key].totalUsers*100)}%) Finished onboarding</Grid>
                                <Grid item xs={3}><strong>{dashboardStats[key].deactivatedUsers}</strong> ({parseInt(dashboardStats[key].deactivatedUsers/dashboardStats[key].totalUsers*100)}%) deactivated users</Grid>
                                <Grid item xs={3}><strong>{dashboardStats[key].profiledUsers}</strong> ({parseInt(dashboardStats[key].profiledUsers/dashboardStats[key].totalUsers*100)}%) profiledUsers users</Grid>
                                <Grid item xs={3}><strong>{dashboardStats[key].totalUsers}</strong> total signups </Grid>
                            </Grid>
                        ))}
                    </DialogContent>
                </Dialog>
            </RemoveScroll>
        )
    }

}


class UserItemBaseDialog extends Component {
    static contextType = AuthUserContext;
    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            openNewDialog: false,
            roles: '',
            ctaText: '',
            phase: '',
            user : props.user,
            leadershipCategories: [],
            leadershipQualities: [],
            currentCategory: '',
            currentLeadershipQuality: '',
            openCurrentCategory: false,
            openCurrentLeadershipQuality: false,
            confirmationOpen: false,
            dialogues: []
        };
    }

    componentDidMount() {
        this._isMounted = true;
        this.getleadershipCategoriesArray();
        this.getleadershipQualitiesArray();
    }

    getleadershipCategoriesArray()
    {
        var myArray = [];
        var myArrayText = [];
        this.props.firebase
        .leadershipCategories()
        .orderBy('text', 'asc')
        .where('active', '==', true)
        .get()
        .then(snapshot => {
            if (snapshot.empty) {
                return;
            }
            snapshot.forEach(doc => {
                if(this.context.programs.includes(doc.data().program)){
                    myArray.push(doc.data().value);
                    myArrayText.push(doc.data().text);
                }
            });
        });
        this.setState({
            leadershipCategories: myArray,
            leadershipCategoriesText: myArrayText
        });
    }
    getleadershipQualitiesArray()
    {
        var myArray = [];
        var myArrayText = [];
        this.props.firebase
        .leadershipQualities()
        .where('active', '==', true)
        .orderBy('text', 'asc')
        .get()
        .then(snapshot => {
            if (snapshot.empty) {
            return;
            }
            snapshot.forEach(doc => {
            if(this.context.programs.includes(doc.data().program)){
                myArray.push(doc.data().value);
                myArrayText.push(doc.data().text + " (" + doc.data().category + ")");
            };
            });
        });
        this.setState({
        leadershipQualities: myArray,
        leadershipQualitiesText: myArrayText
        });
         
    }


    componentDidUpdate(){
        //console.log('init', this.props.uid)
        if (this.props.uid && this.state.uid !== this.props.uid) {
            //console.log('issnit', this.props.uid)
            this.setState({
                loading: true,
                openNewDialog: false,
                uid: this.props.uid,
            });
            this.unsubscribe = this.props.firebase
            .user(this.props.uid)
            .onSnapshot(snapshot => {
                if(this._isMounted){
                    this.setState({
                        user: snapshot.data(),
                        phase: snapshot.data().phase ?snapshot.data().phase : ''
                    });
                }
            });
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
        this.unsubscribe && this.unsubscribe();
    }

    onSendPasswordResetEmail = () => {
        this.props.firebase.doPasswordReset(this.state.user.email);
    };

    handleOpen = () => {
        this.setState({
        openNewDialog: true,
        });
    }

    handleClose = () => {
        this.setState({
            openNewDialog: false,
            open:false,
            openDialogueChats: false,
            openNotificationSettings: false,
        });
    }

    handleOpenDialogueChats = (user, uid) => {
        this.setState({selectedId : uid, selectedUser: user, openDialogueChats: true, open:true} )
    }
    handleOpenNotificationSettings = (user, uid) => {
        this.setState({selectedId : uid, selectedUser: user,openNotificationSettings: true, open:true} )
    }

    handleConfirmationOpen = () => {
        this.setState({ confirmationOpen: true });
    }

    handleConfirmationClose = () => {
        this.setState({ confirmationOpen: false });
    }

    handleUserDelete = () => {
        DeleteUser(this.props.firebase, this.props.uid);
        
        this.handleConfirmationClose();
        //this.props.history.push(ROUTES.ADMIN_USERS);
    }

    convertTimestampToDate = ts => {
        let date = new Date(ts * 1000);
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();
        let hour = date.getHours();
        let minute = date.getMinutes();

        month = (month < 10 ? "0" : "") + month;
        day = (day < 10 ? "0" : "") + day;
        hour = (hour < 10 ? "0" : "") + hour;
        minute = (minute < 10 ? "0" : "") + minute;

        return year + "-" + month + "-" + day + " [" + hour + ":" + minute + "]";
    }

    render() {
        const {
            user,
            dialogues,
            loading,
        } = this.state;

        return (
            <RemoveScroll enabled={this.state.openStats || false}>
            <Dialog
                open={this.props.openDialog}
                onClose={this.props.closeDialog}
                >
                <DialogTitle >
                    <IconButton onClick={this.props.closeDialog}>
                        (X)
                    </IconButton>
                    Modify Content
                </DialogTitle>
                {this.props.openDialog &&
                    <DialogContent >
                        <div>
                        <h5 style={styles.subtitle}>Content ({this.props.uid})</h5>
                        {loading && 
                            <LoadingProgress 
                                defaultSet={this.props.defaultSet}
                                type={'LoadingPageAnimated'} 
                                loadingText={"many, many, data"} 
                                firebaseConnectionSpeed={this.state.firebaseConnectionSpeed} 
                                allowRefresh={true}
                            />
                        }
                            {user && (
                            <div>
                            <Accordion key="user_details"  id={`panel-key`}   TransitionProps={{ mountOnEnter: true }}
                                onChange={(e,expanded) => {this.setState({expandedUserDetails: expanded})}}>
                                <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1c-content"
                                id="panel1c-header"
                                >
                                <div style={styles.column}>
                                    User {this.props.uid}
                                </div>
                                <div style={styles.column}>
                                    See info and available actions
                                </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {this.state.expandedUserDetails &&
                                        <div style={styles.column}>
                                            <h5>General Information</h5>
                                            <p>Number of dialogues: {dialogues!==null? dialogues.length : 0}</p>
                                            <p>User ID: <strong>{this.props.uid}</strong></p>
                                            <p>Timezone: {user.timezone}</p>
                                            <SettingContentBase 
                                                dbUpdateLink={this.props.firebase.user(this.props.uid)}
                                                title={"Username, email"} 
                                                content={user} 
                                                params={['username', 'email']}
                                                /> 
                                            <p>Code {user.code} </p>
                                            <p>Onboarding Feedback: {user.onboardingResponses?.join(", ")} </p>
                                            <p>Last Reminder Sent: <strong>
                                                {user.lastEmailNotificationSentAt !== undefined
                                                    ? this.convertTimestampToDate(user.lastEmailNotificationSentAt.seconds)
                                                    : '-'
                                                }
                                                </strong></p>
                                            <SettingContentActive 
                                                dbUpdateLink={this.props.firebase.user(this.props.uid)}
                                                title={"Notification set initiated? Reset with a False"} 
                                                content={user} 
                                                params={['mobileNotificationsInitiated', 'emailNotificationsInitiated']}
                                                />
                                            <SettingContentBase 
                                                dbUpdateLink={this.props.firebase.user(this.props.uid)}
                                                title={"Goals, CTA"} 
                                                content={user} 
                                                params={['ctaText', 'achievementGoal']}
                                                /> 
                                            <SettingContentSelect
                                                dbUpdateLink={this.props.firebase.user(this.props.uid)}
                                                title={"Email & Mobile (no never, inactive) notification frequency:"} 
                                                content={user} 
                                                updateExtraChange={{'lastEmailSettingAt' : this.props.firebase.fieldValue.serverTimestamp(),
                                                     'emailNotificationsInitiated': false, 
                                                     'mobileNotificationsInitiated': false}}
                                                params={['emailNotifications', 'mobileNotifications']}
                                                selectOptions={['never', 'daily', 'casually', 'weekly', 'inactive']}
                                                />
                                            <SettingContentArraySelect
                                                dbUpdateLink={this.props.firebase.user(this.props.uid)}
                                                title={"Programs:"} 
                                                content={user} 
                                                params={['programs']}
                                                selectOptions={this.context.programs}
                                                />
                                            <SettingContentSelect 
                                                dbUpdateLink={this.props.firebase.content(this.props.uid)}
                                                title={"Current Program:"} 
                                                content={user} 
                                                params={['currentCategory']}
                                                selectOptions={this.state.leadershipCategories}
                                                selectOptionsText={this.state.leadershipCategoriesText}
                                                />
                                            <SettingContentSelect 
                                                dbUpdateLink={this.props.firebase.user(this.props.uid)}
                                                title={"Current Skill/Quality/Dev Are:"} 
                                                content={user} 
                                                params={['currentLeadershipQuality']}
                                                selectOptions={this.state.leadershipQualities}
                                                selectOptionsText={this.state.leadershipQualitiesText}
                                                />
                                            <SettingContentSelect 
                                                dbUpdateLink={this.props.firebase.user(this.props.uid)}
                                                title={"Current Skill/Quality/Dev Are:"} 
                                                content={user} 
                                                params={['todaysLeadershipQuality']}
                                                selectOptions={this.state.leadershipQualities}
                                                selectOptionsText={this.state.leadershipQualitiesText}
                                                />
                                            <SettingContentNumber
                                                dbUpdateLink={this.props.firebase.user(this.props.uid)}
                                                title={"Free Chats"} 
                                                content={user} 
                                                min={0}
                                                max={user.freeDialogues ? user.freeDialogues+15 : 15}
                                                params={['freeDialogues']}
                                                />
                                            <SettingContentBase
                                                dbUpdateLink={this.props.firebase.user(this.props.uid)}
                                                title={"User Batch"} 
                                                content={user} 
                                                params={['userBatch']}
                                                />
                                            <SettingContentArraySelect
                                                dbUpdateLink={this.props.firebase.user(this.props.uid)}
                                                title={"Roles:"} 
                                                content={user} 
                                                params={['roles']}
                                                selectOptions={['COACHEE', 'REMOTECOACH']}
                                                />
                                            <SettingContentArray
                                                dbUpdateLink={this.props.firebase.user(this.props.uid)}
                                                title={"Coaching groups"} 
                                                content={user} 
                                                params={['groups']}
                                                />  
                                            <SettingContentArray
                                                dbUpdateLink={this.props.firebase.user(this.props.uid)}
                                                title={"Content Bookmarked"} 
                                                content={user} 
                                                params={['contentBookmarked']}
                                                />     
                                            <p>Current Dialogue: {user.currentDialogue} </p>
                                            <SettingContentActive 
                                                dbUpdateLink={this.props.firebase.user(this.props.uid)}
                                                title={"Onboarding Done, Demo Mode"} 
                                                content={user} 
                                                params={['onboardingDone', 'demoMode']}
                                            />
                                        </div>
                                    }
                                </AccordionDetails>
                            </Accordion>


                            <Accordion key="actions_details"  id={`panel-actions`}   TransitionProps={{ mountOnEnter: true }}
                                onChange={(e,expanded) => {this.setState({expandedUserActions: expanded})}}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls={`panelactiomn-content`}
                                        id={`panel-actions-header`}
                                    >
                                        Alerts and Chats
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {this.state.expandedUserActions &&
                                            <div>
                                                {
                                                    this.dialogueNotificationSettings()
                                                }
                                                {
                                                    this.dialogueChats()
                                                }
                                                <Button type="button"
                                                    onClick={ () => this.handleOpenNotificationSettings(user, this.props.uid) }
                                                    >
                                                    Prompts
                                                </Button>
                                                <Button type="button"
                                                    onClick={ () => this.handleOpenDialogueChats(user, this.props.uid) }
                                                    >
                                                    Chats
                                                </Button>
                                            </div>
                                        }
                                    </AccordionDetails>
                            </Accordion>
                            <Accordion key="user_detailsadm"  id={`panel-keyadm`}
                                onChange={(e,expanded) => {this.setState({expandedUserAdm: expanded})}}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1c-content"
                                    id="panel1c-header"
                                    >
                                    <div style={styles.column}>
                                        User {this.props.uid}
                                    </div>
                                    <div style={styles.column}>
                                        Admins
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {this.state.expandedUserAdm &&
                                        <div style={styles.column}>
                                            <h5>Admin Actions</h5>
                                            <Button
                                                variant="outlined"
                                                type="button"
                                                onClick={this.onSendPasswordResetEmail}
                                                >
                                                Send Password Reset
                                            </Button>
                                            <Button
                                                variant="outlined"
                                                type="button"
                                                onClick={this.handleConfirmationOpen}
                                                >
                                                Delete User
                                            </Button>
                                            <Dialog
                                                open={this.state.confirmationOpen}
                                                onClose={this.handleConfirmationClose}
                                                aria-labelledby="alert-dialog-title"
                                                aria-describedby="alert-dialog-description"
                                                >
                                                <DialogTitle id="alert-dialog-title">{"Really delete the user?"}</DialogTitle>
                                                <DialogContent>
                                                    <DialogContentText id="alert-dialog-description">
                                                        This deletes all user data and its related data like dialogues and messages. The user account in the Firebase Authentication might have to be deleted seperately.
                                                    </DialogContentText>
                                                </DialogContent>
                                                <DialogActions>
                                                    <Button onClick={this.handleConfirmationClose} >
                                                    Disagree
                                                        </Button>
                                                        <Button onClick={this.handleUserDelete}  autoFocus>
                                                    Agree
                                                    </Button>
                                                </DialogActions>
                                            </Dialog>
                                        </div>
                                    }
                                </AccordionDetails>
                            </Accordion>
                            
                            </div>
                    )}
                        </div>
                    </DialogContent>
                }
            </Dialog> 
            </RemoveScroll>
        );
    }

    dialogueChats = () => {
        const {selectedId, selectedUser} = this.state;
        return(
            <RemoveScroll enabled={this.state.openDialogueChats || false}>
                <Dialog
                    key={this.state.actionId}
                    open={this.state.openDialogueChats || false}
                    onClose={this.handleClose}
                    maxWidth="sm"
                >
                    <DialogTitle  >
                        <IconButton onClick={this.handleClose}>
                            <ArrowBackIcon />
                        </IconButton>
                        <span >
                            AI Chats
                        </span>
                    </DialogTitle>
                    {this.state.openDialogueChats &&
                        <DialogContent >
                            { selectedId !== undefined && selectedId !== ""
                                ? <DayList userId={selectedId} user={selectedUser}/>
                                : <div>Loading</div> 
                            }
                        </DialogContent>
                    }
                </Dialog>
            </RemoveScroll>
        )
    }
    dialogueNotificationSettings = () => {
        const {selectedId, selectedUser} = this.state;
        //console.log(this.state)
        return(
            <RemoveScroll enabled={this.state.openNotificationSettings|| false}>
                <Dialog
                    key={this.state.actionId}
                    open={this.state.openNotificationSettings|| false}
                    onClose={this.handleClose}
                    maxWidth="sm"
                >
                    <DialogTitle >
                        <IconButton onClick={this.handleClose}>
                            <ArrowBackIcon />
                        </IconButton>
                        <span>
                            Vision and Topics
                        </span>
                    </DialogTitle>
                    {this.state.openNotificationSettings &&
                        <DialogContent >
                            { selectedId !== undefined && selectedId !== ""
                                ? <CoachUserActions userId={selectedId} user={selectedUser}
                                    leadershipCategories={this.props.leadershipCategories}
                                    leadershipQualities={this.props.leadershipQualities}
                                    />
                                : <div>Loading</div> 
                            }
                        </DialogContent>
                    }
                </Dialog>
            </RemoveScroll>
        )
    }
}

AdminUsersPage.propTypes = {
  classes: PropTypes.object.isRequired
};

const UserList = withFirebase(UserListBase);
const UserItemDialog = withFirebase(UserItemBaseDialog);

const condition = authUser =>
      authUser && authUser.roles?.includes(ROLES.ADMIN);

export default compose(
    withStyles(styles),
    // withEmailVerification,
    withAuthorization(condition),
)(AdminUsersPage);

