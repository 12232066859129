import {DataCacheRead, DataCacheWrite} from "../Modules/DataCache";

export async function SetStateLoadingBatches(that, db, userId, badgeFamily, badgeLimit, overwrite = false){
    if(that._isMounted !== false){
        if(badgeFamily==='' || badgeFamily===undefined) badgeFamily = 'all';
        let badgesData = {};
        var cacheStore = DataCacheRead('badgesComplete_'+badgeFamily+'_'+userId)
        //console.log('badgeFamily',badgeFamily, cacheStore, db, userId);
        if(cacheStore.cacheAvailable && cacheStore.cacheData !== undefined
            && cacheStore.cacheData.maxPoints !== undefined
            ) {
            badgesData = cacheStore.cacheData;
            if(that._isMounted !== false){ 
                that.setState(badgesData);
            }
            let badgesDataIn = {};
            var cacheStoreIn = DataCacheRead('badgesInComplete_'+badgeFamily+'_'+userId)
            if(cacheStoreIn.cacheAvailable && cacheStoreIn.cacheData !== undefined ) {
                badgesDataIn = cacheStoreIn.cacheData;
                if(that._isMounted !== false){ 
                    that.setState(badgesDataIn);
                }
            }
            ////// the return will end the job and stop the next load of data
            //return(true)
        }
        if(db!==undefined && userId!==undefined
            && (overwrite || cacheStore.cacheAvailable===false || cacheStore.isCacheExpired)) { 
            //console.log('got in')
            var maxPoints = 100;
            var foundLatestBatch = false;
            var foundBatchTotal = false;
            var badgesListComplete = [];
            var badgesListInComplete = [];
            var badgesFamilyComplete = {};
            var badgesFamilyInComplete = {};
            var counterComplete = 0;
            var badgeNameLatest = '';
            var badgeIconLatest = '';
            var badgeLatest = {};
            var badgeNameTotal = '';
            var badgeIconTotal = '';
            var badgeTotal = {};
            var dbConnection = db.journeyBadges(userId)
                .where('active', '==', true)
                .where('completed', '==', true)
            if(badgeFamily !== undefined && badgeFamily!== '' && badgeFamily !== "all"){
                //console.log('badgefamilt')
                dbConnection = dbConnection.where('family', '==', badgeFamily)
                //dbConnection = dbConnection.where('family', 'in', [badgeFamily, 'total'])
            }
            dbConnection = dbConnection.orderBy('createdAt', 'desc')
            if(badgeFamily !== undefined && badgeFamily!== '' && badgeFamily !== "all"){
                //console.log('badgefamilt')
                dbConnection =dbConnection.limit(15)
            }
            else{
                dbConnection =dbConnection.limit(30)
            }
            if(that._isMounted !== false){
                dbConnection
                .get()
                .then((snapshot) => {
                    if (snapshot.size) {
                        //console.log('dfdf', snapshot.size)
                        var batchFamiliesAdded = [];
                        snapshot.forEach(doc => {
                            //console.log(doc.data())
                            if (!foundLatestBatch){
                                badgeNameLatest = doc.data().description;
                                badgeIconLatest = doc.data().iconUrl;
                                badgeLatest = doc.data();
                                if(badgeIconLatest !== undefined && badgeIconLatest !== ''){
                                    if(that._isMounted !== false){ 
                                        that.setState({badgeName: badgeNameLatest, badgeIcon:badgeIconLatest, badge:badgeLatest})
                                    }
                                    if(doc.data().category !== 'total') {foundLatestBatch = true}
                                }
                            }
                            if (!foundBatchTotal){
                                badgeNameTotal = doc.data().description;
                                badgeIconTotal = doc.data().iconUrl;
                                badgeTotal = doc.data();
                                if(badgeIconTotal !== undefined && badgeIconTotal !== '' && doc.data().category === 'total'){
                                    if(that._isMounted !== false){ 
                                        that.setState({badgeNameTotal, badgeIconTotal, badgeTotal})
                                    }
                                    foundBatchTotal = true
                                }
                            }
                            if (counterComplete< badgeLimit || !foundBatchTotal){
                                //var badgeName = doc.data().description;
                                var badgeIcon = doc.data().iconUrl;
                                var selectedFamily = doc.data().family;
                                //var badgeCompleted = doc.data().completed;
                                if(badgeIcon !== undefined && badgeIcon !== ''){
                                    //if(badgeFamily !== undefined && badgeFamily !== '' && badgeFamily !== "total"){
                                    if(badgeFamily !== undefined && badgeFamily !== ''){
                                        if(!batchFamiliesAdded.includes(selectedFamily)){
                                            batchFamiliesAdded.push(selectedFamily);
                                            //console.log('unshift 1', selectedFamily)
                                            badgesListComplete.unshift({...doc.data(), id: doc.id});
                                            badgesFamilyComplete[doc.data().family] = {...doc.data(), id: doc.id};
                                            counterComplete += 1;
                                        }
                                        else if(badgeFamily !== 'all'){
                                            //console.log('push 1', selectedFamily)
                                            badgesListComplete.push({ ...doc.data(), id: doc.id});
                                            badgesFamilyComplete[doc.data().family] = {...doc.data(), id: doc.id};
                                            counterComplete += 1;
                                        }
                                    }
                                    else if(selectedFamily !== undefined && (!(badgesFamilyComplete.includes(selectedFamily)))){
                                        if(!batchFamiliesAdded.includes(selectedFamily)){
                                            batchFamiliesAdded.push(selectedFamily);
                                            //console.log('unshift 2', selectedFamily)
                                            badgesListComplete.unshift({...doc.data(), id: doc.id});
                                            badgesFamilyComplete[doc.data().family] = {...doc.data(), id: doc.id};
                                        }
                                        else {
                                            //console.log('push 2', selectedFamily)
                                            badgesListComplete.push({ ...doc.data(), id: doc.id})
                                            badgesFamilyComplete[doc.data().family] = {...doc.data(), id: doc.id};
                                        }
                                        //badgesFamilyComplete.push(selectedFamily)
                                        //console.log(selectedFamily, badgesFamilyComplete)
                                        counterComplete += 1;
                                    }
                                    else if (doc.data().family === undefined ){
                                        //console.log('push 3', selectedFamily)
                                        badgesListComplete.push({...doc.data(), id: doc.id})
                                        badgesFamilyComplete[doc.data().family] = {...doc.data(), id: doc.id};
                                        counterComplete += 1;
                                    }
                                    if(doc.data().family !== 'total' && doc.data().target > maxPoints ){
                                        maxPoints = doc.data().target ;
                                    }
                                }
                            }
                        })
                        //console.log(badgesListComplete, badgesFamilyComplete)
                        if(that._isMounted !== false){ 
                            that.setState({
                                badgesListComplete,
                                badgesFamilyComplete,
                                maxPoints
                            })
                        }
                        if(foundBatchTotal && badgeNameLatest!== '' && badgeIconLatest!== '' ){
                            //console.log('write all sets')
                            DataCacheWrite('badgesComplete_'+badgeFamily+'_'+userId, {
                                maxPoints, 
                                badgesListComplete, 
                                badgesFamilyComplete,
                                badgeName: badgeNameLatest,
                                badgeIcon: badgeIconLatest,
                                badge: badgeLatest,
                                badgeNameTotal,
                                badgeIconTotal,
                                badgeTotal
                            }, 10)
                        }
                        else if(badgeNameLatest!== '' && badgeIconLatest!== '' ){
                            //console.log('write all sets')
                            DataCacheWrite('badgesComplete_'+badgeFamily+'_'+userId, {
                                maxPoints, 
                                badgesListComplete, 
                                badgesFamilyComplete,
                                badgeName: badgeNameLatest,
                                badgeIcon: badgeIconLatest,
                                badge: badgeLatest
                            }, 10)
                        }
                        else{
                            //console.log('no badge name')
                            DataCacheWrite('badgesComplete_'+badgeFamily+'_'+userId, {
                                maxPoints, 
                                badgesListComplete, 
                                badgesFamilyComplete,
                            }, 10)
                        }
                    }
                })
                .catch((error) => {
                    console.log("Error getting document:", error);
                    if(that._isMounted !== false){ 
                        that.setState({failedLoadingFirestore:true});
                    }
                }); 
                var counterIncomplete = 0
                var dbConnectionIncomplete = db.journeyBadges(userId)
                .where('active', '==', true)
                .where('completed', '==', false)
                if(badgeFamily !== undefined && badgeFamily!== '' && badgeFamily !== "all"){
                    //console.log('badgefamilt')
                    dbConnectionIncomplete = dbConnectionIncomplete.where('family', 'in', [badgeFamily, 'total'])
                }
            }
            if(that._isMounted !== false){
                dbConnectionIncomplete
                .orderBy('createdAt', 'desc')
                .limit(10)
                .get()
                .then((snapshot) => {
                    if (snapshot.size) {
                        snapshot.forEach(doc => {
                            //console.log(doc.data())
                            if ( counterIncomplete< badgeLimit){
                                //var badgeName = doc.data().description;
                                var badgeIcon = doc.data().iconUrl;
                                //var badgeCompleted = doc.data().completed;
                                if(badgeIcon !== undefined && badgeIcon !== ''){
                                    if( doc.data().family !== undefined && ((badgesFamilyInComplete?.[doc.data().family] === undefined))){
                                        badgesListInComplete.push({ ...doc.data(), id: doc.id})
                                        badgesFamilyInComplete[doc.data().family] = doc.data()
                                        counterIncomplete += 1;
                                    }
                                    else if (doc.data().family === undefined ){
                                        badgesListInComplete.push({...doc.data(), id: doc.id})
                                        counterIncomplete += 1;
                                    }
                                }
                            }
                        })
                        if(that._isMounted !== false){ 
                            that.setState({
                                badgesListInComplete,
                                badgesFamilyInComplete,
                            })
                        }
                        DataCacheWrite('badgesInComplete_'+badgeFamily+'_'+userId, {
                            badgesListInComplete, 
                            badgesFamilyInComplete
                        }, 30)
                    }
                })
                .catch((error) => {
                    console.log("Error getting document:", error);
                    if(that._isMounted !== false){ 
                        that.setState({failedLoadingFirestore:true});
                    }
                }); 
            }
            return(true)
        } else{
            return(false)
        }
}
} 
