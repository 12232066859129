import React, { Component } from 'react';
import { compose } from 'recompose';
import { AuthUserContext } from '../Session';
import withStyles from '@material-ui/core/styles/withStyles';
import { withFirebase } from '../Firebase';
import { AddAnalytics } from '../Modules/AddAnalytics';
import * as ROUTES from '../../constants/routes';
import { Link } from "react-router-dom";

import CardTotalStatsTopics from '../Charts/CardTotalStatsTopics';

//import TodayAdd from '../Home/TodayAdd';
import CardRoutineStats from './CardRoutineStats';
//import OverviewEngagement from './OverviewEngagement';
import CardBadges from './CardBadges';
import CardBigFive from '../Assessments/CardBigFive';
import CardAssessmentShop from '../Assessments/CardAssessmentShop';
import TodayProgram from '../Home/TodayProgram';
import CardProgramProgress from '../Charts/CardProgramProgress';
//import TodayPath from '../Home/TodayPath';
import ContentQualitySwipe from '../Content/ContentQualitySwipe';
import {LoadStateEngagementStats} from '../Modules/LoadStateEngagementStats';
import {LoadUserCategoryAndQualities} from "../Modules/LoadUserData";
import CardAssessmentFactors from './CardAssessmentFactors';

import HomeIcon from '@material-ui/icons/Home';
import TopicSuggestions from '../Goals/TopicSuggestions';
//import ProgressDoughnut from '../Charts/ProgressDoughnut';
import LoadingProgress from '../Modules/LoadingProgress';

import badgeStart from "../../images/Badge_XP_congrats.png";
import DayList from '../Days/DayList';
//import { DataCacheRead, DataCacheWrite} from "../Modules/DataCache";

///// Manage the navigation bar appear
import VizSensor from 'react-visibility-sensor';
import pixel from "../../images/pixel.png";

///// Tutoiral Button
//import {AvatarCard} from '../Modules/AvatarImage';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import DialogTutorialPage from '../Onboarding/DialogTutorialPage';

import DialogCardBadges from '../Charts/DialogCardBadges';
import Grid from '@material-ui/core/Grid';
import DonutSmallIcon from '@material-ui/icons/DonutSmall';

import Card from '@material-ui/core/Card';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

//import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
//import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

import {IsMobileDevice} from '../Modules/IsMobileDevice';


const styles = theme => ({
    grow: {
        flexGrow: 1,
    },
    highlightTab: {
        position: theme.highlightTab.position,
        left:  IsMobileDevice()? theme.HomeNoNavigationShiftContent.paddingLeft : theme.HomeNavigationShiftContent.paddingLeft ,
        top: IsMobileDevice()? theme.highlightTab.top : 60,
        backgroundColor:theme.highlightTab.backgroundColor, 
        zIndex:theme.highlightTab.zIndex,
    },
    unHighlightTab: {
        position: "flexible",
    },
});



class HomeStats extends Component {
    static contextType = AuthUserContext;
    _isMounted = false;
    constructor(props) {
        super(props);
        this.dialogRef = React.createRef();
        var startTab = "Insights";
        if (this.props.location && this.props.location.state && this.props.location.state.startTab){
            startTab= this.props.location.state.startTab;
        }
        this.state = {
            loading: true,
            value: startTab,
            days: [],
            headerVisible: true,
            engagementBenchmarkCategory: 1200
        };
    }

    handleChange = (event, newValue) => {
        if(newValue === "Home"){
            this.props?.history?.push(ROUTES.HOME)
        }
        else{
            try{
                if(this.dialogRef !== undefined ) this.dialogRef.current.scrollIntoView({behaviour: 'smooth'});
            }
            catch (err) {
                console.log('No scroll to top');
            }
            this.setState({
                value: newValue,
                [newValue] : true,
            });
        }
    }

    componentDidMount() {
        this._isMounted = true;
        LoadStateEngagementStats(this, this.props.user.uid);
        //console.log("home stats props", this.props)
        this.setState({ loading: false },
            (() => {
                try{
                    if(this.dialogRef !== undefined ) {
                        this.dialogRef.current.scrollIntoView({behaviour: 'smooth'});
                        //console.log('locaded into view on start')
                    }
                }
                catch (err) {
                    console.log('No scroll to top');
                }
            })
        );
        this.loadCategoriesAndQualities();
        AddAnalytics('screen_view', 'PageStats');
    }

    componentDidUpdate(prevProps)  {
        if(this._isMounted && prevProps.user !== undefined && this.props.user !== undefined){
            if(this.props.user.currentLeadershipQuality !== prevProps.user.currentLeadershipQuality){
                //console.log('updated mina stats', this.props);
                this.loadCategoriesAndQualities();
            }
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
        this.setState({ loading: false });
        //window.removeEventListener('scroll', this.handleScroll);
    }

    loadCategoriesAndQualities() {
        //console.log('yyy updating loadCategoriesAndQualities')
        var userGrowthPath = LoadUserCategoryAndQualities(this, this.context.uid, this.context, this.props.leadershipCategories, this.props.leadershipQualities)
        if(this._isMounted && userGrowthPath !== undefined ){
            this.setState({
                mainQuality: userGrowthPath.mainQuality, 
            }, () => {
                this.setState({
                    ...userGrowthPath.oldCommentList,
                    mainCategory: userGrowthPath.mainCategory, 
                    mainQuality: userGrowthPath.mainQuality, 
                    programQualities: userGrowthPath.programQualities, 
                    mainQualitiesDicts: userGrowthPath.mainQualitiesDicts , 
                    sisterQualities: userGrowthPath.sisterQualities
                })
                //console.log('XXX userGrowthPath', this.state.mainQualitiesDicts)
            });
        }
    }

    render() {
        const { theme } = this.props;
        const { loading, days,  value } = this.state;
        const { leadershipQualities, user, leadershipCategories, screen} =  this.props;
        if(user !== null){
            return(
                <div id='pageroot'>
                    <Grid key="splitsidenavi" container direction="row" ref={this.dialogRef}>
                        <Grid item xs={screen==="desktop" ? 2 : false} >
                            {false && screen==="desktop"
                                &&
                                <Card style={theme.CardDesktopLeft}>
                                    <Card style={theme.CardNaviLeft}>
                                        <h4 style={theme.CardContent.titleText}>Insights</h4>
                                        {this.renderTabs("vertical")}
                                    </Card>
                                </Card>
                            }
                        </Grid>
                        <Grid item xs={screen==="desktop" ? 8 : 12}>
                            {loading
                                ?
                                    <Card style={theme.CardContentItem.rootFeed}>
                                        <LoadingProgress 
                                            defaultSet={this.props.defaultSet}
                                            type={'LoadingPageAnimated'} loadingText={"loading stats"} /> 
                                    </Card>
                                :
                                    <Card style={screen==="desktop"? theme.CardDesktopMain : theme.CardAppMain}>
                                        <VizSensor 
                                            partialVisibility={"top"}
                                            onChange={(isVisible) => { 
                                                if(screen!=="desktop" && isVisible && this._isMounted) { 
                                                    this.setState({headerVisible: true});
                                                    //console.log('visible top cart')
                                                }
                                                else if (screen!=="desktop" && this._isMounted){
                                                    this.setState({headerVisible: false});
                                                    //console.log('hding top cart')
                                                }
                                            }}>
                                            <div style={{height: 1}}>  <img src={ pixel} alt=''/></div>
                                        </VizSensor>
                                        <Card style={{
                                                ...(this.props.screen === 'desktop' ? theme.CardDesktopTop : theme.CardTop), 
                                                position: this.state.headerVisible? 'relative' : 'fixed',
                                                zIndex: this.state.headerVisible? 0 : 100,
                                                top: 0,
                                                opacity: this.state.headerVisible? '1.0' : '0.94',
                                            }}>
                                            {this.state.headerVisible
                                                ?   <h2 style={theme.CardContent.titleText}><DonutSmallIcon style={theme.inlineIconH2}/>&nbsp;Insights</h2> 
                                                :   <div style={theme.textMonthCenter}><strong>{value}</strong></div>
                                            }
                                            {(!this.state.headerVisible)
                                                &&
                                                    <div style={{position: 'fixed', top: 3, right: 8}}>
                                                        <IconButton onClick={() => {this.setState({handleOpenTutorial: true})}}>
                                                            <HelpOutlineIcon />
                                                        </IconButton>
                                                    </div>
                                            }
                                            {
                                             this.renderTabs("horizontal")  
                                            }
                                        </Card>
                                        <Card style={{
                                                ...theme.CardBack, 
                                                marginTop: this.state.headerVisible? 0 : 200,
                                                paddingBottom: '30vh',
                                            }} >

                                            <div>
                                                {true &&
                                                <div value={"Insights"} index={1} hidden={value !== "Insights"}>
                                                    <Card style={theme.CardContentItem.CardContentSpace}>
                                                        <TodayProgram
                                                            user={user}
                                                            defaultSet={this.props.defaultSet || undefined}
                                                            engagementStats= {this.state.engagementStats || undefined}
                                                            engagementBenchmarkQuality= {400}
                                                            engagementBenchmarkCategory={ this.state.engagementBenchmarkCategory || undefined}
                                                            //handlerContinue={() => {this.loadCategoriesAndQualities(); console.log('updated Insights')}}
                                                            leadershipCategories={leadershipCategories}
                                                            leadershipQualities={leadershipQualities}
                                                            showAsContent={true}
                                                            showWeekly={false}
                                                            rerender={value !== "Insights"? false : true}
                                                        />
                                                    </Card>
                                                    <Card style={theme.CardContentItem.CardContentSpace}>
                                                        <CardProgramProgress 
                                                            engagementStats= {this.state.engagementStats || undefined}
                                                            engagementBenchmarkQuality= {400}
                                                            engagementBenchmarkCategory={ this.state.engagementBenchmarkCategory || undefined}
                                                            leadershipCategories={leadershipCategories}
                                                            leadershipQualities={leadershipQualities}
                                                            handlerNext = {() => this.setState({openAssessments: true})}
                                                            handlerSpecial = {() => this.setState({openOCEANAssessment: true})}
                                                            user={user}
                                                            rerender={value !== "Insights"? false : true}
                                                            tutorialOpen = {(tutorialFamily) => {this.setState({tutorialFamily: tutorialFamily, handleOpenTutorial: true})}}
                                                        />
                                                    </Card>
                                                    <Card style={theme.CardContentItem.CardContentSpace}>
                                                        <ContentQualitySwipe 
                                                            user={user}
                                                            defaultSet={this.props.defaultSet || undefined}
                                                            noMargin={true}
                                                            screen={screen}
                                                            showCompletionLevel={true}
                                                            handlerContinue={() => setTimeout(() => { this.loadCategoriesAndQualities();}, 200)}
                                                            mainQualitiesDicts={this.state.mainQualitiesDicts || undefined}
                                                            engagementStats={this.state.engagementStats || undefined}
                                                            engagementBenchmarkQuality= {400}
                                                            leadershipCategories={leadershipCategories}
                                                            leadershipQualities={leadershipQualities}
                                                            rerender={value !== "Insights"? false : true}
                                                            tutorialOpen = {(tutorialFamily) => {this.setState({tutorialFamily: tutorialFamily, handleOpenTutorial: true})}}
                                                        />
                                                    </Card>
                                                    {user.engagementCounter!== undefined && user.engagementCounter>=5 && user.roles!==undefined && (!user.roles?.includes("COACHEE"))
                                                    &&
                                                        <Card style={theme.CardContentItem.CardContentSpace}>
                                                            <TopicSuggestions
                                                                defaultSet={this.props.defaultSet || undefined}
                                                                handlerContinue={() => {this.loadCategoriesAndQualities()}}
                                                                engagementStats={this.state.engagementStats}
                                                                engagementBenchmarkQuality= {400}
                                                                leadershipCategories={leadershipCategories}
                                                                leadershipQualities={leadershipQualities} 
                                                                user={user}
                                                                rerender={value !== "Insights"? false : true}
                                                            />
                                                        </Card>
                                                    }
                                                </div> 
                                                }
                                                <div value={"Assessments"} index={1} hidden={value !== "Assessments"}>
                                                    {true &&
                                                        <Card style={theme.CardContentItem.CardContentSpace}>
                                                            <CardAssessmentShop 
                                                                defaultSet={this.props.defaultSet || undefined}
                                                                showSummary = {true}
                                                                allowDialog = {true}
                                                                leadershipCategories={leadershipCategories}
                                                                leadershipQualities={leadershipQualities} 
                                                                openDefaultAssessment = {this.state.openAssessments || false}
                                                                openOCEANAssessment = {this.state.openOCEANAssessment || false}
                                                                handlerClose= {() => this.setState({openAssessments : false, openOCEANAssessment: false})}
                                                                user={user}
                                                                screen={screen}
                                                                rerender={value !== "Assessments"? false : true}
                                                                tutorialOpen = {(tutorialFamily) => {this.setState({tutorialFamily: tutorialFamily, handleOpenTutorial: true})}}
                                                            />
                                                            {user?.userAssessmentUpdatedAt !== undefined && (value === 'Assessments' || this.state['Assessments'])
                                                            &&
                                                                <div style={{paddingTop: 40}}>
                                                                    <CardAssessmentFactors 
                                                                        user={user}
                                                                        isCoach={this.props.isCoach || false}
                                                                        noMargin={true}
                                                                        statspage={true}
                                                                        //engagementStats={this.state.engagementStats || undefined}
                                                                        //mainCategory ={this.state.mainCategory} 
                                                                        //mainQuality ={this.state.mainQuality}
                                                                        //mainQualitiesDicts={this.state.mainQualitiesDicts}
                                                                        handlerNext={() => this.setState({openOCEANAssessment: true})}
                                                                        //personalityTraits={user.userAssessment?.['O'] ? user.userAssessment : undefined}
                                                                        //engagementBenchmarkQuality= {400}
                                                                        leadershipCategories={leadershipCategories}
                                                                        leadershipQualities={leadershipQualities} 
                                                                        tutorialOpen = {(tutorialFamily) => {this.setState({tutorialFamily: tutorialFamily, handleOpenTutorial: true})}}
                                                                    />
                                                                </div>
                                                            }
                                                        </Card>
                                                    }
                                                </div>
                                                <div value={"Traits"} index={1} hidden={value !== "Traits"}>
                                                    {(value === 'Traits' || this.state['Traits']) &&
                                                        <CardBigFive 
                                                            showSummary = {true}
                                                            allowDialog = {true}
                                                            rerender={value !== "Traits"? false : true}
                                                            user = {this.props.user}
                                                        />
                                                    }
                                                </div> 
                                                {user.engagementCounter!== undefined && user.engagementCounter>=30
                                                    ?
                                                        <div value={"Stats"} index={1} hidden={value !== "Stats"}>
                                                            {(value === 'Stats' || this.state['Stats']) &&
                                                                <CardRoutineStats
                                                                    rerender={value !== "Stats"? false : true}   
                                                                />
                                                            }
                                                        </div>
                                                    :
                                                        <div value={"Stats"}  index={0} hidden={value !== "Stats"}>
                                                            {this.nextUnlockCard('Unlock Dialogue Stats with 30 Experience Points', 30)}
                                                        </div>
                                                }                    
                                                <div value={"Milestones"} index={0} hidden={value !== "Milestones"}>
                                                    {(value === 'Milestones' || this.state['Milestones']) &&
                                                        <Card style={theme.CardContentItem.CardContentSpace}>
                                                            <CardBadges
                                                                visualLimit={10}
                                                                showUnfinished={false}
                                                                variant={'circle'}
                                                                rerender={value !== "Milestones"? false : true}
                                                                leadershipCategories={leadershipCategories}
                                                                leadershipQualities={leadershipQualities}
                                                                //setParentAllQualitiesLoaded={ v => this.setState({allQualitiesLoaded :[...this.state.allQualitiesLoaded, v] }) }
                                                            />
                                                        </Card>
                                                    }
                                                </div>
                                                {user.engagementCounter!== undefined && user.engagementCounter>=30
                                                    ?
                                                        <div value={"Engagement"} index={2} hidden={value !== "Engagement"}>
                                                            {(value === 'Engagement' || this.state['Engagement']) &&
                                                                <Card style={theme.CardContentItem.CardContentSpace}>
                                                                {/***
                                                                    <CardProgramProgress 
                                                                        engagementStats= {this.state.engagementStats || undefined}
                                                                        engagementBenchmarkQuality= {400}
                                                                        engagementBenchmarkCategory={ this.state.engagementBenchmarkCategory || undefined}
                                                                        leadershipCategories={leadershipCategories}
                                                                        leadershipQualities={leadershipQualities}
                                                                        user={user}
                                                                    />
                                                                    */}
                                                                    <h4>
                                                                        eXperience Points (XP)
                                                                    </h4>
                                                                    <p>
                                                                        Overview of modules with most experience points collected by using your AI Coach.
                                                                    </p>
                                                                    <CardTotalStatsTopics
                                                                        engagementStats= {this.state.engagementStats || undefined}
                                                                        engagementBenchmarkQuality= {400}
                                                                        engagementBenchmarkCategory={ this.state.engagementBenchmarkCategory || undefined}
                                                                        leadershipCategories= {leadershipCategories}
                                                                        leadershipQualities = {leadershipQualities}
                                                                        user={user}
                                                                        noMargin={true}
                                                                        statspage={true}
                                                                        rerender={value !== "Engagement"? false : true}
                                                                    />
                                                                </Card>
                                                            }
                                                        </div>
                                                    :
                                                        <div value={"Engagement"}  index={0} hidden={value !== "Engagement"}>
                                                            <Card style={theme.CardContentItem.CardContentSpace}>
                                                                {this.nextUnlockCard('Unlock Engagement Stats with 225 Experience Points', 225)}
                                                            </Card>
                                                        </div>
                                                }
                                                {days && user.engagementCounter!== undefined && user.engagementCounter>=150
                                                    ?
                                                        <div value={"Mood Levels"} index={3} hidden={value !== "Mood Levels"}>
                                                            <Card style={theme.CardContentItem.CardContentSpace}>
                                                                {(value === 'Mood Levels' || this.state['Mood Levels']) &&
                                                                    <DayList userId={this.context.uid} user={this.context}/>
                                                                }
                                                            </Card>
                                                        </div>
                                                    :
                                                        <div value={"Mood Levels"}  index={0} hidden={value !== "Mood Levels"}>
                                                            <Card style={theme.CardContentItem.CardContentSpace}>
                                                                {this.nextUnlockCard('Unlock Mood Levels with 150 Experience Points', 150)}
                                                            </Card>
                                                        </div>
                                                }
                                            </div>
                                        </Card>
                                        <DialogTutorialPage 
                                                defaultSet={this.props.defaultSet}
                                                handleOpen={this.state.handleOpenTutorial || false}
                                                handleBadgeFamily = {this.state.tutorialFamily?.length>1 ? this.state.tutorialFamily : 'insights'}
                                                handleClose={() => {this.setState({handleOpenTutorial: false, tutorialFamily : 'insights'})}}
                                            />
                                        <DialogCardBadges 
                                                handleOpen={this.state.dialogCardOpen || false}
                                                handleClose={() => {this.setState({dialogCardOpen: false})}}
                                                badgeFamilyDetailSelection= {this.state.handleBadgeFamily}
                                            />
                                    </Card>
                            }
                        </Grid>
                        <Grid item xs={screen==="desktop" ? 2 : false} ></Grid>
                    </Grid>
                </div>
            ); // end return
        }// end if user
        else {
            return(
                <Card style={theme.CardContent}>
                    <p style={theme.CardContent.titleText}>Please wait a moment</p>
                </Card>
            )// end 2nd return
        } // end else
    }// end render

    nextUnlockCard = (title, points) => {
        const {theme} = this.props;
        return(
            <Card style={theme.CardDialogueButton}  onClick={() => {this.handlerOpenBadgeDetails((  'total' ))} }>
                <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                    <Grid item xs={3} >
                    <div style={{
                        height: theme.CardDialogueButton.height, 
                        maxHeight:theme.CardDialogueButton.maxHeight}}>
                        <span>
                            <img src={ badgeStart} 
                            style={{maxWidth:'100%', maxHeight: '100%', height: 'auto', verticalAlign: 'middle'}} 
                            alt={ 'Next Unlock'}  />
                        </span>
                    </div>
                    </Grid>
                    <Grid item xs={9}>
                        <span style={theme.CardContentItem.TextSubTitle}> {title} </span>
                    </Grid>
                </Grid>
            </Card>
        )
    }
    handlerOpenBadgeDetails = (badgeFamily) => {
        if(badgeFamily !== undefined && badgeFamily!== "" && badgeFamily!== "total" && this.props.badgeFamilyDetailSelection === undefined){
            this.setState({ 
                handleBadgeFamily:badgeFamily,
            });
            this.setState({ 
                handleBadgeFamily:badgeFamily,
                dialogCardOpen: true,
            });
        }
        else{
            this.setState({ 
                handleBadgeFamily:badgeFamily,
                handleOpen: true,
            });

        }
    }

    renderTabs(orientation = "horizontal") {
        const { value, days } = this.state;
        const { user, theme, screen } = this.props;
        if(orientation === 'vertical'){
            return(
                <>
                    <Link to={ROUTES.HOME}>
                        <span><HomeIcon style={theme.inlineIconLeft}/>Home</span>
                    </Link>
                    <hr/>
                    <Link onClick={() => (this.handleChange(undefined, "Insights"))}>
                        <span><DonutSmallIcon style={theme.inlineIconLeft}/>Insights</span>
                    </Link>
                    {value !== "Insights" &&
                        <>
                            {true && 
                                <Link onClick={() => (this.handleChange(undefined, value))}>
                                    <li style={{paddingTop: 8, paddingLeft: 10, whiteSpace: 'nowrap'}}> {value} </li>
                                </Link>
                            }
                        </>
                    }
                </>
            )
        }
        else{
            return(
            <>
            {user !== null && days!== undefined && days!== null
            &&
                    <Tabs
                        value={value}
                        onChange={this.handleChange}
                        orientation={orientation}
                        aria-label="Coaching Modules"
                        variant="scrollable"
                        scrollButtons="auto"
                        initialselectedindex={"Milestones"}
                        style={{
                            marginTop: 20,
                            marginBottom: (screen==="desktop" ? 0 : -1),
                            marginLeft: -20,
                            width: 'calc(100% + 40px)'
                        }}
                        ref={el => this.containerLine = el}
                    >
                        {false && this.props.screen==="desktop" && 
                            <Tab value="Home" label={<span><HomeIcon style={theme.inlineIconLeft}/>Home</span>}/>
                        }
                        <Tab value={"Insights"} 
                            label="Insights"/>
                        <Tab value={"Assessments"} 
                            label="Assessments"/>
                        {/*** 
                        <Tab value={"Traits"} 
                            label="Traits"/>
                        */}
                        {
                            //<Tab value={"Stats"}  label="Dialogue Stats"/>
                        }
                        <Tab value={"Milestones"} 
                            label="Milestones"/>
                        {user.engagementCounter!== undefined && user.engagementCounter>=30
                            &&
                            <Tab value={"Engagement"} 
                            label="Engagement Stats"/>
                        }
                        {days && user.dialogueCounter > 3
                            &&
                            <Tab value={"Mood Levels"} 
                            label="Mood Levels"/>
                        }
                    </Tabs>            
                } 
                </>
            )
        }
    }
} // end class

export default compose(
    withFirebase,
    withStyles(styles, { withTheme: true })
)(HomeStats);
  
