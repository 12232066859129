import React, { Component } from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import { AuthUserContext } from '../Session';
import { withRouter } from 'react-router-dom';
import LanguageManager from '../../constants/languages';
import withStyles from '@material-ui/core/styles/withStyles';
import * as ROUTES from '../../constants/routes';
import {CheckXPLevel, CurrentXPLevel} from '../Charts/XPLevels';
import { SetAgentStatus } from '../Routine/SetAgentStatus';
import LeadershipQualityPicker from '../Goals/LeadershipQualityPicker';
import ProgressDoughnut from '../Charts/ProgressDoughnut';
import { FindTextValueInDict, ReturnFilteredDict } from '../Modules/LoadCategoriesAndQualities';
import {SetStateLoadingBatches} from '../Charts/LoadingBatches';
import DialogChangeGoal from '../Goals/DialogChangeGoal';
import ActionFabButton from './ActionFabButton';

import logoWhatsNext from "../../images/Badge_XP_whatsnext.png";
import SnackbarInfo from '../Modules/SnackbarInfo';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
//
import BadgeCard from '../Modules/BadgeCard';

///// Manage the navigation bar appear
import VizSensor from 'react-visibility-sensor';
import pixel from "../../images/pixel.png";
import imageVisionBackground from '../../images/satisfied5.png';
import ExploreRoundedIcon from '@material-ui/icons/ExploreRounded';
import SupervisedUserCircleRoundedIcon from '@material-ui/icons/SupervisedUserCircleRounded';
import RockyIcon from '../Modules/RockyIcon';
import ForumIcon from '@material-ui/icons/Forum';
import StarsRoundedIcon from '@material-ui/icons/StarsRounded';
import AdjustRoundedIcon from '@material-ui/icons/AdjustRounded';
import DonutSmallIcon from '@material-ui/icons/DonutSmall';
//import CheckCircleIcon from '@material-ui/icons/CheckCircle';
//import AssignmentIcon from '@material-ui/icons/Assignment';

import DialogCardBadges from '../Charts/DialogCardBadges';
/// Tutorials
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import DialogTutorialPage from '../Onboarding/DialogTutorialPage';
import IconButton from '@material-ui/core/IconButton';

import logo from "../../images/rocky-logo-square.png";
import imageSettingGoal from "../../images/settinggoal.png";
import badgeStart from "../../images/Badge_XP_congrats.png";
//import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
//import ForumIcon from '@material-ui/icons/Forum';
import DialogProgramItems from '../Charts/DialogProgramItems';
import StatsBar from '../Charts/StatsBar';
import AvatarImage from '../Modules/AvatarImage';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
//import List from '@material-ui/core/List';
//import ListItem from '@material-ui/core/ListItem';


//import ListItemText from '@material-ui/core/ListItemText';


// added plus for choosing daily topic
//import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
//import IconButton from '@material-ui/core/IconButton';

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
});
const stylesTab = {
    tabMain: {
        padding: 0,
        margin: 0,
        width: '100%',
        minWidth: 'auto',
        display: 'contents'
    },
    tabsNavi: {
        backgroundColor: 'transparent', 
        padding: 0,
        margin: 0,
        opacity: 100,
        width: 'auto',
        minWidth: 'auto',
        display: 'contents'
    }
}

class TodayProgram extends Component {
    _isMounted = false;
    static contextType = AuthUserContext;

    constructor(props) {
        super(props);
        this.dialogRef = React.createRef();
        this.state = {
            open: false,
            disabled: false,
            text: "Default Message",
            title: "AI Coach",
            insights: [],
            todaysLeadershipQuality: "",
            scope: 'todays',
            primingStatus: 'START',
            reflectionStatus: 'START',
            xpLevels : {}
        };
    }

    componentDidMount() {
        this._isMounted = true;
        this.setGreetingAndDate();
        this.scrollToTop();
        this.getProgramParam();
        if(this.context?.currentCategory?.length > 0){
            var backgroundImage = FindTextValueInDict(this.props.firebase, this.props.leadershipCategories, this.context.currentCategory, 'url');
            if(backgroundImage?.includes('http')){
                console.log('backgroundImage', backgroundImage)
                this.setState({ backgroundImage });
            }
        }
        if(this.context?.achievementSelf?.icon?.length > 0){
            var backgroundIcon = this.context.achievementSelf.icon;
            this.setState({ backgroundIcon });
        }
        this.getEngagementStats();
        this.getXPLevels();
        this.setState(SetAgentStatus(this.props.user));
    }
    componentWillUnmount(){
        this._isMounted = false;
    }
    shouldComponentUpdate(nextProps, nextState) {  
        if (nextProps.rerender === false) {  
            //console.log('no rerender')
            return false;  
        }  
        //console.log(' rerender')
        return true;  
    } 

    scrollToTop() {
        try{
            if(this.dialogRef !== undefined ) this.dialogRef.current.scrollIntoView({behaviour: 'smooth'});
            //document.body.scrollTop = document.documentElement.scrollTop = 0;
            //console.log('scollring to top body');
        } catch  (err) {
            console.log('No scroll to top');
        }
    }

    componentDidUpdate(prevProps)  {
        if(this._isMounted){
            var newValues = '';
            var oldValues = '';
            if(this.props.user !== undefined && this.props.user.currentLeadershipQuality !== ""){
                newValues = this.props.user.currentLeadershipQuality 
            }
            if(prevProps.user !== undefined && prevProps.user.currentLeadershipQuality  !== ""){
                oldValues = prevProps.user.currentLeadershipQuality 
            }
            if(newValues !== oldValues && newValues!== "") {
                //console.log('update naubqyalu')
                this.getProgramParam();
            }
            if(prevProps.user !== undefined && this.props.user.engagementCounter !== prevProps.user.engagementCounter){
                this.getXPLevels();
                this.getEngagementStats();
            }
        }
        return true
    }

    handleStartRoutine = () => {
        if(this._isMounted){
            this.setState({ disabled: true });
            this.props.history.push(ROUTES.ROUTINE);
        }
    }
    handleOpenContent = () => {
        if(this._isMounted){
            this.setState({ disabled: true });
            this.props.history.push(ROUTES.CONTENT);
        }
    }
    handleOpenDiscuss = () => {
        if(this._isMounted){
            this.setState({ disabled: true });
            this.props.history.push(ROUTES.MESSAGES);
        }
    }
    handleOpenStats = () => {
        if(this._isMounted){
            this.setState({ disabled: true });
            this.props.history.push(ROUTES.STATS);
        }
    }
    handleOpenJournal = () => {
        if(this._isMounted){
            this.setState({ disabled: true });
            this.props.history.push(ROUTES.JOURNAL);
        }
    }

    getEngagementStats(){
        //var [engagementStats, engagementBenchmarkQuality, engagementBenchmarkCategory] = LoadStateEngagementStats(this, this.props.user.uid);
        var {engagementStats, engagementBenchmarkQuality, engagementBenchmarkCategory} = this.props;
        var percentageCategory = 0;
        var percentageQuality = 0;
        //var engagementStats = this.state.engagementStats;
        if(engagementStats!==undefined && engagementStats['leadership_category'] !==undefined && engagementStats['leadership_category'][this.props.user.currentCategory] !==undefined ){
            percentageCategory = Math.round(engagementStats['leadership_category'][this.props.user.currentCategory]["points"] / engagementBenchmarkCategory * 100)
        }
        if(engagementStats!==undefined && engagementStats['leadership_quality'] !==undefined && engagementStats['leadership_quality'][this.props.user.currentLeadershipQuality] !==undefined ){
            percentageQuality = Math.round(engagementStats['leadership_quality'][this.props.user.currentLeadershipQuality]["points"] / engagementBenchmarkQuality * 100)
        }
        //console.log('loaded data', weeklyEngagementTypes, engagementStats, percentageCategory, percentageQuality)
        if(this._isMounted){
            this.setState({percentageCategory, percentageQuality, engagementBenchmarkQuality, engagementStats});       
        } 
    }

    getXPLevels(){
        const xpLevels = {
            modules: CheckXPLevel(this.context, 'modules'),
            followUp: CheckXPLevel(this.context, 'followUp'),
            content: CheckXPLevel(this.context, 'content'),
            journal: CheckXPLevel(this.context, 'journal'),
            social: CheckXPLevel(this.context, 'social'),
            journey: CheckXPLevel(this.context, 'journey'),
        }
        const userLevel = CurrentXPLevel(this.context);
        this.setState({xpLevels: xpLevels, userLevel});
    }

    getLatestBatch(){
        if(this._isMounted){
            SetStateLoadingBatches(this, this.props.firebase, this.context.uid, this.props.user.currentCategory, 10)
        }
    }

    getProgramParam(){
        var logoProgram = logo;
        if (this.context.programLogo !== "" && this.context.programLogo !== undefined) {
            logoProgram = this.context.programLogo;
        }
        var icon = undefined;
        var iconProgram = undefined;
        try{
            icon = FindTextValueInDict(this.props.firebase, this.props.leadershipQualities, this.props.user.currentLeadershipQuality, 'icon')
            iconProgram = FindTextValueInDict(this.props.firebase, this.props.leadershipCategories, this.props.user.currentCategory, 'icon')
            //console.log('new icon', icon)
        } catch (e){
            icon = undefined;
        }
        var bgColor = "white";
        var bgColorProgram = "white";
        try {
            bgColor = FindTextValueInDict(this.props.firebase, this.props.leadershipQualities, this.props.user.currentLeadershipQuality, 'backgroundColor')
            bgColorProgram = FindTextValueInDict(this.props.firebase, this.props.leadershipCategories, this.props.user.currentCategory, 'backgroundColor')
            //console.log('try:', bgColor, bgColorProgram);
        } catch (e) {
            bgColor = this.props.theme.palette.secondary.light;
            //console.log('catch:', bgColor);
        }
        var programQualities = [];
        try {
            programQualities = ReturnFilteredDict(this.props.firebase, this.props.leadershipQualities, this.props.user.currentCategory, 'category');
        } catch  (err) {
            console.log('issue with finding the program qualities');
        }
        if(this._isMounted){
            this.setState({logoProgram, icon: icon, iconProgram , bgColor, bgColorProgram, programQualities});
            //console.log('update icon state', this.state);
            this.getLatestBatch();
        }
    }

    setGreetingAndDate(){
        const today = new Date();
        const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
        const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
        const greetings = ["Hello", "Hi", "Cheers", "Hi", "Hello",
            "Good morning", "Morning,", "Good morning", "Morning,", "Good morning",
            "Hi", "Cheers", "Hi",
            "Good afternoon", "Good afternoon", "Good afternoon",
            "Hello", "Cheers", "Hi",
            "Good evening", "Evening", "Good evening",
            "Hello", "Hi", "Cheers"];
        var hours = today.getHours();
        //if(this.context.timezone !== undefined && this.context.timezone !== ""){
        //    hours = new Date(today.toLocaleString('en-US', { timeZone: this.context.timezone })).getHours();
            //console.log(today, hours)
        //}
        var username = this.context.username?.length > 2 ? this.context.username.split(" ")[0] : "";
        var welcomeGreeting = greetings[hours] + ' {username: ' + username +"}";
        var welcomeDate = weekdays[today.getDay()] + ', ' + months[today.getMonth()] + ' ' + today.getDate();
        if(this._isMounted){
            this.setState({welcomeGreeting, welcomeDate})
        }
    }

    render() {
        const { theme } = this.props;
        const { icon , bgColor} =  this.state;
        const {percentageCategory, percentageQuality} = this.state;
        //console.log("props", this.props, this.state)

        return (
            <div ref={this.dialogRef}>
                <DialogTutorialPage 
                    defaultSet={this.props.defaultSet}
                    handleOpen={this.state.handleOpenTutorial || false}
                    handleBadgeFamily = {this.state.tutorialFamily?.length>1 ? this.state.tutorialFamily : 'total'}
                    handleClose={() => {this.setState({handleOpenTutorial: false})}}
                />
                <SnackbarInfo 
                    alertText = {this.state.alertText || 'Try to collect more XP'}
                    openOnClick = {true}
                    alertOpenEvent = {this.state.alertOpenEvent || false}
                    closeHandler = {() => this.setState({alertOpenEvent: false})}
                />
                        <Card style={this.props.showAsContent? theme.CardNeutral : this.props.screen === 'desktop' ? theme.CardDesktopTop : theme.CardTop}> 
                            {this.props.showAsContent !== true
                            && 
                                <div style={{width: '100%', position: 'relative'}}>
                                    { this.props.screen !== "desktop"
                                        &&  
                                        <div style={{marginRight: -10, zindex: 1, postion:'relative'}}>
                                            {false && !this.state.headerVisible
                                                && <div style={{width: 40, height:10, position: 'relative',  float : 'left'}}></div>
                                            }
                                            <StatsBar
                                                animateEveryPoint = { false}
                                                animatePlus1Trigger = { false}
                                                handleClick={this.props.buttonClickStats !== undefined && this.state.headerVisible
                                                    ?   () => this.props.buttonClickStats()
                                                    :   undefined
                                                }
                                                type='realtime' 
                                                //hide={!this.state.headerVisible ? true: false} 
                                                hide={true}
                                            />
                                        </div>
                                    }
                                    {this.state.headerVisible !== false
                                        ?
                                            <div style={{position: 'relative', zindex: 0}}> 
                                                <p style={{...theme.textTodayDate, paddingTop: 10}}>{this.state.welcomeDate}</p>
                                                <h4 style={{color:theme.palette.secondary.light, paddingRight: 40, marginBottom: 0, marginTop: 14}}>
                                                    <LanguageManager
                                                        text={this.state.welcomeGreeting}
                                                        type='button'
                                                        language={this.context?.features?.language}
                                                    />
                                                </h4>
                                                <div style={{position: 'absolute', top: 26, right:-6}}>
                                                    <IconButton style={{marginRight: 0}} onClick={() => {this.setState({tutorialFamily: 'path', handleOpenTutorial: true})}}>
                                                        <HelpOutlineIcon />
                                                    </IconButton>
                                                </div>
                                            </div>
                                        :
                                            <Card style={{
                                                ...theme.CardTop, 
                                                position: this.state.headerVisible? 'relative' : 'fixed',
                                                zIndex: this.state.headerVisible? 0 : 100,
                                                top: 0,
                                                left: 0,
                                                paddingBottom: 10,
                                                opacity: this.state.headerVisible? '1.0' : '0.94',
                                            }}>
                                                <div style={theme.textMonthCenter}>
                                                    <strong onClick={ () => {this.scrollToTop()}}>
                                                        {(this.context?.achievementSelf?.text && this.context?.achievementSelf?.text !== '') ? this.context?.achievementSelf.text : 'Coaching Plan'}
                                                    </strong>
                                                </div>
                                                <div style={{position: 'fixed', top: 3, right: 8}}>
                                                    <IconButton onClick={() => {this.setState({tutorialFamily: 'total', handleOpenTutorial: true})}}>
                                                        <HelpOutlineIcon />
                                                    </IconButton>
                                                </div>
                                            </Card>
                                    }
                                </div>
                            }
                            
                            <div>
                                <Grid container spacing={1} justifyContent="center" alignItems="center">
                                    <LeadershipQualityPicker
                                        defaultSet={this.props.defaultSet || undefined}
                                        firstSessionDone={this.props.user.firstSessionDone}
                                        chosenCategory={this.state.chosenCategory}
                                        scope={'current'}
                                        user={this.props.user}
                                        openChooseTopic1={this.state.openLeadershipQualityPicker || false}
                                        openChooseTopic2={this.state.openLeadershipQualityPicker2 || false}
                                        leadershipCategories={this.props.leadershipCategories}
                                        leadershipQualities={this.props.leadershipQualities}
                                        engagementStats={this.props.engagementStats}
                                        engagementBenchmarkQuality={400}
                                        handler={() => {this.setState({  openLeadershipQualityPicker: false,  openLeadershipQualityPicker2: false })}}
                                        handlerOpen2={(entry) =>  {this.setState({  openLeadershipQualityPicker: true,  openLeadershipQualityPicker2: true })}}
                                        handlerClose2={() =>  {this.setState({  openLeadershipQualityPicker: true,  openLeadershipQualityPicker2: false })}}
                                        handlerCloseAll={() => {this.setState({  openLeadershipQualityPicker: false,  openLeadershipQualityPicker2: false })}}
                                        closeDialog={() => {}}
                                    />
                                    <Grid item sm style={{width:'100%'}}>
                                        {(!this.context.programs?.includes('default') || FindTextValueInDict(this.props.firebase, this.props.leadershipCategories, this.context.currentCategory, 'program') !== 'default')
                                            ?
                                                <BadgeCard
                                                    backgroundColor = {'transparent'} 
                                                    //image={logoProgram}
                                                    //backgroundColor = {FindTextValueInDict(this.props.firebase, this.props.leadershipCategories, this.pro
                                                    avatarImage = { <AvatarImage 
                                                                type='category' 
                                                                id={this.props.user.currentCategory}
                                                                key={this.props.user.currentCategory} 
                                                                vertical-align="middle" item={this.props.user.currentCategory} 
                                                                squareSize={"auto"} backgroundColor={
                                                                    FindTextValueInDict(this.props.firebase, this.props.leadershipCategories, this.props.user.currentCategory, 'backgroundColor')
                                                                }

                                                                dict={this.props.leadershipCategories}
                                                            /> 
                                                        }
                                                    //image = {FindTextValueInDict(this.props.firebase, this.props.leadershipCategories, this.props.user.currentCategory, 'icon') || logoProgram} 
                                                    title = {FindTextValueInDict(this.props.firebase, this.props.leadershipCategories, this.props.user.currentCategory)}
                                                    subtitle = {FindTextValueInDict(this.props.firebase, this.props.leadershipCategories, this.props.user.currentCategory, 'explanationShort')}
                                                    strongSubtitle = {false}
                                                    onClick={
                                                        (this.state.xpLevels?.modules?.valid !== true || (this.props.showTopic===false && this.props.engagementCounter === undefined && this.props.user.engagementCounter< 50))
                                                        ?
                                                            undefined
                                                        :
                                                            this.props.showTopic===false 
                                                            ?   ( () => { this.props.history.push(ROUTES.STATS) })
                                                            :   (this.context.roles?.includes("COACHEE") )
                                                                ?   undefined
                                                                :   (() => {this.setState({openLeadershipQualityPicker: true})} )
                                                            }
                                                    clickText= {
                                                        (this.state.xpLevels?.modules?.valid !== true || ( this.props.showTopic ===false && this.props.engagementCounter === undefined && this.props.user.engagementCounter< 50))
                                                        ?
                                                            undefined
                                                        :
                                                            this.props.showTopic===false 
                                                            ?   'Insights'
                                                            :   this.context.roles?.includes("COACHEE")
                                                                    ?   undefined
                                                                    :   "Change"
                                                        }
                                                />
                                            :   this.context?.achievementSelf && this.context?.achievementSelf.text
                                                ?
                                                    <BadgeCard
                                                        backgroundColor ={"transparent"}
                                                        image={this.context?.achievementSelf?.icon || logoWhatsNext} 
                                                        avatarImage = {undefined}
                                                        title = {this.context?.achievementSelf?.text}
                                                        //title = {this.context?.achievementSelf?.text + (this.state.userLevel > 0 ? ' - Level '+this.state.userLevel : '')}
                                                        subtitle = {this.context?.achievementSelf?.explanationShort || undefined}
                                                        strongSubtitle = {false}
                                                        onClick={
                                                            this.props.showTopic===false && this.context?.dialogueCounter >= 4
                                                                //?   (() => { this.props.history.push(ROUTES.STATS) })
                                                                ?   (() => { this.props.history.push({pathname: ROUTES.ONBOARDING, state: {allowBack: true} }) })
                                                                :   (() => { this.props.history.push({pathname: ROUTES.ONBOARDING, state: {allowBack: true} }) })
                                                                //this.props.showTopic===false ? undefined : (() => { this.props.history.push({pathname: ROUTES.ONBOARDING, state: {allowBack: true} }) })
                                                            }
                                                        clickText= {
                                                            this.props.showTopic===false && this.context?.dialogueCounter >= 4
                                                                ?   'Change' // 'Insights'
                                                                :   'Change'
                                                                //this.props.showTopic===false ? undefined :  "Change"
                                                            }
                                                    />
                                                :
                                                    <BadgeCard
                                                        backgroundColor ={"white"}
                                                        image={logoWhatsNext} 
                                                        avatarImage = {undefined}
                                                        title = {'My Future Self'}
                                                        subtitle = {'Define who you want to be'}
                                                        strongSubtitle = {false}
                                                        onClick={ () => { this.props.history.push({pathname: ROUTES.ONBOARDING, state: {allowBack: true} }) }}
                                                        clickText= {'Set Up'}
                                                    />
                                        }
                                    </Grid>
                                    <Grid item xs style={{width:'100%'}}> 
                                        {this.props.showGoal!== false && this.state.backgroundImage?.includes('http')
                                            ?
                                                <BadgeCard
                                                    backgroundColor = {"transparent"}
                                                    backgroundImage = {this.state.backgroundImage || imageVisionBackground}
                                                    //image={this.state.backgroundIcon || imageSettingGoal} 
                                                    //avatarImage = { undefined  }
                                                    //color={theme.palette.themeAltGold}
                                                    //title = {"My Vision"}
                                                    //subtitle = {}
                                                    strongSubtitle = {false}
                                                    shadowText={false}
                                                    onClick={ () => {
                                                        if(this.context.roles?.includes("COACHEE")){ void(0)}
                                                        else{ this.setState({openLeadershipQualityPicker: true}) }
                                                    } }
                                                    clickText= {this.context.roles?.includes("COACHEE")
                                                        ?   undefined
                                                        :   "Change"
                                                    }
                                                />
                                            :
                                                ''
                                        }
                                    </Grid>
                                    {this.props.showTopic !== false && 
                                        <Grid item xs style={{width:'100%'}}>
                                            <BadgeCard
                                                id={this.props.user.currentLeadershipQuality}
                                                key={this.props.user.currentLeadershipQuality}
                                                backgroundColor ={"transparent"}
                                                image={undefined} 
                                                avatarImage = {
                                                    bgColor !== undefined
                                                    ?
                                                        (icon!== undefined && icon!== "")
                                                            ?
                                                                <AvatarImage 
                                                                    type='imageIcon' 
                                                                    id={this.props.user.currentLeadershipQuality}
                                                                    key={this.props.user.currentLeadershipQuality}
                                                                    image={icon}
                                                                    vertical-align="middle" squareSize={"auto"}  backgroundColor={bgColor} />
                                                            :
                                                                <AvatarImage 
                                                                    type='quality'
                                                                    dict={this.props.leadershipQualities}
                                                                    id={this.props.user.currentLeadershipQuality}
                                                                    key={this.props.user.currentLeadershipQuality} 
                                                                    vertical-align="middle" item={this.props.user.currentLeadershipQuality} 
                                                                    squareSize={"auto"} backgroundColor={bgColor} />
                                                    :
                                                        undefined
                                                    }
                                                title = {"Today's Quest"}
                                                subtitle = {FindTextValueInDict(this.props.firebase, this.props.leadershipQualities, this.props.user.currentLeadershipQuality)}
                                                strongSubtitle = {true}
                                                onClick={ () => {
                                                    if(this.context.roles?.includes("COACHEE")){ void(0)}
                                                    else{ this.setState({openLeadershipQualityPicker: true}) }
                                                } }
                                                clickText= {this.context.roles?.includes("COACHEE")
                                                    ?   undefined
                                                    :   "Change"
                                                }
                                            />
                                        </Grid>
                                    }
                                    {this.props.showBadge !== false && (this.state.badgeIcon!== undefined && this.state.badgeIcon!== '')
                                    ?
                                        <Grid item xs style={{width:'100%'}}>
                                            <DialogCardBadges 
                                                handleOpen={this.state.dialogCardBadgeOpen || false}
                                                handleClose={() => {this.setState({dialogCardBadgeOpen: false})}}
                                                badgeFamilyDetailSelection= {this.props.user.currentCategory}
                                            />
                                            <BadgeCard
                                                backgroundColor ={"transparent"}
                                                image={(this.state.badgeIcon!== undefined && this.state.badgeIcon!== '')?  this.state.badgeIcon :  badgeStart} 
                                                title = {this.state.badge !== undefined && this.state.badge.title !== undefined
                                                            ? this.state.badge.title 
                                                            : 'My program'
                                                        }
                                                subtitle = {this.state.badgeName !== undefined && this.state.badgeName !== ""
                                                            ? this.state.badgeName
                                                            : FindTextValueInDict(this.props.firebase, this.props.leadershipCategories, this.props.user.currentCategory)
                                                        }
                                                strongSubtitle = {true}
                                                onClick={ () => {this.setState({dialogCardBadgeOpen:true})} }
                                                clickText={"Show"}
                                            />
                                        </Grid>
                                    :   this.props.showTopic !== false
                                        ?   <Grid item xs style={{width:'100%', minWidth: 200}}> </Grid>
                                        :   ""
                                    }
                                    {this.props.showProgress !== false && 
                                        <Grid item xs={12}>
                                            <DialogProgramItems 
                                                user={this.props.user}
                                                handleOpen={this.state.dialogOpenProgressCategory || false}
                                                handleClose={() => {this.setState({dialogOpenProgressCategory:false})}}
                                                leadershipCategories = {this.props.leadershipCategories}
                                                leadershipQualities = {this.props.leadershipQualities}
                                                selectedQualities={this.state.programQualities}
                                                engagementStats={this.state.engagementStats || undefined}
                                                engagementBenchmarkQuality = {400} 
                                                title = {'Related Modules'}
                                            />
                                            <Card style={theme.CardDialogueButtonDesktop} 
                                                onClick={() => this.setState({dialogOpenProgressCategory: true})}
                                            //onClick={() => { this.props.history.push(ROUTES.STATS) }}
                                            >
                                                <div style={{paddingTop:20, height:"100%", overflow:'hidden'}}>
                                                    <p style={theme.textSupportCenter}> 
                                                        Experience Points (XP) &bull; Module & program
                                                    </p>
                                                    <Grid container style={{marginBottom: 40}} direction="row" justifyContent="space-between" alignItems="flex-end">
                                                        <Grid item xs={6} >
                                                            <div style={{height:theme.CardDialogueButtonDesktop.minHeight*0.7, width:"100%", minWidth: 100, maxWidth:150, padding:2, margin:'auto'}}>
                                                                <div style={{position:'relative',
                                                                    textAlign: 'center',
                                                                    zIndex: 2,
                                                                    top:"100%",
                                                                    height:0,
                                                                    width: '100%',
                                                                    color: theme.palette.secondary.main}}
                                                                    >
                                                                    {    FindTextValueInDict(this.props.firebase, this.props.leadershipQualities, this.props.user.currentLeadershipQuality)} 
                                                                </div>
                                                                <ProgressDoughnut
                                                                    percentage={percentageQuality}
                                                                    //colorMain = {bgColor}
                                                                    colorMain={theme.palette.themeAlertGreen}
                                                                />
                                                            </div>
                                                        </Grid>
                                                        <Grid item xs={6} >
                                                            <div style={{height:theme.CardDialogueButtonDesktop.minHeight, width:"100%",  minWidth: 100, maxWidth:150, padding:2, margin:'auto'}}>
                                                                <div style={{position:'relative',
                                                                    textAlign: 'center',
                                                                    zIndex: 2,
                                                                    top:"100%",
                                                                    height:0,
                                                                    width: '100%',
                                                                    color: theme.palette.secondary.main}}
                                                                    >
                                                                    {FindTextValueInDict(this.props.firebase, this.props.leadershipCategories, this.props.user.currentCategory)}
                                                                </div>
                                                                <ProgressDoughnut
                                                                    percentage={percentageCategory}
                                                                    //colorMain = {bgColorProgram}
                                                                    colorMain={theme.palette.themeAlertGreen}
                                                                    />
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            </Card>
                                        </Grid>
                                    }
                                    <Grid item xs={12}>
                                    </Grid>
                                    {this.props.showButtons && 
                                        <Card style={{
                                            ...theme.CardDialogueButtonDesktop, 
                                            width:  this.props.screen !== "desktop" ? 'calc(100% + 40px)': "100%", 
                                            padding: 0, 
                                            margin: this.props.screen !== "desktop" ? '10px -20px' : '10px 0px',
                                            }}   >
                                                <div style={{padding: '10px 0px 10px 0px', margin:0, width: "100%", maxHeight: 200, height:"100%", overflow:'hidden'}}>
                                                    <Tabs
                                                        aria-label="Coaching Modules"
                                                        variant="scrollable"
                                                        scrollButtons="auto"
                                                        value={4}
                                                        style={{
                                                            //marginLeft:20, 
                                                            //marginRight:20, 
                                                            //paddingLeft: 10
                                                        }} 
                                                    >
                                                        {(!(this.state.xpLevels?.journey?.valid) || !(this.state.xpLevels?.modules?.valid)) && (this.context.roles===undefined || !this.context.roles?.includes("COACHEE")) && 
                                                            <Tab 
                                                                value={1}
                                                                style={stylesTab.tabsNavi}
                                                                onClick={ this.props.buttonClickTopics!== undefined ? this.props.buttonClickTopics : void(0)}
                                                                label={
                                                                    <ActionFabButton
                                                                        theme={theme}
                                                                        icon={StarsRoundedIcon}
                                                                        text="Modules"
                                                                        //percentage = {percentageQuality || 0}
                                                                        height = '9vH'
                                                                        //colorCircle = {theme.palette.themeDarkBlue }
                                                                        //colorButton = {this.props.actionTopicsScore > 40?  theme.palette.themeAltGold : theme.palette.themeDarkBlue }
                                                                        percentage = {100}
                                                                        colorCircle = {'#e1e6e6'}
                                                                        colorButton = { theme.palette.themeBlue }
                                                                    />
                                                                }
                                                            />
                                                        }
                                                        {(this.state.xpLevels?.followUp?.valid !== true || this.props.screen === 'desktop')
                                                            &&
                                                            <Tab 
                                                                style={{...stylesTab.tabsNavi}}
                                                                value={2}
                                                                onClick={this.handleStartRoutine}
                                                                label={
                                                                <ActionFabButton
                                                                    text="AI Chat"
                                                                    icon={RockyIcon}
                                                                    theme={theme}
                                                                    height = '9vH'
                                                                    style={{marginLeft:10}}
                                                                    //percentage = {this.props.actionUpdatesScore || 0}
                                                                    //colorCircle = {this.props.actionUpdatesScore > 40? theme.palette.themeDarkBlue : theme.palette.themeAltGold}
                                                                    //colorButton = {this.props.actionUpdatesScore > 40? theme.palette.themeAltGold : theme.palette.themeDarkBlue }
                                                                    percentage = {100}
                                                                    colorCircle = {'#e1e6e6'}
                                                                    colorButton = {theme.palette.themeBlue }
                                                                />}
                                                            />
                                                        }
                                                        {this.props.buttonClickAssessment && (this.state.xpLevels?.social?.valid !== true || this.state.xpLevels?.journey?.valid)
                                                            &&
                                                            <Tab 
                                                                value={3}
                                                                style={{...stylesTab.tabsNavi, paddingRight: '20px !important', marginRight: '20px !important'}}
                                                                onClick={this.props.buttonClickAssessment}
                                                                label={
                                                                    <ActionFabButton
                                                                        theme={theme}
                                                                        icon={ExploreRoundedIcon}
                                                                        text="Assess"
                                                                        height = '9vH'
                                                                        percentage = {100}
                                                                        colorCircle = {'#e1e6e6'}
                                                                        colorButton = { theme.palette.themeBlue  }
                                                                    />
                                                                }
                                                            />
                                                        }
                                                        <Tab 
                                                            value={4}
                                                            onClick={this.state.xpLevels?.followUp?.valid !== true 
                                                                ? () => this.setState({alertText: this.state.xpLevels?.followUp?.text, alertOpenEvent: true})
                                                                : this.props.buttonClickPriorities!== undefined ? this.props.buttonClickPriorities : void(0)
                                                            }
                                                            style={stylesTab.tabsNavi}
                                                            label={
                                                            <ActionFabButton
                                                                text="Journey"
                                                                icon={AdjustRoundedIcon}
                                                                theme={theme}
                                                                //percentage = {this.props.actionUpdatesScore || 0}
                                                                height = '9vH'
                                                                //colorCircle = {theme.palette.themeDarkBlue }
                                                                //colorButton = {this.props.actionUpdatesScore > 40? theme.palette.themeAltGold : theme.palette.themeDarkBlue }
                                                                percentage = {100}
                                                                colorCircle = {'#e1e6e6'}
                                                                colorButton = {this.state.xpLevels?.followUp?.valid !== true ? theme.palette.themeGrey : theme.palette.themeBlue }
                                                            />}
                                                        />
                                                        {
                                                            <Tab 
                                                                value={5}
                                                                style={{...stylesTab.tabsNavi, paddingRight: '20px !important', marginRight: '20px !important'}}
                                                                onClick={this.state.xpLevels?.social?.valid !== true 
                                                                    ? () => this.setState({alertText: this.state.xpLevels?.social?.text, alertOpenEvent: true})
                                                                    : this.handleOpenDiscuss}
                                                                label={
                                                                    <ActionFabButton
                                                                        theme={theme}
                                                                        icon={SupervisedUserCircleRoundedIcon}
                                                                        text="Discuss"
                                                                        //percentage = {percentageQuality || 0}
                                                                        height = '9vH'
                                                                        //colorCircle = {theme.palette.themeDarkBlue }
                                                                        //colorButton = {this.props.actionChallengesScore >= 50?  theme.palette.themeAltGold : theme.palette.themeDarkBlue }
                                                                        percentage = {100}
                                                                        colorCircle = {'#e1e6e6'}
                                                                        colorButton = {this.state.xpLevels?.social?.valid !== true ? theme.palette.themeGrey : theme.palette.themeBlue  }
                                                                    />
                                                                }
                                                            />
                                                        }
                                                        {
                                                            <Tab 
                                                                value={6}
                                                                style={stylesTab.tabsNavi}
                                                                onClick={(this.state.xpLevels?.journey?.valid) 
                                                                    ? this.handleOpenStats
                                                                    : () => this.setState({alertText: this.state.xpLevels?.journey?.text, alertOpenEvent: true})
                                                                }
                                                                label={
                                                                    <ActionFabButton
                                                                        theme={theme}
                                                                        icon={DonutSmallIcon}
                                                                        text="Insights"
                                                                        //percentage = {percentageQuality || 0}
                                                                        height = '9vH'
                                                                        //colorCircle = {theme.palette.themeDarkBlue }
                                                                        //colorButton = {this.props.actionTopicsScore > 40?  theme.palette.themeAltGold : theme.palette.themeDarkBlue }
                                                                        percentage = {100}
                                                                        colorCircle = {'#e1e6e6'}
                                                                        colorButton = {this.state.xpLevels?.journey?.valid !== true ? theme.palette.themeGrey : theme.palette.themeBlue }
                                                                    />
                                                                }
                                                            />
                                                        }
                                                        {/***
                                                        <Tab 
                                                            value={7}
                                                            style={stylesTab.tabsNavi}
                                                            onClick={this.state.xpLevels?.content?.valid !== true 
                                                                ? () => this.setState({alertText: this.state.xpLevels?.content?.text, alertOpenEvent: true})
                                                                : this.handleOpenContent}
                                                            label={
                                                                <ActionFabButton
                                                                    theme={theme}
                                                                    icon={WidgetsRoundedIcon}
                                                                    text="Tips"
                                                                    //percentage = {percentageQuality || 0}
                                                                    height = '9vH'
                                                                    //colorCircle = {theme.palette.themeDarkBlue }
                                                                    //colorButton = {this.props.actionTopicsScore > 40?  theme.palette.themeAltGold : theme.palette.themeDarkBlue }
                                                                    percentage = {100}
                                                                    colorCircle = {'#e1e6e6'}
                                                                    colorButton = {this.state.xpLevels?.content?.valid !== true ? theme.palette.themeGrey : theme.palette.themeBlue }
                                                                />
                                                            }
                                                        />
                                                         */}
                                                        {/***
                                                        <Tab 
                                                            value={8}
                                                            style={stylesTab.tabsNavi}
                                                            onClick={this.state.xpLevels?.journal?.valid !== true 
                                                                ? () => this.setState({alertText: this.state.xpLevels?.journal?.text, alertOpenEvent: true})
                                                                : this.handleOpenJournal}
                                                            label={
                                                                <ActionFabButton
                                                                    theme={theme}
                                                                    icon={AssignmentTurnedInIcon}
                                                                    text="Goals"
                                                                    //percentage = {this.props.actionChallengesScore || 0}
                                                                    height = '9vH'
                                                                    //colorCircle = {theme.palette.themeDarkBlue }
                                                                    //colorButton = {this.props.actionChallengesScore >= 50?  theme.palette.themeAltGold : theme.palette.themeDarkBlue  }
                                                                    percentage = {100}
                                                                    colorCircle = {'#e1e6e6'}
                                                                    colorButton = {this.state.xpLevels?.journal?.valid !== true ? theme.palette.themeGrey : theme.palette.themeBlue }
                                                                />
                                                            }
                                                        />
                                                         */}
                                                        {!(this.state.xpLevels?.modules?.valid) && this.context.roles?.includes("COACHEE") && 
                                                            <Tab 
                                                                value={9}
                                                                style={stylesTab.tabsNavi}
                                                                onClick={ this.props.buttonClickTopics!== undefined ? this.props.buttonClickTopics : void(0)}
                                                                label={
                                                                    <ActionFabButton
                                                                        theme={theme}
                                                                        icon={StarsRoundedIcon}
                                                                        text="Modules"
                                                                        //percentage = {percentageQuality || 0}
                                                                        height = '9vH'
                                                                        //colorCircle = {theme.palette.themeDarkBlue }
                                                                        //colorButton = {this.props.actionTopicsScore > 40?  theme.palette.themeAltGold : theme.palette.themeDarkBlue }
                                                                        percentage = {100}
                                                                        colorCircle = {'#e1e6e6'}
                                                                        colorButton = { theme.palette.themeBlue }
                                                                    />
                                                                }
                                                            />
                                                        }
                                                    </Tabs> 
                                                </div>
                                        </Card>
                                    }
                                    {false && this.props.showButtons && 
                                        <Grid item xs={12}>
                                            <Card style={{...theme.CardDialogueButtonDesktop, width: "100%"}}   >
                                                <div style={{paddingTop:20, paddingBottom:20, height:"100%", overflow:'hidden'}}>
                                                    <Grid container direction="row" justifyContent="center" alignItems="center">
                                                        <Grid item xs={4}
                                                            onClick={ this.props.buttonClickPriorities!== undefined ? this.props.buttonClickPriorities : void(0)}
                                                            >
                                                            <ActionFabButton
                                                                text="To-Dos"
                                                                theme={theme}
                                                                percentage = {this.props.actionUpdatesScore || 0}
                                                                height ={
                                                                    this.props.screen !== "desktop"
                                                                        ?   'calc(24vw*'+(this.props.actionUpdatesScore >= 60 ? 0.6 : 1)  +')'
                                                                        :   theme.CardDialogueButtonDesktop.minHeight * (this.props.actionUpdatesScore >= 60 ? 0.6 : 1)  
                                                                    }
                                                                colorCircle = {this.props.actionUpdatesScore > 40? theme.palette.themeAlertGreen : theme.palette.themeAltGold}
                                                                colorButton = {this.props.actionUpdatesScore > 40? theme.palette.themeAlertGreen : theme.palette.themeAltGold}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4}
                                                            onClick={ this.props.buttonClickTopics!== undefined ? this.props.buttonClickTopics : void(0)}
                                                             >
                                                             <ActionFabButton
                                                                theme={theme}
                                                                text="Modules"
                                                                percentage = {percentageQuality || 0}
                                                                height ={
                                                                    this.props.screen !== "desktop"
                                                                        ?   'calc(24vw*'+ ((this.props.actionUpdatesScore < 10 || this.props.actionTopicsScore >= 60 )? 0.6 : 1)   +')'
                                                                        :   theme.CardDialogueButtonDesktop.minHeight *  ((this.props.actionUpdatesScore < 10 || this.props.actionTopicsScore >= 60 )? 0.6 : 1) 
                                                                }
                                                                colorCircle = {percentageQuality> 40?  theme.palette.themeAlertGreen : percentageQuality>10 ? theme.palette.themeAltGold : theme.palette.themeLightGrey}
                                                                colorButton = {this.props.actionTopicsScore > 40?  theme.palette.themeAlertGreen : this.props.actionUpdatesScore > 20 ?  theme.palette.themeAltGold : theme.palette.themeLightGrey}
                                                             />
                                                        </Grid>
                                                        <Grid item xs={4} 
                                                            onClick={ this.props.buttonClickMission!== undefined ? this.props.buttonClickMission : void(0)}
                                                            >
                                                            <ActionFabButton
                                                                theme={theme}
                                                                text="Challenges"
                                                                percentage = {this.props.actionChallengesScore || 0}
                                                                height ={
                                                                    this.props.screen !== "desktop"
                                                                        ?   'calc(24vw*'+ ((this.props.actionTopicsScore > 20 && !(this.props.actionChallengesScore >=50 ))? 1 : 0.6)   +')'
                                                                        :   theme.CardDialogueButtonDesktop.minHeight *  ((this.props.actionTopicsScore > 20 && !(this.props.actionChallengesScore >=50 ))? 1 : 0.6)
                                                                }
                                                                colorCircle = {this.props.actionChallengesScore >= 50?  theme.palette.themeAlertGreen : this.props.actionChallengesScore > 5 ?  theme.palette.themeAltGold : theme.palette.themeLightGrey}
                                                                colorButton = {this.props.actionChallengesScore >= 50?  theme.palette.themeAlertGreen : this.props.actionTopicsScore > 20 ?  theme.palette.themeAltGold : theme.palette.themeLightGrey}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            </Card>
                                        </Grid>
                                    }
                                    <VizSensor 
                                        partialVisibility={"top"}
                                        onChange={(isVisible) => { 
                                            if(this.props.screen !== 'desktop' && isVisible && this._isMounted) { 
                                                this.setState({headerVisible: true});
                                                //console.log('visible top cart')
                                            }
                                            else if (this.props.screen !== 'desktop' && this._isMounted){
                                                this.setState({headerVisible: false});
                                                //console.log('hding top cart')
                                            }
                                        }}>
                                        <div style={{height: 1}}>  <img src={ pixel} alt=''/></div>
                                    </VizSensor>
                                </Grid>
                            </div>
                        </Card>
                    </div>      
        );
    }
}

export default compose(
    withFirebase,
	withRouter,
    withStyles(styles, { withTheme: true })
)(TodayProgram);
