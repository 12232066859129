import React, { Component } from 'react';
import { compose } from 'recompose';
import { AuthUserContext } from '../Session';
import { AddAnalytics } from '../Modules/AddAnalytics';
import withStyles from '@material-ui/core/styles/withStyles';
import { withFirebase } from '../Firebase';

import VizSensor from 'react-visibility-sensor';
import InfiniteContent from '../Content/InfiniteContent';

import {AvatarCard} from '../Modules/AvatarImage';
import AvatarImage from '../Modules/AvatarImage';
import { FindTextValueInDict } from '../Modules/LoadCategoriesAndQualities';
import {AddEngagementPoints} from '../Modules/AddEngagementPoints';
import DateBadge from '../Modules/DateBadge';
import dust from "../../images/animateddust.gif";
import {checkInTimeRange} from '../Routine/SetAgentStatus';
import {ExtendDialogueMessage} from '../Routine/DiaryManual';
import {IsMobileDevice} from '../Modules/IsMobileDevice';
import DialogTutorialPage from '../Onboarding/DialogTutorialPage';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Box from '@material-ui/core/Box';
//import Backdrop from '@material-ui/core/Backdrop';
//expansion
//import Accordion from '@material-ui/core/Accordion';
//import AccordionDetails from '@material-ui/core/AccordionActions';
//import AccordionSummary from '@material-ui/core/AccordionSummary'
//import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// Modules
import CardAlert from '../Alerts/CardAlert';
import SpeechToTextButton from '../Modules/SpeechToTextButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import SendIcon from '@material-ui/icons/Send';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

//import CardMedia from "@material-ui/core/CardMedia";
import Switch from '@material-ui/core/Switch';


import { SelectOption, SelectWrapper } from '../Modules/SelectOption';
import LanguageManager from '../../constants/languages';

//popup
import {RemoveScroll} from 'react-remove-scroll';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
//import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import AssessmentSlider from '../Charts/AssessmentSlider';
import AssessmentTimeline from '../Charts/AssessmentTimeline';
import InfoIcon from '@material-ui/icons/Info';
import EmailIcon from '@material-ui/icons/Email';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import {IconPicker} from '../Modules/IconPicker';

/// DND
//import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
    LeadingActions,
    SwipeableList,
    SwipeableListItem,
    SwipeAction,
    TrailingActions,
    } from 'react-swipeable-list';
import 'react-swipeable-list/dist/styles.css';
//https://www.npmjs.com/package/react-swipeable-list

// collapse details
import IconButton from '@material-ui/core/IconButton';
//import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
//import CancelIcon from '@material-ui/icons/Cancel';
//import ArrowBackIcon from '@material-ui/icons/ArrowBack';
//import {IconPicker} from '../Modules/IconPicker';

import ProgressHeatMap from '../Charts/ProgressHeatMap';
import TextField from '@material-ui/core/TextField';
import JournalList  from './JournalList';

// Icons
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import WarningIcon from '@material-ui/icons/Warning';
import LowPriorityIcon from '@material-ui/icons/LowPriority';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import SmsFailedIcon from '@material-ui/icons/SmsFailed';
//import AddAlertIcon from '@material-ui/icons/AddAlert';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ControlPointRoundedIcon from '@material-ui/icons/ControlPointRounded';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import EventIcon from '@material-ui/icons/Event';
import FilterCenterFocusIcon from '@material-ui/icons/FilterCenterFocus';
import MoveToInboxIcon from '@material-ui/icons/MoveToInbox';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Slide from '@material-ui/core/Slide';
import BadgeCard from '../Modules/BadgeCard';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const styles = theme => ({
    grow: {
        flexGrow: 1,
    },
    ReactCalendarHeatmap:{
        text:{
            fontSize: 4,
            fill: '#555'
        },
        '&.color-empty': {
            fill: 'red'
        }
    }
})

const delay = ms => new Promise(
    resolve => setTimeout(resolve, ms)
);

class JournalActivitiesItem extends Component {
    static contextType = AuthUserContext;
    _isMounted = false;

    constructor(props) {
        super(props);
        //console.log('probs in JournalWeek ',this.probs);
        var originalDate = props.cta.createdAt;
        if(props.cta.createdAtInit !== undefined && props.cta.createdAtInit !== null && props.cta.createdAtInit !== ''){
            originalDate = props.cta.createdAtInit;
        }
        var originalMessage = props.cta.message;
        if(props.cta.messageInit !== undefined && props.cta.messageInit !== null && props.cta.messageInit !== ''){
            originalMessage = props.cta.messageInit;
        }

        var upToDate = false;
        if(props.cta.createdAt !== undefined && props.cta.createdAt !== null){
            var currentDate = new Date();
            /// minus six hour same day
            //currentDate.setHours(currentDate.getHours()-5);
            //// reduce Date by 36 hours
            currentDate = new Date(currentDate - 36 * 60 * 60 * 1000);
            var dialogDate = new Date(props.cta.createdAt.seconds * 1000);
            if(currentDate < dialogDate){
                upToDate = true;
            }
        }

        var routine = 'priming';
        var isReflection = false;
        try{
            var reflectionFrom = this.context.reflectionFrom;
            var primingFrom  = this.context.primingFrom;
            isReflection = checkInTimeRange(reflectionFrom, primingFrom)
        } catch  (err) {
            isReflection = checkInTimeRange("15:00", "04:00")
        }
        if(isReflection){
            routine = 'reflection';
        }

        var backgroundColor = FindTextValueInDict(props.firebase, props.leadershipCategories, props?.cta?.leadership_category, 'backgroundColor');
        
        this.state = {
            open: false,
            openAlert : false,
            actionId: props.ctaId,
            originalDate,
            originalMessage,
            upToDate,
            //dateAlert: materialDateInput,
            //timeAlert: "13:30",
            routine: routine,
            message: props.cta.message,
            intention: props.cta.intention,
            action: props.cta,
            backgroundColor
        };
    }  

    componentDidMount() {
        this._isMounted = true;
    }
    componentWillUnmount(){
        this._isMounted =  false;
    }
    componentDidUpdate(prevProps){
        if(this.props.open !== undefined && this.props.open === true && prevProps.open !== this.props.open && this.state.open !== true ){
            if(this._isMounted ) {
                this.setState({ open : true})
            }
        }
        if(this.props.cta !== undefined && this.props.cta.createdAt !== undefined && this.props.cta.createdAt !== null && prevProps.cta !== undefined && prevProps.cta.createdAt !== undefined){
            if(this._isMounted ) {
                if(this.props.cta.createdAt !== prevProps.cta.createdAt){
                    var currentDate = new Date();
                    currentDate.setHours(currentDate.getHours()-5);
                    var dialogDate = new Date(this.props.cta.createdAt.seconds * 1000);
                    if(currentDate < dialogDate){
                        this.setState({ upToDate : true, action: this.props.cta})
                    }
                }
                else if(this.props.cta?.value !== prevProps.cta.value){
                    this.setState({action: this.props.cta})
                    console.log('updated value')
                }
            }
        }
    }
    
    renderSwipeableList(date, action){
        const {theme} = this.props;
        var backgroundColor = this.state.backgroundColor || theme.palette.themeAlertBlue;
        var swipe2ndTreshold = 50;
        var swipe1stTreshold = 30;
        return(
            <div style={{
                width: '100%',
                //position: 'absolute',
                //top: 'calc(-150px - 10px)',
                //bottom: '-10',
                //minHeight: theme.cardJournalDayHighlight.minHeight,
                height: '100%',
                //left: '30%',
                //zIndex: this.state.isDragging ? 1 : -1
            }}>
                <Card 
                    style={{
                        ...theme.cardJournalDayHighlight, 
                        //background: backgroundColor, //theme.palette.themeLightGrey,
                        background: 'linear-gradient(to right, '+backgroundColor+' '+(30)+'%, '+theme.palette.backPrimary+' '+(70)+'%)',
                        marginLeft: this.props.subItemView !== true ? 0 : '10%',
                        //marginTop: this.props.subItemView !== true ? 10 : -6,
                        position: 'relative'
                    }}
                    onClick={() => {
                        this.state.swiping
                        ?   void(0)
                        :   this.handleOpen()
                    }}
                >
                    <SwipeableList 
                        key={action.id}
                        //type={ListType.IOS}
                        fullSwipe={true}
                        threshold={0.3}
                        >
                        <SwipeableListItem
                            blockSwipe={action.done ? true : false}
                            onSwipeProgress={ (p,d) => {
                                //console.log('progress', p ,d)
                                this.setState({swipeProgress: p, swiping: true})
                            }} 
                            onSwipeEnd={async () => {
                                //console.info('swipe action ended')
                                await delay(500);
                                this.setState({swiping: false})
                                //console.info('delayed')
                            }}
                            leadingActions={
                                <LeadingActions
                                    style={{backgroundColor: backgroundColor}}
                                >
                                    <SwipeAction
                                        onClick={() => {
                                            console.info('swipe action done')
                                            if(this.state.swipeProgress >= swipe2ndTreshold){
                                                this.actionSetDone()
                                            }
                                            else{
                                                this.actionSetOnTrack()
                                            }
                                            //this.actionSetDone()
                                        }}
                                        >
                                        <Grid
                                            container spacing={0} direction="row"
                                            justifyContent="space-between"
                                            alignItems="center"
                                            style={{ 
                                                borderColor: this.state.swipeProgress >= swipe2ndTreshold ? theme.palette.themeAltGold : backgroundColor,
                                                //backgroundColor: this.state.backgroundColor, this.state.invertColor
                                                backgroundColor: this.state.swipeProgress >= swipe2ndTreshold ? theme.palette.themeAltGold : backgroundColor, 
                                                margin: 0
                                            }} 
                                            onClick={() => this.actionSetDone()}
                                        >
                                            {this.state.swiping && this.state.swipeProgress >= swipe2ndTreshold &&
                                                <Grid item xs={12} style={{paddingLeft: 20}}>
                                                    <IconButton 
                                                        style={{padding: 2}}>
                                                        <EmojiEventsIcon 
                                                            style={{color: 'black' }}
                                                            fontSize="small"/>
                                                    </IconButton>
                                                    <br/>
                                                    <span 
                                                        style={{color: theme.palette.black, whiteSpace: 'nowrap', textAlign: 'center' }} 
                                                    >
                                                        <LanguageManager
                                                            text={"Done"} 
                                                            type='button'
                                                            language={this.context?.features?.language}
                                                        />
                                                    </span>
                                                </Grid>
                                            }
                                        </Grid>
                                    </SwipeAction>
                                    <SwipeAction 
                                        onClick={() => console.info('swipe action on track')}
                                        //onClick={() => this.actionSetOnTrack(undefined, true)}
                                        >
                                        <Grid
                                            container spacing={0} direction="row"
                                            justifyContent="space-between"
                                            alignItems="center"
                                            style={{fontSize:"small", textAlign:'center', 
                                                backgroundColor: backgroundColor,
                                                borderColor: backgroundColor,
                                                margin: 0
                                                //marginTop: 1, //marginLeft: 1, marginBottom: 1, 
                                                //paddingTop: 1, paddingLeft: 1,
                                                //backgroundColor: this.state.swipeProgress < swipe2ndTreshold ? theme.palette.themeAlertOrange : 'transparent',
                                                //color: this.state.swipeProgress < swipe2ndTreshold ? 'lightgrey' : backgroundColor,
                                                //filter: this.state.swipeProgress < swipe2ndTreshold ? 'invert(60%)' : 'invert(0%)',
                                            }}
                                        >
                                            {this.state.swipeProgress < swipe2ndTreshold && this.state.swipeProgress >= swipe1stTreshold && this.state.swiping &&
                                                <Grid item xs={12} style={{paddingRight: 20}}>
                                                    <IconButton style={{padding: 2, marginTop:'auto', marginBottom:'auto'}}>
                                                        <CheckCircleOutlineIcon 
                                                            style={{
                                                                color: 'black',
                                                                //filter: 'invert(100%)',
                                                            }}
                                                        />
                                                    </IconButton>
                                                    <br/>
                                                    <span style={{color: theme.palette.black, whiteSpace: 'nowrap', textAlign: 'center' }}>
                                                        <LanguageManager
                                                            text={"On Track"} 
                                                            type='button'
                                                            language={this.context?.features?.language}
                                                        />
                                                    </span>
                                                </Grid>
                                            }
                                        </Grid>
                                    </SwipeAction>
                                </LeadingActions>
                            }
                            trailingActions={
                                <TrailingActions>
                                    <SwipeAction
                                        //destructive={true}
                                        onClick={() => console.info('swipe action triggered')}
                                        //onClick={() => this.actionSetOffTrack(undefined, true)}
                                        >
                                        <Grid
                                            container spacing={0} direction="row"
                                            justifyContent="space-between"
                                            alignItems="center"
                                            style={{fontSize:"small", textAlign:'center', 
                                                backgroundColor: theme.palette.backPrimary,
                                                margin: 0
                                            }}
                                        >
                                            {this.state.swipeProgress < swipe2ndTreshold && this.state.swipeProgress >= swipe1stTreshold && this.state.swiping &&
                                                <Grid item xs={12} style={{paddingRight: 20}}>
                                                    <IconButton color="primary" style={{padding: 2}}>
                                                        <SentimentVeryDissatisfiedIcon fontSize="small" style={{color: theme.palette.themeRed}}/>
                                                    </IconButton>
                                                    <br/>
                                                    <span style={{color: theme.palette.themeRed, whiteSpace: 'nowrap', textAlign: 'center' }}  >
                                                        <LanguageManager
                                                            text={"To Improve"} 
                                                            type='button'
                                                            language={this.context?.features?.language}
                                                        />
                                                    </span>
                                                </Grid>
                                            }
                                        </Grid>
                                    </SwipeAction>
                                    <SwipeAction
                                        //destructive={true}
                                        onClick={() => {
                                            if(this.state.swipeProgress >= swipe2ndTreshold){
                                                this.actionSetHide()
                                            }
                                            else{
                                                this.actionSetOffTrack()
                                            }
                                            //console.info('swipe action off track')
                                        }}
                                        >
                                        <Grid
                                            container spacing={0} direction="row"
                                            justifyContent="space-between"
                                            alignItems="center"
                                            style={{fontSize:"small", textAlign:'center', 
                                                backgroundColor: this.state.swipeProgress >= swipe2ndTreshold ? theme.palette.themeAlertOrange : theme.palette.backPrimary, 
                                                margin: 0
                                            }}
                                        >
                                            {this.state.swipeProgress >= swipe2ndTreshold && this.state.swiping &&
                                                <Grid item xs={12} style={{paddingRight: 20}}>
                                                    <IconButton color="primary" style={{padding: 2}}>
                                                        <LowPriorityIcon fontSize="small" style={{color: theme.palette.themeRed}}/>
                                                    </IconButton>
                                                    <br/>
                                                    <span style={{color: theme.palette.themeRed, whiteSpace: 'nowrap', textAlign: 'center' }}  >
                                                        <LanguageManager
                                                            text={"Stash"} 
                                                            type='button'
                                                            language={this.context?.features?.language}
                                                        />
                                                        &nbsp;
                                                    </span>
                                                </Grid>
                                            }
                                        </Grid>
                                    </SwipeAction>
                                </TrailingActions>
                            }
                        >
                            <CardContent 
                                style={{
                                    padding: 0, 
                                    minHeight: theme.cardJournalDayHighlight.minHeight,
                                    width: '100%'
                                }}>
                                {  this.returnCheckSection(backgroundColor, date, action) }
                            </CardContent>
                        </SwipeableListItem>
                    </SwipeableList>
                    {this.props.flagHighlighted && !(this.state.swiping)
                        &&
                        <div
                            style={{
                                position: 'absolute',
                                bottom: '-10%',
                                left: 0,
                                width: '30%',
                                height: '120%',
                                //top: 0,
                                //top: '80vH',
                                zIndex: '15001 !important',
                                backgroundColor: 'transparent',
                                color: 'transparent',
                            }}>
                            <img 
                                src={dust} 
                                alt={''}
                                style={{
                                    opacity: 0.94,
                                    width: '100%',
                                    height: '120%',
                                }}
                            />
                        </div>
                    }
                    {(action.important || action.urgent)
                        ?
                            <div
                                style={{
                                    position: 'absolute',
                                    top: '2px',
                                    right: '8px',
                                    zIndex: '15001 !important',
                                    color: theme.palette.secondary.main,
                                    fontSize: '12px',
                                    visibility: this.state.swiping ? 'hidden' : 'visible'
                                }}>
                                <LanguageManager
                                    text={"Important"} 
                                    type='button'
                                    language={this.context?.features?.language}
                                />!
                            </div>
                        :
                            <div
                                style={{
                                    position: 'absolute',
                                    top: '2px',
                                    right: '14px',
                                    zIndex: '15001 !important',
                                    color: theme.palette.secondary.main,
                                    fontSize: '12px',
                                    visibility: this.state.swiping ? 'hidden' : 'visible'
                                }}
                            >
                                <LanguageManager
                                    text={"Show"} 
                                    type='button'
                                    language={this.context?.features?.language}
                                />
                            </div>
                    }
                </Card>
            </div>
        )
    }

    render(){
        var action = this.state.action;
        const {theme} = this.props;
        //console.log("journalactivitiesitem", this.state)
        //const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
        
        if(action!==undefined && action!==null) {
            var date = new Date();
            if(action.date!== undefined){
                date = new Date(action.date.substring(0, 10));
            }
            var secondColor = theme.cardJournalDayHighlight.backgroundColor;
            if(action.failed ) secondColor = theme.palette.themeGrey;
            else if(action.urgent ) secondColor = theme.palette.themeAlertRed;
            else if(action.done || action.onTrack) secondColor = theme.palette.themeAlertGreen;
            else if(action.important ) secondColor = theme.palette.themeAlertBlue;
            else if(action.offTrack ) secondColor = theme.palette.themeAlertOrange;
            var background = 'linear-gradient(to bottom, '+secondColor+' 0%, '+theme.palette.backPaper+' 30%)';
            return(
                <div>
                    <DialogTutorialPage 
                        defaultSet={this.props.defaultSet}
                        handleOpen={this.state.handleOpenTutorial || false}
                        handleBadgeFamily = {'followup'}
                        handleClose={() => {this.setState({handleOpenTutorial: false})}}
                    />
                    {this.props.compactMode!==undefined && this.props.compactMode
                        &&
                            this.returnLinkSection(date, action)
                    }
                    {this.props.checkMode!==undefined && this.props.checkMode
                        &&
                            this.renderSwipeableList(date, action)
                    }
                    {this.props.checkMode!==true && this.props.compactMode!==true
                        &&
                            <Card key={this.state.actionId}  style={{...theme.cardJournalDayHighlight, background: background }}   >
                                <CardContent>
                                    { this.returnMainSection(date, action)}
                                </CardContent>
                            </Card>
                    }
                    <RemoveScroll enabled={this.state.open || false}>
                        <Dialog
                            key={this.state.actionId}
                            open={this.state.open}
                            onClose={() => this.handleClose()}
                            fullScreen={IsMobileDevice()}
                            PaperProps={{style:theme.dialogWindow}}
                            TransitionComponent={Transition}
                        >
                        <DialogTitle style={{...theme.dialogTitle, background}}  >
                            <Grid container spacing={0} direction="row"
                                justifyContent="space-between"
                                alignItems="center">
                                <Grid item >
                                    <IconButton onClick={() => this.handleClose()}>
                                        <ArrowBackIcon />
                                    </IconButton>
                                </Grid>
                                <Grid item>
                                    <span style={theme.dialogTitle.titleText}>
                                        <LanguageManager
                                            text={"My Goals"} 
                                            type='button'
                                            language={this.context?.features?.language}
                                        />
                                    </span>
                                </Grid>
                                <Grid item>
                                    <IconButton style={{marginRight: 2}} onClick={() => {this.setState({handleOpenTutorial: true})}}>
                                        <HelpOutlineIcon />
                                    </IconButton>
                                    {/***
                                    <span style={theme.dialogTitle.titleLink} onClick={() => this.handleClose()}>
                                        Close
                                    </span>
                                     */}
                                </Grid>
                            </Grid>
                        </DialogTitle>
                        {this.state.open &&
                            <DialogContent style={{...theme.dialogContent}}>
                                { this.returnMainSection(date, action, true)}
                                
                                {(action.intention?.length>0 || (action.activityArchive !== undefined && action.activityArchive !== null && action.activityArchive.length>0 ))
                                    &&
                                    <div>
                                        <div style={{ paddingTop: 20 }}> </div>
                                        {
                                            <AssessmentSlider
                                                user = {this.context}
                                                circular = {true}
                                                variant = {'followups'}
                                                assessmentKey={action.messageId || this.state.actionId}
                                                assessmentAction = {action}
                                                assessmentText = {action.questionProgress?.length > 2 ? action.questionProgress : action.comment?.length > 2 ? action.comment : action.message}
                                                assessmentQuestion = 'What is the status?'
                                                assessmentStartValue = {action.done === true ? 100 : action.value ? action.value : undefined}
                                                textMax = 'Victorious'
                                                textMin = 'To Improve'
                                                textMeasure = 'Satisfaction Level'
                                                updateScore = { (score) => { 
                                                    if(score>93) this.actionSetDone(score)
                                                    else if(score >= 50) this.actionSetOnTrack(score, true)
                                                    else if(score >=8) this.actionSetOffTrack(score)
                                                    else if(score <7) this.actionSetOffTrack(score)
                                                }}
                                            />
                                        }
                                        {action.done !== true
                                            &&
                                            this.returnPriorityMatrix(action)
                                        }

                                        {this.state.showTimeLine
                                            ?
                                                <AssessmentTimeline
                                                    user = {this.context}
                                                    defaultSet={this.props.defaultSet || undefined}
                                                    assessmentArchive = {action.activityArchive?.length>0 ? action.activityArchive : []}
                                                    assessmentText = {''}
                                                    assessmentQuestion = 'What is the status:'
                                                    type = {'category'}
                                                    updateComment = { (comment) => {this.actionChangeComment(comment)} }
                                                    //updateScore = { (score) => { if (this.props.updateScore !== undefined) this.props.updateScore(score) } }
                                                />
                                            :   true
                                                ?   ""
                                                :
                                                    <div>
                                                        <h4>
                                                            <LanguageManager
                                                                text={"Timeline"} 
                                                                type='button'
                                                                language={this.context?.features?.language}
                                                            />
                                                        </h4>
                                                        <div style={{textAlign: 'center'}}>
                                                            <Button
                                                                onClick={() => this.setState({showTimeLine: true})}
                                                            >
                                                                <LanguageManager
                                                                    text={"Show timeline"} 
                                                                    type='button'
                                                                    language={this.context?.features?.language}
                                                                />
                                                            </Button>
                                                        </div>
                                                    </div>
                                        }
                                        {this.props.showHeatMap
                                            &&
                                            <div style={{overflowX: 'auto', direction: 'rtl'}}>
                                                <ProgressHeatMap
                                                    startDate={date}
                                                    defaultSet={this.props.defaultSet || undefined}
                                                    endDate={new Date()}
                                                    values={action.activityArchive}
                                                    classForValue={(value) => {
                                                        if (!value) {
                                                            return 'color-empty';
                                                        }
                                                        return `color-scale-${value.count}`;
                                                    }}
                                                    onClick={(item) => this.setState({highlightItem: item})}
                                                    weekdayLabels={['S', 'M', 'T', 'W', 'T', 'F', 'S']}
                                                    showMonthLabels={true}
                                                    showWeekdayLabels={false}
                                                    showOutOfRangeDays={false}
                                                />
                                                {this.state.highlightItem !== undefined && this.state.highlightItem !== null &&
                                                    <div style={theme.divCenterPadding}>
                                                        <span style={theme.cardJournalDayHighlight.iconDesign}>{this.state.highlightItem.date}:</span><br/>
                                                        {this.state.highlightItem.offTrack && 'To Improve'}
                                                        {this.state.highlightItem.onTrack && 'On Track'}
                                                        {this.state.highlightItem.important && 'Important'}
                                                        {this.state.highlightItem.urgent && 'Urgent'}
                                                        {this.state.highlightItem.done && 'Done'}
                                                        {this.state.highlightItem.failed && 'Failed'}
                                                        {this.state.highlightItem.message && this.state.highlightItem.message!=='' && 
                                                            <q> {this.state.highlightItem.message} </q>
                                                        }
                                                    </div>
                                                }
                                            </div>
                                        }
                                        {(this.props.showTips) && action.intentClass?.length>1
                                        &&
                                            <VizSensor 
                                                partialVisibility={true} 
                                                active={ this.state.isVisible !== true ? true : false}
                                                onChange={(isVisible) => { 
                                                    if(isVisible &&  this._isMounted) { 
                                                        this.setState({isVisible: true});
                                                    }
                                                }} >
                                                <div style={{marginTop: 40, minHeight: '20vh', position: 'relative'}}>
                                                {this.state.isVisible &&
                                                    <InfiniteContent 
                                                        user = {this.props.user}
                                                        defaultSet={this.props.defaultSet || undefined}
                                                        scrollIntoViewDisabled = {true}
                                                        screenWidth = {this.props.screenWidth || (!IsMobileDevice() ? 800 : undefined)}
                                                        visualLimit = {8}
                                                        hideLimitPrompt = {true}
                                                        raised = {true}
                                                        filterIntent = {action.intentClass}
                                                        filterCategory = {action.leadership_category?.length>1 ? action.leadership_category : undefined }
                                                        filterText = {action.intention}
                                                        leadershipCategories={this.props.leadershipCategories}
                                                        leadershipQualities={this.props.leadershipQualities}
                                                        rerender={this.state.isVisible || false}
                                                    />
                                                }
                                                </div>
                                            </VizSensor> 
                                        }
                                    </div>
                                }
                                {/***
                                <div style={{ paddingTop: 20 }}>
                                    {(  action.done || action.onTrack || action.offTrack || action.important || action.urgent || action.failed
                                        || (action.activityArchive !== undefined && action.activityArchive !== null && action.activityArchive.length>0))
                                        ?
                                            <Button onClick={() => this.actionSetHide()}  variant="outlined" color="secondary">
                                                Remove & Stash&nbsp;<LowPriorityIcon fontSize="small"/>
                                            </Button>
                                        :
                                            <Button onClick={() => this.actionSetDelete()}  variant="outlined" color="secondary">
                                                Delete Action & Log&nbsp;<DeleteForeverIcon fontSize="small"/>
                                            </Button>
                                    }
                                </div>
                                 */}
                                <div style={theme.divCenterPadding}>
                                    <span style={theme.CardContentItem.TextTitle}> . . .</span>
                                </div>
                                {this.props.showDiary && this.state.originalDate!== undefined && this.state.originalDate!== null  && this.props.specialQuery !== 'contentByIds' &&
                                    <JournalList historyLimit={1} messageWeek={this.getDateFormatSat(this.state.originalDate.seconds * 1000)}  />
                                }
                                
                            </DialogContent>
                        }
                        {/***
                        <DialogActions style={theme.dialogAction}>
                            {this.state.action.done 
                                ? ""
                                :
                                    <Grid container spacing={0} direction="row"
                                        justifyContent="space-between"
                                        alignItems="center">
                                        <Grid item >
                                            <Button variant="contained"  onClick={() => {this.setState({openAlert: true})}}>
                                                Add Calendar&nbsp;<AddAlertIcon fontSize='small'/>
                                            </Button>
                                        </Grid>
                                        <Grid item >
                                        </Grid>
                                        <Grid item >
                                        </Grid>
                                    </Grid>
                            }
                        </DialogActions>
                         */}
                    </Dialog>
                    </RemoveScroll>  
                    <RemoveScroll enabled={this.state.openAlert ||false}>
                        <Dialog
                            key={this.state.actionId}
                            open={this.state.openAlert}
                            onClose={() => this.setState({openAlert: false})}
                            //fullScreen={IsMobileDevice()}
                            //PaperProps={{style:theme.dialogWindow}}
                            PaperProps={{style:theme.dialogPopUp}}
                        >
                            <DialogTitle style={{...theme.dialogTitle, background}}  >
                                <Grid container spacing={0} direction="row"
                                    justifyContent="space-between"
                                    alignItems="center">
                                    <Grid item >
                                        <IconButton onClick={() =>  {this.setState({openAlert: false})} }>
                                            <ArrowBackIcon />
                                        </IconButton>
                                    </Grid>
                                    <Grid item>
                                        <span style={theme.dialogTitle.titleText}> 
                                            <LanguageManager
                                                text={"Add personal alert"} 
                                                type='button'
                                                language={this.context?.features?.language}
                                            />
                                        </span>
                                    </Grid>
                                    <Grid item>
                                        <IconButton style={{marginRight: 2}} onClick={() => {this.setState({handleOpenTutorial: true})}}>
                                            <HelpOutlineIcon />
                                        </IconButton>
                                        {/**
                                        <span style={theme.dialogTitle.titleLink} onClick={() =>  {this.setState({openAlert: false})} }>
                                            {this.state.alertSet? "Done" : "Cancel"}
                                        </span>
                                        */}
                                    </Grid>
                                </Grid>
                            </DialogTitle>
                            {this.state.openAlert &&
                                <DialogContent style={{...theme.dialogContent}}>
                                    <CardAlert 
                                        userId={this.context.uid} 
                                        user={this.context}
                                        alertSet={(value) => {
                                            this.setState({alertSet:value})
                                            //console.log('alert messabe', value)
                                        }}
                                        urgent={this.state.addUrgency || false}
                                        addCalendar={true}
                                        senderName={this.context.username}
                                        preMessage={this.state.intention!==undefined && this.state.intention!==""
                                            ?   this.state.intention
                                            :   this.state.message
                                        } 
                                        preContext={this.state.addUrgency
                                            ?   'Important and urgent moment'
                                            :   'Remember to work on your Moment'
                                        } 
                                    />
                                </DialogContent>
                            }
                            {/***
                            <DialogActions style={theme.dialogAction}>
                                <Button onClick={() =>  {this.setState({openAlert: false})}}>
                                    Cancel
                                </Button>
                            </DialogActions>
                             */}
                        </Dialog>
                    </RemoveScroll>
                    <RemoveScroll enabled={this.state.openArchive ||false}>
                        <Dialog
                            key={this.state.actionId}
                            open={this.state.openArchive || false}
                            onClose={() => this.setState({openArchive: false})}
                            //fullScreen={IsMobileDevice()}
                            //PaperProps={{style:theme.dialogWindow}}
                            PaperProps={{style: theme.dialogPopUp}}
                        >
                            <DialogTitle style={{...theme.dialogTitle, background}}  >
                                <Grid container spacing={0} direction="row"
                                    justifyContent="space-between"
                                    alignItems="center">
                                    <Grid item >
                                        <IconButton onClick={() =>  {this.setState({openArchive: false})} }>
                                            <ArrowBackIcon />
                                        </IconButton>
                                    </Grid>
                                    <Grid item>
                                        <span style={theme.dialogTitle.titleText}>
                                            <LanguageManager
                                                text={"Manage Goals"} 
                                                type='button'
                                                language={this.context?.features?.language}
                                            />
                                        </span>
                                    </Grid>
                                    <Grid item>
                                        <IconButton style={{marginRight: 2}} onClick={() => {this.setState({handleOpenTutorial: true})}}>
                                            <HelpOutlineIcon />
                                        </IconButton>
                                        {/***
                                        <span style={theme.dialogTitle.titleLink} onClick={() =>  {this.setState({openArchive: false})} }>
                                            {this.state.alertSet? "Done" : "Cancel"}
                                        </span>
                                         */}
                                    </Grid>
                                </Grid>
                            </DialogTitle>
                            {this.state.openArchive
                                ?
                                    this.state.addDelegate
                                    ?
                                        <DialogContent style={{...theme.dialogContent}}>
                                            <div style={{textAlign: 'center'}}>
                                                <p>
                                                    <LanguageManager
                                                        text={"Non-important but urgent tasks can often be delegated."} 
                                                        type='tutorial'
                                                        language={this.context?.features?.language}
                                                    />
                                                </p>
                                                <p style={theme.textSupport}>
                                                    <LanguageManager
                                                        text={"Wouldn't it be great if you sent yourself and another person an email message as a reminder?"} 
                                                        type='tutorial'
                                                        language={this.context?.features?.language}
                                                    />
                                                </p>
                                            </div>
                                            <div style={{textAlign: 'right'}}>
                                                <Button onClick={() => {
                                                        var link = 'mailto:'+(this.context?.email || this.context?.username);
                                                        link = link + '?subject='+this.state.intention
                                                        link = link + '&body='+this.state.intention
                                                        window.open(link, "_blank");
                                                    }} 
                                                    variant="outlined" color="secondary">
                                                    <EmailIcon fontSize="small"/>
                                                    &nbsp;
                                                    <LanguageManager
                                                        text={"Send Email"} 
                                                        type='button'
                                                        language={this.context?.features?.language}
                                                    />
                                                </Button>
                                            </div>
                                            <p style={theme.textSupportCenter}>
                                                <br/><br/>
                                            </p>
                                        </DialogContent>
                                    :
                                        <DialogContent style={{...theme.dialogContent}}>
                                            <div style={{textAlign: 'center'}}>
                                                <p>
                                                    <LanguageManager
                                                        text={"In most cases, you can remove goals that are not urgent or important from your priority list."} 
                                                        type='tutorial'
                                                        language={this.context?.features?.language}
                                                    />
                                                </p>
                                                <p style={theme.textSupport}>
                                                    <LanguageManager
                                                        text={"When you click 'Stash', this goal will be archived and removed from the active list."} 
                                                        type='tutorial'
                                                        language={this.context?.features?.language}
                                                    />
                                                </p>
                                            </div>
                                            <div style={{textAlign: 'right'}}>
                                                <Button onClick={() => this.actionSetHide()} variant="outlined" color="secondary">
                                                    <LowPriorityIcon fontSize="small"/>
                                                    &nbsp;
                                                    <LanguageManager
                                                        text={"Stash"} 
                                                        type='button'
                                                        language={this.context?.features?.language}
                                                    />
                                                </Button>
                                            </div>
                                            <p style={theme.textSupportCenter}>
                                                <br/><br/>
                                                <LanguageManager
                                                    text={"Don't worry, your AI Coach will bring this goal up again if necessary."} 
                                                    type='tutorial'
                                                    language={this.context?.features?.language}
                                                />
                                            </p>
                                        </DialogContent>
                                :   ''
                            }
                        </Dialog>
                    </RemoveScroll>
                </div>
            )  
        }
        else{
            return(
                <div>
                    <LanguageManager
                        text={"Chat with your 'AI Coach' to create new goals."} 
                        type='tutorial'
                        language={this.context?.features?.language}
                    />
                </div>
            )
        }
    }

    returnCheckSection(backgroundColor, date, action, dialog=false) {
        const {theme} = this.props;
        var questionTitle = 'Key thoughts';
        var resolvmentPercentage = 30;
        if(action.done ) resolvmentPercentage = 0;
        else if(action.failed ) resolvmentPercentage = 100;
        else if(action.value > 5 && action.value < 95 ) resolvmentPercentage = 100-action.value ;
        else if(action.onTrack ) resolvmentPercentage = 50;
        else if(action.offTrack ) resolvmentPercentage = 80;

        if(action.questionTitle!== undefined && !action.questionTitle.includes('_')){
            questionTitle = action.questionTitle;
            if(action.questionTitle.length>20){
                questionTitle = action.questionTitle.substring(0,19)+"...";
            }
        }
        //console.log('resolvmentPercentage', resolvmentPercentage)
        return(
            <Grid container 
                spacing= {1}
                direction= "row"
                justifyContent= "space-between"
                alignItems="center" 
                style= {{
                    marginTop: 0,
                    marginBottom: 0,
                    minHeight: 80,
                    background : this.state['hover'+action.messageId ]
                        ?   theme.palette.themeAltGold
                        :   ('linear-gradient(to right, '+backgroundColor+' '+(100-resolvmentPercentage-15)+'%, '+theme.palette.backPrimary+' '+(100-resolvmentPercentage+5)+'%)'),
                }}
                onMouseEnter={(this.state['hover'+action.messageId ] !== true) ? () => {this.setState({['hover'+action.messageId ]: true})} : void(0)}
                onMouseLeave={(this.state['hover'+action.messageId ] === true) ? () => {this.setState({['hover'+action.messageId ]: false})} : void(0)}
            >

                {this.props.subItemView !== true &&
                    <Grid item xs={2}>
                        <div style={{visibility: this.state.swiping ? 'hidden' : 'visible', borderRadius: 16, overflow: 'hidden'}}>
                            <AvatarImage 
                                type='category'
                                rounded={true}
                                id={action.messageId}
                                key={action.messageId}
                                vertical-align="middle" 
                                item={action.leadership_category} 
                                squareSize={"auto"} 
                                backgroundColor={this.state['hover'+action.messageId ] ? backgroundColor : 'transparent'}
                                dict={this.props.leadershipCategories}
                            />
                        </div>
                    </Grid>
                }
                <Grid item xs={this.props.subItemView !== true ? 8 : 10} 
                    style={{
                        paddingLeft: this.props.subItemView !== true ? 0 : 30,
                        textAlign: 'center'
                    }}>
                    <span 
                        style={{
                            color: theme.palette.darkMode ? theme.palette.themeAltGold : theme.palette.themeDarkBlue, 
                            textShadow: theme.palette.darkMode
                                ? " 0.07em 0 black,  0 0.07em black, -0.07em 0 black, 0 -0.07em black"
                                : " 0.07em 0 "+theme.palette.themeLightGrey+",  0 0.07em "+theme.palette.themeLightGrey+", -0.07em 0 "+theme.palette.themeLightGrey+", 0 -0.07em "+theme.palette.themeLightGrey+""
                        }}
                    >
                        <strong>
                        {!(action?.baseLanguage?.length > 0 && action.baseLanguage === this.context?.features?.language)
                            ?
                                <LanguageManager
                                    text={action.intention}
                                    limit={49}
                                    type='text'
                                    forceEnglish={action?.baseLanguage?.length > 0 && !(action?.baseLanguage?.includes('en'))}
                                    language={this.context?.features?.language}
                                    handlerUpdate={(text) => this.setState({textIntention: text})}
                                />
                            :   action?.intention?.length > 49
                                ?   action.intention.substring(0, 49) + "..."
                                :   action.intention
                        }
                        </strong>
                    </span>
                </Grid>
                <Grid item xs={2} style={{textAlign: 'center', visibility: this.state.swiping ? 'hidden' : 'visible'}}>
                    {(action.done)
                        ?
                            <EmojiEventsIcon fontSize="large" style={{color: "gold"}} />
                        :
                            this.state.upToDate
                                ?
                                    action.onTrack
                                    ?  <CheckCircleIcon fontSize="large" style={{color: "green"}}/>
                                    :   action.offTrack
                                        ?   <SentimentVeryDissatisfiedIcon fontSize="large" style={{color: ((action.urgent || action.important) ? "red" : "orange")}}/>
                                        :   <ControlPointRoundedIcon fontSize="large" style={{color: ((action.urgent || action.important) ? "red" : "green")}}/>
                                :
                                    <RadioButtonUncheckedIcon fontSize="large" style={{color: ((action.urgent || action.important) ? "red" : "grey")}}/>
                                
                    }
                </Grid>
            </Grid>
        )
    }

    returnLinkSection (date, action, dialog=false) {
        return(
            <div onClick={() => this.handleOpen()}>
                <Grid container spacing={1} >
                    <Grid item xs={10}>
                        { this.showStatus() }
                    </Grid>
                    { this.state.open 
                        ? ""
                        :   <Grid item xs={2}>

                                <div style={{fontSize:"small", color:'grey', textAlign:'center'}} >
                                    <IconButton color="secondary" style={{padding: 2}}>
                                        <EditIcon fontSize="small" style={{color: "grey"}}/>
                                    </IconButton>
                                    <br/>Details 
                                </div>
                            </Grid>
                    }
                </Grid>
            </div>
        )
    }

    returnMainSection (date, action, dialog=false) {
        const {theme} = this.props;
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
        return(
            <Grid container spacing={1} >
                <Grid item xs={12}>
                {dialog
                    ?
                        this.props.showStatus
                        ?
                            <Grid container spacing={2} direction='row' justifyContent="space-between" >
                                <Grid item >
                                    <DateBadge showDays={true} date={date} valueMonth={months[date.getMonth()]} valueDay={date.getDate()}/> 
                                </Grid>
                                <Grid item > </Grid>
                                <Grid item>
                                    { this.showStatus() }
                                </Grid>
                            </Grid>
                        :
                            ''
                    :
                        <Grid container spacing={2} direction='row' justifyContent="space-between" onClick={this.handleOpen}>
                            <Grid item >
                                <DateBadge showDays={true} date={date} valueMonth={months[date.getMonth()]} valueDay={date.getDate()}/> 
                            </Grid>
                            <Grid item>
                                { this.showStatus() }
                            </Grid>
                            <Grid item>
                                <div style={{fontSize:"small", color:'grey', textAlign:'center'}} >
                                    <IconButton color="secondary" style={{padding: 2}}>
                                        <EditIcon fontSize="small" style={{color: "grey"}}/>
                                    </IconButton>
                                    <br/>
                                    <LanguageManager
                                        text={"Details"} 
                                        type='button'
                                        language={this.context?.features?.language}
                                    /> 
                                </div>
                            </Grid>
                        </Grid>
                }
                </Grid> 
                {dialog && this.props.showPrioSwitch
                    &&
                    <>
                        <Grid item xs={12}>
                            <Grid container spacing={2} direction='row' justifyContent="space-between" >
                                <Grid item > </Grid>
                                <Grid item > </Grid>
                                <Grid item>
                                    {/***
                                    <Button onClick={() => this.actionSetImportant()}>
                                        Important&nbsp;<FormatListNumberedIcon fontSize="small"/>
                                    </Button> */}
                                    {this.state.action.important? 'Important' : 'Not important'} &nbsp;
                                    <Switch
                                        checked={this.state.action.important || false}
                                        onChange={(e) => this.actionSetImportant(e.target.checked)}
                                        name="Important"
                                        inputProps={{ 'aria-label': 'Set to important' }}
                                    />   
                                    <FormatListNumberedIcon style={{verticalAlign:'middle'}} fontSize="small"/>                                       
                                </Grid>
                            </Grid>
                        </Grid> 
                        <Grid item xs={12}>
                            <Grid container spacing={2} direction='row' justifyContent="space-between" >
                                <Grid item ></Grid>
                                <Grid item > </Grid>
                                <Grid item>
                                    {/**** <Button  onClick={() => this.actionSetUrgent()}>
                                        Urgent&nbsp;<WarningIcon fontSize="small"/>
                                    </Button>*/}
                                    {this.state.action.urgent? 'Urgent' : 'Not urgent'} &nbsp;
                                    <Switch
                                        checked={this.state.action.urgent || false}
                                        onChange={(e) => this.actionSetUrgent(e.target.checked)}
                                        name="Urgent"
                                        inputProps={{ 'aria-label': 'Set to urgent' }}
                                    />  
                                    <WarningIcon fontSize="small" style={{verticalAlign:'middle'}}/>
                                    <br/><br/>
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                }
                {dialog
                    &&
                    <>
                        <Grid item xs={12}>
                            {(action.leadership_category?.length>1 && this.state.avatarCategorySelection !== true)
                                ?   
                                    <div
                                        onClick={() => {
                                            this.setState({avatarCategorySelection: true})
                                            //console.log('clicked to change')
                                        }}
                                    >
                                        <AvatarCard 
                                            key={action.leadership_category} 
                                            dict={this.props.leadershipCategories}
                                            type='category' 
                                            item={action.leadership_category} 
                                            squareSize='20' 
                                        />
                                    </div>
                                :
                                    <div>
                                        <div style={theme.textSupportCenter}>
                                            <InfoIcon style={theme.inlineIconLeft}/>Assign this goal to a related pillar.
                                        </div>
                                        {this.props.topicSelectRadio
                                            ?
                                                <FormControl component="fieldset">
                                                    <RadioGroup row aria-label="position" name="position" 
                                                        onChange={(event) => { 
                                                            this.props.firebase.journeyFollowUpAction(this.state.actionId).update({leadership_category: event.target.value});
                                                            if(this._isMounted){
                                                                this.setState({avatarCategorySelection: false, action: {...this.state.action, leadership_category: event.target.value}});
                                                            }
                                                        }}
                                                    >
                                                        {(action.leadership_category?.length>1 && !['confidence', 'priority', 'people', 'clarity', 'communication', 'balance'].includes(action.leadership_category) )
                                                        &&
                                                            <FormControlLabel
                                                                key={'_raw'}
                                                                value={action.leadership_category}
                                                                style={{margin: 0}} 
                                                                checked={true}
                                                                control={<Radio color="secondary" />}
                                                                label={<AvatarImage 
                                                                    type='category' 
                                                                    item={action.leadership_category} 
                                                                    squareSize='4vh'
                                                                    dict={this.props.leadershipCategories}
                                                                    backgroundColor={FindTextValueInDict(this.props.firebase, this.props.leadershipCategories, action.leadership_category, 'backgroundColor') || theme.palette.themeNaviGrey}
                                                                />}
                                                                labelPlacement="top"
                                                            />
                                                        }
                                                        {['confidence', 'priority', 'people', 'clarity', 'communication', 'balance'].map(( item, index) => (
                                                            <FormControlLabel
                                                                key={item+'_'+index}
                                                                value={item}
                                                                style={{margin: 0}} 
                                                                checked={action.leadership_category === item}
                                                                control={<Radio color="secondary" />}
                                                                label={<AvatarImage 
                                                                    type='category' 
                                                                    item={item} 
                                                                    squareSize='4vh'
                                                                    dict={this.props.leadershipCategories}
                                                                    backgroundColor={FindTextValueInDict(this.props.firebase, this.props.leadershipCategories, item, 'backgroundColor') || theme.palette.themeNaviGrey}
                                                                />}
                                                                labelPlacement="top"
                                                            />
                                                        ))}
                                                    </RadioGroup>
                                                </FormControl>
                                            :
                                                <FormControl>
                                                    <SelectWrapper
                                                        labelId="demo-simple-select-helper-label"
                                                        id="demo-simple-select-helper"
                                                        value={action.leadership_category || 'none'}
                                                        disableUnderline
                                                        onChange={(event) => { 
                                                            this.props.firebase.journeyFollowUpAction(this.state.actionId).update({leadership_category: event.target.value});
                                                            if(this._isMounted){
                                                                this.setState({avatarCategorySelection: false, action: {...this.state.action, leadership_category: event.target.value}});
                                                            }
                                                        }} 
                                                    >
                                                        {(action.leadership_category?.length>1 && !['confidence', 'priority', 'people', 'clarity', 'communication', 'balance'].includes(action.leadership_category) )
                                                        &&
                                                            <SelectOption
                                                                key={'_raw'}
                                                                value={action.leadership_category}
                                                                style={{marginBottom: -10}}
                                                            >
                                                                <AvatarCard 
                                                                    type='category' 
                                                                    item={action.leadership_category} 
                                                                    squareSize='4vh'
                                                                    dict={this.props.leadershipCategories}
                                                                    backgroundColor={FindTextValueInDict(this.props.firebase, this.props.leadershipCategories, action.leadership_category, 'backgroundColor') || theme.palette.themeNaviGrey}
                                                                />
                                                            </SelectOption>
                                                        }
                                                        {['confidence', 'priority', 'people', 'clarity', 'communication', 'balance'].map(( item, index) => (
                                                            <SelectOption
                                                                key={item+'_'+index}
                                                                value={item}
                                                                style={{marginBottom: -10}}
                                                            >
                                                                <AvatarCard 
                                                                    style={{marginBottom: 0}}
                                                                    type='category' 
                                                                    item={item} 
                                                                    squareSize='4vh'
                                                                    dict={this.props.leadershipCategories}
                                                                    backgroundColor={FindTextValueInDict(this.props.firebase, this.props.leadershipCategories, item, 'backgroundColor') || theme.palette.themeNaviGrey}
                                                                />
                                                            </SelectOption>
                                                        ))}
                                                    </SelectWrapper>
                                                </FormControl>
                                        }
                                    </div>
                            }
                        </Grid>
                    </>
                }
                {dialog
                    &&
                    <>
                        {(this.state.editIntention)
                            &&
                            <>
                                <Grid item xs={12}>
                                    {(this.state.editIntention || !action.intentionApproved)
                                        &&
                                            <TextField
                                                //autoFocus
                                                id={this.state.actionId+(dialog?'dialog':'normal')}
                                                style={ {fontSize: '16px'} }
                                                multiline
                                                value={this.state.intention} 
                                                variant='outlined'
                                                onChange={(event) => {
                                                    var text = event.target.value;
                                                    if(text.length > 79){
                                                        text = text.substring(0, 75) + "...";
                                                    }
                                                    this.setState({intention : text })} 
                                                }
                                                fullWidth
                                                InputProps={{
                                                    style:{fontSize: '16px'},
                                                    endAdornment: (
                                                        <>
                                                        {action.intention === this.state.intention
                                                        ?   ""
                                                        :
                                                            action.intentionApproved
                                                                ?
                                                                    <div style={{fontSize:"small", color: theme.palette.themeRed, textAlign:'center'}} onClick={() => (this.actionChangeIntention(this.state.intention))}>
                                                                        <IconButton fontSize="small">
                                                                            <SaveIcon fontSize="small" />
                                                                        </IconButton>
                                                                        <br/>Change
                                                                    </div>
                                                                :
                                                                    <div style={{fontSize:"small", color: theme.palette.themeRed, textAlign:'center'}} onClick={() => (this.actionChangeIntention(this.state.intention))}>
                                                                        <IconButton fontSize="small">
                                                                            <CheckCircleIcon fontSize="small" style={{color:theme.palette.themeRed}}/>
                                                                        </IconButton>
                                                                        <br/>Update
                                                                    </div>
                                                        }
                                                        </>
                                                    ),
                                                }}
                                            />
                                    }
                                </Grid>
                                {!action.intentionApproved &&
                                    <Grid item xs={12}>
                                        <p style={theme.textSupportCenter}>
                                            <InfoIcon style={theme.inlineIconLeft}/>
                                            <LanguageManager
                                                text={"AI generated goal title."} 
                                                type='tutorial'
                                                language={this.context?.features?.language}
                                            /> 
                                        </p>
                                    </Grid>
                                }
                            </>
                        } 
                    </>
                }
                {dialog && !this.state.editIntention
                    &&
                        <>
                            <Grid item xs={12}>
                                <BadgeCard
                                    backgroundColor ={"white"}
                                    image={undefined} 
                                    avatarImage = { <FilterCenterFocusIcon style={{ height: '80%', width: '80%', color: theme.palette.secondary.main}} />}
                                    title = {undefined}
                                    limit = {79}
                                    subtitle = {action.intention}
                                    strongSubtitle = {false}
                                    onClick={() => {this.setState({editIntention:true})}}
                                    clickText= {'Change'}
                                    button={undefined}
                                />
                            </Grid>
                            {!action.intentionApproved &&
                                <Grid item xs={12}>
                                    <p style={theme.textSupportCenter}>
                                        <InfoIcon style={theme.inlineIconLeft}/>
                                        <LanguageManager
                                            text={"AI generated goal title."} 
                                            type='tutorial'
                                            language={this.context?.features?.language}
                                        /> 
                                    </p>
                                </Grid>
                            }
                        </>
                }
                {!dialog
                    &&
                        <Grid item xs={12}>
                            <strong>
                                {action.intention && action.intention.length > 79
                                    ?
                                        <span>{action.intention.substring(0, 79) + "..."}</span>
                                    :
                                        <span>{action.intention}</span>
                                }
                            </strong>
                        </Grid>
                }
                {dialog && this.props.addSingleEvent &&
                    <Grid item xs={12}>
                        <span style={theme.cardJournalDayHighlight.iconDesign}>{action.question? ('AI Coach: '+action.question) : 'Last comment or intention:'}</span>
                        <Grid item xs={12} style={{paddingTop: 10}}>
                            <TextField
                                name="comment"
                                style={ {fontSize: '16px'} }
                                //label="Add a new status update"
                                value={this.state.newComment}
                                onChange={(e) => {this.setState({newComment: e.target.value})}}
                                type="text"
                                variant="outlined"
                                placeholder="My comment..."
                                fullWidth
                                multiline
                                InputProps={{
                                    style:{fontSize: '16px'},
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                edge="end"
                                                aria-label="Send message"
                                                onClick={() => (this.actionChangeComment(this.state.newComment))}
                                            >
                                                <SendIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ), 
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SpeechToTextButton 
                                                renderKey={'journal_'}
                                                returnText={(result) => this.setState({newComment : result})}
                                                existingText={this.state.newComment || ""}
                                            />
                                        </InputAdornment>
                                    ),
                                }}
                            />    
                        </Grid>
                    </Grid>
                }
                {dialog && this.props.showMessage &&
                    <React.Fragment>
                        <Grid item xs={10}>
                            <span style={theme.cardJournalDayHighlight.iconDesign}>Me:</span>
                            <br/>
                            {this.state.editMessages && dialog
                                ?
                                    <form>
                                        <TextField
                                            //autoFocus
                                            id={this.state.actionId+(dialog?'dialog':'normal')}
                                            style={ {fontSize: '16px'} }
                                            multiline
                                            InputProps={{
                                                style:{fontSize: '16px'}
                                            }}
                                            value={this.state.message} 
                                            variant='outlined'
                                            onChange={(event) => {this.setState({message : event.target.value })} }
                                            fullWidth
                                        />
                                    </form> 
                                : 
                                    action.message && action.message.length > 99
                                    ?
                                        <q style={theme.textSupport}><strong>{action.message.substring(0, 79) + "..."}</strong></q>
                                    :
                                        <q style={theme.textSupport}><strong>{action.message}</strong></q>
                            }
                        </Grid>
                        <Grid item xs={2}> 
                            {this.state.editMessages 
                                ?  
                                    <IconButton fontSize="small" onClick={() => (this.actionChangeComment(this.state.message))}>
                                        <SaveIcon fontSize="small"/>
                                    </IconButton>
                                :  
                                    <IconButton fontSize="small" onClick={() => {this.setState({editMessages:true})}}>
                                        <EditIcon fontSize="small" />
                                    </IconButton>
                            }
                        </Grid>
                    </React.Fragment>
                }
                {dialog && this.props.showButtons
                    &&
                        <Grid item xs={12}>
                            <Grid item xs={12}>
                                <Grid container spacing={2} direction='row' justifyContent="space-between" alignItems="center"> 
                                    <Grid item xs={4}>
                                        {action.done
                                            ?
                                                <div style={{textAlign:'center'}}>
                                                    <EmojiEventsIcon fontSize="large" style={{color: "gold"}} />
                                                    <br/>
                                                    <strong style={{color:'green'}}>Done</strong>
                                                </div>
                                            :
                                                <div style={{fontSize:"small", color:'grey', textAlign:'center'}} onClick={() => this.actionSetDone()}>
                                                    <IconButton color="secondary" style={{padding: 2}}>
                                                        <EmojiEventsIcon fontSize="small" style={{color: "grey"}}/>
                                                    </IconButton>
                                                    <br/>&nbsp;Done&nbsp;
                                                </div>
                                        }
                                    </Grid>
                                    <Grid item xs={4}>
                                        {this.state.upToDate && action.offTrack
                                            ?
                                                <div style={{textAlign:'center'}}>
                                                    <strong style={{color: theme.palette.themeRed}}>To Improve</strong>
                                                </div>
                                            :
                                                <div style={{fontSize:"small", color: theme.palette.themeRed, textAlign:'center'}}  onClick={() => this.actionSetOffTrack(undefined, true)}>
                                                    <IconButton color="primary" style={{padding: 2}}>
                                                        <ThumbDownIcon fontSize="small" style={{color: theme.palette.themeRed}}/>
                                                    </IconButton>
                                                    <br/>To&nbsp;Improve
                                                </div>
                                        }
                                    </Grid>
                                    <Grid item xs={4}>
                                        {this.state.upToDate && action.onTrack
                                            ?
                                                <div style={{textAlign:'center'}}>
                                                    <strong style={{color:'green'}}>On Track</strong>
                                                </div>
                                            :
                                                <div style={{fontSize:"small", color: theme.palette.themeRed, textAlign:'center'}} onClick={() => this.actionSetOnTrack(undefined, true)}>
                                                    <IconButton color="primary" style={{padding: 2}}>
                                                        <CheckCircleOutlineIcon  style={{color: theme.palette.themeRed}}/>
                                                    </IconButton>
                                                    <br/>On&nbsp;Track
                                                </div>
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>   
                        </Grid>
                }
                <Grid item xs={12}>
                    { dialog
                        ?
                            <Grid container spacing={2} direction='column' justifyContent="space-between" >
                                <Grid item >
                                    {/**
                                    <Button onClick={() => this.actionSetFailed()}>
                                        I failed this activity.&nbsp;<SmsFailedIcon fontSize="small"/>
                                    </Button>
                                        */}
                                </Grid>
                                <Grid item>
                                    {/**
                                    <Button  onClick={() => this.actionSetDone()}>
                                        I finished this activty.&nbsp;<CheckCircleIcon fontSize="small"/>
                                    </Button>
                                        */}
                                </Grid>
                            </Grid>  
                            
                        :
                            <Grid item xs={12}>
                                {/***
                                <Grid container spacing={0} direction='row' justifyContent="space-between" >
                                    <Grid item >
                                    {(action.done || action.onTrack || action.offTrack || action.important || action.urgent || action.failed
                                        || (action.activityArchive !== undefined && action.activityArchive !== null && action.activityArchive.length>0))
                                        ?
                                            <Button onClick={() => this.actionSetHide()}  variant="outlined" color="secondary">
                                                Stash&nbsp;<LowPriorityIcon fontSize="small"/>
                                            </Button>
                                        :
                                            <Button variant="outlined" color="secondary" onClick={() => this.actionSetDelete()}>
                                                Delete&nbsp;<DeleteForeverIcon fontSize="small"/>
                                            </Button>
                                    }
                                    </Grid>
                                    <Grid item > </Grid>
                                    <Grid item>
                                        <Button onClick={this.handleOpen}>
                                            Follow-up&nbsp;<EditIcon fontSize="small"/>
                                        </Button>
                                    </Grid>
                                </Grid>
                                */}
                                <Grid container spacing={0} direction='row' justifyContent="space-between" >
                                    <Grid item >
                                        {(action.done || action.onTrack || action.offTrack || action.important || action.urgent || action.failed
                                            || (action.activityArchive !== undefined && action.activityArchive !== null && action.activityArchive.length>0))
                                            ?
                                                this.state.upToDate
                                                ?
                                                    <div style={{fontSize:"small", color:'grey', textAlign:'center'}} onClick={() => this.actionSetHide()}>
                                                        <IconButton color="secondary" style={{padding: 2}}>
                                                            <LowPriorityIcon fontSize="small" style={{color: "grey"}}/>
                                                        </IconButton>
                                                        <br/>Stash 
                                                    </div>
                                                :
                                                    <div style={{fontSize:"small", color:'grey', textAlign:'center'}} onClick={() => this.actionSetDone()}>
                                                        <IconButton color="secondary" style={{padding: 2}}>
                                                            <EmojiEventsIcon fontSize="small" style={{color: "grey"}}/>
                                                        </IconButton>
                                                        <br/>&nbsp;Done&nbsp;
                                                    </div>
                                            :
                                                <div style={{fontSize:"small", color:'grey', textAlign:'center'}}  onClick={() => this.actionSetDelete()}>
                                                    <IconButton color="secondary" style={{padding: 2}}>
                                                        <DeleteForeverIcon fontSize="small" style={{color: "grey"}}/>
                                                    </IconButton>
                                                    <br/>Delete
                                                </div>
                                        }
                                    </Grid>
                                    <Grid item>
                                        {((this.state.upToDate  && (action.onTrack ||  action.offTrack)) || action.done)
                                            ?
                                                ""
                                            :
                                                <div style={{fontSize:"small", color: theme.palette.themeRed, textAlign:'center'}}  onClick={() => this.actionSetOffTrack(true)}>
                                                    <IconButton color="primary" style={{padding: 2}}>
                                                        <ThumbDownIcon fontSize="small" style={{color: theme.palette.themeRed}}/>
                                                    </IconButton>
                                                    <br/>Off&nbsp;Track
                                                </div>
                                        }
                                    </Grid>
                                    <Grid item>
                                        {action.done
                                            ?
                                                <EmojiEventsIcon fontSize="large" style={{color: "gold"}} />
                                            :
                                                this.state.upToDate && (action.onTrack ||  action.offTrack)
                                                    ?
                                                        action.onTrack
                                                        ?  <CheckCircleIcon fontSize="large" style={{color: "green"}} onClick={this.moveNext}/>
                                                        :   action.offTrack
                                                            ?   <SentimentVeryDissatisfiedIcon fontSize="large" style={{color: "orange"}} onClick={this.moveNext}/>
                                                            :   ""
                                                    :
                                                        <div style={{fontSize:"small", color: theme.palette.themeRed, textAlign:'center'}} onClick={() => this.actionSetOnTrack(undefined, true)}>
                                                            <IconButton color="primary" style={{padding: 2}}>
                                                                <CheckCircleOutlineIcon  style={{color: theme.palette.themeRed}}/>
                                                            </IconButton>
                                                            <br/>On&nbsp;Track
                                                        </div>
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                    }
                </Grid>
            </Grid>
        )
    }

    returnPriorityMatrix (action) {
        const {theme} = this.props;
        if(action !== undefined){
            return(
                <div>
                    {(this.state.prioritiesExpanded !== true && !action.important)
                    &&
                        <div style={{wdith: '100%'}}>
                            <h4>
                                <LanguageManager
                                    text={"Priority Management"} 
                                    type='button'
                                    language={this.context?.features?.language}
                                />
                            </h4>
                            <Grid container spacing={0} direction='row' justifyContent="space-between" style={{marginTop: 20, marginBottom: 20}}>
                                <Grid item xs={4} style={{textAlign: 'center'}}>
                                    <div onClick={() => this.setState({openArchive: true, addDelegate: false})}>
                                        <IconButton>
                                            <LowPriorityIcon fontSize="large" style={{color: theme.palette.secondary.main}}/>
                                        </IconButton>
                                        <br/>
                                        <strong style={{color: theme.palette.secondary.main}}>
                                            <LanguageManager
                                                text={"Stash"} 
                                                type='button'
                                                language={this.context?.features?.language}
                                            />
                                            <br/>
                                        </strong>
                                    </div>
                                </Grid>
                                <Grid item xs={4} style={{textAlign: 'center'}}>
                                    <div onClick={() => this.setState({prioritiesExpanded: true})}>
                                        <IconButton>
                                            <MoveToInboxIcon fontSize="large" style={{color: theme.palette.secondary.main}}/>
                                        </IconButton>
                                        <br/>
                                        <strong style={{color: theme.palette.secondary.main}}>{action.important? 'Change' : 'Set'}&nbsp;Prio</strong>
                                    </div>
                                </Grid>
                                <Grid item xs={4} style={{textAlign: 'center'}}>
                                    <div onClick={() => this.setState({openAlert: true, addUrgency: false})}>
                                        <IconButton>
                                            <EventIcon fontSize="large" style={{color: theme.palette.secondary.main}}/>
                                        </IconButton>
                                        <br/>
                                        <strong style={{color: theme.palette.secondary.main}}>
                                            <LanguageManager
                                                text={"Reminder"} 
                                                type='button'
                                                language={this.context?.features?.language}
                                            />
                                            <br/>
                                        </strong>
                                    </div>
                                </Grid>
                            </Grid>
                            <p style={theme.textSupportCenter}> 
                                <LanguageManager
                                    text={"Set Importance"} 
                                    type='button'
                                    language={this.context?.features?.language}
                                />
                                &nbsp;&bull;&nbsp;
                                <LanguageManager
                                    text={"Add Reminder"} 
                                    type='button'
                                    language={this.context?.features?.language}
                                />
                            </p>
                        </div>
                    }
                    {(this.state.prioritiesExpanded === true || action.important)
                        &&
                        <div style={{wdith: '100%'}}>
                            <h4>
                                <LanguageManager
                                    text={"Priority Matrix"} 
                                    type='button'
                                    language={this.context?.features?.language}
                                />
                            </h4>
                            <Grid container spacing={0} direction="row"
                                justifyContent="center"
                                alignItems="center"
                                >
                                <Grid item xs={1}> &nbsp;</Grid>
                                <Grid item xs={5} style={{textAlign: 'center'}}>
                                    <div style={{
                                        textAlign: 'center',
                                        color: action.urgent ? theme.palette.themeGrey : theme.palette.themeRed
                                    }}>
                                        <LanguageManager
                                            text={"Has Time"} 
                                            type='button'
                                            language={this.context?.features?.language}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={5} style={{ textAlign: 'center'}}>
                                    <div style={{
                                        textAlign: 'center',
                                        color: action.urgent ? theme.palette.themeRed : theme.palette.themeGrey
                                    }}>
                                        <LanguageManager
                                            text={"Urgent"} 
                                            type='button'
                                            language={this.context?.features?.language}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={1}> &nbsp;</Grid>
                                <Grid item xs={1} style={{textAlign: 'center'}}>
                                    &nbsp;
                                </Grid>
                                <Grid item xs={5} style={{textAlign: 'center'}}>
                                    <Box borderRight={1} borderBottom={1} style={{minHeight: 100, display: 'grid', placeItems: 'center'}}>
                                        {action.urgent !== true && action.important === true
                                            ?
                                                <div onClick={() => this.setState({openAlert: true, addUrgency: false})}>
                                                    <IconButton>
                                                        <EventIcon fontSize="large" style={{color: theme.palette.secondary.main}}/>
                                                    </IconButton>
                                                    <strong style={{color: theme.palette.secondary.main}}><br/>
                                                        <LanguageManager
                                                            text={"Schedule"} 
                                                            type='button'
                                                            language={this.context?.features?.language}
                                                        />
                                                    </strong>
                                                </div>
                                            :
                                                <IconButton onClick={() => {
                                                    this.actionSetUrgent(false)
                                                    this.actionSetImportant(true)
                                                    }}>
                                                    <RadioButtonUncheckedIcon fontSize="large" style={{color: "grey"}}/>
                                                </IconButton>
                                        }
                                    </Box>
                                </Grid>
                                <Grid item xs={5} style={{ textAlign: 'center'}}>
                                    <Box borderLeft={0} borderBottom={1} style={{minHeight: 100, display: 'grid', placeItems: 'center'}}>
                                        {action.urgent === true && action.important === true
                                            ?
                                                <div onClick={() => this.setState({openAlert: true, addUrgency: true})}>
                                                    <IconButton>
                                                        <FilterCenterFocusIcon fontSize="large" style={{color: theme.palette.secondary.main}}/>
                                                    </IconButton>
                                                    <strong style={{color: theme.palette.secondary.main}}><br/>
                                                        <LanguageManager
                                                            text={"Do Now"} 
                                                            type='button'
                                                            language={this.context?.features?.language}
                                                        />
                                                    </strong>
                                                </div>
                                            :
                                                <IconButton onClick={() => {
                                                    this.actionSetUrgent(true)
                                                    this.actionSetImportant(true)
                                                    }}>
                                                    <RadioButtonUncheckedIcon fontSize="large" style={{color: "grey"}}/>
                                                </IconButton>
                                        }
                                    </Box>
                                </Grid>
                                <Grid item xs={1} style={{textAlign: 'center'}}>
                                    <div style={{
                                        writingMode: 'vertical-rl',
                                        textOrientation: 'mixed',
                                        color: action.important? theme.palette.themeRed : theme.palette.themeGrey
                                    }}>
                                        <LanguageManager
                                            text={"Important"} 
                                            type='button'
                                            language={this.context?.features?.language}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={1} style={{textAlign: 'center'}}>
                                    &nbsp;
                                </Grid>
                                <Grid item xs={5} style={{ textAlign: 'center'}}>
                                    <Box borderRight={1} borderTop={0} style={{minHeight: 100, display: 'grid', placeItems: 'center'}}>
                                        {action.urgent !== true && action.important !== true
                                            ?
                                                <div onClick={() => this.setState({openArchive: true, addDelegate: false})}>
                                                    <IconButton>
                                                        <LowPriorityIcon fontSize="large" style={{color: theme.palette.secondary.main}}/>
                                                    </IconButton>
                                                    <strong style={{color: theme.palette.secondary.main}}><br/>
                                                        <LanguageManager
                                                            text={"Stash"} 
                                                            type='button'
                                                            language={this.context?.features?.language}
                                                        />
                                                    </strong>
                                                </div>
                                            :
                                                <IconButton onClick={() => {
                                                    this.actionSetUrgent(false)
                                                    this.actionSetImportant(false)
                                                    }}>
                                                    <RadioButtonUncheckedIcon fontSize="large" style={{color: "grey"}}/>
                                                </IconButton>
                                        }
                                    </Box>
                                </Grid>
                                <Grid item xs={5} style={{ textAlign: 'center'}}> 
                                    <Box borderLeft={0} borderTop={0} style={{minHeight: 100, display: 'grid', placeItems: 'center'}}>
                                        {action.urgent === true && action.important !== true
                                            ?
                                                <div onClick={() => this.setState({openArchive: true, addDelegate: true})}>
                                                    <IconButton>
                                                        <MoveToInboxIcon fontSize="large" style={{color: theme.palette.secondary.main}}/>
                                                    </IconButton>
                                                    <strong style={{color: theme.palette.secondary.main}}><br/>
                                                        <LanguageManager
                                                            text={"Monitor"} 
                                                            type='button'
                                                            language={this.context?.features?.language}
                                                        />
                                                    </strong>
                                                </div>
                                            :
                                                <IconButton onClick={() => {
                                                    this.actionSetUrgent(true)
                                                    this.actionSetImportant(false)
                                                    }}>
                                                    <RadioButtonUncheckedIcon fontSize="large" style={{color: "grey"}}/>
                                                </IconButton>
                                        }
                                    </Box>
                                </Grid>
                                <Grid item xs={1} style={{textAlign: 'center'}}>
                                    <div style={{
                                        writingMode: 'vertical-rl',
                                        textOrientation: 'mixed',
                                        color: action.important ? theme.palette.themeGrey : theme.palette.themeRed
                                    }}>
                                        <LanguageManager
                                            text={"Negligible"} 
                                            type='button'
                                            language={this.context?.features?.language}
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                            <p style={theme.textSupportCenter}> 
                                <LanguageManager
                                    text={"Set Importance"} 
                                    type='button'
                                    language={this.context?.features?.language}
                                />
                                &nbsp;&bull;&nbsp;
                                <LanguageManager
                                    text={"Add Reminder"} 
                                    type='button'
                                    language={this.context?.features?.language}
                                />
                            </p>
                        </div>
                    }
                </div>
            )
        }
    }

    showStatus () {
        const {theme} = this.props;
        //console.log('this.state', this.state)
        var action = this.state.action;
        if (action.done) {
            return(
                <AvatarCard key="done" textBlock='Done' type='icon' image={<CheckCircleIcon />} squareSize='20' backgroundColor={theme.palette.themeAlertGreen}/>
            )
        }
        else if (action.failed) {
            return(
                <AvatarCard key="fail"  textBlock='Failed' type='icon' image={<SmsFailedIcon />} squareSize='20' backgroundColor={theme.palette.themeGrey} />
            )
        }
        else if(action.urgent) {
            return(
                <AvatarCard  key="urg" textBlock='Urgent' type='icon' image={<WarningIcon />} squareSize='20' backgroundColor={theme.palette.themeAlertRed} />
            )
        }
        else if(action.important) {
            return(
                <AvatarCard  key="import"  textBlock='Important' type='icon' image={<FormatListNumberedIcon />} squareSize='20' backgroundColor={theme.palette.themeAlertBlue} />
            )
        }
        else if(action.onTrack) {
            return(
                <AvatarCard  key="ontr" textBlock='On Track' type='icon' image={<ThumbUpIcon />} squareSize='20' backgroundColor={theme.palette.themeAlertGreen} />
            )
        }
        else if(action.offTrack) {
            return(
                <AvatarCard key="offt"  textBlock='Off Track' type='icon' image={<SentimentVeryDissatisfiedIcon />} squareSize='20' backgroundColor={theme.palette.themeAlertOrange} />
            )
        }
        else{
            if(!this.state.open && this.props.compactMode ){
                return(
                    <Button>
                        Update&nbsp;<EditIcon fontSize="small"/>
                    </Button>
                )
            }
            else{
                return "?"
            }
        }

    }

    moveNext = (removeFlag) => {
        if (this.props.moveNext !== undefined ){
            //console.log(' move next itme')
            this.props.moveNext(removeFlag)
        }
    }

    getActivityArchiveList(){
        var activityArchive = [];
        var actionUpdate = {};
        var actionNew = true;
        if(this.state.action?.activityArchive !== undefined){
            activityArchive = this.state.action.activityArchive;
        }
        var todayDateLong = this.getDateStringFormat()
        var todayDateShort = todayDateLong.slice(0,10);
        if (activityArchive.length > 0){
            var index = activityArchive.length;
            var newAction = activityArchive[activityArchive.length - 1];
            //console.log('newacto', newAction)
            while(index > 0){
                var tempAction = activityArchive[index-1];
                if(tempAction.date && tempAction.date.slice(0,10) === todayDateShort){
                    //console.log('today existing removing last one')
                    if(newAction.message !== undefined && tempAction.message?.length>0){
                        newAction['message'] = tempAction.message;
                    }
                    if(newAction.comment !== undefined && tempAction.comment?.length>0){
                        newAction['comment'] = tempAction.comment;
                    }
                    actionUpdate = newAction;
                    activityArchive.pop();
                    actionNew = false;
                }
                else{
                    break;
                }
                index -= 1;
            }
        }
        else{
            actionUpdate = {
                date : this.state.action,
                message: this.state.action.message,
            }
            if(this.state.action.date && this.state.action.date.slice(0,10) === todayDateShort){
                actionNew = false;
            }
            //console.log('used existing message to add')
        }
        //console.log('today', todayDateShort, actionUpdate, activityArchive);
        return [activityArchive, actionUpdate, actionNew]
    }
    actionChangeComment = (comment) => {
        if(comment !== undefined && comment!== '' && comment.length > 5 && comment!== this.state.originalMessage) {
            var action = this.state.action;
            var [activityArchive, actionUpdate, actionNew] = this.getActivityArchiveList();
            actionUpdate['date'] = this.getDateStringFormat();
            actionUpdate['comment'] = comment;
            if(actionUpdate.value === undefined){
                actionUpdate['value'] = 50;
            }
            activityArchive.push(actionUpdate);
            action['comment'] =  comment;
            action['activityArchive'] =  activityArchive;
            this.setState({action, editMessages: false, newComment:''});
            action['activityArchive'] = activityArchive;
            const timestamp = this.props.firebase.fieldValue.serverTimestamp();
            var dateOriginal = this.getDateStringFormat();
            if(this.props.cta.date) dateOriginal = this.props.cta.date;
            this.props.firebase.journeyFollowUpAction( this.state.actionId).update({
                createdAt: timestamp,
                createdAtInit: this.state.originalDate,
                message: comment,
                messageInit: this.state.originalMessage,
                activityArchive
            });
            if(actionNew){
                var engagement = {
                    userId: this.context.uid,
                    points:1,
                    type:'followUp',
                    message: comment,
                    origin:'days',
                }
                if(this.props.cta?.leadership_category !== undefined){
                    engagement['leadership_category'] = this.props.cta.leadership_category;
                }
                if(this.props.cta?.leadership_quality !== undefined){
                    engagement['leadership_quality'] = this.props.cta.leadership_quality;
                }
                AddEngagementPoints(this.props.firebase, engagement);
                if(this.props.handlerMessageSubmit === undefined)
                    ExtendDialogueMessage(this.props.firebase, this.context, comment, this.state.originalMessage, 'utter_ask_activity', '', 'activities', (this.props.reflectionMode || false));
            }
            if(this.props.handlerMessageSubmit){
                this.props.handlerMessageSubmit(comment);
            }
            AddAnalytics('goal_completion', 'GoalManagePriorities');
        }
    }
    actionChangeIntention = () => {
        if(this.state.intention!== '' && this.state.intention.length > 5) {
            var action = this.state.action;
            var [activityArchive, actionUpdate, actionNew] = this.getActivityArchiveList();
            actionUpdate['date'] = this.getDateStringFormat();
            actionUpdate['message'] = this.state.intention;
            if(actionUpdate.value === undefined){
                actionUpdate['value'] = 50;
            }
            //console.log('actionUpdate', actionUpdate)
            activityArchive.push(actionUpdate);
            action['activityArchive'] = activityArchive;
            action['intention'] = this.state.intention;
            action['intentionApproved'] = true;
            this.setState({action, editIntention: false});
            const timestamp = this.props.firebase.fieldValue.serverTimestamp();
            this.props.firebase.journeyFollowUpAction( this.state.actionId).update({
                createdAt: timestamp,
                createdAtInit: this.state.originalDate,
                intention: this.state.intention,
                intentionApproved: true,
                activityArchive
            });
            if(actionNew){
                var engagement = {
                    userId: this.context.uid,
                    points:1,
                    type:'followUp',
                    message:this.state.intention,
                    origin:'days',
                }
                if(this.props.cta?.leadership_category !== undefined){
                    engagement['leadership_category'] = this.props.cta.leadership_category;
                }
                if(this.props.cta?.leadership_quality !== undefined){
                    engagement['leadership_quality'] = this.props.cta.leadership_quality;
                }
                AddEngagementPoints(this.props.firebase, engagement);
                if(this.props.handlerMessageSubmit === undefined)
                    ExtendDialogueMessage(this.props.firebase, this.context, 'My goal is to ' + this.state.intention, this.state.originalMessage, 'utter_ask_activity', '', 'activities', (this.props.reflectionMode || false));
            }  
            if(this.props.handlerMessageSubmit){
                this.props.handlerMessageSubmit('My goal is to ' + this.state.intention);
            }
            if(this.props.handlerMoment !== undefined){
                this.props.handlerMoment('intention')
            }
            AddAnalytics('goal_completion', 'GoalManagePriorities');
        }
    }
    actionSetImportant = (checked=true) => {
        var action = this.state.action;
        var [activityArchive, actionUpdate, actionNew] = this.getActivityArchiveList();
        actionUpdate['date'] = this.getDateStringFormat();
        actionUpdate['important'] = checked;
        activityArchive.push(actionUpdate);
        action['activityArchive'] = activityArchive;
        action['important'] = checked;
        this.setState({action});
        const timestamp = this.props.firebase.fieldValue.serverTimestamp();
        this.props.firebase.journeyFollowUpAction( this.state.actionId).update({
            createdAt: timestamp,
            createdAtInit: this.state.originalDate,
            important: checked,
            activityArchive
            //activityArchive: [{date: this.getDateStringFormat(),important: true,}]
            //activityArchive: this.props.firebase.fieldValue.arrayUnion( {date: this.getDateStringFormat(),important: checked}),
        });
        if(actionNew){
            var engagement = {
                userId: this.context.uid,
                points:1,
                type:'followUp',
                message:'Activity important',
                origin:'days',
            }
            if(this.props.cta?.leadership_category !== undefined){
                engagement['leadership_category'] = this.props.cta.leadership_category;
            }
            if(this.props.cta?.leadership_quality !== undefined){
                engagement['leadership_quality'] = this.props.cta.leadership_quality;
            }
            AddEngagementPoints(this.props.firebase, engagement);
            this.props.firebase.user(this.context.uid).update({ctaText: this.state.intention });
            if(checked){
                if(this.props.handlerMessageSubmit){
                    this.props.handlerMessageSubmit('Very important is: ' + this.state.intention);
                }
                else{
                    ExtendDialogueMessage(this.props.firebase, this.context, 'Very important is '+this.state.intention,  this.state.intention, 'utter_ask_activity', '', 'activities', (this.props.reflectionMode || false));
                }
            }
            AddAnalytics('goal_completion', 'GoalManagePriorities');
        }
    }
    actionSetUrgent = (checked=true) => {
        //console.log('check', checked)
        var action = this.state.action;
        var [activityArchive, actionUpdate, actionNew] = this.getActivityArchiveList();
        actionUpdate['urgent'] = checked;
        actionUpdate['date'] = this.getDateStringFormat();
        activityArchive.push(actionUpdate);
        action['activityArchive'] = activityArchive;
        action['urgent'] = checked;
        this.setState({action});
        const timestamp = this.props.firebase.fieldValue.serverTimestamp();
        this.props.firebase.journeyFollowUpAction( this.state.actionId).update({
            createdAt: timestamp,
            createdAtInit: this.state.originalDate,
            urgent: checked,
            activityArchive
            //activityArchive: [{date: this.getDateStringFormat(),urgent: true,}]
            //activityArchive: this.props.firebase.fieldValue.arrayUnion( {date: this.getDateStringFormat(),urgent: checked}),
        }); 
        if(actionNew){
            var engagement = {
                userId: this.context.uid,
                points:1,
                type:'followUp',
                message:'Activity urgent',
                origin:'days',
            }
            if(this.props.cta?.leadership_category !== undefined){
                engagement['leadership_category'] = this.props.cta.leadership_category;
            }
            if(this.props.cta?.leadership_quality !== undefined){
                engagement['leadership_quality'] = this.props.cta.leadership_quality;
            }
            AddEngagementPoints(this.props.firebase, engagement);
            this.props.firebase
            .user(this.context.uid)
            .update({
                ctaText: this.state.intention,
            });
            if(checked){
                if(this.props.handlerMessageSubmit){
                    this.props.handlerMessageSubmit('Very urgent is: ' + this.state.intention);
                }
                else{
                    ExtendDialogueMessage(this.props.firebase, this.context, 'Very urgent is: '+ this.state.intention,  this.state.intention, 'utter_ask_activity', '', 'activities', (this.props.reflectionMode || false));
                }
            }
            AddAnalytics('goal_completion', 'GoalManagePriorities');
        }
    }
    actionSetHide = () => {
        //const timestamp = this.props.firebase.fieldValue.serverTimestamp();
        this.props.firebase.journeyFollowUpAction( this.state.actionId).update({
            hide: true,
            important: false,
            urgent: false,
            intentionApproved: false
        }); 
        this.handleClose(true);
    }
    actionSetDelete = () => {
        //const timestamp = this.props.firebase.fieldValue.serverTimestamp();
        //var originalDoc =  this.props.firebase.journeyFollowUpAction( this.state.actionId).get().data();
        this.props.firebase.journeyFollowUpAction( this.state.actionId).delete();
        this.handleClose(true);
    }
    actionSetFailed = (score) => {
        var action = this.state.action;
        score = (score ? score : 0);
        var [activityArchive, actionUpdate, actionNew] = this.getActivityArchiveList();
        actionUpdate['done'] = false;
        actionUpdate['failed'] = true;
        actionUpdate['date'] = this.getDateStringFormat();
        actionUpdate['value'] = score;
        actionUpdate['intentionApproved'] = false;
        activityArchive.push(actionUpdate);
        action['activityArchive'] = activityArchive;
        this.setState({action});
        //const timestamp = this.props.firebase.fieldValue.serverTimestamp();
        this.props.firebase.journeyFollowUpAction( this.state.actionId ).update({
            //createdAt: timestamp,
            //createdAtInit: this.state.originalDate,
            done: false,
            failed: true,
            value: score,
            activityArchive,
            intentionApproved: false
            //activityArchive: [{date: this.getDateStringFormat(),failed: true,}]
            //activityArchive: this.props.firebase.fieldValue.arrayUnion( {date: this.getDateStringFormat(), failed: true, value: (score? score: 0)}),
        });
        if(actionNew){
            var engagement = {
                userId: this.context.uid,
                points:1,
                type:'followUp',
                message:'Activity failed',
                origin:'days',
            }
            if(this.props.cta?.leadership_category !== undefined){
                engagement['leadership_category'] = this.props.cta.leadership_category;
            }
            if(this.props.cta?.leadership_quality !== undefined){
                engagement['leadership_quality'] = this.props.cta.leadership_quality;
            }
            AddEngagementPoints(this.props.firebase, engagement);
            ExtendDialogueMessage(this.props.firebase, this.context, 'I failed with: ' + this.state.intention,  this.state.intention, 'utter_ask_activity', '', 'activities', (this.props.reflectionMode || false));
        }
    }
    actionSetDone = (score = undefined) => {
        var action = this.state.action;
        score = (score ? score : 100);
        var [activityArchive, actionUpdate, actionNew] = this.getActivityArchiveList();
        actionUpdate['done'] = true;
        actionUpdate['failed'] = false;
        actionUpdate['important'] = false;
        actionUpdate['urgent'] = false;
        actionUpdate['date'] = this.getDateStringFormat();
        actionUpdate['value'] = (score? score: 100);
        actionUpdate['intentionApproved'] = false;
        action['done'] = true;
        action['failed'] = false;
        action['important'] = false;
        action['urgent'] = false;
        action['value'] = (score? score: 100);
        action['intentionApproved'] = false;
        activityArchive.push(actionUpdate);
        action['activityArchive'] =  activityArchive;
        this.setState({action});
        //const timestamp = this.props.firebase.fieldValue.serverTimestamp();
        this.props.firebase.journeyFollowUpAction( this.state.actionId).update({
            //createdAt: timestamp,
            //createdAtInit: this.state.originalDate,
            done: true,
            onTrack: true,
            offTrack: false,
            failed: false,
            important: false,
            urgent: false,
            value: score,
            intentionApproved: false,
            activityArchive
            //activityArchive: [{date: this.getDateStringFormat(),done: true,}]
            //activityArchive: this.props.firebase.fieldValue.arrayUnion( {date: this.getDateStringFormat(),done: true}),
        });
        if(actionNew){
            var engagement = {
                userId: this.context.uid,
                points:1,
                type:'followUp',
                message:'Activity done',
                origin:'days',
                }
            if(this.props.cta?.leadership_category !== undefined){
                engagement['leadership_category'] = this.props.cta.leadership_category;
            }
            if(this.props.cta?.leadership_quality !== undefined){
                engagement['leadership_quality'] = this.props.cta.leadership_quality;
            }
            AddEngagementPoints(this.props.firebase, engagement);
            var newMessage = this.state.message?.replace('I want to learn more about', '');
            if(this.props.handlerMessageSubmit){
                this.props.handlerMessageSubmit('I accomplished ' + this.state.intention);
            }
            else{
                ExtendDialogueMessage(this.props.firebase, this.context, 'I accomplished ' + this.state.intention,  this.state.intention, 'utter_ask_activity', '', 'activities', (this.props.reflectionMode || false));
            }
        }
        this.handleClose(true);
        if(this.props.handlerMoment !== undefined){
            this.props.handlerMoment('accomplishment')
        }
        AddAnalytics('goal_completion', 'GoalManagePriorities');
    }
    actionSetOnTrack = (score=undefined, checked=true) => {
        var action = this.state.action;
        score = (score? score: 75);
        var [activityArchive, actionUpdate, actionNew] = this.getActivityArchiveList();
        //console.log('check', actionUpdate, activityArchive)
        actionUpdate['onTrack'] = checked;
        actionUpdate['offTrack'] = !checked;
        action['onTrack'] = checked;
        action['offTrack'] = !checked;
        action['done'] = false;
        actionUpdate['date'] = this.getDateStringFormat();
        actionUpdate['value'] = score;
        activityArchive.push(actionUpdate);
        action['activityArchive'] = activityArchive;
        this.setState({action, upToDate: true});
        //console.log('action', action, actionUpdate)
        const timestamp = this.props.firebase.fieldValue.serverTimestamp();
        this.props.firebase.journeyFollowUpAction( this.state.actionId).update({
            createdAt: timestamp,
            createdAtInit: this.state.originalDate,
            onTrack: checked,
            offTrack: !checked,
            done: false,
            failed: false,
            value: score,
            activityArchive
            //activityArchive: [{date: this.getDateStringFormat(),onTrack: true,}]
            //activityArchive: this.props.firebase.fieldValue.arrayUnion( {date: this.getDateStringFormat(),onTrack: checked}),
        });
        if(actionNew){
            var engagement = {
                userId: this.context.uid,
                points:1,
                type:'followUp',
                message:'On Track',
                origin:'days',
            }
            if(this.props.cta?.leadership_category !== undefined){
                engagement['leadership_category'] = this.props.cta.leadership_category;
            }
            if(this.props.cta?.leadership_quality !== undefined){
                engagement['leadership_quality'] = this.props.cta.leadership_quality;
            }
            AddEngagementPoints(this.props.firebase, engagement);
            if(this.props.handlerMessageSubmit){
                this.props.handlerMessageSubmit('I am on track with: ' + this.state.intention);
            }
            else{
                ExtendDialogueMessage(this.props.firebase, this.context, 'I am on track with: ' + this.state.intention ,  this.state.intention, 'utter_ask_activity', '', 'activities', (this.props.reflectionMode || false));
            }
        }
        if(['New Goals', 'Ongoing Goals'].includes(this.props.title)) this.moveNext(true)
        else this.moveNext()
        if(this.props.handlerMoment !== undefined){
            //console.log('happiness re')
            //this.props.handlerMoment('ontrack')
        }
    }
    actionSetOffTrack = (score=undefined) => {
        //console.log('off tracked')
        var action = this.state.action;
        score = (score? score: 25);
        var [activityArchive, actionUpdate, actionNew] = this.getActivityArchiveList();
        actionUpdate['onTrack'] = false;
        actionUpdate['offTrack'] = true;
        action['onTrack'] = false;
        action['offTrack'] = true;
        action['done'] = false;
        actionUpdate['date'] = this.getDateStringFormat();
        actionUpdate['value'] = score
        activityArchive.push(actionUpdate);
        action['activityArchive'] = activityArchive;
        this.setState({action, upToDate: true});
        const timestamp = this.props.firebase.fieldValue.serverTimestamp();
        this.props.firebase.journeyFollowUpAction( this.state.actionId).update({
            createdAt: timestamp,
            createdAtInit: this.state.originalDate,
            offTrack: true,
            onTrack: false,
            done: false,
            failed: false,
            value: score,
            activityArchive
            //activityArchive: [{date: this.getDateStringFormat(),offTrack: true,}]
            //activityArchive: this.props.firebase.fieldValue.arrayUnion( {date: this.getDateStringFormat(),offTrack: true}),
        });
        if(actionNew){
            var engagement = {
                userId: this.context.uid,
                points:1,
                type:'followUp',
                message:'Off Track',
                origin:'days',
            }
            if(this.props.cta.leadership_category !== undefined){
                engagement['leadership_category'] = this.props.cta.leadership_category;
            }
            if(this.props.cta.leadership_quality !== undefined){
                engagement['leadership_quality'] = this.props.cta.leadership_quality;
            }
            AddEngagementPoints(this.props.firebase, engagement);
            ExtendDialogueMessage(this.props.firebase, this.context, 'I am behind the schedule and have challenges: '+this.state.intention,  this.state.intention, 'utter_ask_activity', '', 'activities', (this.props.reflectionMode || false));
            if(this.props.handlerMessageSubmit){
                this.props.handlerMessageSubmit('I am behind the schedule and have challenges: ' + this.state.intention);
            }
        }
        if(['New Goals', 'Ongoing Goals'].includes(this.props.title)) this.moveNext(true)
        else this.moveNext()
    }

    getDateStringFormat(){
        var selectDate = new Date()
        const dayOfWeek = ["Sun","Mon","Tue","Wed","Thu","Fri","Sat"]
        var dateString = 
            selectDate.getFullYear() +"-"+
            ("0" +  (selectDate.getMonth()+1)).slice(-2) +"-"+ 
            ("0" + selectDate.getDate()).slice(-2) + " " + 
            dayOfWeek[selectDate.getDay()] + " " + 
            ("0" + selectDate.getHours()).slice(-2) + ":" + 
            ("0" + selectDate.getMinutes()).slice(-2) + ":" + 
            ("0" + selectDate.getSeconds()).slice(-2);
        return dateString
    }

    handleClose(removeFlag = false){
        this.unblock();
        this.setState({
            open: false,
        });
        if(this.props.handleClose !== undefined){
            //console.log('close');
            //this.props.handleClose();
        }
        this.moveNext(removeFlag);
    }
    unblock = () =>  this.props.allowRefresh();

    handleOpen = () => {
        this.block();
        this.setState({
            open: true,
        });
        //console.log('open');
    }
    block = () => {this.props.blockRefresh();}

    getDateFormatSat(sourceDate) {
        var dateNextSat = new Date(sourceDate);
        //console.log(dateNextSat)
        dateNextSat = this.getNextDayOfTheWeek("sat", false, dateNextSat );
        var dateNextSatString = 
            dateNextSat.getFullYear() +"-"+
            ("0" +  (dateNextSat.getMonth()+1)).slice(-2) +"-"+ 
            ("0" + dateNextSat.getDate()).slice(-2);
        //console.log(dateNextSatString)
        return dateNextSatString;
    }

    getNextDayOfTheWeek(dayName, excludeToday = true, refDate = new Date()) {
    const dayOfWeek = ["sun","mon","tue","wed","thu","fri","sat"]
                        .indexOf(dayName.slice(0,3).toLowerCase());
    if (dayOfWeek < 0) return;
    refDate.setHours(0,0,0,0);
    refDate.setDate(refDate.getDate() + !!excludeToday + 
                    (dayOfWeek + 7 - refDate.getDay() - !!excludeToday) % 7);
    return refDate;
    }
}


export default compose(
    withFirebase,
    withStyles(styles, { withTheme: true })
)(JournalActivitiesItem);
