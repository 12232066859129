import React, { Component } from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import LanguageManager from '../../constants/languages';
import withStyles from '@material-ui/core/styles/withStyles';
import {IsMobileDevice} from '../Modules/IsMobileDevice';
import {LoadUserCategoryAndQualities} from "../Modules/LoadUserData";
import AvatarImage from '../Modules/AvatarImage';
import {AvatarCard} from '../Modules/AvatarImage';
import {ExtendDialogueMessage} from '../Routine/DiaryManual';
import { FindTextValueInDict } from '../Modules/LoadCategoriesAndQualities';
import CardChangeName from "../Onboarding/CardChangeName.js";
import GoalOverview from '../Goals/GoalOverview';
import RoleOverview from '../Profile/RoleOverview';
import {CheckXPLevel} from '../Charts/XPLevels';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import {freeTopics, CheckSubscriptionStatus} from '../Modules/CheckSubscriptionStatus';

import RockyIcon from '../Modules/RockyIcon';
import ForumIcon from '@material-ui/icons/Forum';
import BadgeCard from '../Modules/BadgeCard';
//import TodayPath from '../Home/TodayPath';
import logoWhatsNext from "../../images/Badge_XP_whatsnext.png";
//import Task4Topics from '../Home/Task4Topics';

/// Tutorials
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import DialogTutorialPage from '../Onboarding/DialogTutorialPage';

import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import {RemoveScroll} from 'react-remove-scroll';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
//import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import imageSettingQuality from "../../images/settingquality.png";
import Slide from '@material-ui/core/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const styles = theme => ({
    root: {
        backgroundColor: '#2d2c4c',
    },
});

class DialogQuickTopicSetting extends Component { 
    _isMounted = false;

    constructor(props) {
        super(props);
    
        this.state = {
            loading: true,
        };
    }

    componentDidMount() {
        this._isMounted = true;
        this.setState({
            currentCategory: this.props.user.currentCategory,
            currentLeadershipQuality: this.props.user.currentLeadershipQuality,
            achievementGoal: this.props.user.achievementGoal,
            username: this.props.user.username,
            todaysLeadershipQuality: this.props.user.todaysLeadershipQuality
        })
        this.loadCategoriesAndQualities();
        this.getSubScriptionStatus();
        const xpLevels = {
            modules: CheckXPLevel(this.props.user, 'modules'),
        }
        this.setState({xpLevels});
    }

    getSubScriptionStatus(){
        let subscriptionStatus = CheckSubscriptionStatus(this.props.user);
        //console.log('dial select', subscriptionStatus)
        this.setState({nonSubscriber: subscriptionStatus.nonSubscriber, trialSubscriber: subscriptionStatus.trialPeriod});
    }

    componentDidUpdate(prevProps){
        //console.log('update', this.props)
        if(this._isMounted){
            if(this.props.openCurrentAchievementGoal === true && prevProps.openCurrentAchievementGoal !== true){
                this.setState({
                    currentCategory: this.props.user.currentCategory,
                    currentLeadershipQuality: this.props.user.currentLeadershipQuality,
                    achievementGoal: this.props.user.achievementGoal,
                    username: this.props.user.username,
                    todaysLeadershipQuality: this.props.user.todaysLeadershipQuality
                })
                if(this.props.openCurrentShopFlag === true){
                    this.setState({openTopicSelection: true})
                }
                this.getSubScriptionStatus();
            }
            if(this.props.user?.currentLeadershipQuality !== prevProps.user?.currentLeadershipQuality){
                this.loadCategoriesAndQualities();
                this.getSubScriptionStatus();
            }
        }
    }

    loadCategoriesAndQualities() {
        var userGrowthPath = LoadUserCategoryAndQualities(this, this.props.user.uid, this.props.user, this.props.leadershipCategories, this.props.leadershipQualities)
        //console.log('userGrowthPath', userGrowthPath)
        if(this._isMounted && userGrowthPath !== undefined ){
            this.setState({
                ...userGrowthPath.oldCommentList,
                mainCategory: userGrowthPath.mainCategory, 
                mainQuality: userGrowthPath.mainQuality, 
                programQualities: userGrowthPath.programQualities, 
                mainQualitiesDicts: userGrowthPath.mainQualitiesDicts , 
                sisterQualities: userGrowthPath.sisterQualities
            });
            //console.log('mainQualitiesDicts', userGrowthPath )
        }
    }

    
    componentWillUnmount(){
        this._isMounted =false;
        //this.unsubscribe();
    }

    handleCurrentAchievementGoalSubmit = (forced=false) => {
        if(this.props.handleSubmitCurrentAchievementGoal !== undefined){
            //console.log('handleCurrentAchievementGoalSubmit', forced)
            if(forced){
                this.props.handleSubmitCurrentAchievementGoal(true, this.state.currentLeadershipQuality);
            }
            else{
                var changeFlag = this.checkChanges();
                //console.log(changeFlag, this.state, this.props)
                this.props.handleSubmitCurrentAchievementGoal(changeFlag, this.state.currentLeadershipQuality);
            }
        }
    }
    checkChangeAndClose = () => {
        var changeFlag = this.checkChanges();
        if(changeFlag){
            this.props.handleSubmitCurrentAchievementGoal(changeFlag, this.state.currentLeadershipQuality);
        }
        else{
            this.props.handleClose()
        }
    }
    checkChanges(){
        var changeFlag = false;
        if(this.props.user.currentCategory !== this.state.currentCategory) changeFlag = true;
        else if(this.props.user.currentLeadershipQuality !== this.state.currentLeadershipQuality) changeFlag = true;
        else if(this.props.user.achievementGoal !== this.state.achievementGoal) changeFlag = true;
        else if(this.props.user.username !== this.state.username) changeFlag = true;
        else if(this.props.user.todaysLeadershipQuality !== this.state.todaysLeadershipQuality) changeFlag = true;
        //console.log('change', changeFlag, this.state, this.props.user)
        return changeFlag;
    }

    render(){
        const { theme } = this.props;
        if(this.props.buttonsOnly){
            return(
                <>
                    {this.renderTopicButtons()}
                </>
            )
        }
        else{
            return(
                <div>
                    {this.props.renderPreview &&
                        this.renderTopicButtons()
                    }
                    <DialogTutorialPage 
                        defaultSet={this.props.defaultSet}
                        handleOpen={this.state.handleOpenTutorial || false}
                        handleBadgeFamily = {'path'}
                        handleClose={() => {this.setState({handleOpenTutorial: false})}}
                    />
                    <RemoveScroll enabled={this.props.openCurrentAchievementGoal || false}>
                        <Dialog
                            open={this.props.openCurrentAchievementGoal || false}
                            onClose={this.checkChangeAndClose}
                            fullScreen={IsMobileDevice()}
                            PaperProps={{style:theme.dialogWindow}}
                            TransitionComponent={Transition}
                            maxWidth="sm"
                        >
                            <DialogTitle style={theme.dialogTitle} >
                                <Grid container spacing={0} direction="row"
                                    justifyContent="space-between"
                                    alignItems="center">
                                    <Grid item >
                                        <IconButton onClick={this.checkChangeAndClose}>
                                            <ArrowBackIcon />
                                        </IconButton>
                                    </Grid>
                                    <Grid item>
                                        <span style={theme.dialogTitle.titleText}>
                                            <LanguageManager
                                                text={"Topics and Goals"}
                                                type='button'
                                                language={this.props.user?.features?.language}
                                            />
                                        </span>
                                    </Grid>
                                    <Grid item>
                                        <IconButton style={{marginRight: 2}} onClick={() => {this.setState({handleOpenTutorial: true})}}>
                                            <HelpOutlineIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </DialogTitle>
                            <DialogContent style={theme.dialogContent}>
                                {this.props.openCurrentAchievementGoal &&
                                    <div style={{textAlign: 'center'}}>
                                        {this.props.isCoach !== true
                                        ?
                                            <GoalOverview 
                                                key={"goal"}
                                                user={this.props.user}
                                                defaultSet={this.props.defaultSet || undefined}
                                                features={['goal']}
                                                type='quick'
                                                closeDialog={() => {
                                                    this.setState({goalOpen: false})
                                                }}
                                                openDialogue={ this.state.goalOpen ? true : false}
                                                leadershipCategories={this.props.leadershipCategories}
                                                leadershipQualities={this.props.leadershipQualities}
                                            />
                                        :   this.props.user?.achievementSelf && this.props.user?.achievementSelf.text
                                            ?
                                                <BadgeCard
                                                    backgroundColor ={"transparent"}
                                                    image={this.props.user?.achievementSelf?.icon || logoWhatsNext} 
                                                    avatarImage = {undefined}
                                                    title = {this.props.user?.achievementSelf?.text}
                                                    subtitle = {this.props.user?.achievementSelf?.explanationShort || undefined}
                                                    strongSubtitle = {false}
                                                />
                                            :   ''
                                        }
                                        <Grid container direction="row" justifyContent={"center"}  alignItems="center"  spacing={1}>
                                            <Grid item > 
                                                <img src={imageSettingQuality} alt='My Current Focus' style={theme.cardSettings.iconDesign} />
                                            </Grid>
                                            <Grid item > 
                                                <strong>
                                                    <LanguageManager
                                                        text={"Today's 'Quest'"}
                                                        type='button'
                                                        language={this.props.user?.features?.language}
                                                    />
                                                </strong>
                                            </Grid>
                                        </Grid>
                                        {false && this.props.unselectTopics !== true 
                                        && /// removed as now onlz the avatarcard shown
                                            <BadgeCard
                                                backgroundColor ={"transparent"}
                                                image={undefined} 
                                                avatarImage = {<AvatarImage id={this.props.user?.currentLeadershipQuality || 'new'} type='quality' 
                                                                    key={this.props.user?.currentLeadershipQuality} 
                                                                    image={FindTextValueInDict(this.props.firebase, this.props.leadershipQualities, this.props.user?.currentLeadershipQuality, 'icon')}
                                                                    vertical-align="middle" item={this.props.user?.currentLeadershipQuality} 
                                                                    squareSize={"auto"} backgroundColor={FindTextValueInDict(this.props.firebase, this.props.leadershipQualities, this.props.user?.currentLeadershipQuality, 'backgroundColor')} 
                                                                />}
                                                title = {'Selected Quest: ' + FindTextValueInDict(this.props.firebase, this.props.leadershipCategories, this.props.user.currentCategory)}
                                                subtitle = {FindTextValueInDict(this.props.firebase, this.props.leadershipQualities, this.props.user.currentLeadershipQuality)}
                                                strongSubtitle = {true}
                                            />
                                        }
                                        {this.props.unselectTopics !== true &&
                                            <span
                                            >
                                                <AvatarCard key={this.props.user.currentLeadershipQuality}  dict={this.props.leadershipQualities} type='quality' item={this.props.user.currentLeadershipQuality}  squareSize={42} />
                                            </span>
                                        }
                                        <div style={{marginBottom: 40}}>
                                            <span style={theme.textSupportCenter}>Change today's Quest by clicking on it<br/><br/></span>
                                            {this.renderTopicButtons()}
                                        </div>
                                        <Card style={{display: 'none'}} key={"qualit"}>
                                            <GoalOverview key={"qualit"}
                                                user = {this.props.user}
                                                defaultSet={this.props.defaultSet || undefined}
                                                features={['quality']}
                                                type='quick'
                                                closeDialog={() => {
                                                    this.setState({openTopicSelection: false, goalOpen: false});
                                                }}
                                                closeDialogNext={() => {
                                                    this.setState({openTopicSelection: false, goalOpen: false});
                                                    //this.handleNext();
                                                }} 
                                                handlerContinue={() => {
                                                    this.setState({openTopicSelection: false, goalOpen: false});
                                                }}
                                                openQualityPickerAfterQuiz = {true}
                                                assessmentResults={ this.state.assessmentResults || undefined }
                                                //openDialogue ={ this.state.openTopicSelection || false}
                                                openProgramSelection ={ this.state.openTopicSelection || false}
                                                leadershipCategories={this.props.leadershipCategories}
                                                leadershipQualities={this.props.leadershipQualities}
                                            />
                                        </Card>
                                        <Card style={theme.CardGoals} key={"role"}>
                                            <RoleOverview 
                                                defaultSet={this.props.defaultSet || undefined}
                                                key={"role"}
                                                type={'quick'}
                                                user={this.props.user}
                                            />
                                        </Card>
                                        {this.props.user?.uid?.length > 2
                                        &&
                                            <Card style={theme.CardGoals} key={"conversation"}>
                                                <Grid container direction="row" justifyContent={"center"} alignItems="center" spacing={1}>
                                                    <Grid item > 
                                                        {this.props.defaultSet?.programLogo?.includes('http')
                                                            ?   <ForumIcon fontSize="large" />
                                                            :   <RockyIcon fontSize="large" />
                                                        }
                                                    </Grid>
                                                    <Grid item > 
                                                        <strong>
                                                            <LanguageManager
                                                                text={"Conversation Style"}
                                                                type='button'
                                                                language={this.props.user?.features?.language}
                                                            />
                                                        </strong>
                                                    </Grid>
                                                    <Grid item > 
                                                    </Grid>
                                                </Grid>
                                                <Button 
                                                    onClick = {() => {
                                                        this.props.firebase.user(this.props.user.uid).update({
                                                            ['features.mentormode']: this.props.firebase.fieldValue.delete()
                                                        });
                                                        if(this.props.handleSetState !== undefined){
                                                            this.props.handleSetState({tabSelection: 'Coach'})
                                                        }
                                                    }}
                                                    variant = {this.props.user?.features?.mentormode!==true ? 'text' : "outlined"}
                                                    color = {this.props.user?.features?.mentormode!==true  ? 'primary' : "secondary"}
                                                    style={{marginLeft:(0), marginTop: 10}}>
                                                        <LanguageManager
                                                            text={"Coaching"}
                                                            type='button'
                                                            language={this.props.user?.features?.language}
                                                        />
                                                </Button>
                                                <Button 
                                                    onClick = {() => {
                                                        this.props.firebase.user(this.props.user.uid).update({
                                                            ['features.mentormode']: true
                                                        });
                                                        if(this.props.handleSetState !== undefined){
                                                            this.props.handleSetState({tabSelection: 'Mentor'})
                                                        }
                                                    }}
                                                    variant = {this.props.user?.features?.mentormode===true ? 'text' : "outlined"}
                                                    color = {this.props.user?.features?.mentormode===true  ? 'primary' : "secondary"}
                                                    style={{marginLeft:10, marginTop: 10}}>
                                                    Mentoring
                                                </Button>
                                            </Card>
                                        }
                                        {this.props.isCoach!== true
                                            &&
                                                <>
                                                    <Card style={theme.CardGoals} key={"name"}>
                                                        <CardChangeName
                                                            title = {'Change Name'}
                                                            text = {"What should I call you?"}
                                                            user = {this.props.user}
                                                        />
                                                    </Card>
                                                </>
                                        }
                                    </div>
                                }
                            </DialogContent>
                            <DialogActions style={theme.dialogAction}>
                                <>
                                    {false &&
                                        <Button variant='contained' onClick={this.props.handleClose}>
                                            <LanguageManager
                                                text={"Cancel"}
                                                type='button'
                                                language={this.props.user?.features?.language}
                                            />
                                        </Button>
                                    }
                                    &nbsp;
                                    <Button variant='contained' onClick={this.checkChangeAndClose}>
                                        <LanguageManager
                                            text={"Continue"}
                                            type='button'
                                            language={this.props.user?.features?.language}
                                        />
                                    </Button>
                                </>
                            </DialogActions>
                        </Dialog>
                    </RemoveScroll>
                </div>
            )// end return
        }
    }//end render

    submitButton(quality, category){
        //console.log(this.props.user?.currentLeadershipQuality, quality)
        if(this.props.user?.currentLeadershipQuality === quality ){
            if(this.props.handleSubmitCurrentAchievementGoal !== undefined){
                this.props.handleSubmitCurrentAchievementGoal(false, this.state.currentLeadershipQuality);
            }
        }
        else{
            this.changeSelectedQualities(quality, category, 'add');
            if(this.props.handleSubmitCurrentAchievementGoal !== undefined){
                this.props.handleSubmitCurrentAchievementGoal(true, this.state.currentLeadershipQuality);
            }
        }
    }

    renderTopicButtons(){
        const {theme} = this.props;
        //console.log('sdfsgfg', this.state.mainQualitiesDicts)
        return(
            <React.Fragment>
                {this.state?.mainQualitiesDicts?.map( (qualityDict, index) => {
                    if(qualityDict?.text?.length > 3 && this.props.buttonsOnly !== true && this.props.user?.currentLeadershipQuality === qualityDict.value && (this.props.unselectTopics !== true || this.state.overwrite)){
                        if(this.props.unselectTopics !== true ){
                            return ""
                        }
                        else{
                            return(
                                <React.Fragment key={"badegeselfUnselect"+index}> 
                                    &nbsp;&nbsp;
                                    {this.props.buttonsOnly
                                        ?   
                                            <Button
                                                style={{marginLeft: 8, marginTop: 4, marginBottom: 4}}
                                                //onClick={() => this.onSubmit(extraButton.payload) }
                                                color="secondary"
                                                variant="outlined"
                                            >
                                                <LanguageManager
                                                    text={qualityDict.text}
                                                    type='button'
                                                    language={this.props.user?.features?.language}
                                                />
                                            </Button>
                                        :   
                                            <span
                                                onClick={() => {
                                                    this.setState({overwrite: true});
                                                }}
                                            >
                                                <AvatarCard key={qualityDict.value} backgroundColor={qualityDict.backgroundColor || theme.palette.themeBlue} dict={this.props.leadershipQualities} type='quality' item={qualityDict.value}  squareSize={26} />
                                            </span>
                                    }
                                </React.Fragment>
                            )
                        }
                    }
                    else{
                        if(qualityDict?.text?.length > 3 && (this.state.nonSubscriber !== true || freeTopics.includes(qualityDict.value))){
                            return(
                                <React.Fragment key={"badegeselfSelect"+index}> 
                                    {this.props.buttonsOnly
                                    ?   
                                        <Button
                                            style={{marginLeft: 8, marginTop: 4, marginBottom: 4, paddingLeft: 6}} 
                                            onClick={() => {
                                                this.submitButton(qualityDict.value, qualityDict.category)
                                            } }
                                            color="secondary"
                                            variant="outlined"
                                        >
                                            <AvatarImage key={qualityDict.value} backgroundColor={qualityDict?.backgroundColor || theme.palette.secondary.main} dict={this.props.leadershipQualities} type='quality' item={qualityDict.value} squareSize={26}/>
                                            &nbsp;
                                            <LanguageManager
                                                text={qualityDict.text}
                                                type='button'
                                                language={this.props.user?.features?.language}
                                            />
                                        </Button>
                                    :
                                        <span 
                                            style={{opacity: 0.5, marginLeft: 8, marginTop: 4, marginBottom: 4}} 
                                            onClick={() => {
                                                this.changeSelectedQualities(qualityDict.value, qualityDict.category, 'add');
                                            } }
                                        >
                                            <AvatarCard key={qualityDict.value} backgroundColor={'grey'} dict={this.props.leadershipQualities} type='quality' item={qualityDict.value} squareSize={26} />
                                        </span>
                                    }
                                </React.Fragment>
                            )
                        }
                        else if (qualityDict?.text?.length > 3){
                            return(
                                <React.Fragment key={"badegeselfSelectUnsub"+index}>
                                    {this.props.buttonsOnly
                                    ?   
                                        <Button 
                                            style={{position: 'relative', paddingLeft: 6, marginLeft: 8, marginTop: 4, marginBottom: 4}}
                                            onClick={() => {
                                                this.submitButton(qualityDict.value, qualityDict.category)
                                            } }
                                            color="secondary"
                                            variant="outlined"
                                        > 
                                            <AvatarImage key={qualityDict.value} backgroundColor={qualityDict?.backgroundColor || theme.palette.secondary.main} dict={this.props.leadershipQualities} type='quality' item={qualityDict.value} squareSize={26}/>
                                            <ShoppingBasketIcon style={{fontSize: 16, marginLeft: 0, position: 'absolute', left: 24, bottom: 1, color:theme.palette.themeAltGold}} />
                                            &nbsp;
                                            <LanguageManager
                                                text={qualityDict.text}
                                                type='button'
                                                language={this.props.user?.features?.language}
                                            />
                                        </Button>
                                    :
                                        <span style={{position: 'relative', opacity: 0.5, marginLeft: 8, marginTop: 4, marginBottom: 4}} 
                                            key={qualityDict.value}
                                            onClick={() => {
                                                this.changeSelectedQualities(qualityDict.value, qualityDict.category, 'add');
                                            } }
                                            >
                                            &nbsp;
                                            <AvatarCard key={qualityDict.value} backgroundColor={'grey'} dict={this.props.leadershipQualities} type='quality' item={qualityDict.value} squareSize={26}/>
                                            &nbsp;
                                            <ShoppingBasketIcon style={{fontSize: 16, marginLeft: 4, position: 'relative', left: -20, bottom: 26, color:theme.palette.themeAltGold}} />
                                        </span>
                                    }
                                </React.Fragment>
                            )
                        }
                        else{
                            return ''
                        }
                    }
                })}
                {(
                    //!(this.state.xpLevels?.modules?.valid) || 
                    this.props.user?.roles?.includes("COACHEE") // || !(this.props.user?.programs?.includes("default"))
                )
                    ?
                        ''
                    :   (this.props.buttonsOnly )
                        ?   this.props.openShop
                            ?
                                <Button
                                    style={{marginLeft: 8, marginTop: 4, marginBottom: 4, backgroundColor: theme.palette.secondary.main+'20'}} 
                                    onClick={() => {
                                        if(this.props.handleOpen !== undefined){
                                            this.props.handleOpen()
                                        }
                                    }}
                                    color="secondary"
                                    variant="outlined"
                                >
                                    <ShoppingBasketIcon fontSize='small'/>
                                    &nbsp;
                                    <LanguageManager
                                        text={"Add new 'Quest'"}
                                        type='button'
                                        language={this.props.user?.features?.language}
                                    />
                                </Button>
                            :   ''
                        :
                            <span key={"badegeselfSelect_Shop"} style={{opacity: 0.9, position: 'relative', marginLeft: 8, marginTop: 4, marginBottom: 4}}
                                    onClick={ () => {
                                        if(false && this.props.renderPreview && this.props.handleOpen !== undefined){
                                            this.props.handleOpen()
                                        }
                                        else{
                                            this.setState({openTopicSelection: true})}
                                        }
                                    }
                                > 
                                &nbsp;&nbsp;
                                <Button
                                    style={{marginLeft: 8, marginTop: 8, marginBottom: 8, backgroundColor: theme.palette.secondary.main+'20'}} 
                                    color="secondary"
                                    variant="outlined"
                                >
                                    <ShoppingBasketIcon fontSize='small'/>
                                    &nbsp;
                                    <LanguageManager
                                        text={"Add more 'Quests'"}
                                        type='button'
                                        language={this.props.user?.features?.language}
                                    />
                                </Button>
                            </span>
                }
            </React.Fragment>
        )
    }

    changeSelectedQualities(quality, category, type='add'){
        var change = false;
        var changedSelectedQualities = [quality];
        changedSelectedQualities.push(this.props.user.currentLeadershipQuality);
        if(this.props.user.additionalLeadershipQuality1?.length > 0) changedSelectedQualities.push(this.props.user.additionalLeadershipQuality1)
        if(this.props.user.additionalLeadershipQuality2?.length > 0) changedSelectedQualities.push(this.props.user.additionalLeadershipQuality2)
        if(this.props.user.additionalLeadershipQuality3?.length > 0) changedSelectedQualities.push(this.props.user.additionalLeadershipQuality3)
        if(this.props.user.additionalLeadershipQuality4?.length > 0) changedSelectedQualities.push(this.props.user.additionalLeadershipQuality4)
        if(this.props.user.additionalLeadershipQuality5?.length > 0) changedSelectedQualities.push(this.props.user.additionalLeadershipQuality5)
        if(this.props.user.additionalLeadershipQuality6?.length > 0) changedSelectedQualities.push(this.props.user.additionalLeadershipQuality6)
        //console.log('before', changedSelectedQualities)
        if (type==='add' && quality !==''){
            change = true;
            //console.log('qualit', quality, this.props.leadershipQualities)
            ExtendDialogueMessage(this.props.firebase, this.props.user, ('Today I want to talk about ' + FindTextValueInDict(this.props.firebase, this.props.leadershipQualities, quality, 'text'))+ ".", 'What do you want to talk about today?', 'utter_ask_improvement', '', 'profile');
        }
        //console.log('after', changedSelectedQualities)
        if(change){
            var userProfileUpdate = {};
            var noDoublesList = [quality];
            userProfileUpdate['currentLeadershipQuality'] = quality;
            userProfileUpdate['currentCategory'] = category;
            userProfileUpdate['additionalLeadershipQuality1'] = this.props.firebase.fieldValue.delete();
            userProfileUpdate['additionalLeadershipQuality2'] = this.props.firebase.fieldValue.delete();
            userProfileUpdate['additionalLeadershipQuality3'] = this.props.firebase.fieldValue.delete();
            userProfileUpdate['additionalLeadershipQuality4'] = this.props.firebase.fieldValue.delete();
            userProfileUpdate['additionalLeadershipQuality5'] = this.props.firebase.fieldValue.delete();
            userProfileUpdate['additionalLeadershipQuality6'] = this.props.firebase.fieldValue.delete();
            var newLeadershipQuality = changedSelectedQualities[0];
            var addIndex = 1;
            changedSelectedQualities.forEach((value, index) => {
                if(index === 0){
                    //userProfileUpdate['currentLeadershipQuality'] = newLeadershipQuality;
                }
                else if (addIndex < 6 && value !== newLeadershipQuality && !noDoublesList.includes(value)){
                    userProfileUpdate['additionalLeadershipQuality'+(addIndex)] = value;
                    noDoublesList.push(value);
                    addIndex+=1;
                }
            })
            if(this.props.user?.uid){
                //console.log(this.context.uid, 'userProfileUpdate', noDoublesList, userProfileUpdate)
                this.props.firebase.user(this.props.user.uid).update(userProfileUpdate);
                this.setState({overwrite: true})
            }
        }
    }

};//end class

export default compose(
    withStyles(styles, { withTheme: true }),
    withFirebase,
)(DialogQuickTopicSetting);

//  module.exports.handleCurrentAchievementGoalOpen = DialogChangeGoal.handleCurrentAchievementGoalOpen();
