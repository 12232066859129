import React from 'react';
import { Route, Switch } from 'react-router-dom';
//import { BrowserRouter as Router, Route } from 'react-router-dom';
import {HashRouter} from 'react-router-dom';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { themeDark } from '../../themes';


import CancelIcon from '@material-ui/icons/Cancel';
import IconButton from '@material-ui/core/IconButton';

import { BreakpointProvider } from 'react-socks';
import Dialog from '@material-ui/core/Dialog';
//import OnboardingPage from '../Onboarding/OnboardingPage';
import OnboardingSelf from '../Onboarding/OnboardingSelf';
import OnboardingQuick from '../Onboarding/OnboardingQuick';
import WelcomePage from '../Onboarding/WelcomePage';
import WelcomeChat from '../Onboarding/WelcomeChat';
import SignUpPage from '../SignUp/SignUpPage';
import SignUpCreatorPage from '../SignUp/SignUpCreatorPage';
import SignUpCoachPage from '../SignUp/SignUpCoachPage';
import SignUpTeamPage from '../SignUp/SignUpTeamPage';
import SignInAlexaPage from '../SignIn/SignInAlexaPage';
import SignInPage from '../SignIn/SignInPage';
import SignInCoachPage from '../SignIn/SignInCoachPage';
import PasswordForgetPage from '../PasswordForget';
import LinkEmailUnsubscribe from '../Unsubscribe/LinkEmailUnsubscribe';
import HomePageAdaptive from '../Home/HomePageAdaptive';
//import HomeContent from '../Content/HomeContent';
import HomeFeed from '../Content/HomeFeed';
import HomeJournal from '../Days/HomeJournal';
import HomeCommunity from '../Community/HomeCommunity';
import HomeStats from '../Charts/HomeStats';
import ChatSupport from '../Support/HomeSupport';
import HomeProfile from '../Profile/HomeProfile';
import HomeProducts from '../Profile/HomeProducts';
import PaymentPage from '../Profile/PaymentPage';
import AdminPage from '../Admin';
import AdminDashboardPage from '../Admin/Dashboard';
import AdminFeedbacksPage from '../Admin/Feedbacks';
import AdminUsersPage from '../Admin/Users';
import AdminTutorialsPage from '../Admin/Tutorials';
import AdminShopPage from '../Admin/Shop';
import AdminNewsPage from '../Admin/News';
import AdminContentPage from '../Admin/Content';
import AdminFlagsList from '../Admin/Content/AdminFlagsList';
import AdminBadgesPage from '../Admin/Badges';
import AdminDialoguesPage from '../Admin/Dialogues';
import AdminLeadershipCategoriesPage from '../Admin/LeadershipCategories';
import AdminLeadershipQualitiesPage from '../Admin/LeadershipQualities';
import AdminLeadershipProgramsPage from '../Admin/LeadershipPrograms';
import AdminTasksPage from '../Admin/Tasks';
import AdminQuestionUtterancesPage from '../Admin/QuestionUtterances';
import AdminQuestionIntentsPage from '../Admin/QuestionIntents';
import AdminIntentStatsPage from '../Admin/QuestionIntents/AdminIntentStatsPage';
import AdminQuestionnairesPage  from '../Admin/Questionnaires';
import AdminTrainingPage from '../Admin/Training/AdminTrainingPage';
import CoachPage from '../Coach';
import ManageCoachContentPage from '../Coach/HomeManageContent';
import ManageCoachAdminPage from '../Coach/ManageCoachAdminPage';
//import CoachUsersPage from '../Coach/Users';
import RoutineWithContent from '../Routine/RoutineWithContent';

import * as ROUTES from '../../constants/routes';
import { withAuthentication } from '../Session';
import RouteWithLayout from '../Layout/RouteWithLayout';
import MainLayout from '../Layout/MainLayout';
import MainLayoutCoach from '../Layout/MainLayoutCoach';

const styles = {
    root: {
        // marginTop: -42,
    },
};


// Global Error Handler Hook
class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        // Disable React's error overlay in development
        if (process.env.NODE_ENV === 'development') {
            window.__REACT_ERROR_OVERLAY__ = null;
            console.log('error overlay disabled in default mode')
        }
        else{
            window.__REACT_ERROR_OVERLAY__ = null;
            console.log('error overlay disabled in default mode')
        }
        this.state = {
            hasError: false,
            errorMessage: '',
        };
    }
    static getDerivedStateFromError(error) {
        return {
            hasError: true,
            errorMessage: error?.message || 'An unexpected error occurred',
        };
    }
    cleanup = () => {
        if (this.errorTimeout) {
            clearTimeout(this.errorTimeout);
        }
        window.onerror = null;
        window.onunhandledrejection = null;
    }
    componentDidMount() {
        //console.log('mounting error boundary')
        const errorHandler = (error, type = 'default') => {
            if (!this.errorCaught) {
                this.errorCaught = true;
                console.error(`ERROR HABNDLER ${type} error caught:`, error);
                
                this.setState({
                    error: error,
                    hasError: true,
                    errorMessage: error?.message || 'An unexpected error occurred'
                });

                // Optional: Reset after some time if needed
                setTimeout(() => {
                    this.errorCaught = false;
                }, 5000);
            }
            return false;
        };
        // Handle synchronous errors
        window.onerror = (message, source, lineno, colno, error) => {
            console.error('Global error:', error);
            return errorHandler(error);
        };
        // Handle unhandled promise rejections
        window.onunhandledrejection = (event) => {
            console.error('Unhandled promise rejection:', event.reason);
            errorHandler(event.reason);
            // prevent React's listener from firing
            event.stopImmediatePropagation();
            event.stopPropagation();
            event.preventDefault();
        }; 
        // Additional event listeners
        const handleUnhandledRejection = (event) => {
            console.log('Unhandled Promise Rejection:', event, event.reason);
            errorHandler(event.reason);
            // prevent React's listener from firing
            event.stopImmediatePropagation();
            event.stopPropagation();
            event.preventDefault();
        };
        const handleGlobalError = (event) => {
            console.error('Uncaught Global Error:', event, event.error);
            errorHandler(event.error);
            // prevent React's listener from firing
            event.stopImmediatePropagation();
            event.stopPropagation();
            event.preventDefault();
        };
        window.addEventListener('unhandledrejection', handleUnhandledRejection);
        window.addEventListener('error', handleGlobalError);
        // Store cleanup functions
        this.cleanup = () => {
            if (this.errorTimeout) {
                clearTimeout(this.errorTimeout);
            }
            window.removeEventListener('unhandledrejection', handleUnhandledRejection);
            window.removeEventListener('error', handleGlobalError);
        };
    }
    componentWillUnmount() {
        console.log('unmounting error boundary')
        if (this.cleanup) {
            this.cleanup();
        }
    }
    render() {
        var {error} = this.state;
        //console.log('Render ERROR', error, this.state)
        if (true || this.state.hasError) {
            return (
                <>
                    <Dialog
                        open={this.state.hasError || false}
                        onClose={()=>{this.setState({hasError: false})}}
                        PaperProps={{style:{borderRadius: 12, padding: 24}}}
                    >
                        <div style={{textAlign: 'right', marginRight: -20, marginTop: -20}}>
                            <IconButton onClick={()=>{this.setState({hasError: false})}}>
                                <CancelIcon />
                            </IconButton>
                        </div>
                        {error?.message?.includes('permission')
                        ?   
                            <div>
                                Sign in to continue
                            </div>
                        :  
                            <div>
                                Hi there! We're sorry, but an unexpected error occurred. Please try again later.
                                {error && <div style={{marginTop: 20, color: 'red'}}>{error.message}</div>}
                                {error && <div style={{marginTop: 20, color: 'black'}}>{error.reason}</div>}
                            </div>
                        }
                        <div style={{marginBottom: 20}}>
                        </div>
                    </Dialog>
                    {this.props.children}
                </>
            )
        }
        return this.props.children;
    }
}

const App = ( component ) => { 
    let colorTheme = component.colorTheme;
    if(component?.themeStyle === 'darkmode'){
        colorTheme = themeDark;
    }
    return (
        <ErrorBoundary>
            <MuiThemeProvider theme={colorTheme}>
                <BreakpointProvider>
                    <HashRouter >
                        {/* <Navigation /> */}
                        <div style={styles.root} >
                            <Switch>
                                <RouteWithLayout exact path={ROUTES.LANDING} 
                                    component={HomePageAdaptive} 
                                    layout={MainLayout}
                                    defaultSet={component?.defaultSet || {}}
                                />
                                <RouteWithLayout exact path={ROUTES.PROGRAM} 
                                    component={HomePageAdaptive} 
                                    layout={MainLayout}
                                    defaultSet={component?.defaultSet || {}}
                                />
                                <RouteWithLayout exact path={ROUTES.HOME} 
                                    component={HomePageAdaptive} 
                                    layout={MainLayout}
                                    defaultSet={component?.defaultSet || {}}
                                />
                                <RouteWithLayout exact path={ROUTES.CONTENT} 
                                    component={HomeFeed} 
                                    layout={MainLayout}
                                    defaultSet={component?.defaultSet || {}}
                                />
                                <RouteWithLayout exact path={ROUTES.JOURNAL} 
                                    component={HomeJournal} 
                                    layout={MainLayout}
                                    defaultSet={component?.defaultSet || {}}
                                />
                                <RouteWithLayout exact path={ROUTES.STATS} 
                                    component={HomeStats} 
                                    layout={MainLayout}
                                    defaultSet={component?.defaultSet || {}}
                                />
                                <RouteWithLayout exact path={ROUTES.MESSAGES} 
                                    component={HomeCommunity} 
                                    layout={MainLayout}
                                    defaultSet={component?.defaultSet || {}}
                                />
                                <RouteWithLayout exact path={ROUTES.SUPPORT} 
                                    component={ChatSupport} 
                                    layout={MainLayout}
                                    defaultSet={component?.defaultSet || {}}
                                />
                                <RouteWithLayout exact path={ROUTES.PROFILE} 
                                    component={HomeProfile} 
                                    layout={MainLayout}
                                    defaultSet={component?.defaultSet || {}}
                                />
                                <RouteWithLayout exact path={ROUTES.PRODUCTS} 
                                    component={HomeProducts} 
                                    layout={MainLayout}
                                    defaultSet={component?.defaultSet || {}}
                                />
                                <Route exact path={ROUTES.PAYMENT} render={() => <PaymentPage defaultSet={component?.defaultSet || {}} />} />
                                <Route exact path={ROUTES.WELCOMECHAT} render={() => <WelcomeChat defaultSet={component?.defaultSet || {}} />} />
                                <Route exact path={ROUTES.WELCOME} render={() => <WelcomePage defaultSet={component?.defaultSet || {}} />}  />
                                <Route exact path={ROUTES.ONBOARDING} render={() => <OnboardingSelf defaultSet={component?.defaultSet || {}} />}  />
                                <Route exact path={ROUTES.ONBOARDQUICK} render={() => <OnboardingQuick defaultSet={component?.defaultSet || {}} />}  />
                                <Route path={ROUTES.COACH_SIGN_UP} render={() => <SignUpCoachPage defaultSet={component?.defaultSet || {}} />}  />
                                <Route path={ROUTES.TEAM_SIGN_UP} render={() => <SignUpTeamPage defaultSet={component?.defaultSet || {}} />}  />
                                <Route path={ROUTES.CREATOR_SIGN_UP} render={() => <SignUpCreatorPage defaultSet={component?.defaultSet || {}} />}  />
                                <Route path={ROUTES.ALEXA_SIGN_IN} render={() => <SignInAlexaPage defaultSet={component?.defaultSet || {}} />}  />
                                <Route exact path={ROUTES.SIGN_UP}
                                    render={() =>
                                        (window.location?.hostname?.includes('coach.rocky.ai') || window.location?.hostname?.includes('admin.rocky.coach'))
                                        ?   <SignUpCoachPage defaultSet={component?.defaultSet || {}}/>
                                        :   <SignUpPage defaultSet={component?.defaultSet || {}}/>
                                    }
                                />
                                <Route exact path={ROUTES.SIGN_IN} render={() =>
                                        (window.location?.hostname?.includes('coach.rocky.ai') || window.location?.hostname?.includes('admin.rocky.coach'))
                                        ?   <SignInCoachPage defaultSet={component?.defaultSet || {}}/>
                                        :   <SignInPage defaultSet={component?.defaultSet || {}}/>
                                    } 
                                />
                                <Route exact path={ROUTES.PASSWORD_FORGET} render={() => <PasswordForgetPage defaultSet={component?.defaultSet || {}} />}  />
                                <Route exact path={ROUTES.LINK_UNSUBSCRIBE_EMAIL} render={() => <LinkEmailUnsubscribe defaultSet={component?.defaultSet || {}} />}  />
                                <Route exact path={ROUTES.ROUTINE} render={(props) => <RoutineWithContent {...props} defaultSet={component?.defaultSet || {}} />} />
                                <RouteWithLayout exact={true} path='*' 
                                    component={HomePageAdaptive} 
                                    defaultSet={component?.defaultSet || {}}
                                    layout={MainLayout}
                                />
                            </Switch>
                        </div>
                    </HashRouter>
                </BreakpointProvider>
            </MuiThemeProvider>
        </ErrorBoundary>
    )
}

export default withAuthentication(App);

const AppAdmin = (component) => {
    let colorTheme = component.colorTheme;
    if(component?.themeStyle === 'darkmode'){
        colorTheme = themeDark;
    }
    //console.log('componetne', colorTheme, component)
    return(
        <ErrorBoundary>
            <MuiThemeProvider theme={colorTheme}>
                <BreakpointProvider>
                    <HashRouter >
                        {/* <Navigation /> */}
                        <div style={styles.root}>
                            <Switch>
                                <RouteWithLayout exact path={ROUTES.LANDING} defaultSet={component?.defaultSet || {}} component={HomePageAdaptive} layout={MainLayout}/>
                                <RouteWithLayout exact path={ROUTES.HOME} defaultSet={component?.defaultSet || {}} component={HomePageAdaptive} layout={MainLayout}/>
                                <RouteWithLayout exact path={ROUTES.PROGRAM} defaultSet={component?.defaultSet || {}} component={HomePageAdaptive} layout={MainLayout}/>
                                <RouteWithLayout exact path={ROUTES.CONTENT} defaultSet={component?.defaultSet || {}} component={HomeFeed} layout={MainLayout}/>
                                <RouteWithLayout exact path={ROUTES.JOURNAL} defaultSet={component?.defaultSet || {}} component={HomeJournal} layout={MainLayout}/>
                                <RouteWithLayout exact path={ROUTES.STATS} defaultSet={component?.defaultSet || {}} component={HomeStats} layout={MainLayout}/>
                                <RouteWithLayout exact path={ROUTES.PROFILE} defaultSet={component?.defaultSet || {}} component={HomeProfile} layout={MainLayout}/>
                                <RouteWithLayout exact path={ROUTES.SUPPORT} defaultSet={component?.defaultSet || {}} component={ChatSupport} layout={MainLayout}/>
                                <RouteWithLayout exact path={ROUTES.MESSAGES} defaultSet={component?.defaultSet || {}} component={HomeCommunity} layout={MainLayout}/>
                                <RouteWithLayout exact path={ROUTES.PRODUCTS} defaultSet={component?.defaultSet || {}} component={HomeProducts} layout={MainLayout}/>
                                <Route exact path={ROUTES.PAYMENT} render={() => <PaymentPage defaultSet={component?.defaultSet || {}} />} />
                                <Route exact path={ROUTES.WELCOMECHAT} render={() => <WelcomeChat defaultSet={component?.defaultSet || {}} />} />
                                <Route exact path={ROUTES.WELCOME} render={() => <WelcomePage defaultSet={component?.defaultSet || {}} />}  />
                                <Route exact path={ROUTES.ONBOARDING} render={() => <OnboardingSelf defaultSet={component?.defaultSet || {}} />}  />
                                <Route exact path={ROUTES.ONBOARDQUICK} render={() => <OnboardingQuick defaultSet={component?.defaultSet || {}} />}  />
                                <Route path={ROUTES.COACH_SIGN_UP} render={() => <SignUpCoachPage defaultSet={component?.defaultSet || {}} />}  />
                                <Route path={ROUTES.TEAM_SIGN_UP} render={() => <SignUpTeamPage defaultSet={component?.defaultSet || {}} />}  />
                                <Route path={ROUTES.CREATOR_SIGN_UP} render={() => <SignUpCreatorPage defaultSet={component?.defaultSet || {}} />}  />
                                <Route path={ROUTES.ALEXA_SIGN_IN} render={() => <SignInAlexaPage defaultSet={component?.defaultSet || {}} />}  />
                                <Route exact path={ROUTES.SIGN_UP}
                                    render={() =>
                                        (window.location?.hostname?.includes('coach.rocky.ai') || window.location?.hostname?.includes('admin.rocky.coach'))
                                        ?   <SignUpCoachPage defaultSet={component?.defaultSet || {}}/>
                                        :   <SignUpPage defaultSet={component?.defaultSet || {}}/>
                                    }
                                />
                                <Route exact path={ROUTES.SIGN_IN} render={() =>
                                        (window.location?.hostname?.includes('coach.rocky.ai') || window.location?.hostname?.includes('admin.rocky.coach'))
                                        ?   <SignInCoachPage defaultSet={component?.defaultSet || {}}/>
                                        :   <SignInPage defaultSet={component?.defaultSet || {}}/>
                                    } 
                                />
                                <Route exact path={ROUTES.PASSWORD_FORGET} render={() => <PasswordForgetPage defaultSet={component?.defaultSet || {}} />}  />
                                <Route exact path={ROUTES.LINK_UNSUBSCRIBE_EMAIL} render={() => <LinkEmailUnsubscribe defaultSet={component?.defaultSet || {}} />}  />
                                <Route exact path={ROUTES.ROUTINE} render={(props) => <RoutineWithContent {...props} defaultSet={component?.defaultSet || {}} />} />
                                
                                <RouteWithLayout exact path={ROUTES.COACH} defaultSet={component?.defaultSet || {}} advancedCondition={true} refreshDefaultSet={component?.refreshDefaultSet !== undefined ? component.refreshDefaultSet : undefined} component={CoachPage} layout={MainLayoutCoach}/>
                                <RouteWithLayout exact path={ROUTES.COACH_CONTENT} defaultSet={component?.defaultSet || {}} advancedCondition={true} refreshDefaultSet={component?.refreshDefaultSet !== undefined ? component.refreshDefaultSet : undefined} component={ManageCoachContentPage} layout={MainLayoutCoach}/>
                                <RouteWithLayout exact path={ROUTES.COACH_ADMIN} defaultSet={component?.defaultSet || {}} advancedCondition={true} refreshDefaultSet={component?.refreshDefaultSet !== undefined ? component.refreshDefaultSet : undefined} component={ManageCoachAdminPage} layout={MainLayoutCoach}/>
                        
                                <Route exact path={ROUTES.ADMIN} render={() => <AdminPage defaultSet={component?.defaultSet || {}} advancedCondition={true} />} />
                                <Route exact path={ROUTES.ADMIN_DASHBOARD} render={() => <AdminDashboardPage defaultSet={component?.defaultSet || {}} advancedCondition={true} />} />
                                <Route exact path={ROUTES.ADMIN_USERS} render={() => <AdminUsersPage defaultSet={component?.defaultSet || {}} advancedCondition={true} />} />
                                <Route exact path={ROUTES.ADMIN_TUTORIALS} render={() => <AdminTutorialsPage defaultSet={component?.defaultSet || {}} advancedCondition={true} />} />
                                <Route exact path={ROUTES.ADMIN_SHOP} render={() => <AdminShopPage defaultSet={component?.defaultSet || {}} advancedCondition={true} />} />
                                <Route exact path={ROUTES.ADMIN_NEWS} render={() => <AdminNewsPage defaultSet={component?.defaultSet || {}} advancedCondition={true} />} />
                                <Route exact path={ROUTES.ADMIN_CONTENTS} render={() => <AdminContentPage defaultSet={component?.defaultSet || {}} advancedCondition={true} />} />
                                <Route exact path={ROUTES.ADMIN_FLAGS} render={() => <AdminFlagsList defaultSet={component?.defaultSet || {}} advancedCondition={true} />}  />
                                <Route exact path={ROUTES.ADMIN_BADGES} render={() => <AdminBadgesPage defaultSet={component?.defaultSet || {}} advancedCondition={true} />}  />
                                <Route exact path={ROUTES.ADMIN_DIALOGUES} render={() => <AdminDialoguesPage defaultSet={component?.defaultSet || {}} advancedCondition={true} />}  />
                                <Route exact path={ROUTES.ADMIN_FEEDBACKS} render={() => <AdminFeedbacksPage defaultSet={component?.defaultSet || {}} advancedCondition={true} />} />
                                <Route exact path={ROUTES.ADMIN_LEADERSHIP_CATEGORIES} render={() => <AdminLeadershipCategoriesPage defaultSet={component?.defaultSet || {}} advancedCondition={true} />}  />
                                <Route exact path={ROUTES.ADMIN_LEADERSHIP_QUALITIES} render={() => <AdminLeadershipQualitiesPage defaultSet={component?.defaultSet || {}} advancedCondition={true} />}  />
                                <Route exact path={ROUTES.ADMIN_LEADERSHIP_PROGRAMS} render={() => <AdminLeadershipProgramsPage defaultSet={component?.defaultSet || {}} advancedCondition={true} />}  />
                                <Route exact path={ROUTES.ADMIN_TASKS} render={() => <AdminTasksPage defaultSet={component?.defaultSet || {}} advancedCondition={true} />}  />
                                <Route exact path={ROUTES.ADMIN_UTTERANCES} render={() => <AdminQuestionUtterancesPage defaultSet={component?.defaultSet || {}} advancedCondition={true} />}   />
                                <Route exact path={ROUTES.ADMIN_INTENTS} render={() => <AdminQuestionIntentsPage defaultSet={component?.defaultSet || {}} advancedCondition={true} />} />
                                <Route exact path={ROUTES.ADMIN_INTENTSTATS} render={() => <AdminIntentStatsPage defaultSet={component?.defaultSet || {}} advancedCondition={true} />}   />
                                <Route exact path={ROUTES.ADMIN_QUESTIONNAIRES} render={() => <AdminQuestionnairesPage defaultSet={component?.defaultSet || {}} advancedCondition={true} />} />
                                <Route exact path={ROUTES.ADMIN_TRAINING} render={() => <AdminTrainingPage defaultSet={component?.defaultSet || {}} advancedCondition={true} />} />
                                        
                                <RouteWithLayout exact={true} path='*' defaultSet={component?.defaultSet || {}} refreshDefaultSet={component?.refreshDefaultSet || undefined} component={HomePageAdaptive} layout={MainLayout}/>
                            </Switch>
                        </div>
                    </HashRouter>
                </BreakpointProvider>
            </MuiThemeProvider>
        </ErrorBoundary>
    )
}

export const AppAdminSet = withAuthentication(AppAdmin);