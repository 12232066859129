import React, { Component } from 'react';
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';

import { AuthUserContext } from '../../Session';
import { withFirebase } from '../../Firebase';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
//import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
//import TextField from '@material-ui/core/TextField';

import IconButton from '@material-ui/core/IconButton';
import BorderColorTwoToneIcon from '@material-ui/icons/BorderColorTwoTone';
import Tooltip from '@material-ui/core/Tooltip';

const styles = theme => ({
    grow: {
        flexGrow: 1,
    },
});


class SettingContentArraySelect extends Component {
    static contextType = AuthUserContext;

    constructor(props) {
        super(props);
        this.state = {
            defaultValue: [],
            openSetting: false,
        };
    }

    // Username Handling
    handleSettingOpen = () => {
        this.props.params.map((p) => {
            if(this.props.content[p]!== undefined){
            return this.setState({[p] : this.props.content[p], [(p+'Change')]: this.props.content[p]});
            }
            else{
                return this.setState({[p] : this.state.defaultValue, [(p+'Change')]:this.state.defaultValue});
            }
        })
        this.setState({
            openSetting: true,
        });
    }

    handleSettingChange = (param, index, event) => {
        var myArray = [...this.state[(param+'Change')]];
        if(index > (this.state[(param+'Change')].length+1) && event.target.value !== ''){
            myArray.push(event.target.value)
            this.setState({
                [(param+'Change')]: myArray
            });
        } 
        else if(event.target.value !== undefined){
            myArray[index] = event.target.value;
        }
        this.setState({
            [(param+'Change')]: myArray
        });
    }

    handleSettingClose = () => {
        this.setState({
            openSetting: false,
        });
        this.props.params.map((p) => {
            return this.setState({[p] : this.state.defaultValue, [p+'Change']:this.state.defaultValue});
        })
    }

    handleSettingSubmit = () => {
        this.props.params.map((p) => {
            if(this.state[(p+'Change')] !== null && this.state[(p+'Change')].length>0 && this.state[(p+'Change')] !== undefined && this.state[(p+'Change')] !== this.props.content[p] ){
                var newArray = this.state[(p+'Change')].filter(function(v){return (v!=='' && v!==undefined)});
                if(this.props.sorting){
                    newArray.sort();
                }
                var updateSet = { [p]: newArray };
                if(this.props.updateExtraChange !== undefined && typeof this.props.updateExtraChange === 'object'){
                    Object.assign(updateSet, this.props.updateExtraChange)
                }
                this.props.dbUpdateLink.update(updateSet);
                //console.log('new setting', newArray)
            }
            return '';
        })        
        this.handleSettingClose();
    }

    render() {
        const { content, params, title, selectOptions, selectOptionsText, labels } = this.props;
        const { theme, classes } = this.props;

        return (
        content
            ?
            <div className={classes.grow}>
                <Card style={theme.cardAdminSettings} onClick={this.handleSettingOpen}>
                    <Grid container>
                        <Grid item xs={9} >
                            <h6><a to="#">{title}</a>
                            {this.props.mandatory 
                                &&  <strong style={{color:theme.palette.themeRed}}>&nbsp;*</strong>
                            }
                            {this.props.mandatory===false
                                &&  <span style={{fontSize: 'small', fontWeight:'normal'}}>&nbsp;(optional)</span>
                            }
                            </h6>
                        </Grid>
                        <Grid item xs={1}>  </Grid>
                        <Grid item xs={2} style={{textAlign: 'right', marginTop: -10}}>
                            {(this.props.access === undefined || this.props.access === true)  &&
                                <Tooltip title={'Edit/Change'}>
                                    <IconButton onClick={this.handleSettingOpen}> 
                                        <BorderColorTwoToneIcon fontSize="small" style={{color:"red"}}/>
                                    </IconButton>
                                </Tooltip>
                            }
                        </Grid>
                    </Grid>
                    {
                        params.map((p, index) => (
                            <p style={theme.textSettings} key={"current_"+p}>
                                {content[p]!== undefined && content[p] instanceof Array
                                    ? 
                                        <span style={{ float: 'right', fontWeight: 700, maxWidth:'75%' }}>
                                            {content[p].map( (item, index) => (
                                                <li key={"sort_"+index}>
                                                    {
                                                        selectOptions!== undefined && selectOptionsText !== undefined && selectOptions.indexOf(item) >= 0
                                                        ?   selectOptionsText[ selectOptions.indexOf(item) ]
                                                        :   item
                                                    }
                                                </li>
                                            ))}
                                            {/**
                                             
                                            [{content[p].join(', ')}]
                                            */} 
                                        </span>
                                    :   <span style={{ float: 'right'}}>{this.props.emptyStage || "[n/a]"}</span>
                                }
                                {this.props.noLabel !== true &&
                                    <span>{labels!==undefined? labels[index]: p}</span>
                                }
                            </p>
                        ))
                    }
                </Card>
                <Dialog
                    open={this.state.openSetting}
                    onClose={this.handleSettingClose}
                    fullWidth
                    maxWidth="sm">
                    <DialogTitle>Change Setting</DialogTitle>
                    <form>
                    <DialogContent>
                        {
                            params.map((p, index) => (
                                
                                <div style={{width: `100%`}} key={"change_"+p}> 
                                <strong>{labels!==undefined? labels[index]: p}</strong>
                                <br/><br/>Selection options 
                                {this.state[p] !== undefined && this.state[p] !== null && Array.isArray(this.state[p]) &&
                                    this.state[p].map((x, indexLow) => (
                                        <div key={indexLow+"_org_"+x+"_"+index}  style={{height: 50}}> 
                                            {this.props.noLabel
                                                ?
                                                    "" + (indexLow+1) + '.'
                                                :
                                                <strong >{this.state[p][indexLow]}</strong>
                                            }
                                            <Select
                                                style={{ float: 'right', width:this.props.noLabel?'90%':'60%'}}
                                                value={this.state[(p+'Change')][indexLow]}
                                                onChange={(e) => this.handleSettingChange(p, indexLow, e)}
                                                name={p}
                                                disabled={((selectOptions=== undefined && !this.context.roles?.includes("ADMIN")) || 
                                                    (selectOptions!== undefined && !(selectOptions.includes(x)) && !this.context.roles?.includes("ADMIN")))
                                                    ?   true:false}
                                                >
                                                {
                                                    selectOptions !== undefined && selectOptions.map((entry, indexDeep) => {
                                                        return (
                                                            <MenuItem key={"select_"+entry} value={entry}
                                                                selected={entry === x} style={{backgroundColor: entry === x ? 'lightgreen' : this.state[p].includes(entry) ? 'lightblue' : 'white'}}
                                                            >
                                                                {
                                                                selectOptionsText !== undefined && selectOptionsText[indexDeep] !== undefined && selectOptionsText[indexDeep] !== ""
                                                                ? selectOptionsText[indexDeep]
                                                                : entry
                                                                }
                                                            </MenuItem>
                                                        );
                                                    })
                                                }
                                                {!(selectOptions.includes(x)) &&
                                                    <MenuItem key={"select_plys_"+x} value={x}
                                                        selected={true}  style={{backgroundColor:  'yellow' }}
                                                    >
                                                        {x + " [NO ACCESS]"}
                                                    </MenuItem>
                                                }
                                                { (indexLow > 0 || this.props.firstItemMandatory!==true )&&
                                                    <MenuItem key={"select_0"+x} value={''}  >
                                                        {'---REMOVE---'}
                                                    </MenuItem>
                                                }
                                                </Select>
                                        </div>
                                    ))
                                }
                                {(this.props.maxNumberOfItems===undefined || (this.state[p]!==undefined && this.props.maxNumberOfItems>this.state[p].length))
                                &&
                                    <Select
                                        style={{ float: 'right', width:'60%'}}
                                        value={this.state[(p+'Change')] !== undefined && this.state[(p+'Change')].length > this.state[p].length
                                        ? this.state[(p+'Change')][(this.state[p].length+1)] 
                                        : ''}
                                        onChange={(e) => this.handleSettingChange(p, (this.state[p].length+1), e)}
                                        name={p}
                                        >
                                        { selectOptions!== undefined && selectOptions.map((entry, indexNew) => {
                                            return (
                                                <MenuItem key={"select_"+entry} value={entry} 
                                                    style={{backgroundColor: this.state[p]!== undefined && this.state[p].includes(entry) ? 'lightblue' : 'white'}}
                                                >
                                                    {
                                                    selectOptionsText !== undefined && selectOptionsText[indexNew] !== undefined && selectOptionsText[indexNew] !== ""
                                                    ? selectOptionsText[indexNew]
                                                    : entry
                                                    }
                                                </MenuItem>
                                            );
                                        })  }
                                        <MenuItem key={"selectadd_0"} value={''} selected={true}>
                                            {'---ADD---'}
                                        </MenuItem>
                                    </Select>
                                }
                                </div>
                            ))
                        }
                        {this.state.errorMessage !== '' && this.state.errorMessage !== undefined
                        ?
                        <p style={theme.textErrorCenterPadding}>
                            {this.state.errorMessage}
                        </p>
                        : <p> </p>
                        }
                    </DialogContent>
                    </form>
                    <DialogActions>
                        <Button onClick={this.handleSettingClose}>
                            Cancel
                        </Button>
                        <Button onClick={this.handleSettingSubmit}>
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        : <div> </div>
                
        );
    }
}

SettingContentArraySelect.propTypes = {
    classes: PropTypes.object.isRequired
};

export default compose(
    withStyles(styles, { withTheme: true }),
    withFirebase
)(SettingContentArraySelect);
