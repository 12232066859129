/**
 * LanguageManager - Handles language selection and translations for the application
 * @class
 * @property {string} currentLanguage - The currently selected language code (e.g. 'en-US', 'es-ES')
 * @property {Object} translations - Object containing all translation key-value pairs
 * @property {string[]} supportedLanguages - Array of supported language codes
 * @property {function} onLanguageChange - Callback function triggered when language changes
 * @property {Object} defaultTranslations - Default fallback translations
 * 
 * @example
 * // Initialize language manager
 * const languageManager = new LanguageManager({
 *   currentLanguage: 'en',
 *   translations: {...},
 *   supportedLanguages: ['en', 'es'],
 *   onLanguageChange: (newLang) => console.log(`Language changed to ${newLang}`)
 * });
 */

import React, { Component } from 'react';
//import { compose } from 'recompose';
import * as chatAgent from '../chatAgent';
import { openDB } from 'idb';

// language
import {franc} from 'franc-min'
import OfflineTranslationsJSON from './translations.json'
import OfflineTranslationsOtherJSON from './translationsother.json'
import OfflineTranslationsTextJSON from './translationstext.json'
import OfflineTranslationsTutorialJSON from './translationstutorial.json'

//import anyAscii from 'any-ascii';
import { transliterate } from 'transliteration';
 /*
 * Generate a short ID from text with length-based strategies
 * @param {string} text - Input text to generate ID from
 * @returns {string} Shortened, lowercase ID
 */

// Helper function to extract text content while preserving HTML tags
export function ExtractHTMLTextChunks(htmlString) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    const parts = [];
    // Regex for symbols that should be separated
    //const separateSymbolsPattern = /[^a-zA-Z\u0080-\u024F\s.,!?]+/g;
    const emojiPattern = /[\p{Emoji}]/gu;
    function processTextContent(text) {
        if (!text) return;
        let segments = text.split(emojiPattern);
        let emojiMatches = text.match(emojiPattern) || [];
        segments.forEach((segment, index) => {
            if (segment) {
            // Find symbols at the beginning
            const startSymbolMatch = segment.match(/^[^a-zA-Z\u0080-\u024F\s.,!?]+/);
            if (startSymbolMatch) {
                parts.push({
                type: 'other',
                content: startSymbolMatch[0]
                });
                segment = segment.slice(startSymbolMatch[0].length);
            }
            // Find symbols at the end
            const endSymbolMatch = segment.match(/[^a-zA-Z\u0080-\u024F\s.,!?]+$/);
            if (endSymbolMatch) {
                if (segment.slice(0, -endSymbolMatch[0].length).trim()) {
                parts.push({
                    type: 'text',
                    content: segment.slice(0, -endSymbolMatch[0].length)
                });
                }
                parts.push({
                type: 'other',
                content: endSymbolMatch[0]
                });
            } else if (segment.trim()) {
                parts.push({
                type: 'text',
                content: segment
                });
            }
            }
            // Add emoji if exists
            if (emojiMatches[index]) {
            parts.push({
                type: 'other',
                content: emojiMatches[index]
            });
            }
        });
    }
    function processNode(node) {
        if (node.nodeType === Node.TEXT_NODE) {
            const text = node.textContent;
            if (text) {
            processTextContent(text);
            }
        } else if (node.nodeType === Node.ELEMENT_NODE) {
            // Add opening tag
            const openTag = `<${node.tagName.toLowerCase()}${getAttributes(node)}>`;
            parts.push({
            type: 'tag',
            content: openTag
            });
            // Process children
            Array.from(node.childNodes).forEach(child => processNode(child));
            // Add closing tag
            const closeTag = `</${node.tagName.toLowerCase()}>`;
            parts.push({
            type: 'tag',
            content: closeTag
            });
        }
    }
    function getAttributes(node) {
        return Array.from(node.attributes)
            .map(attr => ` ${attr.name}="${attr.value}"`)
            .join('');
    }
    processNode(doc.body);
    //console.log('parts', parts)
    return parts;
}

export function GenerateIDShortString(text) {
    // Convert to ASCII Roman characters
    //console.log('GenerateIDShortString')
    var short_id = ""
    if (typeof text === 'string') {
        text = text.replace("-", " ")
        //text = anyAscii("" + text);   
        text = transliterate("" + text);
        const filtered_message = text.replace(/[^.,!?a-zA-Z0-9 -]/g, ''); 
        // Split into words
        const words = filtered_message.split(/\s+/);
        // Short text handling
        if (text.length < 44) {
            // Keep only alphanumeric characters and convert to lowercase
            short_id = filtered_message.replace(/[^0-9a-zA-Z]/g, '').toLowerCase();
        }
        else if (text.length < 88) {
            // Take first three characters of each word
            short_id = words.map(word => word.slice(0, 3).toLowerCase()).join('');
        } 
        else {
            // Take first two characters of each word
            short_id = words.map(word => word.slice(0, 2).toLowerCase()).join('');
        }
        short_id = short_id.replace(/[^0-9a-zA-Z]/g, '').toLowerCase();
    }
    //console.log('GenerateIDShortString finished', short_id)
    return short_id;
}

export const LanguageSets = {
    "en-US" : {
        short: 'en',
        native: "",
        mid: 'eng',
        title: "American English",
        timezones: [
            'Pacific/Honolulu',
            'America/Chicago',
            'America/Denver',
            'America/Detroit',
            'America/Los_Angeles',
            'America/New_York',
            'America/New_York',
            'America/New_York',
            'America/Indianapolis',
            'America/Edmonton',
            'America/Phoenix',
            'America/Toronto',
            'America/Vancouver',
            'America/Winnipeg',
            'America/Regina',
            'America/Halifax',
            'America/Whitehorse',
            'America/Anchorage',
        ],
    },
    "en-GB" : {
        short: 'en',
        mid: 'eng',
        native: "",
        title: "British English",
        timezones: [
            'Europe/London',
            'Europe/Dublin',
            'Australia/Adelaide',
            'Australia/Perth',
            'Australia/Sydney',
            'Australia/Brisbane',
            'Australia/Melbourne',
            'Australia/Darwin',
            'Pacific/Auckland',
            'Asia/Singapore'
        ],
    },
    "hr-HR": {
        short: 'hr',
        mid: 'hrv',
        native: "Hrvatski",
        title: "Croatian",
        timezones: [
            "Europe/Zagreb",
            "Europe/Sarajevo"
        ],
    },
    "bn-BD": {
        short: 'bn',
        mid: 'ben',
        native: "ঢাকা বাঙালি",
        title: "Dhaka Bengali",
        timezones: [
            "Asia/Dhaka",   // Bangladesh
            "Asia/Kolkata"  // West Bengal and Tripura (India)
        ],
    },
    "nl-NL": {
        short: 'nl',
        mid: 'nld',
        title: "Dutch",
        native: "Nederlands",
        timezones: [
            // Netherlands and Belgium
            "Europe/Amsterdam",   // Netherlands
            "Europe/Brussels",    // Belgium
            // Dutch Caribbean
            "America/Aruba",      // Aruba
            "America/Curacao",    // Curaçao
            "America/Kralendijk", // Bonaire
            "America/Paramaribo", // Suriname
        ],
    },
    "fr-FR": {
        short: 'fr',
        mid: 'fra',
        native: "Français",
        title: "French",
        timezones: [
            // France and its territories
            "Europe/Paris",          // France
            "Indian/Reunion",        // Réunion
            "Indian/Mayotte",        // Mayotte
            "Pacific/Noumea",        // New Caledonia
            "Pacific/Tahiti",        // French Polynesia
            "Pacific/Wallis",        // Wallis and Futuna
            "America/Cayenne",       // French Guiana
            "America/Miquelon",      // Saint Pierre and Miquelon
            "America/Guadeloupe",    // Guadeloupe
            "America/Martinique",    // Martinique
            // Belgium, Switzerland, Luxembourg
            "Europe/Brussels",       // Belgium
            "Europe/Monaco",       // Monaco
        ],
    },
    "de-DE": {
        short: 'de',
        mid: 'deu',
        native: "Deutsch",
        title: "German",
        timezones: [
            "Europe/Berlin",
            "Europe/Vienna",
            "Europe/Zurich",
            "Europe/Busingen",
            "Europe/Vaduz",
            "Europe/Luxembourg",     // Luxembourg
        ],
    },
    "el-GR": {
       short: 'el',
       mid: 'ell',
       native: "Ελληνικά",
       title: "Greek",
       timezones: [
           "Europe/Athens",   // Greece
           "Asia/Nicosia"     // Cyprus
       ],
    },
    "he-IL": {
        short: 'he',
        mid: 'heb',
        native: "עברית",
        title: "Hebrew",
        timezones: [
            "Asia/Jerusalem"
        ]
    },
    "hi-IN": {
        mid: 'hi',
        short: 'hin',
        native: "हिंदी",
        title: "Hindi",
        timezones: [
            // India
            "Asia/Kolkata",
            "Asia/Kathmandu",
            // Mauritius (Hindi is spoken by a significant population)
            "Indian/Mauritius"
        ],
    },
    "id-ID": {
        short: 'id',
        mid: 'ind',
        native: "Bahasa Indonesia",
        title: "Indonesian",
        timezones: [
            "Asia/Jakarta",    // Java and Sumatra (Indonesia)
            "Asia/Pontianak",  // Kalimantan (Indonesia)
            "Asia/Makassar",   // Sulawesi (Indonesia)
            "Asia/Balikpapan", // Kalimantan (Indonesia)
            "Asia/Mataram"     // Lombok (Indonesia)
        ],
    },
    "it-IT": {
        short: 'it',
        mid: 'ita',
        native: "Italiano",
        title: "Italian",
        timezones: [
            "Europe/Rome",        // Italy
            "Europe/Vatican",     // Vatican City
            "Europe/San_Marino",  // San Marino
        ],
    },
    "ja-JP": {
        short: 'jp',
        mid: 'jap',
        native: "日本語",
        title: "Japanese",
        timezones: [
            "Asia/Tokyo"
        ],
    },
    "ko-KR": {
        short: 'kr',
        mid: 'kor',
        native: "한국어",
        title: "Korean",
        timezones: [
            "Asia/Seoul",   // South Korea
            "Asia/Pyongyang" // North Korea
        ],
    },
    "zh-CN": {
        short: 'zn',
        mid: 'cmn',
        native: "普通话",
        title: "Mandarin Chinese",
        timezones: [
            // China
            "Asia/Shanghai",   // Mainland China (China Standard Time)
            // Taiwan
            "Asia/Taipei",     // Taiwan
            // Hong Kong
            "Asia/Hong_Kong",  // Hong Kong
            // Macau
            "Asia/Macau"       // Macau
        ],
    },
    "pl-PL": {
        short: 'pl',
        mid: 'pol',
        native: "Polski",
        title: "Polish",
        timezones: [
            "Europe/Warsaw"
        ]
    },
    "pt-PT": {
        short: 'pt',
        mid: 'por',
        native: "Português",
        title: "Portuguese",
        timezones: [
            // Portugal
            "Europe/Lisbon",
            "Atlantic/Madeira",
            "Atlantic/Azores",
            // Brazil
            "America/Sao_Paulo",
            "America/Rio_Branco",
            "America/Manaus",
            "America/Cuiaba",
            "America/Fortaleza",
            "America/Belem",
            // Cape Verde
            "Atlantic/Cape_Verde",
            // Other Portuguese-speaking regions in Africa (use standard African time zones)
            "Africa/Luanda", // Angola
            "Africa/Maputo"  // Mozambique
        ],
    },
    "ru-RU": {
        short: 'ru',
        mid: 'rus',
        native: "Русский",
        title: "Russian",
        timezones: [
             // Russia (various time zones across the country)
            "Europe/Moscow",
            "Europe/Kaliningrad",
            "Europe/Samara",
            "Asia/Yekaterinburg",
            "Asia/Omsk",
            "Asia/Novosibirsk",
            "Asia/Krasnoyarsk",
            "Asia/Irkutsk",
            "Asia/Yakutsk",
            "Asia/Vladivostok",
            "Asia/Magadan",
            "Asia/Sakhalin",
            "Asia/Kamchatka",
            "Asia/Anadyr",
            // Other countries with significant Russian-speaking populations
            "Europe/Minsk",    // Belarus
            "Europe/Simferopol", // Crimea (disputed)
            "Asia/Almaty",     // Kazakhstan
            "Asia/Bishkek",    // Kyrgyzstan
            "Asia/Tashkent",   // Uzbekistan
            "Asia/Ashgabat"    // Turkmenistan
        ],
        //"Europe/Kiev",     // Ukraine (now split into "Europe/Kyiv" for post-2014)  
    },
    "es-ES": {
        short: 'es',
        mid: 'spa',
        native: "Español",
        title: "Spanish",
        timezones: [
            "America/Buenos_Aires",
            "America/Bogota",  // Colombia
            "America/Chihuahua",  // Mexico
            "America/Costa_Rica",
            "America/Dominica",
            "America/El_Salvador",
            "America/Guatemala",
            "America/Guayaquil",  // Ecuador
            "America/Havana",  // Cuba
            "America/La_Paz",  // Bolivia
            "America/Lima",  // Peru
            "America/Managua",  // Nicaragua
            "America/Mexico_City",
            "America/Montevideo",  // Uruguay
            "America/Panama",
            "America/Paraguay",
            "America/Puerto_Rico",
            "America/Santiago",  // Chile
            "America/Santo_Domingo",  // Dominican Republic
            "Atlantic/Canary",  // Canary Islands (Spain)
            "Europe/Madrid",  // Spain
            "Pacific/Easter"  // Easter Island (Chile)
        ],
    },
    "ar-EG": {
        short: 'ar',
        mid: 'arb',
        native: "العربية الفصحى",
        title: "Standard Arabic", 
        timezones: [
            // Middle East
            "Asia/Riyadh",       // Saudi Arabia
            "Asia/Dubai",        // United Arab Emirates
            "Asia/Kuwait",       // Kuwait
            "Asia/Qatar",        // Qatar
            "Asia/Bahrain",      // Bahrain
            "Asia/Baghdad",      // Iraq
            "Asia/Amman",        // Jordan
            "Asia/Damascus",     // Syria
            "Asia/Beirut",       // Lebanon
            // North Africa
            "Africa/Cairo",      // Egypt
            "Africa/Tripoli",    // Libya
            "Africa/Tunis",      // Tunisia
            "Africa/Algiers",    // Algeria
            "Africa/Casablanca", // Morocco
            "Africa/El_Aaiun",   // Western Sahara (disputed)
            // Sudan and neighboring regions
            "Africa/Khartoum",   // Sudan
            "Africa/Juba",       // South Sudan
            // Other Arabic-speaking countries
            "Africa/Mogadishu",  // Somalia
            "Asia/Aden",         // Yemen
            "Asia/Gaza",         // Palestine
            "Asia/Hebron"        // Palestine (Hebron area)
        ],
    },
    "sw-KE": {
        short: 'sw',
        mid: 'swh',
        native: "Kiswahili",
        title: "Swahili",
        timezones: [
            "Africa/Nairobi",
            "Africa/Dar_es_Salaam",
            "Africa/Kampala",
            "Africa/Lubumbashi",
            "Africa/Maputo",
            "Africa/Kigali",
            "Africa/Bujumbura",
            "Indian/Comoro",
            "Africa/Mogadishu",
            "Africa/Juba",
            "Africa/Addis_Ababa"
        ],
    },
    "sv-SE": {
        short: 'sv',
        mid: 'swe',
        native: "Svenska",
        title: "Swedish",
        timezones: [
            "Europe/Stockholm",
            "Europe/Helsinki"
        ],
    },
    "tr-TR": {
        short: 'tr',
        mid: 'tur',
        native: "Türkçe",
        title: "Turkish",
        timezones: [
            "Europe/Istanbul",
        ],
    },
    "uk-UA": {
        short: 'uk',
        mid: 'ukr',
        native: "Українська",
        title: "Ukrainian",
        timezones: [
            "Europe/Kyiv"
        ]
    },
    "vi-VN": {
        short: 'vi',
        mid: 'vie',
        native: "Việt Nam",
        title: "Vietnamese",
        timezones: [
            "Asia/Ho_Chi_Minh"
        ]
    },
}
export const ZonesEnglish = [
    ...LanguageSets["en-US"]['timezones'],
    ...LanguageSets["en-GB"]['timezones']
];
export const ZonesLowCost = [
    "Asia/Calcutta",
    "Asia/Pakistan",
    "Asia/Bangkok",
    "Asia/Beirut",
    "Asia/Dhaka",
    "Asia/Jakarta",
    "Asia/Karachi",
    "Asia/Kuala_Lumpur",
    "Asia/Manila",
    "Asia/Rangood",
    "Asia/Riyadh",
    "Asia/Saigon",
    "Asia/Shanghai",
    "Asia/Tehran",
    "Asia/Tehran",
    "Asia/Tehran",
    "Europe/Moscow",
    "Europe/Istanbul",
    "Europe/Kiev",
    "Africa/Accra",
    "Africa/Cairo",
    "Africa/Johannesburg",
    "Africa/Lagos"
]

export const LanguageDetections = Object.values(LanguageSets).map(entry => entry.mid);

export function BrowserLanguage(timezone){
    //console.log('languageCode', timezone)
    var languageCode = undefined
    if(timezone?.length > 2){
        for (let langKey of Object.keys(LanguageSets)) {
            if (LanguageSets[langKey].timezones?.includes(timezone)) {
                languageCode = langKey;
                break;
            }
        }
    }
    //console.log('languageCode', languageCode)
    return languageCode 
}

export function CheckLanguage(text, preferredLanguageCode, browserLanguageCode){
    //// preferredLanguageCode is stored in user parameters
    //// browserLanguageCode based on timezone
    var languageCodeFranc = franc(text, {
        minLength: 3,
        only: LanguageDetections
        //only: ['eng', 'spa', 'fra', 'deu', 'ita', 'por', 'rus', 'cmn', 'arb', 'ben', 'hin', 'ind', 'nld', 'ell', 'hrv']
    });
    //console.log('LanguageDetections', LanguageDetections)
    //console.log('CheckLanguage', languageCodeFranc, preferredLanguageCode, browserLanguageCode)
    if(browserLanguageCode?.includes('en') && languageCodeFranc === 'eng'){
        if((preferredLanguageCode === 'en-GB')){
            return 'en-GB'
        }
        else {
            return 'en-US'
        }
    }
    else if((preferredLanguageCode === undefined || preferredLanguageCode === '') && languageCodeFranc === 'eng'){
        return 'en-US'
    }
    else if(preferredLanguageCode?.length > 2 && preferredLanguageCode === browserLanguageCode){
        if(languageCodeFranc === 'und'){
            //console.log('languageCode und', preferredLanguageCode)
            return preferredLanguageCode
        }
        else if(LanguageSets?.[preferredLanguageCode]?.mid === languageCodeFranc){
            //console.log('languageCode mid match', preferredLanguageCode)
            return preferredLanguageCode
        }
        else{
            //console.log('languageCode ????', languageCodeFranc, preferredLanguageCode)
            return preferredLanguageCode
        }
    }
    else if (preferredLanguageCode?.length > 2){
        if(languageCodeFranc === 'und'){
            //console.log('languageCode und langsewtting', preferredLanguageCode)
            return preferredLanguageCode
        }
        else if(LanguageSets?.[preferredLanguageCode]?.mid === languageCodeFranc){
            //console.log('languageCode mid match', preferredLanguageCode)
            return preferredLanguageCode
        }
        else{
            return 'und'
        }
    }
    else{
        return 'und'
    }
}

export function PlaceholderTexts(text, username, textNumber, placeholder){
    if (typeof text === 'string') {
        const usernameRegex = /\{username:\s*([^}]+)\}/; // Match {username: Lara}
        const usernameMatch = text.match(usernameRegex);
        if (usernameMatch) {
            //console.log('usernameRegex before', text)
            username = usernameMatch[1]; // Extract username (e.g., "Lara")
            text = text.replace(usernameRegex, "{username}"); // Replace with {username}
        }
        const numberRegex = /\{number:\s*([^}]+)\}/; // Match {number: 34}
        const numberMatch = text.match(numberRegex);
        if (numberMatch) {
            //console.log('numberMatch before', text)
            textNumber = numberMatch[1]; // Extract number (e.g., "34")
            text = text.replace(numberRegex, "{number}"); // Replace with {number}
        }
        const placeholderRegex = /\{placeholder:\s*([^}]+)\}/; // Match {number: 34}
        const placeholderMatch = text.match(placeholderRegex);
        if (placeholderMatch) {
            //console.log('placeholderMatch before', text)
            placeholder = placeholderMatch[1]; // Extract number (e.g., "34")
            text = text.replace(placeholderRegex, "{placeholder}"); // Replace with {placeholder}
        }
    }
    return [text, username, textNumber, placeholder]
}

/**
 * Regular expressions for detecting emojis at string boundaries
 * Matches only proper emojis, excluding:
 * - Numbers
 * - Letters from other languages
 * - Special characters
 * Uses Unicode property 'Emoji' with additional presentation selectors
 */

// Matches emoji at the start of string
export const emojiRegexLeft = /^(?:\p{Emoji_Presentation}|\p{Emoji}\uFE0F)/u;

// Matches emoji at the end of string
export const emojiRegexRight = /(?:\p{Emoji_Presentation}|\p{Emoji}\uFE0F)$/u;

// Initialize IndexedDB
const DB_NAME = 'languageDB';
const STORE_NAME = 'translations';

const showLatestVersion = () => {
    var latestVersion = 1;
    const jsonCreatedAt = OfflineTranslationsJSON?.['000_createdAt']?.createdAt;
    if (jsonCreatedAt > 0){
        latestVersion = jsonCreatedAt;
        window?.localStorage?.setItem('latestTranslationVersion', latestVersion);
    }
    return latestVersion;
};
// Separate function to load translations
const loadTranslations = async (store, db) => {
    //console.log('Loading translations into database...');
    Object.entries(OfflineTranslationsJSON).forEach(([id, data]) => {
        store.add({
            id,
            altids: data.altids || [],
            ...data
        }); 
        //console.log('Translations loading');
    });
    console.log('Translations loaded successfully');
    // Load other languages asynchronously after core is loaded
    Object.entries(OfflineTranslationsTutorialJSON).forEach(([id, data]) => {
        store.add({
            id,
            altids: data.altids || [],
            ...data
        });
    });
    console.log('Translations Tutorial loaded successfully');
    // Load other languages asynchronously after core is loaded
    Object.entries(OfflineTranslationsOtherJSON).forEach(([id, data]) => {
        store.add({
            id,
            altids: data.altids || [],
            ...data
        });
    });
    console.log('Translations Other loaded successfully');
    Object.entries(OfflineTranslationsTextJSON).forEach(([id, data]) => {
        store.add({
            id,
            altids: data.altids || [],
            ...data
        });
    });
    console.log('Translations Text loaded successfully');
}
// Open or create the database
const initDB = async () => {
    //console.log('init db..');
    var latestVersion = (window?.localStorage?.getItem('latestTranslationVersion') || undefined);
    var randomChance = Math.floor(Math.random() * 30);
    if(latestVersion === undefined){
        latestVersion = showLatestVersion()
    }
    else if (randomChance === 0 ) {
        latestVersion = showLatestVersion()
        //console.log('latestVersion', latestVersion)
    }
    return await openDB(DB_NAME, latestVersion, {
        upgrade(db, oldVersion, newVersion) {
            console.log('open domain..', latestVersion, oldVersion, newVersion);
            if(oldVersion !== newVersion) {
                if (db.objectStoreNames.contains(STORE_NAME)) {
                    console.log('Updating translations db...', oldVersion, newVersion);
                    db.deleteObjectStore(STORE_NAME);
                }
            }
            if (!db.objectStoreNames.contains(STORE_NAME)) {
                console.log('Creating new translations db...', latestVersion);
                let store = db.createObjectStore(STORE_NAME, { 
                    keyPath: 'id',
                    autoIncrement: false
                });
                store.createIndex('altids', 'altids', { multiEntry: true });
                loadTranslations(store, db);
            }
        },
    });
};
// Check if a translation exists
const getTranslation = async (shortId) => {
    //console.log('getTranslation start')
    const db = await initDB();
    //console.log('getTranslation inigted done')
    const tx = db.transaction(STORE_NAME, 'readonly');
    const store = tx.objectStore(STORE_NAME);
    let result = undefined;
    try{
        //first lookup the 
        const index = store.index('altids');
        result = await index.get(shortId);
        //console.log('results here', result, shortId)
        if(!result){
            //console.log('no results here', shortId)
            // Then try direct ID lookup
            result = await store.get(shortId);
        }
    }
    catch(e){
        result = await store.get(shortId);
    }
    await tx.done;
    return result;
    //return await db.get(STORE_NAME, `${shortId}`);
};
/// Save or update a translation (extend without overwriting)
const saveTranslation = async (shortId, language, text) => {
    const db = await initDB();
    // Fetch existing data for this ID
    const existingData = await getTranslation(shortId);
    var updatedAltIds = [shortId]
    if (existingData) {
        // Merge existing altids with new ones if provided
         updatedAltIds = [...new Set([...(existingData.altids || []), shortId])]
    }
    const tx = db.transaction(STORE_NAME, 'readwrite');
    const store = tx.objectStore(STORE_NAME);
    // Extend the existing data with the new translation
    const updatedData = {
        ...existingData, // Keep existing translations
        id: shortId,     // Ensure the ID remains intact
        altids: updatedAltIds,
        [language]: text, // Add or update the language translation
    };
    // Save back to IndexedDB
    await store.put(updatedData);
    // Then update all records that share any of the altids
    if (updatedAltIds.length > 0) {
        const altidsIndex = store.index('altids');
        for (const altId of updatedAltIds) {
            const relatedRecords = await altidsIndex.getAll(altId);
            for (const record of relatedRecords) {
                if (record.id !== shortId) {  // Skip the main record we just updated
                    await store.put({
                        ...record,
                        altids: updatedAltIds,  // Update altids
                        [language]: text        // Update translation
                    });
                }
            }
        }
    }
    //console.log('Translation saved:', updatedData);
};

class MemoLanguageManager extends Component {
    _isMounted = true;
    _needUpdate = true;
    constructor(props) {
        super(props);
        var language = 'en-US';
        if(props?.language?.length > 0){
            language = props.language;
        }
        var [sourceText, username, textNumber, placeholder] = PlaceholderTexts(props.text || "")
        var stateSet = {
            text: sourceText, username, textNumber, placeholder, language
        }
        if(language?.includes('en') || (props.sourceLanguage !== undefined && language === props.sourceLanguage)){
            stateSet['preText'] = "";
        }
        //console.log('stateSet', stateSet)
        var updateSet = this.initialState(this.props, sourceText, language, username, textNumber, placeholder);

        this.state = {...stateSet, ...updateSet}
    }
    async initialState(props, sourceText = "", language = 'en-US', username="", textNumber="", placeholder="", stateUpdate = false){
        //console.log('sourceText', sourceText)
        var textPackage={
            sourceText,
            text : sourceText + ""
        }
        if(stateUpdate){
            textPackage={
                sourceText,
                text : sourceText + "",
                username,
                textNumber,
                placeholder
            }
        }
        if(language === 'en-US'){
            textPackage['text'] = sourceText;
        }
        if(stateUpdate && this._isMounted){
            this.setState(textPackage);
        }
        //console.log('textPackage', textPackage)
        if(language?.includes('en') || (props.sourceLanguage !== undefined && language === props.sourceLanguage)){
            textPackage['preText'] = "";
        }
        textPackage = await this.getLanguageText(textPackage, language);
        if(stateUpdate && this._isMounted){
            this.setState(textPackage);
        }
        return textPackage
    }
    componentDidMount() {
        this._isMounted = true;
        //var stateSet = this.initialState(this.props, this.state.sourceText, this.state.language, this.state.username, this.state.textNumber, this.state.placeholder);
        //this.setState(stateSet)
        //console.log('state', this.state)
    }
    componentWillUnmount() {
        this._isMounted = false;
        if(this.unsubscribe!==undefined) this.unsubscribe();
    }
    componentDidUpdate(prevProps){
        var sourceText, username, textNumber, placeholder;
        if(this.props.language !== undefined && this.props.language !== prevProps.language){
            //console.log('changed languages ', this.props.language, prevProps.language, this.props.text)
            this._needUpdate = true;
            if((this.props.language !== undefined && !(this.props.language?.includes('en') && !(this.props.forceEnglish))) && this._isMounted){
                //console.log('changed languages ', this.props.language, prevProps.language, this.props.text)
                this.setState({preText: undefined})
            }
            [sourceText, username, textNumber, placeholder] = PlaceholderTexts(this.props.text || "")
            this.initialState(this.props, sourceText, this.props.language, username, textNumber, placeholder, true);
        }
        else if(this.props.text !== prevProps.text){
            //console.log('changed prevProps text ', this.props.text, prevProps.text, this.props.text)
            this._needUpdate = true;
            if((this.props.language !== undefined && !(this.props.language?.includes('en') && !(this.props.forceEnglish))) && this._isMounted){
                //console.log('changed prevProps text ', this.props, prevProps.text, this.props.text)
                this.setState({preText: undefined})
            }
            [sourceText, username, textNumber, placeholder] = PlaceholderTexts(this.props.text || "")
            //console.log('changed prevProps text ', this.props, prevProps.text, sourceText, this.props.text)
            this.initialState(this.props, sourceText, this.props.language, username, textNumber, placeholder, true);
        }
    }
    shouldComponentUpdate(nextProps, nextState) {  
        if(nextProps.language !== this.state.language){
            //console.log('nextProps', nextProps, 'nextState', nextState)
            this._needUpdate = true;
            return true
        }
        else if(nextProps.text !== this.props.text){
            this._needUpdate = true;
            //console.log('nextProps', nextProps.text, this.props.text)
            return true
        }
        else if (nextState.text === this.state.text && this._needUpdate === true) {  
            ///// in the case that rtranslation is the same text
            this._needUpdate = false
            return true;  
        }  
        else if (nextState.text === this.state.text) {  
            //console.log('no rerender')
            return false;  
        }  
        //console.log(' rerender')
        return true;  
    }

    replaceTemplates(text){
        text = text.replace('{username}', this.state.username)
        text = text.replace('{number}', this.state.textNumber)
        text = text.replace('{placeholder}', this.state.placeholder)
        return text
    }

    async getLanguageText(newtextPackage, language){
        var text = "" + newtextPackage['text'];
        var type_text = (this.props.type || 'text')
        var extensionText = "";
        var preText = "";
        var emojiLeft = "";
        var emojiRight = "";
        if (typeof text === 'string' && text.length > 0 && !(text.includes('http'))){
            const matchRight = text.match(emojiRegexRight);
            if(matchRight){
                //console.log('matchRight', matchRight)
                text = text.slice(0, - matchRight[0].length).trim();
                emojiRight = " " + matchRight[0];
            }
            const matchLeft = text.match(emojiRegexLeft);
            if(matchLeft){
                //console.log('matchLeft', matchLeft)
                text = text.slice(matchLeft[0].length).trim();
                emojiLeft = matchLeft[0] + " ";
            }
            if (text.endsWith(":")) {
                text = text.substring(0, text.length - 1);
                extensionText = ":";
            }
            else if (text.endsWith(" ...")) {
                text = text.substring(0, text.length - 4);
                extensionText = "...";
            }
            else if (text.endsWith("...")) {
                text = text.substring(0, text.length - 3);
                extensionText = "...";
            }
            else if (text.endsWith(" ")) {
                text = text.substring(0, text.length - 1);
                extensionText = " ";
            }
            else if (text.endsWith("...")) {
                text = text.substring(0, text.length - 3);
                extensionText = "...";
            }
            else if (text.endsWith(" ")) {
                text = text.substring(0, text.length - 1);
                extensionText = " ";
            }
            if (text.startsWith(" ")) {
                text = text.substring(1);
                preText = " ";
            }
            if (text.startsWith("... " )) {
                text = text.substring(4);
                preText = preText+"...";
            }
            else if (text.startsWith("...")) {
                text = text.substring(3);
                preText = preText+"...";
            }
            else if (text.startsWith("- ")) {
                text = text.substring(2);
                preText = preText+"- ";
            }
            else if (text.startsWith("-")) {
                text = text.substring(1);
                preText = preText+"-";
            }
            //if(preText !== "") console.log('preText', preText, text)
            text = PlaceholderTexts(text)[0]
            var shortId = GenerateIDShortString(text)
            if(this._isMounted && shortId?.length > 0 && language === undefined){
                //console.log('undefined- CASE', language, text)
                this.setState({ text: text, preText, extensionText, emojiLeft, emojiRight } , 
                    () => {this._needUpdate = false}
                );
                if(this.props.handlerUpdate !== undefined){
                    this.props.handlerUpdate(this.replaceTemplates(text))
                }
                return {
                    ...newtextPackage, 
                    text, 
                    preText, 
                    extensionText, 
                    language, 
                    emojiLeft, 
                    emojiRight  
                }
            }
            else if(this._isMounted && shortId?.length > 0){
                const existingTranslation = await getTranslation(shortId, language);
                if (existingTranslation?.[language]?.length > 0) {
                    //console.log('existingTranslation here', language, existingTranslation?.[language], shortId)
                    if(this._isMounted){
                        //console.log('Local translation available', shortId, existingTranslation[language]);
                        this.setState({ text: existingTranslation[language], preText, extensionText, language, emojiLeft, emojiRight  } , 
                            () => {this._needUpdate = false}
                        );
                        if(this.props.handlerUpdate !== undefined){
                            this.props.handlerUpdate(this.replaceTemplates(existingTranslation[language]))
                        }
                    }
                    return {
                        ...newtextPackage, 
                        text: existingTranslation[language], 
                        preText, 
                        extensionText, 
                        language, 
                        emojiLeft, 
                        emojiRight 
                    }
                }
                else if(this.props.sourceLanguage !== undefined && language === this.props.sourceLanguage){
                    if(this._isMounted){
                        //console.log('sourceLanguage case', this.props.sourceLanguage)
                        this.setState({ text: text, preText, extensionText, language, emojiLeft, emojiRight  } , 
                            () => {this._needUpdate = false}
                        );
                        if(this.props.handlerUpdate !== undefined){
                            this.props.handlerUpdate(this.replaceTemplates(text))
                        }
                    }
                    return {
                        ...newtextPackage, 
                        text, 
                        preText, 
                        extensionText, 
                        language, 
                        emojiLeft, 
                        emojiRight  
                    }
                }
                else if(!(this.props.forceEnglish) && language?.includes("en-")){
                    if(this._isMounted){
                        //console.log('en- CASE', language)
                        this.setState({ text: text, preText, extensionText, language, emojiLeft, emojiRight  } , 
                            () => {this._needUpdate = false}
                        );
                        if(this.props.handlerUpdate !== undefined){
                            this.props.handlerUpdate(this.replaceTemplates(text))
                        }
                    }
                    return {
                        ...newtextPackage, 
                        text, 
                        preText, 
                        extensionText, 
                        language, 
                        emojiLeft, 
                        emojiRight  
                    }
                }
                else{
                    //console.log('localDefaultSet need to search', shortId)
                    var packageTranslation = {
                        'texts': [text], 
                        'source_language': (this.props.sourceLanguage || 'en-US'), 
                        'target_language': language,
                        'type_text' : type_text
                    }
                    this._needUpdate = true;
                    chatAgent.sendMessage(packageTranslation, 'requestTranslationAI').then(extractionResults => {
                        //console.log('extractionResults', extractionResults)
                        if(extractionResults?.translations?.length > 0){
                            const translatedText = extractionResults.translations[0];
                            if(this._isMounted){
                                this.setState({ text: translatedText, preText, extensionText, language, emojiLeft, emojiRight } , 
                                    () => {this._needUpdate = false}
                                );
                                if(this.props.handlerUpdate !== undefined){
                                    this.props.handlerUpdate(this.replaceTemplates(translatedText))
                                }
                            }
                            // Save translation to IndexedDB
                            saveTranslation(shortId, language, translatedText);
                            // Optionally export the data as a JSON file
                            //exportJsonFile();
                        }
                    })
                    return{}
                }
            }
            else{
                console.log('preText', text, preText, shortId)
                return {
                    ...newtextPackage, 
                    text, 
                    preText, 
                    extensionText,
                    language, 
                    emojiLeft, 
                    emojiRight 
                }
            }
        }
        else{
            return {
                ...newtextPackage, 
                text, 
                preText, 
                extensionText, 
                language, 
                emojiLeft, 
                emojiRight  
            }
        }
    }
    render() {
        var text = (this.state.text || this.props.text || "");
        if(text === undefined || text === ""){
            return ""
        }
        else{
            if (typeof text === 'string') {
                text = this.replaceTemplates(text);
                if(this.props.type === 'button'){
                    //console.log('button pr4e', text)
                    text = text.replace(/['"]([^'"\s]+(?:\s+[^'"\s]+)*?)['"]/g, '$1');
                    //console.log('button after', text)
                    /***
                     * 
                     * This regex will:

                            Only match quotes that come after a space or start of string (?<=\s|^)
                            Capture the word inside (\w+)
                            Match closing quote before a space or end of string (?=\s|$)
                            Replace the entire match with just the captured word $1

                            Examples of how it works:

                            'word' → word
                            "hello" → hello
                            I'm → I'm (unchanged)
                            don't → don't (unchanged)
                            'don't' → don't
                    */
                }
            }
            if(this._needUpdate && this.state.preText===undefined){
                //console.log('blur state', this.state.text , this.props.text, this.state)
                return(
                    <span style={{filter: 'blur(3px)', opacity: 0.7}}>
                        {this.state.emojiLeft}{this.state.preText || ""}
                        {(this.props.limit > 0 && text?.length > this.props.limit)
                            ?   (text.substring(0, (this.props.limit-3)) + '...')
                            :   text
                        }
                        {this.state.extensionText || ""}{this.state.emojiRight}
                    </span>
                )
            }
            //console.log('updatre state', this.state.text || this.props.text, this.state)
            else{
                return (
                    <>
                        {this.state.emojiLeft}{this.state.preText || ""}
                        {(this.props.limit > 0 && text?.length > this.props.limit)
                            ?   (text.substring(0, (this.props.limit-3)) + '...')
                            :   text
                        }
                        {this.state.extensionText || ""}{this.state.emojiRight}
                    </>
                )
            }
        }
    }
}

// Wrap the class component with React.memo
const LanguageManager = React.memo((props) => {
    return(
        <MemoLanguageManager {...props} />
    )},
    (prevProps, nextProps) => {
        if (nextProps.language === prevProps.language && nextProps.text === prevProps.text) {
            //console.log('memorize ', prevProps.text)
            return true; // props are equal
        }
        //console.log('not memorize ', nextProps.language, prevProps.language, nextProps.text, prevProps.text)
        return false; // props are not equal -> update the component
    }
)

export default LanguageManager;