import React, { Component } from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { AuthUserContext } from '../Session';
import { withFirebase } from '../Firebase';
import { AddAnalytics } from '../Modules/AddAnalytics';
import LanguageManager from '../../constants/languages';

import withStyles from '@material-ui/core/styles/withStyles';
//import Card from '@material-ui/core/Card';
import LoadingProgress from '../Modules/LoadingProgress';
import JournalActivitiesItem from './JournalActivitiesItem';
import { IsMobileDevice } from '../Modules/IsMobileDevice';
import { DataCacheRead, DataCacheWrite} from "../Modules/DataCache";
import Grid from '@material-ui/core/Grid';
import AssessmentSlider from '../Charts/AssessmentSlider';
import AssessmentTimeline from '../Charts/AssessmentTimeline';

import BadgeCard from '../Modules/BadgeCard';
import DialogChangeGoal from '../Goals/DialogChangeGoal';
import DialogSettingEmail from '../Profile/DialogSettingEmail';
import NotificationsOffIcon from '@material-ui/icons/NotificationsOff';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import GoalOverview from '../Goals/GoalOverview';
import imageSettingGoal from "../../images/settinggoal.png";

import {CheckXPLevel} from '../Charts/XPLevels';
import SnackbarInfo from '../Modules/SnackbarInfo';
import DialogTutorialPage from '../Onboarding/DialogTutorialPage';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

import * as ROUTES from '../../constants/routes';
import { Link } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import AdjustRoundedIcon from '@material-ui/icons/AdjustRounded';
//popup
import {RemoveScroll} from 'react-remove-scroll';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
//import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Slide from '@material-ui/core/Slide';
import { FindTextValueInDict } from '../Modules/LoadCategoriesAndQualities';
import { CheckMobileNotificationActivated } from '../Modules/StoreMobileDeviceInfo';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const styles = theme => ({
    grow: {
        flexGrow: 1,
    },
})

class JournalIntentionCheck extends Component {
    static contextType = AuthUserContext;
    _isMounted = false;

    constructor(props) {
        super(props);
        var backgroundIcon = imageSettingGoal;
        //console.log('probs in JournalWeek ',this.probs);
        this.state = {
            loading: false,
            allowRefresh: true,
            emptyData: false,
            backgroundIcon,
            loadingText: 'loading activities',
            journeyFollowUps: [],
            journeyFollowUpsHide : [],
            journeyFollowUpsDone : [],
            journeyFollowUpsFailed : [],
            journeyFollowUpsImportant : [],
            journeyFollowUpsUrgent : [],
            xpLevels: {},
            goalAssessedPoint: 0
        };
    }

    componentDidMount() {
        this._isMounted = true;
        const xpLevels = {
            followUp: CheckXPLevel(this.context, 'followUp'),
        }
        this.setState({ loading: true, xpLevels });
        if(this.context?.achievementSelf?.icon?.length > 0){
            var backgroundImage = FindTextValueInDict(this.props.firebase, this.props.leadershipCategories, this.context.currentCategory, 'url');
            if(backgroundImage?.includes('http')){
                this.setState({ backgroundImage });
            }
        }
        if(this.context?.achievementSelf?.icon?.length > 0){
            var backgroundIcon = this.context.achievementSelf.icon;
            this.setState({ backgroundIcon });
        }
        this.loadActionSet();
    }

    loadActionSet = () => {
        var updatedActions = {};
        var limit = 24;
        if(this.props.historyLimit>1){
            limit = this.props.historyLimit;
        }
        var query = this.props.firebase
        .journeyFollowUps()
        .where("userId","==",this.context.uid)
        if(this.props.specialQuery === 'contentByIds' && this.props.contentIds !== undefined && this.props.contentIds.length > 0){
            query = query.where("contentId",'in', this.props.contentIds)
        }
        else if(this.props.specialQuery === 'contentByIds' && (this.props.contentIds !== undefined  || (this.props.contentIds !== undefined && this.props.contentIds.length === 0))){
            var fakeContentId = "sdfnoirgbnreiobnerbneigmrpfomrg53";
            query = query.where("contentId",'in', [fakeContentId])
        }
        else{
            //query = query.where("important", '==', true)
        }
        query = query.orderBy('createdAt', 'desc')
        if(this.props.historyLimit !== undefined && this.props.historyLimit > 0){
            limit = this.props.historyLimit
        }
        ///// write into cache parameter set and Loading ///////////////////
        let actionsCache = {};
        var cacheName = 'actionsCache_'+limit+this.props.contentIds +'_'+this.context.uid;
        var cacheStore = DataCacheRead(cacheName)
        if(cacheStore.cacheAvailable && cacheStore.cacheData !== undefined) {
            actionsCache = cacheStore.cacheData;
            this.setState(actionsCache);
        }
        var initFollowedUpCounter = 0;
        var initFollowtoCounter = 0;
        // ###################
        var keys = ['achievementGoal'];
        keys.forEach( key => {
            if(key!== undefined && key !== ""){
                initFollowtoCounter += 1;
                cacheStore = DataCacheRead('newAssess_goals_'+key+'_'+this.context.uid)
                //console.log("read",cacheStore)
                if(cacheStore!== null && cacheStore.isCacheExpired!== true && cacheStore.cacheData > 0){
                    if(this._isMounted){
                        // this item is only the achievementGoal which is now the param goalAssessedPoint
                        if(key === 'achievementGoal'){ 
                            this.setState({goalAssessedPoint: 1, ['measure_'+key] : cacheStore.cacheData, ['lastMeasure_'+key] : cacheStore.cacheData}) ;
                        }
                        else{
                            initFollowedUpCounter += 1;
                            this.setState({['measure_'+key] : cacheStore.cacheData, ['lastMeasure_'+key] : cacheStore.cacheData}) ;
                        }
                    }
                }
            }
        })
        var followedUpCounter = initFollowedUpCounter;
        this.setState({initFollowedUpCounter})
        var followtoCounter = initFollowtoCounter;
        //////////////////////////////////////////////////////////////////
        query = query.limit(limit*3);
        //console.log(query)
        // weekly Journals
        //query.get().then(snapshot => {
        this.unsubscribe = query
        .onSnapshot(snapshot => {
            if(this._isMounted){
                if (snapshot.size) {
                    followedUpCounter = this.state.initFollowedUpCounter;
                    followtoCounter = initFollowtoCounter;
                    let journeyFollowUps = [];
                    let journeyFollowUpsPreview = [];
                    let journeyFollowed = [];
                    let journeyFollowUpsHide = [];
                    let journeyFollowUpsDone = [];
                    let journeyFollowUpsFailed = [];
                    let journeyFollowUpsImportant = [];
                    let journeyFollowUpsUrgent = [];
                    var currentDate = new Date();
                    var visualLimit = limit;
                    if(this.props.visualLimit>0){
                        visualLimit = this.props.visualLimit;
                    }
                    /// minus six hour same day
                    //currentDate.setHours(currentDate.getHours()-5);
                    //// reduce Date by 36 hours
                    currentDate = new Date(currentDate - 36 * 60 * 60 * 1000);
                    snapshot.forEach(doc =>{
                        var item = doc.data();
                        //console.log('first intnet', item.intention)
                        if(true || (item.intentClass !== undefined && 
                            (
                                item.intentClass.includes('activit') || item.intentClass.includes('reason') || item.intentClass.includes('intention') || item.intentClass.includes('goal') || item.intentClass.includes('challenge')
                            )
                        )
                        ){
                            if(visualLimit > 0){
                                if(item.hide) {
                                    journeyFollowUpsHide.push({ ...item, id: doc.id });
                                }
                                else if(item.done ) {
                                    //journeyFollowUpsDone.push({ ...item, id: doc.id });
                                    journeyFollowed.push({ ...item, id: doc.id });
                                }
                                else if(item.failed ) {
                                    journeyFollowUpsFailed.push({ ...item, id: doc.id });
                                }
                                else if(item.important || item.urgent) {
                                    followtoCounter += 1;
                                    visualLimit -= 1;
                                    //console.log('intention', item.intention)
                                    if(item.createdAt !== undefined && item.createdAt !== null){
                                        var dialogDate = new Date(item.createdAt.seconds * 1000);
                                        if(currentDate < dialogDate && (item.onTrack || item.offTrack)){
                                            //journeyFollowed.unshift({ ...item, id: doc.id });
                                            journeyFollowUpsImportant.unshift({ ...item, id: doc.id });
                                            followedUpCounter +=1;
                                            if(journeyFollowUpsPreview.length < 3){
                                                journeyFollowUpsPreview.push({ ...item, id: doc.id })
                                            }
                                        }
                                        else{
                                            journeyFollowUpsImportant.push({ ...item, id: doc.id });
                                        }
                                    }
                                    else{
                                        journeyFollowUpsImportant.push({ ...item, id: doc.id });
                                    }
                                }
                                else{
                                    followtoCounter += 1;
                                    visualLimit -= 1;
                                    journeyFollowUps.push({ ...item, id: doc.id });
                                    if(journeyFollowUpsPreview.length < 3){
                                        journeyFollowUpsPreview.push({ ...item, id: doc.id })
                                    }
                                }
                            }
                        }
                    } );
                    if(this.state.allowRefresh){
                        updatedActions = {
                            followedUpCounter,
                            followtoCounter,
                            journeyFollowUps,
                            journeyFollowUpsPreview,
                            journeyFollowed,
                            journeyFollowUpsDone,
                            journeyFollowUpsFailed,
                            journeyFollowUpsImportant,
                            journeyFollowUpsUrgent,
                            journeyFollowUpsHide,
                            journeyFollowUpsTemp: journeyFollowUps,
                            journeyFollowUpsDoneTemp: journeyFollowUpsDone,
                            journeyFollowUpsFailedTemp: journeyFollowUpsFailed,
                            journeyFollowUpsImportantTemp: journeyFollowUpsImportant,
                            journeyFollowUpsUrgentTemp: journeyFollowUpsUrgent,
                            journeyFollowUpsHideTemp: journeyFollowUpsHide,
                            loading: false,
                            emptyData: false
                        };
                        if(journeyFollowUps.length < 1){
                            updatedActions["emptyData"] = true;
                        }
                        if(journeyFollowUpsUrgent.length > 0){
                            updatedActions['compactViewSelection'] = "journeyFollowUpsUrgent";
                        }
                        else if(journeyFollowUpsImportant.length > 0){
                            updatedActions['compactViewSelection'] = "journeyFollowUpsImportant";
                        }
                        else if(journeyFollowUps.length > 0){
                            updatedActions['compactViewSelection'] = "journeyFollowUps";
                        }
                        else if(journeyFollowUpsDone.length > 0){
                            updatedActions['compactViewSelection'] = "journeyFollowUpsDone";
                        }
                        if(this._isMounted){
                            this.setState(updatedActions);
                        }
                        ///// write into cache parameter set and Loading ///////////////////
                        DataCacheWrite('actionsCache_'+limit+this.props.contentIds+'_'+this.context.uid, updatedActions, 2)
                    }
                    else{
                        updatedActions = {
                            journeyFollowUpsTemp: journeyFollowUps,
                            journeyFollowUpsDoneTemp: journeyFollowUpsDone,
                            journeyFollowUpsFailedTemp: journeyFollowUpsFailed,
                            journeyFollowUpsImportantTemp: journeyFollowUpsImportant,
                            journeyFollowUpsUrgentTemp: journeyFollowUpsUrgent,
                            journeyFollowUpsHideTemp: journeyFollowUpsHide,
                            loading: false,
                            emptyData: false
                        };
                        if(this._isMounted){
                            this.setState(updatedActions);
                        }
                    }
                    if(this.props.updateScore!== undefined){
                        var resultScore = followedUpCounter/followtoCounter*100;
                        if(resultScore> 100)resultScore = 100;
                        else if(resultScore<0) resultScore =0;
                        //console.log('update', resultScore);
                        this.props.updateScore(resultScore);
                    }
                    //console.log('update',this.state);
                } //end snapshot size
                else {
                    updatedActions = {
                        journeyFollowUps: null, 
                        loading: false, 
                        emptyData: true 
                    };
                    if(this._isMounted ){
                        this.setState(updatedActions);
                    }
                    //console.log('no data loaded for journal week');
                    ///// write into cache parameter set and Loading ///////////////////
                    DataCacheWrite('actionsCache_'+limit+this.props.specialQuery+'_'+this.context.uid, updatedActions, 2)
                }//end no size
            }//end ismounted
        });  
    }


    componentDidUpdate(prevProps)  {
        var newContentIds = '';
        var oldContentIds = '';
        if(this.props.contentIds !== undefined && this.props.contentIds !== ""){
            newContentIds = this.props.contentIds
        }
        if(prevProps.contentIds !== undefined && prevProps.contentIds !== ""){
            oldContentIds = prevProps.contentIds
        }
        if(newContentIds !== oldContentIds ) {
            if (this.unsubscribe !== undefined){
                this.unsubscribe()
            } 
            this.loadActionSet();
        }
        if( this._isMounted && this.props.open && prevProps.open !== true && this.state.open !== true){
            this.setState({open: true, openedFirst: true})
            if(!this.state.openedFirst){
                AddAnalytics('screen_view', 'PageJourney');
            }
        }
        return true
    }

    componentWillUnmount() {
        this._isMounted = false;
        if (this.unsubscribe !== undefined){
            this.unsubscribe()
        } 
        this.setState({ loading: false });
    }

    handleClose = () => {
        this.setState({ open: false });
    }

    render(){
        var user = this.props.user || this.context;
        const { theme } =  this.props;
            return(
                <div>
                    {this.state.xpLevels?.followUp?.valid !== true
                        &&
                        <SnackbarInfo 
                            history= {this.props.history}
                            alertText = {this.state.alertText || 'Try to collect more XP.'}
                            openOnClick = {true}
                            alertOpenEvent = {this.state.alertOpenEvent || false}
                            closeHandler = {() => this.setState({alertOpenEvent: false})}
                            buttonText={this.props.avatarGreyOut ? 'Unlock' : undefined}
                            buttonLink={{
                                pathname: ROUTES.PROFILE,
                                state: {showSubscriptions: true}
                            }}
                        />
                    }
                    <Grid container 
                        direction="row"
                        justifyContent="space-between"
                        alignItems="flex-start"
                        spacing={2}
                    >
                        {this.props.showRecentGoalsOnly !== true 
                        &&
                        <>
                            <Grid item xs={9}>
                                <h4 style={theme.CardContent.titleText}>
                                    <LanguageManager
                                        text={this.props.title?.length > 2 ? this.props.title : 'Vision and Journey'} 
                                        type='button'
                                        language={this.context?.features?.language}
                                    />
                                </h4>
                            </Grid>
                            <Grid item xs={3} style={{textAlign: 'right'}}>
    
                            </Grid>
                        </>
                        }
                        {this.props.showRecentGoalsOnly
                            ?
                                <Grid item xs={12}>
                                    {this.renderJourneyGoals()}
                                </Grid>
                            :
                            <>
                                <Grid item xs style={{minWidth: 'calc(200px + 12vw)', width: '100%'}}>
                                    <BadgeCard
                                        backgroundColor ={"white"}
                                        image={imageSettingGoal} 
                                        avatarImage = { undefined  }
                                        title = {"My Vision"}
                                        subtitle = {
                                            (this.props.user.achievementGoal !== undefined && this.props.user.achievementGoal.length > 54)
                                                ?   (this.props.user.achievementGoal.substring(0,53) + "...")
                                                :   this.props.user.achievementGoal 
                                        }
                                        strongSubtitle = {false}
                                        onClick={ () => {
                                            if(this.context.roles?.includes("COACHEE")){ void(0)}
                                            else{this.setState({openCurrentAchievementGoal : true})}
                                            //else{ this.props.history.push(ROUTES.PROFILE) }
                                        } }
                                        clickText= {this.context.roles?.includes("COACHEE")
                                            ?   undefined
                                            :   "Change"
                                        }
                                    />
                                    <DialogChangeGoal 
                                        openCurrentAchievementGoal = {this.state.openCurrentAchievementGoal || false}
                                        leadershipQualities={this.props.leadershipQualities}
                                        handleCurrentAchievementGoalClose = {() => this.setState({openCurrentAchievementGoal:false})}
                                        user={this.props.user}
                                        />
                                    <div style={{...theme.buttonCenter, marginBottom: 30, marginTop: 30}} 
                                        onClick={() => {this.setState({open:true})}}
                                    >
                                        {this.state.followtoCounter!== undefined && this.state.followtoCounter>0 && (this.state.followedUpCounter >= (this.state.followtoCounter-1))
                                        ?
                                            <Button >
                                                <CheckCircleOutlineIcon fontSize="large" />
                                                &nbsp;
                                                <LanguageManager
                                                    text={"Show Journey"} 
                                                    type='button'
                                                    language={this.context?.features?.language}
                                                />
                                            </Button>
                                        :
                                            <Button>
                                                <AdjustRoundedIcon fontSize="large" />
                                                &nbsp;
                                                <LanguageManager
                                                    text={"Show Journey"} 
                                                    type='button'
                                                    language={this.context?.features?.language}
                                                />
                                            </Button>
                                    }
                                    </div>
                                    <p style={theme.textSupportCenter}> 
                                        <LanguageManager
                                            text={"North-star goal"} 
                                            type='button'
                                            language={this.context?.features?.language}
                                        />
                                        &nbsp;&bull;&nbsp;
                                        <LanguageManager
                                            text={"Journal"} 
                                            type='button'
                                            language={this.context?.features?.language}
                                        />
                                        &nbsp;&bull;&nbsp;
                                        <LanguageManager
                                            text={"Goal-Tracking"} 
                                            type='button'
                                            language={this.context?.features?.language}
                                        />
                                    </p>
                                </Grid>
                                {this.props.type === 'quick'
                                    ?
                                        <p style={{color: theme.palette.themeBlue}}> 
                                            <q>{this.context.achievementGoal || '... add your personal vision'}</q>
                                        </p>
                                    :   ''
                                }
                                {this.state.journeyFollowUpsPreview?.length > 0 
                                &&
                                    <Grid item xs style={{minWidth: 'calc(150px + 12vw)', width: '100%', marginBottom: 0, marginTop: 10}}>
                                        <div style={{...theme.buttonCenter, marginTop: 0}} >
                                            {
                                                this.renderList(this.state.journeyFollowUpsPreview, '', '', true)
                                            }
                                        </div>
                                        <div style={{width: '100%', textAlign: 'center'}}>
                                            <DialogSettingEmail 
                                                handleOpen = {this.state.openEmailSettings || false}
                                                leadershipQualities={this.props.leadershipQualities}
                                                handleClose = {() => this.setState({openEmailSettings:false})}
                                                user={this.context}
                                            />
                                            <div style={{...theme.buttonCenter, marginBottom: 30, marginTop: 30}} 
                                                onClick={() => {this.setState({openEmailSettings:true})}}
                                            >
                                                <Button>
                                                    {CheckMobileNotificationActivated(this.context)
                                                        ?
                                                            <>
                                                                <NotificationsNoneIcon fontSize="small"/>
                                                                &nbsp;
                                                                <LanguageManager
                                                                    text= {"Change Reminders"}
                                                                    type='button'
                                                                    language={this.context?.features?.language}
                                                                />
                                                            </>
                                                        :
                                                            <>
                                                                <NotificationsOffIcon fontSize="large" style={{color: "red"}}/>
                                                                &nbsp;
                                                                <LanguageManager
                                                                    text= {"Set Reminders"}
                                                                    type='button'
                                                                    language={this.context?.features?.language}
                                                                />
                                                            </>
                                                    }
                                                </Button>
                                            </div>    
                                        </div>
                                        {this.props.linkToContent !== false
                                            &&
                                            <div style={{width: '100%', textAlign: 'right', whiteSpace: 'nowrap'}}>
                                                <Link to={ROUTES.JOURNAL}>
                                                    <LanguageManager
                                                        text= {"Show All"}
                                                        type='button'
                                                        language={this.context?.features?.language}
                                                    />
                                                    &nbsp;&gt;
                                                </Link>
                                            </div>
                                        }
                                    </Grid>
                                }
                            
                            </>
                        }
                    </Grid>
                    <RemoveScroll enabled={this.state.open || false}>
                    <Dialog
                        key={this.state.actionId}
                        open={this.state.open || false}
                        onClose={this.handleClose}
                        fullScreen={IsMobileDevice()}
                        PaperProps={{style:theme.dialogWindow}}
                        TransitionComponent={Transition}
                    >
                        <DialogTitle style={theme.dialogTitle}  >
                            <Grid container spacing={0} direction="row"
                                justifyContent="space-between"
                                alignItems="center">
                                <Grid item >
                                    <IconButton onClick={this.handleClose}>
                                        <ArrowBackIcon />
                                    </IconButton>
                                </Grid>
                                <Grid item>
                                    <span style={theme.dialogTitle.titleText}>
                                        {this.props.title?.length > 2 ? this.props.title : 'Vision and Journey'} 
                                    </span>
                                </Grid>
                                <Grid item>
                                    <IconButton style={{marginRight: 2}} onClick={() => {this.setState({handleOpenTutorial: true})}}>
                                        <HelpOutlineIcon />
                                    </IconButton>
                                    {/***
                                    <span style={theme.dialogTitle.titleLink} onClick={this.handleClose}>
                                        Close
                                    </span>
                                     */}
                                </Grid>
                            </Grid>
                        </DialogTitle>
                        {this.state.open &&
                            <DialogContent style={theme.dialogContent}>
                                {!this.state.loading  
                                ?
                                    <div style={{marginTop: 20, paddingLeft: 0, paddingRight:0}}>
                                        <GoalOverview
                                            user={this.context}
                                            defaultSet={this.props.defaultSet || undefined}
                                            features={['goal']}
                                            type='quick'
                                            leadershipCategories={this.props.leadershipCategories}
                                            leadershipQualities={this.props.leadershipQualities}
                                        />
                                        {
                                            this.renderGoalAssessment(this.state.journeyFollowed, '', '')
                                        }
                                        {   
                                            this.renderJourneyGoals()
                                        }
                                        {
                                            this.renderTimeline(this.state.journeyFollowed, '', '')
                                        }
                                    </div>
                                :   
                                    <div>
                                        <LoadingProgress 
                                            defaultSet={this.props.defaultSet}
                                            type={'LoadingPageAnimated'} loadingText={"loading intentions..."} />
                                    </div>
                            }
                            </DialogContent>
                        }
                    </Dialog>
                    </RemoveScroll>
                    <DialogTutorialPage 
                        defaultSet={this.props.defaultSet}
                        handleOpen={this.state.handleOpenTutorial || false}
                        handleBadgeFamily = {'followup'}
                        handleClose={() => {this.setState({handleOpenTutorial: false})}}
                    />
                </div>
            )
    }

    renderJourneyGoals(){
        const {theme} = this.props;
        return(
            <>
                {
                    this.renderList(this.state.journeyFollowUpsImportant, 'Important Goals',  "Swipe for status or Click to change", true)
                }
                {
                    this.renderList(this.state.journeyFollowUps, 'Recent Goals', "Swipe for status or Click to change", true)
                }
                {
                    this.renderList(this.state.journeyFollowed, 'Victories', 'Accomplished sub-goals')
                }
                {false &&
                    <p style={theme.textSupportCenter}> 
                        Goal tracking &bull; Priorities
                    </p>
                }
            </>
        )
    }

    allowRefreshAndUpdate = (keyWord = undefined) => {
        if(this._isMounted){
            //console.log('updateat content')
            this.setState({
                journeyFollowUps : this.state.journeyFollowUpsTemp,
                journeyFollowUpsDone : this.state.journeyFollowUpsDoneTemp,
                journeyFollowUpsFailed : this.state.journeyFollowUpsFailedTemp,
                journeyFollowUpsImportant : this.state.journeyFollowUpsImportantTemp,
                journeyFollowUpsUrgent : this.state.journeyFollowUpsUrgentTemp,
                journeyFollowUpsHide : this.state.journeyFollowUpsHideTemp,
            });
            if(keyWord!== undefined && keyWord!==""){
                this.setState({
                    allowRefresh: true,
                    ['activeStep_'+keyWord]  : 0
                });
            }
            else{
                this.setState({
                    allowRefresh: true,
                });
            }
        }
    }

    renderList = (block, keyWord, comment, showTips=false) => {
        const {theme} = this.props;
        if(block!== undefined && block!== null && block.length> 0){
            return(
                <div>
                    {keyWord?.length > 0 &&
                        <h4>
                            {keyWord}
                        </h4>
                    }
                    {comment?.length > 0 &&
                        <p style={theme.textSupportCenter}>
                            {comment}
                        </p>
                    }
                    {block!== undefined  && block.flatMap((item, index) => {
                        if(this.props.visualLimit === undefined || (this.props.visualLimit>0 && this.props.visualLimit >= index+1)){
                            return(
                                <JournalActivitiesItem 
                                    user={this.props.user}
                                    defaultSet={this.props.defaultSet || undefined}
                                    checkMode={true}
                                    allowRefresh={() => {this.allowRefreshAndUpdate(keyWord)}}
                                    blockRefresh={() => {this.setState({allowRefresh:false})}}
                                    key={item.id} 
                                    cta={item} 
                                    ctaId={item.id}
                                    showTips={showTips}
                                    specialQuery= {this.props.specialQuery} 
                                    leadershipCategories={this.props.leadershipCategories}
                                    leadershipQualities={this.props.leadershipQualities}
                                    handlerMoment = {this.props.handlerMoment || undefined}
                                    />    
                            ) 
                        }
                        else{
                            return ''
                        }
                    })}
                    {(block=== undefined || block.length < 1)
                        &&
                        <span>Nothing to check in this list.</span>
                    }
                    {this.props.linkToContent === true &&
                        <Link 
                            onClick={() => {
                                if(this.props.handleChangeTab !== undefined){
                                    this.props.handleChangeTab(keyWord === "Victories" ? "Victories" : "Sub-Goals")
                                }
                            }}
                            to={
                                {
                                    pathname: ROUTES.JOURNAL,
                                    state: {startTab: keyWord === "Victories" ? "Victories" :"Sub-Goals"}
                                }
                            } 
                            style={{float: 'right'}}
                        >
                            See all &gt;
                        </Link>
                    }
                </div>
            )
        }
        else return ""
    }

    renderGoalAssessment = (block, keyWord, comment) => {
        return(
            <div>
                <h4>
                    {keyWord}
                </h4>
                <p>
                    {comment}
                </p>
                {
                    <AssessmentSlider
                        circular = {true}
                        user = {this.props.user}
                        assessmentKey = 'achievementGoal'
                        assessmentText = {this.context.achievementGoal}
                        hideAssementText = {true}
                        assessmentQuestion = 'Is your vision becoming a reality?'
                        hideAssementQuestion = {false}
                        updateScore = { (score) => { 
                            this.loadActionSet();
                            this.setState({goalAssessedPoint: 1});
                        } }
                    />
                }
            </div>
        )
    }

    renderTimeline = (block, keyWord, comment) => {
        return(
            <div>
                {
                    <AssessmentTimeline
                        user = {this.props.user }
                        assessmentKey = 'achievementGoal'
                        //assessmentText = {this.context.achievementGoal}
                        assessmentText = {'Journey and Vision Diary'}
                        assessmentQuestion = 'How satisfied are you currently with:'
                        updateScore = { (score) => { 
                            this.loadActionSet();
                            //if (this.props.updateScore !== undefined) this.props.updateScore(score)
                            this.setState({goalAssessedPoint: 1});
                        }}
                    />
                }
            </div>
        )
    }
}

export default compose(
    withFirebase,
    withRouter,
    withStyles(styles, { withTheme: true })
)(JournalIntentionCheck);