import React, { Component } from 'react';
import { compose } from 'recompose';
import LanguageManager from '../../constants/languages';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import { StoreMobileDeviceToken, RequestNotificationPermission, CheckMobileNotificationActivated } from '../Modules/StoreMobileDeviceInfo';

import { AuthUserContext } from '../Session';
import { withFirebase } from '../Firebase';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import List from '@material-ui/core/List';


import { SelectOption, SelectWrapper } from '../Modules/SelectOption';
import TextField from '@material-ui/core/TextField';
import Switch from "react-switch";


const styles = theme => ({
    grow: {
        flexGrow: 1,
    },
});


class SettingEmail extends Component {
    _isMounted = false;
    static contextType = AuthUserContext;

    constructor(props) {
        super(props);
        this.state = {
            currentNotificationFrequency: (props.user?.emailNotifications || 'weekly'),
            currentMobileNotificationFrequency: (props.user?.mobileNotifications || 'casually'),
            primingTime: '',
            reflectionTime: '',
            //default not touching
            openSetting: false,
            newPrimingTime: "08:45",
            newReflectionTime: "19:45",
        };
    }

    componentDidMount() {
        this._isMounted = true;
        //console.log("context", this.context)
        this.props.firebase.insight(this.context.uid).get()
        .then(doc => {
            if (doc.exists && this._isMounted
                && doc.data().insightsPriming!== undefined
                && doc.data().insightsReflection!== undefined
                && doc.data().insightsPriming.last4Weeks!== undefined
                && doc.data().insightsReflection.last4Weeks!== undefined
                ) {
                var insightsSet = doc.data();
                var primingTimeDefault = (insightsSet?.insightsPriming?.last4Weeks?.timeLocalMean || "09:15");
                this.setState({primingTime: primingTimeDefault});
                if(this.props.user?.emailPrimingReminderTime !== undefined){
                    this.setState({newPrimingTime: this.props.user.emailPrimingReminderTime})
                }
                else{
                    this.setState({newPrimingTime: primingTimeDefault})
                }
                //console.log('default loaded', defaultSet);
                var reflectionTimeDefault = (insightsSet?.insightsReflection?.last4Weeks?.timeLocalMean || "19:15");
                this.setState({reflectionTime: reflectionTimeDefault});
                if(this.props.user?.emailReflectionReminderTime !== undefined)
                    {this.setState({newReflectionTime: this.props.user.emailReflectionReminderTime})}
                else{
                    this.setState({newReflectionTime: reflectionTimeDefault})
                }
                
            } 
            else if(this._isMounted) {
                this.setState({primingTime: "07:45"});
                if(this.props.user?.emailPrimingReminderTime!==undefined){
                    this.setState({newPrimingTime: this.props.user.emailPrimingReminderTime})}
                else{
                    this.setState({newPrimingTime: "07:45"})
                }
                this.setState({reflectionTime: "18:45"});
                if(this.props.user?.emailReflectionReminderTime!==undefined){
                    this.setState({newReflectionTime: this.props.user.emailReflectionReminderTime})
                }
                else{
                    this.setState({newReflectionTime: "18:45"})
                }
            }
            if(this._isMounted){
                this.setState({
                    currentNotificationFrequency: (this.props.user.emailNotifications || 'weekly') ,
                    currentMobileNotificationFrequency: (this.props.user.mobileNotifications || 'casually'),
                    reflectionDay : (this.props.user.reflectionDay || 'thu'), 
                    primingDay : (this.props.user.primingDay || 'tue'),
                });
            }
        });//end of fb then
    }

    componentWillUnmount(){
        this._isMounted = false;
    }

    handleSettingOpen = () => {
        this.setState({
            openSetting: true,
            errorMessage : '',
            currentNotificationFrequency: this.props.user.emailNotifications,
            currentMobileNotificationFrequency: this.props.user.mobileNotifications,
        });
    }

    handleSettingChange = event => {
        this.setState({
            errorMessage : '',
            currentNotificationFrequency: event.target.value
        },
            () => this.handleSettingSubmit()
        );
    }

    handleMobileSettingChange = event => {
        RequestNotificationPermission(this.props.firebase, this.props.firebase.user(this.context.uid));
        StoreMobileDeviceToken(this.props.firebase.user(this.context.uid), true);
        //console.log('event', event)
        this.setState({
            openSelect: false,
            errorMessage : "",
            currentMobileNotificationFrequency: event.target.value
        },
            () => this.handleSettingSubmit()
        );
        //console.log('event', event, this.state)
    }

    handleSettingClose = () => {
        this.setState({
            openSetting: false,
            code: '',
        });
        if(this.props.handleClose !== undefined){
            this.props.handleClose();
        }
    }

    handleTimePrimingChange = date => {
        this.setState({
            errorMessage : '',
            primingDate: date,
        })
    };

    handleSettingSubmit = () => {
        var settingUpdate = {};
        if(this.state.currentMobileNotificationFrequency!== '' && this.state.currentMobileNotificationFrequency!==undefined){
            settingUpdate = {
                lastEmailSettingAt : this.props.firebase.fieldValue.serverTimestamp(),
                mobileNotifications: this.state.currentMobileNotificationFrequency,
                emailNotifications: this.state.currentNotificationFrequency,
                emailNotificationsInitiated: false, 
                mobileNotificationsInitiated: false
            };
        }
        else{
            settingUpdate = {
                lastEmailSettingAt : this.props.firebase.fieldValue.serverTimestamp(),
                emailNotifications: this.state.currentNotificationFrequency,
                emailNotificationsInitiated: false
            };
        }

        if(this.state.primingDay!==undefined ){
            settingUpdate['primingDay'] = this.state.primingDay;
        }
        if(this.state.reflectionDay!==undefined ){
            settingUpdate['reflectionDay'] = this.state.reflectionDay;
        }
        
        if(this.state.newPrimingTime!=="" && this.state.newPrimingTime!== this.state.primingTime){
            settingUpdate['emailPrimingReminderTime'] = this.state.newPrimingTime;
        }
        else{
            this.setState({newPrimingTime: this.state.primingTime})
            settingUpdate['emailPrimingReminderTime'] = "";
        }
        if(this.state.newReflectionTime!=="" && this.state.newReflectionTime!== this.state.reflectionTime){
            settingUpdate['emailReflectionReminderTime'] = this.state.newReflectionTime;
        }
        else{
            this.setState({newReflectionTime: this.state.reflectionTime})
            settingUpdate['emailReflectionReminderTime'] = "";
        }
        //console.log(settingUpdate)
        this.props.firebase.user(this.context.uid).update(settingUpdate);
        this.setState({errorMessage : 'Changes saved', showSaveButton: false, showCloseButton: true})
    }

    showSpanSelect(textMobileNotificationSettings, notificationPermission){
        return(
            <span 
                style={{ float: 'right', pointerEvents: (this.state.openSelect !== true? true : false) }}
                onClick={() => {if(this.state.openSelect !== true ) this.setState({openSelect: true})}}
            >
                <div style={{display: "none"}}>
                    {textMobileNotificationSettings.map(entry => (
                        <LanguageManager
                            key={entry.key}
                            text={entry.value}
                            type='button'
                            language={this.context?.features?.language}
                            handlerUpdate={(text) =>  this.setState({['textMobileNotificationSettings_'+entry.value]: text})}
                        />
                    ))}
                    <LanguageManager
                        text={"Suppressed"}
                        type='button'
                        language={this.context?.features?.language}
                        handlerUpdate={(text) =>  this.setState({['textMobileNotificationSettings_suppressed']: text})}
                    />
                    <LanguageManager
                        text={"none"}
                        type='button'
                        language={this.context?.features?.language}
                        handlerUpdate={(text) =>  this.setState({['textMobileNotificationSettings_none']: text})}
                    />
                </div>
                <SelectWrapper
                    value={notificationPermission === false ? 'suppressed' : this.state.currentMobileNotificationFrequency}
                    onChange={this.handleMobileSettingChange}
                    open={this.state.openSelect || false}
                    onClose={() => this.setState({openSelect: false})}
                    onOpen={() => this.setState({openSelect: true})}
                    name='notificationSettings'
                >
                    {textMobileNotificationSettings?.map(entry => {
                        return (
                            <SelectOption key={entry.key} value={entry.key}
                                selected={notificationPermission && entry.key === this.state.currentMobileNotificationFrequency}
                            >
                                {this.state['textMobileNotificationSettings_'+entry.value] || entry.value}
                            </SelectOption>
                        );
                    })}
                    {notificationPermission === false
                    &&
                        <SelectOption key={'suppressed'} value={'suppressed'}
                            selected={notificationPermission === false}
                        >
                            {this.state['textMobileNotificationSettings_suppressed'] || "Suppressed"}
                            &nbsp;({this.state['textMobileNotificationSettings_none'] || "none"})
                        </SelectOption>
                    }
                </SelectWrapper>
            </span>
        )
    }

    render() {
        const { user } = this.props;
        const { theme, classes } = this.props;

        //console.log(this.state)
        // specific for this setting
        const notificationSettings = [
            { key: "daily", value: "Daily" },
            { key: "casually", value: "Twice a week"},
            { key: "weekly", value: "Weekly"},
            { key: "fixed", value: "Fixed schedule" },
            { key: "inactive", value: "Paused"},
            { key: "never", value: "Never"},
        ];
        const mobileNotificationSettings = [
            { key: "daily", value: "Daily" },
            { key: "casually", value: "Twice a week"},
            { key: "weekly", value: "Weekly"},
            { key: "fixed", value: "Fixed schedule" },
            { key: "inactive", value: "Paused"},
        ];
        const weekdays = [
            { key: "mon", value: "Monday" },
            { key: "tue", value: "Tuesday"},
            { key: "wed", value: "Wednesday"},
            { key: "thu", value: "Thursday" },
            { key: "fri", value: "Friday"},
            { key: "sat", value: "Saturday"},
            { key: "sun", value: "Sunday"},
        ];
        var notificationPermission = false;
        if(CheckMobileNotificationActivated(user)){
            notificationPermission = true;
        }
        return (
        user
        ?
                <div className={classes.grow}>
                    <Card style={theme.cardSettings}>
                        <List style={theme.textSettings}>
                            <span style={{float: 'right'}}>{user.timezone===undefined || user.timezone===""? 'not available' : user.timezone}</span>
                            <span style={theme.textSettings.settingsTextDescription}>
                                <LanguageManager
                                    text={"Timezone"}
                                    type='button'
                                    language={this.context?.features?.language}
                                />
                                :</span>
                        </List>
                        <p>&nbsp;</p>
                        <form>
                            <h6>
                                <LanguageManager
                                    text={"Push Notifications"}
                                    type='button'
                                    language={this.context?.features?.language}
                                />
                            </h6>
                            {(true || user.mobileDeviceToken?.length > 20 || user.mobilePWAToken?.length > 20)
                            &&
                                <List style={theme.textSettings}>
                                    {this.showSpanSelect(mobileNotificationSettings, notificationPermission)}
                                    <span style={theme.textSettings.settingsTextDescription}>
                                        <LanguageManager
                                            text={"App Notifications"}
                                            type='button'
                                            language={this.context?.features?.language}
                                        />
                                        :
                                    </span>
                                    {!(user.mobileDevicePushNotificationAllowed === true || user.mobilePWAPushNotificationAllowed === true)
                                    ?
                                        <div style={{width: "100%", marginTop: 40}}>
                                            {((user.mobileDevicePushNotificationAllowed === true || user.mobilePWAPushNotificationAllowed === true) && !notificationPermission)
                                                &&
                                                <p style={{color: theme.palette.themeAlertRed}}>
                                                    <LanguageManager
                                                        text={"Change your notification frequency"}
                                                        type='button'
                                                        language={this.context?.features?.language}
                                                    />
                                                </p>
                                            }
                                            {!(user.mobilePWAPushNotificationAllowed === true) && !(user.mobilePWAToken?.length > 20) && user.mobileDevicePushNotificationAllowed !== true
                                                ?
                                                <p style={{color: theme.palette.themeAlertRed}}>
                                                    <LanguageManager
                                                        text={"You need to activate the notification permission in your system or browser settings!"}
                                                        type='tutorial'
                                                        language={this.context?.features?.language}
                                                    />
                                                </p>
                                                :   !(user.mobileDevicePushNotificationAllowed === true) && !(user.mobileDeviceToken?.length > 20)
                                                    ?
                                                        <p style={{color: theme.palette.themeAlertRed}}>
                                                            <LanguageManager
                                                                text={"You need to activate the notification permission in your app settings!"}
                                                                type='tutorial'
                                                                language={this.context?.features?.language}
                                                            />
                                                        </p>
                                                    :   ""
                                            }
                                        </div>
                                    :   !notificationPermission
                                        ?
                                            <div style={{width: "60%"}}>
                                                {((user.mobileDevicePushNotificationAllowed === true || user.mobilePWAPushNotificationAllowed === true) && !notificationPermission)
                                                    &&
                                                    <span style={{color: theme.palette.themeAlertRed}}>
                                                        <LanguageManager
                                                            text={"Change your notification frequency!"}
                                                            type='tutorial'
                                                            language={this.context?.features?.language}
                                                        />
                                                    </span>
                                                }
                                            </div>
                                        :   ""
                                    }
                                </List>
                            }
                            <p>&nbsp;</p>
                            <h6>
                                <LanguageManager
                                    text={"Email Notifications"}
                                    type='button'
                                    language={this.context?.features?.language}
                                />
                            </h6>
                            {!(user.mobileDeviceToken?.length > 2 || user.mobilePWAToken?.length > 2) && user.anonymousSignup !== true 
                            &&
                                <List style={theme.textSettings}>
                                    <span style={{ float: 'right'}}>
                                    <Switch
                                        checked={this.state.currentNotificationFrequency === 'never' ? false : true}
                                        onColor={theme.palette.themeBlue}
                                        value={this.state.currentNotificationFrequency}
                                        onChange={(c, event, id) => {
                                            this.setState({errorMessage: '', currentNotificationFrequency : this.state.currentNotificationFrequency === 'never'? 'casually' : 'never'});
                                            this.props.firebase.user(this.context.uid).update({emailNotifications: c ? 'casually' : 'never'});
                                        }}
                                        inputProps={{ 'aria-label': 'Allow Email Notifications' }}
                                    />
                                    </span>
                                    <span style={theme.textSettings.settingsTextDescription}>
                                        <LanguageManager
                                            text={"Allow Email Notifications"}
                                            type='button'
                                            language={this.context?.features?.language}
                                        />
                                        :
                                    </span>
                                </List>
                            }
                            {(user.mobileDeviceToken?.length > 2 || user.mobilePWAToken?.length > 2) 
                            &&
                                <List style={theme.textSettings}>
                                    <span style={{ float: 'right'}}>
                                        <Switch
                                            checked={this.state.currentNotificationFrequency === 'never' ? false : true}
                                            onColor={theme.palette.themeBlue}
                                            onChange={(c, event, id) => {
                                                this.setState({errorMessage: '', currentNotificationFrequency : this.state.currentNotificationFrequency === 'never'? 'casually' : 'never'});
                                                this.props.firebase.user(this.context.uid).update({emailNotifications: c? 'casually' : 'never'});
                                            }}
                                        />
                                    </span>
                                    <span style={theme.textSettings.settingsTextDescription}>
                                        <LanguageManager
                                            text={"Allow Email Notifications"}
                                            type='button'
                                            language={this.context?.features?.language}
                                        />
                                        :
                                    </span>
                                    <div style={{width: "60%"}}>
                                        <p style={theme.textSupportGrey}>
                                            <LanguageManager
                                                text={"When app notifications not available"}
                                                type='tutorial'
                                                language={this.context?.features?.language}
                                            />
                                        </p>
                                    </div>
                                </List>
                            }
                            {(true || !(user.mobileDeviceToken?.length > 2 || user.mobilePWAToken?.length > 2) || this.state.currentMobileNotificationFrequency === 'inactive')
                                &&
                                <>
                                    <div style={{display: "none"}}>
                                        {notificationSettings.map(entry => (
                                            <LanguageManager
                                                key={entry.key}
                                                text={entry.value}
                                                type='button'
                                                language={this.context?.features?.language}
                                                handlerUpdate={(text) =>  this.setState({['textNotificationSettings_'+entry.value]: text})}
                                            />
                                        ))}
                                        <LanguageManager
                                            text={"Inactive"}
                                            type='button'
                                            language={this.context?.features?.language}
                                            handlerUpdate={(text) =>  this.setState({['textNotificationSettings_inactive']: text})}
                                        />
                                        <LanguageManager
                                            text={"Change Email"}
                                            type='button'
                                            language={this.context?.features?.language}
                                            handlerUpdate={(text) =>  this.setState({['textNotificationSettings_changeemail']: text})}
                                        />
                                    </div>
                                    <List style={theme.textSettings}>
                                        <span style={theme.textSettings.settingsFixedTextValue}>
                                            <SelectWrapper
                                                value={this.state.currentNotificationFrequency}
                                                onChange={this.handleSettingChange}
                                                name='notificationSettings'
                                            >
                                                {user.anonymousSignup === true 
                                                    ?   <SelectOption key={'inactive'} value={'inactive'}  > 
                                                            {(this.state['textNotificationSettings_inactive'] || "Inactive")
                                                            + " (" +
                                                            (this.state['textNotificationSettings_changeemail'] || "Change email")
                                                            + ")"}
                                                        </SelectOption>
                                                    :
                                                        notificationSettings.map(entry => {
                                                            return (
                                                                <SelectOption key={entry.key} value={entry.key} >
                                                                    {this.state['textNotificationSettings_'+entry.value] || entry.value}
                                                                </SelectOption>
                                                            );
                                                        })
                                                }
                                            </SelectWrapper>
                                        </span>
                                        <span style={theme.textSettings.settingsTextDescription}>
                                            <LanguageManager
                                                text={"Email Frequency"}
                                                type='button'
                                                language={this.context?.features?.language}
                                            />
                                            :
                                        </span>
                                    </List>
                                </>
                            }
                            <p>&nbsp;</p>
                            <h6>
                                <LanguageManager
                                    text={"Preferred Reminder Time"}
                                    type='button'
                                    language={this.context?.features?.language}
                                />
                            </h6>
                            {weekdays.map(entry => (
                                <div style={{display: "none"}} key={entry.key}>
                                    <LanguageManager
                                        key={entry.key}
                                        text={entry.value}
                                        type='button'
                                        language={this.context?.features?.language}
                                        handlerUpdate={(text) =>  this.setState({['textWeekdays_'+entry.value]: text})}
                                    />
                                </div>
                            ))}
                            {(this.state.currentMobileNotificationFrequency === 'fixed' || this.state.currentNotificationFrequency === 'fixed') 
                                &&
                                <List style={theme.textSettings} key="prime">
                                    <span style={theme.textSettings.settingsFixedTextValue}>
                                        <SelectWrapper
                                            value={this.state.primingDay || 'mon'}
                                            onChange={(event) => {this.setState({primingDay : event.target.value, showSaveButton: true})}}
                                            name='primingDay'
                                        >
                                            {weekdays.map((entry) => (
                                                <SelectOption key={entry.key} value={entry.key}>
                                                    {this.state['textWeekdays_'+entry.value] || entry.value}
                                                </SelectOption>
                                            ))}
                                        </SelectWrapper>
                                    </span>
                                    <span style={theme.textSettings.settingsTextDescription}>
                                        <LanguageManager
                                            text={"Mindset Day"}
                                            type='button'
                                            language={this.context?.features?.language}
                                        />
                                        :
                                    </span>
                                </List>
                            }
                            {this.state.newPrimingTime !== undefined  &&
                                <List style={theme.textSettings}>
                                    <span style={theme.textSettings.settingsFixedTextValue}>
                                    <TextField
                                        id="priming"
                                        type="time"
                                        variant="outlined"
                                        style={ {fontSize: '16px'} }
                                        min={user.primingFrom}
                                        max={user.primingUntil}
                                        defaultValue={this.state.newPrimingTime}
                                        onChange={e => {this.setState({newPrimingTime: e.target.value, showSaveButton: true})}}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            step: 300, // 5 min
                                            style: {
                                                paddingTop: 6,
                                                paddingBottom: 6,
                                            }
                                        }}
                                    />
                                    </span>
                                    <span style={theme.textSettings.settingsTextDescription}>
                                        <LanguageManager
                                            text={"Coaching Chat Reminder"}
                                            type='button'
                                            language={this.context?.features?.language}
                                        />
                                        :
                                    </span>
                                    <div style={{width: "60%"}}>
                                        <p style={theme.textSupportGrey}>
                                        <LanguageManager
                                            text={"Average morning mindset check-in time"}
                                            type='tutorial'
                                            language={this.context?.features?.language}
                                        />
                                        :&nbsp;
                                        <span style={theme.textSupport}>{this.state.primingTime} </span></p>
                                    </div>
                                </List>
                            }
                            {(this.state.currentMobileNotificationFrequency === 'fixed' || this.state.currentNotificationFrequency === 'fixed' )
                            &&
                                <List style={theme.textSettings} key="reflect">
                                    <span style={theme.textSettings.settingsFixedTextValue}>
                                        <SelectWrapper
                                            value={this.state.reflectionDay || 'thu'}
                                            onChange={(event) => this.setState({reflectionDay : event.target.value, showSaveButton: true})}
                                            name='reflectionDay'
                                            
                                        >
                                            {weekdays.map(entry => (
                                                <SelectOption key={entry.key} value={entry.key}
                                                >
                                                    {this.state['textWeekdays_'+entry.value] || entry.value}
                                                </SelectOption>
                                            ))}
                                        </SelectWrapper>
                                    </span>
                                    <span style={theme.textSettings.settingsTextDescription}>
                                        <LanguageManager
                                            text={"Reflection Day"}
                                            type='button'
                                            language={this.context?.features?.language}
                                        />
                                        :
                                    </span>
                                </List>
                            }
                            {this.state.newReflectionTime !== undefined  
                            &&
                                <List style={theme.textSettings}>
                                    <span style={theme.textSettings.settingsFixedTextValue}>
                                        <TextField
                                            id="reflection"
                                            type="time"
                                            variant="outlined"
                                            style={ {fontSize: '16px'} }
                                            min={user.reflectionFrom}
                                            max={user.reflectionUntil}
                                            defaultValue={this.state.newReflectionTime}
                                            onChange={e => {this.setState({newReflectionTime: e.target.value, showSaveButton: true})}}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            inputProps={{
                                                step: 300, // 5 min
                                                style: {
                                                    paddingTop: 6,
                                                    paddingBottom: 6,
                                                }
                                            }}
                                        />
                                    </span>
                                    <span style={theme.textSettings.settingsTextDescription}>
                                        <LanguageManager
                                            text={"Reflective Chat Reminder"}
                                            type='button'
                                            language={this.context?.features?.language}
                                        />
                                        :
                                    </span>
                                    <div style={{width: "60%"}}>
                                        <p style={theme.textSupportGrey}>
                                            <LanguageManager
                                                text={"Average evening reflection check-in time"}
                                                type='button'
                                                language={this.context?.features?.language}
                                            />
                                            :&nbsp;
                                            <span style={theme.textSupport}>{this.state.reflectionTime} 
                                            </span></p>
                                    </div>
                                </List>
                            }
                            {this.state.showSaveButton
                            ?
                                <div style={{marginTop: 50}}> 
                                    <Button style={theme.buttonRight} onClick={this.handleSettingSubmit}>
                                        <LanguageManager
                                            text={"Save"}
                                            type='button'
                                            language={this.context?.features?.language}
                                        />
                                    </Button>
                                </div>
                            :   (this.state.showCloseButton && this.props.handleClose !== undefined)
                                ?
                                    <div style={{marginTop: 50}}> 
                                        <Button style={theme.buttonRight} onClick={this.handleSettingClose}>
                                            <LanguageManager
                                                text={"Close"}
                                                type='button'
                                                language={this.context?.features?.language}
                                            />
                                        </Button>
                                    </div>
                                :   ""
                            }
                            <div style={{width: "100%"}}>
                                {this.state.errorMessage !== '' && this.state.errorMessage !== undefined
                                ?
                                    <p style={theme.textErrorCenterPadding}>
                                        <LanguageManager
                                            text={this.state.errorMessage || ""}
                                            type='tutorial'
                                            language={this.context?.features?.language}
                                        />
                                    </p>
                                :   <p> </p>
                                }
                            </div>
                        </form>
                    </Card>
                </div>

        : <div> </div>
                
        );
    }
}

SettingEmail.propTypes = {
    classes: PropTypes.object.isRequired
};

export default compose(
    withStyles(styles, { withTheme: true }),
    withFirebase
)(SettingEmail);
