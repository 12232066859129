/// V9 firebase compatible
import app from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
//// storage is used to upload picture
import 'firebase/compat/storage';
//// the firestore database is only used for connection checks
import "firebase/compat/database";
import "firebase/compat/messaging";

// new Web Modular API
//import {getDocs, collection, query, where, or } from "firebase/firestore";

//HN for soical new signup and async adding new data set to the user
import InternetConnectionQuality from '../Modules/InternetConnectionQuality';

export const config = {
    apiKey: "AIzaSyCCrPiSiSuAcRMw547bhnFvIj6jvYEuw_E",
    authDomain: "auth.rocky.ai",
    //authDomain: "rocky-beta-dev.firebaseapp.com",
    databaseURL: "https://rocky-beta-dev.firebaseio.com",
    projectId: "rocky-beta-dev",
    storageBucket: "rocky-beta-dev.appspot.com",
    messagingSenderId: "42021279944",
    appId: "1:42021279944:web:bc2a0730e9319891",
    measurementId: "G-QLWCH7R9VC"
};

class Firebase {
    constructor() {
        app.initializeApp(config);

        /* Helper */
        this.fieldValue = app.firestore.FieldValue;
        this.emailAuthProvider = app.auth.EmailAuthProvider;
        //HN query by documentid with fieldpath
        this.fieldPath = app.firestore.FieldPath;
        ///// overwrite where query so that digital twin options of quality can have the filter inside
        const originalWhere = app.firestore.Query.prototype.where;
        app.firestore.Query.prototype.where = function (fieldPath, opStr, value) {
            //console.log('irestore.Query.prototype.w')
            // Add your additional filter logic here.
            // For example, prevent querying certain sensitive fields
            if(fieldPath === 'leadership_quality'){
                //console.log('value leadership quality', value)
                if(typeof value === 'string'){
                    let charPos = value.indexOf(".");
                    if (charPos !== -1) {
                        // Finding everything to the right side of the specified character.
                        //console.log('replace .', value);
                        var valAfter = value.substring(charPos + 1);
                        var valBefore = value.substring(0, charPos);
                        if(opStr === 'array-contains'){
                            opStr = 'array-contains-any';
                            value = [valBefore, valAfter, value];
                            //console.log('replace opstr'); // This will print " World!"
                        }
                        //console.log(value); // This will print " World!"
                    }
                }
                else if(Array.isArray(value)){
                    //console.log('replace before array .', value);
                    var listOriginials = [];
                    var listInherited = [];
                    var listHybrid = [];
                    value.map((item, index) => {
                        if(item.includes(".")){
                            var charPos = item.indexOf(".");
                            var newKeyPre = item.substring(0, charPos);
                            var newKeyPost = item.substring(charPos + 1);
                            listOriginials.push(newKeyPre);
                            listInherited.push(newKeyPost);
                            listHybrid.push(item);
                        }
                        else{
                            listOriginials.push(item);
                        }
                    })
                    value = [...new Set([...listOriginials, ...listInherited, ...listHybrid])];
                    if(value.length >= 10){
                        value = value.slice(0,13);
                    }
                    //console.log('replace array .', value);
                }
                //console.log('request where')
                //console.log('final leadership valie', value)
            }
            // Call the original function with the original arguments
            return originalWhere.call(this, fieldPath, opStr, value);
        };

        /* Firebase APIs */
        this.auth = app.auth();
        this.db = app.firestore();
        this.storage = app.storage(); 
        //// used to connect the authfunction
        this.authUserListener = undefined;
        // Not needed anymore since Firestore v5.9.0
        // this.db.settings({ timestampsInSnapshots: true });

        this.googleProvider = new app.auth.GoogleAuthProvider();
        this.facebookProvider = new app.auth.FacebookAuthProvider();
        this.facebookProvider.addScope('email');
        //this.twitterProvider = new app.auth.TwitterAuthProvider();
        this.appleProvider = new app.auth.OAuthProvider('apple.com');
        this.appleProvider.addScope('email');
        this.appleProvider.addScope('name');
        this.connected = true;
        this.connectionAttempts = 0;
        this.lastConnectionTime = Date.now();
        //// get token for PWA and browser
        this.activatePWAMessage();
    }

    onDisconnectSimple(that, eventFunction = undefined) {
        // [START rtdb_ondisconnect_simple]
        // Write a string when this client loses connection
        try{
            /*** */
            var connectedRef = app.database().ref(".info/connected");
            var firebaseConnectionSpeed = 'online';
            let connectionListenerResult = InternetConnectionQuality();
            if(that!== undefined && connectionListenerResult === 'low'){
                firebaseConnectionSpeed = connectionListenerResult;
                that.setState({firebaseConnectionSpeed: firebaseConnectionSpeed});
                this.currentNetInfo = firebaseConnectionSpeed;
                //console.log('that initial', this.currentNetInfo, firebaseConnectionSpeed)
            }
            document.addEventListener("internet-connection-quality", (e) => {
                //console.log('internet-connection-quality', e); // Prints "Example of an event"
                if(e?.detail?.speed !== undefined && that!== undefined){
                    firebaseConnectionSpeed = e.detail.speed;
                    that.setState({firebaseConnectionSpeed: firebaseConnectionSpeed});
                    this.currentNetInfo = firebaseConnectionSpeed;
                    //console.log('that status updates', this.currentNetInfo, firebaseConnectionSpeed)
                }
                /**
                console.log('currentNetInfo', this.currentNetInfo, firebaseConnectionSpeed)
                if(that!== undefined && firebaseConnectionSpeed !== ''){
                    that.setState({firebaseConnectionSpeed: firebaseConnectionSpeed});
                    this.currentNetInfo = firebaseConnectionSpeed;
                    console.log('that status', this.currentNetInfo, firebaseConnectionSpeed)
                }
                 */
            });
            connectedRef.on("value", (snap) => {
                if (snap.val() === true) {
                    console.log("connected");
                    this.connected = true;
                    if (typeof this.timeoutID === "number") {
                        //console.log("not connected cleared");
                        clearTimeout(this.timeoutID);
                    }
                    if(that!== undefined && that._isMounted){
                        if(eventFunction !== undefined){
                            eventFunction(true);
                        }
                        that.setState({firebaseConnectionError: false});
                        //console.log('that status firebaseConnectionError false', that.state)
                    }
                } 
                else if(snap.val() === false) {
                    const currentTime = Date.now();
                    this.connectionAttempts++;
                    const timeSinceLastConnection = currentTime - this.lastConnectionTime;
                    if(that !== undefined && that._isMounted){
                        if (window.navigator?.onLine) {
                            //this.connected = false;
                            //that.setState({firebaseConnectionError: true, firebaseConnectionSpeed: 'low'});
                            console.error("Possible firewall blocking connection to Firebase");
                            if( this.connectionAttempts > 3 && timeSinceLastConnection < 10000){
                                console.error("3 attemntpos firewall blocking connection to Firebase");
                            }
                            // Your error handling here
                        }
                        //console.log("not connected initi");
                        this.timeoutID = setTimeout(() => {
                            //console.log("not connected started");
                            if(this.connected !== true){
                                if(eventFunction !== undefined){
                                    eventFunction(false);
                                }
                                that.setState({firebaseConnectionError: true, firebaseConnectionSpeed: 'low'});
                                console.log("not connected");
                            }
                        }, 3000);
                        //console.log('that error status', that.state, snap);
                    }
                }
                else{
                    console.log('snap', snap.val(), snap)
                }
            }, (error) => {
                this.connected = false;
                if(that!== undefined && that._isMounted){
                    setTimeout(() => {
                        if(this.connected !== true){
                            if(eventFunction !== undefined){
                                eventFunction(false);
                            }
                            that.setState({firebaseConnectionError: true, firebaseConnectionSpeed: 'low'});
                            console.log("not connected error result", error);
                        }
                    }, 3000);
                }
            }
            );
        }        
        catch(error) {
            this.connected = false;
            if(that!== undefined && that._isMounted){
                setTimeout(() => {
                    if(this.connected !== true){
                        if(eventFunction !== undefined){
                            eventFunction(false);
                        }
                        that.setState({firebaseConnectionError: true, firebaseConnectionSpeed: 'low'});
                        console.log("Error with connection test", error);
                    }
                }, 3000);
            }
        }
    }

    activatePWAMessage = async (forcePermission = false) => {
        if(process?.env?.REACT_APP_DEVICE_PLATFORM === "BROWSER"){
            try{
                this.messaging = app.messaging();
                if(forcePermission){
                    this.messaging
                        .getToken()
                        .then((currentToken) => {
                            if (currentToken) {
                                //console.log("Token: ", currentToken);
                                window?.localStorage?.setItem('mobilePWAToken', currentToken);
                                return currentToken
                            } 
                            else {
                                console.log(
                                    "No Instance ID token available. Request permission to generate one."
                                );
                                window?.localStorage?.setItem('mobilePWAToken', "NOPUSHPWAACCESS");
                                return undefined
                            }
                        })
                        .catch((err) => {
                            console.log("An error occurred while retrieving token. ", err);
                            window?.localStorage?.setItem('mobilePWAToken', "NOPUSHPWAACCESS");
                            return undefined
                        });
                }
                this.messaging.onMessage((payload) => {
                    console.log('Message received. ', payload);
                    // Customize notification here
                    const notificationTitle = (payload?.notification?.title || 'My AI Coach');
                    const notificationOptions = {
                        tag: 'my-ai-coach',
                        body: (payload?.notification?.body || 'Continue your daily reflection and AI coaching'),
                        icon: (payload?.notification?.image || 'https://firebasestorage.googleapis.com/v0/b/rocky-beta-dev.appspot.com/o/rockyai%2Fimages%2Fprograms%2FDIGITALTWIN%2FprogramLogo_1712662770224_512x512_my-ai-coach-logo-512.png?alt=media&token=32f604af-5e7a-4aee-8444-a6fd9c4588dc')
                    };
                    try{
                        new Notification(notificationTitle, notificationOptions);
                        /***
                        navigator.serviceWorker.getRegistrations().then(function(registrations) {
                            registrations[0].showNotification(title, options);
                        });
                         */
                    }
                    catch(error){
                        console.log('error inapp notificaiton', error)
                    }
                });
                //console.log('messaging', this.messaging);
            }
            catch(error){
                console.log('error messaing', error)
                return undefined
            }
        }
    }
    getBrowserPermission = (dbuser) => {
        if(process?.env?.REACT_APP_DEVICE_PLATFORM === "BROWSER"){
            Notification.requestPermission().then((permission) => {
                console.log('asking Notification permission', permission)
                if (permission === "granted") {
                    dbuser.update({ mobilePWAPushNotificationAllowed: true});
                    this.messaging
                        .getToken()
                        .then((currentToken) => {
                            if (currentToken) {
                                window?.localStorage?.setItem('mobilePWAToken', currentToken);
                                dbuser.update({ mobilePWAToken: currentToken});
                                console.log("Token Stored: ", currentToken);
                                return currentToken
                            } 
                            else {
                                window?.localStorage?.setItem('mobilePWAToken', "NOPUSHPWAACCESS");
                                console.log(
                                    "No Instance ID token available. Request permission to generate one."
                                );
                                return undefined
                            }
                        })
                        .catch((err) => {
                            window?.localStorage?.setItem('mobilePWAToken', "NOPUSHPWAACCESS");
                            console.log("An error occurred while retrieving token. ", err);
                            return undefined
                        });
                } 
                else {
                    dbuser.update({ mobilePWAPushNotificationAllowed: false, mobilePWAToken:  "NOPUSHPWAACCESS"});
                    window?.localStorage?.setItem('mobilePWAToken', "NOPUSHPWAACCESS");
                    console.log("Unable to get permission to notify.");
                    return undefined
                }
            });
            if(this.messaging === undefined){
                this.activatePWAMessage(true);
            }
        }
    }

    // *** Auth API ***
    doCreateUserWithEmailAndPassword = (email, password) =>
        this.auth.createUserWithEmailAndPassword(email, password);

    doGenerateSignInWithEmailLink = (email, actionCodeSettings) =>
        this.auth.sendSignInLinkToEmail(email, actionCodeSettings)

    doSignInWithEmailLink = (email, href) =>
        this.auth.signInWithEmailLink(email, href)

    checkSignInWithEmailLink = (href) =>
        this.auth.isSignInWithEmailLink(href)

    doSignInWithEmailAndPassword = (email, password) =>
        this.auth.signInWithEmailAndPassword(email, password);
  
    doSignInWithCustomToken = (token) =>
        this.auth.signInWithCustomToken(token);

    doSignInWithCredential = (credential) =>
        this.auth.signInWithCredential(credential);

    doSendEmailVerification = () => {
        this.auth.currentUser.sendEmailVerification({
            url: process?.env.REACT_APP_CONFIRMATION_EMAIL_REDIRECT,
            // url: 'http://localhost:3000',
        });
    };


    //// User changes password
    doEmailChange = async (oldEmail, password, newEmail) => {
        var result = {success: false, errorMessage:''}
        await this.auth.signInWithEmailAndPassword(oldEmail, password)
        .then(async () => {
            console.log('reauthenticate')
            var user = this.auth.currentUser;
            await user.updateEmail(newEmail)
            .then(() => {
                console.log('Email change success')
                result = {success:true, errorMessage:"Email changed!"}
            })
            .catch((error) => {
                console.log('error', error)
                result = {success:false, error:error, errorMessage:error.message}
            })
            return result
        })
        .catch((error) => {
            console.log('error', error)
            result = {success:false, error:error, errorMessage:error.message}
        })
        return result
    };
    doPasswordChange = async (userEmail, oldPassword, newPassword) => {
        var result = {success: false, errorMessage:''}
        await this.auth.signInWithEmailAndPassword(userEmail, oldPassword)
        .then(async () => {
            console.log('reauthenticate')
            var user = this.auth.currentUser;
            await user.updatePassword(newPassword)
            .then(() => {
                console.log('Password change success')
                result = {success:true, errorMessage:"Password changed!"}
            })
            .catch((error) => {
                console.log('error', error)
                result = {success:false, error:error, errorMessage:error.message}
            })
            return result
        })
        .catch((error) => {
            console.log('error', error)
            result = {success:false, error:error, errorMessage:error.message}
        })
        return result
    };
    doDeleteAccount = async (userEmail, password, uid) => {
        //var result = {success: false, errorMessage:''}
        return this.auth.signInWithEmailAndPassword(userEmail, password)
        .then(() => {
            console.log('reauthenticate')
            var user = this.auth.currentUser;
            return this.user(uid).update({
                username:"DELETED ACCOUNT", 
                email:"DELETED ACCOUNT",
                mobileNotificationsInitiated: true,
                emailNotificationsInitiated:true,
                mobileNotifications:'never',
                emailNotifications:'never',
                mobileDeviceToken:''
            })
            .then(() =>
                user.delete()
                .then(() => {
                    console.log('Account deleted')
                    return {success:true, errorMessage:"Account deleted!"}
                })
                .catch((error) => {
                    console.log('error', error)
                    return {success:false, error:error, errorMessage:error.message}
                })
            )
        })
        .catch((error) => {
            console.log('error', error)
            return {success:false, error:error, errorMessage:error.message}
        })
    };

    doLinkWithCredential = async (credential) => {
        await this.auth.currentUser.linkWithCredential(credential)
        .then((usercred) => {
            console.log(usercred)
            return true;
        })
        .catch((error) => {
            console.log(error)
            throw(error)
        })
    }
    createEmailAuthProviderCredential = (email, password) => {
        return this.emailAuthProvider.credential(email, password);
    }
    fetchSignInMethodsForEmail = async (email) => {
        return await this.auth.fetchSignInMethodsForEmail(email)
        .then(result => {
            //console.log('result, result', result)
            return result;
        });
    }
    socialLoginRedirectResultListener = () =>
        this.auth.getRedirectResult();
    //socialLoginAuthenticated = () =>
    //  this.auth.isAuthenticated();

    doRedirectWithGoogle = () => 
        this.auth.signInWithRedirect(this.googleProvider);
    doRedirectWithFacebook = () => 
        this.auth.signInWithRedirect(this.facebookProvider);
    //doRedirectWithTwitter = () => 
    //    this.auth.signInWithRedirect(this.twitterProvider);
    doRedirectWithApple = () => 
        this.auth.signInWithRedirect(this.appleProvider);
    
    doSignInWithGoogle = () =>
        this.auth.signInWithPopup(this.googleProvider);
    doSignInWithFacebook = () =>
        this.auth.signInWithPopup(this.facebookProvider);
    //doSignInWithTwitter = () => this.auth.signInWithPopup(this.twitterProvider);
    doSignInWithApple = () =>
        this.auth.signInWithPopup(this.appleProvider);

    createCredentialForGoogle = (token) => {
        return this.googleProvider.credential(token);
    }
    createCredentialForGoogleAlternative = (token) => {
        return app.auth.GoogleAuthProvider.credential(token);
    }
    createCredentialForFacebook = (token) => {
        return this.facebookProvider.credential(token);
    }
    createCredentialForFacebookAlternative = (token) => {
        return app.auth.FacebookAuthProvider.credential(token);
    }
    //createCredentialForTwitter = (token) => {
    //    return this.twitterProvider.credential(token);
    //}
    createCredentialForApple = (token, unhashedNonce) => {
        return this.appleProvider.credential({
            idToken: token,
            rawNonce: unhashedNonce,
        });
    }

    cleanupStorage(defaultSetStorage){
        localStorage?.removeItem('authUser');
        localStorage?.clear();
        window?.localStorage?.removeItem('authUser');
        window?.localStorage?.clear();
        sessionStorage?.removeItem('userDataListening');
        sessionStorage?.clear();
        window?.sessionStorage?.removeItem('userDataListening');
        window?.sessionStorage?.clear();

        // Restore default set if it existed
        if (defaultSetStorage?.length > 1) {
            window?.localStorage?.setItem('defaultSet', defaultSetStorage);
        }
    };

    OLDdoSignOut = async () => {
        // Store default set before cleanup
        const defaultSetStorage = window?.localStorage?.getItem('defaultSet') || localStorage?.getItem('defaultSet');
        try {
            this.auth.signOut()
            .then(() => {
                this.stopUserUpdateListener();
                this.holy = {};
                this.cleanupStorage(defaultSetStorage);
                return true;
            })
            .catch((error) => {
                // Firebase built-in error codes
                switch (error.code) {
                    case 'auth/no-current-user':
                    case 'auth/invalid-user-token':
                        this.cleanupStorage(defaultSetStorage);
                        return true;
                    default:
                        console.error('Signout error:', error.message);
                        this.cleanupStorage(defaultSetStorage);
                        return false;
                }
            });
            const currentLocation = window.location?.href?.split('/#')[0] || "";
            console.log('signout successful', currentLocation);
            return true;
    
        } catch (error) {
            console.error('Signout error:', error);
            // Optionally handle specific error types
            if (error.code === 'auth/no-current-user') {
                this.cleanupStorage(defaultSetStorage);
                return true;
            }
            //throw error;
        }
    }

    killAllFirebaseRequests = () => {
        try {
            // Detach all Realtime Database listeners
            if (true) {
                app.database().goOffline();
                app.database().goOnline();
            }
            // Detach Firestore listeners
            if (this.firestore) {
                this.firestore.terminate();
                this.firestore.clearPersistence();
            }
            // Clear any custom listeners
            if (this.authUserListener) {
                this.authUserListener();
            }
            if (this.userUpdateListener) {
                this.userUpdateListener();
            }
            console.log('All Firebase requests killed');
            return true;
        } catch (error) {
            console.error('Error killing Firebase requests:', error);
            return false;
        }
    }

    doSignOut = () => {
        //console.log('pre signout', this.authUserListener)
        var currentLocation;
        this.stopUserUpdateListener();
        //if (this.authUserListener!== undefined && this.authUserListener instanceof Function) {this.authUserListener();}
        this.holy = {};
        var defaultSetStorage = window?.localStorage?.getItem('defaultSet');
        if(defaultSetStorage?.length>1){
            console.log('keeping default')
        }
        else{
            defaultSetStorage = localStorage?.getItem('defaultSet');
        }
        if (!this.auth.currentUser) {
            this.killAllFirebaseRequests();
            console.log('User is already signed out');
            return true;
        }
        //console.log('call dignout')
        this.killAllFirebaseRequests();
        this.auth.signOut()
        .then(result => {
            this.cleanupStorage(defaultSetStorage);
            currentLocation = window.location?.href?.split('/#') || "";
            console.log('after signout by click', currentLocation[0])
            return true;
        })
        .catch((error) => {
            // Firebase built-in error codes
            console.error('Signout error:', error.message);
            this.cleanupStorage(defaultSetStorage);
            currentLocation = window.location?.href?.split('/#') || "";
            console.log('catch signout by click', currentLocation[0])
            return true;
        });
        /*
        try{
            this.appAuth = new window.FirebaseAuth();
            this.fbAuth.getToken().then(token => {
                this.appAuth.signOut();
            }).catch(error => {
                console.log(error);
                return true;
            });
        } catch (error) {
            console.log("Error app signout");
        }
        */
    }
    stopUserUpdateListener(){
        if(this.authUserListenerExtra !== undefined && this.authUserListenerExtra!== null && typeof this.authUserListenerExtra === 'function'){
            this.authUserListenerExtra(); 
            this.authUserListenerExtra=null;
            //console.log(' additional check logout extra usnsubscribe')
        }
        if(this.authUserListener !== undefined && this.authUserListener!== null && typeof this.authUserListener === 'function'){
            this.authUserListener(); 
            this.authUserListener=null;
            //console.log('addiitonal check logout auth usnsubscribe')
        }
    }

    doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

    doPasswordUpdate = password => this.auth.currentUser.updatePassword(password);

    checkSignIn = () => {
        this.auth.onAuthStateChanged(function(userCheck) {
            if (userCheck) {
                return true;
            } 
            else {
                return false;
            }
        });
    }

    checkAuthProvider = () => {
        var providerIds = [];
        if(this.auth.currentUser != null){
            //console.log('auth checked')
            this.auth.currentUser.providerData.forEach( (profile) => {
                providerIds.push(profile.providerId)
                //console.log('auth added')
            })
        }
        return providerIds
    }

    doUnlinkAuthProvider = (providerId, makeAnonymous = false, uid='', email='', password='', deleteAccount=false) => {
        var status = false;
        if(this.auth.currentUser != null && providerId !== ""){
            if(deleteAccount){
                var user = this.auth.currentUser;
                return this.user(uid).update({
                    username:"DELETED ACCOUNT", 
                    email:"DELETED ACCOUNT",
                    mobileNotificationsInitiated: true,
                    emailNotificationsInitiated:true,
                    mobileNotifications:'never',
                    emailNotifications:'never',
                    mobileDeviceToken:''
                })
                .then(() => {
                    user.delete()
                    .then(() => {
                        console.log('Account deleted')
                        return {success:true, errorMessage:"Account deleted!"}
                    })
                    .catch((error) => {
                        console.log('error', error)
                        return {success:false, error:error, errorMessage:error.message}
                    })
                })
            }
            else if(makeAnonymous) {
                return this.auth.currentUser.unlink(providerId)
                .then( () => {
                    this.db.doc(`users/${uid}/accounts/anonymous`).set({makeAnonymous : true}, {merge:true }); 
                    //this.user(uid).collection('accounts').document('anonymous').update({makeAnonymous : true})
                    console.log("UNLINK * amonymous DONE")
                    status = true;
                    return status
                })
                .catch((error) => {
                    console.log("UNLINK & amonymous FAILED", error)
                    status = false;
                    return status
                })
            }
            else{
                /**
                 */
                return this.auth.currentUser.unlink(providerId)
                .then( () => {
                    console.log("UNLINK DONE")
                    status = true;
                    return status
                })
                .catch((error) => {
                    console.log("UNLINK FAILED")
                    status = false;
                    return status
                })
            }
        }
        else{
            return status
        }
    }

    onAuthUserListener = (next, fallback) => this.auth.onAuthStateChanged(authUser => {
        // if(this.dbUserInit === undefined) this.dbUserInit = {}
        //this.authUserListener = this.getAuthListener();
        //console.log('update', this.auth.currentUser, authUser, this.authUserListener)
        
        if (authUser) {
            //console.log('onAuthStateChanged with authUser')
            //console.log('i know some parameter',this.holy)
            //let data = localStorage.getItem();
            /**
            if( this.holy !== undefined && this.holy.roles !== undefined && this.holy.roles instanceof Array && this.holy.uid === authUser.uid ){
                if(true){
                    //console.log('this.holy')
                    authUser = {
                        uid: authUser.uid,
                        email: authUser.email,
                        emailVerified: authUser.emailVerified,
                        providerData: authUser.providerData,
                        onSnapshotListener: true,
                        ...this.holy,
                    };
                    next(authUser);
                }
                else if (false){
                    this.resetAuthListener(this.authUserListener);
                    //console.log('update data')
                    //this.user(authUser.uid).get().then(snapshot => {
                    this.authUserListener = this.user(authUser.uid).onSnapshot(snapshot => {
                        var dbUser = snapshot.data();
                        if(snapshot.id === authUser.uid ){
                            console.log('snapshot extra reload',  authUser.uid)
                            // #### HN Async problem with new users via social login
                            // there add the offlineNew User installation set from file signup
                            if ( dbUser === undefined) {
                                dbUser = myInstallationOfflineDataSet
                            }
                            if (!("roles" in dbUser)) {
                                dbUser.roles = [];
                                //console.log('dbUser2', dbUser)
                            }
                            
                            // merge auth and db user
                            authUser = {
                                uid: authUser.uid,
                                email: authUser.email,
                                emailVerified: authUser.emailVerified,
                                providerData: authUser.providerData,
                                onSnapshotListener: true,
                                ...dbUser,
                            };
    
                            this.holy = dbUser;
                            this.setAuthListener(this.authUserListener)
                            next(authUser);
                        }
                        else{
                            this.resetAuthListener(this.authUserListener);
                            this.holy = {};
                            console.log('snapshot extra else',this.idextra , authUser.uid)
                            //this.authUserListener(); 
                            fallback();
                        }
                    }, (error) => {
                        console.log('auth extra error',authUser.uid, this.idextra , error)
                        fallback();
                    })
                }
            }
             */
            if(true){
            //else {
                if(authUser.roles!==undefined){
                    //console.log('existing auth', 'new loader')
                    next(authUser);
                }
                else{
                    //console.log('sending package')
                    //this.resetAuthListener(this.authUserListener);
                    //this.resetPreviousListener();
                    authUser = {
                        uid: authUser.uid,
                        email: authUser.email,
                        roles: [],
                        emailVerified: authUser.emailVerified,
                        providerData: authUser.providerData,
                        onSnapshotListener: false
                    };
                    next(authUser);
                }
            }
        } else {
            fallback();
        }
    });

    batch = () => this.db.batch();

    // *** Defaults API ***
    collectionByName = (name) => this.db.collection(`${name}`);
    documentIdByCollection = (name, id) => this.db.doc(`${name}/${id}`);
    // *** User API ***
    user = uid => this.db.doc(`users/${uid}`);
    users = () => this.db.collection('users');
    userCollection = (uid, collection) => this.db.collection(`users/${uid}/${collection}`);
    usersNotification = (uid, label) => this.db.doc(`users/${uid}/notificationsScheduled/${label}`);
    invitedUsers = () => this.db.collection(`invitedUsers`);

    // *** Leadership Categories API ***
    leadershipCategory = uid => this.db.doc(`leadership_categories/${uid}`);
    leadershipCategories = () => this.db.collection('leadership_categories');

    // *** Leadership Qualities API ***
    leadershipQuality = uid => this.db.doc(`leadership_qualities/${uid}`);
    leadershipQualities = () => this.db.collection('leadership_qualities');

    // *** Content API ***
    content = cid => this.db.doc(`contents/${cid}`);
    contents = () => this.db.collection('contents');
    contentBookmark = (cid,uid) => this.db.doc(`contents/${cid}/contentsEngagement/${uid}`);
    contentBookmarks = (cid) => this.db.collection(`contents/${cid}/contentsEngagement`);
    contentBookmarkEngagements = () => this.db.collectionGroup(`contentsEngagement`);
    badge = bid => this.db.doc(`badges/${bid}`);
    badges = () => this.db.collection('badges');

    insight = uid => this.db.doc(`insights/${uid}`);
    insightAssessment = (uid, date) => this.db.doc(`insights/${uid}/assessments/${date}`);
    insightAssessments = (uid) => this.db.collection(`insights/${uid}/assessments`);
    insightsArchive = (uid, archiveId) => this.db.doc(`insights/${uid}/archive/${archiveId}`);
    insights = () => this.db.collection('insights');
    insightsEngagement = () => this.db.collection('insightsEngagement');
    insightsWordsFilter = () => this.db.collection('insightsWordsFilter');
    // *** Word and Journal API ***
    insightsWords = uid => this.db.doc(`insightsWords/${uid}`);
    insightsWordsArchive = (uid) => this.db.collection(`insightsWords/${uid}/archive`);
    insightsWordsArchiveMessages = (uid, messageId) => this.db.doc(`insightsWords/${uid}/archive/${messageId}`);
    // *** Journey and change events API ***
    journeyUserChanges = () => this.db.collection(`usersDocumentChanges`);
    journeyFollowUps = () => this.db.collection(`usersFollowUps`);
    journeyFollowUpAction = (aid) => this.db.doc(`usersFollowUps/${aid}`);
    journeyBadges = uid => this.db.collection(`users/${uid}/badges`);

    personalityTrait = uid => this.db.doc(`personalityTrait/${uid}`);
    personalityTraitArchive = uid => this.db.collection(`personalityTrait/${uid}/archive`);
    personalityTraits = () => this.db.collection('personalityTrait');

    // *** Message API ***
    message = uid => this.db.doc(`messages/${uid}`);
    messages = () => this.db.collection('messages');

    // *** Dialogue API ***
    dialogue = uid => this.db.doc(`dialogues/${uid}`);
    dialoguePostprocess = (uid, action) => this.db.doc(`dialogues/${uid}/postprocess/${action}`);
    dialogues = () => this.db.collection(`dialogues`);

    // *** Text from DB to be used ***
    textBotUtter = () => this.db.collection('utterDescriptions');
    agentUtter = (utterance_class, cid) => this.db.doc(`agent_utterances/${utterance_class}/utterances/${cid}`);
    agentUtters = (utterance_class) => this.db.collection(`agent_utterances/${utterance_class}/utterances`);
    agentUtterClasses = () => this.db.collection('agent_utterances');
    agentUttersByLeadership = (leadership_quality) => this.db.collectionGroup(`utterances`).where('leadership', 'array-contains', `${leadership_quality}`);
    agentUttersList = (leadership_qualities_list) => this.db.collectionGroup(`utterances`).where('leadership', 'array-contains-any', leadership_qualities_list);
    agentUttersListByCat = (category_list) => this.db.collectionGroup(`utterances`).where('category', 'array-contains-any', category_list);
    agentUttersByTextList = (textList) => this.db.collectionGroup(`utterances`).where('template.text', 'in', textList);
    agentUttersByTextSearch = (text) => this.db.collectionGroup(`utterances`).where('template.text', '>=', text).limit(10);
    agentUttersByIntent = (intent) => this.db.collectionGroup(`utterances`).where('intents', 'array-contains', `${intent}`);
    agentUttersByIntents = (intentList) => this.db.collectionGroup(`utterances`).where('intents', 'array-contains-any', intentList);
    agentUttersCollection = () => this.db.collectionGroup(`utterances`);
    agentIntentClasses = () => this.db.collection('agent_responses');
    agentIntent = (cid) => this.db.doc(`agent_responses/${cid}`);

    trainingData = () => this.db.collection(`agent_trainingdata`);
    trainingDataItem = (id) => this.db.doc(`agent_trainingdata/${id}`);

    // *** QUESTIONAIIRES
    questionnaires = () => this.db.collection(`questionnaires`);
    questionnaire = (id) => this.db.doc(`questionnaires/${id}`);
    questionnaireSet = (qid) => this.db.collection(`questionnaireSets`).where('questionnaires', 'array-contains', `${qid}`);
    questionnaireQuestion = (qid) => this.db.doc(`questionnaireSets/${qid}`);
    questionnaireQuestions = () => this.db.collection(`questionnaireSets`);

    // *** singup codes and user programs plus setting access
    signupCodeRESET = () => this.db.doc(`signupCodes/RESET`);
    signupCodeByName = code => this.db.doc(`signupCodes/${code}`);
    signupCodes = () => this.db.collection(`signupCodes`);
    group = id => this.db.doc(`groups/${id}`);
    groups = () => this.db.collection(`groups`);

    // *** purchases
    purchases = uid => this.db.collection(`users/${uid}/purchases`);
    purchaseArticles = () => this.db.collection(`shopArticles`);

    // *** Onboarding Answers API
    onboardingContents = () => this.db.collection(`onboardingContent`);
    onboardingContent = (cid) => this.db.doc(`onboardingContent/${cid}`);
    onboardingAnswer = uid => this.db.doc(`onboarding_answers/${uid}`);
    onboardingAnswers = () => this.db.collection('onboarding_answers');

    // *** Day API ***
    day = uid => this.db.doc(`days/${uid}`);
    days = () => this.db.collection('days');

    // *** Demo Scenarios API ***
    demoScenario = uid => this.db.doc(`demo_scenarios/${uid}`);
    demoScenarios = () => this.db.collection('demo_scenarios');

    storageImages = foldername => this.storage.ref(`rockyai/images/${foldername}/`);

    appErrors = () => this.db.collection(`appErrors`);
}

export default Firebase;
