import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import LoadingProgress from '../../Modules/LoadingProgress';

import { withFirebase } from '../../Firebase';
import { withAuthorization,
	 // withEmailVerification
       } from '../../Session';
import { AuthUserContext } from '../../Session';
import * as ROLES from '../../../constants/roles';
import * as ROUTES from '../../../constants/routes';

// MUI stuff
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
//import Table from '@material-ui/core/Table';
//import TableCell from '@material-ui/core/TableCell';
//import TableHead from '@material-ui/core/TableHead';
//import TableRow from '@material-ui/core/TableRow';
//import TableBody from '@material-ui/core/TableBody';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
//import ButtonSwitch from '@material-ui/core/Switch';
import {Column, Table, SortDirection} from 'react-virtualized';
import {  AutoSizer } from 'react-virtualized';
import 'react-virtualized/styles.css'
import _ from "lodash";


import { SelectOption, SelectWrapper } from '../../Modules/SelectOption';
// Icons
import AddIcon from '@material-ui/icons/Add';

// Modules for parameter change
import SettingContentBase from '../Modules/SettingContentBase';
import SettingContentActive from '../Modules/SettingContentActive';
import SettingContentNumber from '../Modules/SettingContentNumber';
import SettingContentArray from '../Modules/SettingContentArray';
//import SettingContentImage from '../Modules/SettingContentImage';
import SettingContentUploadImage from '../Modules/SettingContentUploadImage';
//import SettingContentRichText from '../Modules/SettingContentRichText';
//import SettingContentArraySelect from '../Modules/SettingContentArraySelect';
import SettingContentSelect from '../Modules/SettingContentSelect';

const styles = {
  table: {
    minWidth: 100,
  },
  subtitle: {
    marginTop: 20,
    fontWeight: 700,
  },
  addButton: {
    marginTop: 20,
  },
};

class AdminTutorialsPage extends Component {

    render() {
        return (
            <div style={{backgroundColor: "#DDDDDD"}}>
                <Switch>
                    <Route exact path={ROUTES.ADMIN} component={TutorialsList} />
                    <Route exact path={ROUTES.ADMIN_TUTORIALS} component={TutorialsList} />
                </Switch>
            </div>
        );
    }
}

class TutorialsListBase extends Component {
    static contextType = AuthUserContext;
    _isMounted = false;
    constructor(props) {
        super(props);
        const sortBy = 'active';
        const sortDirection = SortDirection.ASC;

        this.state = {
        loading: false,
        contents: [],
        contentsTab: [],
        contentsLabels: [],
        title: '',
        author: '',
        program: '',
        open: false,
        sortBy,
        sortDirection,
        };
        //this.sortState = createMultiSort(this.sort);
    }

    componentDidMount() {
        this._isMounted = true;
        this.setState({
            loading: true,
            open: false, 
            title: '',
            author: '',
            program: '',
            showTab: '',
            errorMessage : ''
        });

        var name = '';

        this.unsubscribe = this.props.firebase
        .onboardingContents()
        .orderBy('active', 'desc')
        .onSnapshot(snapshot => {
            let contentsTab = [];
            let contentsTabList = [];
            let contentsLabels = [];

            var keySorting = 'category'
            var content = {}
            
            snapshot.forEach(doc =>{
                if(this.context.roles?.includes('ADMIN') && this._isMounted){
                    if(this.context.programs?.includes(doc.data().program) || doc.data().program===undefined){
                        name = doc.data()[keySorting];
                        content = doc.data();
                        if(name === undefined){
                            name = '---undefined---'
                        }
                        if(!(name in contentsTab)){
                            //contentsTab[doc.data().category]={name:doc.data().category, contents: []};
                            contentsTab[name] = [];
                            contentsTabList[name]= [];
                            contentsLabels.push(name );
                        }
                        contentsTab[name].push({ ...doc.data(), uid: doc.id });
                        contentsTabList[name].push({
                            active: content.active? 'Yes': 'No',
                            badgeFamily: content.badgeFamily || '-',
                            h1 : content.h1 || '-',
                            h2:  content.h2 !== undefined ? content.h2.length>30 ? (content.h2.substring(0,29)+'...'): content.h2 : '-',
                            text:  content.text !== undefined ? content.text.length>30 ? (content.text.substring(0,29)+'...'): content.text : '-',
                            engCnt: content.engagementCounter>=0 ? content.engagementCounter: '-',
                            images: content.images !== undefined ? content.images.length : '-',
                            imageMain: content.imageMain !== undefined && content.imageMain !== "" ? "Yes" : '-',
                            button: content.buttonLandingPage || '-',
                            page: content.page || '-',
                            uid: doc.id,
                        });
                    } // end if true
                } // end of if ADMIN
                else if(this.context.roles?.includes('COACHADMIN') && this._isMounted ){
                    if(this.context.programs?.includes(doc.data().program) && doc.data().program !=='default'){
                        name = doc.data()[keySorting];
                        content.doc.data();
                        if(name === undefined){
                            name = '---undefined---'
                        }
                        if(!(name in contentsTab)){
                            //contentsTab[doc.data().category]={name:doc.data().category, contents: []};
                            contentsTab[name] = [];
                            contentsTabList[name] = [];
                            contentsLabels.push(name );
                        }
                        contentsTab[name].push({ ...doc.data(), uid: doc.id });
                        contentsTabList[name].push({
                            active: content.active? 'Yes': 'No',
                            badgeFamily: content.badgeFamily || '-',
                            h1 : content.h1 || '-',
                            h2:  content.h2 !== undefined ? content.h2.length>30 ? (content.h2.substring(0,29)+'...'): content.h2 : '-',
                            text:  content.text !== undefined ? content.text.length>30 ? (content.text.substring(0,29)+'...'): content.text : '-',
                            engCnt: content.engagementCounter>=0 ? content.engagementCounter: '-',
                            images: content.images !== undefined ? content.images.length : '-',
                            imageMain: content.imageMain !== undefined && content.imageMain !== "" ? "Yes" : '-',
                            button: content.buttonLandingPage || '-',
                            page: content.page || '-',
                            uid: doc.id,
                        });
                    }//end if program / no default
                } // end of if ADMIN
            });//end of snapshot


            //console.log('contents', contentsTab)
            if(this.state.showTab === undefined || this.state.showTab === ''){
                this.setState({showTab: name})
            }
            if(this._isMounted){
                this.setState({
                    contentsTab: contentsTab,
                    contentsTabList,
                    contentsLabels,
                    loading: false,
                });
            }
            //console.log('total',contentsTabList['total'][0])
        });
        
    }

    _sortList = ({sortBy, sortDirection}) => {
        const list = this.state.contentsTabList[this.state.showTab];
        //console.log('sortingbefore', sortBy ,sortDirection)
        let newList = _.sortBy(list, [sortBy]);
        if (sortDirection === SortDirection.DESC) {
            newList.reverse();
        }
        //console.log('sorting',newList, this.state.showTab )
        return newList;
    }
    _sort = ({sortBy, sortDirection}) => {
        const sortedList = this.state.contentsTabList;
        sortedList[this.state.showTab] = this._sortList({sortBy, sortDirection});
        this.setState({sortBy, sortDirection, contentsTabList: sortedList});
    }

    handleOpen = () => {
        //console.log('handleOpen');
        this.setState({ open: true });
    };

    handleClose = () => {
        //console.log('handleClose');
        this.setState({ open: false });
    };

    handleChange = (event) => {
        //console.log([event.target.name] + ": " + event.target.value);
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleSubmit = () => {
        if(this.state.title !== '' && this.state.author !== '' && this.state.category !== '')
        {
            const timestamp = this.props.firebase.fieldValue.serverTimestamp();
            this.props.firebase.onboardingContents().add({
                title: this.state.title,
                author: [this.state.author],
                category: this.state.program,
                active: false,
                createdAt: timestamp
            });
            this.setState({errorMessage : 'Added content (category undefined)'})
        }
        else{
            this.setState({errorMessage : 'All three values have to be defined to add a content'})
        }
        this.setState({
            title: '',
            author: '',
            category: '',
        });
        this.handleClose();
    }

    componentWillUnmount() {
        this._isMounted = false;
        this.unsubscribe();
    }

    handleTabChange = (event, newValue) => {
        //console.log('new tab', newValue)
        this.setState({
        showTab: newValue
        });
    }

    render() {
        const { showTab, contentsTab, contentsLabels, loading } = this.state;
        //console.log('votes', maxVotes)
        return (
            <div style={{height: '100vh'}}>
                {loading && 
                    <LoadingProgress 
                        defaultSet={this.props.defaultSet}
                        type={'LoadingPageAnimated'} 
                        loadingText={"many, many, data"} 
                        firebaseConnectionSpeed={this.state.firebaseConnectionSpeed} 
                        allowRefresh={true}
                    />
                }
                <div>
                    <IconButton style={styles.addButton} onClick={this.handleOpen}>
                        <AddIcon />
                    </IconButton>
                    <Dialog
                        open={this.state.open}
                        onClose={this.handleClose}
                        fullWidth
                        maxWidth="sm">
                        <DialogTitle>Add a new tutorial set</DialogTitle>
                        <DialogContent>
                            <form>
                                <TextField
                                    name="title"
                                    style={ {fontSize: '16px'} }
                                    type="text"
                                    label="1. Title"
                                    placeholder="Enter title"
                                    value={this.state.title}
                                    onChange={this.handleChange}
                                    fullWidth
                                />
                                <TextField
                                    name="author"
                                    style={ {fontSize: '16px'} }
                                    type="text"
                                    label="2. Author"
                                    placeholder="Enter content author name"
                                    value={this.state.author}
                                    onChange={this.handleChange}
                                    fullWidth
                                />
                                <p>3. Select category</p>
                                <SelectWrapper
                                    value={this.state.program}
                                    onChange={this.handleChange}
                                    name='program'
                                    >
                                    {this.state.contentsLabels !== undefined && this.state.contentsLabels.map(entry => {
                                        return (
                                            <SelectOption key={"add_"+entry} value={entry} >
                                                {entry}
                                                </SelectOption>
                                            );
                                        })
                                    }
                                </SelectWrapper>
                            </form>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleClose}>
                                Cancel
                            </Button>
                            <Button onClick={this.handleSubmit}>
                                Save
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
                {this.state.errorMessage !== '' && 
                <h4 style={{color :'red'}}>{this.state.errorMessage}</h4>
                }
                <Tabs
                    variant="scrollable"
                    scrollButtons="auto"
                    onChange={this.handleTabChange}
                    initialselectedindex={0}
                    value={showTab}
                >
                    {contentsLabels.length>0 && 
                        contentsLabels.map( (key) => (
                        <Tab 
                            value={key} 
                            key={key}
                            label={key + " ("+contentsTab[key].length+")"}/>
                        ))
                    }
                </Tabs>
                {contentsLabels.length>0
                 &&  contentsLabels.map( (key) => (
                    
                    <div 
                    value={key} 
                    key={key} 
                    hidden={showTab !==key}>
                        <AutoSizer>
                        {({ height, width }) => (
                            <Table
                                width={width}
                                height={700}
                                headerHeight={20}
                                rowHeight={30}
                                rowCount={this.state.contentsTabList[key].length}
                                sort={this._sort}
                                sortBy={this.state.sortBy}
                                sortDirection={this.state.sortDirection}
                                rowGetter={({index}) => this.state.contentsTabList[key][index]}
                                onRowClick={({ event, index, rowData })  => {this.setState({
                                    currentContentId: this.state.contentsTabList[key][index].uid, dialogOpen:true})}}
                            >
                                <Column label="Active" dataKey="active" width={100} disableSort={false}  />
                                <Column label="Family" dataKey="badgeFamily" width={200} disableSort={false} />
                                <Column label="H1" dataKey="h1" width={200} disableSort={false} />
                                <Column label="H2" dataKey="h2" width={200} disableSort={false} />
                                <Column label="Text" dataKey="text" width={200} disableSort={false} />
                                <Column label="engCnt" dataKey="engCnt" width={100} disableSort={false} />
                                <Column label="images" dataKey="images" width={100} disableSort={false} />
                                <Column label="imageMain" dataKey="imageMain" width={100} disableSort={false}  />
                                <Column label="Button" dataKey="button" width={100} disableSort={false}  />
                                <Column label="Page" dataKey="page" width={100} disableSort={false}  />
                            </Table>
                        )}
                        </AutoSizer>
                    </div>
                ))
                }
                    <TutorialsItemDialog 
                        defaultSet={this.props.defaultSet}
                        contentId={this.state.currentContentId}
                        openDialog={this.state.dialogOpen|| false}
                        closeDialog={(e) => {this.setState({dialogOpen : false })}}
                        contentsLabels={contentsLabels}
                    />
                </div>
        );
    }
}


class TutorialsItemBaseDialog extends Component {
    static contextType = AuthUserContext;
    _isMounted = false;
    constructor(props) {
        super(props);
        var errorMessage = '';
        var contentsLabels = ['total'];
        this.state = {
            loading: false,
            content: null,
            cid:'',
            contentsLabels: contentsLabels,
            errorMessage: errorMessage,
        };
    }
  
    componentDidMount() {
        this._isMounted = true;
        //    this.getleadershipCategoriesArray();
        //    this.getleadershipQualitiesArray();
        //    this.getBotUtterArray();
    }
  
    componentDidUpdate() {
        //console.log('myid',this.props.contentId)
        if (this.props.contentId && this.state.cid !== this.props.contentId) {
            //console.log('myidopen', this.props.contentId)
            this.setState({ loading: true, cid: this.props.contentId });
            this.unsubscribe = this.props.firebase
                .onboardingContent(this.props.contentId)
                .onSnapshot(snapshot => {
                    //console.log('data',snapshot.data())
                    if(snapshot && this._isMounted){
                        this.setState({
                            content: snapshot.data(),
                            loading: false,
                        });
                    }
                });
        }
    }

    componentWillUnmount() {
        this.setState({content:null});
        if (this.unscrubscribe !== undefined ) this.unsubscribe();
    }
  
  
    render() {
    const { content, loading } = this.state;
    
     
    //console.log('my context', this.context)
        return (
            <Dialog
                open={this.props.openDialog}
                onClose={this.props.closeDialog}
                >
                <DialogTitle >
                    <IconButton onClick={this.props.closeDialog}>
                        (X)
                    </IconButton>
                    Modify Tutorials
                </DialogTitle>
                <DialogContent >
                    <div>
                    <h5 style={styles.subtitle}>Tutorial Page ({this.props.contentId})</h5>
                    {loading && 
                        <LoadingProgress 
                            defaultSet={this.props.defaultSet}
                            type={'LoadingPageAnimated'} 
                            loadingText={"many, many, data"} 
                            firebaseConnectionSpeed={this.state.firebaseConnectionSpeed} 
                            allowRefresh={true}
                        />
                    }
            
                    {this.state.errorMessage !== '' && 
                        <h4 style={{color :'red'}}>{this.state.errorMessage}</h4>
                    }
                    {content && (
                        <div>
                            <SettingContentActive 
                                dbUpdateLink={this.props.firebase.onboardingContent(this.state.cid)}
                                title={"Active"} 
                                content={content} 
                                params={['active']}
                            />
                            <SettingContentBase 
                                dbUpdateLink={this.props.firebase.onboardingContent(this.state.cid)}
                                title={"Intro section"} 
                                content={content} 
                                params={['h1', 'h2']}
                                emptyOption={true}
                            />
                            <SettingContentBase 
                                dbUpdateLink={this.props.firebase.onboardingContent(this.state.cid)}
                                title={"Text if available"} 
                                content={content} 
                                params={['text']}
                                emptyOption={true}
                            />
                            <SettingContentBase 
                                dbUpdateLink={this.props.firebase.onboardingContent(this.state.cid)}
                                title={"Main section"} 
                                content={content} 
                                params={['h3', 'h4']}
                                emptyOption={true}
                            />
                            <SettingContentBase
                                dbUpdateLink={this.props.firebase.onboardingContent(this.state.cid)}
                                title={"Extended section"} 
                                content={content} 
                                params={['h5', 'h6']}
                                emptyOption={true}
                            />
                            <SettingContentUploadImage
                                dbUpdateLink={this.props.firebase.onboardingContent(this.state.cid)}
                                title={"Main Image (for single image use)"} 
                                content={content} 
                                params={['imageMain']}
                                emptyOption={true}
                                uploadDatabase={this.props.firebase.storageImages('Tutorials/'+this.state.cid)}
                            />
                            <SettingContentBase 
                                dbUpdateLink={this.props.firebase.onboardingContent(this.state.cid)}
                                title={"Youtube Video Code (e.g. lzPJY1sTk4c)"} 
                                content={content} 
                                youTubeFormat={true}
                                labels={['Only youtubeId code!']}
                                params={['youtubeId']}
                                emptyOption={true}
                            />
                            <SettingContentArray
                                dbUpdateLink={this.props.firebase.onboardingContent(this.state.cid)}
                                title={"Image rotary"} 
                                content={content} 
                                params={['images']}
                                emptyOption={true}
                            />
                            <SettingContentArray
                                dbUpdateLink={this.props.firebase.onboardingContent(this.state.cid)}
                                title={"Page Features"} 
                                content={content} 
                                params={['features']}
                                emptyOption={true}
                            />
                            <SettingContentBase 
                                dbUpdateLink={this.props.firebase.onboardingContent(this.state.cid)}
                                title={"Button"} 
                                content={content} 
                                params={['button','buttonLandingPage']}
                                emptyOption={true}
                            />
                            <SettingContentNumber 
                                dbUpdateLink={this.props.firebase.onboardingContent(this.state.cid)}
                                title={"Page Position"} 
                                content={content} 
                                min={0}
                                max={20}
                                params={['page']}
                            />
                            <SettingContentNumber 
                                dbUpdateLink={this.props.firebase.onboardingContent(this.state.cid)}
                                title={"Engagement Counter"} 
                                content={content} 
                                min={0}
                                max={(content.engagementCounter>1 ? content.engagementCounter: 0) +50}
                                params={['engagementCounter']}
                            />
                            <SettingContentArray
                                dbUpdateLink={this.props.firebase.onboardingContent(this.state.cid)}
                                title={"Program listings"} 
                                content={content} 
                                params={['programs']}
                                emptyOption={true}
                            />
                            <SettingContentSelect 
                                dbUpdateLink={this.props.firebase.onboardingContent(this.state.cid)}
                                title={"Content category (tutorial/welcome/...)"} 
                                content={content} 
                                params={['category']}
                                selectOptions={this.props.contentsLabels}
                            />
                            <SettingContentBase 
                                dbUpdateLink={this.props.firebase.onboardingContent(this.state.cid)}
                                title={"Badge Family e.g. total"} 
                                content={content} 
                                params={['badgeFamily']}
                            />
                            <SettingContentArray 
                                dbUpdateLink={this.props.firebase.onboardingContent(this.state.cid)}
                                title={"Internal author"} 
                                content={content} 
                                params={['author']}
                            />
                            <br/>
                            <br/>
                            <Button onClick={this.duplicate}>Duplicate</Button>
                        </div>
                    )}
                    </div>
                </DialogContent>
            </Dialog> 
        );
    }

    duplicate = () => {
        var newContent = this.state.content || {};
        if(newContent !== {}){
            newContent['active'] = false;
            console.log('newcontent', newContent);
            this.props.firebase.onboardingContents().add(newContent);
        }
    }
}

AdminTutorialsPage.propTypes = {
    classes: PropTypes.object.isRequired
}

const TutorialsList = withFirebase(TutorialsListBase);
const TutorialsItemDialog = withFirebase(TutorialsItemBaseDialog);

const condition = authUser =>
    authUser && authUser.roles?.includes(ROLES.ADMIN);

export default compose(
    withStyles(styles),
    // withEmailVerification,
    withAuthorization(condition),
)(AdminTutorialsPage);

