import React, { Component } from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../../Firebase';
import { withAuthorization } from '../../Session';
import { AuthUserContext } from '../../Session';
import * as ROLES from '../../../constants/roles';
import * as chatAgent from '../../../chatAgent';

import {DeleteExamplesFromOtherIntentes} from '../Training/TrainingDataManagement';

//import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DialogIntents from '../Modules/DialogIntents';
//expansion
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionActions';
import AccordionSummary from '@material-ui/core/AccordionSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


import { SelectOption, SelectWrapper } from '../../Modules/SelectOption';
import TextField from '@material-ui/core/TextField';
/// Group move
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';


const styles = {
    dialogContent: {
        marginTop: 20,
    },
    dialogTitle: {
        backgroundColor: '#fcd750',
        fontSize: 13,
    },
    loading: {
        marginTop: 50,
        textAlign: 'center',
    },
    showUser: {
        cursor: 'pointer',
    },
    title: {
        fontSize: 18,
        fontWeight: 700,
        marginTop: 10,
        marginLeft: 15,
    }
};

function convertTimestampToDate(ts) {
    let date = new Date(ts * 1000);
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    let hour = date.getHours();
    let minute = date.getMinutes();

    month = (month < 10 ? "0" : "") + month;
    day = (day < 10 ? "0" : "") + day;
    hour = (hour < 10 ? "0" : "") + hour;
    minute = (minute < 10 ? "0" : "") + minute;

    return year + "-" + month + "-" + day + " [" + hour + ":" + minute + "]";
}

class AdminTrainingPage extends Component {
    static contextType = AuthUserContext;
    _isMounted = false;
    unsubscribe = undefined
    unsubscribeSpecific = undefined

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            trainingList: [],
            open: false,
            username: "",
            email: "",
        };
    }

    componentDidMount() {
        this._isMounted = true;
        this.setState({ loading: true });
        this.loadTrainingIntents();
        if(this.props.moveIntent!==undefined && this.props.moveIntent!==''){
            this.setState({
                moveIntent: this.props.moveIntent,
                moveSentiment: this.props.moveSentiment,
                moveTense: 'default',
                moveExpandedStatus: true
            },() => {
                console.log('this.props.moveIntent',this.props.moveIntent)
                this.loadRelatedIntents();
            })
        }
    }

    /*
    */
    componentWillUnmount() {
        this._isMounted = false;
        if (this.unsubscribe !== undefined) this.unsubscribe();
        if (this.unsubscribeSpecific !== undefined) this.unsubscribeSpecific();
    }

    loadTrainingIntents(direction = 'asc'){
        //console.log("reloaded")
        this.setState({
            loading: true,
        });
        if (this.unsubscribe !== undefined) {
            this.unsubscribe();
        }
        this.unsubscribe = this.props.firebase
        .trainingData()
        ///// adding extra filder
        if(this.state.filterIntent !== undefined && this.state.filterIntent!=='all'){
            console.log('sentiments x',this.state.filterIntent)
            this.unsubscribe = this.unsubscribe
            .where('sentiment', '==', this.state.filterIntent)
            .orderBy('accuracy', direction)
        }
        else{
            this.unsubscribe = this.unsubscribe
            .orderBy('createdAt', 'desc')
        }
        //.orderBy('feedback', 'desc')
        //.orderBy('accuracy', 'desc')
        this.unsubscribe = this.unsubscribe
        //.where('feedback', '>', '""')
        //.orderBy('feedback', 'desc')
        //.orderBy('accuracy', 'asc')
        .limit(100)
        //.get().then(snapshot => {
        .onSnapshot(snapshot => {
            let trainingList = [];
            let accuracyLast = 1;
            snapshot.forEach(doc =>{
                var accuracy = doc.data().accuracy;
                //console.log('accuracy', accuracy)
                if(accuracy<accuracyLast){
                    accuracyLast = accuracy;
                    trainingList.unshift({ ...doc.data(), id: doc.id });
                }
                else{
                    trainingList.push({ ...doc.data(), id: doc.id });
                }
            } );

            //trainingList.sort((a,b) => (a.createdAt > b.createdAt) ? 1 : ((b.createdAt > a.createdAt) ? -1 : 0));
            //trainingList.reverse();
            this.setState({
                trainingList,
                loading: false,
            });
            
        });
    }

    loadRelatedIntents(){
        //console.log('start lopading', this.state.moveIntent)
        if (this.unsubscribeSpecific !== undefined) this.unsubscribeSpecific();
        this.unsubscribeSpecific = this.props.firebase
        .trainingData()
        .where('intent', '==', this.state.moveIntent)
        .orderBy('accuracy', 'asc')
        .limit(20)
        //.get().then(snapshot => {
        .onSnapshot(snapshot => {
            let trainingListSpecific = [];
            snapshot.forEach(doc =>
                trainingListSpecific.push({ ...doc.data(), id: doc.id })
            );
            //trainingList.sort((a,b) => (a.createdAt > b.createdAt) ? 1 : ((b.createdAt > a.createdAt) ? -1 : 0));
            //trainingList.reverse();
            this.setState({
                trainingListSpecific,
                loading: false,
            });
            //console.log('start trainingListSpecific', trainingListSpecific)
        });
    }

    loadRelatedTextIntents(queryText){
        //console.log('start lopading', this.state.moveIntent)
        queryText = queryText.toLowerCase();
        if (this.unsubscribeSpecific !== undefined) this.unsubscribeSpecific();
        this.unsubscribeSpecific = this.props.firebase
        .trainingData()
        .where('sentiment', '==', this.state.moveSentiment)
        //.where('text', '>=', queryText)
        //.where('text', '>=', queryText)
        //.where('text', '<=', queryText+ '\uf8ff')
        //.orderBy('text', 'asc')
        .orderBy('accuracy', 'asc')
        .limit(5000)
        //.get().then(snapshot => {
        .onSnapshot(snapshot => {
            let trainingListSpecific = [];
            var counter = 0;
            snapshot.forEach(doc => {
                var dataSet = doc.data();
                var text = (dataSet.text || '').toLowerCase();
                if (text.includes(queryText)){
                    trainingListSpecific.push({ ...dataSet, id: doc.id })
                }
                counter += 1;
            });
            //trainingList.sort((a,b) => (a.createdAt > b.createdAt) ? 1 : ((b.createdAt > a.createdAt) ? -1 : 0));
            //trainingList.reverse();
            this.setState({
                trainingListSpecific,
                loading: false,
            });
            //console.log('start trainingListSpecific', trainingListSpecific)
            console.log('start trainingListSpecific', counter)
        });
    }

    handleOpen = (data) => {
        //console.log("open screen")
        if( data!== undefined && data.sentiment!== undefined && data.intent!== undefined ){
            this.setState({
                selectedText: data.text, 
                openDialogueUtterance: false, 
                openDialogueIntents: true,
                nluIntent: data,
                selectedSentiment: data.sentiment,
                selectedIntent: data.intent,
            })
        }
        else{
            this.setState({
                selectedText: data.text, 
                openDialogueUtterance: false, 
                openDialogueIntents: true,
                nluIntent: data,
            })
        }
    }

    removeItemFromList= (data, id) => {
        //console.log('delete', data, id)
        if(id !== undefined && id !== ""){
            this.props.firebase.trainingDataItem(id).delete();
        }
        else if(data.id !== undefined && data.id !== ""){
            this.props.firebase.trainingDataItem(id).delete();
        }
    }

    handleClose = () => {
        this.setState({
            openDialogueIntents: false,
        });
    }


    render() {
        const { loading, trainingList, trainingListSpecific } = this.state;
        //console.log('state', this.state)
        const intentList = [
            'all',
            'inform_intention_short',
            'inform_positive_short',
            'inform_neutral_short',
            'inform_negative_short',
            'inform_intention_long',
            'inform_positive_long',
            'inform_neutral_long',
            'inform_negative_long',
            'question',
            'chat',
            'inform_measurement',
        ]
        return (
            <div style={{backgroundColor: "#DDDDDD"}}>
                <h1 style={styles.title}>New unassigned training data</h1>
                <div style={{width: '100%', textAlign:'right'}}>
                    &nbsp; Intent Tag Filter: &nbsp;
                    <TextField
                        name="title"
                        type="text"
                        label="sub-intent"
                        placeholder="e.g. confidence"
                        value={this.state.intentTag || ''}
                        onChange={(e) => this.setState({intentTag: e.target.value})}
                        style={ {fontSize: '16px'} }
                        /> &nbsp;
                    {this.state.filterIntent !== 'all'
                        &&
                        <Button style={{backgroundColor: 'green'}} onClick={() => this.loadTrainingIntents('desc')}>
                            Sort Top
                        </Button>
                    }
                    &nbsp; Filter Intents: &nbsp;
                    <SelectWrapper
                        value={this.state.filterIntent || 'all'}
                        onChange={(e) => {
                            this.setState({filterIntent: e.target.value}, () => this.loadTrainingIntents() );
                        }}
                        name='program'
                        >                  
                        {intentList.map(entry => {
                            return (
                                <SelectOption key={"add_"+entry} value={entry} >
                                    {entry}
                                </SelectOption>
                            ) 
                        })}
                    </SelectWrapper>
                    &nbsp;
                    {this.state.filterIntent !== 'all'
                        &&
                        <Button style={{backgroundColor: 'green'}} onClick={() => this.handleRetrain('intents')}>
                            Retrain
                        </Button>
                    }
                </div>
                {loading &&
                    <div style={styles.loading}>
                        <CircularProgress color="secondary"/>
                    </div>
                }
                {this.state.moveSentiment!== undefined && this.state.moveSentiment!=='' && this.state.moveIntent!==undefined && this.state.moveIntent !== ''
                    &&
                    <>
                        <Accordion 
                            onChange={(event, expanded) => {
                                if(expanded){
                                    this.loadRelatedIntents();
                                }
                            }}
                            expanded={this.state.moveExpandedStatus}
                            >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-label="Account-Expand"
                                aria-controls="Account-content"
                                id="Account-header"
                                >
                                Move check ({trainingListSpecific !== undefined ? trainingListSpecific.length : '0'}) items to: <strong style={{color: "red"}}>{this.state.moveSentiment} / {this.state.moveIntent }</strong> ({this.state.moveTense})
                            </AccordionSummary>
                            <AccordionDetails>
                                <div style={{width: '100%'}}>
                                    <div>
                                        <RadioGroup row aria-label="Type" value={this.state.moveTense} name="type" onChange={(e) => {this.setState({moveTense: e.target.value}) } }>
                                            <FormControlLabel value="default" control={<Radio color='secondary' />} label="default" />
                                            <FormControlLabel value="past" control={<Radio color='secondary'/>} label="past" />
                                            <FormControlLabel value="future" control={<Radio color='secondary' />} label="future" />
                                        </RadioGroup>
                                    </div>
                                    {this.state.moveSentiment!== undefined && this.state.moveSentiment!=='' && this.state.moveIntent!==undefined && this.state.moveIntent !== ''
                                        && this.state.selectionForChange!== undefined && this.state.selectionForChange.length > 0
                                        &&
                                            <Button style={{backgroundColor: 'red'}} onClick={this.handleMoveIntents}>
                                                Move
                                            </Button>
                                    }
                                    <Button style={{backgroundColor: 'green'}} onClick={() => this.handleRetrain('list')}>
                                        Retrain
                                    </Button>
                                    &nbsp; Search by Key: &nbsp;
                                    <TextField
                                        name="title"
                                        type="text"
                                        label="text"
                                        placeholder="e.g. resilient"
                                        value={this.state.intentTextSearch || ''}
                                        onChange={(e) => this.setState({intentTextSearch: e.target.value})}
                                        style={ {fontSize: '16px'} }
                                        /> &nbsp;
                                    {this.state.intentTextSearch?.length>2
                                        &&
                                        <Button style={{backgroundColor: 'green'}} onClick={() => this.loadRelatedTextIntents(this.state.intentTextSearch)}>
                                            Filter
                                        </Button>
                                    }
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Action</TableCell>
                                                <TableCell>Check</TableCell>
                                                <TableCell>Text</TableCell>
                                                <TableCell>Intent</TableCell>
                                                <TableCell>Date</TableCell>
                                                <TableCell>ADD</TableCell>
                                                <TableCell>Acc</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {trainingListSpecific !== undefined && 
                                                trainingListSpecific.map((f, index) => (
                                                    <TableRow key={f.id+"_"+index}
                                                            style={{backgroundColor : f.updatedAt !== undefined ? 'orange' : 'transparent'}}
                                                            >
                                                        <TableCell
                                                                onClick={ () => this.removeItemFromList(f.text, f.id)}
                                                                style={styles.showUser}
                                                            >
                                                            DEL
                                                        </TableCell>
                                                        <TableCell>
                                                            <Checkbox
                                                                style ={{  color: "red" }}
                                                                onChange={(event) => {
                                                                    //console.log('chnge', event)
                                                                    var selectionForChange = this.state.selectionForChange || [];
                                                                    if(event.target.checked){
                                                                        if(!selectionForChange.includes(f.text)){
                                                                            selectionForChange.push(f.text);
                                                                        }
                                                                    }
                                                                    else{
                                                                        if(selectionForChange.includes(f.text)){
                                                                            var index = selectionForChange.indexOf(f.text);
                                                                            if (index !== -1) {
                                                                                selectionForChange.splice(index, 1);
                                                                            }
                                                                        }
                                                                    }
                                                                    this.setState({selectionForChange});
                                                                }}
                                                            />
                                                        </TableCell>
                                                        <TableCell
                                                                onClick={ () => this.handleOpen(f )}
                                                                style={styles.showUser}
                                                            >
                                                            {f.text}
                                                        </TableCell>
                                                        <TableCell>
                                                            {f.intent}
                                                        </TableCell>
                                                        <TableCell>
                                                            ADD
                                                        </TableCell>
                                                        <TableCell>
                                                            {f.createdAt?.seconds !== undefined
                                                                ? convertTimestampToDate(f.createdAt.seconds)
                                                                : 'n/a'
                                                            }
                                                        </TableCell>
                                                        <TableCell
                                                                onClick={ () => this.handleOpen(f )}
                                                                style={styles.showUser}
                                                            >
                                                            {f.accuracy}
                                                        </TableCell>
                                                    </TableRow>
                                                ))
                                            }
                                        </TableBody>
                                    </Table>
                                </div>
                            </AccordionDetails>
                        </Accordion> 
                    </>
                }
                {trainingList && 
                    <Table>
                        <TableHead>
                        <TableRow>
                            <TableCell>Action</TableCell>
                            <TableCell>Date</TableCell>
                            <TableCell>Text</TableCell>
                            <TableCell>Intent</TableCell>
                            <TableCell>False</TableCell>
                            <TableCell>ADD</TableCell>
                            <TableCell>Acc</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                            {trainingList.map((f, index) => (
                                <TableRow key={f.id+"_"+index}  >
                                    <TableCell
                                            onClick={ () => this.removeItemFromList(f.text, f.id)}
                                            style={styles.showUser}
                                        >
                                        DEL
                                    </TableCell>
                                    <TableCell>
                                        {f.createdAt?.seconds !== undefined
                                            ? convertTimestampToDate(f.createdAt.seconds)
                                            : 'n/a'
                                        }
                                    </TableCell>
                                    <TableCell
                                            onClick={ () => this.handleOpen(f )}
                                            style={styles.showUser}
                                        >
                                        {f.text}
                                    </TableCell>
                                    <TableCell>
                                        {f.intent}
                                    </TableCell>
                                    <TableCell
                                            onClick={ () => this.props.firebase.trainingDataItem(f.id).update('accuracy', 0.1)}
                                            style={styles.showUser}
                                        >
                                        False
                                    </TableCell>
                                    <TableCell
                                            onClick={ () => this.addExampletoIntentFromArray(f.text, 'default' , f.sentiment, f.intent)}
                                            style={styles.showUser}
                                        >
                                        ADD
                                    </TableCell>
                                    <TableCell
                                            onClick={ () => this.handleOpen(f )}
                                            style={styles.showUser}
                                        >
                                        {f.accuracy?.toFixed(2) || 0}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    }

                    <DialogIntents
                        openDialogueUtterance = {this.state.openDialogueUtterance || false}
                        openDialogueIntents = {this.state.openDialogueIntents || false}
                        selectedText = {this.state.selectedText || ""}
                        nluIntent = {this.state.nluIntent || undefined}
                        handlerMoveList = {(sentiment, intent, tense) => {this.setState({selectionForChange: [], moveSentiment: sentiment, moveIntent: intent, moveTense: tense}) }}
                        handlerRemoveItem = {(item, replaceId) => {this.removeItemFromList(item, this.state.nluIntent.id)}}
                        closeDialogue = {() => {this.setState({openDialogueUtterance: false, openDialogueIntents:false})}}
                        leadershipCategories={this.props.leadershipCategories || []}
                        leadershipQualities={this.props.leadershipQualities || []}
                        cleanItem = {this.props.cleanItem || undefined}
                        />
            </div>
        )
    }

    handleMoveIntents = () => {
        //console.log('move')
        if(this.state.moveSentiment!== undefined && this.state.moveSentiment!=='' && this.state.moveIntent!==undefined && this.state.moveIntent !== ''
            && this.state.moveTense!==undefined && this.state.moveTense !== ''){
            if(this.state.selectionForChange !== undefined && this.state.selectionForChange.length > 0){
                this.state.selectionForChange.map(example => {
                    this.addExampletoIntentFromArray(example, this.state.moveTense , this.state.moveSentiment, this.state.moveIntent);
                    return true;
                })
            }
        } 
    }

    handleRetrain = (content = 'list') => {
        var trainingListSpecific = undefined;
        if(content === 'list') {trainingListSpecific = this.state.trainingListSpecific || undefined}
        else if(content === 'intents') {trainingListSpecific = this.state.trainingList || undefined}
        if (trainingListSpecific !== undefined ) {
            trainingListSpecific.map((f, index) => {
                console.log(f)
                if(f.text !== undefined && f.id !== undefined){
                    var item = f.text;
                    chatAgent.sendMessage({ 'text': f.text }, 'requestNLU')
                    .then( (result) => {
                        //console.log(item, result)
                        try{
                            var version = 'v2';
                            var newIntentMatch = {
                                text: item,
                                entities : result.entities,
                                sentiment : result.intent.name,
                                confidence : {}
                            };
                            var retrievalIntent = result.intent.name;
                            if(result.response_selector[result.intent.name] !== undefined ){
                                if(version === 'v1'){
                                    retrievalIntent = result.response_selector[result.intent.name].full_retrieval_intent;
                                }
                                else{
                                    retrievalIntent = result.response_selector[result.intent.name].response.intent_response_key;
                                }
                            }

                            var intentName = retrievalIntent.substring(retrievalIntent.indexOf("/") + 1);
                            newIntentMatch['intent'] = intentName;
                            var intents = []
                            if(result.response_selector[result.intent.name] !== undefined){
                                var intent1 = result.response_selector[result.intent.name]['ranking'][0];
                                retrievalIntent = '';
                                if(version === 'v1'){
                                    retrievalIntent = intent1.full_retrieval_intent;
                                    intent1['text'] = intent1.name+'';
                                }
                                else{
                                    retrievalIntent = intent1.intent_response_key;
                                    intent1['text'] = result.response_selector[result.intent.name].response.responses[0].text+'';
                                }
                                intent1['sentiment'] = retrievalIntent.substring(0, retrievalIntent.indexOf("/"));
                                intent1['name'] = retrievalIntent.substring(retrievalIntent.indexOf("/") + 1);
                                intents.push(intent1);
                                var intent2 = result.response_selector[result.intent.name]['ranking'][1];
                                if(version === 'v1'){
                                    retrievalIntent = intent2.full_retrieval_intent;
                                    intent2['text'] = intent2.name+'';
                                }
                                else{
                                    retrievalIntent = intent2.intent_response_key;
                                    intent2['text'] = result.response_selector[result.intent.name].response.responses[0].text+'';
                                }
                                intent2['sentiment'] = retrievalIntent.substring(0, retrievalIntent.indexOf("/"));
                                intent2['name'] = retrievalIntent.substring(retrievalIntent.indexOf("/") + 1);
                                intents.push(intent2);
                            }
                            else{
                                intents=[retrievalIntent]
                            }
                            try{
                                var intent3 = result.response_selector[result.intent_ranking[1].name]['ranking'][0];
                                if(version === 'v1'){
                                    retrievalIntent = intent3.full_retrieval_intent;
                                    intent3['text'] = intent3.name+'';
                                }
                                else{
                                    retrievalIntent = intent3.intent_response_key;
                                    intent3['text'] = '';
                                }
                                intent3['sentiment'] = retrievalIntent.substring(0, retrievalIntent.indexOf("/"));
                                intent3['name'] = retrievalIntent.substring(retrievalIntent.indexOf("/") + 1);
                                intents.push(intent3);
                            }
                            catch(err){}
                            newIntentMatch.confidence['intent'] = intents;
                            var sentiments = []
                            sentiments.push(result.intent_ranking[0]);
                            sentiments.push(result.intent_ranking[1]);
                            newIntentMatch.confidence['sentiment'] = sentiments;
                            if(result.response_selector[result.intent.name] !== undefined){
                                if(version === 'v1'){
                                    newIntentMatch['response'] = result.response_selector[result.intent.name].response.name;
                                }
                                else{
                                    newIntentMatch['response'] = result.response_selector[result.intent.name].response.responses[0].text;
                                }
                                newIntentMatch['accuracy'] = result.response_selector[result.intent.name].response.confidence;
                            }
                            else{
                                newIntentMatch['response'] = result.text;
                                newIntentMatch['accuracy'] = result.intent.confidence;
                            }
                            if(newIntentMatch.accuracy !== undefined){
                                if(newIntentMatch.accuracy > 0.7 && newIntentMatch.intent === f.intent){
                                    console.log('DELETE SAME', f.id, newIntentMatch)
                                    if(f.id !== undefined && f.id !== ""){
                                        this.props.firebase.trainingDataItem(f.id).delete();
                                    }
                                }
                                else if(newIntentMatch.accuracy > 0.7 && newIntentMatch.intent !== f.intent){
                                    console.log('DELETE OTHER', f.id, newIntentMatch)
                                    if(f.id !== undefined && f.id !== ""){
                                        this.props.firebase.trainingDataItem(f.id).delete();
                                    }
                                }
                                else{
                                    console.log('UPDATE', f.id, newIntentMatch)
                                    if(f.id !== undefined && f.id !== ""){
                                        newIntentMatch['updatedAt'] = this.props.firebase.fieldValue.serverTimestamp();
                                        newIntentMatch['createdAt'] = this.props.firebase.fieldValue.serverTimestamp();
                                        this.props.firebase.trainingDataItem(f.id).update(newIntentMatch);
                                    }
                                }
                            }
                        }
                        catch(error){
                            console.log(error)
                        }
                    })
                }
                return true;
            })
        } 
    }

    addExampletoIntentFromArray = (example, tense, sentiment, intent) => {
        if(this._isMounted){
            console.log('move: ' + sentiment, intent )
            if(sentiment!==undefined && intent!==undefined){
                //console.log('Searching for ('+tense+ '): ' + intent, sentiment)
                this.setState({errorMessage : 'Searching for ('+tense+ '): ' + intent});
                //ar index = 0;
                this.props.firebase
                .agentIntentClasses()
                .where('sentiment', '==', sentiment)
                .where('intent', '==', intent)
                .where('tenses', 'array-contains', tense)
                .get().then(snapshot => {
                    if (snapshot.empty){
                        return false;
                    }
                    else{
                        var newIds = []
                        snapshot.forEach((doc) =>{
                            newIds.push(doc.id);
                            var upDateSet = {}
                            if(this.props.selectedExplanation!== undefined &&  this.props.selectedExplanation!== ""){
                                upDateSet["responses"] = this.props.firebase.fieldValue.arrayUnion(this.props.selectedExplanation.trim())
                            }
                            if(tense === 'future'){
                                upDateSet["examplesFuture"] =  this.props.firebase.fieldValue.arrayUnion(example.trim())
                            }
                            else if (tense === 'past'){
                                upDateSet["examplesPast"] =  this.props.firebase.fieldValue.arrayUnion(example.trim())
                            }
                            else{
                                upDateSet["examples"] = this.props.firebase.fieldValue.arrayUnion(example.trim())
                            }
                            console.log( tense + ":", example, upDateSet)
                            this.props.firebase.agentIntent(doc.id).update(upDateSet);
                            if(this.state.trainingListSpecific !== undefined ){
                                var removeText = example;
                                try{
                                    var newId = this.state.trainingListSpecific.find(item => item.text === removeText).id;
                                    //console.log('remove', removeText, newId)
                                    this.removeItemFromList(removeText, newId);
                                }
                                catch(e){

                                }
                            }
                            if(this._isMounted){
                                //console.log('found  :', doc.data(), upDateSet)
                                this.setState({errorMessage : 'Added '+example.trim() +' to ('+tense+ ') ' + intent})
                            }
                            //index += 1;
                        })
                        DeleteExamplesFromOtherIntentes(this, example, newIds);
                        //console.log('added: ', example, intent)
                        if(this.props.cleanItem !== undefined){
                            this.props.cleanItem(example);
                        }
                    }
                })
            }
            else{
                this.setState({errorMessage : 'Please choose sentiment and intent first!'})
            }
        }
    }

}

const condition = authUser =>
      authUser && authUser.roles?.includes(ROLES.ADMIN);

export default compose(
  withFirebase,
  withAuthorization(condition),
)(AdminTrainingPage);
