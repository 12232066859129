import React, { Component } from 'react';
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';

import { AuthUserContext } from '../../Session';
import { withFirebase } from '../../Firebase';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
//import Grid from '@material-ui/core/Grid';
//
//import { SelectOption, SelectWrapper } from '../../Modules/SelectOption';
import TextField from '@material-ui/core/TextField';

import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import _ from "lodash";

const styles = theme => ({
    grow: {
        flexGrow: 1,
    },
});


class SettingContentMapList extends Component {
    static contextType = AuthUserContext;

    constructor(props) {
        super(props);
        var defaultValue = [];
        if(this.props.objectSet){
            defaultValue = {};
        }
        this.state = {
            defaultValue,
            openSetting: false,
        };
    }

    // Username Handling
    handleSettingOpen = () => {
        this.props.params.map((p) => {
            var content = this.props.content[p];
            if(this.props.objectSet){
                if(p.includes('.')){
                    const myArray = p.split(".")
                    myArray.map((item, index) => {
                        if(!item.includes("_TEMPLATE")){
                            if(index === 0 && this.props.content?.[item] !== undefined) content = this.props.content[item];
                            else if(index === 0 && this.props.content?.[item] === undefined) content = {item};
                            else if(index === 0) content = this.props.content[item];
                            else content = content[item];
                            console.log('content', content)
                        }
                        return true
                    })
                }
            }
            if(content!== undefined){
                return this.setState({[p] : content, [(p+'Change')]: content});
            }
            else{
                return this.setState({[p] : this.state.defaultValue, [(p+'Change')]: this.state.defaultValue});
            }
        })
        this.setState({
            openSetting: true,
        });
    }


    handleSettingChange = (param, index, key, event) => {
        console.log(param, event.target.value, index, key)
        var newValue = '';
        var activateUpdate = true;
        if(event.target.value === undefined){
            activateUpdate = false;
        }
        else{
            newValue  = event.target.value;
        }
        if(this.props.max!==undefined && this.props.max>0 ){
            if(event.target.value.length > this.props.max){
                activateUpdate = false;
                this.setState({
                    errorMessage: 'Text is too long - max ' + this.props.max + ' characters'
                });
            }
        } 
        if(index === 0 && this.state[(param+'Change')].length === 1 && newValue === '' && this.props.emptyOption !== true){
            activateUpdate = false;
        }
        if(activateUpdate){
            //console.log(this.state)
            var tempCopy = this.state[(param+'Change')] || [];
            if(this.props.objectSet === true){
                tempCopy[key] = newValue;
                if(newValue === ''){
                    delete tempCopy[key];
                }
                this.setState({
                    [(param+'Change')]: tempCopy,
                    errorMessage: ''
                });
            }
            else{
                var myArray = [...tempCopy];
                //console.log('myarray', myArray, this.state)
                if(index >= (myArray.length)){
                    var newSet = {};
                    newSet[key] = newValue;
                    myArray.push(newSet);
                    this.setState({
                        [(param+'Change')]: myArray,
                        errorMessage: ''
                    });
                    //console.log('added new', myArray, newSet)
                }
                else if(myArray.length > index ){
                    myArray[index][key] = newValue;
                }
                else{
                    console.log('extra new', myArray)
                }
                this.setState({
                    [(param+'Change')]: myArray,
                    errorMessage: ''
                });
            }
            //console.log('State', this.state)
        }
    }

    handleSettingClose = () => {
        this.setState({
            openSetting: false,
        });
        this.props.params.map((p) => {
            //console.log('new  close', p, this.state, this.state.defaultValue)
            return this.setState({[p] : this.state.defaultValue, [p+'Change']: this.state.defaultValue});
        })
    }

    handleSettingSubmit = () => {
        this.props.params.map((p) => {
            //console.log('pre setting', this.state[(p+'Change')])
            if(this.state[(p+'Change')] !== null && this.state[(p+'Change')] !== undefined){
                var myObject = this.state[(p+'Change')] || [];
                var cleanObject = [];
                var failure = false;
                if(this.props.objectSet){
                    cleanObject = {};
                    var changeKey = "";
                    cleanObject = this.state[(p+'Change')];
                    console.log('cleanObject 1',cleanObject , p)
                    Object.keys(cleanObject).map((new_keys) => {
                        console.log('newkyes',new_keys )
                        if(new_keys.includes("_TEMPLATE") || p.includes("_TEMPLATE")){
                            var cleanObjectRepl = cleanObject[new_keys];
                            console.log('cleanObjectRepl', cleanObject, cleanObjectRepl)
                            if(this.props.templateParam?.length > 0){
                                if(new_keys === this.props.templateParam && cleanObject[new_keys]?.length > 0 ){
                                    changeKey = cleanObject[this.props.templateParam];
                                    console.log('changeKey a', changeKey, cleanObject, cleanObjectRepl)
                                    //cleanObject[changeKey] = cleanObjectRepl;
                                    //delete cleanObject[new_keys];
                                    this.setState({
                                        errorMessage: 'Set New Key Parameter'
                                    });
                                }
                                else if(cleanObjectRepl?.[this.props.templateParam]?.length > 0){
                                    changeKey = new_keys.replace("TEMPLATE", cleanObjectRepl[this.props.templateParam]);
                                    console.log('changeKey', changeKey, cleanObject, cleanObjectRepl)
                                    cleanObject[changeKey] = cleanObjectRepl;
                                    delete cleanObject[new_keys];
                                    this.setState({
                                        errorMessage: 'Set New Key Parameter'
                                    });
                                }
                                else{
                                    failure = true;
                                    this.setState({
                                        errorMessage: 'Define Key Parameter'
                                    });
                                }
                            }
                            else{
                                failure = true;
                                this.setState({
                                    errorMessage: 'Missing Key Parameter'
                                });
                            }
                        }
                        return true;
                    })
                    console.log("p", p, changeKey)
                    if(p.includes("_TEMPLATE") && changeKey !== ""){
                        failure = true;
                        var changeKeyP = p.replace("TEMPLATE", changeKey);
                        this.props.dbUpdateLink.update({[changeKeyP]: cleanObject });
                    }
                }
                else{
                    myObject.map((p) => {
                        var addItemFlag = false;
                        Object.keys(p).map((q) => {
                            //console.log('save', p, q, [p][q])
                            if(p[q] !== undefined && p[q] !== '' && p[q].trim() !== ''){
                                //console.log('save check', p, q)
                                addItemFlag = true;
                            }
                            return true;
                        })
                        if(addItemFlag){
                            cleanObject.push(p)
                        }
                        return true;
                    })
                }
                if(!failure){
                    this.props.dbUpdateLink.update({[p]: cleanObject });
                    //console.log('new setting', cleanObject)
                }
            }
            return true;
        })        
        this.handleSettingClose();
    }

    render() {
        const { content, params, title, mapOptions, mapOptionsText} = this.props;
        const { theme, classes } = this.props;
        //console.log('state', this.state)
        //console.log('props', this.props)
        return (
        content
            ?
            <div className={classes.grow}>
                <Card style={theme.cardAdminSettings} onClick={this.handleSettingOpen}>
                    <Grid container>
                        <Grid item xs={9} >
                            <h6><a to="#">{title}</a>
                            {this.props.mandatory 
                                &&  <strong style={{color:theme.palette.themeRed}}>&nbsp;*</strong>
                            }
                            {this.props.mandatory===false
                                &&  <span style={{fontSize: 'small', fontWeight:'normal'}}>&nbsp;(optional)</span>
                            }
                            </h6>
                          </Grid>
                        <Grid item xs >  </Grid>
                        <Grid item xs >
                            {(this.props.access === undefined || this.props.access === true)
                            &&
                                <IconButton onClick={this.handleSettingOpen}> 
                                    <EditIcon fontSize="small" style={{color:"red"}}/>
                                </IconButton>
                            }
                        </Grid>
                    </Grid>
                    {params.map((p, index) => (
                        <p style={theme.textSettings} key={"current_"+p}>
                            <span style={{ float: 'right', fontWeight: 700, maxWidth:'75%' }}>
                                {this.props.objectSet === true
                                    ?   _.get(content, p) !== undefined
                                        ?   'Open to change'
                                        :   'n/a'
                                    :   (p !== undefined && content[p] !== undefined && content[p].length > 0 )
                                        ?   <strong>{content[p].length + ' Items available'}</strong>
                                        :   'n/a'
                                }
                            </span>
                        </p>
                    ))}
                </Card>
                <Dialog
                    open={this.state.openSetting}
                    onClose={this.handleSettingClose}
                    fullWidth
                    maxWidth="sm">
                    <DialogTitle>Change Setting</DialogTitle>
                    <form>
                        <DialogContent>
                            { this.state.openSetting && params.map((p, index) => (
                                <div style={{width: `100%`}} key={"change_"+p}>
                                    <Grid
                                        container
                                        direction="row"
                                        style={{width: `100%`}}
                                        justifyContent="space-between"
                                        alignItems="flex-start"
                                    >
                                        {mapOptions!== undefined && mapOptions.map((m, indexLow) => (
                                            <Grid item xs={6} key={'title_'+indexLow}>
                                                <strong>{mapOptionsText!==undefined && mapOptionsText[indexLow]!== undefined ? mapOptionsText[indexLow] :  m}</strong>
                                            </Grid>
                                        )) }
                                        {this.state[p] !== undefined && Array.isArray(this.state[p]) 
                                            &&
                                            this.state[p].map((x, indexParam) => {
                                                return(
                                                    mapOptions!== undefined && mapOptions.map((m, indexLow) => (
                                                        <Grid item xs={6} key={'field_'+indexParam+'_'+indexLow}>
                                                            <TextField
                                                                key={'main_'+m+'_'+indexLow}
                                                                fullWidth
                                                                style={ {fontSize: '16px'} }
                                                                label={'Add ' + (mapOptionsText!==undefined && mapOptionsText[indexLow]!== undefined ? mapOptionsText[indexLow] :  m)}
                                                                value={this.state[p][indexParam][m] || ''}
                                                                onChange={(e) => this.handleSettingChange(p, indexParam, m, e)}
                                                            />
                                                        </Grid>
                                                    ))
                                                )
                                            })
                                        }
                                        <Grid item xs={12}>
                                            <p>&nbsp;</p>
                                        </Grid>
                                        {mapOptions!== undefined && mapOptions.map((m, indexLow) => (
                                            <Grid item xs={6} key={'extra_'+indexLow}>
                                                {(this.props.limit > 0 && this.state[p] && this.state[p].length>1 && this.props.limit <= this.state[p].length)
                                                    ?   ''
                                                    :   <TextField
                                                            key={'extra_'+m}
                                                            fullWidth
                                                            style={ {fontSize: '16px'} }
                                                            value={this.props.objectSet ? (this.state[(p+'Change')]?.[m] || "") : undefined}
                                                            label={'Add ' + (mapOptionsText!==undefined && mapOptionsText[indexLow]!== undefined ? mapOptionsText[indexLow] :  m)}
                                                            onChange={(e) => this.handleSettingChange(p, (this.state[p].length ) , m, e)}
                                                        />
                                                }
                                            </Grid>
                                        )) }
                                    </Grid>
                                </div>
                            )) }
                            {this.state.errorMessage !== '' && this.state.errorMessage !== undefined
                                ?
                                    <p style={theme.textErrorCenterPadding}>
                                        {this.state.errorMessage}
                                    </p>
                                :   <p> </p>
                            }
                        </DialogContent>
                    </form>
                    <DialogActions>
                        <Button onClick={this.handleSettingClose}>
                            Cancel
                        </Button>
                        <Button onClick={this.handleSettingSubmit}>
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        : <div> </div>
                
        );
    }
}

SettingContentMapList.propTypes = {
    classes: PropTypes.object.isRequired
};

export default compose(
    withStyles(styles, { withTheme: true }),
    withFirebase
)(SettingContentMapList);
