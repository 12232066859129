import HelpOutlineIcon from '@material-ui/icons/HelpOutline';


export function ExternalHelpLink(theme, text, link, target=undefined, textAlign="right"){
    return(
        <span style={{width: '100%', textAlign: textAlign, display: 'block', paddingRight: 10}}>
            <a href={link} target={target?.length > 1 ? target : "_blank"}>
                Help
                <HelpOutlineIcon style={theme.inlineIconRight}/>
                &nbsp;
                {text}
            </a>
        </span>
    )
}
