import React, { Component } from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import withStyles from '@material-ui/core/styles/withStyles';

import LanguageManager from '../../constants/languages';
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from '@material-ui/core/CardContent';

import VizSensor from 'react-visibility-sensor';
import Card from '@material-ui/core/Card';

import YouTubeIcon from '@material-ui/icons/YouTube';
import AudiotrackIcon from '@material-ui/icons/Audiotrack';

import { FindDictByKey, QualityID } from '../Modules/LoadCategoriesAndQualities';

//HN added thumbs up
import BookMarkVote from './BookMarkVote';
import {AddEngagementPoints} from '../Modules/AddEngagementPoints';
//import ContentComments from './ContentComments';
import ContentCommentHighlight from './ContentCommentHighlight';

import AvatarImage from '../Modules/AvatarImage';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';

import Grid from '@material-ui/core/Grid';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import SendIcon from '@material-ui/icons/Send';
import Divider from '@material-ui/core/Divider';

const styles = theme => ({
    root: {
        backgroundColor: 'transparent',
    },
});

class ContentItemCard extends Component { 
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            loading: true
        };
    }

    componentDidMount() {
        this._isMounted = true;
        var bgColor = this.props.theme.palette.secondary.light;
        var catText = "Other Modules";
        var icon = undefined;
        let content = this.props.contentData || undefined;
        if(content !== undefined){
            //console.log('starting', this.props)
            if(content.leadership_quality !== undefined && content.leadership_quality.length>0){
                try {
                    var quality = content.leadership_quality[Math.floor(Math.random() * content.leadership_quality.length)]
                    if (content?.leadership_quality?.includes(this.props.user?.currentLeadershipQuality)){
                        quality = this.props.user.currentLeadershipQuality;
                        //console.log('new q', quality)
                    }
                    var qualityDict = FindDictByKey(this.props.firebase, this.props.leadershipQualities, quality);
                    if(qualityDict?.text?.length > 0){
                        catText = qualityDict.text;
                        bgColor = qualityDict.backgroundColor;
                        if(qualityDict.icon !== undefined && qualityDict.icon!==""){ 
                            icon = qualityDict.icon;
                        }
                }
                    //console.log('try:', bgColor, catText);
                } catch (e) {
                    console.log('error:', e, catText);
                }
            }
            else if(content.leadership_category !== undefined && content.leadership_category.length>0){
                try {
                    var catDict = FindDictByKey(this.props.firebase, this.props.leadershipCategories, content.leadership_category);
                    if(catDict?.text?.length > 0){
                        bgColor = catDict.backgroundColor;
                        catText = catDict.text;
                        if(catDict.icon !== undefined && catDict.icon!=="") {
                            icon = catDict.icon;
                        }
                        //console.log('try:', bgColor, catText);
                    }
                } catch (e) {
                    bgColor = this.props.theme.palette.secondary.light;
                }
            }
        }
        this.setState( {
            loading: false,
            catText,
            bgColor,
            icon
        });
        //console.log("content",  content)
    }
    componentWillUnmount() {
        this._isMounted = false;
        if (this.unsubscribeBookmark !== undefined){
            this.unsubscribeBookmark()
        }
    }

    handleOpen(type="content"){
        //console.log("itemcard", this.props.contentId);
        if (this.props.handleOpen !== undefined ){
            this.props.handleOpen(this.props.contentId, type);
        }
    }
    
    render(){
        const { theme } = this.props;
        const {bgColor, catText, icon} = this.state;
        let content = this.props.contentData || undefined;
        if(content !== undefined){
            return(
                <VizSensor 
                    partialVisibility={true} 
                    active={ this.state.vizSensorActive !== false ? true : false}
                    onChange={(isVisible) => { 
                        if(isVisible && this.state['isVisible_'+this.props.contentId]===undefined && this._isMounted) { 
                            this.setState({['isVisible_'+this.props.contentId]: true, ['isVisible_Bookmark_'+this.props.contentId]: true, vizSensorActive: false});
                            if (this.props.listIndex !== undefined && this.props.handlerListIndex!== undefined ){
                                this.props.handlerListIndex(this.props.listIndex);
                            }
                        }
                    }} >
                    { ({isVisible}) =>
                    <>
                    {this.props.showMinimal !== true 
                        ?
                            <Card 
                                key={this.props.contentId + content.leadership_category} 
                                style={{
                                    ...this.props.raised 
                                        ? theme.CardContentItem.rootSwipeShadow
                                        : theme.CardContentItem.rootFeed
                                    ,
                                    opacity : this.state['hover'+this.props.contentId ] ? 0.7 : 1
                                }}
                                onMouseEnter={(this.state['hover'+this.props.contentId ] !== true) ? () => {this.setState({['hover'+this.props.contentId ]: true})} : void(0)}
                                onMouseLeave={(this.state['hover'+this.props.contentId ] === true) ? () => {this.setState({['hover'+this.props.contentId ]: false})} : void(0)}
                                >
                                <CardContent style={{...theme.CardContentItem.CardContentFeed, paddingBottom:0}} 
                                    onClick={() => this.handleOpen()} >
                                    <Grid container 
                                        spacing={2} 
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center" 
                                    >
                                        <Grid item xs={12} style={{maxWidth:"85vw", width:"100%"}}>
                                            <Grid container 
                                                spacing={2} 
                                                direction="row"
                                                justifyContent="flex-start"
                                                alignItems="center"
                                                >
                                                <Grid item style={{width: "20%"}}>
                                                    {content?.leadership_quality?.length === 1
                                                        ?
                                                            <AvatarImage 
                                                                user={this.props.user}
                                                                textBlock={catText} 
                                                                dict={this.props.leadershipQualities} 
                                                                type='quality'
                                                                image={icon}
                                                                item={content.leadership_quality[0]} 
                                                                squareSize='20' 
                                                                backgroundColor={bgColor} />
                                                        :   
                                                            <AvatarImage 
                                                                user={this.props.user}
                                                                textBlock={catText} 
                                                                dict={this.props.leadershipCategories} 
                                                                type='category'
                                                                image={icon}
                                                                item={content.leadership_category} 
                                                                squareSize='20' 
                                                                backgroundColor={bgColor} />
                                                    }
                                                </Grid>
                                                <Grid item style={{maxWidth:"65vw", width:"80%", padding: "0px 0px 0px 6px"}}>
                                                    {content.category === "quotes"
                                                        ?   <span style={{color: theme.palette.themeGrey}}>{content.subtitle}</span> 
                                                        :   <>
                                                                <strong>
                                                                    <LanguageManager
                                                                        text= {content.title}
                                                                        type='text'
                                                                        forceEnglish={content?.baseLanguage?.length > 0 && !(content?.baseLanguage?.includes('en'))}
                                                                        language={this.props.user?.features?.language}
                                                                        handlerUpdate={(text) => this.setState({textTitle: text})}
                                                                    />
                                                                </strong> 
                                                                <br/>
                                                                <span style={{color: theme.palette.themeGrey}}>{content.source}</span> 
                                                            </>
                                                    }
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} style={{paddingBottom: 0, paddingTop:0}}>
                                            {content.category === "quotes" 
                                                ?   <p><q>
                                                        <LanguageManager
                                                            text= {content.title}
                                                            type='text'
                                                            forceEnglish={content?.baseLanguage?.length > 0 && !(content?.baseLanguage?.includes('en'))}
                                                            language={this.props.user?.features?.language}
                                                            handlerUpdate={(text) => this.setState({textTitle: text})}
                                                        />
                                                    </q></p> 
                                                :   <p>
                                                        <LanguageManager
                                                            text= {content.subtitle}
                                                            type='text'
                                                            forceEnglish={content?.baseLanguage?.length > 0 && !(content?.baseLanguage?.includes('en'))}
                                                            language={this.props.user?.features?.language}
                                                        />
                                                    </p> 
                                            }
                                        </Grid>
                                    </Grid>
                                </CardContent>
                                <>
                                    {(content.url) 
                                    //&& this.state["imgViz_"+this.props.contentId] 
                                        ?
                                        <CardMedia
                                            style={theme.CardContentItem.CardMedia}
                                            image={(isVisible || this.state['isVisible_'+this.props.contentId]) ? content.url : ''}
                                            onClick={() => this.handleOpen()}
                                        >
                                            {catText !== "Other Modules" &&
                                                <div style={theme.CardContentItem.ribbon}>
                                                    {(content?.category === 'tips' && (content?.premium === true || (content?.program !== 'default' && !(content?.premium === false))) )
                                                    &&
                                                        <span style={{position:'relative',
                                                            top:2,
                                                            left:-4,
                                                            color:theme.palette.themeAltGold}}
                                                        >
                                                            <ShoppingBasketIcon style={{ color:theme.palette.themeAltGold, fontSize: '1rem' }}/>
                                                        </span>
                                                    }
                                                    <LanguageManager
                                                        text= {catText}
                                                        type='button'
                                                        language={this.context?.features?.language}
                                                    />
                                                </div>
                                            }
                                            {content.youtubeId !== undefined && content.youtubeId !== ""
                                                &&
                                                <div style={theme.CardContentItem.ribbonIcon}>
                                                    <YouTubeIcon 
                                                        onClick={() => this.handleOpen()} />
                                                </div>
                                            }
                                            {content.mediaType === 'video' && content.s3Url !== undefined
                                                &&
                                                <div style={theme.CardContentItem.ribbonIcon}>
                                                    <YouTubeIcon 
                                                        onClick={() => this.handleOpen()} />
                                                </div>
                                            }
                                            {content.mediaType === 'audio' && content.s3Url !== undefined
                                                &&
                                                <div style={theme.CardContentItem.ribbonIcon}>
                                                    <AudiotrackIcon 
                                                        onClick={() => this.handleOpen()} />
                                                </div>
                                            }
                                        </CardMedia>
                                        :
                                        <CardMedia
                                            style={{...theme.CardContentItem.CardMedia, paddingTop:"30%", 
                                                background: 'linear-gradient(215deg, #FFFFFF 10%, '+bgColor+' 90%)', 
                                            }}
                                            //image={this.state['isVisible_'+this.props.contentId] ?  createGradientImage(bgColor, "#FFFFFF") : '' }
                                            //image={this.state['isVisible_'+this.props.contentId] ?  pixel : '' }
                                            onClick={() => this.handleOpen()}
                                        >
                                            <div style={theme.CardContentItem.ribbon}>
                                                {catText}
                                            </div>
                                            {content.youtubeId !== undefined && content.youtubeId !== ""
                                                &&
                                                <div style={theme.CardContentItem.ribbonIcon}>
                                                    <YouTubeIcon 
                                                        onClick={() => this.handleOpen()} />
                                                </div>
                                            }
                                        </CardMedia>
                                    }
                                    <CardContent style={{...theme.CardContentItem.CardContentFeed}}
                                        >
                                            {(isVisible || this.state['isVisible_Bookmark_'+this.props.contentId] )
                                            && !this.state.addExtraAction 
                                                &&
                                                <BookMarkVote
                                                    userId={this.props.user.uid}
                                                    contentData={content}
                                                    contentId={this.props.contentId}
                                                    handleOpen={(contentData, type="content") => this.handleOpen( type)}
                                                    handleOpenComment={(contentData, type="comment") => this.handleOpen( type)}
                                                    handleOpenRead={(contentData, type="read") => this.handleOpen( type)}
                                                    commentsAndActions={true}
                                                    //openCommentsPopup={this.state.OpenCommentsPopup || false}
                                                    //userEngagement={this.state[this.props.contentId]} 
                                                    //setParent={ v => this.setState({[this.props.contentId] : v}) }
                                                />
                                            }
                                            {(isVisible || this.state['isVisible_Bookmark_'+this.props.contentId] )
                                            && this.props.previewComments 
                                                &&
                                                <div
                                                    onClick = {() => this.handleOpen("comment")}
                                                > 
                                                    <ContentCommentHighlight 
                                                        loadHandler={this.props.loadHandler !== undefined ? this.props.loadHandler : void(0)}
                                                        contentId={this.props.contentId} 
                                                        visualCommentsLimit={2}
                                                    />
                                                </div>
                                            }
                                            {(isVisible || this.state['isVisible_Bookmark_'+this.props.contentId] )
                                            && this.props.previewLatestComment 
                                                &&
                                                <div
                                                    onClick = {() => this.handleOpen("comment")}
                                                > 
                                                    <ContentCommentHighlight 
                                                        loadHandler={this.props.loadHandler !== undefined ? this.props.loadHandler : void(0)}
                                                        contentId={this.props.contentId} 
                                                        visualCommentsLimit={1}
                                                    />
                                                </div>
                                            }
                                    </CardContent>
                                    {this.props.showActions && this.props.handlerMessageSubmit && content?.validated && content?.actions?.length>0 &&
                                        <CardContent style={{...theme.CardContentItem.CardContentFeed, paddingTop: 0}} >
                                            {(isVisible || this.state['isVisible_Bookmark_'+this.props.contentId] )
                                                &&
                                                <>
                                                    <Divider/>
                                                    <List 
                                                        style={{
                                                            height: this.state.nonSubscriber ? 160 : 'auto',
                                                            overflowY: this.state.nonSubscriber ? 'hidden' : 'auto',
                                                            //filter: this.state.nonSubscriber? 'blur(4px)': 'none'
                                                            WebkitMask: this.state.nonSubscriber 
                                                            //'-webkit-mask': this.state.nonSubscriber 
                                                                ?   '-webkit-gradient( linear, left 0%, left 140, from(#fefffe), to(rgba(0, 0, 0, 0)) )'
                                                                :   'none'
                                                        }}>
                                                        {content.actions.map((content, index) => {
                                                            if(index < 2)
                                                                return(
                                                                    <ListItem key={'expl_'+index} 
                                                                        style={{paddingLeft:0, paddingRight:0 }}
                                                                        onClick={
                                                                            this.props.handlerMessageSubmit !== undefined
                                                                                ?   () => {
                                                                                        if(this.props.messageSubmitFull !== undefined){
                                                                                            this.props.messageSubmitFull(content);
                                                                                        }
                                                                                        else{
                                                                                            this.props.handlerMessageSubmit(content);
                                                                                        }
                                                                                    }
                                                                                :   void(0)    
                                                                            }
                                                                        onMouseEnter={(this.props.handlerMessageSubmit !== undefined) ? () => {this.setState({['hover'+index]: true})} : void(0)}
                                                                        onMouseLeave={(this.props.handlerMessageSubmit !== undefined) ? () => {this.setState({['hover'+index]: false})} : void(0)}
                                                                        >
                                                                        <ListItemText disableTypography 
                                                                            style={{color: this.state['hover'+index] === true ? theme.palette.themeAltGold : theme.palette.themeText, paddingLeft:0, paddingRight: 10}}>
                                                                            {content}
                                                                        </ListItemText>
                                                                        {this.props.handlerMessageSubmit !== undefined
                                                                            &&
                                                                            <ListItemIcon style={{marginRight:10, minWidth:'auto',  color:"green"}}>
                                                                                <SendIcon fontSize='small' style={{color: theme.palette.secondary.main}}  />
                                                                            </ListItemIcon>
                                                                        }
                                                                    </ListItem>
                                                                )
                                                            else return ''            
                                                        })}
                                                    </List>
                                                </>
                                            }
                                        </CardContent>
                                    }
                                </>
                            </Card>
                        :
                            <div onClick={() => this.handleOpen()} >
                                <Grid container 
                                    spacing={2} 
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center" 
                                    >
                                        
                                    <Grid item xs={3} style={{paddingLeft:5, paddingBottom:5, textAlign: 'right', maxWidth: 100, marginLeft: "auto"}}>
                                        {content.url
                                            ?
                                                <div style={{
                                                    backgroundImage: `url(${content.url})`,
                                                    backgroundPosition: "center 30%",
                                                    borderRadius: theme.borderRadius,
                                                    backgroundSize: "cover",
                                                    backgroundRepeat: "no-repeat",
                                                    width: this.state.previewWidth,
                                                    height: this.state.previewHeight || 40,
                                                    margin: 5,
                                                    }}>
                                                </div>
                                            :
                                                <AvatarImage 
                                                    textBlock={catText} 
                                                    dict={this.props.leadershipCategories} 
                                                    type='category'
                                                    image={icon}
                                                    item={content.leadership_category} 
                                                    squareSize='20' 
                                                    backgroundColor={bgColor} 
                                                />
                                        }
                                    </Grid>
                                    <Grid item xs={9}>
                                        <div  style={{paddingLeft: 10, paddingRight: 10, display: 'flex'}}>
                                            <span>
                                                {(content?.category === 'tips' && (content?.premium === true || (content?.program !== 'default' && !(content?.premium === false))) )
                                                &&
                                                    <ShoppingBasketIcon style={{ ...theme.inlineIconLeft , color:theme.palette.themeAltGold}} />
                                                }
                                                <span style={{ color:theme.palette.secondary.main, textDecoration: "underline", fontSize: 'small'}}>
                                                {content?.textWordCount > 90
                                                    ?
                                                        ("Open "+ Math.round(content.textWordCount/100*0.6) + " min read:")
                                                    :
                                                        "Read Article:"
                                                }
                                                </span>
                                                {content.youtubeId !== undefined && content.youtubeId !== ""
                                                    &&
                                                    <YouTubeIcon style={{ ...theme.inlineIconRight}} />
                                                }
                                                {content.mediaType === 'video' && content.s3Url !== undefined
                                                    &&
                                                    <YouTubeIcon style={{ ...theme.inlineIconRight}} />
                                                }
                                                {content.mediaType === 'audio' && content.s3Url !== undefined
                                                    &&
                                                    <AudiotrackIcon style={{ ...theme.inlineIconRight}} />
                                                }
                                                <br/>
                                                <span style={{color: theme.palette.themeText}}>
                                                    {content.title?.length> 40
                                                        ? (this.state.textTitle || content.title).substring(0,39) + '...'
                                                        : (this.state.textTitle || content.title)
                                                    }
                                                </span>
                                            </span>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                    }
                    </>
                    }
                </VizSensor>
            
            )// end return
        }
        else{
            return "loading"
        }
    }//end render



    getBookMarks = (contentId) => {
        let content = this.props.contentData || undefined;
        if(content !== undefined && contentId!== undefined && contentId!== "") {
            this.unsubscribeBookmark = this.props.firebase.contentBookmark(contentId, this.props.user.id)
            //this.props.firebase.contentBookmark(contentId, this.props.user.id)
                //.get().then(docEngagement => {
                .onSnapshot(docEngagement => {
                    if(this._isMounted){
                        //console.log('loaded engagement:', docEngagement.data());
                        if (docEngagement.exists) {
                            var userEngagement = docEngagement.data();
                            var contentEngagementRating = 1;
                            if(userEngagement.read) {contentEngagementRating +=1}
                            if(userEngagement.comment !== undefined && userEngagement.comment.length > 20  ) {contentEngagementRating +=1}
                            else if (userEngagement.comment !== undefined && userEngagement.comment.length > 2  ) {contentEngagementRating +=0.5}
                            if(userEngagement.actions !== undefined && userEngagement.actions.length >= 1 ) {contentEngagementRating +=1}
                            if(userEngagement.vote !== undefined && userEngagement.vote !== '' ) {contentEngagementRating +=1}
                            //this.setState({ tips: shuffle(tips) });
                            if(this._isMounted){
                                this.setState({
                                    ['userEngagement' + contentId]: userEngagement,
                                    contentEngagementRating
                                });
                            }
                        }//end if exists
                        else if(this._isMounted) {
                            this.setState({
                                ['userEngagement' + contentId]: []
                            });
                        }//end else
                        if( this.state['userEngagement' + contentId] === undefined
                            || ( this.state['userEngagement' + contentId] !== undefined && !( this.state['userEngagement' + contentId].opened) ) ){
                            var engagement = {
                                userId: this.props.user.id,
                                points: 1,
                                type:   'content',
                                message: 'Opened: ' + (this.state.textTitle || content.title),
                                origin: 'resources',
                                }
                            if(content.leadership_category !== undefined){
                                engagement['leadership_category'] = content.leadership_category;
                            }
                            if(content.leadership_quality !== undefined){
                                engagement['leadership_quality'] = QualityID(content.leadership_quality);
                            }
                            AddEngagementPoints(this.props.firebase, engagement);
                            this.props.firebase.contentBookmark(contentId, this.props.user.uid).set({
                                createdAt: this.props.firebase.fieldValue.serverTimestamp(), 
                                origin: 'resources',
                                opened: true,
                                contentId: this.props.contentId,
                                userId: this.props.user.uid
                            }, {merge:true }); 
                        }//end addengagement
                    }//end if ismounted
                });
        }
    }

    expandHandler = (contentId) => {
        let content = this.props.contentData || undefined;
        if(content !== undefined){
            if( !(this.state['contentExpanded'+contentId]) 
                // && (this.state['userEngagement'+contentId] !== undefined && !(this.state['userEngagement'+contentId].read) ) 
            ){
                var engagement = {
                    userId: this.props.user.id,
                    points: 1,
                    type:   'content',
                    message: 'Read: ' + (this.state.textTitle || content.title),
                    origin: 'resources',
                    }
                if(content.leadership_category !== undefined){
                    engagement['leadership_category'] = content.leadership_category;
                }
                if(content.leadership_quality !== undefined){
                    engagement['leadership_quality'] = QualityID(content.leadership_quality);
                }
                AddEngagementPoints(this.props.firebase, engagement);
                this.props.firebase.contentBookmark(contentId, this.props.user.id).set({
                    createdAt: this.props.firebase.fieldValue.serverTimestamp(), 
                    origin: 'resources',
                    read: true,
                    contentId: contentId,
                    userId: this.props.user.id
                }, {merge:true }); 
            }
            this.setState({
                ['contentExpanded'+contentId] : true,
            });
        }
    }


};//end class


export function ContentProgramOverwrite(content, userData, overWriteQualityValue = undefined, leadershipQualities=[], addingMode = false){
    //console.log('overwrite quality', content)
    if(userData?.uid?.length > 4){
        var qualities = content['leadership_quality'] || [];
        var currentLeadershipQuality = userData.currentLeadershipQuality;
        var filterCurrentLeadershipQuality = ''+currentLeadershipQuality;
        var filterCurrentTopicAlternative = "XSFDNRUBGRU";
        if(filterCurrentLeadershipQuality?.includes(".")){
            filterCurrentLeadershipQuality  = currentLeadershipQuality.substring(0, currentLeadershipQuality.indexOf("."));
            filterCurrentTopicAlternative = currentLeadershipQuality.substring(currentLeadershipQuality.indexOf(".") + 1);
        }
        //// 1. overwrite forced
        if(overWriteQualityValue?.length > 0){
            if(addingMode){
                content['leadership_quality'] = content['leadership_quality'].push(overWriteQualityValue);
            }
            else{
                content['leadership_quality'] = [overWriteQualityValue];
            }
            //console.log('overWriteQualityValue', overWriteQualityValue)
        }
        //// 2. use the main quality
        else if((qualities.includes(currentLeadershipQuality) || qualities.includes(filterCurrentLeadershipQuality || qualities.include(filterCurrentTopicAlternative))) && (currentLeadershipQuality !== filterCurrentLeadershipQuality || currentLeadershipQuality.includes(".")) ){
            if(addingMode){
                content['leadership_quality'] = [currentLeadershipQuality, filterCurrentLeadershipQuality, filterCurrentTopicAlternative];
            }
            else{
                content['leadership_quality'] = [currentLeadershipQuality];
            }
            //console.log('currentLeadershipQuality', currentLeadershipQuality, filterCurrentLeadershipQuality, filterCurrentTopicAlternative)
        }
        else if(userData?.additionalLeadershipQuality1?.length > 5){
            [userData.additionalLeadershipQuality1,
                userData.additionalLeadershipQuality2,
                userData.additionalLeadershipQuality3,
                userData.additionalLeadershipQuality4,
                userData.additionalLeadershipQuality5,
                userData.additionalLeadershipQuality6]?.every(item => {
                if(item?.length > 2){
                    filterCurrentLeadershipQuality = ''+item;
                    if(item?.includes(".")){
                        filterCurrentTopicAlternative = item.substring(0, item.indexOf("."));
                        filterCurrentLeadershipQuality = item.substring(item.indexOf(".") + 1);
                    }
                    //console.log('additionalLeadershipQuality1', item, filterCurrentLeadershipQuality)
                    if(qualities.includes(item) || qualities.includes(filterCurrentLeadershipQuality || qualities.include(filterCurrentTopicAlternative))){
                        if(addingMode){
                            content['leadership_quality'] = [item, filterCurrentLeadershipQuality, filterCurrentTopicAlternative];
                        }
                        else{
                            content['leadership_quality'] = [item];
                        }
                        //console.log("ContentItemCard", item, filterCurrentLeadershipQuality, filterCurrentTopicAlternative)
                        return false;
                    }
                    else{
                        return true;
                    }
                }
                else{
                    return true;
                }
            })
        }
        else if(!['default', 'confidence', 'priority', 'communication', 'people', 'clarity', 'balance'].includes(userData?.currentCategory)){
            content['leadership_quality']?.every( itemkey => {
                var replaceQuality = (leadershipQualities?.find(c => c.motherQuality === itemkey)?.value || '');
                //console.log('checkitemkey', itemkey, replaceQuality)
                if (replaceQuality===''){
                    return true
                }
                else{
                    content['leadership_quality'] = [replaceQuality];
                    return false
                }
            })
        }
    }
    return content;
}

export default compose(
    withStyles(styles, { withTheme: true }),
    withFirebase,
)(ContentItemCard);
