import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { compose } from "recompose";
import withStyles from "@material-ui/core/styles/withStyles";
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import LanguageManager from '../../constants/languages';

import {pageText} from '../SignIn';
import {stylesSignin, linksInfo} from '../SignIn';

import logo from '../../images/rockyai-logo-image-only.png';
import logoCaption from '../../images/rockyai-logo-caption.png';

// MUI stuff
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';


const INITIAL_STATE = {
  email: '',
  error: null,
};

class PasswordForgetPage extends Component {
    constructor(props) {
        super(props);
        var storedAuthUser = JSON.parse(window?.localStorage?.getItem('authUser')) || undefined;
        this.state = { ...INITIAL_STATE, storedAuthUser };
    }

  onSubmit = event => {
    const { email } = this.state;

    this.props.firebase
      .doPasswordReset(email)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        var errorEmail = new Error("An email will be send today to: {number:" +email+"}"); 
        this.setState({ error : errorEmail});
      })
      .catch(error => {
	      this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

    render() {
        const {classes, defaultSet} = this.props;
        const { error } = this.state;

        return (
            <div className={classes.rootFrame}>
                <div className={classes.root}>
                    <Grid
                        container
                        spacing={0}
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Grid item xs={12} style={{ maxWidth: 500 }}>
                            <div className={classes.login}>
                            <Link to={ROUTES.HOME}>
                                <div className={classes.logoContainer}>
                                    <img 
                                        src={defaultSet?.programLogo?.length > 5 
                                            ?   defaultSet.programLogo
                                            :   logo
                                        }
                                        className={classes.logo} alt="My AI Coach" />
                                    {false && /// not always brand
                                        <img
                                            src={defaultSet?.programBrand?.length > 5 
                                                ?   defaultSet.programBrand
                                                :   logoCaption
                                            }
                                            className={classes.logoCaption}
                                            alt="My AI Coach"
                                        />
                                    }
                                </div> 
                            </Link>
                            <h4 >
                                <LanguageManager
                                    text={"Forgot your password?"}
                                    type='tutorial'
                                    language={this.state.storedAuthUser?.features?.language || defaultSet?.programLanguage}
                                />
                            </h4>
                            <div>
                                <form onSubmit={this.onSubmit}>
                                    <TextField
                                        name="email"
                                        //label="Email"
                                        value={this.state.email}
                                        onChange={this.onChange}
                                        type="text"
                                        variant="outlined"
                                        placeholder="Email"
                                        fullWidth
                                        className={classes.textField}
                                    />
                                    {error && error !== null &&
                                        error.code !== pageText.ERROR_CODE_USER_NOT_FOUND 
                                        ?
                                            <p className={classes.error}>
                                                <LanguageManager
                                                    text={error.message}
                                                    type='tutorial'
                                                    language={this.state.storedAuthUser?.features?.language || defaultSet?.programLanguage}
                                                />
                                            </p>
                                        :
                                            ""
                                    }

                                    {error && error !== null &&
                                        error.code === pageText.ERROR_CODE_USER_NOT_FOUND
                                        ?
                                            <p className={classes.error}>
                                                <LanguageManager
                                                    text={error.message}
                                                    type='tutorial'
                                                    language={this.state.storedAuthUser?.features?.language || defaultSet?.programLanguage}
                                                />
                                                &nbsp;
                                                <Link to={ROUTES.SIGN_UP}>{pageText.linkResetNEW}</Link>
                                            </p>
                                        :
                                            ""
                                    }
                                    <p style={{ marginTop: 30, marginBottom:  50}}>
                                        <LanguageManager
                                            text={"Send me instructions to change password."}
                                            type='tutorial'
                                            language={this.state.storedAuthUser?.features?.language || defaultSet?.programLanguage}
                                        />
                                    </p>
                                    <div className={classes.socialLogins}>
                                        <Button
                                            className={classes.button}
                                            //disabled={isInvalid}
                                            type="submit"
                                        >
                                            <LanguageManager
                                                text={"Send via email"}
                                                type='button'
                                                language={this.state.storedAuthUser?.features?.language || defaultSet?.programLanguage}
                                            />
                                        </Button>
                                    </div>
                                </form>
                            </div>
                            <div>
                                <hr />
                                <p className={classes.socialLoginPrompt}>
                                    <LanguageManager
                                        text={"Back to Login"}
                                        type='button'
                                        language={this.state.storedAuthUser?.features?.language || defaultSet?.programLanguage}
                                    />
                                </p>
                                <div className={classes.socialLogins}>
                                    <Link to={ROUTES.SIGN_IN}>
                                        <Button className={classes.buttonBottom}>
                                            <LanguageManager
                                                text={"Log In"}
                                                type='button'
                                                language={this.state.storedAuthUser?.features?.language || defaultSet?.programLanguage}
                                            />
                                        </Button>
                                    </Link>
                                </div>
                            </div>
                            </div>
                            <div className={classes.footer}>
                                {linksInfo(defaultSet, this.state.storedAuthUser)}
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
        );
    }
}

export default PasswordForgetPage = compose(
  withStyles(stylesSignin, { withTheme: true }),
  withFirebase
)(PasswordForgetPage);

export const PasswordForgetForm = 'Please check passwordchange form'