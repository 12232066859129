//import palette from '../palette';

function muiStyle (palette) { return {
    root: {
        color: palette.secondary.main,
        fontWeight: 'bold',
        paddingLeft: 14,
        paddingRight: 2,
        backgroundColor: palette.white + "66",
        borderRadius: 15,
        },
        select: {
            '&:focus': {
                backgroundColor: palette.white + "66",
                borderRadius: 15,
            },
            '&.MuiSelect-select': {  // Add this new style
                borderRadius: 15,
                textAlign: 'center',
            },
        },
        selectMenu: {
            backgroundColor: palette.white + "66",
            borderRadius: 15,
        },
        outlined: {
            '&:not(.Mui-focused) .MuiOutlinedInput-notchedOutline': {
                borderColor: 'transparent',
                borderBottom: 'none',
            },
            '& .MuiOutlinedInput-notchedOutline': {
                borderRadius: 15,
                borderBottom: 'none',
            },
        },
        inputBase: {
            '&.Mui-focused': {
                borderBottom: 'none',
            },
        },
}}

export default muiStyle;