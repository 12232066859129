import React, { Component } from 'react';
import { compose } from 'recompose';
import LanguageManager from '../../constants/languages';
import withStyles from '@material-ui/core/styles/withStyles';
import Switch from "react-switch";
import { AuthUserContext } from '../Session';
import { withFirebase } from '../Firebase';
import StarsIcon from '@material-ui/icons/Stars';
import Card from '@material-ui/core/Card';
import List from '@material-ui/core/List';

const styles = theme => ({
    grow: {
        flexGrow: 1,
    },
});

class SettingGamification extends Component {
    static contextType = AuthUserContext;
    constructor(props) {
        super(props);
        this.state = {
            openSetting: false,
        };
    }
    render() {
        //const { user } = this.props;
        const { theme, classes } = this.props;
        //console.log("window params", window)
        return (
            <div className={classes.grow}>
                <Card style={theme.cardSettings}>
                    <List style={theme.textSettings}>
                        <span style= {{ float: 'right'}}>
                            <Switch
                                checked= {this.props.user?.features?.gamification !== undefined ? this.props.user?.features?.gamification : true}
                                //uncheckedIcon= {false}
                                checkedIcon= {<StarsIcon style= {{marginTop: 2}}/>}
                                //offColor= {theme.palette.themeAltGold}
                                onColor= {theme.palette.secondary.main}
                                //value= {this.state.currentNotificationFrequency}
                                onChange= {(c, event, id) => {
                                    if(c){
                                        this.props.firebase.user(this.props.user.uid).update({
                                            ['features.gamification'] : true
                                        });
                                    }
                                    else{
                                        this.props.firebase.user(this.props.user.uid).update({
                                            ['features.gamification'] : false
                                        });
                                    }
                                }}
                                //disabled= {this.props.user.dialogueCounter >= 6 ? true : false}
                            />
                        </span>
                        <span style= {theme.textSettings.settingsTextDescription}>
                            <StarsIcon style= {theme.inlineIconLeft}/>
                            <LanguageManager
                                text={"Show rewards"}
                                type='button'
                                language={this.context?.features?.language}
                            />
                            :
                        </span>
                        <div style= {{width: "60%"}}>
                            <p style= {theme.textSupportGrey}>
                                <LanguageManager
                                    text={"To focus better, you can hide rewards and remove gamification features from the app."}
                                    type='tutorial'
                                    language={this.context?.features?.language}
                                />
                            </p>
                        </div>
                    </List>
                </Card>
            </div>
                
        );
    }
}


export default compose(
    withStyles(styles, { withTheme: true }),
    withFirebase
)(SettingGamification);
