///// FUNCTION SUITE TO LOAD AND MANAGE CODES
import {GetTimeZone} from '../Modules/LoadUserTimezone';
import {ZonesEnglish} from '../../constants/languages';

export var MyInstallationOfflineDataSet = {
    emailNotifications: "casually",
    achievementGoal: "Reflecting 5 minutes a day",
    roles: ['default'],
    code: "",
    programs: ['default'],
    ctaText: "Practice daily with your AI Coach!",
    currentCategory: "priority",
    currentLeadershipQuality: "focus",
    firstSessionDone: false,
    primingFrom: "04:00",
    primingUntil: "15:00",
    reflectionFrom: "15:00",
    reflectionUntil: "04:00",
    dialogueCounter: 0,
    offlineSetUsed: true
};

export const SIGNUPCREATORCODE = "#NEWCOACHCREATOR"
export var MyInstallationOfflineCreatorDataSet = {
    emailNotifications: "weekly",
    achievementGoal: "Using digital coaching with my clients.",
    roles: ['COACHADMIN'],
    code: SIGNUPCREATORCODE,
    programs: ['default'],
    ctaText: "Connect people with your AI Coach.",
    currentCategory: "people",
    currentLeadershipQuality: "coaching",
    firstSessionDone: true,
    onboardingResponses: ['Professional'],
    onboardingDone: false,
    groups: [SIGNUPCREATORCODE],
    primingFrom: "04:00",
    primingUntil: "15:00",
    reflectionFrom: "15:00",
    reflectionUntil: "04:00",
    homeStatus: "idle_day",
    dialogueCounter: 0,
    offlineSetUsed: true
};

export var MyInstallationOfflineCoachDataSet = {
    emailNotifications: "weekly",
    achievementGoal: "Using digital coaching with my team.",
    roles: ['REMOTECOACH'],
    code: "#ROCKYFORTEAMS",
    programs: ['default'],
    ctaText: "Connect my team with ROCKY for TEAMS.",
    currentCategory: "people",
    currentLeadershipQuality: "coaching",
    firstSessionDone: true,
    onboardingDone: false,
    onboardingResponses: ['Professional'],
    groups: ["#ROCKYFORTEAMS"],
    primingFrom: "04:00",
    primingUntil: "15:00",
    reflectionFrom: "15:00",
    reflectionUntil: "04:00",
    homeStatus: "idle_day",
    dialogueCounter: 0,
    offlineSetUsed: true
};

export function AddCodeToExistingAccountSet (userDataSet, codeSet, addDefaultSet = false){
    var updatedSet = Object.assign({}, userDataSet);
    //console.log('codeSet', codeSet)
    var newSet = {};
    if(codeSet?.addingCode !== undefined){
        newSet = codeSet.addingCode;
    }
    else if(Object.keys(codeSet)?.length > 0 && addDefaultSet !== true){
        newSet = codeSet;
    }
    ////// ADD the program Items
    if(addDefaultSet){
        Object.keys(codeSet).map((keyName) => {
            //ExtraLog(keyName)
            if(keyName.includes('program')){
                newSet[keyName]= codeSet[keyName];
            }
            return true
        })
    }
    if(Object.keys(newSet)?.length > 0){
        //console.log('AddCodeToExistingAccountSet', userDataSet, newSet)
        //console.log('new set',  newSet)
        for (var key in newSet) {
            if (newSet.hasOwnProperty(key)) {
                if(Array.isArray(newSet[key])){
                    if(newSet[key].includes("$$EXTEND") && key in updatedSet){
                        //console.log('my context', userDataSet[key])
                        var index = newSet[key].indexOf("$$EXTEND");
                        if (index > -1) {
                            //console.log('my updaete', key)
                            newSet[key].splice(index, 1);
                        }
                        newSet[key] = [...new Set([...updatedSet[key], ...newSet[key]] )]
                    }
                    else{
                        var index = newSet[key].indexOf("$$EXTEND");
                        if (index > -1) {
                            //console.log('my updaete', key)
                            newSet[key].splice(index, 1);
                        }
                        newSet[key] = [...new Set([...newSet[key]] )]
                    }
                }
                updatedSet[key] = newSet[key];
            }
        }
        if(codeSet.code?.length > 0 && !(updatedSet?.code?.length > 0)){
            updatedSet['code'] = codeSet.code;
            //console.log('updatedSet', codeSet)
            if(newSet?.programCode?.length > 2){
                updatedSet['programCode'] = newSet.programCode;
            }
            else if(codeSet.programTitle?.length > 0){
                updatedSet['programCode'] = codeSet.code;
            }
        }
        //console.log('updatedSet', updatedSet)
    }
    return updatedSet;
}

//// ONLY for Digital Twin or White Label Default Codes
export async function LoadAndSaveSignupDefaultCode(that, defaultSet, programCode, storageType='localStorage'){
    if(that?.props?.firebase !== undefined && programCode?.includes("#")){
        //console.log('LoadAndSaveSignupDefaultCode', programCode, 'defaultSet', defaultSet, storageType)
        try{
            return that.props.firebase.signupCodeByName(programCode)
            .get().then(async (codeSet) => {
                //console.log('codeset loaded', codeSet)
                if(codeSet.exists){
                    var newSet = codeSet.data();
                    defaultSet = AddCodeToExistingAccountSet(defaultSet, newSet, true);
                    //defaultSet = newSet;
                    //console.log('default exists', defaultSet)
                    if(defaultSet?.programCode?.length > 2 || storageType==='noStorage'){
                        if(defaultSet.programCode !== programCode){
                            console.log('await check ', defaultSet.programCode);
                            var altCodeSet = await that.props.firebase.signupCodeByName(defaultSet.programCode).get()
                            if(altCodeSet.exists){
                                newSet = altCodeSet.data();
                                defaultSet = AddCodeToExistingAccountSet(defaultSet, newSet, true);

                                console.log('await checked: ', defaultSet);
                            }
                        }
                        try{
                            var storagedefaultSet = {};
                            Object.assign(storagedefaultSet, defaultSet);
                            storagedefaultSet['addingCode'] = {};
                            storagedefaultSet['newUser'] = {};
                            storagedefaultSet['owner'] = [];
                            Object.keys(storagedefaultSet).map((keyName) => {
                                //ExtraLog(keyName)
                                if(!keyName.includes('program') || keyName === "programs"){
                                    delete storagedefaultSet[keyName]
                                }
                                return true
                            })
                            storagedefaultSet['programCode'] = programCode;

                            var setpreLoaded = true;
                            var tempdefaultSet = undefined;
                            if(storageType==='noStorage'){
                                console.log('no storage')
                            }
                            else if(storageType==='sessionStorage'){
                                tempdefaultSet = JSON.parse(window.sessionStorage.getItem('defaultSet'));
                            }
                            else{
                                tempdefaultSet = JSON.parse(window.localStorage.getItem('defaultSet'));
                            }
                            //console.log('online loaded', tempdefaultSet)
                            if(storagedefaultSet?.programCode?.length > 2 && storagedefaultSet?.programCode !== tempdefaultSet?.programCode) {
                                setpreLoaded = false;
                                //console.log('codeset false loaded', storagedefaultSet)
                            }
                            //console.log('codeset loaded', storagedefaultSet)
                            if(storageType==='noStorage'){
                                console.log('no storage')
                            }
                            else if(storageType==='sessionStorage'){
                                window?.sessionStorage?.setItem('defaultSet', JSON.stringify(storagedefaultSet));
                            }
                            else{
                                window?.localStorage?.setItem('defaultSet', JSON.stringify(storagedefaultSet));
                            }
                            if(setpreLoaded === false && storageType!=='noStorage'){
                                //console.log('reload the page')
                                if(that?.refreshDefaultSet !== undefined){
                                    that.refreshDefaultSet(storagedefaultSet, true);
                                }
                                else if(that?.props?.refreshDefaultSet !== undefined){
                                    that.props.refreshDefaultSet(storagedefaultSet, true);
                                }
                                //window.location?.reload();
                            }
                            if(storagedefaultSet?.programTitle?.length > 2){
                                document.title = storagedefaultSet.programTitle + ' - ' + (storagedefaultSet.programSubTitle || "AI Coaching");
                            }
                            if(storagedefaultSet?.programLogo?.length > 5 ){
                                var fi = document.getElementById("favicon");
                                //console.log('f1', fi)
                                if(fi){
                                    fi.setAttribute("href", storagedefaultSet.programLogo);
                                }
                            }
                            try{
                                that.setState({defaultSet: storagedefaultSet, loadedLatestProgram: programCode});
                                //console.log('defaultset setstate', programCode, defaultSet, storagedefaultSet)
                                return storagedefaultSet
                            }
                            catch (error){
                                console.log('error setstate', error)
                                return defaultSet
                            }
                        }
                        catch (error){
                            console.log('default loaded', error)
                            return defaultSet
                        }
                    }
                    else {
                        if(storageType==='noStorage'){
                            console.log('no storage')
                        }
                        else if(storageType==='sessionStorage'){
                            window.sessionStorage.setItem('defaultSet', false);
                        }
                        else{
                            window.localStorage?.setItem('defaultSet', false);
                        }
                        console.log('DefaultCode no Title', programCode);
                        that.setState({program: 'default'})
                        return defaultSet
                    }
                }
                else{
                    if(storageType==='noStorage'){
                        console.log('no storage')
                    }
                    else if(storageType==='sessionStorage'){
                        window.sessionStorage.setItem('defaultSet', false);
                    }
                    else{
                        window.localStorage?.setItem('defaultSet', false);
                    }
                    console.log('DefaultCode: No Set for programCode', programCode);
                    that.setState({program: 'default'})
                    return defaultSet
                }
            })
            .catch((error) => {
                // Handle errors in the query
                console.error("Error fetching signup code:", error);
            });
            
        } catch  (e){
            console.log('DefaultCode: e1', e);
            return defaultSet
        }
    }
    else{
       // console.log('DefaultCode: e1lwe');
        return defaultSet
    }
}

export async function GetUltimimUserSetup(myFirebase, baseDataSet, defaultDataSet = {}, defaultSignUpCode = "RESET", coachcode = ""){
    var defaultSignUpCodes = ["RESET"]
    if(defaultSignUpCode?.includes("#")){
        defaultSignUpCodes.push(defaultSignUpCode);
    }
    if(coachcode?.includes("#")){
        defaultSignUpCodes.push(coachcode);
    }
    //console.log("GetUltimimUserSetup", defaultSignUpCodes )
    try{
        if(defaultDataSet?.programCode?.includes("#")){
            baseDataSet = AddCodeToExistingAccountSet(baseDataSet, {programCode: defaultDataSet.programCode});
        }
        await myFirebase?.signupCodes().where('code', 'in', defaultSignUpCodes).get()
        .then(snapshot => {
            var codeSetsItems = {};
            snapshot.forEach(doc => {
                if(doc.id === "RESET"){
                    codeSetsItems[doc.id] = doc.data().newUser; 
                }
                else{
                    codeSetsItems[doc.id] = doc.data();
                }
            })
            //console.log('codeSetsItems', codeSetsItems)
            defaultSignUpCodes.forEach(item => {
                if(codeSetsItems?.[item] !== undefined){
                    baseDataSet = AddCodeToExistingAccountSet(baseDataSet, codeSetsItems[item]);
                    //console.log('GetUltimimUserSetup docs', item, baseDataSet)
                }
                if(baseDataSet?.["offlineSetUsed"] === true){
                    delete baseDataSet["offlineSetUsed"];
                    //console.log('GetUltimimUserSetup removed offlineSetUsed')
                }
            })
            ///// ADD Min Default Role
            if(!(baseDataSet?.roles?.length > 0)){
                baseDataSet['roles'] = ['default'];
            }
            ////////// REMOVE THE Default Audio for non English Speaker Natives
            const timezone = GetTimeZone();
            //console.log('TZ', timezone)
            //console.log('ZonesEnglish', ZonesEnglish.includes(timezone))
            if(!(ZonesEnglish?.includes(timezone))){
                //console.log('not english')
                baseDataSet['muteAudio'] = true;
            }
        })
    }
    catch(err) {
        console.log('Error getting documents', err)
    }
    try{
        ///// ADD Min Default Role
        if(!(baseDataSet?.roles?.length > 0)){
            baseDataSet['roles'] = ['default'];
        }
        ////////// REMOVE THE Default Audio for non English Speaker Natives
        const timezone = GetTimeZone();
        //console.log('TZ', timezone)
        //console.log('ZonesEnglish', ZonesEnglish.includes(timezone))
        if(!(ZonesEnglish?.includes(timezone))){
            //console.log('not english')
            baseDataSet['muteAudio'] = true;
        }
    }
    catch(err){
        //console.log('not english', err)
    }
    return baseDataSet;
}


export async function GetDefaultUserSetup(myFirebase, baseDataSet=MyInstallationOfflineDataSet, defaultSignUpCode = "RESET", addingCodeMode=false) {
    try{
        var codeSet = await myFirebase.signupCodeByName(defaultSignUpCode).get()
        if(codeSet.exists){
            var defaultAccount = {}
            if(addingCodeMode === true){
                defaultAccount = codeSet.data().addingCode;
            }
            else{
                defaultAccount = codeSet.data().newUser;
            }
            if(defaultAccount !== undefined){
                //console.log('default exists', defaultAccount)
                baseDataSet = AddCodeToExistingAccountSet(baseDataSet, defaultAccount);
                //console.log('default loaded', defaultDataSet)
            }
            else {
                console.log('SignUp No default data set');
            }
        }; //end then exists
    }
    catch(err) {
        console.log('Error getting documents', err)
    }
    try{
        const timezone = GetTimeZone();
        //console.log('TZ', timezone)
        //console.log('ZonesEnglish', ZonesEnglish.includes(timezone))
        if(!(ZonesEnglish?.includes(timezone))){
            //console.log('not english')
            baseDataSet['muteAudio'] = true;
        }
    }
    catch(err){
        //console.log('not english', err)
    }
    return baseDataSet;
}