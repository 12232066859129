import React, { Component } from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { AuthUserContext } from '../Session';
import withStyles from '@material-ui/core/styles/withStyles';
import { withFirebase } from '../Firebase';
import {getDocs, onSnapshot, collection, query, where, or } from "firebase/firestore";
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';

import BadgeCard from '../Modules/BadgeCard';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import DialogSettingPlan from '../Profile/DialogSettingPlan';

import Chip from '@material-ui/core/Chip';
//import _, { includes } from "lodash";
import _ from "lodash";
// General Modules
import LoadingProgress from '../Modules/LoadingProgress';
import { IsMobileDevice } from '../Modules/IsMobileDevice';
import {ConvertTimestampToDateString} from '../Modules/ConvertTimestampToDateString';

//Modules for user admin
import CoachGroupUsersAdmin from '../Coach/CoachGroupUsersAdmin';
import DialogCoachGroupSetting from '../Coach/DialogCoachGroupSetting';
import CoachUserActions from '../Coach/CoachUserActions';
import CoachUserSetting from '../Coach/CoachUserSetting';

import SnackbarInfo from '../Modules/SnackbarInfo';
import SettingContentSelect from '../Admin/Modules/SettingContentSelect';
/// Radio
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
//popup
import {RemoveScroll} from 'react-remove-scroll';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
//import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

//Panels
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionActions';
import AccordionSummary from '@material-ui/core/AccordionSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// Icons
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Warning';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import AvTimerIcon from '@material-ui/icons/AvTimer';
import SchoolIcon from '@material-ui/icons/School';
import PeopleIcon from '@material-ui/icons/People';
import HotelIcon from '@material-ui/icons/Hotel';
import RowingIcon from '@material-ui/icons/Rowing';
import AirplanemodeInactiveIcon from '@material-ui/icons/AirplanemodeInactive';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import PostAddIcon from '@material-ui/icons/PostAdd';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import UpdateIcon from '@material-ui/icons/Update';

import Slide from '@material-ui/core/Slide';
import { HandleCoachTabs } from './HomeCoach';
import { ExternalHelpLink } from '../Modules/ExternalLinks';
import { AccessCheckCoachCreator } from '../../constants/roles';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});


const styles = theme => ({
    grow: {
        flexGrow: 1, 
    },
})

export async function LoadCoachingGroups(that, user){
    if(that){
        console.log('LCG loading groups')
        if(that.unsubscribeGroups !== undefined){
            that.unsubscribeGroups()
        }
        var dbquery = null;
        var adminGroups = [];
        if(
            (that.context.roles?.includes("ADMIN") || that.context.roles?.includes("COACHADMIN")) 
            && 
            (that.context.adminPrograms?.includes(that.props.defaultSet?.programCode) || that.context.adminPrograms?.includes(that.context.programCode))
          ){
            var programCode = "dfdfdf";
            if(that.context.adminPrograms?.includes(that.context.programCode)){
                programCode = that.context.programCode;
            }
            else if(that.context.adminPrograms?.includes(that.props.defaultSet?.programCode)){
                programCode = that.props.defaultSet.programCode;
            }
            const groupsQuery = query(collection(that.props.firebase.db, "groups"), 
                or(
                    where('owner', 'array-contains', that.context.uid),
                    where('programCode', '==', programCode)
                )
            )
            if(true){
                that.unsubscribeGroups = onSnapshot(groupsQuery, (snapshot) => {
                    console.log('LCG ss loading groups')
                    LoadCoachGroupSnapshot(that, snapshot);
                })
            }
            else{
                const q2snaphotsGroups = await getDocs(groupsQuery)
                console.log('LCG ss loading groups')
                LoadCoachGroupSnapshot(that, q2snaphotsGroups);
            }
            /***
            q2snaphotsGroups.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                console.log('second', doc.id, " => ", doc.data().programCode);
            });
            */
        }
        else{
            if(that.context.roles?.includes("ADMIN") && that.context.adminPrograms !== undefined && that.context.adminPrograms.length > 0){
                adminGroups =  that.context.adminPrograms;
                if(adminGroups.length > 10) {adminGroups = adminGroups.slice(0,9);}
                dbquery = that.props.firebase
                .groups()
                //.where('programs', 'array-contains-any',adminGroups)
                .where('owner', 'array-contains', that.context.uid)
                .where('active', '==', true)
            }
            else if(that.context.roles?.includes("COACHADMIN") && that.context.adminPrograms !== undefined && that.context.adminPrograms.length > 0){
                adminGroups =  that.context.adminPrograms;
                adminGroups = adminGroups.filter(e => e !== "default")
                if(adminGroups.length > 10) {adminGroups = adminGroups.slice(0,9);}
                dbquery = that.props.firebase
                .groups()
                //.where('programs', 'array-contains-any', adminGroups)
                .where('owner', 'array-contains', that.context.uid)
                .where('active', '==', true)
            }
            else{
                dbquery = that.props.firebase
                .groups()
                .where('owner', 'array-contains', that.context.uid)
                .where('active', '==', true)
            }
            that.unsubscribeGroups = dbquery
            .onSnapshot(snapshot => {
            //.get().then(snapshot => {
                LoadCoachGroupSnapshot(that, snapshot);
            });
            //console.log('LCG loaded')
        }
    }
    else{
        console.log('LCG No that')
    }
}

export function LoadCoachGroupSnapshot(that, snapshot){
    if(that && snapshot){
        if(that._isMounted ){
            let coachGroups = {};
            let coachGroupsIds = {};
            var ownedGroupsCodes = [];
            var ownedGroupsCodesNames = [];
            var ownedCompetitionIds = [];
            let coachGroupsOwners = {};
            snapshot.forEach(doc => {
                if(that._isMounted){
                    // *** HN adding filtering to users only allowed to be seen by coach who has same program
                    // any kind of program would work, but default program is filtered
                    var group = doc.data();
                    var parentGroup = "Unsorted";
                    if(group.code!=="$$EXTEND"){
                        if(group.parentGroup!==undefined && group.parentGroup!==""){
                            parentGroup = group.parentGroup;
                        }
                        if(!Object.keys(coachGroups).includes(parentGroup)){
                            coachGroups[parentGroup] = [];
                            coachGroupsIds[parentGroup] = [];
                        }
                        if(group.code!==undefined && group.code!==""){
                            coachGroupsOwners[doc.id] = group.owner;
                            coachGroups[parentGroup].push({ ...group, gid: doc.id });
                            ownedGroupsCodesNames.push(group.code +" - "+group.title);
                            coachGroupsIds[parentGroup].push(doc.id);
                            ownedGroupsCodes.push(doc.id);
                        }
                        if(group.groupCompetitionId!==undefined && group.groupCompetitionId!=="" && group.groupCompetitionId!=="default"){
                            if(!ownedCompetitionIds.includes(group.groupCompetitionId)){
                                ownedCompetitionIds.push(group.groupCompetitionId);
                            }
                        }
                    }
                }
            });

            //console.log(coachGroups)
            if(that._isMounted ){
                that.setState({
                    coachGroups,
                    coachGroupsIds,
                    coachGroupsOwners,
                    ownedGroupsCodes,
                    ownedGroupsCodesNames,
                    ownedCompetitionIds,
                    loading: false,
                });
                if(that.loadCoachees !== undefined && !(that.showClientDetailsDict?.uid?.length > 2)){
                    if(that.props?.variant !== 'groupsOnly' || that.context?.roles?.includes("COACHADMIN")){
                        //console.log(' variant', that.props?.variant, coachGroups, coachGroupsIds )
                        if(Object.keys(coachGroups).length === 1){
                            that.loadCoachees(coachGroupsIds, Object.keys(coachGroups)[0]);
                        }
                    }
                }
                if(that.loadInvitations !== undefined){
                    that.loadInvitations();
                }
            }
        }//end if mounted
    }
}

class CoachGroups extends Component {
    static contextType = AuthUserContext;
    _isMounted = false;

    constructor(props) {
        super(props);
        //console.log('probs in JournalWeek ',this.probs);
        this.state = {
            loading: false,
            loadingText: 'loading users',
            coachGroups: {},
            parentGroup: undefined
        };
    }

    componentDidMount() {
        this._isMounted = true;
        this.setState({ loading: true });
        LoadCoachingGroups(this, this.context);
    }

    loadCoachees = (coachGroupsIds, parentGroup='No Parent Group Available', coachesOnly=false) => {
        //console.log('lodcoaches', coachGroupsIds, parentGroup)
        if(this.unsubscribeCoachees!== undefined ){ this.unsubscribeCoachees()}
        var groupsCodesQuery = [];
        if(coachGroupsIds!== undefined && parentGroup!==undefined && parentGroup!=='' && coachGroupsIds[parentGroup]!== undefined){
            groupsCodesQuery = coachGroupsIds[parentGroup];
        }
        if(groupsCodesQuery!== undefined && groupsCodesQuery!==null && groupsCodesQuery.length>10){
            groupsCodesQuery = groupsCodesQuery.slice(0,9);
        }
        if(this._isMounted ){
            this.setState({
                loadingCoachees: true,
                parentGroup: parentGroup
            });
        }
        if(groupsCodesQuery.length>0){
            var searchGroups = groupsCodesQuery || [];
            //console.log('CG searchGroups', searchGroups)
            this.unsubscribeCoachees = this.props.firebase
            .users()
            .where('groups', 'array-contains-any', searchGroups)
            .onSnapshot(snapshot => {
                let coachees = [];
                let consultants = [];
                let listeners = [];
                let consultantsList = {};
                if(this._isMounted ){
                    snapshot.forEach(doc => {
                        if(doc.id!==this.context.uid){
                            var userSet = doc.data();   
                            if(userSet.email !== "DELETED ACCOUNT"){                         
                                if(userSet.roles!==undefined && ( userSet.roles?.includes('REMOTECOACH') || userSet.roles?.includes('COACHADMIN') )){
                                    consultantsList[doc.id]  = {username: userSet.username, email: userSet.email, seatsToGive: (userSet.seatsToGive || 0)} ;
                                    consultants.unshift({ ...userSet, uid: doc.id });
                                }
                                else if(!coachesOnly && userSet.roles!==undefined && userSet.roles.length===1 && userSet.roles?.includes('default') ){
                                    listeners.unshift({ ...userSet, uid: doc.id });
                                }
                                else if(!coachesOnly){
                                    coachees.push({ ...userSet, uid: doc.id });
                                }
                            }
                        }
                    });
                }
                //console.log('conulstat', consultantsList, consultants)
                if(this._isMounted ){
                    let newCoachees = _.sortBy(coachees, [user => user.username.toLowerCase()]);
                    let newConsultants = _.sortBy(consultants, [user => user.username.toLowerCase()]);
                    let newListeners = _.sortBy(listeners, [user => user.username.toLowerCase()]);
                    this.setState({
                        newCoachees,
                        newConsultants,
                        newListeners,
                        consultantsList,
                        loadingCoachees: false,
                    });
                }
            });
        }
        else{
            this.setState({
                newCoachees: [],
                newConsultants: [],
                newListeners: [],
                loadingCoachees: false,
            });
        }
    }
    loadInvitations = () => {
        this.unsubscribeInvitations = this.props.firebase
        .invitedUsers()
        .where('creatorId', '==', this.context.uid)
        .onSnapshot(snapshot => {
            let coachInvitations = [];
            if(this._isMounted ){
                snapshot.forEach(doc => {
                    if(doc.id!==this.context.uid){
                        coachInvitations.push({ ...doc.data(), id: doc.id });
                    }
                });
                this.setState({
                    coachInvitations
                });
                //console.log("invitaitons", coachInvitations)
            }
        });
    }

    componentDidUpdate(prevProps) {
        //console.log('coachgrop', this.props)
        if(this.props.openNewGroup && this.state.openGroupSettings !== true && this.props.handleClose !== undefined){
            this.handleOpenGroupSettings(null, null, true)
        }
        else if(this.props.openNewUser && this.state.openUserAdmin !== true && this.props.handleClose !== undefined){
            this.handleOpenUserAdmin(null, null, true)
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
        if(this.unsubscribeGroups!== undefined ){ this.unsubscribeGroups();}
        if(this.unsubscribeCoachees!== undefined ){ this.unsubscribeCoachees()}
        if(this.unsubscribeInvitations!== undefined ){ this.unsubscribeInvitations()}
        this.setState({ loading: false, coachGroups: null });
    }

    render(){
        const {theme} = this.props;
        const {coachGroupsIds} =  this.state;
        //console.log('state',this.state)
        if(this.state.loading ){
            return(
                <div>
                    <LoadingProgress 
                        defaultSet={this.props.defaultSet}
                        type={'LinearProgress'} loadingText={this.state.loadingText} />
                </div>
            )
        }
        else{
            return(
                <div>
                    {this.props.variant !== "usersOnly"
                        &&
                        <h4>Manage Teams</h4>
                    }
                    {this.props.variant !== "groupsOnly"
                        &&
                        <h4>Manage Users</h4>
                    }
                    <DialogSettingPlan 
                        defaultSet={this.props.defaultSet || undefined}
                        user={this.context}
                        coach={true}
                        handleOpen={this.state.DialogSettingPlanOpen}
                        premiumOnly={true}
                        purchaseSuccessFlag={() => {
                            this.setState({nonSubscriber: false, premiumSubscriber: true})
                        }}
                        purchaseSuccessHandler={() => {
                            this.setState({DialogSettingPlanOpen: false, nonSubscriber: false, premiumSubscriber: true})
                        }}
                        handleClose={() => this.setState({DialogSettingPlanOpen : false})}
                    />
                    {this.props.variant !== "usersOnly"
                    &&
                        ExternalHelpLink(theme, "Manage Teams", "https://help.rocky.ai/coachee-management/add-and-manage-groups-and-teams", 'Help Domain')
                    }
                    {this.props.variant !== "groupsOnly"
                    &&
                        ExternalHelpLink(theme, "Manage Users", "https://help.rocky.ai/coachee-management/add-and-manage-users-and-coachees", 'Help Domain')
                    }
                    {this.props.variant !== "usersOnly" 
                        &&
                        <>
                            {AccessCheckCoachCreator(this.context) && !(this.state.ownedGroupsCodes?.length>0)
                                ?
                                    <div style={{paddingTop: 20}}>
                                        <p style={{color: theme.palette.themeAlertRed}}>No 'Brand-code' assigned yet!</p>
                                        Please add your brand first, then you can add your own Programs and Quests.
                                        <br/>
                                        <br/>
                                        Go to the: &nbsp;
                                            <Link to={ROUTES.COACH_ADMIN} style={{ textDecorationLine : "underline" ,color : theme.palette.secondary.main }}>
                                                'App Settings'
                                            </Link>
                                    </div>
                                :
                                    <Accordion key="groups_details1"  id={`groups-key1`} 
                                        TransitionProps={{ mountOnEnter: true }}
                                        expanded={this.state.isExpanded === undefined ? true : this.state.isExpanded}
                                        onChange={(event, isExpanded) => this.setState({isExpanded})}
                                    >
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="groups-content"
                                            id="groups"
                                            >
                                            <div style={theme.divFullWidth}>
                                                <PostAddIcon style={theme.inlineIconLeft}/>
                                                <strong style={theme.textSupport}>Create New Team</strong>
                                            </div>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Grid container spacing={0} direction='column' style={{alignItems: 'center'}}> 
                                                <Grid item xs={12}>
                                                    <Button onClick={() => this.handleOpenGroupSettings(null, null, true)}>
                                                        Create New Team
                                                    </Button>
                                                    &nbsp;&nbsp;
                                                    <Button onClick={() => HandleCoachTabs(this, undefined, 'Manage Users', 'openNewUser')}
                                                        variant='outlined'
                                                        color='secondary'
                                                    >
                                                        Invite New User
                                                    </Button>
                                                </Grid>
                                                {((this.context?.seatsMonthlyToGive || 0) + (this.context?.seatsToGive || 0) + 0) < 5
                                                    &&
                                                        <Grid item style={{margin: 40, textAlign: 'center', maxWidth: 500}}>
                                                            <BadgeCard
                                                                key={"creator"}
                                                                backgroundColor= {"white"}
                                                                image={undefined} 
                                                                avatarImage = {<ShoppingCartIcon fontSize="large" style={{color: theme.palette.themeAlertOrangee}}/>}
                                                                //title = { badgeItem.target!== undefined ? "Next milestone:  (" + badgeItem.target + ' Points)' : 'Next milestone:' }
                                                                title = {"Add seats to your account"}
                                                                subtitle = {"Purchase seats or add a subscription"}
                                                                strongSubtitle = {false}
                                                                onClick={ () => this.setState({DialogSettingPlanOpen: true})}  
                                                                clickText= {'Purchase'}
                                                            />
                                                        </Grid>
                                                }
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                            }
                            {this.state.ownedGroupsCodes?.length>0
                                &&
                                    <Accordion key="groups_settings"  id={`groups_settings`} TransitionProps={{ mountOnEnter: true }}
                                        expanded={this.state.showGroups === undefined ? true : this.state.showGroups}
                                        onChange={(event, isExpanded) => this.setState({showGroups: isExpanded})}
                                    >
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="groups_settings"
                                            id="groups_settings"
                                            >
                                            <div style={theme.divFullWidth}>
                                                <GroupWorkIcon style={theme.inlineIconLeft}/>
                                                <strong style={theme.textSupport}>Groups & Teams</strong>
                                            </div>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Grid container spacing={0} direction='column' >
                                                <Grid item >
                                                    {
                                                        this.renderGroups()
                                                    }
                                                </Grid>
                                                <Grid item style={{marginTop: 20, alignSelf: 'center'}}>
                                                    <Button onClick={() => this.handleOpenGroupSettings(null, null, true)}>
                                                        Create New Team
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                            }
                        </>
                    }
                    {this.props.variant !== 'groupsOnly'
                        &&
                            this.state.ownedGroupsCodes?.length>0
                            ?
                                <>
                                    <Accordion 
                                        key="clients_add" 
                                        id={`clients-clients_add`} 
                                        TransitionProps={{ mountOnEnter: true }}
                                        //expanded={true}
                                        expanded={this.state.isExpanded === undefined ? true : this.state.isExpanded}
                                        onChange={(event, isExpanded) => this.setState({isExpanded})}
                                    >
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="clients-clients_add"
                                            id="add"
                                            >
                                            <div style={theme.divFullWidth}>
                                                <PersonAddIcon style={theme.inlineIconLeft}/>
                                                <strong style={theme.textSupport}>Invite New Users</strong>
                                            </div>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Grid container spacing={0} direction='column' style={{alignItems: 'center'}}>
                                                <Grid item xs={12}>
                                                    <Button onClick={() => this.handleOpenUserAdmin(null, null, true)}>
                                                        Invite New User
                                                    </Button>
                                                    &nbsp;&nbsp;
                                                    {((this.context?.seatsMonthlyToGive || 0) + (this.context?.seatsToGive || 0) + 0) > 10
                                                        &&
                                                            <Button onClick={() => this.handleOpenBulkUsers()}
                                                                variant='outlined'
                                                                color='secondary'
                                                            >
                                                                Upload New Users List
                                                            </Button>
                                                    }
                                                    &nbsp;&nbsp;
                                                    <Button onClick={() => HandleCoachTabs(this, undefined, 'Groups & Teams', 'openNewGroup')}
                                                        variant='outlined'
                                                        color='secondary'
                                                    >
                                                        Create New Team
                                                    </Button>
                                                </Grid>
                                                {((this.context?.seatsMonthlyToGive || 0) + (this.context?.seatsToGive || 0) + 0) < 5
                                                    &&
                                                        <Grid item style={{margin: 40, textAlign: 'center', maxWidth: 500}}>
                                                            <BadgeCard
                                                                key={"seats"}
                                                                backgroundColor= {"white"}
                                                                image={undefined} 
                                                                avatarImage = {<ShoppingCartIcon fontSize="large" style={{color: theme.palette.themeAlertOrangee}}/>}
                                                                //title = { badgeItem.target!== undefined ? "Next milestone:  (" + badgeItem.target + ' Points)' : 'Next milestone:' }
                                                                title = {"Add seats to your account"}
                                                                subtitle = {"Purchase seats or add a subscription"}
                                                                strongSubtitle = {false}
                                                                onClick={ () => this.setState({DialogSettingPlanOpen: true})}  
                                                                clickText= {'Purchase'}
                                                            />
                                                        </Grid>
                                                }
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion key="clients_view"  id={`clients_view`}   TransitionProps={{ mountOnEnter: true }}
                                        expanded={this.state.showGroups === undefined ? true : this.state.showGroups}
                                        onChange={(event, isExpanded) => this.setState({showGroups: isExpanded})}
                                    >
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="clients_view"
                                            id="clients_view"
                                            >
                                            <div style={theme.divFullWidth}>
                                                <PeopleAltIcon style={theme.inlineIconLeft}/>
                                                <strong style={theme.textSupport}>User Management</strong>
                                            </div>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Grid container spacing={0} direction='column' >
                                                <Grid item>
                                                    {coachGroupsIds !== undefined && coachGroupsIds !== null
                                                    &&
                                                        <div style={{maxWidth: 400}}>
                                                            <SettingContentSelect 
                                                                title={"Select Group"} 
                                                                content={{'parentGroup': this.state.parentGroup}}
                                                                noLabel={true}
                                                                params={['parentGroup']}
                                                                infoText={[]}
                                                                selectOptions={Object.keys(coachGroupsIds || {})}
                                                                updateState={(resultDict) => {
                                                                    if(resultDict?.parentGroup === 'Show All'){
                                                                        this.setState({parentGroup: undefined})
                                                                    }
                                                                    else if(resultDict?.parentGroup?.length > 2){
                                                                        this.setState({parentGroup: resultDict.parentGroup})
                                                                        this.loadCoachees(coachGroupsIds, resultDict.parentGroup)
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    }
                                                    {false && coachGroupsIds !== undefined && coachGroupsIds !== null
                                                    &&
                                                        <div component="fieldset">
                                                            <strong>Select a group:</strong>
                                                            {Object.keys(coachGroupsIds).map( (parent, parentIndex) => (
                                                                <Chip
                                                                    key={"chip_"+parentIndex}
                                                                    style={{
                                                                        marginRight: 8,
                                                                        marginBottom: 8,
                                                                        color: parent === this.state.parentGroup ? theme.palette.white : theme.palette.black, 
                                                                        backgroundColor: parent === this.state.parentGroup ? theme.palette.themeAlertRed : theme.palette.themeGrey 
                                                                    }}
                                                                    label={parent}
                                                                    onClick={() => this.loadCoachees(coachGroupsIds, parent)}
                                                                />
                                                            ))}
                                                        </div>
                                                    }
                                                    {(false && coachGroupsIds !== undefined && coachGroupsIds !== null)
                                                    &&
                                                        <FormControl component="fieldset">
                                                            <RadioGroup row aria-label="position" name="position" 
                                                                onChange={(event) => this.loadCoachees(coachGroupsIds, event.target.value)}
                                                            >
                                                                {Object.keys(coachGroupsIds).map( (parent, parentIndex) => (
                                                                    <FormControlLabel
                                                                        key={"radio_"+parentIndex}
                                                                        value={parent}
                                                                        checked={this.state.parentGroup === parent}
                                                                        control={<Radio color="secondary" />}
                                                                        label={parent}
                                                                        labelPlacement="top"
                                                                        />
                                                                ))}
                                                            </RadioGroup>
                                                        </FormControl>
                                                    }
                                                </Grid>
                                                {(this.state.parentGroup === undefined || this.state.parentGroup === '')
                                                    ?   <div>Please choose a group!</div>  
                                                    :   this.state.loadingCoachees
                                                        ?
                                                            <div>Loading users!</div>
                                                        :
                                                            <>
                                                                <Grid item >
                                                                    {this.state.newConsultants!==undefined && this.state.newConsultants!==null && this.state.newConsultants.length>0
                                                                        &&
                                                                        this.renderClients(this.state.newConsultants, 'Coaches')
                                                                    }
                                                                </Grid>
                                                                <Grid item >
                                                                    {this.state.newCoachees!==undefined && this.state.newCoachees!==null && this.state.newCoachees.length>0
                                                                        ?
                                                                            this.renderClients(this.state.newCoachees, 'Users')
                                                                        :
                                                                            <div>
                                                                                <span style={{color: 'red'}}><br/>No users assigned to {this.state.parentGroup!== undefined ? this.state.parentGroup: 'this group'}!</span>
                                                                                <br/><br/>
                                                                                <Button onClick={() => this.handleOpenUserAdmin(null, null, true)}>
                                                                                    Invite New User
                                                                                </Button>
                                                                            </div>
                                                                    }
                                                                </Grid>
                                                                <Grid item >
                                                                    {this.state.newListeners!==undefined && this.state.newListeners!==null && this.state.newListeners.length>0
                                                                        &&
                                                                        this.renderClients(this.state.newListeners, 'Anonymous')
                                                                    }
                                                                </Grid>
                                                            </>
                                                }
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion key="clients_invite"  id={`clients-clients_invite`}   TransitionProps={{ mountOnEnter: true }}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="clients-clients_invite"
                                            id="invites"
                                            >
                                            <div style={theme.divFullWidth}>
                                                <CheckCircleIcon style={theme.inlineIconLeft}/>
                                                <strong style={theme.textSupport}>User Invitation Status</strong>
                                            </div>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Grid container spacing={0} direction='column' >
                                                <Grid item >
                                                    {
                                                        this.renderInvitations()
                                                    }
                                                </Grid>
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                </>
                            :
                                <p style={{paddingTop: 10}}>Please create your first Team!</p>
                    }
                    {
                        this.dialogueGroupSettings()
                    }
                    {
                        this.dialogueNotificationSettings()
                    }
                    {
                        this.dialogueUserAdmin()
                    }
                    {
                        this.dialogueCSVImport()
                    }
                </div> 
            )
        }
       
    }

    renderGroups = () => {
        const {theme} = this.props;
        const {coachGroups} = this.state;
        return(
            <Grid container spacing={0} direction='row' justifyContent="space-between" >
                <Grid item xs={6}>
                    <h4><strong style={theme.textSupport}>Groups</strong> / Teams</h4>
                </Grid>
                <Grid item xs={4}>
                    <h4>Coach-Code</h4>
                </Grid>
                <Grid item xs={2}>
                    <h4>Edit</h4>
                </Grid>
                {
                    coachGroups!==undefined && coachGroups!==null && Object.keys(coachGroups).length>0
                    ?
                        Object.keys(coachGroups).map( (parent, parentIndex) => (
                            <Grid item xs={12} key={"parent_"+parentIndex}
                                style={{
                                    backgroundColor: 
                                        (parentIndex%2)===0
                                        ?   theme.palette.backPaper
                                        :   theme.palette.themeDarkBack
                                }} 
                            >
                                <hr style={{marginTop: 0}}/>
                                <p>&nbsp;{parentIndex}. <strong style={theme.textSupport}>{parent}</strong> &nbsp;({coachGroups[parent].length} Teams)</p>
                                {coachGroups[parent].length > 10
                                    &&
                                    <span style={{color:"red"}}>Attention! Max. 10 teams available per group. Add more parent groups!</span>
                                }
                                {(coachGroups[parent] === undefined || coachGroups[parent].length === 0)
                                    ?   <span>No teams set up for group: {parent}</span>
                                    :   coachGroups[parent].map((group, index) => (
                                            <Grid container spacing={2} 
                                                direction='row' justifyContent="space-between" alignItems="center"
                                                key={"group"+index} id={"group"+index} >
                                                <Grid item xs={1} style={{textAlign: 'center'}}>
                                                    {group.programLogo!== undefined
                                                        ?
                                                            <img width={30} src={group.programLogo} alt={group.code} />
                                                        :
                                                            <PeopleIcon/>
                                                    }
                                                </Grid>
                                                <Grid item xs={5}>
                                                    {group.title}
                                                </Grid>
                                                <Grid item xs={4}>
                                                    {group.gid}
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <IconButton onClick={() => this.handleOpenGroupSettings(group, group.gid)}> 
                                                        <EditIcon />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        ))
                                }
                                <div>&nbsp;</div>
                            </Grid>
                        ))
                    :
                        <div>No groups available yet!</div>
                }

            </Grid>
        )
    }

    renderClients = (newCoachees, title='') => {
        const {coachGroups} = this.state;
        const {theme} = this.props;
        var today = new Date();
        var last2Weeks = new Date(Date.now() - 12096e5);
        if(coachGroups!==undefined && coachGroups!==null && Object.keys(coachGroups).length>0){
            return(
                <Grid container spacing={0} direction='row' justifyContent="space-between"  alignItems="center">
                    <Grid item xs={3}>
                        <h4 style={theme.textSupport}>{title!=='' ? title : 'Name'}</h4>
                    </Grid>
                    <Grid item xs={4}>
                        <h4>Email</h4>
                    </Grid>
                    <Grid item xs={3}>
                        <h4>Teams</h4>
                    </Grid>
                    <Grid item xs={1}>
                        <h4>XP</h4>
                    </Grid>
                    <Grid item xs={1}>
                        <h4>Edit</h4>
                    </Grid>
                    {
                        newCoachees!==undefined && newCoachees!==null && newCoachees.length>0
                        ?
                            newCoachees.map((user, index) => (
                                <Grid container direction='row' justifyContent="space-between" alignItems="center" 
                                    key={"user"+index} id={"user"+index} 
                                    style={{
                                        padding:8,
                                        backgroundColor: 
                                            //user.purchasedProduct !== undefined && user.purchasedProduct.owned
                                            (index%2)===0
                                            ?   theme.palette.backPaper
                                            :   theme.palette.backPaperSecondary
                                    }} >
                                    <Grid item xs={3}>
                                        {(user.roles?.includes("REMOTECOACH") || user.roles?.includes("COACHADMIN") )  &&
                                            <Tooltip title='Coach/Consultant Account'>
                                                <SchoolIcon style={{color:"blue"}} fontSize={"small"}/>
                                            </Tooltip>
                                        }
                                        {user.purchasedProduct !== undefined && user.purchasedProduct.owned 
                                            ?
                                                <Tooltip title={'Subscription Status until: ' + ConvertTimestampToDateString(user.purchasedProduct.expiryDate, true)}>
                                                    <LocalAtmIcon style={{color: 
                                                        user.purchasedProduct?.expiryDate?.seconds !== undefined && new Date(user.purchasedProduct.expiryDate.seconds * 1000) > today ? "green" : "red"}} 
                                                        fontSize={"small"} />
                                                </Tooltip>
                                            :
                                                <Tooltip title={'No Subscription!'}>
                                                    <AvTimerIcon style={{
                                                        color: "orange"}} 
                                                        fontSize={"small"} />
                                                </Tooltip>
                                            
                                        }
                                        {user.purchasedProduct?.autoAssignFlag &&
                                            <Tooltip title={'Monthly Auto-Subscription active'}>
                                                <UpdateIcon style={{color: "black"}} 
                                                    fontSize={"small"} />
                                            </Tooltip>
                                        }
                                        &nbsp;
                                        {( user.roles?.includes("default") && user.roles.length ===1 )?user.username.substring(0, 9)+"*****" : user.username }
                                    </Grid>
                                    <Grid item xs={4}>
                                        {( user.roles?.includes("default") && user.roles.length ===1 )? "*****" : user.email}
                                    </Grid>
                                    <Grid item xs={3}>
                                        { user.groups[0] }
                                        { user.groups.length > 1
                                            && 
                                                <Tooltip title={user.groups.join(', ')}>
                                                    <span style={theme.textSupport}>&nbsp; + {user.groups.length-1}</span>
                                                </Tooltip>
                                        }
                                    </Grid>
                                    <Grid item xs={1}>
                                        <Tooltip title={'Last Engagement: ' + ConvertTimestampToDateString(user.lastEngagement, true) + ', AI Chats ' + user.dialogueCounter + ', Experience Points (XP): ' + user.engagementCounter }>
                                            <IconButton onClick={() => this.handleOpenUserNotifications(user, user.uid)}> 
                                            {(user.dialogueCounter === undefined || user.dialogueCounter < 1)
                                                ?   <AirplanemodeInactiveIcon style={{color: "red"}} fontSize={"small"}/>
                                                :  ( user.lastEngagement?.seconds !== undefined && new Date(user.lastEngagement.seconds * 1000 ) > last2Weeks )
                                                    ?    <RowingIcon style={{color: "green"}} fontSize={"small"}/>
                                                    :    <HotelIcon style={{color: "orange"}} fontSize={"small"}/>
                                                        
                                            }
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <IconButton onClick={() => this.handleOpenUserAdmin(user, user.uid)}> 
                                            <EditIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            ))
                        :
                            <div>No users available or no group added yet!</div>
                    }

                </Grid>
            )
        }
        else{
            return(
                <div>No group added yet!</div>
            )
        }
    }

    renderInvitations = () => {
        const {coachInvitations, coachGroups} = this.state;
        const {theme} = this.props;
        if(coachGroups!==undefined && coachGroups!==null && Object.keys(coachGroups).length>0){
            return(
                <Grid container spacing={0} direction='row' justifyContent="space-between" >
                    <SnackbarInfo 
                        alertText = {this.state.snackInfoText}
                        openOnClick = {true}
                        alertOpenEvent = {this.state.openAlert || false}
                        closeHandler = {() => {this.setState({openAlert: false})}}
                    />
                    <Grid item xs={6}>
                        <h4>Name</h4>
                    </Grid>
                    <Grid item xs={3}>
                        <h4>Date</h4>
                    </Grid>
                    <Grid item xs={3}>
                        <h4>Info</h4>
                    </Grid>
                    {coachInvitations!==undefined && coachInvitations!==null && coachInvitations.length>0
                        ?
                            coachInvitations.map((invitation, index) => (
                                <Grid container spacing={0} direction='row' justifyContent="space-between" key={"invitation"+index} id={"invitation"+index} >
                                    <Grid item xs={12}>
                                        <span>&nbsp;</span>
                                    </Grid>
                                    <Grid item xs={6}>
                                        {invitation.username} &nbsp; ({invitation.email})
                                    </Grid>
                                    <Grid item xs={3}>
                                        {invitation.scheduledAt!==undefined ? ConvertTimestampToDateString(invitation.scheduledAt, true) : '-'}
                                    </Grid>
                                    <Grid item xs={3}>
                                        <>
                                        {invitation.emailInvitation
                                            ?   <CheckCircleIcon onClick={() => this.setState({openAlert: true, snackInfoText: 'Info: ' + (invitation.comment || 'n/a') })}/>
                                            :   <WarningIcon onClick={() => this.setState({openAlert: true, snackInfoText: 'Info: ' + (invitation.comment || "Error with new account" )})}/>
                                        }
                                        </>
                                        {invitation.emailInvitation && invitation.password &&
                                            <>
                                                <VpnKeyIcon onClick={() => this.setState({openAlert: true, snackInfoText: 'Initial Password was: ' + invitation.password})}/>
                                            </>
                                        }
                                    </Grid>
                                    <Grid item xs={12}>
                                        <span style={theme.textSupportCenterPadding} >{invitation.comment}</span>
                                        {invitation.emailInvitation
                                            ?   ""
                                            :   "Failure when creating the account!"
                                        }
                                    </Grid>
                                </Grid>
                            ))
                        :
                            <div>No invitations created!</div>
                    }
                    {coachInvitations!==undefined && coachInvitations!==null && coachInvitations.length>0
                        &&
                            <p style={{...theme.textErrorCenterPadding, marginTop:50}} >Please be aware that an invitation may not arrive or end up in a spam folder!</p>
                    }
                </Grid>
            )
        }
        else{
            return(
                <div>No group added yet!</div>
            )
        }
    }

    handleClose = () => {
        if(this.props.handleClose !== undefined){
            this.props.handleClose()
        }
        this.setState({
            uploadError: '',
            loadedFile: false,
            openCSVImport: false, 
            openDialogueChats: false, 
            openGroupSettings: false, 
            openUserAdmin: false,
            openNotificationSettings: false,
            newUser:false, 
            newGroup:false,
            uploadedUserProfiles: [],
            uploadedGroupNames: [],
        } )
    }
    handleOpenGroupSettings = (group, gid, newGroup=false) => {
        if(AccessCheckCoachCreator(this.context) && !(this.state.ownedGroupsCodes?.length>0)){

        }
        else{
            //console.log('open group', this.state.coachGroupsIds,group, "Unsorted")
            this.loadCoachees(this.state.coachGroupsIds, (group?.parentGroup || undefined), true);
            this.setState({selectedId : gid, newGroup, newUser:false, selectedGroup: group,  openGroupSettings: true, openUserAdmin: false} )
        }
    }
    handleOpenUserNotifications = (user, uid, newUser=false) => {
        this.setState({selectedId : uid,  newGroup:false, newUser, selectedUser: user, openNotificationSettings: true} )
    }
    handleOpenUserAdmin = (user, uid, newUser=false) => {
        if(this.state.ownedGroupsCodes?.length>0){
            this.setState({selectedId : uid,  newGroup:false, newUser, selectedUser: user, openGroupSettings: false, openUserAdmin: true} )
        }
    }
    handleOpenBulkUsers = () => {
        this.setState({ openCSVImport: true, openGroupSettings: false, openUserAdmin: false} )
    }

    dialogueGroupSettings = () => {
        const {selectedId, selectedGroup} = this.state;
        return(
            <DialogCoachGroupSetting 
                open={this.state.openGroupSettings || false}
                defaultSet={this.props.defaultSet || undefined}
                handleClose={this.handleClose}
                handleReload={() => LoadCoachingGroups(this, this.context)}
                groupId={selectedId}
                group={selectedGroup}
                newGroup={this.state.newGroup}
                consultantsList={this.state.consultantsList}
                leadershipCategories={this.props.leadershipCategories}
                leadershipQualities={this.props.leadershipQualities}
                ownedCompetitionIds={this.state.ownedCompetitionIds}
                coachGroupsNames={Object.keys(this.state.coachGroupsIds || {}) || undefined}
                refreshDefaultSet={this.props.refreshDefaultSet || undefined}
            />
        )
    }
    dialogueNotificationSettings = () => {
        const {theme} = this.props;
        const {selectedId, selectedUser} = this.state;
        //console.log(this.state)
        return(
            <RemoveScroll enabled={this.state.openNotificationSettings|| false}>
                <Dialog
                    key={this.state.actionId}
                    open={this.state.openNotificationSettings|| false}
                    onClose={this.handleClose}
                    fullScreen={IsMobileDevice()}
                    PaperProps={{style:theme.dialogWindow}}
                    TransitionComponent={Transition}
                    maxWidth="sm"
                >
                    <DialogTitle style={theme.dialogTitle}  >
                        <IconButton onClick={this.handleClose}>
                            <ArrowBackIcon />
                        </IconButton>
                        <span style={theme.dialogTitle.titleText}>
                            Notifications & Coaching Plan
                        </span>
                    </DialogTitle>
                    <DialogContent style={theme.dialogContent}>
                        {this.state.openNotificationSettings && selectedId !== undefined && selectedId !== ""
                            ?   
                                <>
                                    <CoachUserActions 
                                        defaultSet={this.props.defaultSet || undefined}
                                        userId={selectedId} 
                                        user={selectedUser}
                                        leadershipCategories={this.props.leadershipCategories}
                                        leadershipQualities={this.props.leadershipQualities}
                                    />
                                    <CoachUserSetting 
                                        defaultSet={this.props.defaultSet || undefined}
                                        userId={selectedId} 
                                        user={selectedUser}
                                        leadershipCategories={this.props.leadershipCategories}
                                        leadershipQualities={this.props.leadershipQualities}
                                    />
                                </>
                            :   <div>Loading</div> 
                        }
                    </DialogContent>
                </Dialog>
            </RemoveScroll>
        )
    }
    dialogueUserAdmin = () => {
        const {theme} = this.props;
        const {selectedId, selectedUser} = this.state;
        //console.log(this.state)
        return(
            <RemoveScroll enabled={this.state.openUserAdmin || false}>
                <Dialog
                    key={this.state.actionId}
                    open={this.state.openUserAdmin|| false}
                    onClose={this.handleClose}
                    fullScreen={IsMobileDevice()}
                    PaperProps={{style:theme.dialogWindow}}
                    TransitionComponent={Transition}
                    maxWidth="sm"
                >
                    <DialogTitle style={theme.dialogTitle}  >
                        <IconButton onClick={this.handleClose}>
                            <ArrowBackIcon />
                        </IconButton>
                        <span style={theme.dialogTitle.titleText}>
                            User Account Setup
                        </span>
                    </DialogTitle>
                    <DialogContent style={theme.dialogContent}>
                        { selectedId !== undefined && selectedId !== ""
                            ?   <CoachGroupUsersAdmin 
                                    defaultSet={this.props.defaultSet || undefined}
                                    userId={selectedId} 
                                    user={selectedUser}
                                    newUser={this.state.newUser}
                                    handleReload={(clearFields) => {
                                        LoadCoachingGroups(this, this.context);
                                        if(clearFields){
                                            this.setState({showClientDetails: undefined});
                                        }
                                    }}
                                    handleClose={this.handleClose}
                                    coachGroupsOwners={this.state.coachGroupsOwners}
                                    leadershipCategories={this.props.leadershipCategories}
                                    leadershipQualities={this.props.leadershipQualities}
                                    ownedGroupsCodes={this.state.ownedGroupsCodes}
                                    ownedGroupsCodesNames={this.state.ownedGroupsCodesNames}
                                    ownedCompetitionIds={this.state.ownedCompetitionIds}
                                />
                            : <div>Loading</div> 
                        }
                    </DialogContent>
                </Dialog>
            </RemoveScroll>
        )
    }
    dialogueCSVImport = () => {
        const {theme} = this.props;
        const {selectedId} = this.state;
        //console.log(this.state)
        return(
            <RemoveScroll enabled={this.state.openCSVImport || false}>
                <Dialog
                    key={'openCSVImport'}
                    open={this.state.openCSVImport|| false}
                    onClose={this.handleClose}
                    fullScreen={IsMobileDevice()}
                    PaperProps={{style:theme.dialogWindow}}
                    TransitionComponent={Transition}
                    maxWidth="sm"
                >
                    <DialogTitle style={theme.dialogTitle}  >
                        <IconButton onClick={this.handleClose}>
                            <ArrowBackIcon />
                        </IconButton>
                        <span style={theme.dialogTitle.titleText}>
                            Import New User List (CSV)
                        </span>
                    </DialogTitle>
                    <DialogContent style={theme.dialogContent}>
                        { this.state.openCSVImport
                            ? 
                                <div>
                                    <label>Search File </label>
                                    <p>The upload only accepts CSV files (comma or semicolon separated) with 3 columns named: <br/><br/>
                                    <strong>first_name, email, team_code</strong></p>
                                    {this.state.loadedFile
                                        ?
                                            <Button onClick={this.handleClose}>
                                                Restart
                                            </Button>
                                        :
                                            <input type="file" 
                                                id="file"       
                                                multiple={false}
                                                accept=".csv" 
                                                onChange={(event) => { this.readCSVFile(event) }}
                                            />
                                    }
                                    <br/>
                                    <br/>
                                    <p style={theme.textErrorCenter}> <strong>{this.state.uploadError || ''}</strong></p>

                                    <Grid container spacing={2} direction='row' >
                                    {this.state.uploadedUserProfiles !== undefined && this.state.uploadedUserProfiles.map( (newUser, index) => (
                                        <React.Fragment key={"newUser_"+index}>
                                            <Grid item xs={1}>{index}. &nbsp;   </Grid>
                                            <Grid item xs={3}><strong>{newUser['first_name']} </strong></Grid>
                                            <Grid item xs={5}>{newUser['email']}  </Grid>
                                            <Grid item xs={3}>{newUser['team_code']} </Grid>
                                        </React.Fragment>
                                    ))}
                                    </Grid>
                                    <hr/>
                                    { this.state.uploadedUserProfiles !== undefined && this.state.uploadedUserProfiles.length >0 &&
                                        <CoachGroupUsersAdmin 
                                            defaultSet={this.props.defaultSet || undefined}
                                            userId={selectedId} 
                                            handleClose={this.handleClose}
                                            handleReload={(clearFields) => {
                                                LoadCoachingGroups(this, this.context);
                                                if(clearFields){
                                                    this.setState({showClientDetails: undefined});
                                                }
                                            }}
                                            newUserProfiles={this.state.uploadedUserProfiles}
                                            newUserGroups={this.state.uploadedGroupNames || []}
                                            coachGroupsOwners={this.state.coachGroupsOwners}
                                            leadershipCategories={this.props.leadershipCategories}
                                            leadershipQualities={this.props.leadershipQualities}
                                            ownedGroupsCodes={this.state.ownedGroupsCodes}
                                            ownedGroupsCodesNames={this.state.ownedGroupsCodesNames}
                                            ownedCompetitionIds={this.state.ownedCompetitionIds}
                                            />
                                    }
                                </div>
                            :   <LoadingProgress 
                                    defaultSet={this.props.defaultSet}
                                    type={'LoadingPageAnimated'} 
                                    loadingText={"many, many, data"} 
                                    firebaseConnectionSpeed={this.state.firebaseConnectionSpeed} 
                                    allowRefresh={true}
                                />
                        }
                    </DialogContent>
                </Dialog>
            </RemoveScroll>
        )
    }

    readCSVFile = (e) => {
        //console.log('read new', e)
        this.setState({
            loadedFile: true,
            uploadedUserProfiles: [],
            uploadedGroupNames: [],
            uploadError: ('Loading new users from CSV file...')
        });
        if(e !== undefined && e.target!== undefined && e.target.files!== undefined){
            var file = e.target.files[0];
            var reader = new FileReader ();
            //console.log('e', e.target.files[0], e);
            reader.onload = (e) => {
                //console.log('text', reader) ;
                this.processData(reader);
                // The file's text will be printed here
                //console.log('results', e.target.result)
            };
            reader.readAsText(file);
        }
    }
    processData (reader) {
        var allTextLines = reader.result.split(/\r\n|\n/);
        //var headers = allTextLines[0].split(',');
        var headers = allTextLines[0].split(/,|;/);
        var uploadedUserProfiles = [];
        var uploadedGroupNames = [];
        var uploadedEmails = [];
        var checkUpload = false;
    
        for (var i=1; i<allTextLines.length; i++) {
            var data = allTextLines[i].split(/,|;/);
            //var data = allTextLines[i].split(',');
            if (data.length === headers.length) {
                var row = {};
                for (var j=0; j< headers.length; j++) {
                   row[headers[j].trim()] = data[j].trim();
                }
                if(row['first_name'] === undefined || row['first_name'] ===  '' || row['email'] === undefined || row['email'] ===  '' || row['team_code'] === undefined || row['team_code'] === '' ){
                    this.setState({uploadError: ('Error - Missing user information in row: ' + i)});
                    checkUpload = false;
                    //console.log('failure row', row);
                    break;
                }
                var subGroupCode = ''+row['team_code'];
                subGroupCode = subGroupCode.replace(/\W/g, '').toUpperCase();
                if(subGroupCode.charAt(0) !== '#'){
                    subGroupCode = '#'+subGroupCode;
                }
                if(subGroupCode !== undefined && subGroupCode !== ''){
                    if(!uploadedGroupNames.includes(subGroupCode )){
                        if(subGroupCode === '' || this.state.ownedGroupsCodes === undefined || !this.state.ownedGroupsCodes.includes(subGroupCode)){
                            this.setState({uploadError: ('Error - Team Code not available: ' + subGroupCode)});
                            checkUpload = false;
                            break;
                        }
                        uploadedGroupNames.push(subGroupCode);
                    }
                    if(uploadedEmails.includes(row['email'])){
                        this.setState({uploadError: ('Error - Duplicate email: ' + row['email'])});
                        checkUpload = false;
                        break;
                    }
                    uploadedEmails.push(row['email']);
                }
                uploadedUserProfiles.push(row);
                checkUpload = true;
                //console.log('uploadedGroupNames', uploadedGroupNames)
            }
        }
        if(checkUpload){
            this.setState({
                uploadedUserProfiles: uploadedUserProfiles,
                uploadedGroupNames: uploadedGroupNames,
                uploadError: ((i-1) + ' new user accounts found in the list!')
            });
        }
        //console.log('uploadedUserProfiles', uploadedUserProfiles);
    }
    
    


}//end class


export default compose(
    withFirebase,
    withRouter,
    withStyles(styles, { withTheme: true })
)(CoachGroups);
