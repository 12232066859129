import React, { Component} from 'react';
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';
import DOMPurify from 'dompurify';

import { AuthUserContext } from '../Session';
import { withFirebase } from '../Firebase';

import LanguageManager, { ExtractHTMLTextChunks } from '../../constants/languages';


const styles = theme => ({
    grow: {
        flexGrow: 1,
    },
    innerHTML : theme.innerHTML
});

// Configure DOMPurify to allow specific styles
const DOMconfig = {
    ALLOWED_TAGS: ['span'],
    ALLOWED_ATTR: ['style'],
    ADD_STYLE_TAGS: true,
    ALLOWED_STYLE_PROPS: ['color', 'filter', 'opacity']  // specify allowed CSS properties
};

class DangerouslySetInnerHTML extends Component {
    _isMounted = false;
    static contextType = AuthUserContext;
    constructor(props) {
        super(props);
        var textChunks = ExtractHTMLTextChunks(props.textHTML)
        this.state = {
            textChunks
        };
    }
    componentDidMount() {
        this._isMounted = true;
        this.setState({isMounted : true});
    }
    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        var {classes} = this.props;
        var languageUser = this.props.user?.features?.language || this.props.defaultLanguage || undefined;
        if((languageUser === undefined || languageUser === this.props.defaultLanguage || !(this.state.textChunks?.length>0)))
            return(
                <div
                    className={classes.innerHTML}
                    onClick = {(event) => {
                        try{
                            const link = event.target.getAttribute('href');
                            if(link !== undefined && link !== null && link !== ""){
                                event.preventDefault();
                                window.open(link, "_blank")
                            }
                        }
                        catch(e){
                            console.log('No Link', e)
                        }
                    }}
                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(this.props.textHTML) }}
                >
                </div>
            )
        else
            return(
                    <>
                        <div style={{display: 'none'}}>
                            {this.state.textChunks?.map((chunk, index) => {
                                if(chunk.type === 'text'){
                                    return(
                                        <LanguageManager
                                            key={'translate_'+index}
                                            text= {chunk.content}
                                            type='text'
                                            forceEnglish={this.props.defaultLanguage?.length > 0 && !(this.props.defaultLanguage?.includes('en-'))}
                                            language={languageUser || this.props.defaultLanguage}
                                            handlerUpdate={(text) => this.setState({['textChunk_'+languageUser+index] : text}) }
                                        />
                                    )
                                } 
                            })}
                        </div>
                        <div
                            className={classes.innerHTML}
                            onClick = {(event) => {
                                try{
                                    const link = event.target.getAttribute('href');
                                    if(link !== undefined && link !== null && link !== ""){
                                        event.preventDefault();
                                        window.open(link, "_blank")
                                    }
                                }
                                catch(e){
                                    console.log('No Link', e)
                                }
                            }}
                            dangerouslySetInnerHTML={{ __html: 
                                DOMPurify.sanitize(
                                    this.state.textChunks?.map((chunk, index) => {
                                        if(chunk.type === 'tag'){
                                            return(chunk.content)
                                        }
                                        else if(chunk.type === 'other'){
                                            return(chunk.content + "")
                                        }
                                        else if(this.state['textChunk_'+languageUser+index]){
                                            return(
                                                (this.state['textChunk_'+languageUser+index] + " ")
                                            )
                                        }
                                        else if (true){
                                            return(
                                                "<span style='filter: blur(3px); opacity: 0.7'>"
                                                    + chunk.content +
                                                " </span>"
                                            )
                                        }
                                        else return "XXX"
                                    }, DOMconfig).join('')// Join the chunks into a single string without commas
                                )
                            }}
                        >
                        </div>
                    </>
            )   
    }
} // end class

export default compose(
    withFirebase,
    withStyles(styles, { withTheme: true })
)(DangerouslySetInnerHTML);

  