import React, { Component } from 'react';
import { compose } from 'recompose';
import LanguageManager, { LanguageSets } from '../../constants/languages';
import { SelectOption, SelectWrapper } from '../Modules/SelectOption';
import LanguageIcon from '@material-ui/icons/Language';
import withStyles from '@material-ui/core/styles/withStyles';
import { AuthUserContext } from '../Session';
import { withFirebase } from '../Firebase';

import Card from '@material-ui/core/Card';
import List from '@material-ui/core/List';
import { PollyVoicePool } from '../Routine/ChatBlock';

const styles = theme => ({
    grow: {
        flexGrow: 1,
    },
});

export function RenderSettingLanguageOptions(that, user = undefined, writeCache = false){
    //console.log('RenderSettingLanguageOptions', user)
    return(
        <SelectWrapper
            value={user?.features?.language || 'en-US'}
            onChange={(event) => {
                if(event.target.value?.length > 1){
                    var newLanguage = event.target.value;
                    var updateSet = {
                        ['features.language'] : newLanguage
                    }
                    var cacheUpdate = {
                        features : {language: newLanguage}
                    }
                    try{
                        var voiceIds = (Object.keys(PollyVoicePool[newLanguage] || [])); 
                        console.log('changed voiceId', newLanguage, voiceIds)
                        if(voiceIds?.length > 0){
                            updateSet['features.voiceId'] = voiceIds[0]
                            cacheUpdate['features']['voiceId'] = voiceIds[0]
                        }
                        else{
                        //if(!(event.target.value?.includes('en-'))){
                            updateSet['muteAudio'] = true
                            cacheUpdate['muteAudio'] = true
                            updateSet['features.voiceId'] = that.props.firebase.fieldValue.delete()
                            cacheUpdate['features']['voiceId'] = undefined
                        }
                    }
                    catch(e){
                        updateSet['muteAudio'] = true
                        cacheUpdate['muteAudio'] = true
                        updateSet['features.voiceId'] = that.props.firebase.fieldValue.delete()
                        cacheUpdate['features']['voiceId'] = undefined
                    }
                    if(user?.uid?.length > 3){
                        that.props.firebase.user(user.uid).update(updateSet)
                        try{
                            var storedAuthUser = JSON.parse(window?.localStorage?.getItem('authUser'));
                            var userUpdate = {...storedAuthUser, ...cacheUpdate}
                            if(that._isMounted){
                                that.setState({storedAuthUser: userUpdate})
                            }
                        }
                        catch(e){

                        }
                    }
                    if(writeCache){
                        try{
                            var storedAuthUser = JSON.parse(window?.localStorage?.getItem('authUser'));
                            var userUpdate = {...storedAuthUser, ...cacheUpdate}
                            if(that._isMounted){
                                that.setState({storedAuthUser: userUpdate})
                            }
                            window.localStorage?.setItem('authUser', JSON.stringify(userUpdate))
                        }
                        catch(e){

                        }
                    }
                    else{
                        console.log('no userid ofr langauge change')
                    }
                }
            }}
            name={'preselctor'}
        >
            {(Object.keys(LanguageSets))?.map((languageCode) => (
                <SelectOption
                    style={{textAlign: 'center'}}
                    key={"selector_"+languageCode} 
                    value={languageCode}  
                    selected={
                        user?.features?.language?.length > 1
                        ?   languageCode === user.features.language
                        :   languageCode === 'en-US'
                    }  
                >
                    {LanguageSets[languageCode].title}
                    {LanguageSets[languageCode].native?.length > 0
                    &&
                        (" - " + LanguageSets[languageCode].native)
                    }
                </SelectOption>
            ))}
        </SelectWrapper>
    )
}

class SettingLanguage extends Component {
    static contextType = AuthUserContext;
    constructor(props) {
        super(props);
        this.state = {
            openSetting: false,
        };
    }
    render() {
        //const { user } = this.props;
        const { theme, classes } = this.props;
        //console.log("window params", window)
        return (
            <div className={classes.grow}>
                <Card style={theme.cardSettings}>
                    <List style={theme.textSettings}>
                        <span style= {{ float: 'right'}}>
                            {RenderSettingLanguageOptions(this, this.props.user)}
                        </span>
                        <span style= {theme.textSettings.settingsTextDescription}>
                            <LanguageIcon style= {theme.inlineIconLeft}/>
                            <LanguageManager
                                text={"Language"}
                                type='button'
                                language={this.context?.features?.language}
                            />
                            :
                        </span>
                        <div style= {{width: "60%"}}>
                            <p style= {theme.textSupportGrey}>
                                <LanguageManager
                                    text={"Choose your language"}
                                    type='button'
                                    language={this.context?.features?.language}
                                />
                            </p>
                        </div>
                    </List>
                </Card>
            </div>
                
        );
    }
}


export default compose(
    withStyles(styles, { withTheme: true }),
    withFirebase
)(SettingLanguage);
