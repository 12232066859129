import React, { Component } from 'react';
//import { Link } from "react-router-dom";
import LanguageManager from '../../constants/languages';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { withFirebase } from '../Firebase';
import { AuthUserContext } from '../Session';
import withStyles from '@material-ui/core/styles/withStyles';
import * as ROUTES from '../../constants/routes';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import {IsMobileDevice} from '../Modules/IsMobileDevice';
import {CheckXPLevel} from '../Charts/XPLevels';
import DialogCardBadges from '../Charts/DialogCardBadges';
import StatsBar from '../Charts/StatsBar';
import SnackbarInfo from '../Modules/SnackbarInfo';
import BadgeCard from '../Modules/BadgeCard';

import { Link } from "react-router-dom";
import Button from '@material-ui/core/Button';

import WidgetsIcon from '@material-ui/icons/Widgets';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
//import logo from "../../images/rockyai-logo-image-only.png";

// MUI stuff
import Grid from "@material-ui/core/Grid";
import Card from '@material-ui/core/Card';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import AlarmIcon from '@material-ui/icons/Alarm';

import { SetAgentStatus } from '../Routine/SetAgentStatus';
import RockyIcon from '../Modules/RockyIcon';
import ForumIcon from '@material-ui/icons/Forum';
import SwipeableViews from 'react-swipeable-views';
import { mod } from 'react-swipeable-views-core';
import { virtualize } from 'react-swipeable-views-utils';
import SwipeStepper from '../Modules/SwipeStepper';

// Help
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import IconButton from '@material-ui/core/IconButton';
import { SetStateLoadingBatches } from '../Charts/LoadingBatches';

const VirtualizeSwipeableViews = virtualize(SwipeableViews);

const styles = theme => ({
	desktopPadding: {
	 	minHeight: 40,
	},
	buttonCenter: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-around",
		alignItems: "center",
		marginBottom: 15,
    },
});



class TaskNext3D extends Component {
    static contextType = AuthUserContext;
    _isMounted = false;

    constructor(props) {
        super(props);
        
        this.state = {
            open: false,
            challenges: 4,
            dailyChallengesCount: 4,
        };
    } 

    componentDidMount() {
        this._isMounted = true;
        this.getXPLevels();
        this.getLatestBatch();
    }
    componentWillUnmount(){
        this._isMounted = false;
        if(this.unsubscribe !== undefined){
            this.unsubscribe();
        }
    }

    getLatestBatch(){
        if(this._isMounted){
            SetStateLoadingBatches(this, this.props.firebase, this.context.uid, 'all', 10);
            //console.log("getLatestBatch", this.state)
        }
    }

    getXPLevels(){
        const xpLevels = {
            followUp: CheckXPLevel(this.props.user, 'followUp'),
            content: CheckXPLevel(this.props.user, 'content'),
            social: CheckXPLevel(this.props.user, 'social'),
        }
        var dailyChallengesCount = 1;
        if(xpLevels?.followUp?.valid === true) dailyChallengesCount +=1;
        if(xpLevels?.content?.valid === true) dailyChallengesCount +=1;
        if(xpLevels?.social?.valid === true) dailyChallengesCount +=1;
        dailyChallengesCount = 4;
        const agentStatus = SetAgentStatus(this.props.user);
        this.setState({...agentStatus, xpLevels: xpLevels, dailyChallengesCount: dailyChallengesCount});
        //console.log(this.state)
        var targetTotalEngagements = 50;
        if(this.context.mobileNotifications === "daily" || this.context.emailNotifications === "daily"){ targetTotalEngagements = 80; }
        if(this.context.mobileNotifications === "casually" || this.context.emailNotifications === "casually"){ targetTotalEngagements = 50; }
        if(this.context.mobileNotifications === "weekly" || this.context.emailNotifications === "weekly"){ targetTotalEngagements = 20; }
        var currentTotalEngagements = 0;
        var completedTotal = 0;
        var labelTotal = currentTotalEngagements + "/" +targetTotalEngagements;
        var date = new Date();
        //var selectDayFrom = new Date(date.setDate(date.getDate() - 1));
        //var selectDayFrom12Hours = new Date(date.setHours(date.getHours() - 12));
        var selectDayFrom24Hours = new Date();
        selectDayFrom24Hours.setDate(date.getDate() - 1);
        var selectDayFrom48Hours = new Date();
        selectDayFrom48Hours.setDate(date.getDate() - 2);
        var selectDayFrom96Hours = new Date();
        selectDayFrom96Hours.setDate(date.getDate() - 4);
        var statsList = {};
        this.unsubscribe = this.props.firebase
        .insightsEngagement()
        .where('userId', '==', this.context.uid)
        .where('createdAt', '>=', selectDayFrom96Hours)
        .onSnapshot(snapshot => {
            if (snapshot.size) {
                if(this._isMounted){
                    this.setState({loading:true});
                }
                if (statsList === undefined ) statsList = {};

                currentTotalEngagements = 0;

                snapshot.forEach(doc => {
                    var engagementSet = doc.data();
                    var checkDate = new Date(engagementSet.createdAt.seconds * 1000);
                    if (statsList[engagementSet.type] === undefined ) statsList[engagementSet.type] =  [];
                    if(engagementSet.type === 'followUp' && checkDate > selectDayFrom24Hours){
                        statsList[engagementSet.type].push(engagementSet)
                    }
                    else if(engagementSet.type === 'content' && checkDate >= selectDayFrom48Hours){
                        statsList[engagementSet.type].push(engagementSet)
                    }
                    else if(engagementSet.type === 'social' && checkDate >= selectDayFrom96Hours){
                        statsList[engagementSet.type].push(engagementSet)
                    }
                    currentTotalEngagements += 1;
                    //console.log('data', engagementSet);
                }); // end of snapshot
                

                completedTotal = Math.ceil(currentTotalEngagements / targetTotalEngagements * 100)
                if (currentTotalEngagements>targetTotalEngagements) currentTotalEngagements = targetTotalEngagements;
                //console.log("Currenttotal", targetTotal, completedTotal)
                labelTotal = currentTotalEngagements + "/" +targetTotalEngagements;
                //console.log("1 week date")
            } 
            else{
                console.log("no data daily")
            }
            var dailychallengesSolved = 0;
            if(statsList["followUp"] !== undefined && statsList["followUp"].length >=1){
                if (this.state.xpLevels?.followUp?.valid === true ) dailychallengesSolved += 1;
            }
            if(statsList["content"] !== undefined && statsList["content"].length >=1){
                if (this.state.xpLevels?.content?.valid === true ) dailychallengesSolved += 1;
            }
            if(statsList["social"] !== undefined && statsList["social"].length >=1){
                if (this.state.xpLevels?.social?.valid === true ) dailychallengesSolved += 1;
            }
            if(this.props.finishedChat || (this.state.primingInTimeRange &&  this.state.primingStatus === 'DONE' )  || (this.state.reflectionInTimeRange && this.state.reflectionStatus === 'DONE' )){
                dailychallengesSolved += 1;
            }

            //console.log("daily syatrsy", selectDayFrom, statsList)
            //console.log("Currenttotal", targetTotal, completedTotal)
            if(this._isMounted){
                this.setState({
                    dailychallengesSolved,
                    statsList, 
                    labelTotal,
                    completedTotal
                })
            }
            if(this.props.updateScore!== undefined){
                var resultScore = dailychallengesSolved*25;
                if(resultScore> 100)resultScore = 100;
                else if(resultScore<0) resultScore =0;
                //console.log('update', resultScore);
                this.props.updateScore(resultScore);
            }
        });
    }

    render(){
        return(
            this.renderButtons()
        )
    }

    renderChallengeCount(dailychallengesSolved = 0, dailyChallengesCount =4 ){
        const {theme} = this.props;
        return(
            <p style={theme.textSupportCenter}> 
                <LanguageManager
                    text={"{number: " + dailychallengesSolved + "/"+ dailyChallengesCount+"} challenges completed"}
                    type='tutorial'
                    language={this.context?.features?.language}
                />
                <br/>
            </p>
        )
    }

    renderButtons() {
        const { theme } = this.props;
        const { badgesFamilyComplete } = this.state;
        //console.log('    renderButtons()', this.state)
        var checkedText = this.props.finishedChat || (this.state.primingInTimeRange && ( this.state.primingStatus === 'DONE' ))  || (this.state.reflectionInTimeRange && ( this.state.reflectionStatus === 'DONE' ));
        var checkedDiscover =  (this.state.statsList!== undefined && this.state.statsList["content"] !== undefined && this.state.statsList["content"].length >=1);
        var checkedJournal = (this.state.statsList!== undefined && this.state.statsList["followUp"] !== undefined && this.state.statsList["followUp"].length >=1);
        var checkedCommunity = (this.state.statsList!== undefined && this.state.statsList["social"] !== undefined && this.state.statsList["social"].length >=1);
        //console.log(this.state.reflectionInTimeRange,this.state.reflectionStatus )
        return (
            <div style={{margin: this.props.cardMargin !== undefined ? this.props.cardMargin : 20}}>
                <div style={{position: 'relative'}}>
                    <h4 style={theme.CardContent.titleText}>
                        Daily Challenges
                    </h4>
                    {this.props.tutorialOpen 
                    &&
                        <div style={{position: 'absolute', top: -4, right: -6}}>
                            <IconButton style={{marginRight: 0}} onClick={() => {if(this.props.tutorialOpen) this.props.tutorialOpen('mission')}}>
                                <HelpOutlineIcon />
                            </IconButton>
                        </div>
                    }
                </div>
                <div style={{...theme.CardContentItem.TextSubTitle, marginBottom: 20}}>
                    {this.state.dailychallengesSolved > 0 && this.state.dailychallengesSolved>=this.state.dailyChallengesCount
                        ?   
                            <>
                                {this.state.primingInTimeRange 
                                ?
                                    <span style={{color: 'green', marginBottom: 10}}> 
                                        You finished all morning mindset challenges. 
                                        <br/><strong>We will continue tonight! </strong>
                                    </span>
                                :
                                    <span style={{color: 'green', marginBottom: 10}}> 
                                        You finished all challenges for today. 
                                        <br/><strong>We will continue tomorrow! </strong>
                                    </span>
                                }
                                <div style={{width: '100%', height: 50}}>
                                    <Button style={{...theme.buttonRight}} 
                                        onClick={() => {
                                                this.props.history.push({
                                                    pathname: ROUTES.PROFILE,
                                                    state: {showNotifications: true}
                                                });
                                            }}
                                        >
                                        Alerts&nbsp;<AlarmIcon/>
                                    </Button>
                                </div>
                            </>
                        :   false && (this.props.user.dialogueCounter === undefined || this.props.user.dialogueCounter < 5)
                            ?
                                <p style={theme.textCenter}> 
                                    Self-improvement missions &bull; Soft-skill practice
                                </p>
                            :   ''
                    }
                </div>
                {this.renderChallengeCount((this.state.dailychallengesSolved || 0), (this.state.dailyChallengesCount || 4))}
                <SnackbarInfo 
                    alertText = {this.state.alertText || "Complete your daily challenges"}
                    openOnClick = {true}
                    alertOpenEvent = {this.state.openAlert || false}
                    closeHandler = {() => {this.setState({openAlert: false})}}
                />
                <Grid container spacing={2} justifyContent="center" alignItems="stretch">
                    <DialogCardBadges 
                        handleOpen={this.state.dialogCardOpen || false}
                        handleClose={() => {this.setState({dialogCardOpen: false})}}
                        badgeFamilyDetailSelection= {this.state.badgeFamilyDetailSelection || 'all'}
                    />
                    <Grid item xs ={12}>
                        <div style={{ minWidth: 240, marginTop: 20, textAlign:"center"}}>
                            <StatsBar
                                width="100%"
                                //type="weekly"
                                verticalAlign="center"
                                size="large"
                                labelShort="XP"
                            />
                        </div>
                    </Grid>
                    <Grid item sm={6} style={{width:'100%', height: 'auto'}}>
                        <BadgeCard
                            backgroundColor ={checkedText
                                ?   'transparent'
                                :   theme.palette.backPrimary
                            }
                            avatarImage={
                                (checkedText)
                                    ?   <CheckCircleIcon style={{color:"green", height: '80%', width: '80%' }}  />
                                    :   
                                        (this.props.defaultSet?.programAvatarName?.length > 2
                                            ?   <ForumIcon style={{ height: '80%', width: '80%'}} /> 
                                            :   <RockyIcon style={{ height: '80%', width: '80%'}} />
                                        )
                                } 
                            title = {this.state.primingInTimeRange 
                                ? "Coaching Chat"
                                : "Reflective Chat"}
                            subtitle = {
                                (checkedText)
                                ?   (this.state.badgesFamilyInComplete?.[this.context.currentCategory]?.description || "Done!")
                                :   this.chooseAgentText()
                            }
                            buttonIcon = {
                                checkedText
                                ?   (this.state.badgesFamilyInComplete?.[this.context.currentCategory]?.iconUrl || undefined)
                                :   undefined
                            }
                            strongSubtitle = {false}
                            onClick={checkedText
                                ?   () => {this.setState({openAlert: true, alertText: "You've successfully finished today's chat session!"})}
                                :   () => {this.props.history.push(ROUTES.ROUTINE)} }
                            clickText={checkedText? undefined : "Chat"}
                            style={{height: '100%'}}
                        />
                    </Grid>
                    {(true || this.state.xpLevels?.content?.valid === true)
                        &&
                        <Grid item sm={6} style={{width:'100%', height: 'auto'}}>
                            <BadgeCard
                                backgroundColor={
                                    checkedDiscover
                                    ?   'transparent'
                                    :   theme.palette.backPrimary
                                }
                                avatarImage={
                                    checkedDiscover
                                    ?   <CheckCircleIcon style={{color:"green", height: '80%', width: '80%' }}  />
                                    :   <WidgetsIcon style={{ height: '80%', width: '80%'}}/>
                                } 
                                title = {"Learning & Quests"
                                }
                                subtitle = {
                                    checkedDiscover
                                    ?   (this.state.badgesFamilyInComplete?.['xp_content']?.description || "Explored!")
                                    :   "Personalized Program & Content"
                                }
                                buttonIcon = {
                                    checkedDiscover
                                    ?   (this.state.badgesFamilyInComplete?.['xp_content']?.iconUrl || undefined)
                                    :   undefined
                                }
                                strongSubtitle = {false}
                                onClick={checkedDiscover
                                    ?   () => {this.setState({openAlert: true, alertText: "Great job! You've successfully completed a learning article."})}
                                    :   () => {this.props.history.push(ROUTES.CONTENT)} }
                                clickText={checkedDiscover? undefined : "Read"}
                                style={{height: '100%'}}
                            />
                        </Grid>
                    }
                    {(true || this.state.xpLevels?.followUp?.valid === true)
                        &&
                        <Grid item sm={6}  style={{width:'100%', height: 'auto'}}>
                            <BadgeCard
                                backgroundColor ={
                                    checkedJournal
                                    ?   'transparent'
                                    :   theme.palette.backPrimary
                                }
                                avatarImage={
                                    checkedJournal
                                        ?   <CheckCircleIcon style={{color:"green", height: '80%', width: '80%' }}  />
                                        :   <AssignmentTurnedInIcon style={{ height: '80%', width: '80%'}}/>
                                }
                                title = {
                                        "Journey"
                                }
                                subtitle = {
                                    checkedJournal
                                        ?   this.state.badgeTotal?.description?.length > 3 ? (this.state.badgeTotal?.description) : 'Goals tracked!'
                                        :   "Track status & update sub-goals"
                                }
                                buttonIcon = {
                                    checkedJournal
                                    ?   (this.state.badgeTotal?.iconUrl || undefined)
                                    :   undefined
                                }
                                strongSubtitle = {false}
                                onClick={checkedJournal
                                    ?   () => {this.setState({openAlert: true, alertText: "Excellent work! You've successfully followed up and managed your goals."})}
                                    :   () => {this.props.history.push(ROUTES.JOURNAL)} }
                                clickText={checkedJournal? undefined : "Check"}
                                style={{height: '100%'}}
                            />
                        </Grid>
                    }
                    {this.state.xpLevels?.social?.valid === true
                        ?
                            <Grid item sm={6} style={{width:'100%', height: 'auto'}}>
                                <BadgeCard
                                    backgroundColor ={
                                        checkedCommunity
                                        ?   'transparent'
                                        :   theme.palette.backPrimary
                                    }
                                    avatarImage={
                                        checkedCommunity
                                        ?   <CheckCircleIcon style={{color:"green", height: '80%', width: '80%' }}  />
                                        :   <PeopleAltIcon style={{ height: '80%', width: '80%'}}/>
                                    } 
                                    title = {
                                        true
                                        ?   (this.state.badgesFamilyComplete?.['xp_social']?.title || "Discuss")
                                        :   "Discuss"
                                    }
                                    subtitle = {
                                        checkedCommunity
                                        ?   (this.state.badgesFamilyComplete?.['xp_social']?.description ||  "Engaged with community!")
                                        :   "Share ideas & help the community"
                                    }
                                    buttonIcon = {
                                        checkedCommunity
                                        ?   (this.state.badgesFamilyComplete?.['xp_social']?.iconUrl || undefined)
                                        :   undefined
                                    }
                                    strongSubtitle = {false}
                                    onClick={checkedCommunity
                                        ?   () => {this.setState({openAlert: true, alertText: "Fantastic job! You've been a great community member by helping others."})}
                                        :   () => {this.props.history.push(ROUTES.MESSAGES)}}
                                    clickText={checkedCommunity? undefined : "Share"}
                                    style={{height: '100%'}}
                                />
                            </Grid>
                        :
                            <Grid item sm={6} style={{width:'100%', height: 'auto'}}>
                                <BadgeCard
                                    backgroundColor ={
                                        checkedCommunity
                                        ?   'transparent'
                                        :   theme.palette.backPrimary
                                    }
                                    avatarImage={
                                        (this.props.user?.userAssessment?.pA !== undefined || this.props.user?.userAssessment?.O !== undefined)
                                        ?   <CheckCircleIcon style={{color:"green", height: '80%', width: '80%' }}  />
                                        :   <PeopleAltIcon style={{ height: '80%', width: '80%'}}/>
                                    } 
                                    title = {"Self-Assessment"
                                    }
                                    subtitle = {"Complete your personal assessments."
                                    }
                                    buttonIcon = {
                                        checkedCommunity
                                        ?   (this.state.badgesFamilyComplete?.['xp_social']?.iconUrl || undefined)
                                        :   undefined
                                    }
                                    strongSubtitle = {false}
                                    onClick={this.props.updateOcean !== undefined
                                        ?   this.props.updateOcean
                                        :   () => {this.props.history.push(ROUTES.STATS)}}
                                    clickText={"Start"}
                                    style={{height: '100%'}}
                                />
                            </Grid>
                    }
                </Grid>
                <p style={theme.textSupportCenter}>
                    <LanguageManager
                        text={"Self-improvement tasks"}
                        type='button'
                        language={this.context?.features?.language}
                    />
                    &nbsp;&bull;&nbsp;
                    <LanguageManager
                        text={"Gain 'XP'"}
                        type='button'
                        language={this.context?.features?.language}
                    />
                </p>
            </div>
        )
    }

    renderSwipe() {
        const { theme } = this.props;
        //console.log(this.state.reflectionInTimeRange,this.state.reflectionStatus )
        return (
            <div style={{margin:20}}>
                <h4 style={theme.CardContent.titleText}>
                    <LanguageManager
                        text={"Daily Challenges"}
                        type='button'
                        language={this.context?.features?.language}
                    />
                </h4>
				{(this.props.user.dialogueCounter === undefined || this.props.user.dialogueCounter < 5)
					&&
					<p style={theme.textCenter}> 
                        <LanguageManager
                            text={"Self-improvement tasks"}
                            type='button'
                            language={this.context?.features?.language}
                        />
                        &nbsp;&bull;&nbsp;
                        <LanguageManager
                            text={"Soft-skill practices"}
                            type='button'
                            language={this.context?.features?.language}
                        />
					</p>
				}
                {this.renderChallengeCount((this.state.dailychallengesSolved || 0), (this.state.dailyChallengesCount || 4))}
                {/***
                <h1 style={theme.CardContent.titleText}><ControlPointIcon />&nbsp; What's next?</h1>
                 */}

                <VirtualizeSwipeableViews
                /***
                     */
                    key={'main'}
                    axis={'x'}
                    index={this.state['activeStep'] || 0}
                    onChangeIndex={(step) => {
                        try{
                            //console.log('step', step, mod(step,4))
                            this.setState({activeStep : step});
                        }
                        catch (err) {console.log('Swipe Error')}
                    }}
                    enableMouseEvents = {this.state.allowRefresh === false? false : true}
                    disabled = {this.state.allowRefresh === false? true : false}
                    style={{
                        paddingLeft: 0,
                        width: "60%",
                        maxWidth: 400,
                        overflow: 'visible',
                        position: "sticky",
                        left: this.props.screen === "desktop" ? 80 : -120,

                    }}
                    resistance = {true}
                    slideStyle={{
                        paddingRight:0,
                        paddingBottom: 10,
                        flex: 'none',
                        overflow: 'hidden',
                    }}
                    slideRenderer={this.slideRenderer}
                    />
                <SwipeStepper
                    handlerSteps = {(step) => this.setState({activeStep:step}) }
                    steps={4}
                    activeStep = {this.state.activeStep || 0}
                />
                {
                    //<Carousel3D slides={slides} autoplay={true} interval={1000}/>
                }
            </div>
        )
    }

    slideRenderer = (params ) => {
        const {index, key} = params;
        //console.log("params",  params, mod(index,4))
        switch (mod(index,4)){
            case 0:
                return this.renderActionItem(
                    key,
                    <AssignmentTurnedInIcon/>
                    , 'Track Status & Update Journal'
                    , "Follow-up"
                    , (this.state.statsList!== undefined && this.state.statsList["followUp"] !== undefined && this.state.statsList["followUp"].length >=1)
                    , ROUTES.JOURNAL
                )
            case 1:
                return this.renderActionItem(
                    key,
                    (this.props.defaultSet?.programAvatarName?.length > 2
                        ?   <ForumIcon /> 
                        :   <RockyIcon />
                    )
                    , this.chooseAgentText()
                    , this.state.primingInTimeRange 
                        ? "Coaching Chat"
                        : "Reflective Chat"
                    , ((this.state.primingInTimeRange && ( this.state.primingStatus === 'DONE' ))  || (this.state.reflectionInTimeRange && ( this.state.reflectionStatus === 'DONE' )))
                    , ROUTES.ROUTINE
                )
            case 2:
                return this.renderActionItem(
                    key,
                    <WidgetsIcon/>
                    , "Personalized Tips & Inspirations"
                    , "Explore"
                    , (this.state.statsList!== undefined && this.state.statsList["content"] !== undefined && this.state.statsList["content"].length >=1)
                    , ROUTES.CONTENT
                )
            case 3:
                return this.renderActionItem(
                    key,
                    <PeopleAltIcon/>
                    , "Share Ideas & Help the Community"
                    , "Discuss"
                    , (this.state.statsList!== undefined && this.state.statsList["social"] !== undefined && this.state.statsList["social"].length >=1)
                    , ROUTES.MESSAGES
                )
            default:
                return null;
        }
    }

    renderActionItem(key, icon, subtitle, title, actionResult, link){
        const {theme} =  this.props;
        //console.log('redner', key, title)
        var color = theme.palette.themeAlertGreen ;
        if(actionResult) color = theme.palette.themeLightGrey ;
        return(
            <Card id={key} key={key} 
                style={{...theme.CardDialogueButtonFrame, 
                    background : 'linear-gradient(to bottom, '+ color +' 0%, '+theme.palette.white+' 30%)',
                    //backgroundColor : 'linear-gradient(to bottom, '+color+' 0%, #FFFFFF 30%)',
                    margin:10, 
                    padding:20,
                    height: "90%",
                    overflow: "hidden"
                }}
            >
                        <Grid container spacing={2} direction="row" justifyContent="space-between" alignItems="center">
                            <Grid item xs={2}>
                                {icon}
                            </Grid>
                            <Grid item xs={10}>
                                <span style={theme.CardContentItem.TextSubTitle}> 
                                    <strong style={{wordWrap:'break-word'}}>
                                    &nbsp; {title}
                                    </strong>
                                </span>  
                            </Grid>
                            <Grid item xs={12}>
                                <span style={theme.CardContentItem.TextSubTitle}> 
                                   {subtitle}
                                </span>  
                            </Grid>
                            <Grid item xs={12}>
                                &nbsp;
                                {actionResult
                                    ?
                                        <CheckCircleIcon
                                            style={{color:"green",  float: "right"  }}
                                        />
                                    :
                                        <Link to={link}>
                                            <Button
                                                style={{float: "right", backgroundColor: theme.palette.themeRed, marginBottom:10}}
                                                size={IsMobileDevice() ? "small" : "medium"}
                                                endIcon={icon}
                                            >
                                                Start
                                            </Button>
                                        </Link>
                                }
                            </Grid>
                        </Grid>
                    
            </Card>
        )
    }
    
    chooseAgentText = () => {
        if (this.state.primingInTimeRange) {
            if (this.state.primingStatus === 'START') return 'Start AI Chat and answer questions to set the scene for your day.';
            else if (this.state.primingStatus === 'DONE') return 'Come back tonight for an evening reflection practice.';
            else if (this.state.primingStatus === 'CONTINUE') return 'Continue with AI Chat.';
            else return 'Get more coaching questions';
        }
        else if (this.state.reflectionInTimeRange) {
            if (this.state.reflectionStatus === 'START') return 'Start AI Chat and answer questions to reflect on the day.';
            else if (this.state.reflectionStatus === 'DONE') return 'Come back tomorrow for a morning mindset practice.';
            else if (this.state.reflectionStatus === 'CONTINUE') return 'Continue with AI Chat.';
            else return 'Get more coaching questions';
        }
        else return 'Get more coaching questions';
    }
}

export default compose(
    withFirebase,
    withRouter,
    withStyles(styles, { withTheme: true})
)(TaskNext3D);
