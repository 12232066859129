//import ContentSuggestCarousel from "../Content/ContentSuggestCarousel";
import { AccessCheckCoachCreator } from "../../constants/roles";
import {DataCacheRead, DataCacheWrite} from "../Modules/DataCache";
import {getDocs, collection, query, where, or, and } from "firebase/firestore";

export async function SetStateLoadCategoriesAndQualities(that, db, checkExpiration=true, reload=false, programs=['default'], addList=false) {
    //console.log('loading cat 1 cat for', programs)
    if(that?.context?.adminPrograms?.length > 0){
       programs = that.context.adminPrograms.concat(programs) 
    }
    programs = [...new Set(programs)];
    //console.log('loading cat n cat for', programs)
    let leadershipCategories = [];
    let leadershipQualities = [];
    let leadershipCategoriesValues = [];
    let leadershipQualitiesValues = [];
    let leadershipCategoriesPrograms = [];
    if(programs===undefined || programs.length===0){
        programs=['default']
    }
    ////////////////// CHECK THE APP CACHE
    var cacheStoreCategories = DataCacheRead('leadershipCategories')
    if(cacheStoreCategories.cacheAvailable && (!checkExpiration || !cacheStoreCategories.isCacheExpired)) {
        leadershipCategories = cacheStoreCategories.cacheData;
        leadershipCategoriesPrograms = leadershipCategories?.map(item => item.program) || [];
        leadershipCategoriesValues = leadershipCategories?.map(item => item.value) || [];
        if(that._isMounted!==undefined && leadershipCategories?.length > 0){
            that.setState({leadershipCategories: leadershipCategories});
            if(that.props?.refreshCategories !== undefined){
                that.props.refreshCategories(leadershipCategories);
                //console.log('resulted init', leadershipCategories)
            }
        }
        //console.log('cahced valyues',leadershipCategoriesValues)
    }
    else{
        reload = true;
    }
    //console.log(reload, '1')
    if(true){
        /// double check categories
        /// double check programs
        /// not do this for wbite labels that dont have progrmas
        if (false && programs.length > 0){
            programs.map((item) => {
                if (!leadershipCategoriesPrograms.includes(item)){
                    reload = true;
                    //console.log('leadershipCategoriesPrograms', leadershipCategoriesPrograms, item)
                }
                return 'mapped';
            })
        }
        if (that.context?.currentCategory && !leadershipCategoriesValues.includes(that.context.currentCategory)){
            //console.log('missing category', that.context?.currentCategory, leadershipCategoriesValues)
            leadershipCategoriesValues.push(that.context.currentCategory);
            reload = true;
        }
        if(reload === false && that._isMounted!==undefined && leadershipCategories.length > 0){
            that.setState({leadershipCategories: leadershipCategories});
            if(that.props?.refreshCategories !== undefined){
                that.props.refreshCategories(leadershipCategories);
                //console.log('resulted init', leadershipCategories)
            }
            //console.log('new', leadershipCategories)
        }
        else{
            //localStorage.removeItem('leadershipCategories')
            //console.log('remove categoreixs storage')
            reload = true;
            //console.log('reload', leadershipCategories)
        }
    }
    //console.log(reload, '2')
    if(true){ //// load qualities temp
        var cacheStoreQualities = DataCacheRead('leadershipQualities')
        //console.log('setting cache quals', cacheStoreQualities, cacheStoreQualities.isCacheExpired, that )
        if(cacheStoreQualities.cacheAvailable && (!checkExpiration || !cacheStoreCategories.isCacheExpired)) {
            leadershipQualities = cacheStoreQualities.cacheData;
            leadershipQualitiesValues = [];
            leadershipQualities?.map((item, index) => {
                if(item.value?.length > 1){
                    var qualityID = item.value;
                    if(qualityID?.includes(".")){
                        leadershipQualities[index]['valueID'] = qualityID.substring(0, qualityID.indexOf("."));
                    }
                    else{
                        leadershipQualities[index]['valueID'] = qualityID;
                    }
                    if(!leadershipQualitiesValues.includes(qualityID)){
                        leadershipQualitiesValues.push(qualityID);
                    }
                }
            })
            if (that.context?.currentLeadershipQuality && !leadershipQualitiesValues.includes(that.context.currentLeadershipQuality)){
                leadershipQualitiesValues.push(that.context.currentLeadershipQuality);
                reload = true;
            }
            if (that.context?.todaysLeadershipQuality?.length > 2 && !leadershipQualitiesValues.includes(that.context.todaysLeadershipQuality)){
                leadershipQualitiesValues.push(that.context.todaysLeadershipQuality);
                reload = true;
            }
            ['6','5','4','3','2','1'].map(item => {
                if (that.context?.['additionalLeadershipQuality'+item]?.length > 2 && !leadershipQualitiesValues.includes(that.context['additionalLeadershipQuality'+item])){
                    leadershipQualitiesValues.push(that.context['additionalLeadershipQuality'+item]);
                    reload = true;
                }
            })
            if(that._isMounted!==undefined && leadershipQualities.length > 0){
                that.setState({leadershipQualities: leadershipQualities})
                if(that.props?.refreshQualities !== undefined){
                    that.props.refreshQualities(leadershipQualities);
                    //console.log('zzx', leadershipQualities)
                }
            }
            //console.log('setting cache quals', cacheStoreQualities.isCacheExpired, leadershipQualities, that._isMounted, that)
        }
        else{
            reload = true;
        }
        if (that.context?.currentLeadershipQuality && !(leadershipQualitiesValues.includes(that.context.currentLeadershipQuality))){
            //console.log('missing leadershipQualitiesValues', that.context?.currentLeadershipQuality, leadershipQualitiesValues)
            leadershipQualitiesValues.push(that.context.currentLeadershipQuality);
            reload = true;
        }
        if(reload === false && that._isMounted!==undefined && leadershipQualities.length > 0){
            that.setState({leadershipQualities: leadershipQualities})
            if(that.props?.refreshQualities !== undefined){
                that.props.refreshQualities(leadershipQualities);
                //console.log('zzx', leadershipQualities)
            }
            //console.log('zzx', leadershipQualities)
        }
        else{
            //localStorage.removeItem('leadershipQualities')
            //console.log('remove leadershipQualities storage', reload ,  that._isMounted, leadershipQualities.length )
            reload = true;
        }
    }
    console.log('reload', reload)
    if (reload || !cacheStoreCategories.cacheAvailable || (checkExpiration && cacheStoreCategories.isCacheExpired) || 
        !cacheStoreQualities.cacheAvailable || (checkExpiration && cacheStoreCategories.isCacheExpired)) {
        //console.log('loading cat n cat for', programs, that.props, that)
        /***
        var dblc = db
        .leadershipCategories()
        if(AccessCheckCoachCreator(that.context)){
            //console.log('reading cats by coach')
            dblc = dblc.where('program', 'in', ['default'].concat(programs))
        }
        else(
            dblc = dblc.where('active', '==', true)
        )
        dblc.get()
        .then(snapshot => {
        */
        var dblcCatModular = undefined;
        //console.log('AccessCheckCoachCreator', that)
        if(AccessCheckCoachCreator(that.context)){
            //console.log('AccessCheckCoachCreator access', programs, that.context)
            dblcCatModular = query(collection(that.props.firebase.db, "leadership_categories"), 
                or(
                    where('program', 'in', ['default'].concat(programs)),
                    where('public', '==', true)
                )
            )
        }
        else{
            //console.log('rldr access', that.props.firebase.db)
            dblcCatModular = query(collection(that.props.firebase.db, "leadership_categories"), 
                and(
                    where('active', '==', true),
                    or(
                        where('program', 'in', ['default'].concat(programs)),
                        where('public', '==', true)
                    )
                )
            )
        }
        //console.log('rldr start', dblcCatModular)
        try{
            const snapshot = await getDocs(dblcCatModular)
            if (!snapshot) {            
                console.log("Error getting document:");
                if(that._isMounted!==false){
                    that.setState({failedLoadingFirestore:true});
                }
                return;
            }
            else{
                leadershipCategories = []
                leadershipCategories = sortCategoriesFromSnapshot(that, snapshot, programs, leadershipCategoriesValues)
                //console.log('resulted cateogures', leadershipCategories)
                if(that._isMounted!==false && leadershipCategories.length > 0){
                    that.setState({leadershipCategories: leadershipCategories});
                    //console.log('resulted 2', leadershipCategories)
                    if(that.props?.refreshCategories !== undefined){
                        that.props.refreshCategories(leadershipCategories);
                        //console.log('resulted 3', leadershipCategories)
                    }
                    else if(that.refreshCategories !== undefined){
                        that.refreshCategories(leadershipCategories);
                        //console.log('resulted 4', leadershipCategories)
                    }
                    if(addList){
                        var myArray = [];
                        var myArrayText = [];
                        //console.log('props', this.props)
                        leadershipCategories.map((item, index) => {
                            myArray.push(item.value);
                            myArrayText.push("("+ (item.program==="default"? "Essentials" : "Premium")  + ") "+ item.text);
                            return 'mapped';
                        })
                        that.setState({
                            leadershipCategoriesValues: myArray,
                            leadershipCategoriesText: myArrayText
                        });
                    }
                }
                //console.log('xx', leadershipCategories)
                DataCacheWrite('leadershipCategories',leadershipCategories, 1800)
                //// Now load the Qualities
                var lsProgramsSearch = [].concat(programs);
                if(lsProgramsSearch.length === 0){
                    lsProgramsSearch=['default'];
                }
                /****
                var dblq = db
                .leadershipQualities()
                .orderBy('category', 'asc')
                .orderBy('text', 'asc')
                if(AccessCheckCoachCreator(that.context)){
                    console.log('reading quali hide active by coach')
                }
                else(
                    dblq = dblq.where('active', '==', true)
                )
                /// check for special selection
                if(!lsProgramsSearch.includes('default')){
                    /// check for premium content
                    if(programs?.length>=10){
                        lsProgramsSearch = programs.slice(0,8);
                    }
                    if(that?.context?.hidePublicContent !== true && !lsProgramsSearch.includes('default')){
                        lsProgramsSearch.push('default');
                    }
                    else{
                        lsProgramsSearch.push('default');
                    }
                    dblq = dblq.where('program', 'in', lsProgramsSearch)
                }
                //console.log('xx', lsProgramsSearch)
                dblq
                .get()
                 */
                var dblcQualModular = undefined;
                if(AccessCheckCoachCreator(that.context)){
                    //console.log('Qual filter by public or', programs)
                    dblcQualModular = query(collection(that.props.firebase.db, "leadership_qualities"), 
                        or(
                            where('program', 'in', ['default'].concat(programs)),
                            where('public', '==', true)
                        )
                    )
                }
                else if(that?.context?.hidePublicContent !== true && !lsProgramsSearch.includes('default')){
                    //console.log('Qual filter by active and', programs)
                    dblcQualModular = query(collection(that.props.firebase.db, "leadership_qualities"), 
                        and(
                            where('active', '==', true),
                            where('program', 'in', programs)
                        )
                    )
                }
                else{
                    //console.log('Qual filter by else active and public or ', programs)
                    dblcQualModular = query(collection(that.props.firebase.db, "leadership_qualities"), 
                        and(
                            where('active', '==', true),
                            or(
                                where('program', 'in', ['default'].concat(programs)),
                                where('public', '==', true)
                            )
                        )
                    )
                }
                getDocs(dblcQualModular)
                .then(snapshot => {
                    if (snapshot.empty) {
                        console.log('no data');
                        return;
                    }
                    else{
                        //console.log('is data');
                        //console.log('Search from programs', programs, leadershipCategories)
                        leadershipQualities = [];
                        leadershipQualities = sortQualitiesFromSnapshot(that, snapshot, programs, leadershipCategories, leadershipQualitiesValues, (that?.context?.hidePublicContent===true ? true : false))
                        //console.log('2 data leadershipQualities', leadershipQualities);
                        if(that._isMounted!==false && leadershipQualities?.length > 0){
                            //console.log('2 data leadershipQualities', leadershipQualities?.length);
                            try{
                                that.setState({leadershipQualities: leadershipQualities});
                                //console.log('2 quals set', leadershipQualities?.length);
                                if(reload && that.props?.refreshQualities !== undefined){
                                    //console.log('3 data leadershipQualities', leadershipQualities?.length);
                                    that.props.refreshQualities(leadershipQualities);
                                }
                                else if(reload && that.refreshQualities !== undefined){
                                    //console.log('4 data leadershipQualities', leadershipQualities?.length);
                                    that.refreshQualities(leadershipQualities);
                                }
                            }
                            catch(e){
                                console.log('qualites update error', e)
                            }
                            if(addList){
                                var myArray = [];
                                var myArrayText = [];
                                //console.log('props', this.props)
                                leadershipQualities.map((item, index) => {
                                    myArray.push(item.value);
                                    var categoryName = item.category;
                                    categoryName = FindTextValueInDict(that.props.firebase, leadershipCategories, item.category);
                                    myArrayText.push("(" + categoryName + ") " + item.text);
                                    return 'mapped';
                                }) 
                                //console.log('2 data', myArray, myArrayText);
                                that?.setState({
                                    leadershipQualitiesValues: myArray,
                                    leadershipQualitiesText: myArrayText
                                });
                            }
                        }
                        //console.log('zzsss', leadershipQualities)
                        DataCacheWrite('leadershipQualities',leadershipQualities, 600)
                    }
                })
                .catch((error) => {
                    //console.log("Error getting query:", error);
                    if(that._isMounted!==false){
                        that.setState({failedLoadingFirestore:true});
                    }
                }); 
                //console.log('qualities', leadershipQualities)
            }
        }
        catch (error) {
            console.log(`Attempt failed:`, error);
        }
    }
        
}

export function LoadCategoriesAndQualities(db, programs=['default']) {
    //console.log('ls', programs)
    if(programs===undefined || programs.length===0){
        programs=['default']
    }
    let leadershipCategories = [];
    //.where('active', '==', true)
    var cacheStoreCategories = DataCacheRead('leadershipCategories')
    if(cacheStoreCategories.cacheAvailable && !cacheStoreCategories.isCacheExpired) {
        leadershipCategories = cacheStoreCategories.cacheData;
        //that.setState({leadershipCategories: leadershipCategories})
        //console.log(leadershipCategories[0])
    }
    if (!cacheStoreCategories.cacheAvailable || cacheStoreCategories.isCacheExpired) {
        db()
        .leadershipCategories()
        //.where('active', '==', true)
        .get()
        .then(snapshot => {
            if (snapshot.empty) {
                return;
            }
            leadershipCategories = sortCategoriesFromSnapshot(undefined, snapshot, programs)
            DataCacheWrite('leadershipCategories',leadershipCategories, 720)
            //that.setState({leadershipCategories: leadershipCategories})
        });
    }
    //console.log('categories', leadershipCategories)
    let leadershipQualities = [];
    var cacheStoreQualities = DataCacheRead('leadershipQualities')
    if(cacheStoreQualities.cacheAvailable) {
        leadershipQualities = cacheStoreQualities.cacheData;
        //console.log(cacheStoreQualities)
        //console.log('loaded from cache')
    }
    else{
        var searchDb = db
        .leadershipQualities()
        .orderBy('category', 'asc')
        .orderBy('text', 'asc')
        .where('active', '==', true)
        //.where('program', 'array-contains-any', programs)
        searchDb.get()
        .then(snapshot => {
            if (snapshot.empty) {
                console.log('no data');
                return;
            }
            else{
                leadershipQualities = sortQualitiesFromSnapshot(undefined, snapshot, programs)
                //console.log('qualities', leadershipQualities)
                DataCacheWrite('leadershipQualities',leadershipQualities, 720)
            }   
        });
        //console.log('loaded from db', leadershipQualities)
    }
    return {leadershipCategories, leadershipQualities}
}

function sortCategoriesFromSnapshot(that, snapshot, programs=['default'], leadershipCategoriesValues=['default']) {
    let leadershipCategories = [];
    let premiumCats = [];
    let defaultCats = [];
    if(programs===undefined || programs.length===0){
        programs=['default']
    }
    snapshot.forEach(doc => {
        var programItem = doc.data();
        //console.log('filter cats ', programItem.title, programItem.active);
        ////////////////// ignoring program now
        if  (//true || 
                (programs.includes(programItem.program) || leadershipCategoriesValues.includes(programItem.value) || programItem.program === 'default' || programItem.public)
            ){
            if(programItem.premium){
                premiumCats.push({ ...programItem, cid: doc.id });
            }
            else{
                defaultCats.push({ ...programItem, cid: doc.id });
            }
        }
        else{
            //console.log('doc ', programs, doc.data());
        }
    });
    premiumCats = premiumCats
        .map(value => ({ value, sort: Math.random() }))
        .sort((a, b) => a.sort - b.sort)
        .map(({ value }) => value)
    defaultCats = defaultCats
        .map(value => ({ value, sort: Math.random() }))
        .sort((a, b) => a.sort - b.sort)
        .map(({ value }) => value)
    leadershipCategories = leadershipCategories.concat(premiumCats);
    leadershipCategories = leadershipCategories.concat(defaultCats);
    //console.log('fitered cats', premiumCats, defaultCats)
    return leadershipCategories
}

function sortQualitiesFromSnapshot(that, snapshot, programs=['default'], leadershipCategories=[], leadershipQualitiesValues=[], hidePublicContent = false) {
    let leadershipQualities = [];
    if(programs===undefined || programs.length===0){
        programs=['default']
    }
    var leadershipCategoriesValues = leadershipCategories?.map(item => item.value) || [];
    let qualitiesByCategory = {'default': {}, 'preSelected': {}}
    snapshot.forEach(doc => {
        var newQuality = doc.data();
        var qualityID = newQuality.value;
        if(qualityID?.includes(".")){
            newQuality['valueID'] = qualityID.substring(0, qualityID.indexOf("."));
        }
        else{
            newQuality['valueID'] = qualityID;
        }
        if( qualitiesByCategory[newQuality.category] === undefined ){
            qualitiesByCategory[newQuality.category] = {}
        }
        ///////// add default level into quaity and sort into the programs
        if (newQuality.level === undefined || newQuality.level < 1){
            newQuality['level'] = 0;
        }
        var newTempId = (newQuality.level?.toString() || "0")+(newQuality.value || doc.id);
        //console.log('doc ', newQuality.value, newQuality);
        ////////////////// ignoring program now
        ///// !!! actually the single quality does not know if it is public
        //console.log('program', newQuality.program)
        if(/// no need for checking active as done before
            (newQuality.public || programs.includes(newQuality.program) || newQuality.program === 'default' ||
            leadershipCategoriesValues.includes(newQuality.category) || leadershipCategoriesValues?.length===0
            || leadershipQualitiesValues.includes(newQuality.value)
            )
        ){
            //// add the category when needed
            if( qualitiesByCategory[newQuality.category][newTempId] === undefined ){
                qualitiesByCategory[newQuality.category][newTempId] = {}
            }
            var addedFlag = false;
            //console.log('program', programs, newQuality.value, newQuality.program)
            if(programs?.includes(newQuality.program)){
                qualitiesByCategory[newQuality.category][newTempId] = { ...newQuality, qid: doc.id };
                addedFlag = true;
                //console.log('included in program', newQuality.value)
            }
            else if(that?.context?.currentLeadershipQuality?.length > 2 && newQuality?.value?.length > 2){
                /// add a copy of the digital twin original version to the program
                var userOwnedTopics = [
                    (that.context?.currentLeadershipQuality || undefined),
                    (that.context?.additionalLeadershipQuality1 || undefined),
                    (that.context?.additionalLeadershipQuality2 || undefined),
                    (that.context?.additionalLeadershipQuality3 || undefined),
                    (that.context?.additionalLeadershipQuality4 || undefined),
                    (that.context?.additionalLeadershipQuality5 || undefined),
                    (that.context?.additionalLeadershipQuality6 || undefined)
                ]
                //console.log('userOwnedTopics', newQuality?.value , userOwnedTopics)
                if(userOwnedTopics?.length > 0){
                    userOwnedTopics.map(item => {
                        if(newQuality?.value === item){
                            //console.log('overlap', item)
                            addedFlag = true;
                            qualitiesByCategory['preSelected'][newTempId] = { ...newQuality, qid: doc.id };
                        }
                    })
                }
            }
            /// add the module to standard section
            if(!addedFlag){
                qualitiesByCategory[newQuality.category][newTempId] = { ...newQuality, qid: doc.id };
            }
        }
    });
    //console.log('qualis', qualitiesByCategory)
    var preSelectedQualities = [];
    var premiumQualities = [];
    var defaultQualities = [];
    for (var [key,value] of Object.entries(qualitiesByCategory)) {
        value = value+'x';
        var groupItems = [];
        //console.log("groups: key, value", key, value, qualitiesByCategory[key])
        Object.keys(qualitiesByCategory[key] || [])?.sort().map((keyItem) => {
            //if(key === 'collab_selling') console.log("sorting", keyItem, qualitiesByCategory[key][keyItem])
            if((that?.context?.adminPrograms?.length > 0 && that.context?.adminPrograms?.includes(qualitiesByCategory[key][keyItem].program)) || qualitiesByCategory[key][keyItem].active){
                groupItems.push(qualitiesByCategory[key][keyItem]);
                //console.log('push qualit', qualitiesByCategory[key][keyItem].text, qualitiesByCategory[key][keyItem].program, qualitiesByCategory[key][keyItem].category)
            }
            //sorting = sorting+'x';
        })
        if(groupItems.length > 0){
            if(key==='preSelected'){
                preSelectedQualities = preSelectedQualities.concat(groupItems);
            }
            else if(['default', 'confidence', 'priority', 'communication', 'people', 'clarity', 'balance'].includes(key)){
                defaultQualities = defaultQualities.concat(groupItems);
            }
            else{
                premiumQualities = premiumQualities.concat(groupItems);
            }
        }
    }
    /// remove the default content, in the case when the program is exclsuvie
    if(hidePublicContent && (!programs.includes('default'))){
        leadershipQualities = preSelectedQualities.concat(premiumQualities);
    }
    else{
        leadershipQualities = preSelectedQualities.concat(premiumQualities);
        leadershipQualities = leadershipQualities.concat(defaultQualities);
    }
    //console.log("sorftred", leadershipQualities)
    return leadershipQualities
}

export function FindTextValueInDict(db, dict, searchValue, item='text') {
    var returnValue = undefined;
    if(item==='text'){
        returnValue = "" + searchValue;
    }
    if(searchValue?.includes(".")){
        searchValue = searchValue.substring(0, searchValue.indexOf("."));
    }
    if(searchValue !== undefined && searchValue?.length > 0){
        if(item!=='text'){
            returnValue = undefined;
        }
        if(dict !== undefined && searchValue !== undefined){
            try{
                //console.log(dict[0])
                if(dict?.[0]?.valueID !== undefined){
                    returnValue = dict.find(c => c.valueID === searchValue)[item]
                }
                else{
                    returnValue = dict.find(c => c.value === searchValue)[item]
                }
            }
            catch(error){
                //StoreAppError(db, error.message, 'FindTextValueInDict', searchValue, ('item value: ' + item))
            }
        }
    }
    else{
        //console.log('FindTextValueInDict undefined searchValue')
    }
    return returnValue
};

export function FindDictByKey(db, dict, searchValue) {
    var returnValue = undefined;
    if(searchValue?.includes(".")){
        searchValue = searchValue.substring(0, searchValue.indexOf("."));
    }
    if(searchValue !== undefined){
        if(dict !== undefined && searchValue !== undefined){
            try{
                if(dict?.[0]?.valueID !== undefined){
                    returnValue = dict.find(c => c.valueID === searchValue)
                }
                else{
                    returnValue = dict.find(c => c.value === searchValue)
                }
                //console.log('returnValue', returnValue, dict)
            }
            catch(error){
                //StoreAppError(db, error.message, 'FindTextValueInDict', searchValue, ('item value: ' + item))
            }
        }
    }
    else{
        //console.log('FindDictByKey for undefined searchValue')
    }
    return returnValue
};

export function QualityID(qualityValue){
    var result = qualityValue;
    if(typeof(qualityValue) === 'string'){
        //console.log('result strng', result)
        if(qualityValue?.includes(".")){
            result = qualityValue.substring(0, qualityValue.indexOf("."));
        }
    }
    else if(Array.isArray(qualityValue)){
        result = [];
        qualityValue.map(item => {
            var resItem = ""+item;
            if(item?.includes(".")){
                resItem = item.substring(0, item.indexOf("."));
            }
            result.push(resItem);
        })
        //console.log('result array', result)
    }
    return result
}

export function OverlapQualityFilter(qualitySearch, qualityFound, alternativeQuality = ""){
    var result = false;
    if(qualitySearch?.length > 0){
        var filteredQualityOptions = [qualitySearch];
        if(qualitySearch?.includes(".")){
            filteredQualityOptions.push(qualitySearch.substring(0, qualitySearch.indexOf(".")));
            filteredQualityOptions.push(qualitySearch.substring(qualitySearch.indexOf(".") + 1));
        }
        if(alternativeQuality?.length>1 != ""){
            filteredQualityOptions.push(alternativeQuality);
            if(alternativeQuality?.includes(".")){
                filteredQualityOptions.push(alternativeQuality.substring(0, alternativeQuality.indexOf(".")));
                filteredQualityOptions.push(alternativeQuality.substring(alternativeQuality.indexOf(".") + 1));
            }
        }
        //console.log('filteer options', qualitySearch, filteredQualityOptions)
        if(typeof qualityFound === 'string'){
            if(qualityFound === qualitySearch) return true
            else if(filteredQualityOptions?.includes(qualityFound)) return true
        }
        else if(Array.isArray(qualityFound)){
            if(qualityFound.some( i => i === qualitySearch)) return true
            else if(qualityFound.some( i => filteredQualityOptions?.includes(i))) return true
        }
        //console.log('filteredQualityOptions', filteredQualityOptions)
    }
    return result
}

export function ReturnFilteredDict(db, dict, searchValue, key='category') {
    var returnDict = [];
    if(searchValue?.includes(".")){
        searchValue = searchValue.substring(0, searchValue.indexOf("."));
    }
    if(searchValue !== undefined){
        if(dict !== undefined && searchValue !== undefined){
            try{
                returnDict = dict.filter(c => c[key] === searchValue);
                //console.log('returnValue', returnValue)
            }
            catch(error){
            }
        }
    }
    else{
        //console.log('FindDictByKey for undefined searchValue')
    }
    return returnDict
};