import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import withStyles from '@material-ui/core/styles/withStyles';
import * as ROUTES from '../../constants/routes';
import { AddAnalytics } from '../Modules/AddAnalytics';

//import ControlPointIcon from '@material-ui/icons/ControlPoint';
import BadgeCard from '../Modules/BadgeCard';

/// Subscription things
import {freeTopics, CheckSubscriptionStatus} from '../Modules/CheckSubscriptionStatus';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import DialogSettingPlan from '../Profile/DialogSettingPlan';

import { DataCacheRead, DataCacheWrite} from "../Modules/DataCache";
//import logo from "../../images/rockyai-logo-image-only.png";
import { SetAgentStatus } from '../Routine/SetAgentStatus';
import { FindTextValueInDict, FindDictByKey } from '../Modules/LoadCategoriesAndQualities';
//import {IsMobileDevice} from '../Modules/IsMobileDevice';
import _ from "lodash";

// MUI stuff
import Grid from "@material-ui/core/Grid";
//import Card from '@material-ui/core/Card';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import AvatarImage from '../Modules/AvatarImage';

import {ExtendDialogueMessage} from '../Routine/DiaryManual';

const styles = theme => ({
	desktopPadding: {
	 	minHeight: 40,
	},
	buttonCenter: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-around",
		alignItems: "center",
		marginBottom: 15,
    },
});

class TopicSuggestions extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = { 
            loading: true 
        };
    } 

    componentDidMount() {
        this._isMounted = true;
        this.setState(SetAgentStatus(this.props.user));
        let subscriptionStatus = CheckSubscriptionStatus(this.props.user);
        this.setState({nonSubscriber: subscriptionStatus.nonSubscriber});
        if(this.props.allwaysFree1===undefined){
            var cacheStoreDialogues = DataCacheRead('latestQualities')
            if(cacheStoreDialogues.cacheAvailable && ( !cacheStoreDialogues.isCacheExpired)) {
                //var days = cacheStoreDialogues.cacheData;
                this.setState({qualities:cacheStoreDialogues.cacheData})
                //console.log('cached load', cacheStoreDialogues, this.state)
                this.getLatestDialogues();
            }
            else{
                this.getLatestDialogues();
            }
        }
    }
    componentWillUnmount (){
        this._isMounted = false;
    }
    componentDidUpdate(prevProps){
        if(this._isMounted && prevProps?.user!== undefined && this.props!==undefined && this.props.user!==undefined && this.props.user.currentLeadershipQuality !== undefined){
            if(prevProps.user?.currentLeadershipQuality?.length > 1 && this.props.user.currentLeadershipQuality !== prevProps.user.currentLeadershipQuality){
                this.getLatestDialogues();
                //console.log('update 1', this.props.user.currentLeadershipQuality,  prevProps.user.currentLeadershipQuality)
            }
            else if(this.props.user.todaysLeadershipQuality !== prevProps.user.todaysLeadershipQuality){
                this.getLatestDialogues();
                //console.log('update 2', this.props.user.todaysLeadershipQuality, prevProps.user.todaysLeadershipQuality)
            }
        }
    }
    shouldComponentUpdate(nextProps, nextState) {  
        if (nextProps.rerender === false) {  
            //console.log('no rerender')
            return false;  
        }  
        //console.log(' rerender')
        return true;  
    }

    getLatestDialogues = () =>  {
        //////////////////////////////////////////////////////////////////
        if(this.props.user?.uid){
            this.unsubscribeDialogues = this.props.firebase
            .dialogues()
            .orderBy('createdAt', 'desc')
            .where('userId', '==', this.props.user.uid)
            .limit(14)
            .get().then(snapshot => {
                if (snapshot.size) {
                    let days = [];
                    let categories = {};
                    let qualities = {};
                    //var itemCounter = 0;
                    snapshot.forEach(doc =>{
                        var singleDialogue = doc.data();
                        //itemCounter+=1;
                        if(singleDialogue.contentSelection!==undefined ){
                            singleDialogue.contentSelection.map( item => {
                                if(item.leadership_category!== undefined){
                                    if(categories[item.leadership_category]===undefined){
                                        categories[item.leadership_category] =  (1 + (item.overlap_length || 0))
                                    }
                                    else{
                                        categories[item.leadership_category] +=  (1 + (item.overlap_length || 0))
                                    }
                                }
                                if(item.leadership_quality !== undefined){
                                    item.leadership_quality.map( quality => {
                                        if(quality !== this.props.user.currentLeadershipQuality && quality !== this.props.user.todaysLeadershipQuality){
                                            try {
                                                let doc = FindDictByKey(this.props.firebase, this.props.leadershipQualities, quality);
                                                if(doc.active && doc.program!==undefined && this.props.user.programs !== undefined && this.props.user.programs.includes(doc.program)){
                                                    //doc.program
                                                    if(this.props.engagementStats?.['leadership_quality']?.[quality]?.["points"] >= this.props.engagementBenchmarkQuality ){
                                                        qualities[quality] = (1)
                                                    }
                                                    else if(qualities[quality]===undefined){
                                                        qualities[quality] = (1 + (item.overlap_length || 0))
                                                    }
                                                    else{
                                                        qualities[quality] += (1 + (item.overlap_length || 0))
                                                    }
                                                }
                                            } catch (e) {
                                                //console.log('catch:', e);
                                            }
                                        }
                                        return true;
                                    })
                                }
                                return true;
                            })
                        }
                        days.push({ ...singleDialogue, uid: doc.id })
                    });
                    categories = _(categories)
                        .toPairs()
                        .orderBy([1], ['desc'])
                        .fromPairs()
                        .value()
                    qualities = _(qualities)
                        .toPairs()
                        .orderBy([1], ['desc'])
                        .fromPairs()
                        .value()
                    if(this._isMounted) {
                        this.setState({
                            days,
                            qualities,
                            categories,
                        });
                    }
                    //console.log('filtered', categories, qualities)
                    DataCacheWrite('latestQualities', qualities, 1800)
                } 
            });
        }
    }

    render() {
        //console.log(this.state, 'state')
        return (
            <div >
                <DialogSettingPlan 
                    defaultSet={this.props.defaultSet || undefined}
                    user={this.props.user}
                    handleOpen={this.state.dialogSettingPlanOpen}
                    premiumOnly={false}
                    purchaseSuccessFlag={() => {
                        if(this.props.handlerPurchase !== undefined){
                            this.props.handlerPurchase();
                        }
                        this.setState({nonSubscriber: false, premiumSubscriber: true})
                    }}
                    infoText={'Please subscribe to a plan in order to activate this topic.'}
                    purchaseSuccessHandler={() => {
                        if(this.props.handlerPurchase !== undefined){
                            this.props.handlerPurchase();
                        }
                        this.setState({dialogSettingPlanOpen: false, nonSubscriber: false, premiumSubscriber: true})
                    }}
                    handleClose={() => {
                        if(this.props.handlerPurchase !== undefined){
                            ///// JUST FOR TESTING the FLAG
                            //this.props.handlerPurchase();
                        }
                        this.setState({dialogSettingPlanOpen : false})
                    }}
                />
                {
                    this.chooseAgentCard()
                }
            </div>
            
        )
    }

    returnOptionCardUnfinished = () => {
        const {theme} = this.props;
        var bgColor = "white";
        try {
            bgColor = FindTextValueInDict(this.props.firebase, this.props.leadershipQualities, this.props.user.currentLeadershipQuality, 'backgroundColor');
        //console.log('try:', bgColor);
        } catch (e) {
            bgColor = theme.palette.secondary.light;
        //console.log('catch:', bgColor);
        }
        return(
            <BadgeCard
                backgroundColor ={"white"}
                image={undefined} 
                avatarImage = {
                        <AvatarImage type='icon' key='open' vertical-align="middle" image={<LiveHelpIcon fontSize={'large'} style={{margin:"30%"}} />} squareSize={"auto"} backgroundColor={bgColor} />
                    }
                title = {'More recommended modules:'}
                subtitle = {'More interactions with your AI Coach required.'}
                strongSubtitle = {false}
                onClick={ () => { this.props.history.push(ROUTES.ROUTINE) }}
                clickText= {'Start'}
            />
        )
    }

    returnOptionCardProposal = (selectedQuality, number=5, text=undefined) => {
        if(number>100) number = 100;
        else if(number < 0) number = 5;
        const {theme} = this.props;
        var bgColor = "white";
        var icon = undefined;
        var category = 'priority';
        //var program = 'default';
        try {
            let doc = FindDictByKey(this.props.firebase, this.props.leadershipQualities, selectedQuality);
            if(doc.backgroundColor !== undefined)  bgColor = doc.backgroundColor;
            if(doc.icon !== undefined)  icon = doc.icon;
            if(doc.category !== undefined)  category = doc.category;
            //if(doc.program !== undefined)  program = doc.program;
            //console.log('try:', bgColor);
        } catch (e) {
            bgColor = theme.palette.secondary.light;
            //console.log('catch:', bgColor);
        }
        var qualityText = FindTextValueInDict(this.props.firebase, this.props.leadershipQualities, selectedQuality);
        var overlapText = "{number: "+number + "%} overlap";
        var completionValue = (this.props.engagementStats?.['leadership_quality']?.[selectedQuality]?.["points"] || 0);
        var completion = Math.min(Math.round(completionValue / (this.props.engagementBenchmarkQuality || 400) * 100 ), 100);
        if (completion > 99){
            overlapText = 'Advanced Level'
        }
        else if(completion > 0){
            overlapText = "{number: "+completion + "%} completed";
        }
        //console.log('Topicsuggestions props', this.props)
        return(
            <BadgeCard
                backgroundColor ={"white"}
                image={undefined} 
                avatarImage = {
                    icon!== undefined && icon!== ""
                        ?
                            <AvatarImage key={selectedQuality} type='imageIcon' image={icon}  vertical-align="middle" squareSize={"auto"}  backgroundColor={bgColor} />
                        :
                            <AvatarImage key={selectedQuality} type='quality' vertical-align="middle" item={selectedQuality} squareSize={"auto"} backgroundColor={bgColor} />
                    }
                title = {text!==undefined && text!== "" ? text : overlapText}
                subtitle = {qualityText}
                inlineIcon = {
                    (freeTopics.includes(selectedQuality) || this.props.nonSubscriber===false || this.state.nonSubscriber===false)
                    ?   undefined
                    :   <ShoppingBasketIcon style={{ ...theme.inlineIconLeft , color:theme.palette.themeBlue}} />
                }
                strongSubtitle = {true}
                onClick={ () => { 
                    if(freeTopics.includes(selectedQuality) || this.props.nonSubscriber===false || this.state.nonSubscriber===false){
                        SetNewQuality(this.props, selectedQuality, category, qualityText);
                    }
                    else{
                        var subscriberStatus = CheckSubscriptionStatus(this.props.user);
                        if(subscriberStatus.nonSubscriber && !subscriberStatus.trialPeriod ){
                            this.setState({
                                dialogSettingPlanOpen: true
                            });
                            //console.log('npnosubsriber', subscriberStatus)
                        }
                        else{
                            SetNewQuality(this.props, selectedQuality, category, qualityText);
                        }
                    }
                } }
                clickText= {'Activate'}
            />
        )
    }

    chooseAgentCard = () => {
        const { theme } = this.props;
        var topicSuggestions = 0;
        var keys = [];
        if(this.state.qualities !== undefined){
            try{
                keys = Object.keys(this.state.qualities);
                topicSuggestions = keys.length;
            }
            catch (err) {
                topicSuggestions = 0;
            }
        }
        //console.log('suggestion', this.state)
        if ((this.props.proposedTopic !==undefined && this.props.proposedTopic!=="")){
            return(
                <div>
                    {this.returnOptionCardProposal(this.props.proposedTopic, 20, "Choose as main topic:")}
                </div>
            )
        }
        else if ((this.props.allwaysFree1!==undefined && this.props.allwaysFree1!=="")){
            return(
                <>
                    <div>
                        {this.returnOptionCardProposal(this.props.allwaysFree1, 20, "Choose as main topic:")}
                        {this.props.allwaysFree2!==undefined && this.props.allwaysFree2!=="" &&
                            this.returnOptionCardProposal(this.props.allwaysFree2, 20, "Choose as main topic:")
                        }
                    </div>
                    <p>
                        Activate the proposed topic as new main topic. 
                        The topic above is allways <strong>FREE</strong> and does not require a subscription!
                    </p>
                </>
            )
        }
        else if (topicSuggestions > 0 &&
            ((this.state.primingInTimeRange && ( this.state.primingStatus === 'DONE' || this.state.primingStatus === 'START'))
                || (this.state.reflectionInTimeRange && ( this.state.reflectionStatus === 'DONE' || this.state.reflectionStatus === 'START')))
            ){
            return(
                <>
                    <h4 style={theme.CardContent.titleText}>
                        { /*** <ControlPointIcon />&nbsp; */}
                        Suggested Quests
                    </h4>
                    <Grid container direction="row" spacing={3} style={{paddingBottom: 20}}>
                        <Grid item style={{width:"100%", minWidth:260}} xs>
                            {topicSuggestions>=1 && (parseInt(this.state.qualities[keys[0]]) > 30)
                                ?    this.returnOptionCardProposal(keys[0], parseInt(this.state.qualities[keys[0]]))
                                //:   this.returnOptionCardUnfinished()
                                :    this.returnOptionCardProposal(keys[0], parseInt(this.state.qualities[keys[0]])) 
                            }
                        </Grid>
                        <Grid item style={{width:"100%", minWidth:260}} xs>
                            {topicSuggestions>=2 && (parseInt(this.state.qualities[keys[1]]) > 30) 
                            &&
                                this.returnOptionCardProposal(keys[1], parseInt(this.state.qualities[keys[1]]))
                            }
                        </Grid>
                    </Grid>
                    <p style={theme.textSupportCenter}>
                        Add new quest &bull; Customize coaching plan
                    </p>
                </>
            )
        }
        else{
            //return this.returnOptionCardUnfinished()
            return '';
        } 
    }
}

export function SetNewQuality(providedProps, quality, category, qualityText="new topic", sisterQualities=undefined) {
    //console.log('set new qualit', providedProps.user, qualityText)
    if(qualityText === ""){
        qualityText = quality.replace('_',' ');
    }
    if(providedProps.user !== undefined && providedProps.firebase !== undefined){
        var textQuestion = "I want to improve: "+qualityText;
        if((providedProps.allwaysFree1!==undefined && providedProps.allwaysFree1 !=="")){
            textQuestion = "I want to improve: "+qualityText;
        }
        ExtendDialogueMessage(providedProps.firebase, providedProps.user, textQuestion, "What are you currently interested in?", 'utter_reflection_topic', '', 'profile');
        var newToday = providedProps.user.currentLeadershipQuality;
        if(newToday===quality){
            newToday = "";
        }
        ///// First get existing growth path
        var growthPath = [];
        if(providedProps.user.additionalLeadershipQuality1 !== undefined && providedProps.user.additionalLeadershipQuality1 !== ""){
            growthPath = [
                providedProps.user.currentLeadershipQuality,
                providedProps.user.additionalLeadershipQuality1,
                providedProps.user.additionalLeadershipQuality2,
                providedProps.user.additionalLeadershipQuality3,
                providedProps.user.additionalLeadershipQuality4,
                providedProps.user.additionalLeadershipQuality5,
                providedProps.user.additionalLeadershipQuality6
            ];
        }
        else{
            growthPath = [
                providedProps.user.currentLeadershipQuality
            ];
        }
        if(sisterQualities?.length>0){
            growthPath = sisterQualities.concat(growthPath)
        }
        //console.log(growthPath, sisterQualities)
        growthPath = [...new Set(growthPath)];
        const index = growthPath.indexOf(quality);
        if (index > -1) {
            growthPath.splice(index, 1);
        }
        growthPath.unshift(quality);
        growthPath = growthPath.filter(function( element ) {
            return (element !== undefined && element !== '');
        });
        //console.log('growthPath', growthPath)
        ///// Completed udpated growth path
        var updateSet = {
            todaysLeadershipQuality: "",
            currentLeadershipQuality: quality,
            currentCategory: category,
        }
        providedProps.firebase.userCollection(providedProps.user.uid, 'topics').add({
            ...updateSet,
            createdAt :  providedProps.firebase.fieldValue.serverTimestamp(),
            origin :'suggestion'
        });
        if(growthPath.length > 0){
            updateSet['additionalLeadershipQuality1'] = (growthPath[1] !== undefined && growthPath[1] !== "") ? growthPath[1] : providedProps.firebase.fieldValue.delete();
            updateSet['additionalLeadershipQuality2'] = (growthPath[2] !== undefined && growthPath[2] !== "") ? growthPath[2] : providedProps.firebase.fieldValue.delete();
            updateSet['additionalLeadershipQuality3'] = (growthPath[3] !== undefined && growthPath[3] !== "") ? growthPath[3] : providedProps.firebase.fieldValue.delete();
            updateSet['additionalLeadershipQuality4'] = (growthPath[4] !== undefined && growthPath[4] !== "") ? growthPath[4] : providedProps.firebase.fieldValue.delete();
            updateSet['additionalLeadershipQuality5'] = (growthPath[5] !== undefined && growthPath[5] !== "") ? growthPath[5] : providedProps.firebase.fieldValue.delete();
            updateSet['additionalLeadershipQuality6'] = (growthPath[6] !== undefined && growthPath[6] !== "") ? growthPath[6] : providedProps.firebase.fieldValue.delete();
        }
        AddAnalytics('goal_completion', 'GoalChangeTopic');
        //console.log("newtoday", newToday, updateSet)
        providedProps.firebase.user(providedProps.user.uid).update( updateSet )
        .then( () => {
            if(providedProps.handlerContinue!== undefined){
                providedProps.handlerContinue();
            }
            else{
                //providedProps.history?.push(ROUTES.HOME);
            }
        })
        if(providedProps.handlerCloseAll !== undefined ) { 
            providedProps.handlerCloseAll() 
        }
    }
}

export default compose(
    withFirebase,
    withRouter,
    withStyles(styles, { withTheme: true})
)(TopicSuggestions);
