import React, { Component } from 'react';
import { compose } from 'recompose';
import { AuthUserContext } from '../Session';
import LanguageManager from '../../constants/languages';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import badgeStart from "../../images/Badge_XP_congrats.png";
import {Textfit} from 'react-textfit';
import Button from "@material-ui/core/Button";

const styles = theme => ({
    grow: {
      flexGrow: 1,
    },
});

/**
 * @class BadgeCard
 * @extends {React.Component}
 * @description A card component that displays content with optional badge and hover effects
 * 
 * @param {Object} props - Component props
 * @param {string} [props.clickText] - Text to display when card is clickable
 * @param {React.ReactNode} [props.inlineIcon] - Icon to display inline with click text
 * @param {Function} [props.onClick] - Click handler function
 * @param {Function} [props.buttonClick] - Alternative click handler function
 * @param {boolean} [props.shadowText] - Whether to apply text shadow effect
 * @param {Object} [props.user] - User object containing preferences
 * @param {Object} [props.user.features] - User feature settings
 * @param {string} [props.user.features.language] - User's preferred language
 * @param {string} [props.subtitle] - Subtitle text to display
 * @param {boolean} [props.strongSubtitle] - Whether to make subtitle bold
 * @param {Object} theme - Material-UI theme object
 */

class BadgeCard extends Component {
    static contextType = AuthUserContext;
    constructor(props) {
        super(props)
        this.state = {
            readyTitle: false,
            readySubtitle: false
        }
    }

    render() {
        const {theme} = this.props;
        const {backgroundColor, strongSubtitle, onClick, clickText, button, buttonIcon} = this.props;
        //console.log('badgecard',this.props)
        var {title, subtitle} = this.props;
        const {image, avatarImage } = this.props;
        if(title !== undefined && title!=="" && title.length>30){
            title = title.substring(0,27) + "..."
        }
        if(subtitle !== undefined && subtitle!=="" && subtitle.length>80){
            subtitle = subtitle.substring(0,77) + "..."
        }
        return(
            <Card 
                style={{ 
                    ...(this.props.backgroundColor === 'transparent' ? theme.CardDialogueButtonTransparent : theme.CardDialogueButton),
                    width: '100%',
                    minWidth: 'calc( 200px + (22vw / 2) )',
                    backgroundColor: this.state.hover ? theme.palette.themeAltGold : this.props.backgroundColor === 'white' ? theme.palette.backPrimary : this.props.backgroundColor,
                    backgroundImage: this.props.backgroundImage?.length > 0 ? `url(${this.props.backgroundImage})` : undefined,
                    backgroundPosition: this.props.backgroundImage?.length > 0 ? 'center' : undefined,
                    backgroundSize:  this.props.backgroundImage?.length > 0 ? "cover" : undefined,
                    backgroundRepeat:  this.props.backgroundImage?.length > 0 ? "no-repeat" : undefined,
                    color: this.props.color?.length > 0 ? this.props.color : theme.palette.themeText,
                    height: 'calc(100% - 16px)',
                    maxHeight: 120
                }}
                onClick={onClick!== undefined && (this.props.buttonClick===undefined || this.props.buttonClick === "") ? this.props.onClick : void(0) }
                onMouseEnter={(onClick!== undefined || this.props.buttonClick===undefined) ? () => {this.setState({hover: true})} : void(0)}
                onMouseLeave={(onClick!== undefined || this.props.buttonClick===undefined) ? () => {this.setState({hover: false})} : void(0)}
                //raised={this.state.hover || false}
                >
                {clickText !== undefined && clickText !== ""
                    &&          
                        <div style={{
                            position:'relative',
                            fontSize: 'small',
                            top: 4,
                            right: 10,
                            height:0,
                            textAlign: 'right',
                            zIndex: 2,
                            width: '100%',
                            color: theme.palette.secondary.main
                            }}
                        >
                            {this.props.inlineIcon !== undefined
                            &&
                                this.props.inlineIcon
                            }
                            <LanguageManager
                                text={clickText} 
                                type='button'
                                language={this.context?.features?.language || this.props.user?.features?.language}
                            />
                        </div>
                }
                <Grid container direction="row" justifyContent="space-between" alignItems="center" 
                    style={{
                        height: '100%',
                        maxHeight: 120,
                        //background: this.props.shadowText ? "linear-gradient(to right, rgba(0, 0, 0, 0) 24%, rgba(0, 0, 0, 0.3) 24%)" : 'transparent'
                    }}
                >
                    <Grid item xs={3} 
                        style={{
                            height: '100%',
                            aspectRatio: '1/1',
                            maxHeight: 120
                        }
                    }>
                        <Grid container direction="row" justifyContent="space-between" alignItems="center" 
                            style={{
                                height: '100%',
                                maxHeight: 120,
                                display: 'flex',
                                justifyContent: 'center',
                                minHeight: backgroundColor === 'transparent' ? theme.CardDialogueButtonTransparent.minHeight : theme.CardDialogueButton.minHeight ,
                                //maxHeight: backgroundColor === 'transparent' ? theme.CardDialogueButtonTransparent.maxHeight : theme.CardDialogueButton.maxHeight ,
                                maxWidth: backgroundColor === 'transparent' ? theme.CardDialogueButtonTransparent.maxHeight : theme.CardDialogueButton.maxHeight,
                                //maxHeight: '100%',
                                //maxWidth: '100%',
                            }}>
                            <Grid item style={{height: '100%', position: 'relative'}}>
                            {image!== undefined && image !== ""
                                ?
                                    <img 
                                        src={image}
                                        id={'badgeIconImage'} 
                                        style={{
                                            maxWidth:'100%',
                                            maxHeight: 120,
                                            height: 'auto',
                                            verticalAlign: 'middle',
                                            borderRadius: theme.borderRadius,
                                            webKitFilter:( this.props.avatarGreyOut ? 'grayscale(1)' : 'grayscale(0)'), /* Google Chrome, Safari 6+ & Opera 15+ */
                                            filter: (this.props.avatarGreyOut ? 'grayscale(1)' : 'grayscale(0)'), /* Microsoft Edge and Firefox 35+ */
                                        }} 
                                        alt={title} />
                                :   avatarImage !== undefined && avatarImage !== ""
                                        ?
                                            <div
                                                id={'badgeIconAvatar'} 
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    maxWidth: 150,
                                                    maxHeight: 120,
                                                    height: '100%',
                                                    verticalAlign: 'middle',
                                                    textAlign: 'center',
                                                    margin: 'auto',
                                                    borderRadius: theme.borderRadius,
                                                    webKitFilter:( this.props.avatarGreyOut ? 'grayscale(1)' : 'grayscale(0)'), /* Google Chrome, Safari 6+ & Opera 15+ */
                                                    filter: (this.props.avatarGreyOut ? 'grayscale(1)' : 'grayscale(0)'), /* Microsoft Edge and Firefox 35+ */
                                                }} 
                                            >
                                                {
                                                React.cloneElement(avatarImage, { testparam: 'nothing', fitavatar: 'fit' })
                                                }
                                            </div>
                                        :   this.props.backgroundImage?.length > 0
                                            ?   ""
                                            :
                                                <img 
                                                    src={badgeStart}
                                                    id={'badgeIconImg'} 
                                                    style={{
                                                        maxWidth: '100%',
                                                        maxHeight: 120,
                                                        height: 'auto',
                                                        verticalAlign: 'middle',
                                                        borderRadius: theme.borderRadius,
                                                        webKitFilter:( this.props.avatarGreyOut ? 'grayscale(1)' : 'grayscale(0)'), /* Google Chrome, Safari 6+ & Opera 15+ */
                                                        filter: (this.props.avatarGreyOut ? 'grayscale(1)' : 'grayscale(0)'), /* Microsoft Edge and Firefox 35+ */
                                                    }} 
                                                    alt={title} />
                            }
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={((button !== undefined && button !== "") || buttonIcon?.length > 3) ? 5 : 9}>
                        <div style={{paddingLeft : 10, paddingRight : 10, paddingTop: 10, paddingBottom: 10, textAlign: "left"}}>
                            {title !== undefined && title !== ""
                                ?   strongSubtitle
                                    ?
                                        <span style={{
                                            ...theme.CardContentItem.TextSubTitle,
                                            color: this.props.color?.length > 0 ? this.props.color : this.props.shadowText? theme.palette.black : undefined,
                                            textShadow: this.props.shadowText ? " 0.07em 0 white,  0 0.07em white, -0.07em 0 white, 0 -0.07em white" : undefined
                                            }}
                                        > 
                                            <LanguageManager
                                                limit={this.props.limit}
                                                text={title} 
                                                type='button'
                                                language={this.context?.features?.language || this.props.user?.features?.language}
                                            />
                                            <br />
                                        </span>
                                    :
                                        //<strong style={{wordWrap:'break-word'}}>
                                        <strong style={{
                                            minHeight: 1,
                                            color: this.props.shadowText? theme.palette.black : undefined,
                                            textShadow: this.props.shadowText ? " 0.07em 0 white,  0 0.07em white, -0.07em 0 white, 0 -0.07em white" : undefined
                                        }}>
                                            {this.state.readyTitle
                                            ?   title
                                            :
                                                <Textfit
                                                    style={{
                                                        height: ((subtitle === undefined || subtitle === "") ? "calc(70% + 1px)" : "calc(30% + 1px)"), 
                                                        width: (subtitle === undefined || clickText === undefined)? "calc(90% + 1px)" : "calc(70% + 1px)"
                                                    }}
                                                    min={1}
                                                    mode="single" 
                                                    forceSingleModeWidth={false}
                                                    //onReady={()=> { this.setState({readyTitle: true}); console.log("ready")}}
                                                >
                                                    <LanguageManager
                                                        limit={this.props.limit}
                                                        text={title} 
                                                        type='button'
                                                        language={this.context?.features?.language || this.props.user?.features?.language}
                                                    />
                                                </Textfit>
                                            }
                                        </strong>
                                :   ""
                            }
                            {subtitle !== undefined && subtitle !== ""
                                ?   strongSubtitle
                                    ?
                                        <strong style={{
                                            wordWrap:'break-word', 
                                            minHeight: 1,
                                            color: this.props.shadowText? theme.palette.white : undefined,
                                            textShadow: this.props.shadowText ? " 0.07em 0 black,  0 0.07em black, -0.07em 0 black, 0 -0.07em black" : undefined
                                        }}>
                                            {this.state.readySubtitle
                                            ?   subtitle
                                            :
                                                <Textfit 
                                                    style={{ 
                                                        height: "calc(50% + 1px)", 
                                                        width: "calc(90% + 1px)"
                                                    }} 
                                                    mode="single" 
                                                    forceSingleModeWidth={false}
                                                    min={1}
                                                    //onReady={()=> { this.setState({readySubtitle: true}); console.log("ready")}}
                                                >
                                                    <LanguageManager
                                                        limit={this.props.limit}
                                                        text={subtitle} 
                                                        type='tutorial'
                                                        language={this.context?.features?.language || this.props.user?.features?.language}
                                                    />
                                                </Textfit>
                                            }
                                        </strong>
                                    :
                                        <span style={{
                                            ...theme.CardContentItem.TextSubTitle,
                                            color: this.props.color?.length > 0 ? this.props.color : this.props.shadowText ? theme.palette.black : undefined,
                                            textShadow: this.props.shadowText ? "0.07em 0 white, 0 0.07em white, -0.07em 0 white, 0 -0.07em white" : undefined,
                                            fontWeight: this.props.shadowText ? 'bold' : 'normal'
                                            }}
                                        >
                                            <LanguageManager
                                                limit={this.props.limit}
                                                text={subtitle} 
                                                type='tutorial'
                                                language={this.context?.features?.language || this.props.user?.features?.language}
                                            />
                                        </span>
                                :   ""
                            }
                        </div>
                    </Grid>
                    {buttonIcon?.length > 3
                    ?
                        <Grid item xs={4} style={{textAlign: 'right'}}>
                            <img 
                                src={buttonIcon}
                                id={'badgeIconPlus'} 
                                style={{
                                    maxWidth: '100%',
                                    maxHeight: 70,
                                    height: 'auto',
                                    verticalAlign: 'middle',
                                    borderRadius: theme.borderRadius,
                                    webKitFilter:( this.props.avatarGreyOut ? 'grayscale(1)' : 'grayscale(0)'), /* Google Chrome, Safari 6+ & Opera 15+ */
                                    filter: (this.props.avatarGreyOut ? 'grayscale(1)' : 'grayscale(0)'), /* Microsoft Edge and Firefox 35+ */
                                }} 
                                alt={title} />
                        </Grid>
                    :   button !== undefined && button !== ""
                        ?   this.props.buttonClick !== undefined && this.props.buttonClick !== "" 
                            ?
                                <Grid item xs={4}>
                                    <Button style={{...theme.buttonRight, marginRight:10}} 
                                        variant="contained"
                                        onClick={ this.props.buttonClick  }
                                    >
                                        {typeof button === 'string'
                                            ?   <LanguageManager
                                                    text={button} 
                                                    type='button'
                                                    language={this.context?.features?.language || this.props.user?.features?.language}
                                                />
                                            :   button
                                        }
                                    </Button>
                                </Grid>
                            :
                                <Grid item xs={4}>
                                    <Button style={{...theme.buttonRight, marginRight:10}} 
                                        //variant="contained"
                                    >
                                        {typeof button === 'string'
                                            ? <LanguageManager
                                                    text={button} 
                                                    type='button'
                                                    language={this.context?.features?.language || this.props.user?.features?.language}
                                                />
                                            :   button
                                        }
                                    </Button>
                                </Grid>
                        :   ""
                    }
                </Grid>
            </Card>
        )
    }

    
}

export default compose(
    withStyles(styles, { withTheme: true })
)(BadgeCard);