import React, { Component } from 'react';
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';

import { AuthUserContext } from '../../Session';
import { withFirebase } from '../../Firebase';

import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';

import Grid from '@material-ui/core/Grid';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Switch from "react-switch";

import NotInterestedIcon from '@material-ui/icons/NotInterested';
import IconButton from '@material-ui/core/IconButton';
//import EditIcon from '@material-ui/icons/Edit';
import Tooltip from '@material-ui/core/Tooltip';
import BorderColorTwoToneIcon from '@material-ui/icons/BorderColorTwoTone';

const styles = theme => ({
    grow: {
        flexGrow: 1,
    },
});


class SettingContentActive extends Component {
    static contextType = AuthUserContext;
    _isMounted = false;

    constructor(props) {
        super(props);
        //console.log('constructor')
        this.state = {
            openSetting: false,
            // default value for each param
            defaultValue: false,
        };
    }

    componentDidMount(){
        this._isMounted = true;
        this.props.params?.map((p) => {
            if(this.props.content[p]!== undefined){
                return this.setState({[p] : this.props.content[p]});
            }
            else{
                return this.setState({[p] : this.state.defaultValue});
            }
        })
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    componentDidUpdate(prevProps){
        if(this._isMounted){
            this.props.params?.map((p) => {
                if(this.props.content[p] !== prevProps.content[p]){
                    return this.setState({[p] : this.props.content[p]});
                }
            })
        }
    }

    // reopen Handling
    handleSettingOpen = () => {
        this.props.params?.map((p) => {
            if(this.props.content[p]!== undefined){
                return this.setState({[p] : this.props.content[p]});
            }
            else{
                return this.setState({[p] : this.state.defaultValue});
            }
        })
        this.setState({
            openSetting: true,
        });
    }
    handleSettingChange = (param, event) => {
        if(event.target.checked !== undefined){
            //console.log('before', param, event, event.target.checked)
            this.setState({
                [param]: event.target.checked
            });
        }
        //console.log('event', event.target.checked)
    }
    handleSettingChangeSave = (event, param, actionEvent) => {
        //event.stopPropagation(); this event failed....
        if (this.props.access === undefined || this.props.access === true) {
            if(actionEvent.target.checked !== undefined){
                //console.log('save soon', param, actionEvent.target.checked, this.state)
                this.setState({
                    [param]: (actionEvent.target.checked)
                }, () => this.handleSettingSubmit(param))
            }
        }
    }
    handleSettingClose = () => {
        this.setState({
            openSetting: false,
        });
        this.props.params.map((p) => {
            //console.log('parmas ', p)
            //console.log('parmas stateclose', this.state[p])
            //console.log('parmas props close', this.props.content[p])
            if(this.state[p] !== this.props.content[p] && this.state[p] !== undefined){
                this.setState({[p] : this.state.defaultValue});
            }
            return ''
        })
    }
    handleSettingSubmit = (paramFocus = undefined ) => {
        //console.log('state', this.state, paramFocus)
        this.props.params.map((p) => {
            if(paramFocus === undefined || paramFocus === p){
                //console.log('p', this.state)
                if(this.state[p] !== null && this.state[p] !== "" && this.state[p] !== undefined && this.state[p] !== this.props.content[p] ){
                    if(this.props.setUpdate){
                        this.props.dbUpdateLink.set({
                            [p]: this.state[p]
                        }, {merge:true});
                        //console.log('updatefirst', this.state[p], p)
                    }
                    else{
                        var updateSet = { [p]: this.state[p] };
                        if(this.props.updateExtraChange !== undefined && typeof this.props.updateExtraChange === 'object'){
                            Object.assign(updateSet, this.props.updateExtraChange)
                        }
                        if(this.props.dbUpdateLink !== undefined){
                            this.props.dbUpdateLink.set(updateSet, {merge:true});
                        }
                        //console.log('updateSet', updateSet)
                        if(this.props.updateState !== undefined){
                            this.props.updateState(updateSet);
                        }
                    }
                }
            }
            return '';
        })

        this.handleSettingClose();
    }

    render() {
        const { content, params, title, labels } = this.props;
        const { theme, classes } = this.props;
        //console.log('updated props', this.props)

        return (
            content
                ?
                <div className={classes.grow}>
                <Card 
                    style={{
                        ...theme.cardAdminSettings, 
                        backgroundColor: ((this.props.access === false) ? 'transparent': theme.cardAdminSettings.backgroundColor), 
                        cursor: ((this.props.access === false) ? 'none' : 'pointer'),
                        minWidth: 120
                    }} 
                >
                    <Grid container>
                        <Grid item xs={9} >
                            <h6><a to="#" onClick={this.handleSettingOpen}>{title}</a>
                            {this.props.mandatory 
                                &&  <strong style={{color:theme.palette.themeRed}}>&nbsp;*</strong>
                            }
                            {this.props.mandatory===false
                                &&  <span style={{fontSize: 'small', fontWeight:'normal'}}>&nbsp;(optional)</span>
                            }
                            </h6>
                        </Grid>
                        <Grid item xs={1}>  </Grid>
                        <Grid item xs={2} style={{textAlign: 'right', marginTop: -10}}>
                            {(this.props.access === undefined || this.props.access === true) 
                            ?
                                <Tooltip title={'Edit/Change'}>
                                    <IconButton onClick={this.handleSettingOpen}> 
                                        <BorderColorTwoToneIcon fontSize="small" style={{color:"red"}}/>
                                    </IconButton>
                                </Tooltip>
                            :   <span style={{fontSize: 'small', fontWeight:'normal'}}>Read-only</span>
                            }
                        </Grid>
                    </Grid>
                    <Grid container 
                        direction="row"
                        //justifyContent="space-between"
                        alignItems="center"
                        style={{width: `100%`}} 
                    >
                    {params.map((p, index) => (
                        <React.Fragment key={"current_"+p}>
                            {false &&
                                <Grid item xs={1}>
                                    {content[p]!== undefined
                                        ?   content[p] === (this.props.showInverted ? false : true)
                                            ?   <CheckBoxIcon style={{ color:"green" }} 
                                                    onClick = {(event) => this.handleSettingChangeSave(event, p, {target: {checked: this.state[p] === true ? false : true}})}
                                                />
                                            :   <CheckBoxOutlineBlankIcon style={{ color: this.props.access === false ? "grey" : "red" }} 
                                                    onClick = {(event) => this.handleSettingChangeSave(event, p, {target: {checked: this.state[p] === true ? false : true}})}
                                                />
                                        :   this.props.showInverted
                                            ?   
                                                <CheckBoxIcon style={{ color:"green" }} 
                                                    onClick = {(event) => this.handleSettingChangeSave(event, p, {target: {checked: this.state[p] === true ? false : true}})}
                                                />
                                            :   <CheckBoxOutlineBlankIcon style={{ color:"grey" }} 
                                                    onClick = {(event) => this.handleSettingChangeSave(event, p, {target: {checked: this.state[p] === true ? false : true}})}
                                                />
                                    }
                                </Grid>
                            }
                            <Grid item xs={9} style={{textAlign: 'left'}}>
                                <span>{
                                    labels!==undefined
                                    ?   labels[index]
                                    :   this.props.noLabel == true
                                        ?   ""
                                        :   p
                                }
                                </span>
                            </Grid>
                            <Grid item xs={3} style={{textAlign: 'right'}}>
                                <Switch
                                    key={"change_"+p}
                                    onColor={theme.palette.themeAlertGreen}
                                    offColor={p === 'active'
                                        ?   theme.palette.themeRed
                                        :   undefined
                                    }
                                    disabled={this.props.access === false ? true : false}
                                    checked={this.state[p] ===  undefined && this.props.showInverted 
                                        ?   true 
                                        :   this.state[p] === (this.props.showInverted ? false : true)}
                                    onChange={(event) => this.handleSettingChangeSave(event, p, {target: {checked: (this.state[p] === true ? false : true)}})}
                                    uncheckedIcon={p === 'active'
                                        ?   <NotInterestedIcon style={{color: "white", marginLeft: 4, marginTop: 2}}/>
                                        :   undefined
                                    }
                                    value={content[p]}
                                />
                            </Grid>
                            {this.props.infoText?.[index]?.length > 2
                                &&  <Grid item xs={12}>
                                        <p style={theme.textSupport}>{this.props.infoText[index]}</p>
                                    </Grid>
                            }
                        </React.Fragment>
                    ))}
                    </Grid>
                </Card>
                <Dialog
                    open={this.state.openSetting}
                    onClose={this.handleSettingClose}
                    fullWidth
                    maxWidth="sm"
                >
                    <DialogTitle>Change Setting</DialogTitle>
                    <form>
                    <DialogContent>
                        <Grid container 
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            style={{width: `100%`}} 
                        >
                            { params.map((p, index) => (
                                <React.Fragment key={"change_"+p}> 
                                    <Grid item xs={8} >
                                        {labels!==undefined
                                            ? (labels[index] + ' => ')
                                            : (p + ' => ')
                                        } 
                                    </Grid>
                                    <Grid item xs={4} >
                                        <Switch
                                            key={"change_"+p}
                                            onColor={theme.palette.themeAlertGreen}
                                            disabled={this.props.access === false ? true : false}
                                            checked={this.props.showInverted 
                                                ?   (this.state[p] === true ? false : true) 
                                                :   (this.state[p] || false)
                                            }
                                            onChange={(e) => this.handleSettingChange(p, {target: {checked: (this.state[p] === true ? false : true)}})}
                                            value={"change_"+p}
                                            //color="secondary"
                                            //inputProps={{ 'aria-label': 'primary checkbox' }}
                                        />
                                    </Grid>
                                </React.Fragment>
                            )) }
                        </Grid>
                        {this.state.errorMessage !== '' && this.state.errorMessage !== undefined
                            ?
                                <p style={theme.textErrorCenterPadding}>
                                    {this.state.errorMessage}
                                </p>
                            :   <p> </p>
                        }
                    </DialogContent>
                    </form>
                    <DialogActions>
                        <Button onClick={this.handleSettingClose}>
                            Cancel
                        </Button>
                        <Button onClick={() => this.handleSettingSubmit(undefined)}>
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
                </div>
            : <div> </div>
                    
        );
    }
}

SettingContentActive.propTypes = {
    classes: PropTypes.object.isRequired
};

export default compose(
    withStyles(styles, { withTheme: true }),
    withFirebase,
)(SettingContentActive);
