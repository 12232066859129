import React, { Component } from 'react';
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';
import LanguageManager from '../../constants/languages';
import { AuthUserContext } from '../Session';
import { withFirebase } from '../Firebase';

import Card from '@material-ui/core/Card';
import List from '@material-ui/core/List';
import Switch from "react-switch";
import Brightness4Icon from '@material-ui/icons/Brightness4';

const styles = theme => ({
    grow: {
        flexGrow: 1,
    },
});


class SettingAppColorMode extends Component {
    static contextType = AuthUserContext;
    constructor(props) {
        super(props);

        this.state = {
            openSetting: false,
        };
    }

    render() {
        //const { user } = this.props;
        const { theme, classes } = this.props;
        //console.log("window params", window)
        return (
            <div className={classes.grow}>
                <Card style={theme.cardSettings}>
                    <List style={theme.textSettings}>
                        <span style={{ float: 'right'}}>
                            <Switch
                                checked={this.state.darkmodeButton || this.props.user?.features?.darkmode === true || false}
                                onColor={theme.palette.themeBlue}
                                onChange={(c, event, id) => {
                                    console.log('c', c)
                                    if(c){
                                        this.setState({
                                            darkmodeButton: true
                                        })
                                        this.props.firebase.user(this.props.user.uid).update({
                                            ['features.darkmode'] : true
                                        })
                                        .then( () => {
                                            if(window.StatusBar !== undefined){
                                                window.StatusBar.overlaysWebView(false);
                                                window.StatusBar.backgroundColorByHexString(theme.palette.backPrimaryInv);
                                                if(theme.palette.darkMode) window.StatusBar.styleDefault();
                                                else window.StatusBar.styleLightContent();
                                                window.NavigationBar?.backgroundColorByHexString(theme.palette.backTertiaryInv, true);
                                            }
                                        });
                                    }
                                    else{
                                        this.setState({
                                            darkmodeButton: false
                                        })
                                        this.props.firebase.user(this.props.user?.uid).update({
                                            ['features.darkmode'] : false,
                                        })
                                        .then( () => {
                                            if(window.StatusBar !== undefined){
                                                window.StatusBar.overlaysWebView(false);
                                                window.StatusBar.backgroundColorByHexString(theme.palette.backPrimaryInv);
                                                if(theme.palette.darkMode) window.StatusBar.styleDefault();
                                                else window.StatusBar.styleLightContent();
                                                window.NavigationBar?.backgroundColorByHexString(theme.palette.backTertiaryInv, true);
                                            }
                                        });
                                    }
                                }}
                            />
                        </span>
                        <span style={theme.textSettings.settingsTextDescription}>
                            <Brightness4Icon style= {theme.inlineIconLeft}/>
                            <LanguageManager
                                text={"Dark Mode"}
                                type='button'
                                language={this.context?.features?.language}
                            />
                            :
                        </span>
                        <div style={{width: "60%"}}>
                            <p style={theme.textSupportGrey}>
                                <LanguageManager
                                    text={"Change the app to dark or light color mode."}
                                    type='tutorial'
                                    language={this.context?.features?.language}
                                />
                            </p>
                        </div>
                    </List>
                </Card>
            </div>
                
        );
    }
}


export default compose(
    withStyles(styles, { withTheme: true }),
    withFirebase
)(SettingAppColorMode);
