import React, { Component } from 'react';
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';

import { withFirebase } from '../Firebase';
import { AuthUserContext } from '../Session';

//UI

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionActions';
import AccordionSummary from '@material-ui/core/AccordionSummary'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
//popup
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary';
import SchoolIcon from '@material-ui/icons/School';


import RoleOverview from '../Profile/RoleOverview';
import FlareIcon from '@material-ui/icons/Flare';
import WorkIcon from '@material-ui/icons/Work';
// Modules for parameter change
import SettingContentBase from '../Admin/Modules/SettingContentBase';
import SettingContentActive from '../Admin/Modules/SettingContentActive';
import SettingContentSelectCombi from '../Admin/Modules/SettingContentSelectCombi';
//import SettingContentArray from '../Admin/Modules/SettingContentArray';
//import SettingContentImage from '../Admin/Modules/SettingContentImage';
//import SettingContentRichText from '../Admin/Modules/SettingContentRichText';
//import SettingContentArraySelect from '../Admin/Modules/SettingContentArraySelect';
import SettingContentArrayCheckbox from '../Admin/Modules/SettingContentArrayCheckbox';
//import SettingContentSelect from '../Admin/Modules/SettingContentSelect';
import { FindTextValueInDict } from '../Modules/LoadCategoriesAndQualities';



const styles = theme => ({
    grow: {
        flexGrow: 1, 
    },
})

class CoachUserSetting extends Component {
    static contextType = AuthUserContext;
    _isMounted = false;
    constructor(props) {
        super(props);
        //console.log('probs in JournalWeek ',this.probs);
        this.state = {
            loading: false,
            loadingText: 'loading users',
            user: null,
        };
    }

    componentDidMount() {
        this._isMounted = true;
        this.setState({ loading: true });
        this.loadUserPrograms();

        var availableCoachPrograms = ['default'];
        var defaultProgramName = "'Essentials' program of Rocky.ai";
        var availableCoachProgramsText = [defaultProgramName];
        if (this.props.leadershipCategories?.length > 0){
            this.props.leadershipCategories.map(category => {
                //console.log('category',category)
                var text = category.text;
                var item = category.program;
                if(item === 'default' || this.context.programs?.includes(item)){
                    if(!availableCoachPrograms.includes(item)){
                        if(item === 'default'){
                            text = defaultProgramName;
                            availableCoachPrograms.unshift(item);
                            availableCoachProgramsText.unshift(text);
                        }
                        else{
                            availableCoachPrograms.push(item);
                            availableCoachProgramsText.push(text);
                        }
                    }
                }
                return true;
            })
            this.setState({
                availableCoachPrograms,
                availableCoachProgramsText,
            })
        }
        else if(this.context.programs!==undefined && this.context.programs.length>0){
            availableCoachPrograms = [];
            availableCoachProgramsText = [];
            this.context.programs.map(item => {
                var text = item;
                if(item === 'default'){
                    text = defaultProgramName;
                    availableCoachPrograms.unshift(item);
                    availableCoachProgramsText.unshift(text);
                }
                else{
                    text = FindTextValueInDict(this.props.firebase, this.props.leadershipCategories, item);
                    availableCoachPrograms.push(item);
                    availableCoachProgramsText.push(text);
                }
                return true;
            })
            this.setState({
                availableCoachPrograms,
                availableCoachProgramsText,
            })
        }
        else{
            this.setState({
                availableCoachPrograms,
                availableCoachProgramsText,
            })
        }
    }

    loadUserPrograms(){
        if(this.unsubscribe !== undefined){
            this.unsubscribe();
        }
        this.unsubscribe = this.props.firebase
        .user(this.props.userId)
        .onSnapshot(snapshot => {
            var userData=snapshot.data();
            if(this._isMounted ){
                this.setState({
                    user: userData,
                    userId: this.props.userId,
                    loading: false,
                });
            }
            if(true || this.state.leadershipQualitiesText===undefined){
                this.filterQualities(userData.programs);
                this.filterCategories(userData.programs);
            }
        });
        //console.log('loading the user')
    }

    filterCategories(userPrograms){
        var myArray = [];
        var myArrayText = [];
        //console.log('props', this.props)
        this.props.leadershipCategories.map((item, index) => {
            if(userPrograms?.includes(item.program) && item.active){
                myArray.push(item.value);
                myArrayText.push("("+ (item.program==="default"? "Rocky" : "Premium")  + ") "+ item.text);
            };
            this.setState({
                leadershipCategoriesValues: myArray,
                leadershipCategoriesText: myArrayText
            });
            return true
        })
    }
    filterQualities(userPrograms){
        var myArray = [];
        var myArrayText = [];
        this.props.leadershipQualities.map((item, index) => {
            if(userPrograms?.includes(item.program) && item.active){
                myArray.push(item.value);
                var categoryName = item.category;
                categoryName = FindTextValueInDict(this.props.firebase, this.props.leadershipCategories, item.category);
                myArrayText.push("(" + categoryName + ") " + item.text);
            };
            this.setState({
                leadershipQualitiesValues: myArray,
                leadershipQualitiesText: myArrayText
            });
            return true
        })
    }

    componentWillUnmount() {
        this._isMounted = false;
        this.unsubscribe();
        this.setState({ loading: false, user: null });
    }

    changeUserRole(newRole){
        let roles = [...this.state.user.roles];
        var index = roles.indexOf("COACHEE")
        if (index > -1){
            roles.splice(index, 1)
        }
        index = roles.indexOf("default")
        if (index > -1){
            roles.splice(index, 1)
        }
        index = roles.indexOf("OBSERVE")
        if (index > -1){
            roles.splice(index, 1)
        }
        roles.push(newRole)
        //console.log(this.state.user,this.props.userId )
        this.props.firebase.user(this.props.userId).update({roles: roles})
    }

    render(){
        const {theme} = this.props;
        const {user} = this.state;
        if(user!== null && user!== undefined){
            return(
                <div>
                    <h4>{user.username}</h4>
                    <p><span style={theme.textSupport}>Email:&nbsp;</span>{user.roles?.includes("default") && user.roles?.length ===1? "Anonymous" : user.email}</p>
                    <Accordion key="access"  id={`access-key1`} TransitionProps={{ mountOnEnter: true }}>
                        <AccordionSummary
                            expandIcon={<KeyboardArrowRightIcon />}
                            aria-controls="access-content"
                            id="access"
                            >
                            <div style={theme.divFullWidth}>
                                <LocalLibraryIcon style={theme.inlineIconLeft}/>
                                &nbsp;
                                <span style={theme.textSupport}>Program Access:&nbsp;</span>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div style={theme.divFullWidth}>
                                <h5>Program Pools</h5>
                                    {(this.context.roles?.includes("ADMIN") || user.email === this.context.email || user.roles === undefined || (user.roles!== undefined && !(user.roles?.includes("ADMIN")) && !(user.roles?.includes("COACHADMIN"))))
                                        ?
                                            <>
                                                <SettingContentArrayCheckbox
                                                    dbUpdateLink={this.props.firebase.user(this.props.userId)}
                                                    title={"Access to Programs:"}
                                                    content={user} 
                                                    labels={["Program pools"]}
                                                    firstItemMandatory={true}
                                                    params={['programs']}
                                                    selectOptions={this.state.availableCoachPrograms || []}
                                                    selectOptionsText={this.state.availableCoachProgramsText || []}
                                                    updateState = {() => this.loadUserPrograms()}
                                                />
                                                <p>The program pools indicate the main access to programs and modules.
                                                    e.g. Rocky.ai basic public content (People, Priority,...) is defined as 'default'.
                                                </p>
                                                {user.programs!==undefined && !user.programs.includes('default')
                                                    ?
                                                        <>
                                                            <span>
                                                                Public content is related to the default program pools by Rocky.ai -
                                                                <br/>
                                                                Hiding public content makes only sense if your program is not public and exclusive for your audience.
                                                                <br/>
                                                            </span>
                                                            <SettingContentActive
                                                                dbUpdateLink={this.props.firebase.user(this.props.userId)}
                                                                title={"Remove public 'Essentials' content in 'Quests' and 'Chatbot'"} 
                                                                content={user} 
                                                                infoText={["Users will not explore 'Essentials' content during the AI chats"]}
                                                                labels={["'Essentials' content unpublished"]}
                                                                params={['hidePublicContent']}
                                                            />
                                                        </>
                                                    :
                                                        user.hidePublicContent
                                                        ?
                                                            <>
                                                                <p>
                                                                    You can hide public content if you want your client to focus exclusively on articles from the premium content pools!
                                                                    <br/>
                                                                </p>
                                                                <SettingContentActive
                                                                    dbUpdateLink={this.props.firebase.user(this.props.userId)}
                                                                    title={"Remove public 'Essentials' content in 'Quests' and 'Chatbot'"} 
                                                                    content={user} 
                                                                    infoText={["Users will not explore 'Essentials' content during the AI chats"]}
                                                                    labels={["'Essentials' content unpublished"]}
                                                                    params={['hidePublicContent']}
                                                                    />
                                                            </>
                                                        :
                                                            ""
                                                }
                                            </>
                                        :
                                            <span style={{color:'red'}}>No access on this account!</span>
                                    }
                            </div>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion key="quality_details1"  id={`quality-key1`}   TransitionProps={{ mountOnEnter: true }}>
                        <AccordionSummary
                            expandIcon={<KeyboardArrowRightIcon />}
                            aria-controls="quality-content"
                            id="quality"
                            >
                            <div style={theme.divFullWidth}>
                                <SchoolIcon style={theme.inlineIconLeft}/>
                                &nbsp;
                                <span style={theme.textSupport}>Personal Coaching Plan:&nbsp;</span>
                                <br/>Modify the personal development (Influences the AI chat).
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div style={theme.divFullWidth}>
                                <h5>Personal Coaching Plan</h5>
                                <SettingContentSelectCombi
                                    dbUpdateLink={this.props.firebase.user(this.props.userId)}
                                    title={"Choose a Quest"} 
                                    content={user} 
                                    labels={["Module 1"]} 
                                    params={['currentLeadershipQuality']}
                                    selectOptions={this.state.leadershipQualitiesValues || []}
                                    selectOptionsText={this.state.leadershipQualitiesText || []}
                                    selectOptionsFilter={this.state.leadershipCategoriesValues || []}
                                    selectOptionsFilterText={this.state.leadershipCategoriesText || []}
                                    leadershipQualities={this.props.leadershipQualities}
                                    leadershipCategories={this.props.leadershipCategories}
                                    updateState={(set) => {
                                        console.log('set', set)
                                        if(set?.category?.length > 0){
                                            this.props.firebase.user(this.props.userId).update({currentCategory: set.category})
                                        }
                                    }}
                                    />
                                {[1,2,3,4,5].map( (item, index) => {
                                    var setName = 'additionalLeadershipQuality' + ('') + item;
                                    var preSetName = 'additionalLeadershipQuality' + ('') + (item-1);
                                    if(item === 1){
                                        preSetName = 'currentLeadershipQuality';
                                    }
                                    if(user[setName]?.length>0 || user[preSetName]?.length>0){
                                        return(
                                            <SettingContentSelectCombi
                                                key={'addi_'+index}
                                                dbUpdateLink={this.props.firebase.user(this.props.userId)}
                                                title={"Choose additional Module " + (index+2) + ' (optional)'} 
                                                content={user} 
                                                labels={["Module " + (index+2)]} 
                                                params={[setName]}
                                                emptyOption={true}
                                                selectOptions={this.state.leadershipQualitiesValues || []}
                                                selectOptionsText={this.state.leadershipQualitiesText || []}
                                                selectOptionsFilter={this.state.leadershipCategoriesValues || []}
                                                selectOptionsFilterText={this.state.leadershipCategoriesText || []}
                                                leadershipQualities={this.props.leadershipQualities}
                                                leadershipCategories={this.props.leadershipCategories}
                                            />
                                        )
                                    }
                                    else return ""
                                })}
                            </div>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion key="stage" id={`stage-key1`} TransitionProps={{ mountOnEnter: true }}>
                        <AccordionSummary
                            expandIcon={<KeyboardArrowRightIcon />}
                            aria-controls="stage-content"
                            id="stage"
                            >
                            <div style={theme.divFullWidth}>
                                <WorkIcon style={theme.inlineIconLeft}/>&nbsp;
                                <span style={theme.textSupport}>Life/Career Stage:&nbsp;</span>
                                <br/>Define the life/career stage (Influences the AI chat).
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                        <div style={theme.divFullWidth}>
                            <RoleOverview 
                                defaultSet={this.props.defaultSet || undefined}
                                key={"role"}
                                user={{...user, uid: this.props.userId}}
                                coachAccount={true}
                            />
                        </div>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion key="goal_details1"  id={`goal-key1`}   TransitionProps={{ mountOnEnter: true }}>
                        <AccordionSummary
                            expandIcon={<KeyboardArrowRightIcon />}
                            aria-controls="goal-content"
                            id="goal"
                            >
                            <div style={theme.divFullWidth}>
                                <FlareIcon style={theme.inlineIconLeft}/>&nbsp;
                                <span style={theme.textSupport}>Vision/North Star Goal:&nbsp;</span>
                                <br/>The AI Coach will ask about the 'North Star Goal / Personal or Professional Vision' once a while.
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                        <div style={theme.divFullWidth}>
                            <SettingContentBase 
                                dbUpdateLink={this.props.firebase.user(this.props.userId)}
                                title={"North Star Goal"} 
                                noLabel={true}
                                labels={["Vision Statement"]}
                                max={160}
                                content={user} 
                                params={['achievementGoal']}
                                /> 
                        </div>
                        </AccordionDetails>
                    </Accordion>
                </div>
            )

        }
        else{
            return(
                <div>loading
                </div>
            )
        }
    }

    

}//end class


export default compose(
    withFirebase,
    withStyles(styles, { withTheme: true })
)(CoachUserSetting);
